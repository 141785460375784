import { fontWeight } from '@shared/style/sizes'
import styled from 'styled-components'

export const Container = styled.div`
  > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  }
`

export const Card = styled.div`
  padding: 10px;
`

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 35px;
`

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  padding-left: 10px;
  align-items: center;
`

export const RadioText = styled.span`
  padding-left: 10px;
  font-weight: ${fontWeight.normal};
  overflow: hidden;
  text-overflow: ellipsis;
`
