import React, { Component, Fragment } from 'react'
import emptyImage from '@media/cage.svg'
import BasicTopBar from '@components/basicTopBar'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import { Link, EmptyState } from '@toriihq/design-system'

export const ERROR_TYPES = {
  DEFAULT: 'default',
  WORKFLOW_PREVIEW: 'workflow_preview'
}

const errorPropsByType = {
  [ERROR_TYPES.DEFAULT]: {
    title: 'Something went wrong',
    description: 'Our team has been alerted and will be working to solve this',
    link: <Link onClick={() => { window.location.href = '/team' }}>Back to Torii</Link>,
    logError: true
  },
  [ERROR_TYPES.WORKFLOW_PREVIEW]: {
    title: 'Can not display workflow edit history for this version',
    description: 'This workflow version is outdated and can not be displayed',
    logError: false
  }
}

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (_error) {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    const { toggleViewOnlyMode, type } = this.props
    const { logError } = errorPropsByType[type]

    toggleViewOnlyMode({ viewOnlyMode: false })
    this.setState({ hasError: true })

    if (logError) {
      window.Sentry && window.Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        window.Sentry.captureException(error)
      })
    }
  }

  renderUnexpectedError = () => {
    const { title, description, link } = errorPropsByType[this.props.type]

    return (
      <Fragment>
        <BasicTopBar />
        <div {...css({ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' })}>
          <EmptyState
            image={<img src={emptyImage} alt='Error' />}
            title={title}
            description={description}
            link={link}
          />
        </div>
      </Fragment>
    )
  }

  render () {
    if (!this.state.hasError) {
      return this.props.children
    }

    return this.renderUnexpectedError()
  }
}

ErrorBoundary.propTypes = {
  type: PropTypes.oneOf(Object.values(ERROR_TYPES))
}

ErrorBoundary.defaultProps = {
  type: ERROR_TYPES.DEFAULT
}

export default ErrorBoundary
