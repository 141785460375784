import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'

export const Divider = css({
  margin: '30px 0 0'
})

export const Label = css(texts.subheading, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: colors.black,
  marginBottom: '10px',
  textTransform: 'uppercase'
})

export const Row = css({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.border}`,
  ':hover': { backgroundColor: colors.lightBlue3 }
})

export const SelectedRow = css({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  backgroundColor: colors.lightBlue3,
  alignItems: 'center',
  borderBottom: `1px solid ${colors.border}`
})

export const AppDetails = css({
  flexGrow: 1
})

export const Info = css({
  flexGrow: 1,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 400,
  color: colors.grey2,
  paddingRight: '5px'
})

export const CheckButton = css({
  marginLeft: '5px',
  marginRight: '5px'
})
