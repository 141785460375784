import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import texts from '../../../shared/style/texts'
import colors from '../../../shared/style/colors'

const CSS = {
  summary: css({
    padding: `12px 21px 0 21px`,
    height: '70px'
  }),
  description: css(texts.subheading, {
    color: colors.grey1
  }),
  amount: css(texts.headers.large, {
    marginRight: '12px'
  })
}

const ChartSummary = ({ amount, description }) => {
  return <div {...CSS.summary}>
    <span {...CSS.amount}>{amount}</span>
    <span {...CSS.description}>{description}</span>
  </div>
}

ChartSummary.propTypes = {
  amount: PropTypes.node,
  description: PropTypes.string
}

export default ChartSummary
