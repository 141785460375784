import { BranchFilter as BranchFilterType } from '@shared/workflows/types'
import React, { ReactElement } from 'react'
import {
  EntityOption,
  KeyOptionsByEntity,
  KeyOptionValueByEntity,
  OnChangeBranchFilterProps
} from '@components/filters/ifElseBranchFilters/types'
import BranchFilter from '@components/filters/ifElseBranchFilters/branchFilter'
import AndSeparator from '@components/_shared/andSeparator'

interface Props {
  filters: BranchFilterType[]
  branchIndex: number
  entityOptions?: EntityOption[]
  keyOptionsByEntityType?: KeyOptionsByEntity
  onRemove: (branchIndex: number, branchFilterIndex: number) => void
  onChange: ({ branchIndex, branchFilterIndex, key, newValue }: OnChangeBranchFilterProps) => void
  keyOptionValuesByEntityType?: KeyOptionValueByEntity
  disabled: boolean
}

const BranchFilters = ({
  filters,
  branchIndex,
  entityOptions,
  keyOptionsByEntityType,
  keyOptionValuesByEntityType,
  onRemove,
  onChange,
  disabled
}: Props): ReactElement => {
  const filtersAmount = filters.length
  return (
    <div key={branchIndex}>
      {filters.map((branchFilter, index) => {
        const shouldRenderAnd = (index + 1) < filtersAmount
        return (
          <div key={branchFilter.id} >
            <BranchFilter
              filter={branchFilter}
              disabled={disabled}
              onRemove={() => onRemove(branchIndex, index)}
              fieldsAutoFocus
              entityOptions={entityOptions}
              keyOptionsByEntityType={keyOptionsByEntityType}
              keyOptionValuesByEntityType={keyOptionValuesByEntityType}
              onChange={({ key, newValue }) => onChange({ branchIndex, branchFilterIndex: index, key, newValue })}
            />
            <AndSeparator shouldRenderAnd={shouldRenderAnd} />
          </div>
        )
      })}
    </div>
  )
}

export default BranchFilters
