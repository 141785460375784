import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import LicenseTooltipContent from '../licenseTooltipContent'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

const CSS = {
  active: css({
    backgroundColor: colors.lightBlue4
  }),
  inactive: css({
    backgroundColor: colors.warning
  }),
  unassigned: css({
    backgroundColor: colors.grey2
  }),
  description: css(texts.subheading, {
    '> div': { paddingTop: '10px' },
    '> div > span': { float: 'right', color: colors.black }
  })
}

const pieCss = {
  chart: {
    margin: '0 auto'
  },
  pieChartTooltipWrapper: {
    zIndex: 999,
    left: -400,
    top: -20,
    background: 'white',
    padding: 15,
    boxShadow: '0 4px 20px 4px rgba(0,20,60,.1), 0 4px 80px -8px rgba(0,20,60,.2)'
  }
}

const dataConfig = {
  active: { key: 'activeAmount', color: colors.lightBlue4 },
  inactive: { key: 'inactiveAmount', color: colors.warning },
  unassign: { key: 'unassignedAmount', color: colors.grey2 }
}
class LicensePie extends React.Component {
  state = { data: this.getData() }

  getData () {
    const { unassignedAmount, inactiveAmount, activeAmount } = this.props
    return [
      { ...dataConfig.active, value: activeAmount },
      { ...dataConfig.inactive, value: inactiveAmount },
      { ...dataConfig.unassign, value: unassignedAmount }
    ].filter(d => d.value)
  }

  componentDidUpdate (prevProps) {
    const propsToCheckChange = ['unassignedAmount', 'inactiveAmount', 'activeAmount']
    if (propsToCheckChange.some(prop => prevProps[prop] !== this.props[prop])) {
      this.setState({ data: this.getData() })
    }
  }

  render () {
    const { inactiveReasons } = this.props
    const { data } = this.state

    return (
      <PieChart width={55} height={55} style={pieCss.chart}>
        <Pie data={data} dataKey='value' innerRadius={16} outerRadius={24} animationBegin={300} animationDuration={500} minAngle={25}>
          {data.map((entry) => <Cell key={entry.key} fill={entry.color} />)}
        </Pie>
        <Tooltip
          wrapperStyle={pieCss.pieChartTooltipWrapper}
          animationDuration={100}
          content={({ payload, active } = {}) => {
            if (!active) {
              return null
            }

            const { key, value } = payload[0].payload
            const { noUsage, noAccess, leftOrg } = inactiveReasons
            switch (key) {
              case 'activeAmount':
                return <LicenseTooltipContent
                  header='Active Licenses'
                  description='Assigned and being used'
                  amount={value}
                  colorCss={CSS.active}
                />
              case 'inactiveAmount':
                return <LicenseTooltipContent
                  header='Inactive Licenses'
                  description={(
                    <div {...CSS.description}>
                      {noUsage > 0 && <div>
                        Assigned but not being used
                        <span>{noUsage}</span>
                      </div>}
                      {noAccess > 0 && <div>
                        Assigned but with no access to service
                        <span>{noAccess}</span>
                      </div>}
                      {leftOrg > 0 && <div>
                        Assigned to past users
                        <span>{leftOrg}</span>
                      </div>}
                    </div>
                  )}
                  amount={value}
                  colorCss={CSS.inactive}
                />
              case 'unassignedAmount':
                return <LicenseTooltipContent
                  header='Unassigned Licenses'
                  description='Available licenses not assigned to users'
                  amount={value}
                  colorCss={CSS.unassigned}
                />
              default:
                return null
            }
          }}
          cursor={false}
        />
      </PieChart>
    )
  }
}

LicensePie.propTypes = {
  unassignedAmount: PropTypes.number,
  inactiveAmount: PropTypes.number,
  inactiveReasons: PropTypes.shape({
    noAccess: PropTypes.number,
    leftOrg: PropTypes.number,
    noUsage: PropTypes.number
  }),
  activeAmount: PropTypes.number
}

export default LicensePie
