import React from 'react'
import Placeholder from '@components/placeholder'
import { CSS } from './style'
import Select from '@components/select'

type Props = {
    loading: boolean
    customSelectOptions: unknown[]
    customSelectSelectedValue: object
    onChange: (option: unknown) => void
    customSelectOptionRenderer?: (option: unknown) => JSX.Element
}

const TableCustomSelect = ({ loading, customSelectOptions, customSelectSelectedValue, onChange, customSelectOptionRenderer }: Props) : JSX.Element => {
  const props = customSelectOptions === undefined ? {} : typeof customSelectOptionRenderer === 'function' ? { optionRenderer: customSelectOptionRenderer } : { components: customSelectOptionRenderer }
  return (
    <Placeholder loading={loading} type='rect' style={CSS.selectLoadingStyle}>
      <Select
        options={customSelectOptions}
        value={customSelectSelectedValue}
        selectWidth={250}
        clearable={false}
        openOnFocus
        onChange={onChange}
        {...props}
      />
    </Placeholder>
  )
}

export default TableCustomSelect
