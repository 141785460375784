import { Reducer } from 'redux'
import { AppUsersAction, AppUsersState } from '@reducers/appUsers/types'
import { APP_USER_STATUS } from '@shared/types'

const initialState: AppUsersState = {
  loading: false,
  usersPerApp: {},
  fieldValuesPerApp: {},
  appsPerUser: {},
  statePerApp: {},
  inactiveUsers: {
    users: [],
    resources: {
      users: [],
      apps: []
    },
    loading: false,
    loadingMore: false,
    isLoaded: false,
    total: 0
  },
  importAppUsersStatus: undefined,
  appUsersCount: {}
}

const appUsersReducer: Reducer<AppUsersState, AppUsersAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_APP_USERS_PENDING': {
      const { reset = true, idApp } = action.meta
      return {
        ...state,
        loading: true,
        statePerApp: {
          ...state.statePerApp,
          [idApp]: {
            ...state.statePerApp[idApp],
            loadingMore: !reset
          }
        }
      }
    }
    case 'GET_APP_USERS_FAILED': {
      const { idApp } = action.meta
      return {
        ...state,
        loading: false,
        statePerApp: {
          ...state.statePerApp,
          [idApp]: {
            ...state.statePerApp[idApp],
            loadingMore: false
          }
        }
      }
    }
    case 'GET_APP_USERS_RESPONSE': {
      const { users, total } = action.payload
      const { idApp, reset = true } = action.meta
      return {
        ...state,
        loading: false,
        usersPerApp: {
          ...state.usersPerApp,
          [idApp]: reset ? users : (state.usersPerApp[idApp] || []).concat(users)
        },
        statePerApp: {
          ...state.statePerApp,
          [idApp]: {
            loadingMore: false,
            totalUsers: total
          }
        }
      }
    }
    case 'GET_APP_USERS_FIELD_VALUES_RESPONSE': {
      const { idApp } = action.meta
      return {
        ...state,
        loading: false,
        fieldValuesPerApp: {
          ...state.fieldValuesPerApp,
          [idApp]: {
            ...state.fieldValuesPerApp[idApp],
            ...action.payload
          }
        }
      }
    }
    case 'GET_APPS_OF_USER_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'GET_APPS_OF_USER_FAILED': {
      return {
        ...state,
        loading: false
      }
    }
    case 'GET_APPS_OF_USER_RESPONSE': {
      const { apps } = action.payload
      const { idUser } = action.meta

      return {
        ...state,
        loading: false,
        appsPerUser: {
          ...state.appsPerUser,
          [idUser]: apps
        }
      }
    }
    case 'UPDATE_APP_USER_INFO_RESPONSE': {
      const { idUser, idApp, isRemoved: isUserRemovedFromApp } = action.meta

      const updatedInfo = isUserRemovedFromApp
        ? { isUserRemovedFromApp, status: APP_USER_STATUS.DELETED } : { isUserRemovedFromApp }

      const modifiedApps = (state.appsPerUser[idUser] || []).map(
        app => (app.idApp === idApp) ? { ...app, ...updatedInfo } : app
      )

      return {
        ...state,
        appsPerUser: {
          ...state.appsPerUser,
          [idUser]: modifiedApps
        }
      }
    }
    case 'GET_INACTIVE_USERS_PENDING': {
      const { reset = true } = action.meta

      return {
        ...state,
        inactiveUsers: {
          ...state.inactiveUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case 'GET_INACTIVE_USERS_FAILED': {
      return {
        ...state,
        inactiveUsers: {
          ...state.inactiveUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case 'GET_INACTIVE_USERS_RESPONSE': {
      const { inactiveUsers, total, resources } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        inactiveUsers: {
          ...state.inactiveUsers,
          users: reset ? inactiveUsers : (state.inactiveUsers.users || []).concat(inactiveUsers),
          resources: {
            users: {
              ...state.inactiveUsers.resources.users,
              ...resources.users
            },
            apps: {
              ...state.inactiveUsers.resources.apps,
              ...resources.apps
            }
          },
          loadingMore: false,
          loading: false,
          isLoaded: true,
          total
        }
      }
    }
    case 'UPDATE_IMPORT_APP_USERS_STATUS': {
      const { importAppUsersStatus } = action.payload
      return {
        ...state,
        importAppUsersStatus
      }
    }
    case 'GET_APP_USERS_COUNT_PENDING': {
      const { idApp } = action.meta
      return {
        ...state,
        appUsersCount: {
          ...state.appUsersCount,
          [idApp]: {
            ...state.appUsersCount[idApp],
            loading: true
          }
        }
      }
    }
    case 'GET_APP_USERS_COUNT_FAILED': {
      const { idApp } = action.meta
      return {
        ...state,
        appUsersCount: {
          ...state.appUsersCount,
          [idApp]: {
            loading: false
          }
        }
      }
    }
    case 'GET_APP_USERS_COUNT_RESPONSE': {
      const { total } = action.payload
      const { idApp } = action.meta
      return {
        ...state,
        appUsersCount: {
          ...state.appUsersCount,
          [idApp]: {
            count: total,
            loading: false
          }
        }
      }
    }
    default: {
      return state
    }
  }
}

export default appUsersReducer
