import React, { ReactElement } from 'react'
import { StyledTransparentHandle } from './styles'
import { HandleType, Position } from './types'

interface Props {
  type: HandleType
  position: Position
}

export const TransparentHandle = (props: Props): ReactElement => <StyledTransparentHandle
  {...props}
  isConnectable={false}
/>
