import React, { useMemo } from 'react'
import ToriiSelect from '@components/select'
import { getTimezones } from '@root/shared/timeUtils'
import { TimezoneOption } from '@root/shared/types'

interface Props {
  selectedValue?: TimezoneOption | string | null
  onChange?: (value: TimezoneOption) => void,
  disabled?: boolean
}

const SelectTimezone = ({
  selectedValue,
  onChange,
  disabled
}: Props): JSX.Element => {
  const options = useMemo(() => getTimezones(), [])

  const onValueChange = (value: TimezoneOption) => {
    onChange && value && onChange(value)
  }

  const parseTimezone = (value: TimezoneOption | string | null | undefined) => {
    if (typeof value === 'string') {
      return options.find(tz => tz.value === value)
    }

    return value
  }

  return (
    <ToriiSelect
      options={options}
      value={parseTimezone(selectedValue)}
      onChange={newValue => onValueChange(newValue as TimezoneOption)}
      clearable={false}
      disabled={disabled}
      maxMenuHeight={200}
    />
  )
}

export default SelectTimezone
