import React from 'react'
import { Button, Col, Grid, Row } from '@toriihq/design-system'
import Alert, { ALERT_TYPE } from '../alert/index'
import Headers from '../signup/headers/index'
import { Link } from 'react-router-dom'
import TextControl from '../form/text-control'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { align } from '../../shared/style/mixins'
import { fontSize } from '../../shared/style/sizes'
import qs from 'query-string'

const CSS = {
  smallText: css({
    fontSize: fontSize.small,
    fontWeight: fontSize.normal,
    color: colors.grey2,
    lineHeight: '18px'
  }),
  verticalAlignMiddle: css({
    display: 'inline-block',
    verticalAlign: 'middle',
    float: 'none'
  })
}

class ForgotPage extends React.Component {
  constructor (props) {
    super(props)

    this.input = {}
  }

  componentDidMount () {
    if (this.input.email) {
      this.input.email.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    const { forgot, location } = this.props
    const query = qs.parse(location.search)

    forgot({
      email: this.input.email.value(),
      next: query.next
    })
  }

  render () {
    const { error, success, hasError, help, loading } = this.props
    const alerts = []

    const backToLoginInfo = {
      search: this.props.location.search,
      pathname: '/login'
    }

    if (success) {
      alerts.push(
        <div key='success'>
          <Alert type={ALERT_TYPE.SUCCESS}>
            <div>
              If an account matched that address, an email will be sent with instructions.
            </div>
          </Alert>
          <Link to={backToLoginInfo}>Back to login</Link>
        </div>
      )
    }

    if (error) {
      alerts.push(
        <Alert key='error' type={ALERT_TYPE.ERROR}>
          {error}
        </Alert>
      )
    }

    let formElements

    if (!success) {
      formElements = (
        <fieldset>
          <TextControl
            ref={(c) => (this.input.email = c)}
            name='email'
            placeholder="What's your email?"
            hasError={hasError.email}
            error={help.email}
            disabled={loading}
            iconName='User'
          />
          <Grid>
            <Row>
              <Col {...css(CSS.smallText, CSS.verticalAlignMiddle, align.left)}>
                <Link to={backToLoginInfo}>Back to login</Link>
              </Col>
              <Col {...css(CSS.verticalAlignMiddle, align.right)}>
                <Button
                  label='Send Link'
                />
              </Col>
            </Row>
          </Grid>
        </fieldset>
      )
    }

    return (
      <section>
        <Headers
          headerText='Forgot your password?'
          subHeaderText='Enter your email address and we will send you a link to reset your password'
          center
        />
        <form onSubmit={this.handleSubmit.bind(this)}>
          {alerts}
          {formElements}
        </form>
      </section>
    )
  }
}

export default ForgotPage
