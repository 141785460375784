import { USER_FILTER_MODEL_TYPE } from '@shared/filters/userFilters/consts'
import {
  AppUserCriterion,
  UserCriterion
} from '@shared/filters/userFilters/types'
import { UserEntityCriterionKeys } from '@components/filters/userVerticalFilter/types'

export const getUpdatedCriterion = ({
  criterion,
  key,
  newValue
}: {
  criterion: AppUserCriterion | UserCriterion,
  key: UserEntityCriterionKeys,
  newValue
}): AppUserCriterion | UserCriterion => {
  let prevCriterion
  const resetFilters = { id: Date.now() }

  switch (key) {
    case 'type':
      const isUserFieldsType = newValue && newValue.modelType === USER_FILTER_MODEL_TYPE.USER_FIELDS
      prevCriterion = isUserFieldsType ? { filters: resetFilters } : {}
      break
    case 'account':
      const isAccountChanged = criterion.account?.value !== newValue.value
      prevCriterion = {
        ...criterion,
        filters: isAccountChanged ? resetFilters : criterion.filters
      }
      break
    default:
      prevCriterion = criterion
  }

  return {
    ...prevCriterion,
    [key]: newValue
  }
}
