import {
  ActionNodeModel,
  BranchNodeModel,
  IdWorkflowNode,
  WORKFLOW_NODE_TYPE,
  WorkflowNodeModel,
  ActionWithFixedBranchesNodeModel,
  FixedBranchesFieldValue,
  BranchActionFieldValue
} from '@shared/workflows/types'
import { AddActionNodeParams, AddActionNodeResult } from './addActionNode.types'
import { extractParentActionNodeInfo, isDefaultBranchInParent } from './branchActionChildrenOps'
import { cloneDeep, isNil } from 'lodash'
import { ID_TRIGGER_NODE } from '../consts'
import { WORKFLOW_FIELD_TYPE } from '../types'

interface ModifiedNodesInfo {
  modifiedParentNode: WorkflowNodeModel,
  newActionNext: IdWorkflowNode | null
}

interface GetModifiedNodesForBranchParams {
  parentNode: BranchNodeModel | ActionWithFixedBranchesNodeModel
  parentBranches: BranchActionFieldValue | FixedBranchesFieldValue
  idNewActionNode: IdWorkflowNode
  branchPositionInParent: number
}

const buildModifiedActionParent = (parentNode: ActionNodeModel, idNewActionNode: IdWorkflowNode): ModifiedNodesInfo => ({
  modifiedParentNode: {
    ...parentNode,
    next: idNewActionNode
  },
  newActionNext: parentNode.next
})

const getModifiedNodesForBranch = ({
  parentNode,
  parentBranches,
  idNewActionNode,
  branchPositionInParent
}: GetModifiedNodesForBranchParams): ModifiedNodesInfo => {
  const modifiedParentNode = { ...parentNode }

  const isDefaultBranch = isDefaultBranchInParent(branchPositionInParent)
  let newActionNext: IdWorkflowNode | null

  if (isDefaultBranch) {
    newActionNext = parentBranches.default.next
    parentBranches.default = {
      ...parentBranches.default,
      next: idNewActionNode
    }
  } else {
    const originalBranch = parentBranches.branches[branchPositionInParent]
    newActionNext = originalBranch.next

    parentBranches.branches[branchPositionInParent] = {
      ...originalBranch,
      next: idNewActionNode
    }
  }

  return {
    modifiedParentNode,
    newActionNext
  }
}

const buildModifiedBranchActionParent = (
  parentNode: BranchNodeModel,
  idNewActionNode: IdWorkflowNode,
  branchPositionInParent: number
): ModifiedNodesInfo => {
  const parentBranches = parentNode.action.fields[0].value
  return getModifiedNodesForBranch({
    parentNode,
    parentBranches,
    idNewActionNode,
    branchPositionInParent
  })
}

const buildModifiedActionWithFixedBranchesParent = (
  parentNode: ActionWithFixedBranchesNodeModel,
  idNewActionNode: IdWorkflowNode,
  branchPositionInParent: number
): ModifiedNodesInfo => {
  const parentBranches = parentNode.action.fields
    .find(field => field.id === WORKFLOW_FIELD_TYPE.FIXED_BRANCHES)!.value as FixedBranchesFieldValue

  return getModifiedNodesForBranch({
    parentNode,
    parentBranches,
    idNewActionNode,
    branchPositionInParent
  })
}

export const addActionNode = ({
  actions,
  idParentUiNode,
  action
}: AddActionNodeParams): AddActionNodeResult => {
  const updatedActions = cloneDeep(actions)

  const actionNode: ActionNodeModel = {
    type: WORKFLOW_NODE_TYPE.ACTION,
    action,
    next: null
  }
  const idNewActionNode = `${action.id}`

  if (idParentUiNode === ID_TRIGGER_NODE) {
    updatedActions.idRootNode = idNewActionNode
    updatedActions.nodes[idNewActionNode] = {
      ...actionNode,
      next: actions.idRootNode
    }
  } else {
    const { idParentNode, parentNode, branchPositionInParent } = extractParentActionNodeInfo({ idParentUiNode, nodes: updatedActions.nodes })

    let result: ModifiedNodesInfo
    if (parentNode.type === WORKFLOW_NODE_TYPE.ACTION) {
      result = buildModifiedActionParent(parentNode, idNewActionNode)
    } else if (isNil(branchPositionInParent)) {
      throw new Error(`Encountered unexpected branch label node id: ${idParentUiNode}`)
    } else if (parentNode.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
      result = buildModifiedBranchActionParent(parentNode, idNewActionNode, branchPositionInParent)
    } else if (parentNode.type === WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES) {
      result = buildModifiedActionWithFixedBranchesParent(parentNode, idNewActionNode, branchPositionInParent)
    } else {
      throw new Error('addNewAction supports only `action`, `branch` and `action with branches` nodes as parents')
    }

    const { modifiedParentNode, newActionNext } = result

    updatedActions.nodes = {
      ...updatedActions.nodes,
      [idParentNode]: modifiedParentNode,
      [idNewActionNode]: {
        ...actionNode,
        next: newActionNext
      }
    }
  }

  return {
    actions: updatedActions,
    idNewActionNode
  }
}
