import React from 'react'
import { SCOPES } from '@root/constants'
import AdvancedToggle from '@root/components/advancedToggle'
import { ToggleSize } from '@toriihq/design-system'
import { ToggleContainer } from '../styles'

interface Props {
  isCustomSchedule: boolean
  onScheduleTypeChange: (e: { isCustomSchedule: boolean }) => void
}

export const ScheduleTypeSelector = ({
  isCustomSchedule,
  onScheduleTypeChange
}: Props): JSX.Element => {
  const toggleCustomSchedule = () => onScheduleTypeChange({ isCustomSchedule: !isCustomSchedule })

  return (<ToggleContainer>
    <AdvancedToggle
      checked={isCustomSchedule}
      loading={false}
      toggleId='workflow_custom_schedule'
      text='Define a custom schedule for the workflow'
      scopes={[SCOPES.AUTOMATION_WRITE]}
      onToggle={toggleCustomSchedule}
      toggleSize={ToggleSize.medium}
      alignToRight={false}
    />
  </ToggleContainer>)
}
