import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'

const CurrencySymbol = ({ currencySymbol, overrideStyle }) => <span {...css(overrideStyle)}>{currencySymbol}</span>

CurrencySymbol.propTypes = {
  overrideStyle: PropTypes.object
}

export default CurrencySymbol
