import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'

export const FlexColumn = css({
  display: 'flex',
  flexDirection: 'column'
})

export const Text = css(texts.subheading, {
  marginTop: '20px',
  textAlign: 'center',
  ' span': {
    color: colors.blue
  }
})

export const DarkText = css(texts.subheading, {
  color: colors.darkText,
  textAlign: 'center'
})

export const BodyDescription = css(texts.body, {
  color: colors.darkText,
  display: 'grid',
  justifyContent: 'center'
})

export const IconContainer = css({
  height: '77px',
  width: '77px',
  alignSelf: 'center'
})

export const TextSpacingTop = css({
  marginTop: 3
})

export const TextSpacingBottom = css({
  marginBottom: 15
})

export const DetailsContainer = css({
  border: `1px solid ${colors.grey3}`,
  padding: 10,
  borderRadius: 4
})
export const DetailsRow = css({
  ':not(:last-child)': {
    marginBottom: 5
  }
})

export const SummaryContainer = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
})

export const ShowOrHideContainer = css({
  display: 'flex',
  alignItems: 'center'
})
