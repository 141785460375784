import styled from 'styled-components'

export namespace Styles {
  export const PluginAside = styled.div`
    display: flex;
    width: 253px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    font-family: Nunito;
  `

  export const PluginLogoFallback = styled.div`
    border-radius: 8px;
    height: 148px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    background-color: ${({ theme }) => theme.palette.background.disabled};
  `

  export const InnerText = styled.div`
    font: ${({ theme }) => theme.typography.font.header03};
    color: ${({ theme }) => theme.palette.text.secondary};
  `
  export const PluginLogo = styled.img`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    height: 148px;
    width: 100%;
    object-fit: cover;
  `

  export const PluginHelp = styled.div`
    gap: 4px;
    align-self: stretch;
  `

  export const PluginDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    color: ${({ theme }) => theme.palette.text.tertiary};
  `
}
