import React from 'react'
import Chart from './chart'
import Placeholder from '../placeholder'
import PropTypes from 'prop-types'
import { ContainerDiv } from '@components/waterfallChartBox/styles'

const WaterfallChart = ({ barsConfig, loading, data, fill, overrideStyle, barSize = 90, chartHeight, showLegend, tooltipContent, imagesAxis = false, boxHeight = '380', chartOverrideStyle, analyticsEvent, showCustomDynamicBarsChart, interval, customDynamicBarsChartDefaultFill, formatter, legendMapping }) => {
  return (
    <ContainerDiv overrideStyle={overrideStyle} boxHeight={boxHeight}>
      <Placeholder loading={loading} rows={6} style={{ maxWidth: '70%', padding: '20px', height: boxHeight }}>
        <Chart
          barsConfig={barsConfig}
          overrideStyle={chartOverrideStyle}
          data={data}
          fill={fill}
          barSize={barSize}
          showLegend={showLegend}
          legendMapping={legendMapping}
          tooltipContent={tooltipContent}
          chartHeight={chartHeight}
          imagesAxis={imagesAxis}
          analyticsEvent={analyticsEvent}
          showCustomDynamicBarsChart={showCustomDynamicBarsChart}
          interval={interval}
          customDynamicBarsChartDefaultFill={customDynamicBarsChartDefaultFill}
          formatter={formatter}
        />
      </Placeholder>
    </ContainerDiv>
  )
}

WaterfallChart.propTypes = {
  loading: PropTypes.bool,
  overrideStyle: PropTypes.object,
  ...Chart.propTypes,
  chartHeight: PropTypes.number,
  imagesAxis: PropTypes.bool,
  chartOverrideStyle: PropTypes.object,
  formatter: PropTypes.func
}

export default WaterfallChart
