import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'

export const getOrgSecretsInfo = createSelector(
  state => {
    const secrets = get(state, ['orgSecrets', 'secrets'], [])
    const usersMap = get(state, ['orgSecrets', 'usersMap'], {})
    return { secrets, usersMap }
  },
  identity
)

export const getOrgSecretCreationError = createSelector(
  state => get(state, ['orgSecrets', 'creationErrorMessage'], ''),
  identity
)

export const isLoadingOrgSecrets = createSelector(
  state => get(state, ['orgSecrets', 'loading'], false),
  identity
)

export const isLoadedOrgSecrets = createSelector(
  state => get(state, ['orgSecrets', 'loaded'], false),
  identity
)
