import React, { Fragment } from 'react'
import { FIELDS_CONTAINER_ENTITY, formFieldTypeToName, TABLES } from '../../constants'
import Table from '../table'
import { Icon, Tooltip, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import InputPopup from '../popups/inputPopup'
import { hoverActions } from '@shared/style/mixins'
import Analytics from '../../helpers/analytics'
import FieldActions from './fieldActions'
import EnableFor from '../enableFor'
import { Draggable } from 'react-beautiful-dnd'
import { css } from 'glamor'
import * as Style from './style'
import Confirmation from '@components/confirmation'
import ToggleOfFeature from '@components/toggleOfFeature'

class DetailsGroup extends React.Component {
  state = {
    isEditOpen: false,
    isDeleteOpen: false
  }

  constructor (props) {
    super(props)

    this.columns = this.getColumns()
  }

  toggleEditGroupState = (e, isClosedByUser = true) => {
    if (e) {
      e.stopPropagation()
    }
    const { group, itemName } = this.props
    const isEditOpen = !this.state.isEditOpen

    if (isEditOpen) {
      Analytics.track(`Clicked to edit ${itemName} fields group`, {
        'Group ID': group.id,
        'Group name': group.label
      })
    } else if (isClosedByUser) {
      Analytics.track(`Closed edit ${itemName} fields group`, {
        'Group ID': group.id,
        'Group name': group.label
      })
    }
    this.setState({ isEditOpen })
  }

  toggleDeleteGroupState = (e) => {
    if (e) {
      e.stopPropagation()
    }
    const { group, itemName } = this.props
    const isDeleteOpen = !this.state.isDeleteOpen

    if (isDeleteOpen) {
      Analytics.track(`Clicked to delete ${itemName} fields group`, {
        'Group ID': group.id,
        'Group name': group.label
      })
    }
    this.setState({ isDeleteOpen })
  }

  onShownToggle = (isShown, fieldId) => {
    const { idOrg, updateDetailsField } = this.props
    updateDetailsField({ idOrg, idField: fieldId, isShown })
  }

  getHeader = (dragHandleProps) => {
    const { group, fields, allowedScopes } = this.props
    const hasFields = fields.length > 0
    const canEdit = !group.isPredefined && group.isPluginGroup === 0 && !group.isReadOnly
    const canDelete = canEdit && !hasFields
    const editButton =
      <Tooltip
        placement='top'
        hide={canEdit}
        label={<Style.DisabledButtonTooltipLabel>{group.isPluginGroup ? `This group was created by the "${group.label}" plugin and cannot be edited.` : 'This group is defined by Torii and cannot be edited.'}</Style.DisabledButtonTooltipLabel>}
      >
        <EnableFor scopes={allowedScopes}>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={!canEdit} onClick={this.toggleEditGroupState} icon='Edit' />
        </EnableFor>
      </Tooltip>
    const deleteButton =
      <Tooltip
        placement='top'
        hide={canDelete}
        label={<Style.DisabledButtonTooltipLabel>{group.isPredefined || group.isReadOnly ? 'This group is defined by Torii and cannot be deleted.' : group.isPluginGroup ? `This group was created by the "${group.label}" plugin and cannot be deleted.` : `You cannot delete this group because it has fields associated with it. You must remove those fields first.`}</Style.DisabledButtonTooltipLabel>}
      >
        <EnableFor scopes={allowedScopes}>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={!canDelete} onClick={this.toggleDeleteGroupState} icon='Trash' />
        </EnableFor>
      </Tooltip>

    return (
      <div
        {...Style.tableHeader}
      >
        <EnableFor scopes={allowedScopes}>
          <section
            {...dragHandleProps}
            style={{ display: 'flex', alignItems: 'center', cursor: 'move' }}
          >
            <span {...Style.dragIcon} className='dragIcon'>
              <Icon name='Drag' />
            </span>
            <span>
              {group.label}
            </span>
          </section>
        </EnableFor>
        <div {...Style.ButtonsWrapperStyle}>
          {editButton}
          {deleteButton}
        </div>
      </div>
    )
  }

  getColumns = () => {
    const { customColumns = [], allowedScopes = [] } = this.props
    return [
      {
        Header: 'Field Name',
        accessor: 'name'
      },
      {
        Header: (
          <span>
            <Tooltip label='For use in API requests'>Internal Name</Tooltip>
          </span>
        ),
        accessor: 'systemKey'
      },
      {
        Header: (
          <span>
            <Tooltip label='The question that will appear in the workflows forms'>Form Question</Tooltip>
          </span>
        ),
        accessor: 'formQuestion',
        show: !this.props.hideFormQuestion
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value: type }) => formFieldTypeToName[type]
      },
      ...customColumns,
      {
        Header: 'Show',
        accessor: 'isShown',
        Cell: ({ value: isShown, row: { id, idField, systemKey, featureSystemKey } }) => (
          <EnableFor scopes={allowedScopes}>
            <ToggleOfFeature
              feature={featureSystemKey}
              checked={Boolean(isShown)}
              onToggle={this.onShownToggle}
              id={id || idField}
              disabled={this.props.alwaysShownFields.includes(systemKey)}
              tooltipPlacement='top'
            />
          </EnableFor>
        ),
        width: 100
      },
      {
        id: 'action',
        Header: '',
        sortable: false,
        Cell: ({ row: { id, idField, name, isPredefined, isPluginGroup, isReadOnlyGroup, usedByCount, type } }) => {
          return <div {...css(Style.fieldActions, !this.props.isSmallScreen && hoverActions)}>
            <FieldActions
              key={id || idField}
              id={id || idField}
              name={name}
              idOrg={this.props.idOrg}
              isPredefined={Boolean(isPredefined)}
              isPluginGroup={Boolean(isPluginGroup)}
              isReadOnlyGroup={Boolean(isReadOnlyGroup)}
              numItemsUsed={usedByCount}
              deleteDetailsField={this.props.deleteDetailsField}
              toggleUpdateDetailsField={this.props.toggleUpdateDetailsField}
              itemName={this.props.itemName}
              allowedScopes={allowedScopes}
              type={type}
            />
          </div>
        }
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'idField',
        show: false
      },
      {
        accessor: 'isPredefined',
        show: false
      },
      {
        accessor: 'isPluginGroup',
        show: false
      },
      {
        accessor: 'isReadOnlyGroup',
        show: false
      },
      {
        accessor: 'options',
        show: false
      },
      {
        accessor: 'featureSystemKey',
        show: false
      }
    ]
  }

  editGroup = async ({ fieldName }, group) => {
    const { idOrg, editDetailsGroup } = this.props
    return editDetailsGroup({ idOrg, idGroup: group.id, label: fieldName })
  }

  deleteGroup = async () => {
    const { deleteDetailsGroup, idOrg, group } = this.props
    await deleteDetailsGroup({ idOrg, idGroup: group.id })
  }

  onAddFieldClicked = (e) => {
    if (e) {
      e.stopPropagation()
    }
    const { toggleSelectFieldTypePopup, group, addDetailsField, groupsForSelectGroup, hideFormQuestion, itemName } = this.props
    const entityType = itemName === 'app' ? FIELDS_CONTAINER_ENTITY.APPS : FIELDS_CONTAINER_ENTITY.CONTRACTS
    return toggleSelectFieldTypePopup(true, group.id, null, addDetailsField, groupsForSelectGroup, hideFormQuestion, entityType)
  }

  render () {
    const {
      loading,
      group,
      index,
      fields,
      hideFormQuestion,
      allowedScopes,
      onHeaderClick,
      onlyShowHeader,
      isSelected,
      onRowsOrderChanged
    } = this.props
    const { isEditOpen, isDeleteOpen } = this.state
    const editGroupPopup = <InputPopup
      isOpen={isEditOpen}
      header='Edit Group'
      cancelButton={{ label: 'Cancel', onClick: (isClosedByUser) => this.toggleEditGroupState(null, isClosedByUser) }}
      submitButton={{ label: 'Save', onClick: (label) => this.editGroup(label, group) }}
      input={{ label: 'Group Name', fieldName: group.label }}
      showCheckBox={!hideFormQuestion}
    />

    const deleteGroupConfirmation = <Confirmation
      isOpen={isDeleteOpen}
      header={`Delete '${group.label}' group?`}
      confirmText='Delete'
      declineText='Cancel'
      decline={this.toggleDeleteGroupState}
      confirm={this.deleteGroup}
      close={this.toggleDeleteGroupState}
      mainButtonType={ButtonType.destructive}
    />

    const tooltipLabel = group.isPluginGroup === 1 ? `This group was created by the "${group.label}" plugin and cannot be edited.` : 'This group is defined by Torii and cannot be edited.'
    const addField = <div {...Style.addFieldContainer}>
      <Tooltip
        placement='top'
        hide={group.isPluginGroup === 0 && !group.isReadOnly}
        label={<Style.DisabledButtonTooltipLabel>{tooltipLabel}</Style.DisabledButtonTooltipLabel>}
      >
        <EnableFor scopes={allowedScopes}>
          <Button type={ButtonType.compact} size={ButtonSize.small} disabled={group.isPluginGroup || group.isReadOnly} onClick={this.onAddFieldClicked} label='+ Add field' />
        </EnableFor>
      </Tooltip>

    </div>

    const DroggableGroup = ({ disabled }) => (<Draggable
      draggableId={`${group.id}`} index={index} isDragDisabled={disabled}
    >
      {provided => (
        <div
          key={group.label}
          {...css(Style.main, isSelected && Style.selected)}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Table
            tableKey={TABLES.detailsGroupTable.key}
            key={group.id}
            emptyStateMessage={<div {...Style.noFields}>No fields</div>}
            data={fields}
            header={() => this.getHeader(provided.dragHandleProps)}
            onHeaderClick={() => onHeaderClick(group.id)}
            hideTableContent={onlyShowHeader}
            columns={this.columns}
            loading={loading}
            style={{ minHeight: '100px' }}
            getNoDataProps={() => ({ style: { transform: 'none', left: 0, padding: '15px' } })}
            sortable={false}
            overrideTheadThStyle={Style.tableHeader}
            extraHeaderComponent={addField}
            tableHeaderStyle={Style.tableHeaderStyle}
            draggableRows
            onRowsOrderChanged={onRowsOrderChanged}
            scopesForDraggableRows={allowedScopes}
          />
        </div>
      )}
    </Draggable>)

    return (
      <Fragment>
        {isEditOpen && editGroupPopup}
        {isDeleteOpen && deleteGroupConfirmation}
        <EnableFor scopes={allowedScopes}>
          <DroggableGroup />
        </EnableFor>
      </Fragment>
    )
  }
}

export default DetailsGroup
