import React from 'react'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import { css } from 'glamor'
import moment from 'moment'
import get from 'lodash/get'
import map from 'lodash/map'
import { oneLiner } from '../../../shared/style/mixins'

const CSS = {
  main: css({
    width: '235px',
    padding: '12px 16px 18px 16px',
    border: `1px solid ${colors.border}`,
    backgroundColor: colors.white
  }),
  header: css(texts.subheading, {
    color: colors.black,
    textTransform: 'uppercase',
    marginBottom: '3px'
  }),
  categoryRow: css({
    marginBottom: '10px'
  }),
  info: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  categoryName: css(texts.subheading, {
    fontWeight: fontWeight.normal,
    color: colors.text
  }),
  amount: css(texts.subheading, {
    fontWeight: fontWeight.semiBold,
    color: colors.black
  }),
  barContainer: css({
    display: 'flex'
  }),
  bar: css({
    border: '1px solid',
    width: '100%',
    height: '2px'
  }),
  total: css(texts.heading, {
    marginTop: '18px'
  }),
  subHeader: css(oneLiner, {
    marginBottom: '3px',
    fontWeight: fontWeight.normal,
    fontSize: fontSize.small,
    color: colors.grey1
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.grey3}`,
    marginBottom: '8px',
    paddingBottom: '5px'
  })
}

const Tooltip = ({ payload, active, hideOnZeroValue = false, formatter = value => value, fill, data }) => {
  if (!active) {
    return null
  }

  const { date, value: totalAmount, categories, tooltipSubHeader } = get(payload, ['0', 'payload'], {})

  if (hideOnZeroValue && !totalAmount) {
    return null
  }

  return (
    <div {...CSS.main}>
      <div {...CSS.headerContainer}>
        <div {...CSS.header}>{moment.utc(date).format('MMMM YYYY')}</div>
        <div {...CSS.subHeader}>{tooltipSubHeader}</div>
      </div>

      {map(categories, (amount, categoryName) => renderCategory({ categoryName, amount, totalAmount, fill, formatter, date, data }))}
      <div {...css(CSS.info, CSS.total)}>
        <div>Total</div>
        <div>{formatter(totalAmount)}</div>
      </div>
    </div>
  )
}

const renderCategory = ({ categoryName, amount, totalAmount, fill, formatter, date, data }) => {
  const precentage = amount / totalAmount * 100
  const categoriesCount = (data.find(category => category.date === date) || {}).categoriesCount
  const count = (categoriesCount || {})[categoryName]

  return <div {...CSS.categoryRow} key={categoryName}>
    <div {...CSS.info}>
      <div {...CSS.categoryName}>{categoryName} {count && `(${count})`}</div>
      <div {...CSS.amount}>{formatter(amount)}</div>
    </div>
    <div {...CSS.barContainer}>
      <div {...CSS.bar} style={{ width: `${precentage}%`, color: fill }} />
      <div {...CSS.bar} style={{ width: `${100 - precentage}%`, color: colors.grey3 }} />
    </div>
  </div>
}

export default Tooltip
