import { createSelector } from 'reselect'
import get from 'lodash/get'
import { keyBy } from 'lodash'

export const getToriiAdminScopesById = createSelector(
  state => get(state, ['orgs', 'toriiAdminScopes']),
  toriiAdminScopes => keyBy(toriiAdminScopes, 'id')
)

export const getOrgs = createSelector(
  [state => get(state, ['orgs', 'orgs']), getToriiAdminScopesById],
  (orgs, toriiAdminScopes) => {
    return orgs.map(org => {
      return {
        ...org,
        hasWritePermissions: org.idToriiAdminScopes.some(idScope => toriiAdminScopes[idScope].systemKey === 'torii-admin:orgs:write')
      }
    })
  }
)
