import styled from 'styled-components'

export const VERTICAL_FILTER_CLASS_NAME = 'verticalFilterClass'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  background-color: ${({ theme }) => theme.palette.background.primary};
  &:hover {
    border-color: ${({ theme }) => theme.palette.border.primaryHover};
    background-color: ${({ theme }) => theme.palette.background.primaryActive};
  }
`

export const FilterControl = styled.div`
  display: flex;
  height: 40px;
  padding: 8px 16px 0 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const DeleteButtonContainer = styled.div`
  display: none;
  .${VERTICAL_FILTER_CLASS_NAME}:is(:hover) & {
    display: block;
  }
`

export const FilterTitle = styled.div`
  flex: 1 0 0;
  font: ${({ theme }) => theme.typography.font.component03};
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const FilterContainer = styled.div`
  display: flex;
  padding: 8px 16px 16px 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`
