import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getIsBlockThirdPartyAppsEnabled } from '@selectors/org'
import { getConnectedServicesByIdApp } from '@selectors/services'
import { SOURCE_TYPES } from '@root/sourcesConfig'
import { APP_PERMISSIONS } from '@root/constants'

const mapStateToProps = (state) => {
  const isBlockThirdPartyAppsEnabled = getIsBlockThirdPartyAppsEnabled(state)
  const connectedServicesByIdApp = getConnectedServicesByIdApp(state)
  const googleServices = connectedServicesByIdApp[SOURCE_TYPES.google.idApp] ?? []
  const connectedServices = googleServices.filter(service => service.isConnected)
  const connectedServicesWithBlockAccess = connectedServices.filter(service => service.permission === APP_PERMISSIONS.read_write)

  return {
    isBlockThirdPartyAppsEnabled,
    connectedServices,
    connectedServicesWithBlockAccess
  }
}

export default withRouter(connect(mapStateToProps, null)(View))
