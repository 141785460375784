import moment, { MomentZoneOffset } from 'moment-timezone'
import groupBy from 'lodash/groupBy'
import { TimezoneOption } from './types'

const TIMEZONES_TO_FILTER_OUT = ['Australia/Lord_Howe']
export const UTC_TIMEZONE = { value: 'UTC', label: 'UTC', offset: 0 }

const toHours = (totalMinutes: number): string => {
  const absoluteTotalMinutes = Math.abs(totalMinutes)
  const hours = Math.floor(absoluteTotalMinutes / 60)

  return `${totalMinutes <= 0 ? '+' : '-'}${hours}`
}

export const isTimezoneOffsetFullHour = ({ name, offset }: MomentZoneOffset) : boolean => {
  return Boolean(offset % 60 === 0 && !TIMEZONES_TO_FILTER_OUT.includes(name))
}

export const getTimezones = (): TimezoneOption[] => {
  const countries = moment.tz.countries()

  const timezones = countries.map(
    country => moment.tz.zonesForCountry(country, true)
  ).flat()

  const usualTimezones = timezones.filter(isTimezoneOffsetFullHour)

  const groupedTimezones = groupBy(usualTimezones, 'name')
  const uniqueTimezones = Object.values(groupedTimezones).map(timezoneGroup => timezoneGroup[0]).flat()

  uniqueTimezones.sort(
    (a, b) => {
      if (a.offset !== b.offset) {
        return b.offset - a.offset
      }
      return a.name.localeCompare(b.name)
    }
  )

  return [UTC_TIMEZONE, ...uniqueTimezones.map(({ name, offset }) => ({
    value: name,
    label: `UTC${toHours(offset)} ${name}`,
    offset: offset
  }))]
}
