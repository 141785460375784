import send from '@shared/redux-fetch'
import {
  CREATE_SAML_CONFIGURATION,
  GET_SAML_CONFIGURATION,
  UPDATE_SAML_CONFIGURATION,
  DELETE_SAML_CONFIGURATION, CREATE_SAML_CONFIGURATION_BY_SOURCE
} from '@root/constants'

const baseSamlConfigurationUrl = idOrg => `/api/orgs/${idOrg}/samlConfiguration`

export const getSamlConfiguration = ({ idOrg }) =>
  send(GET_SAML_CONFIGURATION, {
    url: baseSamlConfigurationUrl(idOrg)
  })

export const createSamlConfigurationBySource = ({ idOrg, source }) =>
  send(CREATE_SAML_CONFIGURATION_BY_SOURCE, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/samlConfiguration/${source}`
  })

export const createSamlConfiguration = ({ idOrg, idUpload }) =>
  send(CREATE_SAML_CONFIGURATION, {
    method: 'POST',
    url: baseSamlConfigurationUrl(idOrg),
    body: {
      idUpload
    }
  })

export const updateSamlConfiguration = ({ idOrg, allowLoginsViaEmailAndPassword }) =>
  send(UPDATE_SAML_CONFIGURATION, {
    method: 'PUT',
    url: baseSamlConfigurationUrl(idOrg),
    body: {
      allowLoginsViaEmailAndPassword
    },
    meta: {
      allowLoginsViaEmailAndPassword
    }
  })

export const deleteSamlConfiguration = ({ idOrg }) =>
  send(DELETE_SAML_CONFIGURATION, {
    method: 'DELETE',
    url: baseSamlConfigurationUrl(idOrg)
  })
