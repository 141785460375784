import React from 'react'
import texts from '../../../shared/style/texts'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'

const CSS = {
  container: css(texts.subheading, {
    marginTop: '20px',
    color: colors.grey1
  })
}

const Personalization = () => (
  <div {...CSS.container}>
    Use <strong>@</strong> to add personalization tokens and customize your message.
  </div>
)

export default Personalization
