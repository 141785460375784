import styled from 'styled-components'

export const PopoverContent = styled.div`
  max-height: 300px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing('space-150')};
  margin: -16px;
  padding: 8px;
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing('space-050')};
  overflow: auto;
  margin: -8px;
  padding: 8px;
`
export const Option = styled.div`
  padding: 6px 8px;
  border-radius: ${({ theme }) => theme.spacing('space-100')};
  
  &:hover {
    background: ${({ theme }) => theme.palette.background.secondary};
  }
`
