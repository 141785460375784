import styled from 'styled-components'

export namespace Styles {
    export const Image = styled.img`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    `

    export const placeholderStyle = {
      'max-width': '100%',
      width: '787px',
      height: '184px',
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'center',
      margin: '24px auto'
    }
}
