import send from '../../shared/redux-fetch'

import {
  REGISTER,
  GET_EMAIL
} from '../../constants'

export const sendRequest = data =>
  send(REGISTER,
    {
      url: '/api/signup',
      method: 'POST',
      body: data
    }
  )

export const getEmailFromValidationKey = (id, key) =>
  send(GET_EMAIL, {
    url: `/api/signup/email_validation/${id}/${key}`
  })
