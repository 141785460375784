import React from 'react'
import { Grid, Row } from '@toriihq/design-system'
import Widgets from '@root/widgets'

const PluginWidgets = ({ widgets }) => {
  return (
    <Grid>
      <Row style={{ margin: 0 }}>
        <Widgets widgets={widgets} columnsPerWidget={4} />
      </Row>
    </Grid>
  )
}

export default PluginWidgets
