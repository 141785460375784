import View from './view'
import { connect } from 'react-redux'
import { getGlobalActionTypes } from '@selectors/workflows'

const mapStateToProps = state => {
  const globalActionTypes = getGlobalActionTypes(state)

  return {
    globalActionTypes
  }
}

export default connect(mapStateToProps)(View)
