import React from 'react'
import { css } from 'glamor'
import { Icon, Button, ButtonType, Checkbox, Tooltip, Spacer, H4, AlertBox, AlertBoxType, TextBadge, TextArea } from '@toriihq/design-system'
import DatePicker from '../datePicker'
import ToriiPopup from '../popups/ToriiPopupV2'
import FormGroup from '../form/formGroup'
import Placeholder from '../placeholder'
import EnableFor from '../enableFor'
import Confirmation from '@components/confirmation'
import ToriiSelect from '@components/select'
import orderBy from 'lodash/orderBy'
import ToriiToggle from '@components/toriiToggle'
import { PLANS } from '@shared/features'
import Input from '@components/form/input'

const CSS = {
  text: css({
    marginRight: 10
  }),
  toggleAccountText: css({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    marginBottom: 10
  }),
  paidPlanWrapper: css({
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  }),
  list: css({
    listStyleType: 'square'
  }),
  allowOnlyYearlySubscription: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    marginBottom: '10px'
  })
}

const enableOrgWarnMessage = <span>Once you enable the account, customers will be able to access their Torii account.<br />All automated workflows and data syncs will continue where they left off.</span>
const disableOrgWarnMessage = <span>Once you disable the account, customers will no longer be able to access their Torii account. All automated workflows and data syncs will stop. <br />
  Please note that this action is reversible. You will be able to re-enable the account and restore Torii functionality when needed.</span>
const planExpirationTooltipMessage = 'The plan has expired'
const paidPlanExpirationOnDayMessage = 'The plan has expired. Access to the account will be blocked 24 hours after the expiry date.'
const disabledMessage = 'The organization was disabled and data is no longer synced. Customers cannot access the account.'
const planEndMessage = 'Access to the account was blocked for customers. Extend the plan to restore the access.'

class ManageAccount extends React.Component {
  state = {
    isChangeToPaidPopupOpen: false,
    isDomainsPopupOpen: false,
    isEnableDisableOrgPopupOpen: false,
    isDomainChecked: false,
    paidPlanEndTime: null,
    textareaValue: '',
    originalIdPlan: this.props?.orgPlanDetails?.id,
    selectedIdPlan: this.props?.orgPlanDetails?.id,
    isUpdateOrgPlanPopupOpen: false,
    isConfirmDowngradePopupOpen: false,
    confirmDowngradePlanName: null
  }

  componentDidMount () {
    const { getOrgDomains, idOrg, getAllPlans } = this.props

    if (idOrg) {
      getAllPlans()
      getOrgDomains({ idOrg })
    }
  }

  componentDidUpdate (prevProps) {
    const { getOrgDomains, idOrg, orgPlanDetails } = this.props
    if (idOrg && prevProps.idOrg !== idOrg) {
      getOrgDomains({ idOrg })
    }

    if (orgPlanDetails !== prevProps.orgPlanDetails) {
      this.setState({ originalIdPlan: orgPlanDetails.id, selectedIdPlan: orgPlanDetails.id })
    }
  }

  closePopup = () => this.togglePopupOpen(false)
  openPopup = (header) => this.togglePopupOpen(true, header)

  closeDomainsPopup = () => this.setState({ isDomainsPopupOpen: false })

  closeUpdateOrgPlanPopup = () => this.setState({ isUpdateOrgPlanPopupOpen: false })
  closeConfirmDowngradePopup = () => this.setState({ isConfirmDowngradePopupOpen: false })
  openDomainsPopup = () => this.setState({ isDomainsPopupOpen: true, isDomainChecked: false })

  setEnableDisableOrgPopup = (isOpen) => this.setState({ isEnableDisableOrgPopupOpen: isOpen })

  renderPlanAlertBox = () => {
    const { isOrgDisabled, orgHasRestrictions } = this.props
    if (!orgHasRestrictions) {
      return null
    }

    const alertMessage = (isOrgDisabled) ? disabledMessage : planEndMessage
    return (
      <div style={{ marginBottom: 24 }}>
        <AlertBox
          type={AlertBoxType.NOTICE}
          description={alertMessage}
        />
      </div>
    )
  }

  renderTrial = () => {
    const { trialEndTime, updateTrialEndTime, canManageAccount, isTrialExpired } = this.props
    const text = trialEndTime ? 'On trial until' : 'Organization not in trial and not in a paid plan'

    return <>
      <div {...CSS.paidPlanWrapper}>
        <span>{text}</span>
        {trialEndTime ? <DatePicker
          value={trialEndTime}
          onDayChange={updateTrialEndTime}
          disabled={!canManageAccount}
          dayPickerProps={{
            disabledDays: { before: new Date() }
          }}
        /> : null}
        {isTrialExpired ? (
          <Tooltip label={planExpirationTooltipMessage}>
            <Icon name='Alert' color='warning' />
          </Tooltip>)
          : null}
      </div>
      <Spacer top='space-100'>
        <Button onClick={() => this.openPopup('Change account to paid')} label='Change to paid' />
      </Spacer>
    </>
  }

  togglePopupOpen = (isOpen, header = '') => {
    this.setState({ isChangeToPaidPopupOpen: isOpen, header })
  }

  onUpdatePaidPlanEndTime = async () => {
    const { paidPlanEndTime } = this.state
    const { updatePaidPlanEndTime } = this.props
    await updatePaidPlanEndTime(paidPlanEndTime)
    this.closePopup()
  }

  onUpdateOrgPlan = async () => {
    const { allPlans } = this.props
    const { selectedIdPlan, originalIdPlan } = this.state
    const previousPlan = allPlans.find(plan => plan.id === originalIdPlan)
    const plan = allPlans.find(plan => plan.id === selectedIdPlan)

    if (previousPlan.rank >= plan.rank) {
      this.setState({ isConfirmDowngradePopupOpen: true })
    } else {
      await this.onConfirmOrgPlan()
    }
  }

  onConfirmOrgPlan = async () => {
    const { idOrg, updateOrgPlan } = this.props
    const { selectedIdPlan } = this.state

    await updateOrgPlan({ idOrg, idPlan: selectedIdPlan })

    this.closeUpdateOrgPlanPopup()
    this.closeConfirmDowngradePopup()
  }

  onDayChange = (chosenDate) => this.setState({ paidPlanEndTime: chosenDate })

  renderChangeToPaidPopup = () => {
    const { isChangeToPaidPopupOpen, paidPlanEndTime, header } = this.state
    return <ToriiPopup
      isOpen={isChangeToPaidPopupOpen}
      onCloseAction={this.closePopup}
    >
      <ToriiPopup.Header header={header} />
      <ToriiPopup.Content>
        <div>
          <p {...CSS.text}>Enter paid plan end time</p>
          <DatePicker
            onDayChange={this.onDayChange}
            dayPickerProps={{
              disabledDays: { before: new Date() }
            }}
          />
        </div>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonAction={this.onUpdatePaidPlanEndTime}
        mainButtonText='Update'
        showCancelButton={false}
        isMainButtonDisabled={!paidPlanEndTime}
      />
    </ToriiPopup>
  }

  renderUpdateOrgPlanPopup = () => {
    const { allPlans } = this.props
    const { isUpdateOrgPlanPopupOpen, selectedIdPlan } = this.state
    const options = orderBy((allPlans ?? []).map(plan => ({ label: plan.name, value: plan.id })), 'value', 'desc')
    return <ToriiPopup
      isOpen={isUpdateOrgPlanPopupOpen}
      onCloseAction={this.closeUpdateOrgPlanPopup}
    >
      <ToriiPopup.Header header='Update org plan' />
      <ToriiPopup.Content>
        <div>
          <p {...CSS.text}>Select new plan type:</p>
          <ToriiSelect
            value={selectedIdPlan}
            options={options}
            onChange={({ value }) => this.setState({ selectedIdPlan: value })}
            clearable={false}
          />
        </div>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonAction={this.onUpdateOrgPlan}
        mainButtonText='Update'
        showCancelButton={false}
      />
    </ToriiPopup>
  }

  renderConfirmDowngradePopup = () => {
    const { allPlans } = this.props
    const { isConfirmDowngradePopupOpen, selectedIdPlan, confirmDowngradePlanName, originalIdPlan } = this.state
    const previousPlan = allPlans.find(plan => plan.id === originalIdPlan)
    const plan = allPlans.find(plan => plan.id === selectedIdPlan)

    return isConfirmDowngradePopupOpen && <ToriiPopup
      isOpen={isConfirmDowngradePopupOpen}
      onCloseAction={this.closeConfirmDowngradePopup}
    >
      <ToriiPopup.Header header={`Downgrade plan to ${plan.name}`} />
      <ToriiPopup.Content>
        <div>
          {plan.type === PLANS.BASIC &&
          <div>
            <div>Downgrade plan to Basic will perform the following irreversible actions:</div>
            <ul {...CSS.list}>
              <li>Delete finance integration data</li>
              <li>Delete custom  integrations data</li>
              <li>Uninstall plugins</li>
              <li>Change all roles to "Admin"</li>
              <li>Delete all secrets</li>
              <li>Delete all API keys</li>
            </ul>

          </div>
          }
          {plan.type === 'professional' && previousPlan.rank >= plan.rank &&
          <div>
            <div>Downgrade plan to Professional will perform the following irreversible actions:</div>
            <ul {...CSS.list}>
              <li>Delete custom integrations data</li>
              <li>Delete all custom roles to "Read only"</li>
            </ul>
          </div>
          }

          <div>Type {plan.name.toUpperCase()} in the text box below to continue:</div>
          <Input onChange={(e) => this.setState({ confirmDowngradePlanName: e.target.value })} />

        </div>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonAction={this.onConfirmOrgPlan}
        mainButtonText='Downgrade'
        mainButtonType={ButtonType.destructive}
        isMainButtonDisabled={plan.name.toUpperCase() !== confirmDowngradePlanName}
        showCancelButton
        cancelButtonText='Cancel'
      />
    </ToriiPopup>
  }

  renderDomainsPopup = () => {
    const { isDomainsPopupOpen, isDomainChecked } = this.state
    const { domains = [] } = this.props

    return <ToriiPopup
      isOpen={isDomainsPopupOpen}
      onCloseAction={this.closeDomainsPopup}
    >
      <ToriiPopup.Header header='Domains' />
      <ToriiPopup.Content>
        <div>
          <p>Add the non-primary domains, one per line</p>
          <p>
            <TextArea
              placholder='One domain per line'
              defaultValue={domains.join('\n')}
              onBlur={(e) => this.setState({ textareaValue: e.target.value })}
            />
          </p>
          <Checkbox
            label='I have verified that all domains legally belong to this customer'
            onChange={() => this.setState({ isDomainChecked: !this.state.isDomainChecked })}
            checked={isDomainChecked}
          />
        </div>
      </ToriiPopup.Content>

      <ToriiPopup.Footer
        mainButtonAction={this.onUpdateOrgDomains}
        mainButtonText='Update'
        showCancelButton={false}
        isMainButtonDisabled={!isDomainChecked}
      />
    </ToriiPopup>
  }

  renderDisableEnableOrgPopup = () => {
    const { isEnableDisableOrgPopupOpen } = this.state
    const { isOrgDisabled } = this.props
    return (
      <Confirmation
        isOpen={isEnableDisableOrgPopupOpen}
        header={`${isOrgDisabled ? 'Enable' : 'Disable'} Account?`}
        text={isOrgDisabled ? enableOrgWarnMessage : disableOrgWarnMessage}
        confirmText={`${isOrgDisabled ? 'Enable' : 'Disable'} Account`}
        declineText='No'
        confirm={this.onConfirmDisableOrEnableOrg}
        decline={() => this.setEnableDisableOrgPopup(false)}
        close={() => this.setEnableDisableOrgPopup(false)}
        mainButtonType={ButtonType.destructive}
      />
    )
  }

  renderOrgStatus = () => {
    const { isOrgDisabled } = this.props

    const text =
    isOrgDisabled
      ? 'Organization is disabled'
      : 'Organization is enabled'
    const buttonText = isOrgDisabled ? 'Enable organization' : 'Disable organization'
    const icon = isOrgDisabled ? <Icon name='Danger' color='error' /> : null

    return (
      <>
        <div {...CSS.toggleAccountText}>
          {text}
          {icon}
        </div>
        <Button
          type={isOrgDisabled ? ButtonType.primary : ButtonType.destructive}
          onClick={() => this.setEnableDisableOrgPopup(true)}
          label={buttonText}
        />
      </>
    )
  }

  onUpdateOrgDomains = async () => {
    const { textareaValue } = this.state
    const { idOrg, updateOrgDomains } = this.props
    const domains = textareaValue.split('\n').filter(Boolean)
    await updateOrgDomains({ idOrg, domains })
    this.closeDomainsPopup()
  }

  onConfirmDisableOrEnableOrg = async () => {
    const { idOrg, isOrgDisabled, updateOrgIsDisabled } = this.props
    await updateOrgIsDisabled({ idOrg, isDisabled: !isOrgDisabled })
    this.setEnableDisableOrgPopup(false)
  }

  renderPaidAccount = () => {
    const { paidPlanEndTime, isPaidPlanExpired, isPaidPlanExpiredAfterDelay } = this.props
    const alertIcon = (isPaidPlanExpired || isPaidPlanExpiredAfterDelay) ? (
      <Tooltip
        label={isPaidPlanExpiredAfterDelay ? planExpirationTooltipMessage : paidPlanExpirationOnDayMessage}>
        <Icon name='Alert' color='warning' />
      </Tooltip>)
      : null
    return <div>
      <p {...CSS.paidPlanWrapper}>Paid plan until {paidPlanEndTime} {alertIcon}</p>
      <p>
        <Button onClick={() => this.openPopup('Extend paid plan')} label='Extend paid plan' />
      </p>
    </div>
  }

  onOrgAllowOnlyYearlySubscriptionChange = async () => {
    const { idOrg, updateOrgAllowOnlyYearlySubscription, orgAllowOnlyYearlySubscription } = this.props
    updateOrgAllowOnlyYearlySubscription({ idOrg, allowOnlyYearlySubscription: !orgAllowOnlyYearlySubscription })
  }

  renderPricingPlan = () => {
    const { allPlans, orgPlanDetails, orgAllowOnlyYearlySubscription } = this.props
    const { selectedIdPlan } = this.state
    const plan = allPlans.find(plan => plan.id === selectedIdPlan)
    const name = orgPlanDetails?.name ?? ''

    return <div>
      <p>Pricing plan: {name}</p>
      {
        (plan?.type === PLANS.BASIC) && <div {...CSS.allowOnlyYearlySubscription}>
          Yearly subscription only (monthly not allowed)
          <ToriiToggle
            checked={orgAllowOnlyYearlySubscription}
            onToggle={this.onOrgAllowOnlyYearlySubscriptionChange}
          />
        </div>
      }
      <Button
        onClick={() => this.setState({ isUpdateOrgPlanPopupOpen: true })}
        label='Update plan'
      />
    </div>
  }

  render () {
    const { canManageAccount, isTrial, isPaidAccount, primaryDomain, domains, isDomainsLoaded } = this.props
    if (!canManageAccount) {
      return null
    }

    const noPlan = !isTrial && !isPaidAccount

    return <div>
      <Spacer bottom={'space-300'}><H4>Account Settings</H4></Spacer>
      <div style={{ marginBottom: 24 }}>
        <AlertBox
          type={AlertBoxType.INFORMATIVE}
          description='This page is internal and visible only for Torii admins'
        />
      </div>
      {this.renderPlanAlertBox()}
      <FormGroup label='Domains'>
        <Placeholder loading={!isDomainsLoaded} type='text' rows={2} style={{ height: '40px', width: '50px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div><TextBadge color='green' size='Small'>{primaryDomain}</TextBadge></div>
            {domains.map(domain => <div key={domain}><TextBadge color='gray' size='Small'>{domain}</TextBadge></div>)}

            <Spacer top='space-100'>
              <EnableFor scopes={[]} allowForToriiAdmin>
                <Button onClick={this.openDomainsPopup} label='Update' />
              </EnableFor>
            </Spacer>
          </div>
        </Placeholder>
      </FormGroup>

      <FormGroup label='Paid Plan / Trial'>
        {(isTrial || noPlan) && this.renderTrial()}
        {isPaidAccount && this.renderPaidAccount()}
      </FormGroup>

      <FormGroup label='Pricing plan'>
        {this.renderPricingPlan()}
      </FormGroup>

      <FormGroup label='Organization status'>
        {this.renderOrgStatus()}
      </FormGroup>

      {this.renderChangeToPaidPopup()}
      {this.renderDomainsPopup()}
      {this.renderDisableEnableOrgPopup()}
      {this.renderUpdateOrgPlanPopup()}
      {this.renderConfirmDowngradePopup()}
    </div>
  }
}

export default ManageAccount
