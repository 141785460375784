import React from 'react'
import PropTypes from 'prop-types'
import Confirmation from '@components/confirmation'
import { ButtonType } from '@toriihq/design-system'

const ConfirmationPopup = (props) => {
  const {
    header = 'Quit Unsaved Work?',
    message = 'Closing this window will cancel any changes you made.',
    isOpen
  } = props
  const {
    stayButtonAction = () => {},
    stayButtonText = 'Stay',
    quitButtonAction = () => {},
    quitButtonText = 'Quit'
  } = props

  return (
    isOpen && <Confirmation
      isOpen={isOpen}
      header={header}
      text={message}
      confirmText={quitButtonText}
      declineText={stayButtonText}
      confirm={quitButtonAction}
      decline={stayButtonAction}
      close={stayButtonAction}
      mainButtonType={ButtonType.destructive}
    />
  )
}

ConfirmationPopup.propTypes = {
  header: PropTypes.node,
  message: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  stayButtonText: PropTypes.string,
  stayButtonAction: PropTypes.func,
  quitButtonText: PropTypes.string,
  quitButtonAction: PropTypes.func
}

export default ConfirmationPopup
