import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import pluralize from 'pluralize'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import UsersIconsList from '../../usersIconsList'
import texts from '../../../shared/style/texts'

const CSS = {
  message: css({
    color: colors.black,
    fontSize: 12,
    margin: '5px 0 0 0'
  }),
  subHeader: css(texts.body, {
    lineHeight: '7px',
    color: colors.black
  }),
  subHeaderList: css({
    margin: '10px 0px 0px -10px'
  })
}

class OffboardingStartedPopup extends Component {
  viewInProgress = () => {
    const { history, idOrg, close } = this.props
    history.push(`/team/${idOrg}/offboarding/progress`)
    close()
  }

  render () {
    const { isOpen, cancel, users } = this.props

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width='500px'>
        <ToriiPopup.Header header={'Offboarding started'} />
        <ToriiPopup.Content>
          <div {...CSS.subHeader}>{`Offboarding started for ${pluralize('employee', users.length, true)}`}</div>
          <div {...CSS.subHeaderList}><UsersIconsList users={users} maxUsers={5} /></div>
          <div {...CSS.message}>
            <span>You can always change and configure offboarding<br /> methods later on.</span>
          </div>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          cancelButtonText='Close'
          mainButtonText='View offboarding progress'
          mainButtonAction={this.viewInProgress}
        />
      </ToriiPopup>
    )
  }
}

export default OffboardingStartedPopup
