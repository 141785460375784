import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Button, ButtonType, RadioButton, Popover, ButtonSize } from '@toriihq/design-system'
import ToriiSelect from '../../select'
import FormField from '../../workflows/formField'
import { Form, Field } from 'react-final-form'
import mapKeys from 'lodash/mapKeys'
import { fontSize } from '../../../shared/style/sizes'
import EnableFor from '../../enableFor'
import { TABLES } from '../../../constants'
import pluralize from 'pluralize'
import Analytics from '../../../helpers/analytics'
import SubmitButton from '@components/submitButton'
import Confirmation from '@components/confirmation'

const CSS = {
  popoverOverrideStyle: css({
    width: 230,
    fontSize: fontSize.small
  }),
  radioLabel: css({
    marginLeft: 5,
    fontSize: fontSize.small,
    cursor: 'pointer'
  }),
  title: css({
    marginBottom: 11
  }),
  radioGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '24px 0'
  }),
  updateButton: css({
    display: 'flex',
    justifyContent: 'center'
  }),
  field: css({
    maxWidth: '100%',
    width: '100%',
    minWidth: 'unset',
    ' div': {
      width: '100%'
    },
    ' .DayPickerInput': {
      width: '100%'
    },
    ' .DayPickerInput > input': {
      width: '100%'
    }
  }),
  separator: css({
    height: 15
  }),
  center: css({
    display: 'flex',
    justifyContent: 'center'
  })
}

class FieldComponent extends React.Component {
  render () {
    const { field, input } = this.props
    return FormField.mapping[field.type] && FormField.mapping[field.type].component({ field, input, overrideStyle: CSS.field, useSelectState: true })
  }
}

const FIELD_PREFIX = 'field-'

class TableBulkEdit extends React.Component {
  state = { selectedValue: null, selectedMethod: 'update', isConfirmDeleteOpen: false }

  onSubmit = async (data) => {
    const { selectedItems, clearSelection, onSubmit, tableKey } = this.props
    data = mapKeys(data, function (value, key) {
      return key.replace(FIELD_PREFIX, '')
    })
    Analytics.track('Bulk edit field', {
      'Table': (TABLES[tableKey] || {}).name,
      'Field': Object.keys(data)[0]
    })
    await onSubmit(data, selectedItems)
    clearSelection()
  }

  onDeleteField = async () => {
    const { fields, fieldKey } = this.props
    const { selectedValue } = this.state
    const selectedField = (selectedValue && fields.find(field => field[fieldKey] === selectedValue[fieldKey])) || fields[0]
    return this.onSubmit({ [selectedField[fieldKey]]: null })
  }

  showDeleteConfirmation = () => {
    const { tableKey } = this.props
    Analytics.track('Clicked bulk delete', {
      'Table': (TABLES[tableKey] || {}).name
    })
    this.setState({ isConfirmDeleteOpen: true })
  }
  closeDeleteConfirmation = () => this.setState({ isConfirmDeleteOpen: false })

  onDeleteItems = async () => {
    const { selectedItems, onDelete, tableKey, clearSelection } = this.props
    Analytics.track('Bulk delete', {
      'Table': (TABLES[tableKey] || {}).name
    })
    await onDelete(selectedItems)
    clearSelection()
    this.closeDeleteConfirmation()
  }

  renderField = (field) => {
    if (!field) {
      return null
    }

    const { selectedItems, users, itemsName, fieldName, fieldKey, allowedScopes } = this.props
    const typeMapping = FormField.mapping[field.type] || {}
    const validate = typeMapping.validators
    return <Form
      onSubmit={this.onSubmit}
      render={(formProps) => {
        const { handleSubmit } = formProps
        this.formReset = formProps.form.reset
        return <form autoComplete='off' onSubmit={handleSubmit}>
          <div>
            <Field
              index={0}
              validate={validate}
              key={field[fieldKey]}
              name={`${FIELD_PREFIX}${field[fieldName]}`}
              field={field}
              users={users}
              component={FieldComponent}
              format={typeMapping.format}
              parse={typeMapping.parse}
            />
          </div>
          <div>
            <hr />
            <div {...CSS.updateButton}>
              <EnableFor scopes={allowedScopes}>
                <SubmitButton form={formProps} label={`Update ${pluralize(itemsName, selectedItems.length, true)}`} />
              </EnableFor>
            </div>
          </div>
        </form>
      }}
    />
  }
  onChange = selected => {
    this.formReset && this.formReset()
    const update = { selectedValue: selected }
    if (selected && selected.disableDelete) {
      update.selectedMethod = 'update'
    }
    this.setState(update)
  }

  onEdit = ({ isOpen }) => {
    const { tableKey } = this.props
    isOpen && Analytics.track('Clicked bulk edit', {
      'Table': (TABLES[tableKey] || {}).name
    })
  }

  setUpdate = () => this.setState({ selectedMethod: 'update' })
  setDelete = () => this.setState({ selectedMethod: 'delete' })

  render () {
    const { fields, selectedItems, itemsName, fieldKey, showDeleteButton, allowedScopes = [] } = this.props
    const { selectedValue, selectedMethod, isConfirmDeleteOpen } = this.state

    const selectedField = (selectedValue && fields.find(field => field[fieldKey] === selectedValue[fieldKey])) || fields[0]
    const deleteEnabled = !selectedField.disableDelete

    return (<>
      {showDeleteButton && <EnableFor scopes={allowedScopes}>
        <Button type={ButtonType.secondary} onClick={this.showDeleteConfirmation} label={`Delete ${pluralize(itemsName, selectedItems.length, true)}`} />
      </EnableFor>}
      <EnableFor scopes={allowedScopes}>
        <Confirmation
          isOpen={isConfirmDeleteOpen}
          header='Delete confirmation'
          text={<div>Are you sure you want to delete {pluralize(itemsName, selectedItems.length, true)}?</div>}
          confirmText='Delete'
          declineText='Cancel'
          confirm={this.onDeleteItems}
          decline={this.closeDeleteConfirmation}
          close={this.closeDeleteConfirmation}
          mainButtonType={ButtonType.destructive}
        />
      </EnableFor>
      <Popover
        position='bottom'
        align='end'
        openOnClick
        onToggle={this.onEdit}
        content={<div {...CSS.popoverOverrideStyle}>
          <div {...CSS.title}>Select field</div>
          <ToriiSelect
            options={fields}
            placeholder='Select Field'
            valueKey={fieldKey}
            labelKey='name'
            onChange={this.onChange}
            clearable={false}
            value={selectedValue}
          />
          {deleteEnabled
            ? <div {...CSS.radioGroup}>
              <RadioButton checked={selectedMethod === 'update'} onChange={this.setUpdate} label='Update value' labelSize='small' />
              <RadioButton checked={selectedMethod === 'delete'} onChange={this.setDelete} label='Delete value' labelSize='small' />
            </div>
            : <div {...CSS.separator} />
          }
          <div>
            {selectedMethod === 'update' && this.renderField(selectedField)}
            {selectedMethod === 'delete' && <div {...CSS.center}>
              <EnableFor scopes={allowedScopes}>
                <Button type={ButtonType.destructive} onClick={this.onDeleteField} label={`Delete value for ${pluralize(itemsName, selectedItems.length, true)}`} />
              </EnableFor>
            </div>}
          </div>
        </div>}
      >
        <EnableFor scopes={allowedScopes}>
          <Button
            size={ButtonSize.small}
            type={ButtonType.primary}
            label='Edit'
            onClick={() => null}
          />
        </EnableFor>
      </Popover>
    </>
    )
  }
}

TableBulkEdit.propTypes = {
  tableKey: PropTypes.string,
  clearSelection: PropTypes.func
}

TableBulkEdit.defaultProps = {
  clearSelection: () => {}
}

export default TableBulkEdit
