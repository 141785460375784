import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { formFieldTypes } from '@root/constants'
import orderBy from 'lodash/orderBy'
import { getAppDetailsFields as getAppDetailsFieldsSelector } from '@selectors/apps'

const DEFUALT_FIELDS = [
  { systemKey: 'description', name: 'Description', type: formFieldTypes.multiLine },
  { systemKey: 'activeUsersCount', name: 'Users', type: formFieldTypes.number },
  { systemKey: 'owner', name: 'Owner', type: formFieldTypes.usersDropdown },
  { systemKey: 'tags', name: 'Tags', type: 'tags' }
]

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const validFieldTypes = [formFieldTypes.singleLine, formFieldTypes.multiLine, formFieldTypes.number, formFieldTypes.datePicker, formFieldTypes.dropdown, formFieldTypes.dropdownMulti, formFieldTypes.usersDropdown, formFieldTypes.currency, formFieldTypes.usersDropdownMulti]
  const fields = getAppDetailsFieldsSelector(state)
  const filteredFields = fields.filter(detail => detail.isShown)

  const fieldsOptions = filteredFields
    .filter(field => validFieldTypes.includes(field.type)).concat(DEFUALT_FIELDS)
    .map(field => ({ value: field.systemKey, systemKey: field.systemKey, label: field.name, type: field.type }))
  const sortedFieldsOptions = (fieldsOptions.length > 0 && orderBy(fieldsOptions, 'label')) || []

  return {
    idOrg,
    options: sortedFieldsOptions
  }
}

export default withRouter(connect(mapStateToProps)(View))
