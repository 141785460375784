import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Table from '../table'
import { TABLES, SCOPES } from '../../constants'
import RoleActions from './roleActions'
import { FEATURES } from '@root/shared/features'
import { H4 } from '@toriihq/design-system'

const CSS = {
  row: css({}),
  actions: css({
    textAlign: 'right'
  }),
  description: css({
    whiteSpace: 'pre-wrap'
  })
}

CSS.hoverActions = css({
  opacity: 0,
  [`.${CSS.row}:hover &, [data-${CSS.row}]:hover &`]: {
    opacity: 1
  }
})

const setTrGroupStyle = () =>
  ({
    className: CSS.row.toString()
  })

class Roles extends React.Component {
  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  fetchData () {
    const { idOrg, getRoles } = this.props

    getRoles({ idOrg })
  }

  getHeader = () => {
    const { roles } = this.props

    return <H4>Roles ({roles.length})</H4>
  }

  getColumns () {
    const { isSmallScreen } = this.props
    return [
      {
        Header: 'Role',
        accessor: 'name',
        maxWidth: 250
      },
      {
        Header: 'Users',
        accessor: 'usersCount',
        maxWidth: 150
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ row: { description } }) => (
          <div {...CSS.description}>{description}</div>
        )
      },
      {
        id: 'action',
        Header: '',
        maxWidth: 120,
        sortable: false,
        resizable: false,
        className: css(CSS.actions, !isSmallScreen && CSS.hoverActions).toString(),
        Cell: ({ row: { id, name, idOrg, usersCount } }) => <RoleActions idRole={id} name={name} idOrg={idOrg} usersCount={usersCount} />
      },
      {
        accessor: 'idOrg',
        show: false
      },
      {
        accessor: 'id',
        show: false
      }
    ]
  }

  render () {
    const { roles, loading, toggleRolePopup } = this.props

    const customButton = {
      onClick: () => toggleRolePopup({ isOpen: true }),
      text: 'Add role',
      scopes: [SCOPES.MEMBERS_AND_ROLES_WRITE],
      feature: FEATURES.SETTINGS.TABS.ROLES.COMPONENTS.CUSTOM_ROLES
    }

    return (
      <Table
        tableKey={TABLES.rolesTable.key}
        sortable={false}
        getTrGroupProps={setTrGroupStyle}
        data={roles}
        columns={this.getColumns()}
        header={this.getHeader}
        loading={loading}
        customButton={customButton}
      />
    )
  }
}

Roles.propTypes = {
  isSmallScreen: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    usersCount: PropTypes.number,
    description: PropTypes.string
  })),
  idOrg: PropTypes.number.isRequired
}

export default Roles
