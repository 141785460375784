import { omit } from 'lodash'
import { WorkflowActionsModel, Action, WORKFLOW_NODE_TYPE, WorkflowActionModel } from '../../types'

export const createActionsWithASingleAction = (action: WorkflowActionModel): WorkflowActionsModel => {
  const sanitizedAction: Action = omit(action, 'id')
  const idRootNode = '1'

  return {
    idRootNode,
    nodes: {
      [idRootNode]: {
        type: WORKFLOW_NODE_TYPE.ACTION,
        next: null,
        action: sanitizedAction
      }
    }
  }
}
