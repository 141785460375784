import React, { Component } from 'react'
import ToriiSelect from '../select'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import { Spacer, AppIcon } from '@toriihq/design-system'
import { oneLiner } from '../../shared/style/mixins'
import colors from '../../shared/style/colors'
import { fontSize, fontWeight } from '../../shared/style/sizes'
import identity from 'lodash/identity'
import isUndefined from 'lodash/isUndefined'

const HEIGHT = '32px'

const CSS = {
  app: css({
    display: 'flex',
    alignItems: 'center',
    ' > span': css(oneLiner, {
      fontSize: fontSize.small,
      fontWeight: fontWeight.semiBold,
      color: colors.darkText,
      lineHeight: HEIGHT,
      width: 'unset'
    })
  }),
  disabledApp: css({
    cursor: 'not-allowed',
    ' > span': css(oneLiner, {
      color: colors.text,
      lineHeight: HEIGHT
    })
  }),
  badge: css({
    borderRadius: '2px',
    padding: '2px 3px',
    color: colors.white,
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.bold,
    textTransform: 'uppercase',
    margin: '0 4px'
  })
}

const SelectContract = ({ item, app }) => {
  const name = item.name === app.name || item.name.startsWith(app.name) ? item.name : `${app.name} - ${item.name}`
  return <div {...CSS.app}>
    <Spacer right={'space-100'}>
      <AppIcon size='small' appName={app.name} appImageUrl={app.imageUrl} />
    </Spacer>
    <span style={{ width: 'unset' }}>{name}</span>
  </div>
}

class SelectContracts extends Component {
  state = {
    selectedContract: null
  }

  componentDidMount () {
    const { idOrg, getContracts } = this.props
    getContracts({ idOrg })
  }

  onChange = async (selectedContract) => {
    const { onChange } = this.props
    this.setState({ selectedContract })
    onChange && onChange(selectedContract)
  }

  renderContract = ({ data: item }) => {
    const { apps } = this.props
    return <SelectContract item={item} app={apps[item.idApp]} />
  }

  render () {
    const { value, placeholder, contracts, ...restSelectParams } = this.props
    const { selectedContract } = this.state

    return (
      <ToriiSelect
        optionRenderer={this.renderContract}
        valueRenderer={this.renderContract}
        valueKey='id'
        labelKey='name'
        placeholder={placeholder}
        maxItemsToShow={10}
        backspaceRemoves={false}
        clearable={false}
        autoBlur
        filterOptions={identity}
        noResultsText='No contract found'
        cache={false}
        loadingPlaceholder={'Loading...'}
        {...restSelectParams}
        value={isUndefined(value) ? selectedContract : value}
        onChange={this.onChange}
        options={contracts}
      />
    )
  }
}

SelectContracts.propTypes = {
  placeholder: PropTypes.string
}

SelectContracts.defaultProps = {
  placeholder: 'Select Contract...'
}

export default SelectContracts
