import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontSize } from '@shared/style/sizes'

export const Container = css({
  position: 'relative',
  height: '184px'
})

export const ImageContainer = css({
  border: `2px dashed ${colors.border}`,
  borderRadius: '8px',
  textAlign: 'center',
  height: '184px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const Image = css({
  maxWidth: '329px',
  maxHeight: '131px'
})

export const IconsContainer = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  right: 0,
  margin: '5px',
  gap: 8
})

export const Hidden = css({
  display: 'none'
})

export const Icon = css({
  padding: '5px',
  color: colors.blue,
  fontSize: fontSize.medium
})
