import send from '@shared/redux-fetch'
import {
  GET_ALL_PLANS
} from '@root/constants'
export const getAllPlans = () => {
  return send(GET_ALL_PLANS, {
    url: `/api/plans`,
    method: 'GET'
  })
}
