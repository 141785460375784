import fullstory from './fullstory'
import churnZero from './churnzero'
import segment from './segment'
import ga4 from './ga4'
import sentry from './sentry'
import send from '../shared/redux-fetch'
import { REPORT_TORII_USAGE } from '../constants'
import { getSourceByType } from '../sourcesConfig'
import config from '@root/config'

let currentMe
const identify = me => {
  if (!me || !me.id) {
    return
  }
  if (currentMe && currentMe.id === me.id) {
    return
  }
  currentMe = me
  const name = me.firstName + ' ' + me.lastName
  const idRole = me.idRole

  fullstory && fullstory.identify(me.id, {
    displayName: name,
    email: me.email,
    first_name: me.firstName,
    last_name: me.lastName,
    time_created: new Date(me.creationTime),
    idRole
  })

  let options = {
    integrations: {
      All: true,
      Intercom: false,
      Zendesk: false
    }
  }

  if (me.isAdmin || me.isAppOwner) {
    options = {
      integrations: {
        All: true,
        Intercom: {
          user_hash: me.intercomUserHash,
          hideDefaultLauncher: true
        }
      }
    }
  }

  options.integrations = { ...options.integrations, ...config.segment.orgsOverrideIntegrationConfig[me.idOrg] }

  segment && segment.identify(me.id, {
    createdAt: new Date(me.creationTime),
    email: me.email,
    name: name,
    firstName: me.firstName,
    lastName: me.lastName,
    company: {
      id: me.idOrg,
      name: me.org && me.org.companyName,
      createdAt: me.org && me.org.creationTime && new Date(me.org.creationTime)
    },
    isAdmin: me.isAdmin,
    idRole,
    isAppOwner: me.isAppOwner
  }, options)

  segment && segment.group(me.idOrg, {
    name: me.org?.companyName,
    companyName: me.org?.companyName,
    isOnboarded: Boolean(me.org?.isOnboarded),
    isDisabled: Boolean(me.org?.isDisabled),
    trialEndTime_at: me.org?.trialEndTime,
    paidPlanEndTime_at: me.org?.paidPlanEndTime,
    plan: me.org?.plan?.name,
    domainNames: me.org?.domain,
    idUser: me.id,
    toriiWebsiteUrl: `${config.appBaseUrl}/team/${me.idOrg}`
  })

  sentry && sentry.identify({ ...me })

  reportToriiUsage()
}

const reportToriiUsage = () => {
  if (!currentMe || !currentMe.idOrg) {
    return
  }

  const dispatch = () => {}
  const { idOrg } = currentMe
  const usageLog = [
    {
      idApp: getSourceByType('torii').idApp,
      time: new Date(),
      hostname: window.location.hostname
    }
  ]

  send(REPORT_TORII_USAGE, {
    url: `/api/orgs/${idOrg}/extension/apps/usage_log`,
    method: 'POST',
    body: {
      usageLog,
      source: 'torii'
    }
  })(dispatch)
}

const pageView = (pathname) => {
  segment && segment.page()
  churnZero && churnZero.page(pathname)
  reportToriiUsage()
}

const track = (event, props = {}) => {
  segment && segment.track(event, props)
  ga4 && ga4.track(event, props)
}

const alias = (id) => {
  segment && segment.alias(id)
}

const exported = {
  identify,
  pageView,
  track,
  alias
}

export default exported
