import {
  AZURE_AD_ID_APP,
  GOOGLE_ID_APP
} from '@components/popups/configureMergeUsersRulesPopup/stepConfig/selectRule/ruleSelector/constants'

export const getSelectedRuleIdApp = ({ rulesDataByIdApp, selectedRuleIdApp }): number | undefined => {
  if (selectedRuleIdApp) {
    return selectedRuleIdApp
  }

  if (rulesDataByIdApp[GOOGLE_ID_APP].isEnabled) {
    return GOOGLE_ID_APP
  }

  return rulesDataByIdApp[AZURE_AD_ID_APP].isEnabled
    ? AZURE_AD_ID_APP
    : undefined
}
