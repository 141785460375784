import React from 'react'
import { EMPTY_ARRAY, TORII_APP_ID, WORKFLOW_STEP_TYPES } from '@root/constants'
import { TriggerStepConfiguration, Workflow } from '@shared/workflows/types'
import {
  getTriggersByType,
  getWorkflowTriggerDynamicFieldsOption,
  getWorkflowTriggerDynamicFieldsOptionsValues
} from '@selectors/workflows'
import {
  getTriggerPreview
} from '@actions/'
import {
  updateWorkflowLocally,
  updateWorkflow,
  clearTriggerFieldOptions
} from '@actions/workflows'
import { WORKFLOW_CHANGE_TYPE } from '@components/workflows/types'
import { useDispatch, useSelector } from 'react-redux'
import TriggerStepSetup from './triggerStepSetup'
import { useParams } from 'react-router'
import { useTriggerConfiguration } from '../hooks/useTriggerConfiguration'
import { TriggerStep } from '../hooks/useTriggerConfiguration/types'
import { Change, SchedulesChange } from './types'
import { EmptyTriggerStep } from './emptyTriggerStep'
import { TriggerChangeParams } from './triggerStepSetup/types'
import { isEqual } from 'lodash'
import { clearTriggerConfigurationFields } from './utils'

interface Props {
  workflow: Workflow
}

const updateTriggerFieldValue = (triggerConfiguration, selectedValue, fieldId) => {
  if (triggerConfiguration && triggerConfiguration.find(field => field.id === fieldId)) {
    return triggerConfiguration.map(field => field.id === fieldId ? { ...field, value: selectedValue } : field)
  } else {
    return [ ...triggerConfiguration, { id: fieldId, value: selectedValue } ]
  }
}

const clearTriggerConfiguration = (triggerConfiguration) => {
  return triggerConfiguration.map(field => ({ ...field, value: null }))
}

const isValueChanged = ({
  triggerConfiguration,
  selectedValue,
  fieldId
}: {
  triggerConfiguration: TriggerStepConfiguration[],
  selectedValue: any,
  fieldId: string
}): boolean => {
  const oldField = triggerConfiguration.find(field => field.id === fieldId)
  return !isEqual(oldField?.value, selectedValue)
}

export const TriggerConfiguration = ({ workflow }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { idOrg, idWorkflow } = useParams()
  const { triggerSteps } = useTriggerConfiguration({ workflow })

  const triggerTypes = useSelector(getTriggersByType)
  const dynamicFieldsOptionsByTriggerId = useSelector(getWorkflowTriggerDynamicFieldsOption)
  const dynamicFieldsOptions = dynamicFieldsOptionsByTriggerId[workflow.id] || EMPTY_ARRAY
  const dynamicFieldsOptionsValuesByTriggerId = useSelector(getWorkflowTriggerDynamicFieldsOptionsValues)
  const dynamicFieldsOptionsValues = dynamicFieldsOptionsValuesByTriggerId[workflow.id] || EMPTY_ARRAY

  const triggerConfig = triggerTypes[workflow.triggerType!]
  const configuredWorkflowTrigger = {
    id: idWorkflow,
    type: workflow.triggerType,
    fields: workflow.triggerConfiguration,
    idApp: TORII_APP_ID
  }

  const getTriggerStepSetup = () => {
    const stepSetup = triggerSteps.find(step => step.type === WORKFLOW_STEP_TYPES.STEP_SETUP)

    return stepSetup
  }

  const getEnrichedStepSetupFields = (stepSetup: TriggerStep): any[] => {
    return (stepSetup.fields || EMPTY_ARRAY).map(fieldId => {
      const enrichedStepField = {
        ...triggerTypes[workflow.triggerType!].inputSchema[fieldId],
        ...workflow.triggerConfiguration.find(field => field.id === fieldId)
      }

      return {
        ...enrichedStepField,
        options: enrichedStepField.options || (dynamicFieldsOptions.find(f => f.id === fieldId) || {}).options,
        loading: dynamicFieldsOptionsByTriggerId.loading,
        values: (dynamicFieldsOptionsValues.find(f => f.id === fieldId) || {}).values,
        valuesLoading: dynamicFieldsOptionsValues.loading
      }
    })
  }

  const onChange = ({
    data,
    locallyOnly = false,
    clearAllFields = false,
    fieldsToClearOnChange
  }: TriggerChangeParams) => {
    if ((data as SchedulesChange).changeType === WORKFLOW_CHANGE_TYPE.SCHEDULES) {
      const { schedules } = data as SchedulesChange
      const updatedWorkflowData = {
        idOrg,
        idWorkflow: workflow.id,
        workflow: { ...workflow, schedules }
      }

      return dispatch(updateWorkflow(updatedWorkflowData))
    } else {
      const { selectedValue, fieldId } = data as Change
      const { triggerConfiguration } = workflow

      let updatedTriggerConfiguration: TriggerStepConfiguration[]
      if (clearAllFields) {
        updatedTriggerConfiguration = clearTriggerConfiguration(triggerConfiguration)
      } else {
        updatedTriggerConfiguration = updateTriggerFieldValue(triggerConfiguration, selectedValue, fieldId)

        if (fieldsToClearOnChange?.length) {
          const isChanged = isValueChanged({
            triggerConfiguration,
            selectedValue,
            fieldId
          })

          if (isChanged) {
            updatedTriggerConfiguration = clearTriggerConfigurationFields(
              updatedTriggerConfiguration,
              fieldsToClearOnChange
            )
            dispatch(clearTriggerFieldOptions({
              fieldIds: fieldsToClearOnChange,
              idWorkflow: workflow.id
            }))
          }
        }
      }

      const updateWorkflowData = { idOrg, idWorkflow: workflow.id, workflow: { ...workflow, triggerConfiguration: updatedTriggerConfiguration } }

      return locallyOnly ? dispatch(updateWorkflowLocally(updateWorkflowData)) : dispatch(updateWorkflow(updateWorkflowData))
    }
  }

  const onGetTriggerPreview = (params: { idOrg: number, idWorkflow: number}) => dispatch(getTriggerPreview(params))

  const stepSetup = getTriggerStepSetup()
  const fields = stepSetup ? getEnrichedStepSetupFields(stepSetup) : []

  return <>
    {stepSetup ? <TriggerStepSetup
      fields={fields}
      onChange={onChange}
      workflow={workflow}
      triggerConfig={triggerConfig}
      trigger={configuredWorkflowTrigger}
      getTriggerPreview={onGetTriggerPreview}
    /> : <EmptyTriggerStep workflow={workflow} />}
  </>
}
