import { WORKFLOW_TRIGGER_TYPE, WORKFLOW_TYPE } from '@shared/types'
import { Filter, FilterKey, FilterOperation } from '@shared/filters/types'
import { UserEntityCriterion } from '@shared/filters/userFilters/types'

export enum WORKFLOW_ACTION_TYPE {
  IF_ELSE_BRANCH = 'ifElseBranch',
  REQUEST_APPROVAL = 'requestApproval',
  WAIT = 'wait'
}

export type IdWorkflowNode = string

export type IdWorkflowAction = number
export type WorkflowActionType = string | null

interface WorkflowActionFieldModel {
  id: string
  isValid: boolean
  errorMsg: string
  value: '' | Record<string, any>
}

export interface WorkflowActionModel {
  id: IdWorkflowAction
  type: WorkflowActionType
  idApp?: number
  fields: WorkflowActionFieldModel[]
  isValid: boolean
  errorMsg: string
  continueOnError?: boolean
}

export enum BRANCH_FILTER_ENTITY {
  USER = 'user',
  APP = 'app',
  CONTRACT = 'contract'
}

type IdBranchFilter = number

interface BranchFilterBase {
  id: IdBranchFilter
  isValid?: boolean
  errorMsg?: string
}

export interface EmptyBranchFilter extends BranchFilterBase {
  entity?: undefined
  filter?: undefined
}

export interface BranchUserFilter extends BranchFilterBase {
  entity: BRANCH_FILTER_ENTITY.USER
  filter: UserEntityCriterion
}

export interface AppFilter extends Filter {
  key?: FilterKey
  op?: FilterOperation
}

export interface ContractFilter extends Filter {
  key?: FilterKey
  op?: FilterOperation
}

export interface BranchAppFilter extends BranchFilterBase {
  entity: BRANCH_FILTER_ENTITY.APP
  filter: AppFilter
}

export interface BranchContractFilter extends BranchFilterBase {
  entity: BRANCH_FILTER_ENTITY.CONTRACT
  filter: ContractFilter
}

export type BranchFilter = BranchUserFilter | BranchAppFilter | BranchContractFilter | EmptyBranchFilter

export interface BranchBase {
  next: IdWorkflowNode | null
}

export type DefaultBranch = BranchBase

export interface ConditionalBranch extends BranchBase {
  label: string
  isValid: boolean
  errorMsg: string
  filters: BranchFilter[]
}

export interface BranchActionFieldValue {
  branches: ConditionalBranch[]
  default: DefaultBranch
}

export interface BranchActionField extends WorkflowActionFieldModel {
  value: BranchActionFieldValue
}

export type BranchAction = Pick<WorkflowActionModel, 'isValid' | 'errorMsg' > & {
  type: WORKFLOW_ACTION_TYPE.IF_ELSE_BRANCH
  fields: [BranchActionField]
}

export type FixedBranchesFieldValue = {
  branches: BranchBase[]
  default: BranchBase
}

export enum WORKFLOW_NODE_TYPE {
  ACTION = 'action',
  BRANCH_ACTION = 'branchAction',
  ACTION_WITH_FIXED_BRANCHES = 'actionWithFixedBranches'
}

export type Action = Omit<WorkflowActionModel, 'id'>

export interface ActionNodeModel {
  type: WORKFLOW_NODE_TYPE.ACTION
  next: IdWorkflowNode | null
  action: Action
}

export interface BranchNodeModel {
  type: WORKFLOW_NODE_TYPE.BRANCH_ACTION
  action: BranchAction
}

export interface ActionWithFixedBranchesNodeModel {
  type: WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES
  action: Action
}

export type WorkflowNodeModel = ActionNodeModel | BranchNodeModel | ActionWithFixedBranchesNodeModel

export type WorkflowNodes = Record<IdWorkflowNode, WorkflowNodeModel>

export interface WorkflowActionsModel {
  idRootNode: IdWorkflowNode | null
  nodes: WorkflowNodes
}

export interface TriggerStepConfiguration {
  id: string
  isValid: boolean
  errorMsg: string
  value?: any
}

export interface SchedulesType {
  timezone: string
  schedules: Array<{ cron: string }>
  isValid?: boolean
  errorMsg?: string
}

export interface BaseTag {
  id: number
  label: string
  idOrg: number | null
}

export interface Tag extends BaseTag {
  usedByCount: number
}

export interface Workflow {
  id: number
  idOrg: number
  createdBy: number
  name: string
  triggerType: WORKFLOW_TRIGGER_TYPE | null
  triggerConfiguration: TriggerStepConfiguration[]
  idApp: null | number
  actions: WorkflowActionsModel
  isValid: number
  isActive: number
  isDeleted: number
  creationTime: string
  type: WORKFLOW_TYPE
  isHiddenForCustomers: number
  triggerIdApp: null | number
  schedules: null | SchedulesType
  executions: number
  tags?: Tag[]
}
