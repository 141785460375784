import React from 'react'
import PropTypes from 'prop-types'
import { AppIcon, Tooltip } from '@toriihq/design-system'

import { Wrapper, Badge } from './styles'

const AppIconWithBadge = ({ src, name, badge, showTooltip, size = 'medium' }) => {
  const appIcon = (
    <Wrapper badge={badge}>
      <AppIcon appName={name} appImageUrl={src} size={size} />
      {badge && <Badge>{badge}</Badge>}
    </Wrapper>)

  return (
    <Tooltip
      label={name}
      hide={!showTooltip}
    >
      {appIcon}
    </Tooltip>)
}

AppIconWithBadge.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showTooltip: PropTypes.bool
}

AppIconWithBadge.defaultProps = {
  size: 'x-large',
  showTooltip: false
}

export default AppIconWithBadge
