import styled from 'styled-components'
import { link } from '@shared/style/textsStyled'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const Link = styled.a`
  ${link}
`
