import styled from 'styled-components'

export const Container = styled.div`
  width: 310px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.primary};
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ImportStatusContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
  margin: 16px 14px 0;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const LastImportDateTitle = styled.div`
  font: ${({ theme }) => theme.typography.font.caption01};  
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const LastImportDate = styled.div`
  font: ${({ theme }) => theme.typography.font.component02};
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
`
