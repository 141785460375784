import { TRANSACTION_MAPPING_STATUS } from '../../../constants'

export const operatorOptions = [
  { label: 'Ends with', value: 'endsWith' },
  { label: 'Starts with', value: 'startsWith' },
  { label: 'Contains', value: 'contains' },
  { label: 'Is exactly', value: 'exactly' }
]

export const MATCH_RULE_OPERATOR = {
  ENDS_WITH: 'endsWith',
  STARTS_WITH: 'startsWith',
  CONTAINS: 'contains',
  EXACTLY: 'exactly'
}

export const mappingStatusOptions = [
  { label: 'Ignore transaction', value: TRANSACTION_MAPPING_STATUS.IGNORED },
  { label: 'Match to', value: TRANSACTION_MAPPING_STATUS.MAPPED }
]

export const TRANSACTION_MATCHING_RULE_SEARCH_APP_LIMIT = 50
