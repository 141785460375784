import React from 'react'
import moment from 'moment'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { getFormattedDate } from '@lenses/utils'

const CSS = {
  time: css({
    color: colors.text,
    fontSize: '11px'
  }),
  wrapColumn: css({
    whiteSpace: 'normal !important'
  })
}

export const dateTimeColumn = {
  Cell: ({ value }) => value ? (
    <div>
      <div>{getFormattedDate({ date: value })}</div>
      <div {...CSS.time}>{moment.utc(value).format('h:mm A')}</div>
    </div>
  ) : '-',
  width: 180,
  sortable: false
}

export const wrapColumn = {
  className: CSS.wrapColumn.toString(),
  minWidth: 150
}
