import React, { ReactElement } from 'react'
import { ActionsLine, Container, HeaderLine, HeaderSide, WorkflowNameHeaderContainer } from './styles'
import { BackButton } from './backButton'
import { WorkflowNameHeader } from './workflowNameHeader'
import { ActiveWorkflowToggle } from './activeWorkflowToggle'
import { WorkflowActionsButtons } from './workflowActionsButtons'
import { NavToExecutionLog } from './navToExecutionLog'
import { WorkflowTagsContainer } from './workflowTags'

export const WorkflowHeader = (): ReactElement => {
  return (<Container>
    <HeaderLine>
      <HeaderSide>
        <BackButton />
      </HeaderSide>
      <WorkflowNameHeaderContainer>
        <WorkflowNameHeader />
      </WorkflowNameHeaderContainer>
      <HeaderSide>
        <ActiveWorkflowToggle />
      </HeaderSide>
    </HeaderLine>
    <ActionsLine>
      <WorkflowTagsContainer />
      <NavToExecutionLog />
      <WorkflowActionsButtons />
    </ActionsLine>
  </Container>)
}
