import { ContractNameCell, deleteActionButton } from '../columns'
import { capitalize } from 'lodash'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import { Button, ButtonType } from '@toriihq/design-system'
import { ActionsWrapper, DeleteButton } from './style'
import EnableFor from '@root/components/enableFor'
import { SCOPES } from '@root/constants'
import Analytics from '../analytics'

const getSpecialColumns = ({ idOrg, onEditContract, onDeleteContract }) => [
  {
    Header: 'Contract name',
    accessor: 'name',
    minWidth: 200,
    maxWidth: 300,
    Cell: ({ value: name, row: { id: idContract } }) => ContractNameCell({ name, idContract, onEditContract })
  },
  {
    Header: 'Contract Source',
    accessor: 'source',
    minWidth: 200,
    maxWidth: 300,
    textValue: ({ value }) => capitalize((value || '')),
    Cell: ({ value: source }) => capitalize(source)
  },
  {
    id: 'actions',
    Header: '',
    sortable: false,
    resizable: false,
    fixed: 'right',
    Cell: ({ row: { id: idContract, name } }) => {
      return <ActionsWrapper>
        <EnableFor scopes={[SCOPES.CONTRACTS_WRITE]}>
          <Button type={ButtonType.secondary} label={'Match to App'} onClick={() => {
            Analytics.clickedOnMatchContractButton()
            onEditContract(idContract)
          }} />
        </EnableFor>
        <DeleteButton> {deleteActionButton({ onDeleteContract, idContract, contractName: name, idOrg })} </DeleteButton>
      </ActionsWrapper>
    },
    width: 200,
    show: true
  },
  {
    accessor: 'id',
    show: false
  },
  {
    accessor: 'idOrg',
    show: false
  }
]

export const getColumns = ({ dynamicColumnsInfo, onEditContract, name: tableName, onDeleteContract, idOrg }) => {
  return getSpecialColumns({ onEditContract, onDeleteContract, idOrg })
    .concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, usersById: {}, tableName, customFieldPropsByType: {}, fieldIdentifier: 'key', onEdit: onEditContract, contractsById: {}, assignUserCB: null, isOnlyAppOwner: false }))
}
