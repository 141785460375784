import { BranchFilter, ConditionalBranch } from '@shared/workflows/types'
import { changeBranchEntity } from '@components/filters/ifElseBranchFilters/utils/changeBranchEntity'
import { OnChangeBranchFilterProps } from '@components/filters/ifElseBranchFilters/types'

type Props = OnChangeBranchFilterProps & {
  branches: ConditionalBranch[],
}
export const getUpdatedBranchFilter = ({
  branches,
  branchIndex,
  branchFilterIndex,
  key,
  newValue
}: Props): BranchFilter | undefined => {
  const prevBranch = branches[branchIndex]
  if (!prevBranch) {
    return
  }

  const prevBranchFilter = prevBranch.filters[branchFilterIndex]
  if (!prevBranchFilter) {
    return
  }

  if (key === 'entity') {
    return changeBranchEntity(prevBranchFilter, newValue.value)
  }

  return {
    ...prevBranchFilter,
    filter: newValue
  }
}
