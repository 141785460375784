import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import TextField from './components/textField'
import AuthOptions from './components/authOptions'
import SelectPermission from './components/selectPermission'
import * as Style from './style'
import { AUTH_TYPES } from '@root/constants'
import { AlertBox, AlertBoxType, Spacer } from '@toriihq/design-system'

const required = value => (value ? undefined : 'Required')
const validateUrl = value => (value ? /^(http(s):\/\/|www\.){1}[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(value) ? undefined : 'Must be a valid URL' : 'Required')

const ConnectSCIM = ({ formProps, scimConfig, showReconnectAlertInfo, permission, onPermissionChange, supportsWritePermission, disableReadOption }) => {
  const { handleSubmit, submitError, values: { auth } } = formProps
  const errorRef = useRef(null)

  const scrollToBottom = () => {
    if (errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [submitError])

  const renderAuthFields = () => {
    if (!auth) {
      return null
    }

    if (auth === AUTH_TYPES.BASIC) {
      return <>
        <Field
          className='fs-exclude'
          name='username'
          label='Username'
          component={TextField}
          validate={required}
        />
        <Field
          className='fs-exclude'
          name='password'
          label='Password'
          component={TextField}
          validate={required}
        />
      </>
    }

    return <Field
      className='fs-exclude'
      name='token'
      label='Access token'
      component={TextField}
      validate={required}
    />
  }

  return <form onSubmit={handleSubmit}>
    <div {...Style.Fields}>
      {showReconnectAlertInfo && <Spacer bottom='space-100'><AlertBox type={AlertBoxType.INFORMATIVE} description='Providing connection details which relate to a different account will result in the creation of an additional integration instance.' /></Spacer>}
      {supportsWritePermission && <SelectPermission onChange={onPermissionChange} value={permission} disableReadOption={disableReadOption} />}
      <label {...Style.SCIMFieldsLabel}>
        Enter SCIM details
      </label>
      <Field
        name='baseUrl'
        label='Base URL'
        component={TextField}
        validate={validateUrl}
      />
      <Field
        name='auth'
        label='Authentication Method'
        component={AuthOptions}
        validate={required}
        scimConfig={scimConfig}
      />
      {renderAuthFields()}
      {submitError ? <div {...Style.ErrorText} ref={errorRef}>
        {submitError}
      </div> : null}
    </div>
  </form>
}

ConnectSCIM.propTypes = {
  formProps: PropTypes.object.isRequired,
  scimConfig: PropTypes.shape({
    baseUrl: PropTypes.string,
    auth: PropTypes.oneOf([AUTH_TYPES.BASIC, AUTH_TYPES.TOKEN, AUTH_TYPES.BEARER]),
    plan: PropTypes.string,
    requirements: PropTypes.string,
    showReconnectAlertInfo: PropTypes.bool,
    supportsWritePermission: PropTypes.bool,
    link: PropTypes.string
  })
}

export default ConnectSCIM
