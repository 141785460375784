import React from 'react'
import sumBy from 'lodash/sumBy'
import BarsChartBox from '../barsChartBox'
import colors from '../../shared/style/colors'
import numeral from 'numeral'
import emptyImage from '@media/expenses.svg'
import { SCOPES } from '../../constants'
import moment from 'moment'
import Analytics from '@helpers/analytics'
import { Button, ButtonType } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'

const AppExpensesChartBox = ({ hadNoTransactions, expenses, toggleUploadExpenses, isInUse, loading, currencySymbol = '', appName, isAnyExpensesUploaded, overrideStyle, sources, showSources = true, barSize = 25, showLabelsOnHover, description, header, isSmallScreen }) => {
  const onClick = () => {
    Analytics.track('Click to open upload expenses dialog', {
      from: isAnyExpensesUploaded ? 'Empty state 12 months chart - free app' : 'Empty state 12 months chart'
    })
    toggleUploadExpenses(true, true)
  }

  const emptyStateInfoNoExpenses = {
    image: <img src={emptyImage} alt='No expenses' />,
    description: 'Integrate your expenses',
    buttons: [<EnableFor scopes={[SCOPES.EXPENSE_WRITE]}><Button type={ButtonType.secondary} onClick={onClick} label='Upload expenses status report' /></EnableFor>]
  }

  const emptyStateInfoFreeApp = {
    image: <img src={emptyImage} alt='Free app' />,
    description: <div>We didn't detect any expenses from {moment.utc().subtract(11, 'month').format('MMMM YYYY')} to {moment.utc().format('MMMM YYYY')}.<br />Paying for {appName}?</div>,
    buttons: [<EnableFor scopes={[SCOPES.EXPENSE_WRITE]}><Button type={ButtonType.secondary} onClick={onClick} label='Upload the relevant expenses report' /></EnableFor>]
  }

  if (!isInUse && !loading) {
    return null
  }

  const annualAmount = sumBy(expenses, 'value') / 100
  const showEmptyState = hadNoTransactions && annualAmount === 0 && !loading
  const emptyStateInfo = isAnyExpensesUploaded ? emptyStateInfoFreeApp : emptyStateInfoNoExpenses
  const expensesToChartBarsData = expenses.map(expense => ({ ...expense, value: expense.value < 100 ? Math.round(expense.value / 100) * 100 : expense.value }))

  return (
    <div {...overrideStyle}>
      <BarsChartBox
        loading={loading}
        description={description}
        showLabelsOnHover={showLabelsOnHover}
        data={expensesToChartBarsData}
        emptyStateInfo={emptyStateInfo}
        showEmptyState={showEmptyState}
        fill={colors.purple}
        formatter={value => numeral(value / 100).format(`$0,0`).replace('$', currencySymbol)}
        barSize={barSize}
        sources={sources}
        showSources={showSources}
        header={header}
        appName={appName}
        isSmallScreen={isSmallScreen}
      />
    </div>
  )
}

export default AppExpensesChartBox
