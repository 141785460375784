import React from 'react'
import WorkflowTagsTable from './workflowTagsTable'
import { useSelector } from 'react-redux'
import { getWorkflowTags, isLoadingWorkflowTags as isLoadingWorkflowTagsSelector } from '@selectors/workflows'
import { Counter, ExpandableSection } from '@toriihq/design-system'

const WorkflowTagsCollapsable = () => {
  const tags = useSelector(getWorkflowTags)
  const isLoadingWorkflowTags = useSelector(isLoadingWorkflowTagsSelector)

  const renderCollapsableTitle = () => (
    <>
      Tags
      {!isLoadingWorkflowTags && <Counter value={tags?.length ?? 0} />}
    </>
  )

  return (
    <ExpandableSection
      title={renderCollapsableTitle()}
    >
      <WorkflowTagsTable />
    </ExpandableSection>
  )
}

export default WorkflowTagsCollapsable
