import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import ManualMatch from './manualMatch'
import pluralize from 'pluralize'
import Analytics from '../../helpers/analytics'
import { PARSING_STATUS, UPLOAD_TYPES } from '../../constants'
import { UploadExpensesPopupContext } from '../popups/uploadExpensesPopup/uploadExpensesPopupContext'
import {
  DROP_ZONE_DEFAULT,
  DROP_ZONE_UPLOADING,
  DROP_ZONE_MANUAL_MATCH,
  DROP_ZONE_SUCCESS,
  DROP_ZONE_ERROR,
  DROP_ZONE_PARSING_ERROR,
  DROP_ZONE_SIZE_ERROR, DROP_ZONE_DROP_ERROR
} from './constants'
import { Button, ButtonType, Link, UploadArea } from '@toriihq/design-system'
import ErrorImg from '@media/error.svg'
import SuccessImg from '@media/success-checkmark.svg'

const POLL_STATUS_INTERVAL_IN_SECONDS = 5
const VALID_FILE_TYPES = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
const UPLOAD_FILE_SIZE_LIMIT = 10 * 1024 * 1024
class UploadExpenses extends React.Component {
  state = {
    currentDropZoneState: DROP_ZONE_DEFAULT,
    selectedFilename: null,
    idUpload: null,
    manualMatchErrorMessage: null,
    numberOfMatchedTransactions: 0,
    numberOfTransactions: 0,
    errorMessage: ''
  }

  componentDidUpdate (prevProps, prevState) {
    const { currentDropZoneState: currentDropZoneStatePrev } = prevState
    const { currentDropZoneState } = this.state

    if (currentDropZoneStatePrev !== currentDropZoneState) {
      this.context.setState(currentDropZoneState)
      if (currentDropZoneState === DROP_ZONE_SUCCESS) {
        this.context.action.current = this.goToUploadPage
      }
    }
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    const { uploadFile, parseExpenseFile, idOrg, onSuccess, getParsingStatus } = this.props
    this.updateDropZoneState({ newDropZoneState: DROP_ZONE_UPLOADING })
    if (rejectedFiles.length) {
      Analytics.track('Upload expenses file failed (invalid file type)', {
        name: rejectedFiles[0].file.name,
        type: rejectedFiles[0].file.type
      })
      return this.updateDropZoneState({ newDropZoneState: DROP_ZONE_DROP_ERROR, errorMessage: 'Invalid file type. You can only upload CSV/XLSX files.' })
    }

    const file = acceptedFiles[0]
    Analytics.track('Upload expenses file', {
      name: file.name
    })

    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      return this.updateDropZoneState({ newDropZoneState: DROP_ZONE_SIZE_ERROR, errorMessage: 'File exceeds 10MB.' })
    }

    try {
      const { idUpload } = await uploadFile({ idOrg, file, type: UPLOAD_TYPES.EXPENSE_REPORT })
      this.setState({ selectedFilename: file.name, idUpload })
      const { idParsing } = await parseExpenseFile({ idOrg, idUpload })
      if (!this.interval) {
        this.interval = setInterval(async () => {
          const response = await getParsingStatus({ idOrg, idParsing })
          this.handleSubmitResults({ response, onSuccess })
        }, POLL_STATUS_INTERVAL_IN_SECONDS * 1000)
      }
    } catch (e) {
      this.updateDropZoneState({ newDropZoneState: e.message === DROP_ZONE_SIZE_ERROR ? DROP_ZONE_SIZE_ERROR : DROP_ZONE_ERROR })
    }
  }

  submitManualMatch = async ({ parseConfig }) => {
    const { parseExpenseFileManually, idOrg, onSuccess, getParsingStatus } = this.props
    const { idUpload } = this.state

    try {
      Analytics.track('Manual parse of expense file', {
        'Parse Config': parseConfig
      })

      this.updateDropZoneState({ newDropZoneState: DROP_ZONE_UPLOADING })
      const { idParsing } = await parseExpenseFileManually({ idOrg, idUpload, parseConfig })
      if (!this.interval) {
        this.interval = setInterval(async () => {
          const response = await getParsingStatus({ idOrg, idParsing })
          this.handleSubmitResults({ response, onSuccess })
        }, POLL_STATUS_INTERVAL_IN_SECONDS * 1000)
      }

      if (this.state.currentDropZoneState === DROP_ZONE_UPLOADING) {
        this.setState({ showManualMatchErrorMessage: true })
      }
    } catch (e) {
      this.updateDropZoneState({ newDropZoneState: DROP_ZONE_ERROR })
    }
  }

  handleSubmitResults = ({ response, onSuccess }) => {
    const { numberOfMatchedTransactions, numberOfTransactions, parsingStatus } = response
    if (!(parsingStatus === PARSING_STATUS.parsingInProgress)) {
      clearInterval(this.interval)
      this.interval = null
      const newDropZoneState = parsingStatus === PARSING_STATUS.parsedSuccessfully ? DROP_ZONE_SUCCESS : DROP_ZONE_MANUAL_MATCH
      if (parsingStatus !== PARSING_STATUS.parsedSuccessfully) {
        this.context.action.current = this.goToMappingColumns
      }
      this.updateDropZoneState({ newDropZoneState, numberOfMatchedTransactions, numberOfTransactions })
      onSuccess && parsingStatus === PARSING_STATUS.parsedSuccessfully && onSuccess()
    }
  }

  updateDropZoneState = ({ newDropZoneState, numberOfMatchedTransactions = 0, numberOfTransactions = 0, errorMessage = '' }) => {
    this.setState({ currentDropZoneState: newDropZoneState, numberOfMatchedTransactions, numberOfTransactions, manualMatchErrorMessage: null, errorMessage })
  }

  closePopup = () => {
    const { toggleUploadExpenses } = this.props
    toggleUploadExpenses(false, true)
  }

  goToUploadPage = () => {
    const { idOrg, history } = this.props
    const { idUpload } = this.state
    history.push(`/team/${idOrg}/expenses/uploads/${idUpload}`)
    this.closePopup()
  }

  goToMappingColumns = () => {
    this.updateDropZoneState({ newDropZoneState: DROP_ZONE_MANUAL_MATCH })
  }

  renderFooter = (state) => {
    if ([DROP_ZONE_UPLOADING, DROP_ZONE_MANUAL_MATCH, DROP_ZONE_DEFAULT].includes(state)) {
      return null
    }

    return (
      <div {...CSS.postUploadFooter}>
        <Button type={ButtonType.secondary} onClick={this.closePopup} label='Skip' />
        <Button type={ButtonType.secondary} onClick={this.goToUploadPage} label='Review mapping' />
      </div>
    )
  }

  renderDropZone = () => {
    const { currentDropZoneState, errorMessage } = this.state

    const state = currentDropZoneState === DROP_ZONE_UPLOADING ? 'loading' : [DROP_ZONE_DROP_ERROR, DROP_ZONE_SIZE_ERROR, DROP_ZONE_PARSING_ERROR, DROP_ZONE_ERROR].includes(currentDropZoneState) ? 'error' : 'neutral'
    return (
      <UploadArea
        onFileSelect={this.onDrop}
        multiple={false}
        validFileTypes={VALID_FILE_TYPES}
        state={state}
        loadingMessage='Crunching the numbers...'
        errorMessage={errorMessage}
      />
    )
  }

  renderManualMatch = () => {
    return <ManualMatch filename={this.state.selectedFilename} onSubmit={this.submitManualMatch} showManualMatchErrorMessage={this.state.showManualMatchErrorMessage} isInsidePopup />
  }

  renderPostUpload = () => {
    const { currentDropZoneState } = this.state

    return (
      <div {...CSS.alignment} >
        <span {...CSS.iconContainer} style={{ backgroundColor: dropZoneStateView[currentDropZoneState].imageBackgroundColor }}>
          <img src={dropZoneStateView[currentDropZoneState].image} height='24' width='24' alt={'Upload status'} {...CSS.postUploadIcon} />
        </span>
        {dropZoneStateView[currentDropZoneState].textElement(this.state.numberOfMatchedTransactions, this.state.numberOfTransactions)}
      </div>
    )
  }

  renderHeader = () => {
    const { currentDropZoneState } = this.state

    if (currentDropZoneState === DROP_ZONE_SUCCESS) {
      return null
    }

    if (currentDropZoneState === DROP_ZONE_MANUAL_MATCH) {
      return (
        <header {...CSS.header}>
          Map Attributes
          <div {...CSS.subHeader}>
            Match each <b>data type</b> with the <b>correct column</b>
          </div>
        </header>
      )
    }

    return (
      <header {...CSS.header}>
        Upload expenses report
        <div {...CSS.subHeader}>
          Upload a XLSX/CSV report from any financial system, or fill out and upload this <Link href='https://s3-us-west-2.amazonaws.com/torii-static/templates/torii_template_v5.xlsx' target='_blank'>template</Link>.
        </div>
        <div {...CSS.subHeader}>
          Files uploaded cannot exceed 10MB.
        </div>
      </header>
    )
  }

  renderBody = () => {
    const { currentDropZoneState } = this.state
    switch (currentDropZoneState) {
      case DROP_ZONE_MANUAL_MATCH:
        return this.renderManualMatch()
      case DROP_ZONE_SUCCESS:
        return this.renderPostUpload()
      default:
        return this.renderDropZone()
    }
  }
  render () {
    const { currentDropZoneState } = this.state
    const { style, isInsidePopup } = this.props
    const bodyStyle = currentDropZoneState === DROP_ZONE_MANUAL_MATCH ? {} : CSS.body
    return (
      <div style={style}>
        {!isInsidePopup && this.renderHeader()}
        <div {...bodyStyle}>
          {this.renderBody()}
        </div>
        <footer>
          {!isInsidePopup && this.renderFooter(currentDropZoneState)}
        </footer>
      </div>
    )
  }
}

const CSS = {
  header: css(texts.headers.regular, {
    textAlign: 'center',
    borderBottom: `1px solid ${colors.border}`,
    padding: '22px 0'
  }),
  postUploadFooter: css(texts.body, {
    borderTop: `1px solid ${colors.border}`,
    padding: '22px 15px',
    marginBottom: '-12px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ' > button': {
      marginLeft: '10px'
    }
  }),
  subHeader: css(texts.body, {
    marginTop: '5px',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }),
  body: css(texts.body, {
    display: 'grid',
    justifyContent: 'center'
  }),
  displayArea: css({
    width: '100%',
    height: '100%'
  }),
  iconContainer: css({
    height: '77px',
    width: '77px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  alert: css({
    display: 'flex',
    alignItems: 'center'
  }),
  alertIcon: css({
    height: '20px',
    width: '20px',
    backgroundColor: colors.lightBlue1,
    borderRadius: '50%',
    color: colors.white,
    lineHeight: '22px',
    textAlign: 'center',
    marginRight: '12px',
    transform: 'none'
  }),
  text: css(texts.subheading, {
    marginTop: '20px',
    textAlign: 'center',
    ' span': {
      color: colors.blue
    }
  }),
  successTitle: css(texts.headers.regular, {
    color: colors.darkText,
    marginTop: '20px',
    textAlign: 'center'
  }),
  successText: css(texts.subheading, {
    color: colors.darkText,
    marginTop: '15px',
    textAlign: 'center'
  }),
  alignment: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }),
  postUploadIcon: css({
    color: colors.white,
    fontSize: '24px',
    transform: 'none'
  })
}

const dropZoneStateView = {
  [DROP_ZONE_SUCCESS]: {
    image: SuccessImg,
    imageBackgroundColor: colors.blue,
    textElement: (numberOfMatchedTransactions, numberOfTransactions) => (
      <>
        <div {...CSS.successTitle}>You're all Done!</div>
        <div {...CSS.successText}>{`${pluralize('transaction', numberOfTransactions, true)} added, ${numberOfMatchedTransactions} of them matched`}</div>
      </>
    )
  },
  [DROP_ZONE_ERROR]: {
    image: ErrorImg,
    imageBackgroundColor: colors.red,
    textElement: () => <div {...CSS.text}>Something went wrong, Please try again</div>
  },
  [DROP_ZONE_SIZE_ERROR]: {
    image: ErrorImg,
    imageBackgroundColor: colors.red,
    textElement: () => <div {...CSS.text}>File exceeds 10MB.</div>
  },
  [DROP_ZONE_PARSING_ERROR]: {
    image: ErrorImg,
    imageBackgroundColor: colors.red,
    textElement: () => <div {...CSS.text}>We couldn't match any of your expenses. Please fix the attribute mapping and try again.</div>
  }
}

UploadExpenses.contextType = UploadExpensesPopupContext

export default UploadExpenses
