import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Tooltip,
  Spacer,
  Button,
  ButtonType,
  ButtonSize,
  Link,
  LinkSize,
  Icon,
  TextBadge,
  EmptyState
} from '@toriihq/design-system'
import moment from 'moment'
import Table from '../../table'
import { TABLES, SCOPES, EMPTY_OBJECT, WORKFLOW_TYPES, DATE_FORMAT, fieldTypes } from '@root/constants'
import RelativeTeamLink from '../../relativeTeamLink'
import emptyImage from './images/workflows.svg'
import { getDisplayName } from '@lenses/users'
import ExecutionStatus from '../../workflowExecutions/executionStatus'
import WorkflowActivationToggle from '@components/workflowActivationToggle'
import WorkflowActions from '@components/workflows/workflowActions'
import useDeleteLogic from '@components/workflows/workflowActions/hooks/useDeleteConfirmation'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import CSS, { TooltipContent, User, WorkflowNameColumn } from './style'
import AppDetails from '@components/appDetails'
import AppCatalogPolicyActions from '@components/workflows/appCatalogPolicyActions'
import { getInvalidWorkflowTooltipText, isCustomAppCatalogPolicy } from '@lenses/workflows'
import EnableFor from '@components/enableFor'
import VisibleFor from '@root/components/visibleFor'
import { getFormattedDate } from '@lenses/utils'
import NavigationToWorkflowExecutionLog from '@components/navigationToWorkflowExecutionLog'
import { getFilterOptionsValuesPerKey } from '@root/lenses/filters'
import { getExecutionStatus } from '@components/workflowExecutions/executionStatus/view'
import { flattenDeep, isEmpty, uniq } from 'lodash'
import TagsContainer from '@components/tagsContainer'
import Analytics from '@helpers/analytics'
import AppCatalogRequestNewAppPolicyActions from '../appCatalogRequestNewAppPolicyActions'
import { SUPPORT_ARTICLES } from '@root/articles'

const invalidIcon = (tooltipText) => (
  <Spacer right={'space-050'}>
    <Tooltip label={tooltipText}>
      <Icon name='Danger' color='error' />
    </Tooltip>
  </Spacer>
)

const WorkflowsTable = (props) => {
  const {
    workflows,
    loading,
    overrideStyle,
    emptyStateMessage,
    isSearchable,
    idOrg,
    deleteWorkflow,
    getWorkflows,
    isHidden,
    tableHeader,
    extraHeaderComponent,
    referrer,
    deleteLogicProps,
    pathPrefix,
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView,
    usersMap
  } = props

  const { getUpdatedWorkflowsList, getConfirmationHeader, confirmationText } = deleteLogicProps || EMPTY_OBJECT
  const { deleteConfirmation, onOpen } = useDeleteLogic({
    idOrg,
    deleteWorkflow,
    getUpdatedWorkflowsList: getUpdatedWorkflowsList || (async () => getWorkflows({ idOrg, type: WORKFLOW_TYPES.regular, isHidden })),
    referrer,
    getConfirmationHeader,
    confirmationText
  })

  const workflowsWithLastStatus = workflows.map(workflow => ({
    ...workflow,
    lastStatus: workflow.latestExecution ? getExecutionStatus(
      workflow.latestExecution?.completionTime,
      workflow.latestExecution?.completionTime ? workflow.latestExecution?.hasError : workflow.latestExecution?.lastActionHasError
    ) : null,
    tagsLabels: workflow.tags ? workflow.tags.map(tag => tag.label) : workflow.tags
  }))

  const onButtonClick = async () => {
    const { idOrg, history } = props
    history.push(`/team/${idOrg}/workflows/new`)
  }

  const renderNoWorkflows = () => {
    const onClick = () => {
      Analytics.track('Click to create a workflow (empty state)', {
        from: 'From workflows page'
      })
      onButtonClick()
    }
    return <EmptyState
      image={<img src={emptyImage} alt='No workflows' />}
      title='There are no workflows yet'
      buttons={[
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} allowForToriiAdmin>
          <Button type={ButtonType.primary} onClick={onClick} label='Create a workflow' />
        </EnableFor>
      ]}
      link={<Link
        size={LinkSize.Medium}
        href={SUPPORT_ARTICLES.WORKFLOWS_5_TO_START_FROM}
        onClick={() => {
          Analytics.track('Open workflow-examples-documentation', {
            'Open from': 'Empty state'
          })
        }}
        target='_blank'>
        <Link.Icon name='Workflows' />View 5 workflow examples
      </Link>}
    />
  }

  const getColumns = () => {
    const {
      idOrg,
      loading,
      usersMap = EMPTY_OBJECT,
      appsMap = EMPTY_OBJECT,
      defaultTriggerIdApps = [],
      getWorkflowNameBadgeText,
      toggleAddAppCatalogPolicyPopup
    } = props
    return [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value: name, row: { isValid, isActive, id, type, triggerType, triggerIdApp, tags } }) => {
          const isCustomPolicy = isAppCatalogPoliciesView && isCustomAppCatalogPolicy({ workflow: { triggerIdApp, type } })

          const isInactivePolicy = isAppCatalogPoliciesView && !isActive
          const showErrorTooltip = !isValid || isInactivePolicy

          const tooltipText = getInvalidWorkflowTooltipText({ type, triggerType, isValid, isActive, isCustomPolicy, defaultTriggerIdApps })

          const badgeText = getWorkflowNameBadgeText({ type, triggerIdApp })
          const getBadge = () => (badgeText ? <TextBadge color='gray' size='Small'>{badgeText}</TextBadge> : undefined)

          const shouldDisplayTags = !!(!isAppCatalogPoliciesView && !isEmpty(tags))

          return <WorkflowNameColumn>
            <EnableFor scopes={[SCOPES.AUTOMATION_READ]} allowForToriiAdmin>
              <RelativeTeamLink to={`/${pathPrefix}/${id}`}>
                <div {...CSS.name}>
                  {showErrorTooltip && invalidIcon(tooltipText)}
                  <div>
                    <Link>{name} {getBadge()}</Link>
                  </div>
                </div>
              </RelativeTeamLink>
            </EnableFor>
            {
              shouldDisplayTags ? (
                <TagsContainer tags={tags} maxTagsToShow={2} />
              ) : null
            }
          </WorkflowNameColumn>
        },
        minWidth: 220
      },
      {
        Header: 'Applied on',
        accessor: 'triggerIdApp',
        Cell: ({ value: triggerIdApp }) => {
          if (!triggerIdApp) {
            return (
              <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_READ]}>
                <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => toggleAddAppCatalogPolicyPopup({ isOpen: true, isByUser: true, isViewOnly: true })} label={`${defaultTriggerIdApps.length} Apps`} />
              </EnableFor>
            )
          }

          const app = appsMap[triggerIdApp] || {}
          const { id, name, category, imageUrl } = app
          return <AppDetails
            id={id}
            name={name}
            category={category}
            imageUrl={imageUrl}
            component='App catalog'
          />
        },
        minWidth: 160,
        show: Boolean(isAppCatalogPoliciesView)
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ value: createdBy, row: { creationTime } }) => {
          const user = usersMap[createdBy] || (isAppCatalogPoliciesView && usersMap['toriiUser']) || {}
          const { firstName, lastName, photoUrl } = user
          const tooltipLabel =
            <TooltipContent>
              <div>Created by <strong>{' ' + getDisplayName(user)}</strong></div>
              <div>on<strong>{' ' + getFormattedDate({ date: creationTime })}</strong></div>
            </TooltipContent>
          const content = <User>
            <Tooltip label={tooltipLabel}>
              <Avatar
                firstName={firstName}
                lastName={lastName}
                imageUrl={photoUrl}
              />
            </Tooltip>
          </User>

          return (!user.isSupport && user.id ? <RelativeTeamUserLink idUser={user.id}>{content}</RelativeTeamUserLink> : content)
        },
        sortMethod: (a, b) => Table.sortMethods.user(usersMap, a, b)
      },
      {
        Header: 'Triggered',
        accessor: 'executions',
        Cell: ({ value: executions, row }) => {
          const NavToExecutionLog = (
            <NavigationToWorkflowExecutionLog
              navTo={`/${pathPrefix}/${row.id}/executions`}
              workflow={row}
              isAppCatalogPoliciesView={isAppCatalogPoliciesView}
              pageName={isAppCatalogPoliciesView ? 'Policies list' : 'Workflows list'}
              title={executions}
            />
          )

          return executions ? NavToExecutionLog : '-'
        }
      },
      {
        Header: 'Last Status',
        id: 'latestExecution',
        accessor: 'latestExecution',
        Cell: ({ value: latestExecution, row: { id } }) => {
          if (!latestExecution) {
            return '-'
          }

          const { hasError, lastActionHasError, completionTime, creationTime } = latestExecution
          const shouldShowError = completionTime ? hasError : lastActionHasError

          return (
            <Tooltip
              label={moment.utc(completionTime || creationTime).format(DATE_FORMAT)}
            >
              <RelativeTeamLink to={`/${pathPrefix}/${id}/executions`}>
                <ExecutionStatus completionTime={completionTime} hasError={shouldShowError} />
              </RelativeTeamLink>
            </Tooltip>
          )
        },
        width: 200,
        sortMethod: (a, b) => {
          const scoreA = a ? (a.hasError ? 1 : 2) : 0
          const scoreB = b ? (b.hasError ? 1 : 2) : 0
          if (scoreB - scoreA !== 0) {
            return scoreB - scoreA
          }

          const timeA = moment.utc((a && a.completionTime) || (a && a.creationTime) || 0).valueOf()
          const timeB = moment.utc((b && b.completionTime) || (b && b.creationTime) || 0).valueOf()
          return timeB - timeA
        }
      },
      {
        Header: 'Active',
        id: 'isActive',
        accessor: ({ isActive }) => Boolean(isActive),
        className: CSS.activeColumn.toString(),
        Cell: ({ row: workflowInfo }) => {
          return (<WorkflowActivationToggle
            idOrg={idOrg}
            workflowInfo={workflowInfo}
            loading={loading}
            referrer={referrer}
            isAppCatalogPoliciesView={isAppCatalogPoliciesView}
            isRequestNewAppPoliciesView={isRequestNewAppPoliciesView}
          />)
        }
      },
      {
        id: 'actions',
        Header: '',
        sortable: false,
        Cell: ({ row: { id: idWorkflow, name, triggerType, type, isValid } }) => {
          const props = {
            idOrg,
            idWorkflow,
            name,
            triggerType,
            isValid,
            referrer,
            noBorder: true,
            onDeleteConfirmationOpen: () => onOpen({ idWorkflow, workflowName: name, workflowType: type, workflowTriggerType: triggerType }),
            shouldShowMenu: true
          }
          if (isAppCatalogPoliciesView) {
            return <VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
              <AppCatalogPolicyActions {...props} pageName={'policies list'} />
            </VisibleFor>
          } else if (isRequestNewAppPoliciesView) {
            return <VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
              <AppCatalogRequestNewAppPolicyActions {...props} shouldShowMenu={false} pageName={'request new app policies list'} />
            </VisibleFor>
          }

          return <WorkflowActions {...props} />
        },
        minWidth: 140
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'isValid',
        show: false
      },
      {
        id: 'creationTime',
        accessor: ({ creationTime }) => creationTime ? moment.utc(creationTime).toDate().getTime() : null,
        show: false
      },
      {
        id: 'lastTriggeredTime',
        accessor: ({ lastTriggeredTime }) => lastTriggeredTime ? moment.utc(lastTriggeredTime).toDate().getTime() : null,
        show: false
      },
      {
        accessor: 'triggerType',
        show: false
      },
      {
        accessor: 'type',
        show: false
      },
      {
        accessor: 'triggerIdApp',
        show: false
      },
      {
        accessor: 'lastStatus',
        show: false
      },
      {
        id: 'tagsLabels',
        accessor: ({ tagsLabels }) => isEmpty(tagsLabels) ? null : tagsLabels,
        show: false
      },
      {
        accessor: 'tags',
        show: false
      },
      {
        accessor: 'lastActionHasError',
        show: false
      }
    ]
  }

  const searchFilterMethod = (row, search) => {
    return row.name && row.name.toLowerCase().includes(search && search.toLowerCase())
  }

  const getFilterOptions = () => {
    const tagOptions = uniq(flattenDeep(workflowsWithLastStatus.map(({ tagsLabels }) => tagsLabels)))
      .map(tag => ({ label: tag, value: tag }))

    const tagFilterOption = {
      label: 'Tags',
      value: 'tagsLabels',
      type: fieldTypes.dropdown,
      options: tagOptions
    }

    return [
      ...TABLES.workflowsTable.filtersOptions,
      tagFilterOption
    ]
  }

  const filtersOptions = getFilterOptions()
  const filterOptionsValuesPerKey = getFilterOptionsValuesPerKey(workflowsWithLastStatus, { filtersOptions }, usersMap)

  return (
    <Fragment>
      {deleteConfirmation}
      <Table
        tableKey={TABLES.workflowsTable.key}
        style={overrideStyle}
        data={workflowsWithLastStatus}
        columns={getColumns()}
        loading={loading}
        searchable={isSearchable}
        searchFilterMethod={searchFilterMethod}
        emptyStateMessage={emptyStateMessage || renderNoWorkflows()}
        header={tableHeader}
        extraHeaderComponent={extraHeaderComponent}
        clientPaging
        pageSize={25}
        filterable={!(isAppCatalogPoliciesView || isRequestNewAppPoliciesView)}
        filtersOptions={filtersOptions}
        filterOptionsValuesPerKey={filterOptionsValuesPerKey}
        supportViews={!(isAppCatalogPoliciesView || isRequestNewAppPoliciesView)}
      />
    </Fragment>
  )
}

WorkflowsTable.propTypes = {
  workflows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isValid: PropTypes.number,
    creationTime: PropTypes.string,
    isActive: PropTypes.number,
    lastTriggeredTime: PropTypes.string
  })),
  loading: PropTypes.bool.isRequired,
  overrideStyle: PropTypes.object,
  emptyStateMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isSearchable: PropTypes.bool,
  isAppCatalogPoliciesView: PropTypes.bool,
  usersMap: PropTypes.object,
  appsMap: PropTypes.object,
  tableHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  extraHeaderComponent: PropTypes.element,
  defaultTriggerIdApps: PropTypes.array,
  referrer: PropTypes.string,
  deleteLogicProps: PropTypes.object,
  pathPrefix: PropTypes.string,
  getWorkflowNameBadgeText: PropTypes.func
}

WorkflowsTable.defaultProps = {
  isSearchable: true,
  overrideStyle: EMPTY_OBJECT,
  isAppCatalogPoliciesView: false,
  usersMap: EMPTY_OBJECT,
  appsMap: EMPTY_OBJECT,
  defaultTriggerIdApps: [],
  deleteLogicProps: EMPTY_OBJECT,
  referrer: 'Workflows list',
  getWorkflowNameBadgeText: () => undefined
}

export default WorkflowsTable
