import React from 'react'

export const isInnerButtonEvent = (event: Event): boolean => {
  const eventElement = event.currentTarget as Element
  const innerClickedElement = event.target as Element

  let currentElement: Element | null = innerClickedElement

  while (currentElement && currentElement !== eventElement) {
    if (currentElement.tagName === 'BUTTON') {
      return true
    }

    currentElement = currentElement.parentElement
  }

  return false
}

export const getBaseApiPath = (idOrg: number) => `/api/orgs/${idOrg}`

export const exhaustiveCheck = (_v: never) => {}

export const blurInputWhenPressingEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Enter') {
    e.currentTarget.blur()
  }
}
