import View from './view'
import { connect } from 'react-redux'
import { getCurrentApp } from '@selectors/apps'
import { getIsSmallScreen } from '@selectors/ui'
import { getUsageTrends, isLoadingUsageTrends } from '@selectors/trends'
import moment from 'moment'
import { getUsageAndUsersSources } from '@lenses/sources'
import { EMPTY_ARRAY, EMPTY_OBJECT, scoreSpecialValues } from '@root/constants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const mapStateToProps = (state, ownProps) => {
  const { idApp, service, pageName } = ownProps
  const { app = EMPTY_OBJECT, loading: isLoadingApp } = getCurrentApp(state) || EMPTY_OBJECT
  const usageRaw = getUsageTrends(state)[idApp] || EMPTY_ARRAY

  const appName = get(app, ['name'])
  const score = get(app, ['score'])
  const usageSources = get(app, ['usageSources']) || EMPTY_ARRAY

  const isOnlyExtensionLowUsage = score === scoreSpecialValues.onlyExtensionLowUsage
  const { sources, disabledSource } = getUsageAndUsersSources({ service, defaultSources: usageSources, usageType: 'application usage', pageName })

  const usage = usageRaw
    .slice()
    .reverse()
    .map(usage => (
      {
        date: usage.date,
        value: usage.averageDaysUsed,
        name: moment.utc(usage.date).format('MMM YYYY')
      }
    ))

  const isChartDataLoading = isLoadingUsageTrends(state) && !usageRaw.length
  const isNewAppLoading = get(app, ['id']) !== idApp
  const loading = isChartDataLoading || (isLoadingApp && isNewAppLoading)

  const noAppUsageInLast12Months = !isEmpty(usageSources) && usage.every(monthlyAppUsage => monthlyAppUsage.value === 0)
  const showEmptyState = !loading && (isEmpty(usageSources) || isOnlyExtensionLowUsage || noAppUsageInLast12Months)

  return {
    loading,
    isInUse: !isEmpty(app),
    appName,
    usage,
    isSmallScreen: getIsSmallScreen(state),
    sources,
    disabledSource,
    isOnlyExtensionLowUsage,
    noAppUsageInLast12Months,
    showEmptyState
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(View)
