import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing('space-600')};
  @media(max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;

  @media(max-width: 1024px) {
    justify-content: center;
    text-align: center;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: ${({ theme }) => theme.spacing('space-150')};
  margin-top: ${({ theme }) => theme.spacing('space-200')};

  @media(max-width: 1024px) {
    justify-content: center;
  }
`

export const ContactSupportWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing('space-050')};
`

export const Disclaimer = styled.div`
  max-width: 342px;
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const FlexText = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  height: 150px;
`
