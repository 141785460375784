import React, { useRef, useState } from 'react'
import { css } from 'glamor'
import { Field } from 'react-final-form'
import ColorPicker from '@components/colorPicker'
import Input from '@components/form/input'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import countBy from 'lodash/countBy'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import arrayMutators from 'final-form-arrays'
import SelectGroup from '@components/popups/inputPopup/selectGroup'
import InputField from '@components/popups/inputPopup/input'
import CheckboxField from '@components/popups/inputPopup/checkbox'
import FormQuestion from '@components/popups/inputPopup/formQuestion'
import { FieldArray } from 'react-final-form-arrays'
import * as Style from './style'
import PropTypes from 'prop-types'
import { Icon, Button, ButtonType, ButtonSize, Tooltip } from '@toriihq/design-system'

const validate = values => {
  const errors = { fieldOptions: [] }
  if (!values.fieldName) {
    errors.fieldName = ['Field name is mandatory']
  } else if (values.fieldName.length > Style.MAX_FIELD_NAME_LENGTH) {
    errors.fieldName = ['Try something a bit shorter (max. 100)']
  }

  if (!values.fieldOptions || (values.fieldOptions.length < Style.MANDATORY_OPTIONS_AMOUNT)) {
    errors.fieldOptions[0] = 'At least one option must be entered'
  } else {
    const counts = countBy(values.fieldOptions, option => option.label && option.label.toString().toLowerCase())
    const options = []
    values.fieldOptions.forEach((option, optionIndex) => {
      if (!option.label) {
        options[optionIndex] = { label: 'Please fill option name' }
      } else if (counts[option.label.toString().toLowerCase()] > 1) {
        options[optionIndex] = { label: 'Please make sure that all options are unique' }
      }
    })
    if (options.length) {
      errors.fieldOptions = options
    }
  }

  return errors
}

const OptionsPopup = (props) => {
  const { cancelButton, submitButton, header, isOpen, input: { label, fieldName, formQuestion, idGroup }, options, isPredefined, editMode, showGroupDropdown, groupsForSelectGroup, showCheckBox } = props

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const submitAction = useRef(null)
  const [isDirty, setIsDirty] = useState(false)

  const submitForm = async (values) => {
    const { submitButton, cancelButton, autoHideOnSubmit } = props
    const updatedValues = { ...values }
    if (!values.checkbox || !values.formQuestion) {
      updatedValues.formQuestion = null
    }
    await submitButton.onClick(updatedValues)
    if (autoHideOnSubmit) {
      setTimeout(cancelButton.onClick, 1000)
    }
  }

  const makeOnDragEndFunction = fields => result => {
    if (!result.destination) {
      return
    }
    fields.move(result.source.index, result.destination.index)
  }

  const removeOptionButton = ({ disabled, onClick }) => (
    <span {...Style.DeleteButton} className='deleteButton'>
      <Button type={ButtonType.compact} size={ButtonSize.small} htmlButtonType='button' onClick={onClick} disabled={disabled} icon='Trash' />
    </span>
  )

  const renderOption = ({ fields, index, provided, disabled, input, option, meta }) => {
    const { colorableField } = props
    const addRemoveButton = fields.length > Style.MANDATORY_OPTIONS_AMOUNT

    return (
      <React.Fragment>
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...css(Style.OptionContainer, !disabled && Style.DraggableOptionContainer, { ...provided.draggableProps.style })}
        >
          <div className='indexField' {...Style.IndexField}>{index + 1}</div>
          <div className='dotsField' {...Style.DotsField}><Icon name='Drag' /></div>
          {colorableField && <Field name={`${option}.color`}>
            {({ input, meta }) => (
              <ColorPicker
                color={input.value}
                onChange={input.onChange}
                name={input.name}
                disabled={disabled}
                postOpen={value => setIsColorPickerOpen(value)}
                postClose={() => setIsColorPickerOpen(false)}
              />
            )}
          </Field>}
          <Input
            {...input}
            key={option}
            autoFocus={false}
            disabled={disabled}
          />
          {addRemoveButton && removeOptionButton({ disabled, onClick: async () => fields.remove(index) })}
        </div>
        {meta.error && <div {...Style.ErrorMessage}>{meta.touched && meta.error}</div>}
      </React.Fragment>
    )
  }

  const renderOptions = ({ fields, meta: { error, touched } }) => {
    return (
      <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields && fields.map((option, index) => {
                const current = fields.value[index]
                const disabled = Boolean(current.isPredefined)
                return (
                  <Draggable
                    key={option}
                    draggableId={option}
                    index={index}
                    isDragDisabled={isColorPickerOpen || disabled}
                  >
                    {provided => (
                      <Field key={option} name={`${option}.label`}>
                        {({ input, meta }) => (
                          disabled ? (
                            <Tooltip
                              fullWidth
                              position='top'
                              label='This option is defined by Torii and cannot be edited or deleted'
                            >
                              {renderOption({ fields, index, provided, disabled, input, option, meta })}
                            </Tooltip>
                          ) : renderOption({ fields, index, provided, disabled, input, option, meta })
                        )}
                      </Field>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={async () => fields.push({ label: '', isPredefined: 0 })} label='+ Add option' />
        {error && <div {...Style.ErrorMessage}>{touched && error}</div>}
      </DragDropContext>
    )
  }

  const onClose = () => cancelButton.onClick()

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onClose}
      confirmClose={isDirty}
    >
      <ToriiPopup.Header header={header} />
      <ToriiPopup.Form
        onSubmit={submitForm}
        mutators={{
          ...arrayMutators
        }}
        validate={validate}
        initialValues={{ fieldName, idGroup, formQuestion, checkbox: Boolean(formQuestion), fieldOptions: options }}
        render={(formProps) => {
          const { handleSubmit, form } = formProps
          const { checkbox } = form.getState().values
          const disabled = Boolean(isPredefined)
          submitAction.current = handleSubmit
          return (
            <form onSubmit={handleSubmit} onChange={() => setIsDirty(true)}>
              <div>
                {showGroupDropdown && (
                  <Field label={label} name='idGroup' options={groupsForSelectGroup} component={SelectGroup} autoFocus={!editMode} />
                )}
                <Field autoFocus={!editMode} label={label} name='fieldName' component={InputField} disabled={disabled} withTooltip={isPredefined} />
              </div>
              {showCheckBox && (
                <div>
                  <Field name='checkbox' type='checkbox' component={CheckboxField} checked={checkbox} label='Customize how this field will appear in forms' disabled={disabled} withTooltip={isPredefined} />
                  <Field name='formQuestion' component={FormQuestion} show={checkbox} />
                </div>
              )}
              <div {...Style.Divider} />
              <div>
                <div>Selection Options</div>
                <FieldArray name='fieldOptions'>
                  {(props) => {
                    return renderOptions({ ...props })
                  }}
                </FieldArray>
              </div>
            </form>
          )
        }}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            cancelButtonText={cancelButton.label}
            mainButtonText={submitButton.label}
            isMainSubmit
            formProps={formProps}
          />
        )}
      />
    </ToriiPopup>
  )
}

OptionsPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancelButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  submitButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.string
  }).isRequired,
  header: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  editMode: PropTypes.bool,
  autoHideOnSubmit: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  showGroupDropdown: PropTypes.bool,
  isPredefined: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

OptionsPopup.defaultProps = {
  editMode: false,
  autoHideOnSubmit: true,
  showCheckbox: true,
  showGroupDropdown: false,
  isPredefined: false
}

export default OptionsPopup
