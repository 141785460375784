import styled from 'styled-components'

export const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.palette.text.error};
`

export const SelectWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`
