import React from 'react'
import PropTypes from 'prop-types'
import { TextBadge } from '@toriihq/design-system'

export const EXECUTION_STATUSES = {
  completed: 'Completed',
  completedWithError: 'Completed with errors',
  inProgress: 'In Progress',
  error: 'Error'
}

export const getExecutionStatus = (completionTime, hasError) => {
  return completionTime
    ? (hasError ? EXECUTION_STATUSES.completedWithError : EXECUTION_STATUSES.completed)
    : (hasError ? EXECUTION_STATUSES.error : EXECUTION_STATUSES.inProgress)
}

const ExecutionStatus = ({
  completionTime,
  hasError
}) => {
  const color = completionTime ? (hasError ? 'warning' : 'success') : (hasError ? 'error' : 'gray')
  const text = getExecutionStatus(completionTime, hasError)

  return <TextBadge color={color} size='Small'>{text}</TextBadge>
}

ExecutionStatus.propTypes = {
  completionTime: PropTypes.any,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}
export default ExecutionStatus
