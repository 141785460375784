import React from 'react'
import PropTypes from 'prop-types'
import AllExpenseCategories from '../allExpenseCategories'
import ToriiPopup from '../popups/ToriiPopupV2'
import { css } from 'glamor'

const CSS = {
  popup: {
    modal: {
      width: '500px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  mainArea: css({
    width: '100%'
  })
}

const AllExpenseCategoriesPopup = ({ cancel, isOpen, close, title, ...rest }) => {
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={CSS.popup}>
      <ToriiPopup.Header header={title} />
      <ToriiPopup.Content>
        <AllExpenseCategories {...rest} />
      </ToriiPopup.Content>
    </ToriiPopup>
  )
}

AllExpenseCategoriesPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default AllExpenseCategoriesPopup
