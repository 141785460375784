import { Tag, TagSize, TagTheme } from '@toriihq/design-system'
import React from 'react'
import { Styles } from './style'

const PluginCategories = ({ categories = [] }: { categories: string[] }) => {
  return (
    <Styles.PluginCategories>
      Categories
      <Styles.PluginCategoriesTags>
        {categories.map(tag => <Tag key={tag} color='grey' theme={TagTheme.Light} size={TagSize.Medium}>{tag}</Tag>)}
      </Styles.PluginCategoriesTags>
    </Styles.PluginCategories>
  )
}

export default PluginCategories
