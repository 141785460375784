import View from './view'
import { connect } from 'react-redux'
import { forgot } from '../../pages/login/actions'

const mapStateToProps = (state) => ({
  ...state.forgot
})

const mapDispatchToProps = {
  forgot
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
