import styled from 'styled-components'

export const MultilineText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const BoldText = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle02};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const RegularText = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.tertiary};
`
