import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { SCOPES } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import EnableFor from '../../../components/enableFor'
import texts from '../../../shared/style/texts'
import VisibleFor from '@root/components/visibleFor'
import { Button, ButtonType, ButtonSize, ProgressBar } from '@toriihq/design-system'

const CSS = {
  actionsContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '5px'
  }),
  progressHeader: css(texts.heading, {
    marginRight: 'auto',
    color: colors.grey2,
    lineHeight: '26px',
    '>span': {
      marginLeft: '14px',
      color: colors.grey1
    }
  }),
  progressBar: css({
    width: '100%',
    height: '14px',
    flex: 1
  }),
  doneButtonContainer: css({
    marginLeft: 15
  }),
  progressContainer: css({
    flex: 1,
    paddingRight: 15
  }),
  mainContainer: css({
    display: 'flex'
  })
}

class OffboardingUserFooter extends React.Component {
  stopOffboarding = () => {
    const { updateUserLifecycleStatus } = this.props
    return updateUserLifecycleStatus(USER_LIFECYCLE_STATUS.ACTIVE)
  }

  finishOffboarding = () => {
    const { updateUserLifecycleStatus } = this.props
    return updateUserLifecycleStatus(USER_LIFECYCLE_STATUS.OFFBOARDED)
  }

  render () {
    const { apps, overrideStyle } = this.props
    const totalApps = apps.length
    const removedApps = apps.filter(app => app.isUserRemovedFromApp || app.isOffboardingIgnored).length
    const progress = (totalApps > 0) ? (removedApps / totalApps) : 0
    const finishButtonText = totalApps === removedApps ? 'Finish offboarding' : 'Mark as done'
    return (
      <VisibleFor scopes={[SCOPES.OFFBOARDING_READ]}>
        <div {...css(CSS.mainContainer, overrideStyle)}>
          <div {...CSS.progressContainer}>
            <div {...CSS.actionsContainer}>
              <div {...CSS.progressHeader}><span>{`${removedApps}/${totalApps}`}</span> Done</div>
              <EnableFor scopes={[SCOPES.OFFBOARDING_WRITE]}>
                <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.stopOffboarding} label='Stop offboarding' />
              </EnableFor>
            </div>
            <ProgressBar value={progress} max={1} size='Large' />
          </div>
          <div {...CSS.doneButtonContainer} id='whereisdone'>
            <EnableFor scopes={[SCOPES.OFFBOARDING_WRITE]}>
              <Button onClick={this.finishOffboarding} label={finishButtonText} type={ButtonType.secondary} />
            </EnableFor>
          </div>
        </div>
      </VisibleFor>
    )
  }
}

OffboardingUserFooter.propTypes = {
  idUser: PropTypes.number.isRequired,
  idOrg: PropTypes.number.isRequired
}

export default OffboardingUserFooter
