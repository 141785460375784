import { createSelector } from 'reselect'
import { identity, get } from 'lodash'
import moment from 'moment'

export const getBrowserActivationEmailUsersCount = createSelector(
  state => get(state, ['reports', 'extensionStatus', 'browserExtensionActivationEmail', 'usersCount']),
  identity
)

export const getBrowserActivationEmailSampleUsers = createSelector(
  state => get(state, ['reports', 'extensionStatus', 'browserExtensionActivationEmail', 'sampleUsers']),
  identity
)

export const getBrowsersCounts = createSelector(
  state => get(state, ['reports', 'extensionStatus', 'browsersCounts']),
  identity
)

export const getExtensionStatusInfo = createSelector(
  state => get(state, ['reports', 'extensionStatus']),
  identity
)

const NO_DATA = 'Never Reported'

export const getUsersForExtensionReport = createSelector(
  getExtensionStatusInfo,
  ({ users }) => {
    const endOfDay = moment().endOf('day')
    return users.map(user => {
      if (!user.lastReportedTime) {
        return { ...user, lastReportedText: NO_DATA }
      }

      const lastReportMoment = moment(user.lastReportedTime)
      const daysDiff = user.lastReportedTime ? endOfDay.diff(lastReportMoment, 'days') : -1
      return { ...user, lastReportedText: calcTimeText(daysDiff, lastReportMoment) }
    })
  }
)

const calcTimeText = (daysDiff, lastReportMoment) => {
  let timeText = NO_DATA

  if (isInRange(0, 0, daysDiff)) {
    const isToday = lastReportMoment.isSame(moment(), 'day')
    timeText = isToday ? 'Today' : 'Yesterday'
  } else if (isInRange(1, 1, daysDiff)) {
    timeText = 'Yesterday'
  } else if (isInRange(2, 7, daysDiff)) {
    timeText = `${daysDiff} days ago`
  } else if (isInRange(8, 30, daysDiff)) {
    timeText = 'More than a week ago'
  } else if (isInRange(30, Number.MAX_VALUE, daysDiff)) {
    timeText = 'More than 30 days ago'
  }
  return timeText
}

const isInRange = (rangeStart, rangeEnd, daysDiff) => {
  return (rangeStart <= daysDiff && daysDiff <= rangeEnd)
}
