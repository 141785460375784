import React from 'react'
import PropTypes from 'prop-types'
import * as Style from './style'
import StatusIcon from '@components/statusIcon'

const DataInfoBox = (props) => {
  const { status, dataDisplayName, additionalInfoText, emphasizeError, smallComment } = props
  const filteredText = additionalInfoText && additionalInfoText.length ? additionalInfoText.filter(Boolean) : null
  return (
    <Style.DataInfoContainer data-testid='dataInfoContainer'>
      <Style.CredentialStatusLine>
        <StatusIcon status={status} />
        <Style.DataDisplayName data-testid='dataDisplayName'>{dataDisplayName}</Style.DataDisplayName>
        {smallComment && <Style.SmallComment>{smallComment}</Style.SmallComment>}
      </Style.CredentialStatusLine>
      {filteredText && filteredText.map((text, index) => <Style.AdditionalInfo key={index} data-testid='additionalInfo' emphasizeError={emphasizeError}>{text}</Style.AdditionalInfo>)}
    </Style.DataInfoContainer>
  )
}

DataInfoBox.propTypes = {
  status: PropTypes.string.isRequired,
  dataDisplayName: PropTypes.string.isRequired,
  additionalInfoText: PropTypes.arrayOf(PropTypes.string),
  smallComment: PropTypes.string,
  emphasizeError: PropTypes.bool
}

DataInfoBox.defaultProps = {
  emphasizeError: false
}

export default DataInfoBox
