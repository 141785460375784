import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { isDisabledCss } from '../../shared/style/mixins'

const EnableFor = ({ isEnableForScopes, children, showAsDisabled }) => {
  if (children.length > 1) {
    throw new Error(`EnableFor element should contains only one direct child element - See error at ${children[0]._source.fileName}:${children[0]._source.lineNumber}`)
  }

  // if disabled for the given scope, we are setting the following properties to the immediate child:
  // *  disabled: true => disable href links (<a></a>)
  // *  onClick: () => {} => disable event listener
  return isEnableForScopes ? children : React.cloneElement(children, { disabled: true, onClick: () => {}, ...css(showAsDisabled ? isDisabledCss : {}) })
}

EnableFor.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowForToriiAdmin: PropTypes.bool,
  children: PropTypes.node,
  showAsDisabled: PropTypes.bool
}

EnableFor.defaultProps = {
  showAsDisabled: true
}

export default EnableFor
