import React from 'react'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import WorkflowsTemplates from '../../components/workflows/workflowsTemplates'
import { WORKFLOW_TYPES } from '../../constants'
import { css } from 'glamor'
import Analytics from '../../helpers/analytics'
import { getWorkflowPathPrefix } from '@lenses/workflows'

const CSS = {
  pageHeader: css({
    position: 'sticky',
    top: '0px',
    height: '45px'
  })
}

class WorkflowsTemplatesPage extends React.Component {
  componentDidMount () {
    const { getWorkflowsTemplates, idOrg } = this.props
    getWorkflowsTemplates({ idOrg })
  }

  createNewWorkflowFromTemplate = async (idWorkflowTemplate) => {
    const { createWorkflow, idOrg, history } = this.props
    await Analytics.track('Clicked to create a workflow by a template', {
      'Template ID': idWorkflowTemplate
    })

    const workflowInfo = (await createWorkflow({ idOrg, type: WORKFLOW_TYPES.regular, idWorkflowTemplate })).workflow
    const workflowPathPrefix = getWorkflowPathPrefix({ workflowType: WORKFLOW_TYPES.regular })
    history.push(`/team/${idOrg}/${workflowPathPrefix}/${workflowInfo.id}`)
  }

  render () {
    const { templates, loading } = this.props
    return <Page title='New Workflow'>
      <PageHeader overrideStyle={CSS.pageHeader} title={'Choose template'} />
      <WorkflowsTemplates
        loading={loading}
        templatesLists={templates}
        templateOnClick={this.createNewWorkflowFromTemplate}
      />
    </Page>
  }
}

export default WorkflowsTemplatesPage
