import React, { Fragment } from 'react'
import { css } from 'glamor'
import BasicTopBar from '../../components/basicTopBar'
import NotFoundImg from '@media/404.svg'
import { H3, Body1 } from '@toriihq/design-system'

const CSS = {
  main: css({
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
  })
}

class InvalidUrlPage extends React.Component {
  render () {
    return <Fragment>
      <BasicTopBar />
      <div {...CSS.main}>
        <img alt='Not found' src={NotFoundImg} width='110' />
        <H3>This page is no longer available</H3>
        <Body1>The link has expired</Body1>
      </div>
    </Fragment>
  }
}

export default InvalidUrlPage
