import React from 'react'
import Select from '@components/select'
import { AUTH_TYPES } from '@root/constants'
import FormGroup from '@components/form/formGroup'

const AuthOptions = ({ label, input, scimConfig = {}, meta: { error, touched }, ...props }) => {
  return (
    <FormGroup label={label} error={touched && error}>
      <Select
        options={[
          { value: AUTH_TYPES.BASIC, label: 'Basic' },
          { value: AUTH_TYPES.TOKEN, label: 'Token' },
          { value: AUTH_TYPES.BEARER, label: 'Bearer Token' }
        ]}
        disabled={scimConfig.auth !== undefined}
        clearable={false}
        placeholder='Select...'
        name='auth'
        {...input}
        {...props}
        onChange={selection => {
          if (selection) {
            input.onChange(selection.value)
          }
        }}
        onBlur={() => input.onBlur(input.value.value)}
      />
    </FormGroup>
  )
}

export default AuthOptions
