import React, { ReactElement, ReactNode } from 'react'
import { Position } from 'reactflow'
import { Container } from './styles'
import { IdWorkflowUiNode } from '@shared/workflows/actions/types'
import { useWorkflowNavigation } from '@root/pages/workflow_v2/hooks/useWorkflowNavigation'
import { isInnerButtonEvent } from '@shared/utils'
import { TransparentHandle } from '../../transparentHandle'
import { WORKFLOW_NODE_CLASS_NAME } from '@pages/workflow_v2/workflowEditor/workflowGraph/consts'

interface Props {
  id: IdWorkflowUiNode
  children: ReactNode
}

export const WorkflowNodeWrapper = ({ id, children }: Props): ReactElement => {
  const { idSelectedNode, selectNode } = useWorkflowNavigation()

  const isSelected = idSelectedNode === id
  const className = `${WORKFLOW_NODE_CLASS_NAME}${isSelected ? ' selected' : ''}`

  const handleNodeClick = e => {
    const isInnerButtonClick = isInnerButtonEvent(e)
    !isInnerButtonClick && !isSelected && selectNode(id)
  }

  return (<>
    <TransparentHandle type='target' position={Position.Top} />
    <Container
      className={className}
      onClick={handleNodeClick}
    >
      {children}
    </Container>
    <TransparentHandle type='source' position={Position.Bottom} />
  </>)
}
