import colors from '@shared/style/colors'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import { CHECKBOX_SIZE } from '@shared/style/sizes'

export const Row = css({
  minHeight: '60px'
})

export const Column = css({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center'
})

export const RowOnHover = css({
  '&:hover': {
    background: `${colors.lightBlue3} !important`
  }
})

export const RowBorder = css({
  borderBottom: `1px solid ${colors.lightBlue2}`
})

export const Header = css(texts.subheading, {
  boxShadow: 'none',
  padding: '0',
  cursor: 'pointer'
})

export const Placeholder = {
  padding: '10px 0',
  minWidth: `${CHECKBOX_SIZE}px`
}
