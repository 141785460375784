
import { State, ConnectedAppUsersStatusesBreakdown, ActionType } from './app.types'
import { Action } from './types'

const initialState: State = {
  connectedAppUsersStatusesBreakdown: {
    allAppAccountsBreakdown: null,
    breakdownByAppAccountName: null,
    distinctAppUsers: null,
    isLoading: false,
    isErrorLoading: false
  }
}

const appReducer = (state: State = initialState, action: Action<ActionType, unknown>): State => {
  switch (action.type) {
    case `GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN_PENDING`: {
      return {
        ...state,
        connectedAppUsersStatusesBreakdown: {
          ...initialState.connectedAppUsersStatusesBreakdown,
          isLoading: true,
          isErrorLoading: false
        }
      }
    }
    case `GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN_FAILED`: {
      return {
        ...state,
        connectedAppUsersStatusesBreakdown: {
          ...initialState.connectedAppUsersStatusesBreakdown,
          isLoading: false,
          isErrorLoading: true
        }
      }
    }
    case `GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN_RESPONSE`: {
      return {
        ...state,
        connectedAppUsersStatusesBreakdown: {
          isLoading: false,
          isErrorLoading: false,
          ...(action.payload as ConnectedAppUsersStatusesBreakdown)
        }
      }
    }

    default: {
      return state
    }
  }
}

export default appReducer
