
import { useEffect } from 'react'

interface Props {
    idSelectedNode: string | undefined,
    idSelectedNodeStep: string | undefined,
    isTriggerNode: boolean,
    selectNodeStep: (step: string) => void,
    isDrawerOpen: boolean,
    actionSelected: boolean
}
export const useSelectNodeStep = ({
  idSelectedNode,
  idSelectedNodeStep,
  isTriggerNode,
  selectNodeStep,
  isDrawerOpen,
  actionSelected
}: Props) => {
  useEffect(() => {
    if (isDrawerOpen && !idSelectedNodeStep) {
      const selectActionStepOrTriggerIndex = '0'
      const firstActionConfigurationStepIndex = '1'
      selectNodeStep(!actionSelected || isTriggerNode ? selectActionStepOrTriggerIndex : firstActionConfigurationStepIndex)
    }
  }, [idSelectedNodeStep, idSelectedNode, isTriggerNode, selectNodeStep, isDrawerOpen, actionSelected])
}
