import React from 'react'
import PropTypes from 'prop-types'
import { APP_PERMISSIONS, SCOPES } from '@root/constants'
import SelectionBox from '@components/workflows/selectionBox'
import * as Style from '../../style'

const readDescription = 'Allow Torii to access user lists, usage and license data.'
const writeDescription = 'Allow Torii to access user lists, usage and license data, and manage your users.'

const SelectPermission = ({ value, onChange, disableReadOption = false }) => {
  const permissionFields = [
    {
      name: 'Permissions',
      options: [
        { value: APP_PERMISSIONS.read, label: 'Read', description: readDescription, isDisabled: disableReadOption },
        { value: APP_PERMISSIONS.read_write, label: 'Read and take action', description: writeDescription }
      ]
    }
  ]

  return <div {...Style.PermissionContainer}>
    <SelectionBox
      header='Choose permissions'
      selectedValue={value}
      groups={permissionFields}
      onChange={selection => onChange(selection.value)}
      display='row'
      hideHeader
      allowedScopes={[SCOPES.INTEGRATIONS_WRITE]}
    />
  </div>
}

SelectPermission.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
export default SelectPermission
