import React from 'react'
import FormGroup from '@components/form/formGroup'
import Input from '@components/form/input'

const TextField = (props) => {
  const { meta: { submitSucceeded, submitting, error, touched } } = props

  return (
    <div>
      <FormGroup error={touched && error} label={props.label}>
        <Input
          disabled={submitting || submitSucceeded}
          error={touched && error}
          autoComplete='new-password'
          {...props}
          {...props.input}
        />
      </FormGroup>
    </div>
  )
}

export default TextField
