import { CONTRACT_FIELDS_TO_REMOVE_FROM_DUPLICATE, CONTRACT_STATUS, formFieldTypes } from '@root/constants'
import omit from 'lodash/omit'

export const setDuplicatedContract = (contract, contractsFields) => {
  const invalidFields = findInvalidFields(contract, contractsFields)
  const details = omit(contract, [...CONTRACT_FIELDS_TO_REMOVE_FROM_DUPLICATE, ...invalidFields])
  return {
    ...details,
    name: `${contract.name} copy`,
    status: CONTRACT_STATUS.active
  }
}

const findInvalidFields = (contract, contractFields) => {
  const invalidFields = []
  const dropdownFieldTypes = [formFieldTypes.dropdown, formFieldTypes.dropdownMulti]

  const isFieldDropdown = field => dropdownFieldTypes.includes(field.type)
  const optionsContainsValue = (field, value) => {
    if (Array.isArray(value)) {
      return value.every(val => field.options.find(option => option.value === val))
    } else {
      return field.options.find(option => option.value === value)
    }
  }
  for (const systemKey in contract) {
    const contractField = contractFields.find(field => field.systemKey === systemKey)
    if (contractField && isFieldDropdown(contractField) && !optionsContainsValue(contractField, contract[systemKey])) {
      invalidFields.push(systemKey)
    }
  }
  return invalidFields
}
