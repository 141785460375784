import Analytics from '@helpers/analytics'
import { AnalyticsTrackConnectAppAccountClickParams } from '@shared/services/openConnectServicePopup/types'

export const analyticsTrackConnectAppAccountClick = ({
  calledFrom,
  integrationTypeText,
  appName
}: AnalyticsTrackConnectAppAccountClickParams) => {
  Analytics.track(`Connect app-account`, {
    'App name': appName,
    'Open from': calledFrom,
    'Integration type': integrationTypeText
  })
}
