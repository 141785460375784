
export const TRANSPARENT = 'transparent'

export const bucketDisplayName = {
  COST_INCREASED: 'Cost increased',
  COST_DECREASED: 'Cost decreased',
  NO_CHANGE: 'No change',
  APP_IS_CLOSED: 'App is closed',
  NEW_CONTRACT: 'New contract',
  NOT_RENEWED_YET: 'Not renewed yet',
  NOT_RENEWING_THIS_YEAR: 'Not renewing this year',
  MISSING_CONTRACT: 'Missing contract'
}

export const graphBucketDisplayName = {
  notRenewingThisYear: 'Not renewing this year',
  upForRenewal: 'Up for renewal',
  newContracts: 'New contracts',
  costIncrease: 'Cost increase',
  costDecrease: 'Cost decrease',
  closedApp: 'Closed app',
  renewalForecast: 'Renewal forecast',
  renewed: 'Renewed contracts',
  notRenewedYet: 'Not renewed yet',
  missingContracts: 'Missing contracts'
}

export const bucketSummarySortOrder = {
  COST_INCREASED: 1,
  COST_DECREASED: 2,
  NO_CHANGE: 3,
  APP_IS_CLOSED: 4,
  NEW_CONTRACT: 5,
  NOT_RENEWED_YET: 6,
  NOT_RENEWING_THIS_YEAR: 7,
  MISSING_CONTRACT: 8
}

export enum RenewalBuckets {
  notRenewedYet = 'NOT_RENEWED_YET',
  notRenewingThisYear = 'NOT_RENEWING_THIS_YEAR',
  newContract = 'NEW_CONTRACT',
  costIncreased = 'COST_INCREASED',
  costDecreased = 'COST_DECREASED',
  noChange = 'NO_CHANGE',
  appIsClosed = 'APP_IS_CLOSED',
  missingContract = 'MISSING_CONTRACT'
}
