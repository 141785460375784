import React from 'react'
import { css } from 'glamor'

const CSS = {
  statusLeverOuter: css({
    width: '17px',
    height: '17px',
    borderRadius: '17px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px'

  }),
  statusLeverInner: css({
    width: '7px',
    height: '7px',
    borderRadius: '4px'
  })
}

const success = {
  innerColor: 'rgba(65, 189, 101, 1)',
  outerColor: 'rgba(227, 246, 232, 1)'
}

const failure = {
  innerColor: 'rgba(250, 76, 80, 1)',
  outerColor: 'rgba(255, 241, 241, 1)'
}

const warning = {
  innerColor: 'rgba(250, 220, 122)',
  outerColor: 'rgba(250, 220, 122, 0.2)'
}

const unprocessed = {
  innerColor: 'rgba(191, 196, 205)',
  outerColor: 'rgba(191, 196, 205, 0.2)'
}

const statusesByLabel = {
  success: success,
  failure: failure,
  warning: warning,
  unprocessed: unprocessed
}

class StatusIcon extends React.Component {
  render () {
    const { status } = this.props
    const { innerColor, outerColor } = statusesByLabel[status] || statusesByLabel.unprocessed
    return (
      <div data-testid='statusIcon' {...CSS.statusLeverOuter} style={{ backgroundColor: outerColor }}>
        <div {...CSS.statusLeverInner} style={{ backgroundColor: innerColor }} />
      </div>
    )
  }
}

StatusIcon.statuses = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  WARNING: 'warning',
  UNPROCESSED: 'unprocessed'
}

export default StatusIcon
