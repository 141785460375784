import styled from 'styled-components'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0 8px 0;
`

export const HeaderWrapper = styled.div`
  padding: 6px 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ContentHeader = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  background-color: ${({ theme }) => theme.palette.text.inverse};
  z-index: 2;
  position: sticky;
  top: 0;
`

export const ContentHeaderTitle = styled.span`
  flex: 1 0 0;
  font: ${({ theme }) => theme.typography.font.subtitle01};
  color: ${({ theme }) => theme.palette.text.primary};
`
