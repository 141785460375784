import React, { useEffect, useState } from 'react'
import { ScheduleTypeSelector } from './components/scheduleTypeSelector'
import { CustomScheduleEditor } from './components/customScheduleEditor'
import { CustomScheduleEditorContainer, FieldsContainer } from './styles'
import { SchedulesType } from '@shared/workflows/types'
import { utcTimezone } from './consts'

interface Props {
  schedules: SchedulesType | null
  onSchedulesChange: (params: { schedules: SchedulesType | null }) => void
}

export const WorkflowSchedule = ({
  schedules,
  onSchedulesChange
}: Props): JSX.Element => {
  const [isCustomSchedule, setIsCustomSchedule] = useState<boolean>()
  const [selectedCron, setSelectedCron] = useState<string | null>()
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>()

  useEffect(() => {
    if (schedules) {
      setIsCustomSchedule(true)
      const isScheduleDataExists = schedules.schedules?.length > 0
      if (isScheduleDataExists) {
        const { cron } = schedules.schedules[0]
        setSelectedCron(cron)
      }
      if (schedules.timezone) {
        setSelectedTimezone(schedules.timezone)
      }
    } else {
      setIsCustomSchedule(false)
    }
  }, [schedules])

  const handleScheduleTypeChange = ({ isCustomSchedule: selectedIsCustomSchedule }) => {
    setIsCustomSchedule(selectedIsCustomSchedule)

    if (selectedIsCustomSchedule) {
      handleCustomScheduleChange()
    } else {
      onSchedulesChange({ schedules: null })
    }
  }

  const handleCustomScheduleChange = (
    { scheduleCron: selectedScheduleCron, scheduleTimezone }:
    { scheduleCron?: string | null, scheduleTimezone?: string | null } = {}
  ) => {
    selectedScheduleCron && setSelectedCron(selectedScheduleCron)
    scheduleTimezone && setSelectedTimezone(scheduleTimezone)
    const cron = selectedScheduleCron ?? selectedCron
    const newSchedules: SchedulesType = {
      timezone: scheduleTimezone ?? selectedTimezone ?? utcTimezone,
      schedules: cron ? [{ cron }] : []
    }

    onSchedulesChange({ schedules: newSchedules })
  }

  return (
    <>
      <ScheduleTypeSelector
        isCustomSchedule={isCustomSchedule ?? false}
        onScheduleTypeChange={handleScheduleTypeChange}
      />
      {isCustomSchedule && <FieldsContainer>
        <CustomScheduleEditorContainer>
          <CustomScheduleEditor
            scheduleCron={selectedCron}
            onScheduleChange={handleCustomScheduleChange}
            timezone={selectedTimezone}
          />
        </CustomScheduleEditorContainer>
      </FieldsContainer>}
    </>
  )
}
