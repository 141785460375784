import React from 'react'
import Table from '../table'
import { DATE_FORMAT, formFieldTypes } from '../../constants'
import { getValue } from '../../shared/utils'
import UserDetails from '../userDetails'
import { dateTimeColumn } from '../workflowExecutions/columns'
import RelativeTeamLink from '../relativeTeamLink'
import { fontWeight } from '../../shared/style/sizes'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import get from 'lodash/get'
import { getContractCurrencyCode } from '@components/contracts/utils'
import { getDisplayName } from '@lenses/users'
import { Icon, Link, TextBadge } from '@toriihq/design-system'

const CSS = {
  field: css({
    color: colors.black,
    paddingBottom: 27,
    fontSize: 12
  }),
  multiLine: css({
    whiteSpace: 'pre-line',
    width: '100%'
  })
}

class DetailHistoryTable extends React.Component {
  searchFilterMethod = (row, search) => {
    row.values = row.type === formFieldTypes.currency ? getValue(row.values) : row.values
    const values = [
      ...row.labels,
      ...row.values,
      get(row, ['performedBy', 'email']),
      [get(row, ['performedBy', 'firstName']), get(row, ['performedBy', 'lastName'])].join(' '),
      row.creationTime
    ]

    return values.some(value => value && String(value).toLowerCase().includes(search))
  }
  render () {
    const { data, detail, usersMap, loading, defaultSort, close, tableStyle, currencySymbol, currencySymbolsByCode, tableKey } = this.props
    return (
      <Table
        tableKey={tableKey}
        emptyStateMessage={<div>No History</div>}
        data={data}
        searchable
        searchFilterMethod={this.searchFilterMethod}
        header={`CHANGES (${data.length})`}
        style={tableStyle}
        columns={[
          {
            Header: 'Changed to',
            accessor: 'labels',
            Cell: ({ value: labels, row: { values } }) => {
              const value = (labels.length && labels.join(', ')) || getValue(values).join(', ')
              if (isEmpty(value)) {
                return <TextBadge color='gray' size='Small'>Deleted</TextBadge>
              }
              switch (detail.type) {
                case formFieldTypes.currency: {
                  const displayCurrencySymbol = get(currencySymbolsByCode, [getContractCurrencyCode(values[0])], currencySymbol)
                  return `${displayCurrencySymbol}${value / 100}`
                }
                case formFieldTypes.cardNumber: {
                  return `XXXX XXXX XXXX ${value}`
                }
                case formFieldTypes.datePicker: {
                  return moment.utc(value).format(DATE_FORMAT)
                }
                case formFieldTypes.multiLine: {
                  return <span {...CSS.multiLine}>{value}</span>
                }
                case formFieldTypes.usersDropdown: {
                  const user = usersMap[value]
                  return <div onClick={close}>
                    <UserDetails {...user} idUser={value} linkToUserPage />
                  </div>
                }
                case formFieldTypes.usersDropdownMulti: {
                  return values.map(id => usersMap[id] && getDisplayName(usersMap[id])).join(', ')
                }
                case formFieldTypes.contractsDropdownMulti: {
                  const contracts = detail.options.filter(option => values.includes(String(option.value)))
                  return contracts.map(contract => contract.label).join(', ')
                }
                default: {
                  return value
                }
              }
            },
            width: 400
          },
          {
            Header: 'Changed at',
            id: 'creationTime',
            accessor: 'creationTime',
            ...dateTimeColumn,
            width: 200,
            sortable: true
          },
          {
            Header: 'Changed by',
            id: 'performedBy',
            accessor: 'performedBy',
            Cell: ({ value, row: { workflowActionExecution } }) => {
              if (value && value !== -1) {
                const user = usersMap[value] || {}
                return <div onClick={close}>
                  <UserDetails {...user} isSupportTeamUser={user.isSupport} idUser={user.id} linkToUserPage />
                </div>
              }

              if (workflowActionExecution) {
                return <RelativeTeamLink nav onClick={close} to={`/workflow/${workflowActionExecution.idWorkflow}/executions/${workflowActionExecution.idWorkflowExecution}`}><Link>Workflow</Link></RelativeTeamLink>
              }

              return <div style={{ display: 'flex', alignItems: 'center', gap: '4px', fontWeight: fontWeight.semiBold }}><Icon name='Torii' />Torii</div>
            },
            width: 200,
            show: !detail.isPluginGroup
          },
          {
            accessor: 'values',
            show: false
          },
          {
            accessor: 'workflowActionExecution',
            show: false
          }
        ]}
        defaultSort={defaultSort}
        loading={loading}
      />
    )
  }
}

export default DetailHistoryTable
