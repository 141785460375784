import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { Spacer } from '@toriihq/design-system'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import { get, isEmpty } from 'lodash'
import isArray from 'lodash/isArray'
import { updateActionFieldValue } from '@shared/workflows/updateActionFieldValue'
import EditWorkflowAction from '../configureAppForOffboardingPopup/editWorkflowAction'
import UsersIconsList from '../../usersIconsList'
import EmailPreviewButton from '../../workflows/emailSetup/emailPreviewButton'
import Placeholder from '../../placeholder'
import Analytics from '../../../helpers/analytics'
import { SCOPES } from '@root/constants'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'

const HIDDEN_FIELDS_IDS = ['to']

const CSS = {
  popup: {
    modal: { width: '473px' }
  },
  employeesCount: css(texts.body, {
    lineHeight: '7px',
    color: colors.black
  }),
  placeholder: {
    margin: '30px 0 0',
    height: '483px',
    maxWidth: '100%',
    fontSize: '30px'
  },
  formArea: css({
    margin: '21px 0 -26px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end'
  }),
  workflowActions: css({
    width: '100%'
  }),
  emailSetupPreview: css({
    display: 'flex',
    flexShrink: 1,
    marginBottom: '-45px',
    padding: 0
  })
}

class ConfigureBrowserExtensionActivationEmailPopup extends React.Component {
  state = { action: this.props.action }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    const { idOrg, createAction, actionType, actionsConfig } = this.props
    if (prevProps.idOrg !== idOrg) {
      this.fetchData()
    }
    if (prevProps.actionsConfig !== actionsConfig && !isEmpty(actionsConfig)) {
      const action = createAction(actionType)
      this.setState({ action })
    }
  }

  fetchData () {
    const { idOrg, getWorkflowsTriggersConfig, getWorkflowsActionsConfig, getBrowserExtensionActivationUsers } = this.props
    getWorkflowsActionsConfig(idOrg)
    getWorkflowsTriggersConfig()
    getBrowserExtensionActivationUsers({ idOrg })
  }

  onFieldChange = ({ selectedValue, fieldId }) => {
    const { action } = this.state

    const isValid = true
    const updatedAction = updateActionFieldValue(action, selectedValue, fieldId, isValid)
    this.setState({ action: updatedAction })
  }

  onSubmit = async () => {
    const { setupRunActionWorkflow, close } = this.props
    const { action } = this.state

    await setupRunActionWorkflow({ action })
    close()
    Analytics.track(`Clicked on "Send" button in "Send browser extension activation email" popup`)
  }

  isDataValid = () => {
    const { actionsConfig } = this.props
    const { action } = this.state

    if (!action.type) {
      return false
    }

    let isDataValid = true
    const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === action.type) || { inputSchema: [] }
    Object.keys(actionConfig.inputSchema).forEach(fieldId => {
      const fieldConfig = actionConfig.inputSchema[fieldId]
      const fieldInAction = action.fields.find(field => field.id === fieldConfig.id)

      let isHiddenField = false
      if (fieldConfig.showOnFieldSpecificValue) {
        const dependsOnField = action.fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
        const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField.value
        isHiddenField = dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
      }

      if (!isHiddenField && fieldConfig.validations.includes('required') && (!fieldInAction.value || (isArray(fieldInAction.value) && fieldInAction.value.length === 0))) {
        isDataValid = false
      }
    })

    return isDataValid
  }

  render () {
    const { cancel, isOpen, triggerType, usersCount, sampleUsers } = this.props
    const { action } = this.state

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={CSS.popup}>
        <ToriiPopup.Header header={'Send Browser Extension Activation Email'} />
        <ToriiPopup.Content>
          <Fragment>
            <div {...CSS.employeesCount}>{`Send browser extension activation email to ${pluralize('employee', usersCount, true)}`}</div>
            <Spacer top={'space-200'}>
              <UsersIconsList users={sampleUsers} maxUsers={5} totalUsersCount={usersCount} />
            </Spacer>
            <Placeholder loading={!action.type} rows={10} style={CSS.placeholder}>
              <div {...CSS.formArea}>
                <EmailPreviewButton overrideStyle={CSS.emailSetupPreview} actionType={action.type} />
                <div {...CSS.workflowActions}><EditWorkflowAction triggerType={triggerType} action={action} onChange={this.onFieldChange} noBorder hiddenFieldsIds={HIDDEN_FIELDS_IDS} /></div>
              </div>
            </Placeholder>
          </Fragment>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          cancelButtonText={'Cancel'}
          mainButtonText={'Send'}
          mainButtonAction={this.onSubmit}
          isMainButtonDisabled={!this.isDataValid()}
          scopes={[SCOPES.AUTOMATION_WRITE]}
        />
      </ToriiPopup>
    )
  }
}

ConfigureBrowserExtensionActivationEmailPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  idOrg: PropTypes.number
}

export default ConfigureBrowserExtensionActivationEmailPopup
