import styled from 'styled-components'
import { Icon } from '@toriihq/design-system'

export const HeaderTooltipLabel = styled.div`
  text-align: center;
  max-width: 200px;
`

export const StyledIcon = styled(Icon)`
     height: 16px;
     width: 16px;
     border-radius: 50%;
     line-height: 18px;
     text-align: center;
     transform: none;
`
