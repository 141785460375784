import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { RadioButton } from '@toriihq/design-system'

import colors from '../../../../shared/style/colors'
import texts from '../../../../shared/style/texts'
import { clearButton } from '../../../../shared/style/mixins'
import ComingSoonBadge from '../comingSoonBadge'
import { noop } from 'lodash'

const CSS = {
  listItem: css(clearButton, {
    display: 'block',
    width: '100%',
    height: '82px',
    borderTop: `1px solid ${colors.border}`,
    backgroundColor: colors.white,
    transition: 'backgroundColor .2s',
    ':hover': {
      backgroundColor: colors.background
    },
    marginLeft: '-10px',
    marginRight: '-10px',
    cursor: 'pointer'
  }),
  option: css({
    position: 'relative',
    textAlign: 'left',
    width: '100%',
    height: '100%',
    display: 'flex',
    background: colors.transparent,
    border: 'none',
    padding: '0 20px',
    margin: 0,
    outline: 'none',
    alignItems: 'center'
  }),
  optionDescription: css({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '35px'
  }),
  label: css({
    display: 'flex',
    color: colors.darkText,
    alignItems: 'center'
  }),
  description: css(texts.subheading, {
    color: colors.grey1
  }),
  disabled: css({
    cursor: 'not-allowed !important',
    opacity: 0.5
  })
}

const SelectionRowItem = ({ label, description, isSelected, comingSoon, disabled, src, onChange }) => (
  <div {...css(CSS.listItem, disabled && CSS.disabled)} disabled={disabled} onClick={disabled ? noop : onChange}>
    <div {...CSS.option}>
      <RadioButton disabled={disabled} checked={isSelected} />
      <div {...CSS.optionDescription}>
        <div {...CSS.label}>
          <div {...texts.heading}>{label}</div>
          {comingSoon && <ComingSoonBadge />}
        </div>
        <div {...CSS.description}>{description}</div>
      </div>
    </div>
  </div>
)

SelectionRowItem.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  isSelected: PropTypes.bool,
  src: PropTypes.string,
  onChange: PropTypes.func,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool
}

export default SelectionRowItem
