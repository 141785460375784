import React from 'react'
import { css } from 'glamor'
import Select from '../select'
import EnableFor from '../enableFor'
import { SCOPES } from '../../constants'
import Placeholder from '../placeholder'
import FormGroup from '../form/formGroup'
import keyBy from 'lodash/keyBy'
import { Icon, Button, ButtonType, ButtonSize, H4, Link, AlertBox, AlertBoxType, Stack, Subtitle2, Body2 } from '@toriihq/design-system'
import colors from '@shared/style/colors'

const CSS = {
  wrapper: css({
    width: '750px',
    maxWidth: '100%'
  }),
  nonEditModeText: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 15px',
    backgroundColor: colors.background,
    alignItems: 'center'
  }),
  description: css({
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  })
}

const EXTENSION_MODE_OPTIONS = [
  { value: 'enabled', label: 'Authenticated mode (recommended)', description: 'Torii collects data only from authenticated employees' },
  { value: 'enabledUnauthenticated', label: 'Un-Authenticated mode', description: 'Torii collects data from all employees', icon: 'Alert' },
  { value: 'disabled', label: 'Disabled', description: 'Torii does not collect any data' }
]
const EXTENSION_MODE_BY_TYPE = keyBy(EXTENSION_MODE_OPTIONS, 'value')

class BrowserExtensionSettings extends React.Component {
  state = { editMode: false }

  onExtensionModeChanged = (option) => {
    const { idOrg, updateOrg } = this.props
    const extensionMode = option.value
    updateOrg({ idOrg, extensionMode })
    this.toggleEditMode()
  }

  optionRenderer = ({ data: option }) => {
    return <div>
      <Stack direction='row' gap='space-050'>
        <Subtitle2 color='primary'>{option.label}</Subtitle2>
        {option.icon && <Icon name={option.icon} />}
      </Stack>
      <Body2 color='secondary'>{option.description}</Body2>
    </div>
  }

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode })
  }

  render () {
    const { loading, extensionMode } = this.props
    const { editMode } = this.state

    return (
      <Stack gap='space-300'>
        <H4>Browser Extension</H4>
        <Placeholder loading={loading} rows={5} style={{ maxWidth: '80%', marginTop: '10px' }}>
          <div {...CSS.wrapper}>
            <FormGroup label='Extension mode'>
              <div {...CSS.description}>
                <span>Use browser extension to detect user logins to business applications.</span>
                <Link href='https://support.toriihq.com/hc/en-us/articles/5146418720539' target='_blank'>
                  Learn more about Torii extension
                </Link>
              </div>
              <Placeholder loading={loading} type='rect' style={{ width: '100%' }}>
                <Stack gap='space-200'>
                  {editMode
                    ? <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                      <Select
                        options={EXTENSION_MODE_OPTIONS}
                        optionRenderer={this.optionRenderer}
                        value={extensionMode}
                        onChange={this.onExtensionModeChanged}
                        clearable={false}
                        searchable={false}
                        key='extension_mode_fields_select'
                        name='extension_mode_fields_select'
                        openOnFocus
                        autoFocus
                      />
                    </EnableFor>
                    : (extensionMode && <div {...CSS.nonEditModeText}>
                      <span>{EXTENSION_MODE_BY_TYPE[extensionMode].label}</span>
                      <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.toggleEditMode} icon='Edit' />
                      </EnableFor>
                    </div>)
                  }

                  {extensionMode === EXTENSION_MODE_BY_TYPE.enabledUnauthenticated.value &&
                    <AlertBox
                      type={AlertBoxType.NOTICE}
                      description='Your account is now open for unauthenticated data from any source. We recommend changing this configuration.'
                    />
                  }
                </Stack>
              </Placeholder>
            </FormGroup>
          </div>
        </Placeholder>
      </Stack>
    )
  }
}

export default BrowserExtensionSettings
