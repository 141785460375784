import React, { ReactElement, useState } from 'react'
import Placeholder from '@components/placeholder'
import { Styles } from './style'

type Props = {
  src: string
  alt: string
}

export const PopupImage = ({ src, alt }: Props): ReactElement => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  return (
    <div>
      {!isImageLoaded && <Placeholder loading={!isImageLoaded} type={'rect'} style={Styles.placeholderStyle} />}
      <Styles.Image src={src} alt={alt} onLoad={() => setIsImageLoaded(true)} />
    </div>
  )
}
