import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Card from '../../card'
import colors from '../../../shared/style/colors'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import ToriiLogo from '@media/torii_logo.svg'

const CSS = {
  main: css({
    marginTop: '40px',
    marginLeft: '15px',
    marginRight: '15px'
  }),
  card: css({
    maxWidth: '500px',
    margin: 'auto',
    textAlign: 'center',
    padding: '40px',
    ' > h3': {
      color: colors.darkText,
      fontSize: '25px',
      fontWeight: fontWeight.semiBold,
      lineHeight: '35px',
      marginTop: '25px'

    },
    ' > div': {
      color: colors.darkText,
      fontSize: fontSize.medium,
      lineHeight: '22px'
    }
  })
}
const MessagePage = ({ headerText, children }) => (
  <div {...CSS.main}>
    <Card {...CSS.card}>
      <img alt='toro' src={ToriiLogo} width='54' />
      <h3>{headerText}</h3>
      <div>
        {children}
      </div>
    </Card>
  </div>
)

MessagePage.propTypes = {
  headerText: PropTypes.string,
  children: PropTypes.node
}

export default MessagePage
