import analytics from '@root/helpers/analytics'

export const Analytics = {
  onFilterUsers: (appName, recommendationType, columnName) => {
    const props = {
      'App name': appName,
      'Recommendation type': recommendationType,
      'Column name': columnName
    }
    analytics.track('Filter recommendation-users', props)
  },

  onExportCSV: (appName, recommendationType) => {
    const props = {
      'App name': appName,
      'Recommendation type': recommendationType
    }
    analytics.track('Export recommendation-users', props)
  },

  onSearch: (appName, recommendationType, searchTerm) => {
    const props = {
      'App name': appName,
      'Recommendation type': recommendationType,
      'Search term': searchTerm
    }
    analytics.track('Search recommendation-users', props)
  },

  onSort: (appName, recommendationType, columnName) => {
    const props = {
      'App name': appName,
      'Recommendation type': recommendationType,
      'Column name': columnName
    }
    analytics.track('Sort recommendation-users', props)
  },
  onActionClick: (appName, recommendationType, buttonLabel, entities) => {
    const props = {
      'App name': appName,
      'Recommendation type': recommendationType,
      'Button label': buttonLabel,
      'Entities': entities
    }
    analytics.track('Click on recommendation-action-button', props)
  },
  onUsageExplanation: ({ isOpen, appName, recommendationType }) => {
    const props = {
      'App name': appName,
      'Recommendation type': recommendationType
    }
    analytics.track(`${isOpen ? 'Open' : 'Close'} usage-explanation-dialog`, props)
  }
}

export default Analytics
