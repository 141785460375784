import View from './view'
import { connect } from 'react-redux'
import { login, isLoginUsingSAML, getMe, getIsLoggedIn, signup, resetLogout } from '@pages/login/actions'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import { isCatalog, isExtensionLogin } from '@lenses/utils'
import { safeRedirect } from '@shared/utils'
import { getSelf } from '@selectors/me'
import { getLogin, getLogout } from '@selectors/login'
import { getSignup } from '@selectors/signup'

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps
  const query = qs.parse(location.search)
  const { authState } = getSelf(state)
  const login = getLogin(state)
  const logout = getLogout(state)
  const { signup } = getSignup(state)

  return {
    loginState: login,
    logoutState: logout,
    isEmailSent: signup.success,
    isFromCatalog: isCatalog(query.next),
    isExtensionLogin: isExtensionLogin(query.next),
    authState,
    next: query.next,
    onLoginSuccess: (response) => {
      const url = (response && response.loginUrl) || query.next || window.location.origin + '/team'
      safeRedirect(url)
    }
  }
}

const mapDispatchToProps = {
  login,
  isLoginUsingSAML,
  getMe,
  getIsLoggedIn,
  instantAccessLogin: signup,
  resetLogout
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
