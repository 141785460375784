import sumBy from 'lodash/sumBy'
import { graphBucketDisplayName, RenewalBuckets } from './utils'

export const COUNT_SUFFIX = '_count'
export const ACTUAL_VALUE_SUFFIX = '_actualValue'
export const BREAKDOWN_SUFFIX = '_breakdown'

export const calculateGraphData = ({ baseYear, baseYearRenewals }) => {
  const calculateSum = (filterFunction, key) => {
    const filteredRenewals = baseYearRenewals.filter(filterFunction)
    const sum = Math.round((sumBy(filteredRenewals, key) / 100) * 100) / 100
    const renewalsCount = filteredRenewals.length
    return { sum, renewalsCount }
  }
  const notRenewingThisYearPrevYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.notRenewingThisYear, 'previousYearAnnualValue')
  const notRenewingThisYearBaseYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.notRenewingThisYear, 'baseYearAnnualValue')
  const upForRenewal = calculateSum(renewal => renewal.bucket !== RenewalBuckets.notRenewingThisYear, 'previousYearAnnualValue')
  const costDecreasedDiff = calculateSum(renewal => renewal.bucket === RenewalBuckets.costDecreased, 'diffWithPreviousYear')
  const costDecreasedBaseYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.costDecreased, 'baseYearAnnualValue')
  const closedAppDiff = calculateSum(renewal => renewal.bucket === RenewalBuckets.appIsClosed, 'diffWithPreviousYear')
  const closedAppBaseYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.appIsClosed, 'baseYearAnnualValue')
  const costIncreasedDiff = calculateSum(renewal => renewal.bucket === RenewalBuckets.costIncreased, 'diffWithPreviousYear')
  const costIncreasedBaseYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.costIncreased, 'baseYearAnnualValue')
  const newContractsDiff = calculateSum(renewal => renewal.bucket === RenewalBuckets.newContract, 'diffWithPreviousYear')
  const newContractsBaseYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.newContract, 'baseYearAnnualValue')
  const notRenewedYet = calculateSum(renewal => renewal.bucket === RenewalBuckets.notRenewedYet, 'previousYearAnnualValue')
  const missingContracts = calculateSum(renewal => renewal.bucket === RenewalBuckets.missingContract, 'previousYearAnnualValue')
  const noChangeBaseYear = calculateSum(renewal => renewal.bucket === RenewalBuckets.noChange, 'previousYearAnnualValue')
  const renewalForecast = calculateSum(renewal => renewal.bucket === RenewalBuckets.notRenewedYet || renewal.bucket === RenewalBuckets.missingContract, 'previousYearAnnualValue')

  const getMinValueForBarForRendering = () => {
    // this function calculates the minimum value for the bar to be rendered. the highest bars are around 200px high, and we want the smallest bars to be around 4px high
    const startBarValue = notRenewingThisYearPrevYear.sum + upForRenewal.sum
    const endBarValue = notRenewingThisYearBaseYear.sum + costDecreasedBaseYear.sum + closedAppBaseYear.sum + costIncreasedBaseYear.sum + newContractsBaseYear.sum + renewalForecast.sum
    const highBarValue = Math.max(startBarValue, endBarValue)
    return highBarValue * 0.018
  }

  const minValueForBarForRendering = getMinValueForBarForRendering()

  const getBarValue = (value) => {
    // this function returns the value for the bar to be rendered. if the value is 0, it returns 0. otherwise it checks if the value is too small or not, if too small then the value to render will be the minValueForBarForRendering. this function takes into account if the value is positive or negative.
    if (value === 0) return value
    if (value > 0) return value > minValueForBarForRendering ? value : minValueForBarForRendering
    return value < -minValueForBarForRendering ? value : -minValueForBarForRendering
  }

  // the total field is for the labels on the bars, the adjustedTotal field is for the actual value of the bar(height of the bar). pv is for the transparent part of the bar.
  return [
    {
      name: `${baseYear.value} Start`,
      [graphBucketDisplayName.notRenewingThisYear]: getBarValue(notRenewingThisYearPrevYear.sum),
      [`${graphBucketDisplayName.notRenewingThisYear}${ACTUAL_VALUE_SUFFIX}`]: notRenewingThisYearPrevYear.sum,
      [`${graphBucketDisplayName.notRenewingThisYear}${COUNT_SUFFIX}`]: notRenewingThisYearPrevYear.renewalsCount,
      [graphBucketDisplayName.upForRenewal]: getBarValue(upForRenewal.sum),
      [`${graphBucketDisplayName.upForRenewal}${ACTUAL_VALUE_SUFFIX}`]: upForRenewal.sum,
      [`${graphBucketDisplayName.upForRenewal}${COUNT_SUFFIX}`]: upForRenewal.renewalsCount,
      total: notRenewingThisYearPrevYear.sum + upForRenewal.sum,
      adjustedTotal: sumBy([notRenewingThisYearPrevYear.sum, upForRenewal.sum], getBarValue),
      totalLabel: 0,
      pv: 0
    },
    {
      name: 'Cost decrease',
      [graphBucketDisplayName.costDecrease]: getBarValue(costDecreasedDiff.sum),
      [`${graphBucketDisplayName.costDecrease}${ACTUAL_VALUE_SUFFIX}`]: costDecreasedDiff.sum,
      [`${graphBucketDisplayName.costDecrease}${COUNT_SUFFIX}`]: costDecreasedDiff.renewalsCount,
      total: costDecreasedDiff.sum,
      adjustedTotal: getBarValue(costDecreasedDiff.sum),
      totalLabel: 0,
      pv: costDecreasedDiff.sum ? sumBy([notRenewingThisYearPrevYear.sum, upForRenewal.sum], getBarValue) : 0
    },
    {
      name: 'Closed app',
      [graphBucketDisplayName.closedApp]: getBarValue(closedAppDiff.sum),
      [`${graphBucketDisplayName.closedApp}${ACTUAL_VALUE_SUFFIX}`]: closedAppDiff.sum,
      [`${graphBucketDisplayName.closedApp}${COUNT_SUFFIX}`]: closedAppDiff.renewalsCount,
      total: closedAppDiff.sum,
      adjustedTotal: getBarValue(closedAppDiff.sum),
      totalLabel: 0,
      pv: closedAppDiff.sum ? sumBy([costDecreasedDiff.sum, notRenewingThisYearPrevYear.sum, upForRenewal.sum], getBarValue) : 0
    },
    {
      name: 'Cost increase',
      [graphBucketDisplayName.costIncrease]: getBarValue(costIncreasedDiff.sum),
      [`${graphBucketDisplayName.costIncrease}${ACTUAL_VALUE_SUFFIX}`]: costIncreasedDiff.sum,
      [`${graphBucketDisplayName.costIncrease}${COUNT_SUFFIX}`]: costIncreasedDiff.renewalsCount,
      pv: costIncreasedDiff.sum ? sumBy([closedAppDiff.sum, costDecreasedDiff.sum, notRenewingThisYearPrevYear.sum, upForRenewal.sum], getBarValue) : 0,
      total: costIncreasedDiff.sum,
      adjustedTotal: getBarValue(costIncreasedDiff.sum),
      totalLabel: 0
    },
    {
      name: 'New contracts',
      [graphBucketDisplayName.newContracts]: getBarValue(newContractsDiff.sum),
      [`${graphBucketDisplayName.newContracts}${ACTUAL_VALUE_SUFFIX}`]: newContractsDiff.sum,
      [`${graphBucketDisplayName.newContracts}${COUNT_SUFFIX}`]: newContractsDiff.renewalsCount,
      pv: newContractsDiff.sum ? sumBy([costIncreasedDiff.sum, closedAppDiff.sum, costDecreasedDiff.sum, notRenewingThisYearPrevYear.sum, upForRenewal.sum], getBarValue) : 0,
      total: newContractsDiff.sum,
      adjustedTotal: getBarValue(newContractsDiff.sum),
      totalLabel: 0
    },
    {
      name: `${baseYear.value} End`,
      [graphBucketDisplayName.notRenewingThisYear]: getBarValue(notRenewingThisYearBaseYear.sum),
      [`${graphBucketDisplayName.notRenewingThisYear}${ACTUAL_VALUE_SUFFIX}`]: notRenewingThisYearBaseYear.sum,
      [`${graphBucketDisplayName.notRenewingThisYear}${COUNT_SUFFIX}`]: notRenewingThisYearBaseYear.renewalsCount,
      [graphBucketDisplayName.renewed]: getBarValue(costDecreasedBaseYear.sum + closedAppBaseYear.sum + costIncreasedBaseYear.sum + newContractsBaseYear.sum + noChangeBaseYear.sum),
      [`${graphBucketDisplayName.renewed}${ACTUAL_VALUE_SUFFIX}`]: costDecreasedBaseYear.sum + closedAppBaseYear.sum + costIncreasedBaseYear.sum + newContractsBaseYear.sum + noChangeBaseYear.sum,
      [`${graphBucketDisplayName.renewed}${COUNT_SUFFIX}`]: costDecreasedBaseYear.renewalsCount + closedAppBaseYear.renewalsCount + costIncreasedBaseYear.renewalsCount + newContractsBaseYear.renewalsCount + noChangeBaseYear.renewalsCount,
      [graphBucketDisplayName.renewalForecast]: getBarValue(renewalForecast.sum),
      [`${graphBucketDisplayName.renewalForecast}${ACTUAL_VALUE_SUFFIX}`]: renewalForecast.sum,
      [`${graphBucketDisplayName.renewalForecast}${COUNT_SUFFIX}`]: renewalForecast.renewalsCount,
      [`${graphBucketDisplayName.renewalForecast}${BREAKDOWN_SUFFIX}`]: [
        { name: graphBucketDisplayName.notRenewedYet, count: notRenewedYet.renewalsCount, value: notRenewedYet.sum },
        { name: graphBucketDisplayName.missingContracts, count: missingContracts.renewalsCount, value: missingContracts.sum }
      ],
      pv: 0,
      total: notRenewingThisYearBaseYear.sum + costDecreasedBaseYear.sum + closedAppBaseYear.sum + costIncreasedBaseYear.sum + newContractsBaseYear.sum + renewalForecast.sum + noChangeBaseYear.sum,
      adjustedTotal: sumBy([notRenewingThisYearBaseYear.sum, (costDecreasedBaseYear.sum + closedAppBaseYear.sum + costIncreasedBaseYear.sum + newContractsBaseYear.sum + noChangeBaseYear.sum), renewalForecast.sum], getBarValue),
      totalLabel: 0
    }
  ]
}
