import React from 'react'
import { getDisplayName } from '@lenses/users'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { Avatar, Tooltip, Badge } from '@toriihq/design-system'
import { Label } from './styles'

type User = {
  id: number
  firstName: string
  lastName: string
  email: string
  photoUrl: string | null
  isSupport?: boolean
  isDeletedInIdentitySources?: boolean | number
}

type Props = {
  user: User,
  hideTooltip?: boolean
  displayEmail?: boolean
  displayIsDeletedUser?: boolean
}

const LinkableAvatar = ({
  user,
  hideTooltip,
  displayEmail,
  displayIsDeletedUser
}: Props): JSX.Element => {
  const shouldHideLink = user.isSupport
  const isDeletedUser = Boolean(user.isDeletedInIdentitySources)

  const deletedUserBadge =
    displayIsDeletedUser &&
    isDeletedUser &&
    <Badge name='Warning' size='medium' />

  const tooltipLabel =
    <Label>
      <div>{getDisplayName(user)}</div>
      {displayEmail && user.email && <div>{user.email}</div>}
      {
        displayIsDeletedUser &&
        isDeletedUser &&
        <strong>Left the organization</strong>
      }
    </Label>

  const avatar = <Avatar firstName={user.firstName} lastName={user.lastName} imageUrl={user.photoUrl} badge={deletedUserBadge} />

  return <Tooltip hide={hideTooltip} label={tooltipLabel}>
    {shouldHideLink ? avatar : <RelativeTeamUserLink idUser={user.id}>{avatar}</RelativeTeamUserLink>}
  </Tooltip>
}

export default LinkableAvatar
