import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ConfigureAppChargeback, { validate } from '../../configureAppChargeback'
import ToriiPopup from '../ToriiPopupV2'
import noop from 'lodash/noop'

const CSS = {
  popup: {
    modal: {
      width: '1036px'
    }
  }
}

const ConfigureAppChargebackPopup = ({ cancel, isOpen, close, app, callback }) => {
  const [formInitialValues, setFormInitialValues] = useState({})

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={CSS.popup}>
      <ToriiPopup.Header header={'Configure chargeback'} subHeader={`Set ${app.name} chargeback strategy`} />
      <ToriiPopup.Form
        validate={validate}
        initialValues={formInitialValues}
        render={(formProps) => (
          <ConfigureAppChargeback
            onInitialValuesChanged={(values) => setFormInitialValues(values)}
            onSuccess={() => {
              const SUCCESS_DELAY = 1000
              setTimeout(() => {
                callback()
                close()
              },
              SUCCESS_DELAY)
            }}
            onCancel={cancel}
            app={app}
            {...formProps}
          />
        )}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            cancelButtonText={'Cancel'}
            mainButtonText={'Confirm'}
            isMainSubmit
            recoveryTime={1500}
            formProps={formProps}
            isMainButtonDisabled={!formProps.valid}
          />
        )}
      />
    </ToriiPopup>
  )
}

ConfigureAppChargebackPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  callback: PropTypes.func
}

ConfigureAppChargeback.defaultProps = {
  callback: noop
}

export default ConfigureAppChargebackPopup
