import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import Placeholder from '../../placeholder'
import texts from '../../../shared/style/texts'
import { fontWeight } from '../../../shared/style/sizes'

const CSS = {
  section: css({
    padding: '0 30px',
    height: '60px',
    ':first-child': {
      paddingLeft: 0
    },
    ':not(:last-child)': {
      borderRight: `1px solid ${colors.border}`
    },
    ':last-child': {
      paddingRight: 0
    },
    '@media(max-width: 768px)': {
      padding: '20px 0',
      height: 'auto',
      ':not(:last-child)': {
        borderBottom: `1px solid ${colors.border}`,
        borderRight: `none`
      }
    }
  }),
  sectionTitle: css(texts.subheading, {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: colors.text,
    marginBottom: '5px',
    height: '17px'
  }),
  sectionBody: css(texts.headers.large, {
    fontWeight: fontWeight.normal
  }),
  sectionBodyBold: css({
    fontWeight: fontWeight.semiBold
  }),
  browserLogo: css({
    width: '17px',
    height: '17px',
    marginRight: '10px'
  })
}

const BrowserCounter = ({ header, src, loading, number, bold }) => {
  return (
    <div {...CSS.section}>
      <div {...CSS.sectionTitle}>
        {src && <img alt={header} src={src} {...CSS.browserLogo} />}
        <div>{header}</div>
      </div>
      <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
        <div {...css(CSS.sectionBody, bold && CSS.sectionBodyBold)}>{number}</div>
      </Placeholder>
    </div>
  )
}

BrowserCounter.propTypes = {
  header: PropTypes.node,
  loading: PropTypes.bool,
  bold: PropTypes.bool,
  number: PropTypes.number,
  src: PropTypes.string
}

export default BrowserCounter
