import React from 'react'
import PropTypes from 'prop-types'
import WizardPopup from '../wizardPopup'
import { stepsConfig } from './stepsConfig/config'

class AddOrEditContractPopup extends React.Component {
  state = {
    isNewContract: true,
    idUpload: null,
    contractDetails: null,
    isAiRan: false,
    successfullyParsedAmount: 0,
    shouldShowBackButton: false,
    isAlreadyForcedToBeDirty: false
  }
  componentDidMount () {
    this.setState({ isNewContract: !this.props.idContract && !this.props.initialValues })

    this.removeAnyIdContractFromUrl()

    if (this.props.idContract) {
      const currentUrl = window.location.pathname
      const urlWithIdContract = `${currentUrl}/${this.props.idContract}`
      this.updateUrl(urlWithIdContract)
    }
  }

  componentWillUnmount () {
    this.removeAnyIdContractFromUrl()
  }

  componentDidUpdate (prevProps) {
    if (this.props.idContract !== prevProps.idContract) {
      this.setState({ isNewContract: !this.props.idContract && !this.props.initialValues })
    }
  }

  close () {
    this.props.close()
    this.setState({ isNewContract: true })
  }

  cancel () {
    this.props.cancel()
    this.setState({ isNewContract: true })
  }

  updateIdUpload (idUpload) {
    this.setState({ idUpload })
  }

  updateUrl (newUrl) {
    const currentUrl = new URL(window.location.href)
    const currentSearchParams = currentUrl.searchParams

    const fullNewUrl = new URL(newUrl, window.location.origin)
    fullNewUrl.search = currentSearchParams.toString()
    window.history.replaceState(null, '', fullNewUrl.toString())
  }

  removeAnyIdContractFromUrl () {
    const currentUrl = window.location.pathname

    if (/(contracts|renewalsSummary)\/\d+$/i.test(currentUrl)) {
      const urlWithoutIdContract = currentUrl.substring(0, currentUrl.lastIndexOf('/'))
      this.updateUrl(urlWithoutIdContract)
    }
  }

  render () {
    const { isOpen, hasContractAIInPlan } = this.props
    const { isNewContract } = this.state

    return <WizardPopup
      isOpen={isOpen}
      stepsConfig={stepsConfig(isNewContract, hasContractAIInPlan)}
      initialSharedState={this.state}
      sharedProps={this.props}
      onCancel={() => this.cancel()}
      onClose={() => this.close()}
      width='690px'
    />
  }
}

AddOrEditContractPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  idContract: PropTypes.number,
  initialValues: PropTypes.object
}

export default AddOrEditContractPopup
