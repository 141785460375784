import { css } from 'glamor'

export const CSS = {
  select: css({
    minWidth: '250px',
    maxHeight: '32px'
  }),
  selectLoadingStyle: {
    minWidth: '250px',
    height: 32,
    borderRadius: 5
  }
}
