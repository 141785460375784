import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { EMPTY_OBJECT } from '@root/constants'
import {
  getAppCatalogPoliciesByIdSelector,
  getWorkflowsById
} from '@selectors/workflows'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idWorkflow = parseInt(ownProps.match.params.idWorkflow, 10)
  const workflow = (ownProps.isAppCatalogPoliciesView
    ? getAppCatalogPoliciesByIdSelector(state)[idWorkflow]
    : getWorkflowsById(state)[idWorkflow]) || EMPTY_OBJECT

  return {
    idOrg,
    idWorkflow,
    workflow
  }
}

export default withRouter(connect(mapStateToProps)(View))
