import React from 'react'
import { Row, Col, ButtonType, ScreenBreakpoints } from '@toriihq/design-system'
import { css } from 'glamor'
import TotalUserApps from '../../../components/totalUserApps'
import { SCOPES } from '../../../constants'
import EnableFor from '../../../components/enableFor'
import UserDetails from '../userDetails'
import VisibleFor from '@root/components/visibleFor'
import { FEATURES } from '@root/shared/features'
import ButtonOfFeature from '@components/buttonOfFeature'

const CSS = {
  activeUserRow: css({
    display: 'flex',
    marginBottom: '24px'
  }),
  rightBox: css({
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media(max-width: ${ScreenBreakpoints.md})`]: {
      justifyContent: 'flex-start'
    }
  })
}

class ActiveUser extends React.Component {
  onClick = () => {
    const { user, toggleOffboardingPreviewPopup } = this.props
    toggleOffboardingPreviewPopup({ isOpen: true, users: [user] })
  }

  render () {
    const { user, idUser } = this.props

    return (
      <Row {...CSS.activeUserRow}>
        <Col md={7} xs={12}>
          <UserDetails user={user}>
            <VisibleFor scopes={[SCOPES.OFFBOARDING_READ]}>
              <EnableFor scopes={[SCOPES.OFFBOARDING_WRITE]} allowForToriiAdmin>
                <ButtonOfFeature feature={FEATURES.OFFBOARDING.PAGE} onClick={this.onClick} icon='Offboarding' type={ButtonType.secondary} label='Start offboarding' />
              </EnableFor>
            </VisibleFor>
          </UserDetails>
        </Col>
        <Col md={5} xs={12}>
          <div {...CSS.rightBox}>
            <TotalUserApps idUser={idUser} />
          </div>
        </Col>
      </Row>
    )
  }
}

export default ActiveUser
