import Cards from 'react-credit-cards-2'
import React from 'react'
import { type Card } from '../types'

const CreditCard = ({ card }: { card: Card }) => {
  return <Cards
    number={'************'.concat(card.lastFour)}
    expiry={card.expiration}
    cvc={''}
    name={card.owner}
    preview
  />
}

export default CreditCard
