import React from 'react'
import PropTypes from 'prop-types'
import PieChartBox from '../pieChartBox'
import CustomTooltip from './customTooltip'
import RiskIcon from '../riskIcon'

const RiskLevel = ({ data, header, loading, description, link, hasData }) => {
  if (!hasData) {
    return null
  }

  const dataWithIcon = data.map(entry => ({ ...entry, icon: <RiskIcon riskLevel={entry.riskLevel} /> }))
  const tooltip = <CustomTooltip />
  return <PieChartBox header={header} loading={loading} description={description} chartsInfo={[{ hasData, data: dataWithIcon }]} customTooltip={tooltip} link={link} />
}

RiskLevel.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    riskLevel: PropTypes.number.isRequired
  })),
  loading: PropTypes.bool,
  hasData: PropTypes.bool,
  isInUse: PropTypes.bool,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })
}

export default RiskLevel
