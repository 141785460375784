import React from 'react'
import ToriiToggle from '@components/toriiToggle'
import EnableFor from '@components/enableFor'
import { ToggleSize, Tooltip, Icon } from '@toriihq/design-system'
import Placeholder from '@components/placeholder'
import * as Style from './style'

type Props = {
  checked: boolean,
  onToggle?: () => void,
  loading: boolean,
  toggleId: string,
  scopes?: string [],
  text: string,
  subComponent?: JSX.Element,
  tooltipText?: string,
  toggleSize?: ToggleSize,
  alignToRight?: boolean
}

export const AdvancedToggle = ({
  checked,
  onToggle = () => {},
  loading,
  toggleId,
  scopes = [],
  text,
  subComponent,
  tooltipText,
  toggleSize = ToggleSize.small,
  alignToRight = true
}: Props) : JSX.Element => {
  return <Placeholder loading={loading} type='text' rows={2} style={Style.Placeholder}>
    <div>
      <Style.Header>
        <Style.Text>
          {text}
          {tooltipText && (<Tooltip label={tooltipText}>
            <Icon name='Info' color='secondary' />
          </Tooltip>)}
        </Style.Text>
        <Style.ToggleWrapper
          alignToRight={alignToRight}
        >
          <EnableFor scopes={scopes}>
            <ToriiToggle
              checked={checked}
              id={toggleId}
              onToggle={onToggle}
              size={toggleSize}
            />
          </EnableFor>
        </Style.ToggleWrapper>
      </Style.Header>
      {subComponent && (<Style.SubText>
        {subComponent}
      </Style.SubText>)}
    </div>
  </Placeholder>
}

export default AdvancedToggle
