import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'

export const Menu = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: '12px 0 24px'
})

const sharedRoute = {
  display: 'flex',
  alignItems: 'center',
  height: 40,
  minWidth: 'max-content',
  padding: '0 12px',
  margin: '0 16px',
  borderRadius: 40
}

export const Bubble = css(texts.heading, {
  ...sharedRoute,
  color: `${colors.blue} !important`,
  backgroundColor: colors.lightBlue5
})

export const Route = css(texts.heading, {
  ...sharedRoute,
  color: colors.black,

  ':hover': {
    color: colors.blue
  }
})

export const RouteText = css({
  flexGrow: 1,
  display: 'flex',
  marginLeft: 16,
  fontFamily: 'Nunito',
  fontWeight: 600,
  lineHeight: '20px'
})

export const RouteIcon = css({
  position: 'relative',
  display: 'flex'
})

export const BadgeContainer = css({
  position: 'absolute',
  display: 'inline-flex',
  right: -10,
  top: -5
})

const lockIcon = css({
  display: 'flex',
  alignItems: 'center',
  opacity: 0
})

const lockIconSelected = css({
  opacity: 1
})

export const getLockIconCSS = (isRouteMatch) => css(lockIcon, isRouteMatch ? lockIconSelected : {})

export const routeLinkContainer = css({
  alignItems: 'center',
  width: '100%',
  display: 'flex',
  ' .lockIcon': {
    transition: 'opacity 200ms'
  },
  '&:hover': {
    ' .lockIcon': {
      opacity: 1
    }
  }
})
