import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import Lottie from 'react-lottie'
import animationData from './csvLottie.json'
import colors from '../../../shared/style/colors'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData
}

const CSS = {
  popup: css({
    width: '590px'
  }),
  container: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }),
  leftContainer: css({
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column'
  }),
  rightContainer: css({
    maxWidth: '140px',
    display: 'flex',
    flex: 1,
    flexShrink: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }),
  subTitle: css({
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '26px'
  }),
  email: css({
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '26px',
    color: colors.navy
  }),
  note: css({
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '26px',
    color: colors.grey2
  })
}

class CsvExportPopup extends React.Component {
  render () {
    let { cancel, isOpen, close, email } = this.props
    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width={'590px'}>
        <ToriiPopup.Header header='Your CSV file is on its way' />
        <ToriiPopup.Content>
          <div {...CSS.container}>
            <div {...CSS.leftContainer}>
              <div {...CSS.subTitle}>We’re sending a CSV file to your email address</div>
              <div {...CSS.email}>{email}</div>
              <div {...CSS.note}>This might take a few minutes to complete.</div>
            </div>
            <div {...CSS.rightContainer}>
              <Lottie
                isClickToPauseDisabled
                width='139px'
                options={defaultOptions}
              />
            </div>
          </div>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          showCancelButton={false}
          mainButtonText={'Ok'}
          mainButtonAction={close}
        />
      </ToriiPopup>
    )
  }
}

CsvExportPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  email: PropTypes.string.isRequired
}

export default CsvExportPopup
