import { css } from 'glamor'
import styled from 'styled-components'

export const DateNewValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const CSS = {
  dateFields: css({
    margin: '0 10px 0 0',
    width: '100%'
  }),
  dateNumber: css({
    width: '100%'
  })
}
