import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { css } from 'glamor'
import Login from '../../components/login'
import LoginSAML from '../../components/loginSAML'
import LoginSAMLFailure from '../../components/loginSAMLFailure'
import Forgot from '../../components/forgot'
import Reset from '../../components/reset'
import Logout from '../../components/logout'
import CatalogSignup from '../../components/signup/catalogSignup'
import colors from '../../shared/style/colors'
import { fontWeight } from '../../shared/style/sizes'
import WhiteToriiLogo from '@media/white_torii_logo.svg'
import DashboardSignin from '@media/dashboard_signin.svg'
import CatalogSignin from '@media/catalog_signin.svg'
import PoweredBy from '@media/powered_by.svg'
import TrialSignup from '@components/signup/trialSignup'

const SMALL_SCREEN_SIZE = '700px'
const CSS = {
  main: css({
    display: 'flex',
    minHeight: '100vh',
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      display: 'block'
    }
  }),
  company: css({
    position: 'relative',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: fontWeight.bold,
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      display: 'none'
    }
  }),
  darkCompanyBackground: css({
    textAlign: 'center',
    backgroundColor: colors.blue,
    fontSize: '30px',
    color: colors.white
  }),
  lightCompanyBackground: css({
    backgroundColor: colors.lightBlue3,
    fontSize: '35px',
    color: colors.black
  }),
  image: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }),
  actionContainer: css({
    width: '50%',
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      width: '100%'
    }
  }),
  action: css({
    margin: '20% auto',
    maxWidth: '500px',
    minHeight: '421px',
    padding: '40px',
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      margin: '0 auto',
      padding: '20px'
    }
  }),
  mobileHeader: css({
    display: 'none',
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      display: 'block',
      backgroundColor: colors.blue,
      textAlign: 'center',
      padding: '15px'
    }
  }),
  imageContainer: css({
    height: '100%',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  })
}

class App extends React.Component {
  render () {
    const { isFromCatalog } = this.props
    const isDashboard = !isFromCatalog

    const dashboard = <div {...css(CSS.company, CSS.darkCompanyBackground)}>
      <img style={{ zIndex: 3, margin: '15% 0 20px 0', height: '31px' }} alt='Torii logo' src={WhiteToriiLogo} />
      <div style={{ zIndex: 3 }}>Manage all your SaaS apps.</div>
      <div style={{ zIndex: 3 }}>In one single place.</div>
      <div {...CSS.image}><img alt='Torii' src={DashboardSignin} width='100%' /></div>
    </div>

    const catalog = <div {...css(CSS.company, CSS.lightCompanyBackground)}>
      <div style={{ zIndex: 3, margin: '10% 0 0 50px' }}>Application Catalog</div>
      <div {...CSS.image}><img alt='Application catalog' src={CatalogSignin} width='100%' /></div>
      <img style={{ zIndex: 3, position: 'absolute', bottom: '46px', left: '56px' }} alt='Torii logo' src={PoweredBy} />
    </div>

    const mobileUpperHeader = <div {...CSS.mobileHeader}>
      <img alt='Torii logo' src={WhiteToriiLogo} style={{ width: 75, height: 23 }} />
    </div>

    return (
      <div {...CSS.main}>
        {mobileUpperHeader}
        {isDashboard && dashboard}
        {isFromCatalog && catalog}
        <div {...CSS.actionContainer}>
          <div {...CSS.action}>
            <Switch>
              <Route path='/login/signup/trial' component={TrialSignup} />
              <Route path='/login/signup' component={CatalogSignup} />
              <Route path='/login/saml/failure' component={LoginSAMLFailure} />
              <Route path='/login/saml' component={LoginSAML} />
              <Route path='/login/forgot' component={Forgot} />
              <Route path='/login/logout' component={Logout} />
              <Route path='/login/reset/:id/:key' component={({ match: { params } }) => <Reset params={params} />} />
              <Route path='/login' component={Login} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

App.propTypes = {
  children: PropTypes.node
}

export default App
