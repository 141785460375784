import { EntityOption } from '@components/filters/ifElseBranchFilters/types'
import { BranchFilter } from '@shared/workflows/types'
import { changeBranchEntity } from './changeBranchEntity'

interface Params {
  entityOptions: EntityOption[]
}

export const getNewBranchFilter = ({ entityOptions }: Params): BranchFilter => {
  let newFilter: BranchFilter = { id: Date.now() }

  if (entityOptions.length === 1) {
    const [entityOption] = entityOptions
    newFilter = changeBranchEntity(newFilter, entityOption.value)
  }

  return newFilter
}
