import {
  generateDefaultBranchLabelId,
  generatePositionedBranchLabelId
} from '@shared/workflows/actions/utils/branchActionChildrenOps'
import {
  ActionUiNode,
  BranchActionUiNode,
  BranchData,
  BranchLabelUiNode,
  DefaultBranchData,
  DefaultBranchLabelUiNode,
  EndUiNode,
  TriggerUiNode,
  WORKFLOW_UI_NODE_TYPE
} from '../../types'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'

export const INITIAL_XY_POSITION = { x: 0, y: 0 }

export const getTriggerUiNode = (): TriggerUiNode => {
  return {
    type: WORKFLOW_UI_NODE_TYPE.TRIGGER,
    id: ID_TRIGGER_NODE,
    position: INITIAL_XY_POSITION,
    data: undefined
  }
}

export const getActionUiNode = (id: string): ActionUiNode => {
  return {
    type: WORKFLOW_UI_NODE_TYPE.ACTION,
    id,
    position: INITIAL_XY_POSITION,
    data: { idWorkflowNode: id }
  }
}

export const getBranchLabelUiNode = ({
  idBranchingNode,
  branchIndex
}: Pick<BranchData, 'idBranchingNode' | 'branchIndex'>): BranchLabelUiNode => {
  const id = generatePositionedBranchLabelId({ idBranchingNode, branchIndex })
  return {
    type: WORKFLOW_UI_NODE_TYPE.BRANCH_LABEL,
    id,
    position: INITIAL_XY_POSITION,
    data: { idBranchingNode, branchIndex }
  }
}

export const getDefaultBranchLabelUiNode = ({
  idBranchingNode
}: Pick<DefaultBranchData, 'idBranchingNode'>): DefaultBranchLabelUiNode => {
  const id = generateDefaultBranchLabelId({ idBranchingNode })
  return {
    type: WORKFLOW_UI_NODE_TYPE.DEFAULT_BRANCH_LABEL,
    id,
    position: INITIAL_XY_POSITION,
    data: { idBranchingNode }
  }
}

export const getBranchActionUiNode = (id: string): BranchActionUiNode => {
  return {
    type: WORKFLOW_UI_NODE_TYPE.BRANCH_ACTION,
    id,
    position: INITIAL_XY_POSITION,
    data: { idWorkflowNode: id }
  }
}

export const getEndNode = (idLastNode: string): EndUiNode => ({
  type: WORKFLOW_UI_NODE_TYPE.END,
  id: `end-${idLastNode}`,
  position: INITIAL_XY_POSITION,
  data: undefined
})
