import React from 'react'
import { AppIcon, Stack } from '@toriihq/design-system'
import { Props } from './types'

export default function AppBadge ({
  name,
  imageUrl
}: Props) {
  return (
    <Stack direction='row' gap='space-150'>
      <AppIcon appName={name} appImageUrl={imageUrl} />
      <span>{name}</span>
    </Stack>
  )
}
