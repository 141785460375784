import View from './view'
import { connect } from 'react-redux'
import { getSAMLUrl } from '../../pages/login/actions'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps
  const query = qs.parse(location.search)
  return {
    loginInfo: state.login.SAML,
    nextUrl: query.next
  }
}

const mapDispatchToProps = {
  getSAMLUrl
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
