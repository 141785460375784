import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import * as Style from './style'
import AppInfo from '@pages/appsComparison/components/chartWidget/appInfo'
import maxBy from 'lodash/maxBy'
import partition from 'lodash/partition'

const Legend = (props) => {
  const { data = [], renderLegendInfo, renderLegendDataSeparator = () => {}, component = '', loading } = props

  const [appsWithData, appsWithoutData] = useMemo(() => {
    if (data.length === 0) {
      return []
    }

    const apps = data.map(item => {
      return {
        idApp: item[0].idApp,
        name: item[0].name,
        value: item[0].date ? maxBy(item, 'date').value : undefined,
        color: item[0].color,
        imageUrl: item[0].imageUrl
      }
    })

    return partition(apps, app => app.value !== undefined)
  }, [data])

  const LegendItem = ({ item, overrideRowStyle }) => {
    return (
      <div {...css(Style.Row, overrideRowStyle)}>
        <AppInfo
          loading={loading}
          idApp={item.idApp}
          color={item.color}
          name={item.name}
          infoLine={renderLegendInfo(item)}
          imageUrl={item.imageUrl}
          component={component}
        />
      </div>
    )
  }

  return (
    <div {...Style.Container}>
      {appsWithData.map((item, index) => <LegendItem key={`legend-${index}-${item.idApp}`} item={item} overrideRowStyle={{ marginBottom: 30 }} />)}
      {appsWithoutData.length ? (
        <>
          {renderLegendDataSeparator(appsWithoutData)}
          {appsWithoutData.map((item, index) => <LegendItem key={`legend-${index}-${item.idApp}`} item={item} overrideRowStyle={{ marginBottom: 38 }} />)}
        </>) : null}
    </div>
  )
}

Legend.propTypes = {
  data: PropTypes.array,
  renderLegendInfo: PropTypes.func,
  component: PropTypes.string,
  loading: PropTypes.bool
}

export default Legend
