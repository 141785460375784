import { css } from 'glamor'
import colors from '@shared/style/colors'

export const toggleCard = css({
  display: 'flex',
  flexDirection: 'row',
  border: `solid 1px ${colors.border}`,
  borderRadius: '8px',
  padding: '16px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px'
})

export const textContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
})

export const toggleContainer = css({
  display: 'flex',
  height: '100%'
})

export const header = css({
  height: '20px',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px'
})

export const body = css({
  height: '20px',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px'
})
