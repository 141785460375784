import ClassNames from 'classnames'
import ControlGroup from './control-group'
import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import FloatingInput from '../floatingInput'
import { Icon } from '@toriihq/design-system'

const CSS = {
  input: css({
    '.form-control': {
      borderRadius: 0,
      boxShadow: 'none'
    },
    width: '100%',
    outline: 0,
    border: 'none',
    borderBottom: `solid 1px ${colors.grey2}`,
    height: '36px',
    fontSize: '14px',
    fontWeight: 300,
    padding: 0,
    ':focus': {
      borderBottomColor: colors.blue
    },
    '[disabled], [readonly]': {
      backgroundColor: colors.white,
      color: colors.grey2
    }
  }),
  withIcon: css({
    paddingRight: '30px'
  }),
  icon: css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    height: '20px',
    color: colors.text
  }),
  floatingLabel: css({
    color: colors.text
  }),
  hide: {
    opacity: 0,
    transition: 'opacity .2s'
  },
  show: {
    opacity: 1
  }
}

const propTypes = {
  autoCapitalize: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  help: PropTypes.string,
  inputClasses: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  iconName: PropTypes.string
}
const defaultProps = {
  type: 'text',
  autoCapitalize: 'off'
}

class TextControl extends React.Component {
  focus () {
    return this.input.focus()
  }

  value () {
    return this.input.value
  }

  render () {
    const inputClasses = ClassNames(Object.assign({
      [CSS.input.toString()]: true
    }, this.props.inputClasses))
    const showError = this.props.hasError && this.props.error
    const space = '\u00A0'

    return (
      <ControlGroup
        hasError={this.props.hasError}
        label={this.props.label}
        help={this.props.help}
        hideLabel={!this.props.label}
        hideHelp={!this.props.help}>

        <FloatingInput
          refInput={(c) => (this.input = c)}
          labelStyle={CSS.floatingLabel}
          type={this.props.type}
          autoCapitalize={this.props.autoCapitalize}
          className={inputClasses}
          name={this.props.name}
          label={this.props.placeholder}
          value={this.props.value}
          disabled={this.props.disabled ? 'disabled' : undefined}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          {...css(
            CSS.input,
            this.props.iconName && CSS.withIcon
          )}
        >
          {this.props.iconName && (
            <span {...CSS.icon}>
              <Icon name={this.props.iconName} color='inherit' />
            </span>
          )}
        </FloatingInput>
        <span className='has-error' {...css(CSS.hide, showError && CSS.show)}>
          <span className='control-label'>{this.props.error || space}</span>
        </span>
      </ControlGroup>
    )
  }
}

TextControl.propTypes = propTypes
TextControl.defaultProps = defaultProps

export default TextControl
