import analytics from '@root/helpers/analytics'

const Analytics = {
  onDeleteContract: (action, analyticsInfo) => {
    analytics.track(`${action} contract-deletion-confirmation-dialog`, analyticsInfo)
  },
  clickedOnReviewUnmatchedContracts: () => {
    analytics.track('Click on Review-unmatched-contracts')
  },
  clickedOnMatchContractButton: () => {
    analytics.track('Click on Match-contract-button')
  }
}

export default Analytics
