import styled from 'styled-components'
import { WORKFLOW_NODE_CLASS_NAME } from '@pages/workflow_v2/workflowEditor/workflowGraph/consts'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

export const ContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
`

export const DeleteButtonContainer = styled.div`
  padding-top: 11px;
  padding-right: 11px;
  display: none;

  .${WORKFLOW_NODE_CLASS_NAME}:is(:hover, .selected) & {
    display: block;
  }
`
