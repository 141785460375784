import React from 'react'
import { Form } from 'react-final-form'
import Analytics from '../../../helpers/analytics'
import qs from 'query-string'
import ControlGroup from '../../form/control-group'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { safeRedirect } from '../../../shared/utils'
import { Button, ButtonType } from '@toriihq/design-system'
import some from 'lodash/some'

const CSS = {
  backButton: css({
    float: 'left'
  }),
  button: css({
    marginLeft: '15px'
  }),
  signupError: css({
    textAlign: 'right',
    margin: '10px 0',
    color: colors.error
  })
}

class SignupWizard extends React.Component {
  state = {
    values: this.props.initialValues || {}
  }

  navigateToStep (step) {
    const { id, key } = this.props.match.params
    const { history } = this.props
    history.push(`/signup/${step + 1}/${id}/${key}${this.props.location.search}`)
  }

  next = values => {
    const { steps, currentPage, idRole, adminIdRole, org } = this.props

    const isLastPage = currentPage === steps.length - 1
    const hasIntegrationConnected = some(org?.servicesConnectionStatus)
    if (isLastPage && idRole === adminIdRole && !hasIntegrationConnected) {
      return this.redirectToConnectIntegrationStep()
    } else if (isLastPage) {
      return this.lastStepNext()
    }

    this.setState(state => ({ values }))

    const nextPage = Math.min(currentPage + 1, steps.length - 1)
    this.navigateToStep(nextPage)
  }

  previous = () => {
    const { currentPage } = this.props
    const prevPage = Math.max(currentPage - 1, 0)
    this.navigateToStep(prevPage)
  }

  handleSubmit = values => {
    const { steps, currentPage } = this.props
    const { options = {} } = steps[currentPage]

    const shouldSubmit = options.shouldSubmit

    if (shouldSubmit) {
      return this.onSubmit(values)
    } else {
      this.next(values)
    }
  }

  redirectToConnectIntegrationStep () {
    safeRedirect(window.location.origin + `/team/signup/connectIntegrationStep${window.location.search}`)
  }

  lastStepNext = () => {
    const query = qs.parse(window.location.search)
    safeRedirect(query.next || window.location.origin + '/team')
  }

  onSubmit = async (user) => {
    const { id, key } = this.props.match.params
    const { sendRequest } = this.props

    const queryParams = new URLSearchParams(this.props.location.search)
    const utmParams = {
      'utm_source': queryParams.get('utm_source'),
      'utm_medium': queryParams.get('utm_medium'),
      'utm_campaign': queryParams.get('utm_campaign'),
      'utm_content': queryParams.get('utm_content')
    }

    Analytics.track('Click to signup', {
      'First Name': user.firstName,
      'Last Name': user.lastName,
      Company: user.companyName,
      ...utmParams
    })
    const response = await sendRequest({
      ...user,
      id,
      key
    })

    if (response.id) {
      const me = {
        ...response,
        creationTime: Date.now()
      }
      Analytics.alias(me.id)
      Analytics.identify(me)
      Analytics.track('Signed up')
    }

    this.next(user)
  }

  render () {
    const { steps, currentPage, signupError } = this.props
    const { values } = this.state
    const { component: Step, options } = steps[currentPage]
    const { showNavButtons = true, message: Message = () => null } = options || {}

    return (
      <Form
        initialValues={values}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, hasValidationErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Step next={this.next} />
              {showNavButtons && <div className='buttons'>
                <ControlGroup
                  hideLabel
                  hideHelp
                  groupClasses={{ 'text-right': true, 'margin-top-medium': true }}>
                  {currentPage > 0 && (
                    <span {...CSS.backButton}>
                      <Button
                        type={ButtonType.secondary}
                        onClick={this.previous}
                        label='Back'
                      />
                    </span>
                  )}
                  <span {...CSS.button}>
                    <Button
                      disabled={hasValidationErrors || submitting}
                      label='Next'
                    />
                  </span>
                  {signupError && <div {...CSS.signupError}>{signupError}</div>}
                  <Message />
                </ControlGroup>
              </div>}
            </form>
          )
        }}
      </Form>
    )
  }
}

export default SignupWizard
