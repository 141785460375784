import React from 'react'
import AppDetails from '../appDetails'
import Table from '@components/table'
import range from 'lodash/range'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import * as CSS from './style'
import { sortAlphabeticallyCompareFunction } from '@lenses/utils'
import { getQuartersNames } from '@shared/expenses'
import { getDisplayName } from '@lenses/users'
import { Tooltip, Avatar } from '@toriihq/design-system'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import SelectState from '../selectState/view'
import Currency from '../currency'

const AppDetailsCell = ({ value: name, row: { id, category, imageUrl, isNew } }) => (
  <AppDetails
    id={id}
    name={name}
    category={category}
    imageUrl={imageUrl}
    isNew={isNew}
    component='Monthly expenses report'
  />
)
const ExpensesCell = ({ value: expenseInfo }) => <Currency value={expenseInfo} format='$0,0' />

const getExpensesColumns = (showOnlyThisYear) => {
  const currentMonth = moment().month() + 1
  const monthsCounter = range(0, showOnlyThisYear ? currentMonth : 12)
  const monthsAmount = monthsCounter.length - 1
  return [
    ...monthsCounter.map(monthsToReduce => {
      return {
        Header: moment().subtract(monthsAmount - monthsToReduce, 'months').format('MMM YYYY'),
        accessor: monthsToReduce.toString(),
        width: 110,
        Cell: ({ value: expenseMonth }) => expenseMonth ? ExpensesCell({ value: get(expenseMonth, ['total']) }) : null,
        sortMethod: (a, b) => get(a, 'total', 0) - get(b, 'total', 0),
        ...Table.numericFieldProps
      }
    }),
    {
      Header: showOnlyThisYear ? 'Total for this year' : 'Total for the last 12 months',
      accessor: 'total',
      width: showOnlyThisYear ? 160 : 220,
      Cell: ExpensesCell,
      sortMethod: (a, b) => (a || 0) - (b || 0),
      ...Table.numericFieldProps
    }
  ]
}

const getQuarterExpensesColumns = (showOnlyThisYear) => {
  const headers = getQuartersNames(showOnlyThisYear)
  return [
    ...headers.map((header, quarterIndex) => {
      const showPartialIndication = quarterIndex === 3
      const partialMonthIndication = showPartialIndication ? '*' : ''
      const accessor = `q${quarterIndex + 1}`
      const columnHeader = `${header}${partialMonthIndication}`

      return {
        Header: () => <Tooltip hide={!showPartialIndication} label='Showing partial quarter data'>
          {columnHeader}
        </Tooltip>,
        textHeader: columnHeader,
        accessor,
        width: 110,
        Cell: ({ value }) => ExpensesCell({ value }),
        sortMethod: (a, b) => (a || 0) - (b || 0),
        ...Table.numericFieldProps
      }
    }),
    {
      Header: showOnlyThisYear ? 'Total for this year' : 'Total for the last 4 quarters',
      accessor: 'quarterlyTotal',
      width: showOnlyThisYear ? 160 : 220,
      Cell: ExpensesCell,
      sortMethod: (a, b) => (a || 0) - (b || 0),
      ...Table.numericFieldProps
    }
  ]
}

export const getColumns = ({ columnsConfiguration, showOnlyThisYear, stateInfo }) => {
  const isQuarterView = (columnsConfiguration || {})['q1']
  const expensesData = isQuarterView ? getQuarterExpensesColumns(showOnlyThisYear) : getExpensesColumns(showOnlyThisYear)
  return [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 200,
      maxWidth: 300,
      className: CSS.NameAndIcon.toString(),
      fixed: 'left',
      Cell: AppDetailsCell,
      sortMethod: sortAlphabeticallyCompareFunction,
      show: Boolean(columnsConfiguration.name),
      style: {
        paddingLeft: '16px'
      }
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value }) => {
        if (stateInfo) {
          const state = stateInfo && stateInfo.options.find(o => o.label === value)
          return (
            <div style={{ marginLeft: '-8px' }}>
              <SelectState {...stateInfo} selectedValue={state} readOnly />
            </div>
          )
        } else return null
      },
      width: 220
    },
    {
      Header: 'Primary App Owner',
      accessor: 'primaryOwner',
      Cell: ({ value: user = {} }) => {
        if (isEmpty(user)) {
          return <CSS.NoOwner>-</CSS.NoOwner>
        }
        const { firstName, lastName, photoUrl, email } = user
        const tooltipText =
          <CSS.Wrapper>
            <div>{getDisplayName(user)}</div>
            <div>{email}</div>
          </CSS.Wrapper>
        const content = <Tooltip label={tooltipText}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            imageUrl={photoUrl}
          />
        </Tooltip>
        return (user.id ? <RelativeTeamUserLink idUser={user.id}>{content}</RelativeTeamUserLink> : content)
      },
      sortMethod: (a, b) => Table.sortMethods.users(a, b),
      width: 120
    },
    ...expensesData,
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'category',
      show: false
    },
    {
      accessor: 'isNew',
      show: false
    },
    {
      accessor: 'id',
      show: false
    }
  ]
}

export const getColumnsSortMethods = (columns = []) => {
  return columns.reduce((columnsSortMethodById, columnConfiguration) => {
    if (columnConfiguration.sortMethod) {
      columnsSortMethodById[columnConfiguration.accessor] = columnConfiguration.sortMethod
    }
    return columnsSortMethodById
  }, {})
}
