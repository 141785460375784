import React, { Component } from 'react'
import colors from '../../../shared/style/colors'
import { oneLiner } from '@shared/style/mixins'
import { getPermissionDescription, getPermissionSensitivityLevel } from '@shared/thirdPartyPermissions'
import { Tooltip, Link, AppIcon, ExpandableSection } from '@toriihq/design-system'
import { css } from 'glamor'
import AppDetails from '../../appDetails'
import Table from '../../table'
import RiskIcon, { permissionsLegend } from '../../riskIcon'
import { EMPTY_ARRAY, SCOPES, TABLES } from '@root/constants'
import { sourceType } from '../sourceType'
import texts from '@shared/style/texts'
import { getFullUrl } from '@shared/utils'
import get from 'lodash/get'
import SelectState from '../../selectState'
import RelativeTeamLink from '@components/relativeTeamLink'
import { SOURCE_TYPES } from '@root/sourcesConfig'
import VisibleFor from '@root/components/visibleFor'
import { FEATURES } from '@root/shared/features'
import ReportNotificationsForSource from './notificationForSource'
import SourceWithAppsTitle from './sourceWithAppsTitle'

const CSS = {
  riskLevel: css({
    display: 'flex'
  }),
  notFound: css(texts.body, {
    padding: '0 20px 20px 20px'
  })
}

class SourceWithApps extends Component {
  state = { showPermissionLevel: {}, search: null, isOpen: false }

  constructor (props) {
    super(props)
    this.cols = this.getColumns(this.props.source.id)
  }

  getColumns = (sourceId) =>
    [
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 250,
        Cell: ({ value: name, row: { idApp, category, imageUrl, isNew } }) => (
          <AppDetails
            id={idApp}
            name={name}
            category={category}
            imageUrl={imageUrl}
            isNew={isNew}
            component='Third Party Report'
          />
        )
      },
      {
        Header: 'State',
        accessor: 'stateInfo',
        textValue: ({ stateInfo = {} }) => ((stateInfo.options || []).find(option => stateInfo.selectedValue === option.value) || {}).label,
        Cell: ({ value: stateInfo }) => <SelectState
          {...stateInfo}
          allowedScopes={[SCOPES.APPLICATIONS_WRITE]}
          pageName='Security page'
        />,
        style: { overflow: 'inherit' },
        sortMethod: (a, b) => {
          const { stateOrder } = this.props

          return (stateOrder[get(a, 'selectedValue')] || 0) - (stateOrder[get(b, 'selectedValue')] || 0)
        },
        width: 210
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ value: url }) => <Link href={getFullUrl(url)} target='_blank'>{url}</Link>,
        maxWidth: 200
      },
      {
        Header: 'Users',
        id: 'activeUsersWithPermissions',
        accessor: 'activeUsersWithPermissions',
        Cell: ({ value: activeUsersWithPermissions }) => activeUsersWithPermissions,
        maxWidth: 80
      },
      {
        Header: (
          <Tooltip
            label={<div style={{ textAlign: 'center', maxWidth: '200px' }}>Risk Level is based on Torii’s interpretation of permissions’ sensitivity, some companies may interpret it differently</div>}
          >
            Risk Level
          </Tooltip>
        ),
        accessor: 'riskLevel',
        Cell: ({ value: riskLevel }) => {
          return (riskLevel > 0)
            ? <div {...CSS.riskLevel}><RiskIcon riskLevel={riskLevel} /><span>{permissionsLegend[riskLevel].text}</span></div>
            : '-'
        },
        width: 120
      },
      {
        Header: 'Permissions',
        id: 'permissions',
        accessor: (row) => (
          {
            permissions: row.permissions,
            appState: row.stateInfo.selectedValue
          }
        ),
        Cell: ({ value, index }) => {
          const { isBlockThirdPartyAppsEnabled, source } = this.props
          const { permissions, appState } = value

          if (this.isBlockAccessSupportedForSource(source) && isBlockThirdPartyAppsEnabled && appState === 'closed') {
            return !permissions.length ? 'Revoked by Torii' : 'Revoking permissions…'
          }

          const levelControlStyle = { color: colors.blue, cursor: 'pointer' }
          const LevelControl = ({ level, label }) =>
            <div style={levelControlStyle} onClick={() => {
              this.setState({
                showPermissionLevel: {
                  ...this.state.showPermissionLevel,
                  [`${sourceId}_${index}`]: level
                }
              })
            }}>{label}</div>
          let hasExtraPermissions = false
          let hasLessPermissions = false
          let showingAnyPermissions = false
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {
                permissions.map(permission => {
                  const level = getPermissionSensitivityLevel(sourceId, permission)
                  if (level < (this.state.showPermissionLevel[`${sourceId}_${index}`] || 3)) {
                    hasExtraPermissions = true
                    return ''
                  }
                  showingAnyPermissions = true
                  if (level < 3) {
                    hasLessPermissions = true
                  }
                  const description = getPermissionDescription(sourceId, permission)
                  return (
                    <Tooltip
                      key={permission}
                      label={<div>{description}</div>}
                    >
                      <div {...oneLiner}>{description}</div>
                    </Tooltip>
                  )
                })
              }
              {hasExtraPermissions && <LevelControl level={1} label={showingAnyPermissions ? 'View all' : 'View'} />}
              {hasLessPermissions && <LevelControl level={3} label='Hide' />}
            </div>
          )
        }
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'idApp',
        show: false
      }
    ]

  searchFilter = (app) => {
    const { search } = this.props
    const searchTerm = search.toLowerCase()
    return !searchTerm || app.name.toLowerCase().includes(searchTerm)
  }

  onToggle = (isOpen) => {
    this.setState({ isOpen })
  }

  sourceHasClosedApps = (source) => {
    return source.apps?.some(app => app.stateInfo?.selectedValue === 'closed')
  }

  isBlockAccessSupportedForSource = (source) => {
    const supportedSources = [SOURCE_TYPES.google.id]
    return supportedSources.includes(source.id)
  }

  shouldDisplayAlertsForSource = (source) => {
    const sourceHasBlockAccess = this.isBlockAccessSupportedForSource(source)
    const hasClosedApps = this.sourceHasClosedApps(source)

    return sourceHasBlockAccess && hasClosedApps
  }

  render () {
    const { isOpen } = this.state
    const { source, loading, search, isBlockThirdPartyAppsEnabled, connectedServices, connectedServicesWithBlockAccess } = this.props
    const columns = this.cols
    const appsWithPermissions = !isBlockThirdPartyAppsEnabled ? source.apps.filter(app => app.permissions.length) : source.apps
    const data = search ? appsWithPermissions.filter(this.searchFilter) : appsWithPermissions

    if (data && data.length === 0 && search) {
      return <div {...CSS.notFound}>No results found for "{search}"</div>
    }

    return (
      <ExpandableSection
        key={source.id}
        isOpen={Boolean(search) || isOpen}
        onToggle={this.onToggle}
        avatar={(
          <RelativeTeamLink to={`/app/${source.idApp}`}>
            <AppIcon size='medium' appImageUrl={get(source, 'icon.color')} appName={source.name} />
          </RelativeTeamLink>
        )}
        title={(
          <SourceWithAppsTitle
            source={source}
            isBlockThirdPartyAppsEnabled={isBlockThirdPartyAppsEnabled}
            shouldDisplayAlertsForSource={this.shouldDisplayAlertsForSource(source)}
          />
        )}
      >
        {
          <VisibleFor feature={FEATURES.THIRD_PARTY_APPS.BLOCK_ACCESS}>
            <ReportNotificationsForSource
              isBlockThirdPartyAppsEnabled={isBlockThirdPartyAppsEnabled}
              connectedServices={connectedServices}
              connectedServicesWithBlockAccess={connectedServicesWithBlockAccess}
              shouldDisplayBlockAccessNotification={this.shouldDisplayAlertsForSource(source)}
            />
          </VisibleFor>
        }
        <Table
          tableKey={TABLES.thirdPartyReportTable.key}
          style={{ border: '1px solid transparent', minHeight: 'none' }}
          data={data || EMPTY_ARRAY}
          columns={columns}
          loading={loading}
          scrollObjectId={source.id}
          tableHeaderStyle={{ padding: 0, paddingTop: 8 }}
          forceShowNumberOfResults
        />
      </ExpandableSection>
    )
  }
}

SourceWithApps.propTypes = sourceType

export default SourceWithApps
