import { WORKFLOW_NODE_TYPE } from '@shared/workflows/types'
import { getBranchActionBranchesData } from './getBranchActionBranchesData'
import {
  DfsTraverseActionsParams,
  HandleActionNodeParams,
  HandleActionWithFixedBranchesNodeParams,
  HandleBranchActionNodeParams,
  HandleBranchesParams
} from './dfsTraverseActions.types'
import { getFixedBranchesData } from './getFixedBranchesData'

const handleBranches = ({
  branches,
  defaultBranch,
  nodes,
  handleAction,
  handleBranchAction,
  handleActionWithFixedBranches,
  idCurrentNode,
  idAncestors,
  depth
}: HandleBranchesParams): void => {
  const idAncestorsWithCurrent = [...idAncestors, idCurrentNode]

  for (const branch of branches) {
    if (branch.next) {
      dfsTraverseActions({
        idCurrentNode: branch.next,
        nodes,
        handleAction,
        handleBranchAction,
        handleActionWithFixedBranches,
        idAncestors: idAncestorsWithCurrent,
        depth: depth + 1
      })
    }
  }

  if (defaultBranch.next) {
    dfsTraverseActions({
      idCurrentNode: defaultBranch.next,
      nodes,
      handleAction,
      handleBranchAction,
      handleActionWithFixedBranches,
      idAncestors: idAncestorsWithCurrent,
      depth: depth + 1
    })
  }
}

const handleBranchActionNode = ({
  currentNode,
  idCurrentNode,
  nodes,
  handleAction,
  handleBranchAction,
  handleActionWithFixedBranches,
  idAncestors,
  depth = 0
}: HandleBranchActionNodeParams): void => {
  handleBranchAction({
    idNode: idCurrentNode,
    branchNode: currentNode,
    idAncestors,
    depth
  })

  const { branches, defaultBranch } = getBranchActionBranchesData(currentNode)

  handleBranches({
    branches,
    defaultBranch,
    nodes,
    handleAction,
    handleBranchAction,
    handleActionWithFixedBranches,
    idCurrentNode,
    idAncestors,
    depth
  })
}

const handleActionNode = ({
  currentNode,
  idCurrentNode,
  nodes,
  handleAction,
  handleBranchAction,
  handleActionWithFixedBranches,
  idAncestors,
  depth = 0
}: HandleActionNodeParams): void => {
  handleAction({
    idNode: idCurrentNode,
    actionNode: currentNode,
    idAncestors,
    depth
  })
  if (currentNode.next) {
    dfsTraverseActions({
      idCurrentNode: currentNode.next,
      nodes,
      handleAction,
      handleBranchAction,
      handleActionWithFixedBranches,
      idAncestors: [...idAncestors, idCurrentNode],
      depth: depth + 1
    })
  }
}

const handleActionWithFixedBranchesNode = ({
  currentNode,
  idCurrentNode,
  nodes,
  handleAction,
  handleBranchAction,
  handleActionWithFixedBranches,
  idAncestors,
  depth = 0
}: HandleActionWithFixedBranchesNodeParams): void => {
  handleActionWithFixedBranches({
    idNode: idCurrentNode,
    actionNode: currentNode,
    idAncestors,
    depth
  })

  const { branches, defaultBranch } = getFixedBranchesData(currentNode)

  handleBranches({
    branches,
    defaultBranch,
    nodes,
    handleAction,
    handleBranchAction,
    handleActionWithFixedBranches,
    idCurrentNode,
    idAncestors,
    depth
  })
}

export const dfsTraverseActions = ({
  idCurrentNode,
  nodes,
  handleAction,
  handleBranchAction,
  handleActionWithFixedBranches,
  idAncestors = [],
  depth = 0
}: DfsTraverseActionsParams): void => {
  const currentNode = nodes[idCurrentNode]
  if (!currentNode) {
    throw new Error(`Node with id ${idCurrentNode} not found`)
  }

  const params = {
    idCurrentNode,
    nodes,
    handleAction,
    handleBranchAction,
    handleActionWithFixedBranches,
    idAncestors,
    depth
  }

  if (currentNode.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
    handleBranchActionNode({
      currentNode,
      ...params
    })
  } else if (currentNode.type === WORKFLOW_NODE_TYPE.ACTION) {
    handleActionNode({
      currentNode,
      ...params
    })
  } else if (currentNode.type === WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES) {
    handleActionWithFixedBranchesNode({
      currentNode,
      ...params
    })
  }
}
