export type MergeUsersRulesAliasConfig = {
  idApp: number
  userFieldKey: string
}

export enum MERGE_USERS_RULES_TYPE {
  ALIAS = 'alias'
}

export type RuleData = {
  type?: MERGE_USERS_RULES_TYPE
  config: MergeUsersRulesAliasConfig
}

export interface MergeUsersRule extends RuleData {
  id: number
  idOrg: number
}

export type Props = {
  onConfigureMergeClick: () => void
  onManageMergeUsersClick: (rule) => void
  onRevokeMergeUsersClick: (rule) => void
}
