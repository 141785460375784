import React from 'react'
import PropTypes from 'prop-types'
import ToriiSelect from '../select'
import Placeholder from '../placeholder'
import EnableFor from '../enableFor'
import { SCOPES } from '../../constants'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { SelectComponents } from '@toriihq/design-system'

const statusSystemKey = 'status'

class SelectContractStatus extends React.Component {
  state = { selectedValue: this.props.selectedValue }

  componentDidUpdate (prevProps) {
    if (this.props.selectedValue !== prevProps.selectedValue) {
      this.setState({ selectedValue: this.props.selectedValue })
    }
  }

  setValue = (selectedOption) => {
    const { idOrg, idContract, updateContractDetails, onChange, idApp } = this.props
    const selectedValue = selectedOption.value

    this.setState({ selectedValue })
    if (onChange) {
      return onChange(selectedValue)
    } else {
      updateContractDetails({ idOrg, idContract, details: { [statusSystemKey]: selectedValue }, belongsToIdApp: idApp })
    }
  }

  render () {
    const { options, idContract, isLoading, onBlur, idOrg, idApp } = this.props
    const { selectedValue } = this.state
    return <div>
      <Placeholder loading={isLoading} type='rect' style={{ width: '114px', height: '20px' }}>
        <EnableFor scopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}>
          <ToriiSelect
            placeholder='Select status...'
            options={options}
            components={{ Option: SelectComponents.StateOption, SingleValue: SelectComponents.StateValue }}
            onChange={this.setValue}
            value={selectedValue}
            valueKey='value'
            clearable={false}
            searchable={false}
            key={`contract_status_${idContract}`}
            name={`contract_status_${idContract}`}
            onBlur={(event) => onBlur && onBlur(event)}
          />
        </EnableFor>
      </Placeholder>
    </div>
  }
}

SelectContractStatus.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string
  })),
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  idContract: PropTypes.number,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onBlur: PropTypes.func
}

SelectContractStatus.defaultProps = {
  options: []
}

export default SelectContractStatus
