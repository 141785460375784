import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from '@toriihq/design-system'
import UserDetails from '../userDetails'
import texts from '../../../shared/style/texts'
import { getDisplayName } from '../../../lenses/users'
import moment from 'moment'
import { DATE_FORMAT } from '@root/constants'

class OffboardingUser extends React.Component {
  render () {
    const { user, startedByUser } = this.props
    const startedBy = startedByUser ? getDisplayName(startedByUser) : user.offboardingStartedByIdWorkflowAction ? 'Workflow' : null
    const startedByMessage = startedBy ? <span>Offboarding started By <strong>{startedBy}</strong> on <strong>{moment(user.offboardingStartTime).format(DATE_FORMAT)}</strong></span> : ''
    return <Fragment>
      <Row>
        <Col>
          <UserDetails user={user} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div {...texts.subheading}>{startedByMessage}</div>
        </Col>
      </Row>
    </Fragment>
  }
}

OffboardingUser.propTypes = {
  idUser: PropTypes.number.isRequired,
  idOrg: PropTypes.number.isRequired
}

export default OffboardingUser
