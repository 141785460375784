import React, { ReactElement, ReactNode } from 'react'
import { StyledA } from '@components/_shared/styledLink/styles'

interface Props {
  href: HTMLAnchorElement['href']
  children: ReactNode
}

export const StyledLink = ({
  href,
  children
}: Props): ReactElement => {
  return <StyledA
    href={href}
    target='_blank'
    rel='noopener noreferrer'
  >
    {children}
  </StyledA>
}
