import { css } from 'glamor'
import colors from '@shared/style/colors'

export const ContentArea = css({
  height: 212,
  overflowY: 'unset'
})

export const CustomAppContentArea = css({
  height: 496
})

export const Fields = css({
  minHeight: 290,
  '> div:first-child': {
    marginTop: 0
  }
})

export const ErrorMessage = css({
  color: colors.red,
  marginTop: '5px'
})

export const Description = css({
  maxWidth: 'initial'
})

export const AutoFillByAIButton = css({
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'column'
})
