import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'

export const header = css(texts.headers.regular, {
  textAlign: 'center',
  padding: '42px 0 0'
})

export const postUploadFooter = css(texts.body, {
  borderTop: `1px solid ${colors.border}`,
  padding: '22px 15px',
  marginBottom: '-12px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  ' > div': {
    marginLeft: '30px'
  }
})

export const bodyDescription = css(texts.body, {
  color: colors.darkText
})

export const displayArea = css({
  width: '100%'
})

export const iconContainer = css({
  height: '77px',
  width: '77px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const text = css(texts.subheading, {
  marginTop: '20px',
  textAlign: 'center',
  ' span': {
    color: colors.blue
  }
})

export const darkText = css(texts.subheading, {
  color: colors.darkText,
  textAlign: 'center'
})

export const uploading = css({
  marginBottom: '38px'
})

export const alignment = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
})

export const select = css({
  width: '100%'
})

export const detailsContainer = css({
  border: `1px solid ${colors.grey3}`,
  padding: 10,
  borderRadius: 4,
  margin: '0px 30px 15px'
})

export const detailsRow = css({
  ':not(:last-child)': {
    marginBottom: 5
  }
})

export const textSpacingTop = css({
  marginTop: 3
})

export const textSpacingBottom = css({
  marginBottom: 15
})

export const dropZoneStyle = css({
  height: '212px',
  border: `1px dashed ${colors.blue}`,
  borderRadius: '2px',
  opacity: 1
}).toString()

export const dropZoneContent = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%'
})
export const dropZoneContentStyle = css(displayArea, alignment, { cursor: 'pointer' })
