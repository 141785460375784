import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { fontSize } from '../../../shared/style/sizes'
import { formatNumber } from '@root/shared/utils'

const CSS = {
  tooltip: css({
    width: '322px'
  }),
  square: css({
    width: '8px',
    height: '8px',
    marginRight: '5px',
    backgroundColor: colors.lightBlue2
  }),
  headerLine: css(texts.heading, {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  }),
  commentLower: css(texts.caption, {
    color: colors.grey2,
    fontSize: fontSize.small
  }),
  number: css(texts.headers.regular, {
    marginLeft: 'auto'
  })
}

const LicenseTooltipContent = ({ header, description, amount, colorCss }) => (
  <div {...CSS.tooltip}>
    <div {...CSS.headerLine}>
      <div {...CSS.headerLine}>
        <div {...css(CSS.square, colorCss)} />
        {header}
      </div>
      <div {...CSS.number}> {formatNumber(amount)} </div>
    </div>
    <div {...CSS.commentLower}>{description}</div>
  </div>
)

export default LicenseTooltipContent
