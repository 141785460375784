import { ReactElement } from 'react'
import { AppIcon, Icon, Tooltip } from '@toriihq/design-system'
import {
  Container,
  IconContainer,
  Subtitle,
  TextContainer,
  Title
} from './styles'
import { NODE_STATUS_TYPE } from './types'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'

interface Props {
  title: string
  status: string
  statusType: NODE_STATUS_TYPE
  iconUrl: string
  errorMsg?: string
}

export const WorkflowNodeContent = ({
  title,
  iconUrl,
  status,
  statusType,
  errorMsg
}: Props): ReactElement => {
  let errorIcon: ReactElement | null = null
  const { idAudit } = useWorkflowEditorContext()
  const isPreviewMode = Boolean(idAudit)
  if (statusType === NODE_STATUS_TYPE.ERROR) {
    errorIcon = <Icon name='Danger' color='inherit' />
    if (errorMsg) {
      errorIcon = <Tooltip label={errorMsg}>
        {errorIcon}
      </Tooltip>
    }
  }

  return (
    <Container>
      <IconContainer>
        <AppIcon
          appImageUrl={iconUrl}
          size='x-large'
        />
      </IconContainer>
      <TextContainer className={`status-${statusType}`}>
        <Title title={title} transform={!isPreviewMode}>{title}</Title>
        {
          isPreviewMode ? null : <Subtitle>
            {errorIcon}
            {status}
          </Subtitle>
        }
      </TextContainer>
    </Container>
  )
}
