import React from 'react'
import Users from '../../components/usersTabs/usersV2'
import Employees from '../../components/usersTabs/employees'
import TabsPage from '../tabs'
import ExternalUsers from '../../components/usersTabs/externalUsers'
import { USER_TYPE } from '@root/constants'
import { FEATURES } from '@root/shared/features'

class UsersPage extends React.Component {
  componentDidMount () {
    const { idOrg, deprecatedGetApps, getUserDetailsFields, getCurrentUsersAmount, getExternalUsersAmount, getUserTypeAmount } = this.props
    deprecatedGetApps({ idOrg })
    getUserDetailsFields({ idOrg })
    getCurrentUsersAmount({ idOrg })
    getExternalUsersAmount({ idOrg })
    getUserTypeAmount({ idOrg, userType: USER_TYPE.EMPLOYEE })
  }

  render () {
    const { currentUsersAmount, externalEmployeesAmount, employeeAmount, apps, idOrg, loading, loadingApps, featuresLoading } = this.props

    const tabsName = ['employees', 'users', 'external']

    const tabsHeader = [
      { header: 'Employees', counter: employeeAmount, isLoading: loading },
      { header: 'Users', counter: currentUsersAmount, isLoading: loading },
      { header: 'External Users', counter: externalEmployeesAmount, isLoading: loading }
    ]

    const tabsContent = [
      <Employees apps={apps} loadingApps={loadingApps} loading={featuresLoading} />,
      <Users apps={apps} loadingApps={loadingApps} loading={featuresLoading} />,
      <ExternalUsers loadingApps={loadingApps} apps={apps} />
    ]

    const tabsFeature = [
      FEATURES.USERS.TABS.EMPLOYEES,
      FEATURES.USERS.TABS.USERS,
      FEATURES.USERS.TABS.EXTERNAL_USERS
    ]

    return (
      <TabsPage
        pageHeader='Users'
        pageTitle='Users'
        pageName='Users'
        path={`/team/${idOrg}/users`}
        tabsName={tabsName}
        tabsHeader={tabsHeader}
        tabsContent={tabsContent}
        tabsFeature={tabsFeature}
      />
    )
  }
}

export default UsersPage
