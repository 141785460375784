import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import PropTypes from 'prop-types'
import LogoWithText from '@media/logo_with_text.svg'
import LogoWithTextWhite from '@media/logo_with_text_white.svg'

const NAVIGATION_WIDTH = '200px'
const HORIZONTAL_PADDING = '80px'
const BAR_HEIGHT = '56px'

const CSS = {
  main: css(texts.body, {
    background: colors.blue,
    color: colors.white,
    height: BAR_HEIGHT,
    padding: `0 ${HORIZONTAL_PADDING} 0 calc(${NAVIGATION_WIDTH} + ${HORIZONTAL_PADDING})`,
    display: 'flex',
    alignItems: 'center'
  }),
  transparent: css({
    background: colors.white,
    boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.15)',
    '> div> img': { height: '21px', width: '72px' }
  }),
  logo: css({
    maxWidth: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    height: BAR_HEIGHT,
    width: '200px',
    paddingLeft: '18px',
    display: 'flex',
    alignItems: 'center'
  })
}

const BasicTopBar = ({ transparent }) => {
  return (
    <div {...css(CSS.main, transparent && CSS.transparent)}>
      <div {...CSS.logo}>
        <img alt='Torii Logo' src={transparent ? LogoWithText : LogoWithTextWhite} />
      </div>
    </div>
  )
}

BasicTopBar.propTypes = {
  transparent: PropTypes.bool
}

BasicTopBar.defaultProps = {
  transparent: false
}

export default BasicTopBar
