import analytics from '@root/helpers/analytics'

const Analytics = {
  onRunAction: (appName, entities, flowType) => {
    const props = {
      'App name': appName,
      'Entities': entities,
      'Flow type': flowType,
      'Dialog name': 'configure execute action on useres'
    }
    analytics.track('Click on run-action', props)
  },
  onCancel: (appName, entities, flowType) => {
    const props = {
      'App name': appName,
      'Entities': entities,
      'Flow type': flowType,
      'Dialog name': 'configure execute action on useres'
    }
    analytics.track('Click on cancel-button', props)
  }
}

export default Analytics
