import { title } from '@root/shared/style/textsStyled'
import styled from 'styled-components'

export const Title = styled.div`
  margin-top: 32px;
  ${title}
`
export const Container = styled.div`
  width: 624px;
  min-height: 300px;
`
export const Wrapper = styled.div`
  > div {
    margin-top: 24px;
  }
`
