import { ActionShareReport } from './types'
import { getStepsAndFieldsWithDefaultValues } from '@shared/workflows/getStepsAndFieldsWithDefaultValues'
import { SCHEDULE_FREQUENCY } from '@actions/reports/reports.types'

export const createAction = ({ actionType, actionsConfig, fieldsDefaultValues, actionsConfigByType, fieldsToValue }): ActionShareReport | undefined => {
  if (!actionType) {
    return
  }

  const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === actionType)

  const { fields } = getStepsAndFieldsWithDefaultValues(null, actionConfig, fieldsDefaultValues, actionsConfigByType, fieldsToValue)

  return { type: actionType, fields, idApp: actionConfig.idApp }
}

export const buildCron = ({ scheduleFrequency, timeOfDay, dayOfWeek, dayOfMonth }: { scheduleFrequency: Exclude<SCHEDULE_FREQUENCY, SCHEDULE_FREQUENCY.ONCE>, timeOfDay: string, dayOfWeek: number, dayOfMonth: number }): string => {
  if (scheduleFrequency === SCHEDULE_FREQUENCY.DAILY) return `0 ${timeOfDay} * * ?`
  if (scheduleFrequency === SCHEDULE_FREQUENCY.WEEKLY) return `0 ${timeOfDay} ? * ${dayOfWeek}`
  if (scheduleFrequency === SCHEDULE_FREQUENCY.MONTHLY) return `0 ${timeOfDay} ${dayOfMonth} * ?`

  return ''
}
