import styled from 'styled-components'

export const ResultBanner = styled.div`
  padding: 16px;
  margin: 32px 48px 24px 48px;
  border-radius: 6px;
  border: 0;
  background: ${props => props.color};
  display: flex;
  flex-direction: row;
`

export const BannerText = styled.div`
  margin-left: 22px;
`

export const Spinner = styled.img`
  height: 16px;
  width: 16px;
  margin-left: auto;
  margin-right: 18px;
`
