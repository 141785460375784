import config from '../config'

const page = (pathname: string): void => {
  const { ChurnZero } = window as any
  ChurnZero && ChurnZero.push(['trackEvent', 'Page Viewed', pathname])
}

type ChurnZero = {
  page: (pathname: string) => void
}

let churnZero: ChurnZero | null = null
if (config.churnzero.enabled) {
  churnZero = {
    page
  }
}

export default churnZero
