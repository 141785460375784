import styled from 'styled-components'

export const INPUT_ERROR_CLASS_NAME = 'input-error'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .${INPUT_ERROR_CLASS_NAME} {
    border: 1px solid ${({ theme }) => theme.palette.border.error}
  }
`

export const RadioButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
