import config from '../config'
import Analytics from './analytics'

export const exportPDF = (idOrg, reportName) => {
  return () => {
    Analytics.track('Export report', {
      'Report type': reportName,
      'Export format': 'PDF'
    })
    const url = `${config.apiBaseUrl}/api/pdf/${idOrg}/export/${reportName}_expenses_report.pdf/${encodeURIComponent(`/reports/${reportName}`)}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }
}

export default exportPDF
