import React from 'react'
import { Input as DSInput, InputProps } from '@toriihq/design-system'

class Input extends React.Component<InputProps> {
  input = React.createRef<HTMLInputElement>()

  focus = () => {
    this.input && this.input.current?.focus()
  }

  blur = () => {
    this.input && this.input.current?.blur()
  }

  onClickToHandleFirefoxNumberInput = (e) => {
    const { onClick, type } = this.props
    if (type === 'number') {
      this.focus()
    }

    onClick && onClick(e)
  }

  onWheelCapture = () => {
    const { type } = this.props
    if (type === 'number') {
      this.blur()
    }
  }

  render () {
    const { ...rest } = this.props
    return (
      <DSInput
        {...rest}
        onClick={this.onClickToHandleFirefoxNumberInput}
        onWheelCapture={this.onWheelCapture}
        ref={this.input}
      />
    )
  }
}

export default Input
