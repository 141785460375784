import React, { ReactElement } from 'react'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { useSelector } from 'react-redux'
import { getIdOrg, getMergeUsersRules } from '@selectors/org'
import { MergeUsersRule } from './mergeUsersRule'
import Analytics from '@root/helpers/analytics'
import { SOURCE_TYPES_BY_ID_APP } from '@root/sourcesConfig'
import ButtonOfFeature from '@components/buttonOfFeature'
import { FEATURES } from '@shared/features'

type Props = {
  onConfigureMergeClick: () => void
  onRevokeMergeUsersClick: () => void
}

export const MergeUsersRules = ({ onConfigureMergeClick, onRevokeMergeUsersClick }: Props): ReactElement => {
  const idOrg = useSelector(getIdOrg)
  const rules = useSelector(getMergeUsersRules)
  const orgRule = rules[0]
  const idApp = orgRule?.config?.idApp
  const appName = SOURCE_TYPES_BY_ID_APP[idApp].name

  const onConfigureRule = () => {
    Analytics.track('Click on configure merge button', { idOrg })
    onConfigureMergeClick()
  }

  const onEditRule = () => {
    Analytics.track('Click on edit merge button', { idOrg, 'App name': appName })
    onConfigureMergeClick()
  }

  const onDeleteRule = () => {
    Analytics.track('Click on revoke merge button', { idOrg, 'App name': appName })
    onRevokeMergeUsersClick()
  }

  return <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
    {orgRule ? <MergeUsersRule rule={orgRule} onEditRule={onEditRule} onDeleteRule={onDeleteRule} /> : <ButtonOfFeature feature={FEATURES.SETTINGS.TABS.USERS_AND_EMPLOYEES.COMPONENTS.MERGE_USERS} onClick={onConfigureRule} label={'Configure merge'} tooltipPlacement={'right'} />}
  </EnableFor>
}
