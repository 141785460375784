import send from '@shared/redux-fetch'
import {
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION_CHECKOUT_SESSION_URL,
  GET_SUBSCRIPTION_CUSTOMER_PORTAL_URL,
  GET_SUBSCRIPTIONS
} from '@root/constants'
import {
  CancelSubscriptionProps,
  GetSubscriptionCheckoutSessionUrlProps,
  GetSubscriptionCustomerPortalSessionUrlProps,
  GetSubscriptionsProps
} from './types'

export const getSubscriptionCheckoutSessionUrl = ({ idOrg, interval, quantity }: GetSubscriptionCheckoutSessionUrlProps) => {
  return send(GET_SUBSCRIPTION_CHECKOUT_SESSION_URL, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/stripe/checkoutSession`,
    body: JSON.stringify({
      interval,
      quantity
    })
  })
}
export const getSubscriptionCustomerPortalSessionUrl = ({ idOrg }: GetSubscriptionCustomerPortalSessionUrlProps) => {
  return send(GET_SUBSCRIPTION_CUSTOMER_PORTAL_URL, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/stripe/customerPortalSession`
  })
}

export const getSubscriptions = ({ idOrg }: GetSubscriptionsProps) => {
  return send(GET_SUBSCRIPTIONS, {
    url: `/api/orgs/${idOrg}/stripe/subscriptions`
  })
}

export const cancelSubscription = ({ idOrg, idStripeSubscription }: CancelSubscriptionProps) => {
  return send(CANCEL_SUBSCRIPTION, {
    method: 'DELETE',
    url: `/api/orgs/${idOrg}/stripe/subscriptions/${idStripeSubscription}`,
    meta: { idStripeSubscription }
  })
}
