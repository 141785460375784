import styled from 'styled-components'
import Placeholder from '@components/placeholder'

export const StyledPlaceholderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
`

export const StyledPlaceholder = styled(Placeholder)`
    height: 32px;
    width: 200px;
`

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Tooltip = styled.div`
    width: 310px;
    padding: 12px 16px 2px 16px;
    border: 1px solid ${({ theme }) => theme.palette.border.secondary};
    background-color: ${({ theme }) => theme.palette.background.primary};
    border-radius: 8px;
`

export const TooltipHeader = styled.div`
    margin-bottom: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const TooltipRow = styled.div <{ marginBottom: number }>`
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const TooltipBreakdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
`

export const Square = styled.span<{ color: string }>`
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 3px;
    background-color: ${({ color }) => color};
`

export const EmptyStateContainer = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
`

export const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -4px;
`

export const FlexColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`

export const TableWrapper = styled.div`
    padding-top: 4px;
`
