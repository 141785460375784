import { css } from 'glamor'
import styled from 'styled-components'

const CSS = {
  name: css({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal'
  }),
  activeColumn: css({
    display: 'flex',
    alignItems: 'center'
  })
}

export default CSS

export const User = styled.div`
 width: 30px;
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2px;
`

export const WorkflowNameColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
`
