import React from 'react'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import colors from '../../shared/style/colors'
import Placeholder from '../placeholder'
import Analytics from '../../helpers/analytics'
import copy from 'copy-to-clipboard'
import { APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS, SCOPES } from '@root/constants'
import { LOGO_TYPES } from '@root/constants.t'
import EnableFor from '../enableFor'
import EmailSetupBox from '../workflows/emailSetup/emailSetupBox'
import catalogPreviewDisabled from './images/catalogPreviewDisabled.svg'
import catalogPreviewEnabled from './images/catalogPreviewEnabled.svg'
import requestNewAppDisabled from './images/requestNewAppDisabled.svg'
import requestNewAppEnabled from './images/requestNewAppEnabled.svg'
import catalogPreviewNoLogo from './images/catalogPreviewNoLogo.svg'
import catalogPreviewWithLogo from './images/catalogPreviewWithLogo.svg'
import { Col, Grid, Row, Button, ExpandableSection, TextBadge, Body2, Spacer, RowWithoutMargin, Icon, Tooltip } from '@toriihq/design-system'
import { Form, Field } from 'react-final-form'
import noop from 'lodash/noop'
import InputField from '../popups/inputPopup/input'
import { fontWeight } from '@shared/style/sizes'
import MultipleUsers from '../workflows/multipleUsers'
import keyBy from 'lodash/keyBy'
import ToriiSelect from '../select'
import { getFullUrl, urlValidator } from '@shared/utils'
import { EMAIL_SETUP_FIELD } from '../workflows/emailSetup/emailSetupBox/view'
import FormGroup from '../form/formGroup'
import pluralize from 'pluralize'
import SelectAppFields from '@components/selectAppFields'
import CatalogAppsFilters from '@components/catalogAppsFilters'
import BrowseUploadBox from '../../components/browseUploadBox'
import LogoContainer from './components/LogoContainer'
import ToriiPopup from '../popups/ToriiPopupV2'
import VisibleFor from '../visibleFor'
import ToriiToggle from '@components/toriiToggle'

const FIELDS_WIDTH = '60%'

const CSS = {
  container: css(texts.body, {
    display: 'flex',
    flexDirection: 'column',
    color: colors.black,
    gap: '20px',
    paddingBottom: '40px'
  }),
  text: css(texts.body, {
    color: colors.black
  }),
  emailBox: css({
    border: 'none',
    padding: '4px 20px 0px 0px'
  }),
  catalogPreview: css({
    textAlign: 'right'
  }),
  newAppPreview: css({
    marginTop: '9px'
  }),
  requestAccessPreview: css({
    marginTop: '3px'
  }),
  catalogPreviewImage: css({
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.1)',
    width: '100%'
  }),
  toMultiUserSelect: css({
    width: '100%',
    ' .Select-control': {
      border: 'none !important'
    }
  }),
  spaceOnTop: css({
    marginTop: '10px'
  }),
  numberOfConfiguredApps: css({
    marginBottom: '8px',
    alignSelf: 'flex-Start'
  }),
  triggerWorkflow: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  inputField: css({
    width: FIELDS_WIDTH
  }),
  placeholder: {
    maxWidth: '100%',
    marginTop: '20px',
    fontSize: '64px',
    height: '390px'
  },
  textBottomPadding: css({
    paddingBottom: '10px'
  }),
  textTopPaddding: css({
    paddingTop: '10px'
  }),
  logoErrorPopupText: css({
    color: colors.black
  }),
  appsPolicy: css({
    fontWeight: fontWeight.bold
  }),
  viewAndEditPoliciesButton: css({
    alignSelf: 'flex-Start',
    marginTop: '12px'
  }),
  requestNewAppPoliciesText: css({
    display: 'flex',
    alignSelf: 'flex-start'
  }),
  space: css({
    marginRight: '3px'
  }),
  alignBottom: css({
    display: 'flex',
    alignItems: 'flex-end'
  })
}

const requestLicenseConfigKey = 'requestLicenseConfig'
const requestNewAppConfigkey = 'requestNewAppConfig'
const appFieldsConfigKey = 'appFieldsConfig'
const appsFiltersConfigKey = 'appsFiltersConfig'

class AppCatalog extends React.Component {
  state = {
    copySuccess: null,
    isSelectLogoLoading: false,
    logoUploadingErrorOpen: false
  }

  componentDidMount () {
    const { getAppDetailsValues, org } = this.props
    org.id && getAppDetailsValues({ idOrg: org.id })
  }

  componentDidUpdate (prevProps) {
    const { getAppDetailsValues, org } = this.props
    if (org.id && prevProps.org.id !== org.id) {
      getAppDetailsValues({ idOrg: org.id })
    }
  }

  onToggleCatalogSetting = (settingKey) => {
    const { org, catalogSettings, updateAppCatalogSettings } = this.props
    updateAppCatalogSettings({ idOrg: org.id, [settingKey]: !catalogSettings[settingKey] })
  }

  copyToClipboard (link) {
    copy(link)
    this.setState({ copySuccess: 'Link copied!' })
    Analytics.track('User copied app catalog link', {
      'catalogLink': link
    })
    setTimeout(() => this.setState({ copySuccess: null }), 2000)
  }

  reportClick (e) {
    Analytics.track('User clicked on app catalog link', {
      'catalogLink': e.currentTarget.href
    })
  }

  reportToggleCollapse (isOpen, collapseBoxName) {
    Analytics.track(`${!isOpen ? 'Open' : 'Close'} app-catalog-setup-section`, {
      'Section Name': collapseBoxName
    })
  }

  renderRequestAccess = () => {
    const {
      catalogSettings,
      personalization,
      onConfigUpdate,
      onSelectActionUpdate,
      specialUsers,
      history,
      org: { id: idOrg },
      isLoadingCatalogApps,
      policiesInfo
    } = this.props
    const {
      isRequestLicenseEnabled,
      requestLicenseConfig = [],
      defaultConfig = {},
      requestLicenseOptions = []
    } = catalogSettings
    const catalogPreview = catalogSettings.isRequestLicenseEnabled ? catalogPreviewEnabled : catalogPreviewDisabled
    const renderers = {
      [EMAIL_SETUP_FIELD.to]: props => <MultipleUsers text='To:' selectClassName={CSS.toMultiUserSelect.toString()}
        specialUsers={specialUsers} {...props} />,
      [EMAIL_SETUP_FIELD.cc]: props => <MultipleUsers text='CC:' adminsOnly
        selectClassName={CSS.toMultiUserSelect.toString()}
        specialUsers={specialUsers} {...props} />
    }
    const configurationFieldsById = keyBy(requestLicenseConfig, 'id')
    const selectActionField = configurationFieldsById['actionSelection'] || {}
    const buttonNameField = configurationFieldsById['buttonName'] || {}
    const urlField = configurationFieldsById['url'] || {}
    const isComposeEmail = selectActionField.value === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.COMPOSE_EMAIL
    const isOpenLink = selectActionField.value === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.OPEN_LINK
    const isTriggerWorkflow = selectActionField.value === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.TRIGGER_WORKFLOW
    const buttonTextValidator = value => !value ? 'Field is required' : undefined
    const { idAppsWithCustomPolicy, idAppsWithDefaultPolicy } = policiesInfo || {}

    const onViewAndEditPoliciesClick = () => {
      Analytics.track(`Click on view-and-edit-policies-button`)
      history.push(`/team/${idOrg}/appCatalog/accessPolicies`)
    }

    return (
      <ExpandableSection
        title='Request access to an application'
        rightSlot={<TextBadge size='Small' color='blue'>{isRequestLicenseEnabled ? 'On' : 'Off'}</TextBadge>}
      >
        <Grid>
          <RowWithoutMargin nogutter>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Body2 color='tertiary'>Enable a button on the catalog allowing employees to request access to an application.</Body2>
              <div style={{ display: 'flex' }}>
                <Spacer right='space-100'><Body2>Enable request access button</Body2></Spacer>
                <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                  <ToriiToggle
                    id={'enable-catalog-request-access-button-toggle'}
                    checked={Boolean(isRequestLicenseEnabled)}
                    onToggle={() => this.onToggleCatalogSetting('isRequestLicenseEnabled')}
                  />
                </EnableFor>
              </div>
            </div>
          </RowWithoutMargin>
          <Row {...CSS.spaceOnTop}>
            <Col sm={7}>
              <Form
                onSubmit={noop}
                initialValues={{
                  [buttonNameField.id]: buttonNameField.value,
                  [urlField.id]: urlField.value,
                  buttonAction: selectActionField.value
                }}
                render={(formProps) => {
                  const { handleSubmit } = formProps
                  return <form onSubmit={handleSubmit}>
                    <div {...CSS.inputField}>
                      <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                        <Field
                          label='Button name:'
                          key={buttonNameField.id}
                          name={buttonNameField.id}
                          component={this.renderLicenseField}
                          field={buttonNameField}
                          validate={buttonTextValidator}
                          autoFocus={false}
                          disabled={!isRequestLicenseEnabled}
                        />
                      </EnableFor>
                    </div>
                    <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                      <Field
                        id='request-access-action-selection'
                        label='Button action:'
                        name='buttonAction'
                        component={this.renderSelectField}
                        options={requestLicenseOptions}
                        placeholder='Select action'
                        onChange={({ value }) => {
                          if (selectActionField.value !== value) {
                            const updatedSelectActionField = { ...selectActionField, value: value }
                            const selectedActionDefaultConfig = defaultConfig[value]
                            const updatedConfig = [{ ...buttonNameField }, updatedSelectActionField, ...selectedActionDefaultConfig]
                            onSelectActionUpdate({ config: updatedConfig, configKey: requestLicenseConfigKey })
                          }
                        }}
                        clearable={false}
                        disabled={!isRequestLicenseEnabled}
                      />
                    </EnableFor>
                    {isComposeEmail &&
                    <EmailSetupBox
                      disabled={!isRequestLicenseEnabled}
                      fields={requestLicenseConfig}
                      personalization={personalization}
                      border={false}
                      overrideStyle={CSS.emailBox}
                      onChange={(updatedData) => onConfigUpdate({ ...updatedData, configKey: requestLicenseConfigKey })}
                      renderers={renderers}
                      allowedScopes={[SCOPES.APPLICATION_CATALOG_WRITE]}
                    />}
                    {isOpenLink &&
                    <div {...CSS.inputField}>
                      <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                        <Field
                          label='Link to:'
                          key={urlField.id}
                          name={urlField.id}
                          component={this.renderLicenseField}
                          field={urlField}
                          autoFocus={false}
                          validate={(url) => urlValidator(url) ? undefined : 'Invalid url'}
                          parse={(url) => getFullUrl(url)}
                        />
                      </EnableFor>
                    </div>}
                    {isTriggerWorkflow &&
                    <div {...CSS.triggerWorkflow}>
                      <div {...css(CSS.text, CSS.numberOfConfiguredApps)} >
                        <span {...CSS.appsPolicy}>{isLoadingCatalogApps ? 'Calculating...' : pluralize('app', idAppsWithDefaultPolicy.length, true)}</span> configured
                        with default policy
                      </div>
                      <div {...css(CSS.text, CSS.numberOfConfiguredApps)} >
                        <span {...CSS.appsPolicy}> {isLoadingCatalogApps ? 'Calculating...' : pluralize('app', idAppsWithCustomPolicy.length, true)}</span> configured
                        with custom policy
                      </div>
                      <div {...css(CSS.text, CSS.viewAndEditPoliciesButton)} >
                        <VisibleFor scopes={[SCOPES.AUTOMATION_WRITE]}
                          fallback={<Button onClick={onViewAndEditPoliciesClick} label='View policies' />}>
                          <Button onClick={onViewAndEditPoliciesClick} label='View and edit policies' />
                        </VisibleFor>
                      </div>
                    </div>
                    }
                  </form>
                }}
              />
            </Col>
            <Col sm={5}>
              <div {...css(CSS.catalogPreview, CSS.requestAccessPreview)}>
                <img {...CSS.catalogPreviewImage} src={catalogPreview} alt='Application catalog preview' />
              </div>
            </Col>
          </Row>
        </Grid>
      </ExpandableSection>
    )
  }

  renderLicenseField = (props) => {
    const { input, field } = props

    return <InputField
      {...props}
      input={{
        ...input,
        onBlur: e => {
          const selectedValue = e.target ? e.target.value : (e || '')
          input.onBlur(e)
          if (props.meta.valid && props.meta.dirty) {
            this.props.onConfigUpdate({ fieldId: field.id, selectedValue, configKey: requestLicenseConfigKey })
          }
        }
      }} />
  }

  renderField = (props) => {
    const { input, field } = props

    return <InputField
      {...props}
      input={{
        ...input,
        onBlur: e => {
          const selectedValue = e.target ? e.target.value : (e || '')
          input.onBlur(e)
          if (props.meta.valid && props.meta.dirty) {
            this.props.onConfigUpdate({ fieldId: field.id, selectedValue, configKey: requestNewAppConfigkey })
          }
        }
      }} />
  }

  renderSelectAppFields = (props) => {
    const { onConfigUpdate } = this.props
    const { input } = props

    return (
      <SelectAppFields
        {...input}
        {...props}
        onChange={(updatedData) => {
          onConfigUpdate({ selectedValue: updatedData, configKey: appFieldsConfigKey })
        }}
      />)
  }

  renderSelectField = (props) => {
    const { input, label, options, placeholder, onChange, disabled } = props

    return (
      <FormGroup label={label} disabled={disabled}>
        <div style={{ width: '60%' }}>
          <ToriiSelect
            {...input}
            options={options}
            placeholder={placeholder}
            onChange={onChange}
            clearable={false}
            disabled={disabled}
          />
        </div>
      </FormGroup>)
  }

  renderSelectAppsBox = () => {
    const { appsFiltersConfig, onConfigUpdate, catalogApps, isLoadingCatalogApps } = this.props

    const onFiltersChange = (updatedFilters) => {
      return onConfigUpdate({ selectedValue: updatedFilters, configKey: appsFiltersConfigKey })
    }

    return (
      <ExpandableSection
        title='Select applications'
        rightSlot={<Body2>{isLoadingCatalogApps ? 'Calculating...' : pluralize('application', catalogApps.length, true)}</Body2>}
        onToggle={(isOpen) => this.reportToggleCollapse(isOpen, 'Select applications')}
      >
        <CatalogAppsFilters
          onFiltersChange={onFiltersChange}
          filters={appsFiltersConfig}
        />
      </ExpandableSection>
    )
  }

  renderSelectAppFieldsBox = () => {
    const { appFieldsConfig } = this.props

    return (
      <ExpandableSection
        title='Select application fields'
        rightSlot={<Body2>{pluralize('fields', appFieldsConfig.length, true)}</Body2>}
        onToggle={(isOpen) => this.reportToggleCollapse(isOpen, 'Select application fields')}
      >
        <Form
          onSubmit={noop}
          initialValues={{ [appFieldsConfigKey]: appFieldsConfig }}
          render={(formProps) => {
            const { handleSubmit } = formProps
            return <form onSubmit={handleSubmit}>
              <Field
                key={appFieldsConfigKey}
                name={appFieldsConfigKey}
                component={this.renderSelectAppFields}
              />
            </form>
          }}
        />
      </ExpandableSection>
    )
  }

  renderRequestNewApp = () => {
    const { catalogSettings, newAppInitialValues, onSelectActionUpdate, history, org: { id: idOrg }, requestNewAppPoliciesAmount, isLoadingCatalogApps } = this.props
    const { isRequestNewAppEnabled, requestNewAppConfig = [], defaultConfig = [], requestNewAppOptions = [] } = catalogSettings
    const catalogPreview = isRequestNewAppEnabled ? requestNewAppEnabled : requestNewAppDisabled
    const configurationFieldsById = keyBy(requestNewAppConfig, 'id')
    const selectActionField = configurationFieldsById['actionSelection'] || {}
    const buttonNameField = configurationFieldsById['buttonText'] || {}
    const urlField = requestNewAppConfig.find(field => field.id === 'url')
    const buttonTextField = requestNewAppConfig.find(field => field.id === 'buttonText')
    const buttonTextValidator = value => !value ? 'Link field is required' : undefined
    const isOpenLink = selectActionField.value === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.OPEN_LINK
    const isTriggerWorkflow = selectActionField.value === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.TRIGGER_WORKFLOW

    const onViewAndEditPoliciesClick = () => {
      Analytics.track(`Click on view-and-edit-policies-button`) // TODO: change analytics event name
      history.push(`/team/${idOrg}/appCatalog/newAppPolicies`)
    }

    const renderRequestNewAppPolicyAmountMessages = (requestNewAppPoliciesAmount) => {
      const noPolicy = {
        message: 'No policies',
        warningTooltipMessage: 'Without an active policy, submitted requests will not be handled. Please configure a policy to automate the process.'
      }

      const onePolicy = {
        message: `${requestNewAppPoliciesAmount} active policy`
      }

      const multiplePolicies = {
        message: `${requestNewAppPoliciesAmount} active policies`,
        warningTooltipMessage: 'It is recommended to have only one active policy. In the case of multiple policies all of them will be triggered.'
      }

      const infoMessageByPolicyAmount = requestNewAppPoliciesAmount === 0 ? noPolicy : requestNewAppPoliciesAmount === 1 ? onePolicy : multiplePolicies

      return (
        <>
          <span {...css(CSS.appsPolicy, CSS.space, CSS.alignBottom)}>{infoMessageByPolicyAmount.message} </span> <span {...CSS.alignBottom}>defined</span>
          {infoMessageByPolicyAmount.warningTooltipMessage ? <Tooltip label={infoMessageByPolicyAmount.warningTooltipMessage}><Icon name='Alert' color='warning' size='desktop' /></Tooltip> : null}
        </>
      )
    }

    return (
      <ExpandableSection
        title='Request new application'
        onToggle={(isOpen) => this.reportToggleCollapse(isOpen, 'Request new application')}
        rightSlot={<TextBadge size='Small' color='blue'>{isRequestNewAppEnabled ? 'On' : 'Off'}</TextBadge>}
      >
        <Grid>
          <RowWithoutMargin nogutter>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Body2 color='tertiary'>Enable a button on the catalog, allowing employees to request a new application.</Body2>
              <div style={{ display: 'flex' }}>
                <Spacer right='space-100'><Body2>Enable request new app button</Body2></Spacer>
                <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                  <ToriiToggle
                    id={'enable-catalog-request-new-app-toggle'}
                    checked={Boolean(isRequestNewAppEnabled)}
                    onToggle={() => this.onToggleCatalogSetting('isRequestNewAppEnabled')}
                  />
                </EnableFor>
              </div>
            </div>
          </RowWithoutMargin>
          <Row {...CSS.spaceOnTop}>
            <Col sm={7}>
              <Form
                onSubmit={noop}
                initialValues={{ ...newAppInitialValues, buttonAction: selectActionField }}
                render={(formProps) => {
                  const { handleSubmit } = formProps
                  return <form onSubmit={handleSubmit}>
                    <div {...CSS.inputField}>
                      <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                        <Field
                          label='Button name:'
                          key={buttonTextField.id}
                          name={buttonTextField.type}
                          component={this.renderField}
                          field={buttonTextField}
                          disabled={!isRequestNewAppEnabled}
                          validate={buttonTextValidator}
                          autoFocus={false}
                        />
                      </EnableFor>
                    </div>
                    <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                      <Field
                        id='request-new-app-action-selection'
                        label='Button action:'
                        name='buttonAction'
                        component={this.renderSelectField}
                        options={requestNewAppOptions}
                        placeholder='Select action'
                        onChange={(newValue) => {
                          const { value } = newValue
                          if (selectActionField.value !== value) {
                            const updatedSelectActionField = { ...selectActionField, ...newValue }
                            const selectedActionDefaultConfig = defaultConfig[value]
                            const updatedConfig = [{ ...buttonNameField }, updatedSelectActionField, ...selectedActionDefaultConfig]
                            onSelectActionUpdate({ config: updatedConfig, configKey: requestNewAppConfigkey })
                          }
                        }}
                        clearable={false}
                        disabled={!isRequestNewAppEnabled}
                      />
                    </EnableFor>
                    {isOpenLink &&
                    <div {...CSS.inputField}>
                      <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
                        <Field
                          label='Link to:'
                          key={urlField.id}
                          name={urlField.type}
                          component={this.renderField}
                          field={urlField}
                          disabled={!isRequestNewAppEnabled}
                          validate={(url) => urlValidator(url) ? undefined : 'Invalid url'}
                          parse={(url) => getFullUrl(url)}
                          autoFocus={false}
                        />
                      </EnableFor>
                    </div>}
                    {isTriggerWorkflow &&
                    <div {...CSS.triggerWorkflow}>
                      <div {...css(CSS.text, CSS.requestNewAppPoliciesText)} >{isLoadingCatalogApps ? 'Calculating...' : renderRequestNewAppPolicyAmountMessages(requestNewAppPoliciesAmount)}</div>
                      <div {...css(CSS.text, CSS.viewAndEditPoliciesButton)} >
                        <VisibleFor scopes={[SCOPES.AUTOMATION_WRITE]} fallback={<Button onClick={onViewAndEditPoliciesClick} label='View policies' />}>
                          <Button onClick={onViewAndEditPoliciesClick} label='View and edit policies' />
                        </VisibleFor>
                      </div>
                    </div>
                    }
                  </form>
                }}
              />
            </Col>
            <Col sm={5}>
              <div {...css(CSS.catalogPreview, CSS.newAppPreview)}>
                <img {...CSS.catalogPreviewImage} src={catalogPreview} alt='Application catalog preview' />
              </div>
            </Col>
          </Row>
        </Grid>
      </ExpandableSection>
    )
  }

  renderSelectLogo = () => {
    const { org, uploadOrgLogo, removeOrgLogo, getOrg } = this.props
    const { isSelectLogoLoading, logoUploadingErrorOpen } = this.state
    const idOrg = org.id

    const handleFileSelect = (file) => {
      Analytics.track('Uploaded app catalog logo')
      this.setState({ isSelectLogoLoading: true }, async () => {
        const succeed = await uploadOrgLogo({ idOrg, logoFile: file, logoType: LOGO_TYPES.CATALOG })
        await getOrg({ idOrg })
        this.setState({ isSelectLogoLoading: false, logoUploadingErrorOpen: !succeed })
      })
    }

    const handleRemoveLogo = async () => {
      Analytics.track('Removed app catalog logo')
      await removeOrgLogo({ idOrg, logoType: LOGO_TYPES.CATALOG })
      getOrg({ idOrg })
    }

    const handleEditLogo = (file) => {
      Analytics.track('Changed app catalog logo')
      this.setState({ isSelectLogoLoading: true }, async () => {
        await removeOrgLogo({ idOrg, logoType: LOGO_TYPES.CATALOG })
        const succeed = await uploadOrgLogo({ idOrg, logoFile: file, logoType: LOGO_TYPES.CATALOG })
        await getOrg({ idOrg })
        this.setState({ isSelectLogoLoading: false, logoUploadingErrorOpen: !succeed })
      })
    }

    return (
      <>
        <ExpandableSection
          title='Select logo'
          rightSlot={<Body2>{org.logoUrl ? 'Selected' : 'Not Selected'}</Body2>}
          onToggle={(isOpen) => {
            this.reportToggleCollapse(isOpen, 'Select logo')
          }}
        >
          <div {...CSS.collapsableBox}>
            <Spacer bottom='space-200'><Body2 color='tertiary'>Show your company logo in the catalog header</Body2></Spacer>
            <Grid>
              <Row>
                <Col sm={7}>
                  {
                    org.logoUrl
                      ? <LogoContainer logo={org.logoUrl} onEdit={handleEditLogo} onRemove={handleRemoveLogo} logoType={LOGO_TYPES.CATALOG} />
                      : <BrowseUploadBox onFileSelect={handleFileSelect} types={['image/*']} isLoading={isSelectLogoLoading} scopes={[SCOPES.APPLICATION_CATALOG_WRITE]} />
                  }
                  <div {...CSS.textTopPaddding}>Recommended size: 1320px x 300px. PNG or SVG are supported.</div>
                </Col>
                <Col sm={5}>
                  <div {...CSS.catalogPreview}>
                    <img {...CSS.catalogPreviewImage} src={org.logoUrl ? catalogPreviewWithLogo : catalogPreviewNoLogo} alt='Application catalog preview' />
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </ExpandableSection>
        <ToriiPopup isOpen={logoUploadingErrorOpen} onCloseAction={() => this.setState({ logoUploadingErrorOpen: false })}>
          <ToriiPopup.Header header={`Failed To Upload Image`} />
          <ToriiPopup.Content>
            <span {...CSS.logoErrorPopupText}>Your image could not be uploaded because it’s format was not PNG or SVG. Please select a valid image and try again.</span>
          </ToriiPopup.Content>
          <ToriiPopup.Footer
            showCancelButton={false}
            mainButtonAction={() => this.setState({ logoUploadingErrorOpen: false })}
            mainButtonText='Close'
          />
        </ToriiPopup>
      </>
    )
  }

  render () {
    const { loading } = this.props
    return (
      <div {...CSS.container}>
        <Body2 color='tertiary'>Configure what to show in the catalog by editing attributes in the sections below</Body2>
        <Placeholder loading={loading} type='text' rows={5} style={CSS.placeholder} widths={[100]}>
          {this.renderSelectLogo()}
          {this.renderSelectAppsBox()}
          {this.renderSelectAppFieldsBox()}
          {this.renderRequestAccess()}
          {this.renderRequestNewApp()}
        </Placeholder>
      </div>
    )
  }
}

export default AppCatalog
