import React from 'react'
import PropTypes from 'prop-types'
import InviteUser, { validate } from '../../inviteUser/index'
import ToriiPopup from '../ToriiPopupV2'

const InviteUserPopup = ({ cancel, isOpen, close }) =>
  (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel}>
      <ToriiPopup.Header header='Invite your team' subHeader='They will receive an invite email to join Torii' />
      <ToriiPopup.Form
        validate={validate}
        render={(formProps) => (
          <InviteUser
            onSuccess={() => {
              const SUCCESS_DELAY = 1000
              setTimeout(close,
                SUCCESS_DELAY)
            }}
            {...formProps}
          />
        )}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            showCancelButton={false}
            mainButtonText='Invite'
            isMainSubmit
            formProps={formProps}
          />
        )}
      />

    </ToriiPopup>
  )

InviteUserPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default InviteUserPopup
