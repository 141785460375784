import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import sumBy from 'lodash/sumBy'
import Currency from '@components/currency'

const AllExpenseCategories = ({ title, categories, total }) => {
  const totalCount = sumBy(categories, 'count')

  return <div {...CSS.main}>
    <table {...CSS.categoriesTable}>
      <tbody>{categories.map((entry, index) => (
        <tr key={entry.name}>
          <td {...CSS.categoryName}>
            <span>{entry.name} {entry.count && `(${entry.count})`}</span>
          </td>
          <td {...CSS.categoryTotal}><Currency value={entry.value} /></td>
        </tr>
      ))}</tbody>
    </table>
    <hr />
    <table {...CSS.categoriesTable}>
      <tbody>
        <tr>
          <td {...CSS.categoryName}>
            <span>TOTAL {totalCount && `(${totalCount} Apps)`}</span>
          </td>
          <td {...CSS.categoryTotal}><Currency value={total} /></td>
        </tr>
      </tbody>
    </table>
  </div>
}

const CSS = {
  main: css({
    background: colors.white
  }),
  categoriesTable: css({
    width: '100%'
  }),
  categoryName: css(texts.body, {
    padding: '8px 0',
    color: colors.grey2
  }),
  categoryTotal: css(texts.subheading, {
    textAlign: 'right',
    color: colors.black
  })
}

AllExpenseCategories.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  total: PropTypes.number
}

AllExpenseCategories.defaultProps = {
  categories: [],
  total: 0
}

export default AllExpenseCategories
