const validate = (filledFields, props) => {
  const { connectFormConfiguration = {} } = props
  const fieldsDefinition = connectFormConfiguration.fields || []

  const errors = fieldsDefinition.reduce((errors, field) => {
    if (!filledFields[field.name] && field.isMandatory) {
      errors[field.name] = 'This field is required'
    }
    if (filledFields[field.name] && field.validation) {
      if (field.validation.pattern) {
        const regex = new RegExp(field.validation.pattern)
        if (!regex.test(filledFields[field.name])) {
          errors[field.name] = field.validation.error
        }
      }
    }
    return errors
  }, {})

  return errors
}

export default validate
