import React from 'react'
import PluginTabs from './pluginTabs'
import { Styles } from './style'
import PluginContentHeader from './pluginContentHeader'
import { propsType } from './types'

const PluginContent = ({ plugin, onAction, idOrg }: propsType) => {
  return (
    <Styles.PluginContent>
      <PluginContentHeader
        name={plugin?.name}
        isInstalled={plugin?.isInstalled}
        onAction={onAction}
      />
      <PluginTabs plugin={plugin} idOrg={idOrg} />
    </Styles.PluginContent>
  )
}

export default PluginContent
