import React, { ReactElement, useState } from 'react'
import * as Style from './style'
import FileUploadButton from '@components/fileUploadButton'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { LOGO_TYPES, SCOPES } from '@root/constants.t'
import EnableFor from '../../../enableFor'
import Spinner from '@media/spinner.svg'
import Confirmation from '@components/confirmation'

const CONFIRM_MESSAGE_BY_TYPE = {
  [LOGO_TYPES.CATALOG]: 'Are you sure you want to remove the logo?',
  [LOGO_TYPES.EMAIL]: `Are you sure you want to remove the logo? Note that emails will be sent with Torii's logo.`
} as const

const ALLOWED_TYPES = {
  [LOGO_TYPES.CATALOG]: ['image/*'],
  [LOGO_TYPES.EMAIL]: ['image/png'],
  [LOGO_TYPES.CUSTOM_APP]: ['image/png']
}

interface Props {
  logo: string
  onEdit: (file: File) => void
  onRemove: () => void
  isLoading?: boolean
  hasDeleteConfirmation?: boolean
  logoType: typeof LOGO_TYPES[keyof typeof LOGO_TYPES]
  imageComponent?: ReactElement
}

const LogoContainer = ({ logo, onEdit, onRemove, isLoading, hasDeleteConfirmation = true, logoType, imageComponent }: Props): ReactElement => {
  const [isContainerMouseOnOver, setIsContainerMouseOnOver] = useState(false)
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false)

  return (
    <div {...Style.Container} onMouseOver={() => setIsContainerMouseOnOver(true)} onMouseOut={() => setIsContainerMouseOnOver(false)}>
      <div {...Style.ImageContainer}>
        {isLoading
          ? <img {...Style.Image} alt='Loading preview...' src={Spinner} width='26px' height='26px' />
          : imageComponent ? <>{imageComponent}</> : <img {...Style.Image} alt='logo' src={logo} />
        }
      </div>
      <div {...(isContainerMouseOnOver ? Style.IconsContainer : Style.Hidden)}>
        <FileUploadButton onFileSelect={onEdit} types={ALLOWED_TYPES[logoType]} scopes={[SCOPES.APPLICATION_CATALOG_WRITE]} icon='Edit' />
        <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
          <Button type={ButtonType.compact} size={ButtonSize.small} htmlButtonType='button' onClick={() => hasDeleteConfirmation ? setIsRemovePopupOpen(true) : onRemove()} icon='Trash' />
        </EnableFor>
      </div>
      <Confirmation
        isOpen={isRemovePopupOpen}
        header='Remove Company Logo?'
        text={CONFIRM_MESSAGE_BY_TYPE[logoType]}
        confirmText='Remove'
        confirm={onRemove}
        close={() => setIsRemovePopupOpen(false)}
        declineText='Cancel'
        decline={() => setIsRemovePopupOpen(false)}
        mainButtonType={ButtonType.destructive}
      />
    </div>
  )
}

export default LogoContainer
