import { GET_PLUGINS, GET_PLUGIN, INSTALL_PLUGIN, UNINSTALL_PLUGIN } from '@root/constants'
import send from '@shared/redux-fetch'

export const getPlugins = ({ idOrg }) => {
  return send(GET_PLUGINS, {
    url: `/api/orgs/${idOrg}/plugins`,
    method: 'GET'
  })
}

export const getPlugin = ({ idOrg, uuidPlugin }) => {
  return send(GET_PLUGIN, {
    url: `/api/orgs/${idOrg}/plugins/${uuidPlugin}`,
    method: 'GET'
  })
}

export const installPlugin = ({ idOrg, idPlugin, data }) => {
  return send(INSTALL_PLUGIN, {
    url: `/api/orgs/${idOrg}/plugins/${idPlugin}/install`,
    method: 'POST',
    body: data,
    meta: {
      idOrg,
      idPlugin
    }
  })
}

export const uninstallPlugin = ({ idOrg, idPlugin }) => {
  return send(UNINSTALL_PLUGIN, {
    url: `/api/orgs/${idOrg}/plugins/${idPlugin}/uninstall`,
    method: 'DELETE',
    meta: {
      idOrg,
      idPlugin
    }
  })
}
