import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { Spacer, AppIcon } from '@toriihq/design-system'
import UsersList from './usersList'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { TABLES, ITEMS_PER_PAGE, CALCULATED_LICENSE_STATUS, SCOPES } from '../../../constants'
import { APP_USER_STATUS } from '@shared/types'
import config from '../../../config'
import { Wrapper, Name, Expenses } from './styles'
import Currency from '@root/components/currency'
import VisibleFor from '@root/components/visibleFor'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'

const CSS = {
  popup: {
    modal: {
      width: '80%',
      display: 'flex',
      flexDirection: 'column'
    }
  }
}

const DEFAULT_FIELDS_TO_FETCH = ['firstName', 'lastName', 'idUser', 'photoUrl', 'license', 'lastUsageTime', 'email', 'originEmail', 'status', 'userDetails', 'appAccountName', 'pricePerUser', 'pricePerUserConverted', 'currency', 'originIdUser']

class InactiveUsersPopup extends React.Component {
  state = { sort: this.props.defaultSort }
  dynamicFields = []

  componentDidMount () {
    const { getAppUsersFieldValues, idOrg, idApp, tableInfo = {} } = this.props
    if (tableInfo.columnsConfiguration) {
      this.updateSelection(tableInfo.columnsConfiguration)
    }
    this.fetchData(true)
    getAppUsersFieldValues({ idOrg, idApp, status: APP_USER_STATUS.ACTIVE, fields: ['role', 'externalStatus', 'licenses.name', 'appAccountName', 'idAppAccount'] })
  }

  fetchData = debounce((reset = false) => {
    const { licenses } = this.props

    if (reset) {
      this.fetchFields()
    }

    this.fetchUsers({ offset: reset ? 0 : licenses.length, reset })
  }, 500, { leading: true, trailing: false })

  sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  fetchUsers ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false }) {
    const { getAppInactiveLicenses, idOrg, idApp, idLicense, shouldFilterPaidLicenses } = this.props
    const { sort } = this.state

    getAppInactiveLicenses({ idOrg, idApp, idLicense, isLicenseTypePaid: Boolean(shouldFilterPaidLicenses), fields: DEFAULT_FIELDS_TO_FETCH.concat(this.dynamicFields), limit, offset, sort: this.sortStringFromArray(sort), q: this.query, reset })
  }

  fetchFields () {
    const { getUserDetailsFields, idOrg } = this.props
    getUserDetailsFields({ idOrg })
  }

  componentDidUpdate (prevProps) {
    const columnsConfigurationWasChanged = (
      this.props.tableInfo &&
      (!isEqual(this.props.tableInfo.columnsConfiguration, prevProps.tableInfo.columnsConfiguration) ||
      !isEqual(this.props.tableInfo.dynamicColumnsInfo, prevProps.tableInfo.dynamicColumnsInfo))
    )

    if (columnsConfigurationWasChanged) {
      this.updateSelection(this.props.tableInfo.columnsConfiguration)
      this.fetchUsers({ reset: true })
    }
  }

  updateSelection (columnsConfiguration) {
    const selectedFields = Object.keys(columnsConfiguration)
    this.dynamicFields = selectedFields.filter(field => !Object.keys(TABLES.usersTable.preDefinedColumnsMapping).includes(field))
  }

  onSearch = debounce((q) => {
    this.query = q
    this.fetchData(true)
  }, 250)

  onSortedChange = (sort) => {
    this.setState({ sort }, () => this.fetchData(true))
  }

  exportToCsv = ({ sort, query }) => {
    const { idOrg, idApp, idLicense } = this.props

    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=license,appAccountName,firstName,lastName,originEmail,lastUsageTime${this.dynamicFields.length > 0 ? `,${this.dynamicFields.join(',')}` : ''}`
    const params = `calculatedStatus=${CALCULATED_LICENSE_STATUS.INACTIVE}${idLicense ? `&idLicense=${idLicense}` : ''}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/${idApp}/licenses/csv?${sortParam}&${queryParam}&${params}&${fieldsParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  renderHeader () {
    const { app, savingsConverted } = this.props
    const expensesMsg = savingsConverted > 0 ? <Expenses>Estimated savings: <Currency value={savingsConverted} /></Expenses> : <Expenses>Enter license cost to view estimated savings</Expenses>
    return (<Wrapper>
      <Spacer right={'space-150'}>
        <AppIcon size='large' appName={app.name} appImageUrl={app.imageUrl} />
      </Spacer>
      <div>
        <Name>{app.name}</Name>
        <VisibleFor scopes={[SCOPES.LICENSE_AND_CHARGEBACK_READ, getScopeByIdOrgAndIdApp(SCOPES.LICENSE_AND_CHARGEBACK_READ, this.props.idOrg, this.props.idApp)]}>
          {expensesMsg}
        </VisibleFor>
      </div>
    </Wrapper>)
  }

  render () {
    const { cancel, isOpen, idApp, idOrg, licenses, loading, appNotInUsePeriod, tableInfo, configurableColumnsOptions, usersById, inactiveAmount, loadingMore, totalLicensesWithFilter } = this.props

    return (
      <ToriiPopup
        isOpen={isOpen}
        onCloseAction={cancel}
        styles={CSS.popup}
      >
        <ToriiPopup.Header header={this.renderHeader()} overrideStyle={{ padding: 30 }} />
        <ToriiPopup.Content>
          <UsersList
            exportFunction={this.exportToCsv}
            totalUsers={inactiveAmount}
            licenses={licenses}
            loading={loading}
            loadingMore={loadingMore}
            appNotInUsePeriod={appNotInUsePeriod}
            tableInfo={tableInfo}
            configurableColumnsOptions={configurableColumnsOptions}
            usersByid={usersById}
            onSearch={this.onSearch}
            fetchData={this.fetchData}
            onSortedChangeCB={this.onSortedChange}
            totalCount={totalLicensesWithFilter}
            idApp={idApp}
            idOrg={idOrg}
            scrollObjectId='contentArea'
          />
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }
}

InactiveUsersPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  loading: PropTypes.bool
}

export default InactiveUsersPopup
