import React, { useState } from 'react'
import get from 'lodash/get'
import BackAndForwardNavigator from '@components/BackAndForwardNavigator'

type Option = {
  linkSuffix: string | number,
  label: string
}

type Props = {
  label: string,
  options: Option[],
  optionIndex: number,
  linkPrefix: string,
  analyticsEvent: string
  searchParams?: string
}

const BackAndForwardNavigatorByOptionsArray = ({ label, options, optionIndex, linkPrefix, analyticsEvent, searchParams }: Props): JSX.Element => {
  const [selectedIndex, setIndex] = useState(optionIndex)

  const backIndex = selectedIndex - 1
  const nextIndex = selectedIndex + 1
  const backLink = get(options, [backIndex, 'linkSuffix'])
  const nextLink = get(options, [nextIndex, 'linkSuffix'])

  const onGoBack = (): void => {
    setIndex(selectedIndex - 1)
  }

  const onGoNext = (): void => {
    setIndex(selectedIndex + 1)
  }

  return <BackAndForwardNavigator
    onGoNext={onGoNext}
    onGoBack={onGoBack}
    searchParams={searchParams}
    analyticsEvent={analyticsEvent}
    label={`${label}${get(options, [selectedIndex, 'label'])}`}
    linkPrefix={linkPrefix}
    backLink={backLink}
    nextLink={nextLink}
  />
}

export default BackAndForwardNavigatorByOptionsArray
