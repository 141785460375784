import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  width: 200px;
  height: 32px;
`

export const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font: ${({ theme }) => theme.typography.font.subtitle02};
  cursor: default;
`
export const TagsGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
`
