import React, { useMemo } from 'react'
import * as Style from './style'
import { useHistory, useLocation } from 'react-router-dom'
import { generateTextForType } from '../generateTextForType'
import { css } from 'glamor'
import Placeholder from '@root/components/placeholder/view'
import { onClickAnalytics } from './analytics'
import { AppIcon, Icon, Tooltip } from '@toriihq/design-system'
import CurrencyOld, { CurrencyOldFormat } from '@components/currencyOld'
import CurrencySymbol from '@root/components/currencySymbol'
import { SCOPES } from '@root/constants'
import EnableFor from '@root/components/enableFor'
import VisibleFor from '@root/components/visibleFor'
import RelativeTeamLink from '../../relativeTeamLink'
import Currency from '@root/components/currency'
import { FEATURES } from '@root/shared/features'
import ButtonOfFeature from '@components/buttonOfFeature'

const LicenseRecommendation = (props) => {
  const { recommendation = {}, app, idOrg, showTakeActionButton, clickable, loading, fromPage } = props
  const { numberOfUsers = 0, costSavingPerLicenseConverted = 0, appAccountName, recommendedLicense, downgradeToLicense } = recommendation
  const { isPaid, pricePerUserConverted = 0 } = recommendedLicense || {}
  const { pricePerUserConverted: downgradeToLicenseCostConverted = 0 } = downgradeToLicense || {}

  const history = useHistory()
  const location = useLocation()

  const { headerText, insightText, recommendedActionText } = generateTextForType(recommendation, app ? app.name : '')

  const onExploreClick = () => {
    const pathname = location.pathname
    const destPath = pathname.includes('app') ? `/team/${idOrg}/app/${recommendation.idApp}/recommendation/${recommendation.id}` : `/team/${idOrg}/licenses/recommendation/${recommendation.id}`
    history.push({
      pathname: destPath,
      search: location.search
    })
  }

  const onCardClick = () => {
    onExploreClick()
    onClickAnalytics('Click on recommendation-card', fromPage, recommendation.type, app.name)
  }

  const onButtonClick = (e) => {
    e.stopPropagation()
    onExploreClick()
    onClickAnalytics('Click on take-action-recommendation', fromPage, recommendation.type, app.name)
  }

  const expectedImpactTooltipText = () => {
    if (isPaid) {
      if (costSavingPerLicenseConverted && downgradeToLicense) {
        const licenseCostComponent = <Currency value={pricePerUserConverted} shouldRoundValue format={CurrencyOldFormat.FLOAT} />
        const downgradeLicenseCostComponent = <Currency value={downgradeToLicenseCostConverted} shouldRoundValue format={CurrencyOldFormat.FLOAT} />

        return <div {...Style.TooltipLabel}>
          <span>Torii calculates your estimated annual savings based on the difference between the annual cost of your current {app.name} license ({ licenseCostComponent }) and a {downgradeToLicense.name} license ({ downgradeLicenseCostComponent }). You can edit these costs on the Licenses page.</span>
        </div>
      } else if (costSavingPerLicenseConverted && !downgradeToLicense) {
        const costSavingPerLicenseComponent = <Currency value={costSavingPerLicenseConverted} shouldRoundValue format={CurrencyOldFormat.FLOAT} />

        return <div {...Style.TooltipLabel}>
          <span>Estimated annual savings is calculated based on your annual license cost ({ costSavingPerLicenseComponent }) You can edit this value in the Licenses {'>'} Current page.</span>
        </div>
      } else {
        return <div {...Style.TooltipLabel}>
          <span>To see your estimated annual savings, enter your annual costs for all your {app.name} licenses on the Licenses page.</span>
        </div>
      }
    } else {
      return <div {...Style.TooltipLabel}>
        <span>Reclaim past users licenses to keep your organization secure</span>
      </div>
    }
  }

  const savingText = () => {
    if (isPaid) {
      return (<>{annualSavingValue === 0 ? <CurrencySymbol />
        : <>
          <CurrencyOld
            value={annualSavingValue}
            format={CurrencyOldFormat.FLOAT}
          />
        </>} Annual savings</>)
    } else {
      return (<> Stay Secure </>)
    }
  }

  const annualSavingValue = useMemo(() => Math.ceil(numberOfUsers * costSavingPerLicenseConverted / 100), [numberOfUsers, costSavingPerLicenseConverted])

  return (
    <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin>
      <div {...css(Style.Container, (clickable && !loading) ? Style.ClickableContainer : {})} onClick={(clickable && !loading) ? onCardClick : undefined}>
        <div {...Style.ImageContainer}>
          {app && <Placeholder loading={loading} type={'rect'} rows={1} style={{ width: '40px', height: '40px' }}>
            <RelativeTeamLink to={`/app/${app.id}`} onClick={((e) => e.stopPropagation())}> <AppIcon size='large' appImageUrl={app.imageUrl} hoverable /> </RelativeTeamLink>
          </Placeholder>}
        </div>
        <div {...Style.DataContainer}>
          <div {...Style.Header}>
            <Placeholder rows={2} loading={loading}>
              <div>
                <div {...Style.HeaderText}>
                  <div>{headerText}</div>
                </div>
                <div {...Style.AccountText}>Account: {appAccountName}</div>
              </div>
            </Placeholder>
            {showTakeActionButton && <EnableFor scopes={[SCOPES.USERS_READ]}><ButtonOfFeature feature={FEATURES.WORKFLOWS.PAGE} onClick={onButtonClick} label='Take Action' /></EnableFor>}
          </div>
          <div {...Style.LineSeparator} />
          <div {...Style.RecommendationBody}>
            <div {...Style.Info}>
              <Placeholder loading={loading} rows={2} >
                <div {...Style.InfoTextContainer}>
                  <div {...Style.SubTitle}>Insight</div>
                  <div {...Style.InfoText}>{insightText}</div>
                </div>
              </Placeholder>
              <Placeholder loading={loading} rows={2}>
                <div {...Style.InfoTextContainer}>
                  <div {...Style.SubTitle}>Recommended action</div>
                  <div {...Style.InfoText}>{recommendedActionText}</div>
                </div>
              </Placeholder>
            </div>
            <Placeholder loading={loading} rows={2} style={{ width: '100px' }}>
              <VisibleFor scopes={[SCOPES.LICENSE_AND_CHARGEBACK_READ]}>
                <div {...Style.SavingsContainer}>
                  <div {...Style.SubTitle}>Expected impact</div>
                  {app && <Tooltip
                    label={expectedImpactTooltipText()}
                    maxWidth={350}
                  >
                    <div {...css(Style.SavingTextContainer, (!isPaid || (numberOfUsers && costSavingPerLicenseConverted)) ? {} : Style.SavingTextContainerNoLicenseCost)}>
                      <Icon name={isPaid ? 'Wallet' : 'Security'} color='inherit' />
                      <div {...Style.SavingText}>
                        {savingText()}
                      </div>
                    </div>
                  </Tooltip>}
                </div>
              </VisibleFor>
            </Placeholder>
          </div>
        </div>
      </div>
    </EnableFor>
  )
}

export default LicenseRecommendation
