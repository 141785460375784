import styled from 'styled-components'

export namespace Styles {
  export const TextWithImageAside = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    & > :first-child {
      flex-grow: 1;
    }
  `

  export const MultilineText = styled.div<{ insertEmptyLineBetween?: boolean }>`
    display: flex;
    flex-direction: column;
    ${({ insertEmptyLineBetween }) => insertEmptyLineBetween ? 'gap: 20px;' : ''}
  `

  export const RegularText = styled.div`
    font: ${({ theme }) => theme.typography.font.body02};
  `

  export const BoldText = styled.div`
    font: ${({ theme }) => theme.typography.font.subtitle02};
  `

  export const SideImage = styled.img``

  export const SelectLabel = styled.div`
    font: ${({ theme }) => theme.typography.font.subtitle02};
  `

  export const Select = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `

  export const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `

  export const UsersCountLabel = styled.div`
    font: ${({ theme }) => theme.typography.font.component02};
  `

  export const LoadingLabel = styled(UsersCountLabel)`
    display: flex;
    gap: 10px;
    align-items: center;
  `

  export const LoadingLabelImage = styled.img`
    height: 16px;
    width: 16px;
  `

  export const RequestSent = styled.div`
  color: ${({ theme }) => theme.palette.text.success};
  font: ${({ theme }) => theme.typography.font.subtitle02};
  display: inline-flex;
  padding: 6px 12px 6px 0px;
  gap: 4px;
`
}
