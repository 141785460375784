import React from 'react'
import Table from '../table'
import emptyImage from './images/calculator.svg'
import PropTypes from 'prop-types'
import LicenseCell from './licenseCell'
import isNil from 'lodash/isNil'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import uniqBy from 'lodash/uniqBy'
import isUndefined from 'lodash/isUndefined'
import flattenDeep from 'lodash/flattenDeep'
import { CHARGEBACK_ALLOCATION_COST, CHARGEBACK_ALLOCATION_STRATEGY, SCOPES } from '../../constants'
import { exportCSV } from '@helpers/exportCSV'
import moment from 'moment'
import { Button, ButtonType, ButtonSize, AlertBox, AlertBoxType, EmptyState } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'

const tdStyle = {
  style: {
    padding: '5px 20px'
  }
}
const setTdStyle = () => tdStyle
const headerStyle = { justifyContent: 'flex-end' }

class AppChargeback extends React.Component {
  state = {
    displayBanner: false
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    const { idOrg } = this.props
    if (idOrg !== prevProps.idOrg) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const { idOrg, getAppChargeback, idApp } = this.props
    idOrg && getAppChargeback({ idApp, idOrg })
  }

  openConfigureAppChargeback = () => {
    const { toggleConfigureAppChargeback, idApp } = this.props
    const callback = () => this.setState({ displayBanner: true })
    toggleConfigureAppChargeback({ isConfigureAppChargebackOpen: true, idApp, callback })
  }

  renderEmptyState = () => {
    return <EmptyState
      image={<img src={emptyImage} alt='Chargeback is not configured for this application' />}
      title='Chargeback not configured yet'
      description='Define your preferred method for allocating costs associated with this app.'
      buttons={[
        <EnableFor scopes={[SCOPES.LICENSE_AND_CHARGEBACK_WRITE]} allowForToriiAdmin>
          <Button key='configureChargeback' type={ButtonType.primary} onClick={this.openConfigureAppChargeback} label='Configure chargeback' />
        </EnableFor>
      ]}
    />
  }

  getProcessedData = data => {
    if (data.length && data[0].allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.bottomUp && data[0].allocationCost === CHARGEBACK_ALLOCATION_COST.license) {
      data = groupBy(data, 'userFieldDisplayName')
      data = reduce(data, (result, value) => {
        result.push({ ...value[0], licenses: value })
        return result
      }, [])
    }

    return data
  }

  getLicensesConfig = data => {
    return uniqBy(data, 'licenseType')
  }

  getColumns = () => {
    const { data, loading, currency } = this.props

    const processedData = this.getProcessedData(data)

    const config = data[0] || {}
    const isBottomUp = config.allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.bottomUp

    const licensesConfig = (processedData.length && processedData[0].licenses && this.getLicensesConfig(data)) || []

    return [
      {
        Header: config.allocateCostByUserFieldLabel,
        id: 'userFieldDisplayName',
        accessor: 'userFieldDisplayName',
        width: 180,
        fixed: 'left'
      },
      loading ? { id: 'loadingColumn', width: 250 } : null,
      ...licensesConfig.filter(l => l.licenseCost !== null).map((license, index) => ({
        Header: license.licenseDisplayName,
        id: `license_${license.licenseType || index}`,
        width: 250,
        accessor: ({ licenses }) => (licenses || []).find(l => license.licenseType === l.licenseType),
        Cell: ({ value: license }) => {
          if (!license) {
            return '-'
          }
          const totalCost = license.licenseTotalCost
          const count = license.licenseCount
          const costPerLicense = license.licenseCost
          return <LicenseCell hoverClass='rt-tr' totalUserFieldCost={totalCost} count={count} costPerLicense={costPerLicense} isBottomUp />
        },
        headerStyle,
        style: { textAlign: 'right' },
        sortMethod: (a = {}, b = {}) => a.totalCost - b.totalCost,
        textValue: ({ value }) => isNil(value && value.licenseTotalCost) ? '-' : `${currency} ${value.licenseTotalCost}`
      })),
      {
        id: 'userFieldTotalCost',
        accessor: ({ userFieldTotalCost, totalCost, percentage }) => (isBottomUp ? userFieldTotalCost : totalCost * percentage * 0.01),
        Header: loading ? '' : 'Total cost',
        textHeader: `Total cost (${currency})`,
        headerStyle,
        Cell: ({ value, row: { userFieldTotalCount, percentage, totalCost, costPerUser } }) => {
          return <LicenseCell
            hoverClass='rt-tr'
            costPerUser={isUndefined(costPerUser) ? undefined : costPerUser}
            count={userFieldTotalCount}
            totalUserFieldCost={value}
            total={isUndefined(totalCost) ? undefined : totalCost}
            totalColumn
            isBottomUp={isBottomUp}
            percentage={percentage}
          />
        },
        maxWidth: 200
      },
      {
        accessor: 'totalCost',
        show: false
      },
      {
        accessor: 'userFieldTotalCount',
        show: false
      },
      {
        accessor: 'costPerUser',
        show: false
      },
      {
        accessor: 'percentage',
        show: false
      }
    ].filter(Boolean)
  }

  getTableHeader = () => {
    const { data } = this.props
    if (!data.length) {
      return ''
    }
    const config = data[0]
    const isBottomUp = config.allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.bottomUp
    const prefix = isBottomUp ? 'BOTTOM UP ALLOCATION - COST PER ' : 'TOP DOWN ALLOCATION'
    const suffix = isBottomUp ? config.allocationCost === CHARGEBACK_ALLOCATION_COST.license ? 'LICENSE' : 'USER' : ''
    return `${prefix}${suffix}`
  }

  exportToCsv = async () => {
    const { data, currency } = this.props
    let dataForCSV = []
    let columnNames = []
    const config = data[0] || {}
    const totalHeader = `Total cost (${currency})`

    const processedData = this.getProcessedData(data)
    if (config.allocationCost === CHARGEBACK_ALLOCATION_COST.license) {
      const uniqueLicenses = uniqBy(data, 'licenseType')
      columnNames = flattenDeep([
        config.allocateCostByUserFieldLabel,
        uniqueLicenses.map(license => [
          `${license.licenseDisplayName} - number of licenses`,
          `${license.licenseDisplayName} - cost per license (${currency})`,
          `${license.licenseDisplayName} - total cost (${currency})`
        ]),
        totalHeader
      ])

      dataForCSV = flattenDeep(processedData.map(row => {
        const licensesData = row.licenses.reduce((result, license) => {
          if (!result.length) {
            result.push({ [config.allocateCostByUserFieldLabel]: license.userFieldDisplayName, [totalHeader]: license.userFieldTotalCost / 100 })
          }
          result[0] = {
            ...result[0],
            [`${license.licenseDisplayName} - number of licenses`]: license.licenseCount,
            [`${license.licenseDisplayName} - cost per license (${currency})`]: license.licenseCost / 100,
            [`${license.licenseDisplayName} - total cost (${currency})`]: license.licenseTotalCost / 100
          }
          return result
        }, [])
        return licensesData
      }))
    } else if (config.allocationCost === CHARGEBACK_ALLOCATION_COST.user) {
      columnNames = [
        config.allocateCostByUserFieldLabel,
        'Number of users',
        `Cost per user (${currency})`,
        totalHeader
      ]

      dataForCSV = processedData.map(license => {
        return {
          [config.allocateCostByUserFieldLabel]: license.userFieldDisplayName,
          'Number of users': license.userFieldTotalCount,
          [`Cost per user (${currency})`]: license.costPerUser / 100,
          [totalHeader]: license.userFieldTotalCost / 100
        }
      })
    } else {
      columnNames = [
        config.allocateCostByUserFieldLabel,
        'Percentage (%)',
        `Cost per unit (${currency})`,
        totalHeader
      ]

      dataForCSV = processedData.map(license => {
        return {
          [config.allocateCostByUserFieldLabel]: license.userFieldDisplayName,
          'Percentage (%)': license.percentage,
          [`Cost per unit (${currency})`]: license.totalCost / 100,
          [totalHeader]: license.userFieldTotalCost / 100
        }
      })
    }

    exportCSV(`chargeback_${config.appName}_${moment().format('DD_MMMM_YYYY')}.csv`, dataForCSV, columnNames)
  }

  render () {
    const { loading, tableInfo, data } = this.props
    const { displayBanner } = this.state

    const processedData = this.getProcessedData(data)
    const licensesConfig = (processedData.length && processedData[0].licenses && this.getLicensesConfig(data)) || []
    const licensesWithNoPrice = licensesConfig.filter(l => l.licenseCost === null).length

    return (
      <div>
        {displayBanner && <AlertBox type={AlertBoxType.INFORMATIVE} description='Chargeback report data might take a minute to update' />}
        <Table
          tableKey={tableInfo.key}
          data={processedData}
          columns={this.getColumns()}
          loading={loading}
          header={this.getTableHeader()}
          getTdProps={setTdStyle}
          exportable
          exportFunction={this.exportToCsv}
          customButton={data && data.length ? {
            button: <Button size={ButtonSize.small} onClick={this.openConfigureAppChargeback} label='Reconfigure' />,
            scopes: [SCOPES.LICENSE_AND_CHARGEBACK_WRITE]
          } : null}
          emptyStateMessage={this.renderEmptyState()}
          clientPaging
          pageSize={50}
          alertMessage={!!licensesWithNoPrice && <div>Annual cost for {licensesWithNoPrice} licenses is not set and those licenses are not displayed. <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.openConfigureAppChargeback} label='Configure license cost' /></div>}
        />
      </div>
    )
  }
}

AppChargeback.propsTypes = {
  tableKey: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
}

AppChargeback.defaultProps = {
}

export default AppChargeback
