import React from 'react'
import { css } from 'glamor'
import colors from '../../../../shared/style/colors'
import NumberFormat from 'react-number-format'
import { fontSize, fontWeight } from '../../../../shared/style/sizes'
import isNil from 'lodash/isNil'
import { borderStyle } from '../../../form/formStyle'
import EnableFor from '../../../../components/enableFor'

const CSS = {
  rowNotHovered: css({
    backgroundColor: colors.transparent,
    borderColor: colors.transparent
  }),
  priceInput: css(borderStyle, {
    textAlign: 'right',
    fontSize: fontSize.small,
    marginRight: '-12px',
    height: '28px',
    paddingRight: '10px',
    backgroundColor: colors.white,
    paddingLeft: '10px',
    border: `1px solid ${colors.grey2}`,
    ':focus': {
      backgroundColor: colors.white
    },
    '::placeholder': {
      textAlign: 'left'
    }
  }),
  badge: css({
    borderRadius: '2px',
    height: '14px',
    padding: '1px 3px',
    color: colors.grey2,
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.bold,
    textTransform: 'uppercase',
    backgroundColor: colors.transparent,
    border: `1px solid ${colors.grey2}`
  })
}

export const CURRENCY_BADGE = css({
  borderRadius: '2px',
  height: '14px',
  padding: '1px 3px',
  color: colors.grey2,
  fontSize: fontSize.extraSmall,
  fontWeight: fontWeight.bold,
  textTransform: 'uppercase',
  backgroundColor: colors.transparent,
  border: `1px solid ${colors.grey2}`
})

const ENTER_KEY_CODE = 13

const formatValue = (value) => Math.round(parseFloat(value)) * 100

const hoverAndEditRenderer = ({ value, onChange, onBlur, currencySymbol, autoFocus, isRowHovered, placeholder, allowedScopes = [] }) => {
  const internalOnChange = ({ value }) => {
    const parsedValue = (value === '' ? null : formatValue(value))

    onChange(parsedValue)
  }
  const onKeyUp = (e) => {
    if (e.which === ENTER_KEY_CODE) {
      e.target.blur()
    }
  }
  const internalOnBlur = (e) => {
    const value = e.target.value.replace(/[^\d.]/g, '')
    const valueForOnBlur = value === '' ? null : formatValue(value)

    return onBlur(valueForOnBlur)
  }

  const isValueExplicitlyNullOrUndefined = isNil(value)
  const valueAsString = (isValueExplicitlyNullOrUndefined ? '' : value / 100).toString()
  const customCSS = isRowHovered ? {} : CSS.rowNotHovered

  return <EnableFor scopes={allowedScopes}>
    <NumberFormat
      {...css(CSS.priceInput, customCSS)}
      placeholder={placeholder}
      defaultValue={valueAsString}
      onValueChange={internalOnChange}
      onBlur={internalOnBlur}
      autoFocus={autoFocus}
      thousandSeparator
      prefix={`${currencySymbol}`}
      onKeyUp={onKeyUp} />
  </EnableFor>
}

const column =
  {
    type: 'currency',
    valueRenderer: (props) => {
      const { value } = props

      if (value === 0) {
        return <span {...CURRENCY_BADGE}>FREE</span>
      } else if (value === null) {
        return <span {...CURRENCY_BADGE}>UNKNOWN</span>
      }

      return (hoverAndEditRenderer({ ...props, autoFocus: false, isRowHovered: false }))
    },
    hoverRenderer: (props) => hoverAndEditRenderer({ ...props, autoFocus: false, isRowHovered: true }),
    editRenderer: (props) => hoverAndEditRenderer({ ...props, autoFocus: true, isRowHovered: false })

  }

export default column
