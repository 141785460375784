import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { AppIcon, Spacer, TextBadge } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { fontWeight } from '@shared/style/sizes'
import { oneLiner } from '@shared/style/mixins'
import Placeholder from '../placeholder'
import RelativeTeamLink from '../relativeTeamLink'
import Analytics from '../../helpers/analytics'
import EnableFor from '../enableFor'
import { SCOPES } from '@root/constants'

const insideTableRowsClass = '.rt-tr:hover &'
const containerHoverStyle = '.appDetailsContainer:hover &'
const containerStyle = css({
  padding: '2px 0'
})

const nameStyleHover = css({
  color: colors.blue,
  transform: 'translateY(0)'
})

const nameStyleHoverNoBlueOnHover = css({
  transform: 'translateY(0)'
})

const nameStyle = css(oneLiner, {
  color: colors.black,
  [insideTableRowsClass]: css({
    color: colors.blue
  })
})

const nameWithCategoryStyle = css(oneLiner, {
  color: colors.black,
  transform: 'translateY(9px)',
  transition: 'color .2s, transform.2s',
  [insideTableRowsClass]: nameStyleHover,
  [containerHoverStyle]: nameStyleHover
})

const nameStyleNoBlueOnHover = css(oneLiner, {
  color: colors.black
})

const nameWithCategoryStyleNoBlueOnHover = css(oneLiner, {
  color: colors.black,
  transform: 'translateY(9px)',
  transition: 'color .2s, transform.2s',
  [insideTableRowsClass]: nameStyleHoverNoBlueOnHover,
  [containerHoverStyle]: nameStyleHoverNoBlueOnHover
})

const categoryStyleHover = css({
  opacity: 1
})
const categoryStyle = css(oneLiner, {
  fontWeight: fontWeight.normal,
  opacity: 0,
  transition: 'opacity .2s',
  [insideTableRowsClass]: categoryStyleHover,
  [containerHoverStyle]: categoryStyleHover
})

const CSS = {
  name: nameStyle,
  nameWithCategory: nameWithCategoryStyle,
  nameNoBlueOnHover: nameStyleNoBlueOnHover,
  nameWithCategoryNoBlueOnHover: nameWithCategoryStyleNoBlueOnHover,
  category: categoryStyle,
  container: containerStyle,
  main: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px'
  }),
  content: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  }),
  details: css(texts.subheading, {
    flex: 1,
    overflow: 'hidden'
  }),
  nameAndIcon: css({
    width: '100%'
  })
}

const onNavToApp = (name, component) => {
  Analytics.track('Click on app', {
    Component: component,
    'App name': name
  })
}

class AppDetails extends React.Component {
  render () {
    const { id, name, category, imageUrl, isNew, badge, component, loading, overrideStyle, blueNameOnHover } = this.props

    const isNewBadge = isNew && <TextBadge color='blue' size='Small'>New</TextBadge>
    const badgeComponent = badge && <TextBadge color={badge.color} size='Small'>{badge.text}</TextBadge>
    const nameCSS = category ? blueNameOnHover ? CSS.nameWithCategory : CSS.nameWithCategoryNoBlueOnHover : blueNameOnHover ? CSS.name : CSS.nameNoBlueOnHover
    const singleAppPageNeededScopes = [SCOPES.APPLICATIONS_READ, SCOPES.APP_OWNER_READ]
    const content = (
      <div {...CSS.content}>
        <Spacer right={'space-150'}>
          <AppIcon appName={name} appImageUrl={imageUrl} />
        </Spacer>
        <div {...CSS.details}>
          <div {...css(nameCSS)}>{name} {isNewBadge} {badgeComponent}</div>
          {category && <div {...CSS.category}>{category}</div>}
        </div>
      </div>
    )

    return (
      <div className='appDetailsContainer' {...css(CSS.container, overrideStyle)}>
        <Placeholder loading={loading} type='text' rows={2} style={{ height: '40px', width: '50px' }}>
          <div {...CSS.main}>
            <EnableFor scopes={singleAppPageNeededScopes} allowForToriiAdmin>
              {id ? <RelativeTeamLink to={`/app/${id}`} className={CSS.nameAndIcon.toString()} onClick={() => onNavToApp(name, component)}>{content}</RelativeTeamLink> : content}
            </EnableFor>
          </div>
        </Placeholder>
      </div>
    )
  }
}

AppDetails.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  category: PropTypes.string,
  imageUrl: PropTypes.string,
  isNew: PropTypes.bool,
  badge: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string
  }),
  loading: PropTypes.bool,
  component: PropTypes.string,
  blueNameOnHover: PropTypes.bool
}

AppDetails.defaultProps = {
  blueNameOnHover: true
}

export default AppDetails
