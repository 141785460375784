import styled from 'styled-components'
import { ELEVATION_WEIGHT } from '@toriihq/design-system'
import { zIndex } from '@shared/style/sizes'

const DRAWER_WIDTH = '460px'

const drawerZIndex = zIndex.zendeskWidget + 1

export const DrawerContainer = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: ${({ theme }) => theme.elevation(ELEVATION_WEIGHT.SHADOW_500)};
  height: 100%;
  width: ${DRAWER_WIDTH};
  position: fixed;
  top: 0;
  z-index: ${drawerZIndex};
  transition: right 250ms ease-in-out;
  ${({ isOpen }) => isOpen
    ? 'right: 0;'
    : `right: -${DRAWER_WIDTH};`
}
`

export const DrawerContent = styled.div`
  padding: 16px 24px;
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;
`
