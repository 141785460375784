import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'

const CSS = {
  label: css({
    transition: 'top 0.2s ease-out, opacity 0.2s ease-out, left 0.2s',
    position: 'relative',
    pointerEvents: 'none'
  }),
  visible: css({
    opacity: 1,
    top: 0,
    left: 0
  }),
  hidden: css({
    opacity: 0,
    top: '1.5em',
    left: '2px'
  }),
  inputWrapper: css({
    position: 'relative'
  })
}

class FloatingInput extends React.Component {
  constructor (props) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      focus: false,
      empty: !this.props.value
    }
  }

  onFocus (e) {
    const { onFocus } = this.props
    this.setState({ focus: true })
    return onFocus && onFocus(e)
  }

  onBlur (e) {
    const { onBlur } = this.props
    this.setState({ focus: false })
    return onBlur && onBlur(e)
  }

  onChange (e) {
    const { onChange } = this.props
    this.setState({ empty: e.currentTarget.value === '' })
    return onChange && onChange(e)
  }

  render () {
    const { label, labelStyle, placeholder, refInput, children, ...rest } = this.props
    const { focus, empty } = this.state
    const shouldShowLabel = focus || !empty
    return (<div>
      <div>
        <label
          {...css(CSS.label,
            labelStyle,
            shouldShowLabel ? CSS.visible : CSS.hidden)}
        >
          {label}
        </label>
      </div>
      <div {...CSS.inputWrapper}>
        <input
          {...CSS.input}
          ref={refInput}
          {...rest}
          placeholder={focus ? placeholder : label}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
        />
        {children}
      </div>
    </div>)
  }
}

FloatingInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.element,
  labelStyle: PropTypes.object
}

FloatingInput.defaultProps = {
  children: null
}

export default FloatingInput
