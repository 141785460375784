import analytics from '@root/helpers/analytics'
import { SOURCE_TYPES_BY_ID_APP } from '@root/sourcesConfig'
import get from 'lodash/get'

type AnalyticsParams = {
    idOrg: number,
    idApp?: number,
    buttonLocation?: string
}

const Analytics: Record<string, (params: AnalyticsParams) => void> = {
  onApplyMerge: ({ idOrg, idApp }) => {
    analytics.track('Click on apply merge button', { idOrg, 'App name': get(SOURCE_TYPES_BY_ID_APP, ['idApp', 'name']) })
  },
  onBackClick: ({ idOrg }) => {
    analytics.track('Click on back button in merge users popup button', { idOrg })
  },
  onCancelClick: ({ idOrg }) => {
    analytics.track('Click on cancel button in merge users popup button', { idOrg })
  },
  onSendReportClick: ({ idOrg }) => {
    analytics.track('Click on send report button in merge users popup', { idOrg })
  },
  onLearnMoreClick: ({ idOrg, buttonLocation }) => {
    analytics.track('Click on learn more link merge users', { idOrg, 'Button location': buttonLocation })
  },
  onRevokeMergeClick: ({ idOrg, idApp }) => {
    analytics.track('Click on revoke merge button in merge users popup', { idOrg, 'App name': get(SOURCE_TYPES_BY_ID_APP, ['idApp', 'name']) })
  }
}

export default Analytics
