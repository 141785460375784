import PropTypes from 'prop-types'

export const sourceType = PropTypes.shape({
  source: PropTypes.shape({
    id: PropTypes.string,
    idApp: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.object,
    apps: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      sources: PropTypes.arrayOf(PropTypes.string).isRequired,
      url: PropTypes.string.isRequired
    }))
  })
})
