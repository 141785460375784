import { WORKFLOW_ACTION_TYPES } from '@root/constants'
import moment from 'moment'

const getLatestItem = (data, key) => {
  const sortedData = data.sort((e1, e2) => moment(e1[key]) < moment(e2[key]) ? 1 : -1)
  return sortedData[0]
}

export const getActionFromExecutions = executions => {
  if (executions && executions.length > 0) {
    const nonIgnoredExecutions = executions.filter(e => !e.isIgnored)
    const execution = getLatestItem(nonIgnoredExecutions, 'creationTime')

    if (execution && execution.actions.length > 0) {
      const nonIgnoredActions = execution.actions.filter(e => !e.isIgnored)
      return getLatestItem(nonIgnoredActions, 'creationTime')
    }
  }

  return null
}

export const getIgnoredExecution = (executions) => {
  const jiraIssuesExecutions = executions.filter(execution => execution?.actions[0]?.actionType === WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE)
  return getLatestItem(jiraIssuesExecutions, 'creationTime')
}
