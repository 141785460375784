import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize } from '../../../shared/style/sizes'

const CSS = {
  center: css({
    margin: 'auto',
    textAlign: 'center'
  }),
  header: css({
    color: colors.black,
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: '600',
    marginBottom: '10px'
  }),
  subHeader: css({
    fontSize: fontSize.small,
    fontWeight: '600',
    lineHeight: '18px',
    color: colors.grey2,
    textTransform: 'uppercase'
  })
}

const BoxHeader = ({ center, header, subHeader, overrideHeaderStyle }) => (
  <div {...css(center && CSS.center)}>
    <div {...css(CSS.header, overrideHeaderStyle)}>
      {header}
    </div>
    <div {...CSS.subHeader}>
      {subHeader}
    </div>
  </div>
)

BoxHeader.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.string,
  overrideHeaderStyle: PropTypes.object
}

export default BoxHeader
