import React from 'react'
import { Styles } from './style'
import { Col, Grid, Row, Spacer, Spacing } from '@toriihq/design-system'
import upgradeWorkflowImage from '@media/upgrade_workflow.svg'
import upgradeAppComparisonImage from '@media/upgrade_app_comparison.svg'
import upgradeOffboardingImage from '@media/upgrade_offboarding.svg'
import upgradeRolesImage from '@media/upgrade_roles.svg'
import upgradeChargebackImage from '@media/upgrade_chargeback.svg'
import upgradeAppOwnersImage from '@media/upgrade_app_owners.svg'
import upgradeCustomApplicationsImage from '@media/upgrade_custom_applications.svg'
import upgradeRecommendationsImage from '@media/upgrade_recommendations.svg'
import upgradeAppCatalogImage from '@media/upgrade_app_catalog.svg'
import upgradeSecretsVaultImage from '@media/upgrade_secrets_vault.svg'
import upgradeLicenseTrendImage from '@media/upgrade_license_trend.svg'
import upgradeRenewalsSummary from '@media/upgrade_renewals_summary.svg'
import upgradeContractDetailsImage from '@media/upgrade_contract_details.svg'
import upgradeAPIAccessImage from '@media/upgrade_api_access.svg'
import upgradeLabsImage from '@media/upgrade_labs.svg'
import upgradePluginsImage from '@media/upgrade_plugins.svg'
import { FEATURES } from '@shared/features'
import { withRouter } from 'react-router-dom'
import UpgradePlanButton from '@components/upgradePlanButton'

interface Props {
  feature: string,
  topSpace: Spacing,
  match: { params: { idOrg: string }}
}

const FEATURES_CONFIG = {
  workflows: {
    header: 'Workflows',
    upgradeImage: upgradeWorkflowImage,
    infoText: 'Orchestrate tailored workflows for your unique use case. Visualize every possible option with limitless branching for your multi-variant context. Complexity made elegant.'
  },
  roles: {
    header: 'Roles',
    upgradeImage: upgradeRolesImage,
    infoText: 'Define the information the user is exposed to and limit users\' actions in Torii\'s console. You can also customize the permissions for new and existing users in the Torii account.',
    shownOnSubpage: true
  },
  appComparison: {
    upgradeImage: upgradeAppComparisonImage,
    infoText: 'Discover similar apps used in your organization and explore their attributes and usage trends side-by-side. This powerful tool allows you to identify cost-saving and consolidation opportunities.'
  },
  offboarding: {
    header: 'Offboarding',
    upgradeImage: upgradeOffboardingImage,
    infoText: 'Streamline the employee offboarding process through advanced automation, efficient delegation, and task management.'
  },
  appCatalog: {
    header: 'App Catalog',
    upgradeImage: upgradeAppCatalogImage,
    infoText: 'Curate a comprehensive catalog of all your managed applications and share it seamlessly with your employees. This feature not only keeps everyone informed about the available applications but also facilitates easy access requests, ensuring that employees have the tools they need.'
  },
  plugins: {
    header: 'Plugins',
    upgradeImage: upgradePluginsImage,
    infoText: 'Extend Torii\'s functionality with our Plugin Marketplace. Browse a curated selection of extensions designed to amplify and personalize your experience. Make Torii truly yours and explore a world of added capabilities.'
  },
  chargeback: {
    upgradeImage: upgradeChargebackImage,
    infoText: 'IT Chargeback is a reliable system that tracks software expenses across all departments and business units, generates detailed reports outlining SaaS costs for each department, and equips departments with tools to manage and adhere to their tech budget.'
  },
  recommendations: {
    upgradeImage: upgradeRecommendationsImage,
    infoText: 'Get actionable recommendations that guide you to optimize license utilization with the License Recommendations feature. It suggests ways to downgrade underutilized licenses, reclaim unused licenses, and retrieve licenses from past users, helping you maximize your investments and curb unnecessary expenses.'
  },
  licenseTrend: {
    upgradeImage: upgradeLicenseTrendImage,
    infoText: 'Experience a visual insight into your software\'s performance as it displays the trend of license utilization over time. Track the ups and downs of your license usage, offering a clear picture of how your resources are being utilized, aiding in informed decision-making and future planning.'
  },
  renewalsSummary: {
    upgradeImage: upgradeRenewalsSummary,
    infoText: 'Gain valuable insights into your SaaS subscriptions with the Yearly Renewals Report. This feature helps you review past contract renewals, identify overall savings, and track rising app costs. With detailed data, you can highlight significant savings and present concrete dollar amounts to executives. Use this information to inform future decisions, ensuring optimal subscription management and cost optimization.'
  },
  appOwners: {
    upgradeImage: upgradeAppOwnersImage,
    infoText: 'Grant application owners the ability to manage their own applications directly in Torii. This feature ensures that those most familiar with an application can make informed decisions, streamline processes, and maintain optimal performance within the Torii platform.'
  },
  contractDetails: {
    header: 'Contract Details',
    upgradeImage: upgradeContractDetailsImage,
    infoText: 'Tailor how contracts are presented in Torii with the ability to create custom fields. This feature ensures that contract details align perfectly with your organization\'s specific needs and preferences.',
    shownOnSubpage: true
  },
  customApps: {
    header: 'Custom Applications',
    upgradeImage: upgradeCustomApplicationsImage,
    infoText: 'Add custom applications beyond what\'s automatically discovered. Manage specialized tools and platforms, ensuring a holistic view and control of all your software resources within Torii.',
    shownOnSubpage: true
  },
  secretsVault: {
    header: 'Secrets Vault',
    upgradeImage: upgradeSecretsVaultImage,
    infoText: 'Encrypt and store variables securely in Torii using the Secrets Vault. Designed for use in workflows as personalization tokens.',
    shownOnSubpage: true
  },
  apiAccess: {
    header: 'API Access',
    upgradeImage: upgradeAPIAccessImage,
    infoText: 'Harness the full capabilities of Torii with API Access. Facilitate seamless connections with other systems, tools, and platforms. Utilize the API to further extend and amplify the functionalities of Torii, ensuring a tailored experience that aligns with your unique operational needs.',
    shownOnSubpage: true
  },
  labs: {
    header: 'Labs',
    upgradeImage: upgradeLabsImage,
    infoText: 'Activate Labs within Torii to get a sneak peek at cutting-edge, experimental features.',
    shownOnSubpage: true
  }
}

export const FEATURE_TO_UPGRADE_CONFIG = {
  [FEATURES.WORKFLOWS.PAGE]: 'workflows',
  [FEATURES.OFFBOARDING.PAGE]: 'offboarding',
  [FEATURES.APP_CATALOG.PAGE]: 'appCatalog',
  [FEATURES.PLUGIN_MARKETPLACE.PAGE]: 'plugins',
  [FEATURES.SETTINGS.TABS.ROLES.PAGE]: 'roles',
  [FEATURES.SETTINGS.TABS.CUSTOM_APPS]: 'customApps',
  [FEATURES.SETTINGS.TABS.SECRETS_VAULT]: 'secretsVault',
  [FEATURES.SETTINGS.TABS.API_ACCESS]: 'apiAccess',
  [FEATURES.SETTINGS.TABS.CONTRACT_DETAILS]: 'contractDetails',
  [FEATURES.SETTINGS.TABS.MEMBERS.TABS.APP_OWNERS]: 'appOwners',
  [FEATURES.SETTINGS.TABS.LABS]: 'labs',
  [FEATURES.APPLICATIONS.TABS.COMPARE]: 'appComparison',
  [FEATURES.LICENSES.TABS.RECOMMENDATIONS]: 'recommendations',
  [FEATURES.APPLICATION.TABS.RECOMMENDATIONS]: 'recommendations',
  [FEATURES.LICENSES.TABS.TREND]: 'licenseTrend',
  [FEATURES.RENEWALS.TABS.RENEWALS_SUMMARY]: 'renewalsSummary',
  [FEATURES.LICENSES.TABS.CHARGEBACK]: 'chargeback',
  [FEATURES.APPLICATION.TABS.CHARGEBACK]: 'chargeback'
}

const SuggestToUpgrade = ({
  feature,
  topSpace = 'space-0',
  match
}: Props): JSX.Element => {
  const config = FEATURES_CONFIG[FEATURE_TO_UPGRADE_CONFIG[feature]]

  if (!config) {
    console.error(`No upgrade config found for feature ${feature}`)
    return <></>
  }

  return (
    <Spacer top={topSpace}>
      <Grid>
        <Row>
          <Col sm={6}>
            <Styles.LeftSideWrapper>
              <Spacer right={'space-100'}>
                <Styles.HeaderText shownOnSubpage={config.shownOnSubpage}>
                  {config.header}
                </Styles.HeaderText>
              </Spacer>
              <Spacer top={config.shownOnSubpage ? 'space-100' : 'space-200'}>
                <Styles.InfoText shownOnSubpage={config.shownOnSubpage}>
                  {config.infoText}
                </Styles.InfoText>
              </Spacer>
              <Spacer top={'space-200'}>
                <UpgradePlanButton feature={feature} />
              </Spacer>
            </Styles.LeftSideWrapper>
          </Col>
          <Col sm={4} >
            <img height='488px' width='495px' src={config.upgradeImage} alt='feature description' />
          </Col>
        </Row>
      </Grid>
    </Spacer>
  )
}

export default withRouter(SuggestToUpgrade)
