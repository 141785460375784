import { css } from 'glamor'
import colors from '@shared/style/colors'

export const Main = css({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: 'repeat(2, 45%) 25px',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: colors.transparent,
  padding: 0,
  alignItems: 'center',
  boxSizing: 'border-box',
  marginBottom: '20px'
})

export const Action = css({
  fontSize: '16px'
})

export const Button = css({
  backgroundColor: 'transparent',
  padding: 0,
  margin: '0 15px 0 0',
  color: colors.blue,
  ':last-child': {
    marginRight: 0
  }
})
