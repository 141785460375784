import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Table from '../table'
import RelativeTeamLink from '../relativeTeamLink'
import UserDetails from '../userDetails'
import { css } from 'glamor'
import { TABLES, SCOPES } from '../../constants'
import EnableFor from '../enableFor'
import pluralize from 'pluralize'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import { hoverActions } from '../../shared/style/mixins'
import { Button, ButtonType, ButtonSize, Link } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'

const CSS = {
  hoverActions: css(hoverActions, {
    textAlign: 'right'
  }),
  confirmationText: css({
    padding: '10px 0',
    fontSize: '16px',
    lineHeight: 1.5
  })
}

class UploadsTable extends React.Component {
  state = { isDeletePopupOpen: false, fileForDeletion: null }

  constructor (props) {
    super(props)
    this.columns = this.getColumns()
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData()
    }
  }

  fetchData () {
    const { getUploads, idOrg } = this.props
    getUploads({ idOrg })
  }

  getColumns = () => {
    return [
      {
        Header: 'File',
        accessor: 'fileName',
        Cell: ({ value: fileName, row: { id } }) => (
          <RelativeTeamLink to={`/expenses/uploads/${id}`}><Link>{fileName}</Link></RelativeTeamLink>
        )
      },
      {
        Header: 'Upload date',
        accessor: 'creationTime',
        Cell: ({ value: creationTime }) => moment(creationTime).format('MMM DD, YYYY')
      },
      {
        Header: 'Uploaded by',
        accessor: 'idUser',
        Cell: ({ value: idUser }) => {
          const { usersById } = this.props
          const { id, firstName, lastName, email, isExternal, photoUrl, isSupport } = usersById[idUser] || {}
          return (
            <UserDetails
              idUser={id}
              firstName={firstName}
              lastName={lastName}
              email={email}
              isExternal={isExternal}
              photoUrl={photoUrl}
              isSupportTeamUser={isSupport}
            />
          )
        }
      },
      {
        Header: 'Matched transactions',
        accessor: 'mapped'
      },
      {
        Header: 'Total Transactions',
        accessor: 'transactions'
      },
      {
        id: 'actions',
        Cell: ({ row }) => (
          <div {...CSS.hoverActions}>
            <EnableFor scopes={[SCOPES.EXPENSE_WRITE]}>
              <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={() => this.toggleIsDeletePopupOpen({ fileForDeletion: row })} icon='Trash' />
            </EnableFor>
          </div>
        ),
        sortable: false
      },
      {
        accessor: 'id',
        show: false
      }
    ]
  }

  toggleIsDeletePopupOpen = ({ fileForDeletion } = {}) => {
    const update = omitBy({
      isDeletePopupOpen: !this.state.isDeletePopupOpen,
      fileForDeletion
    }, isUndefined)

    this.setState(update)
  }

  onDelete = async () => {
    const { deleteUpload, idOrg } = this.props
    const { fileForDeletion } = this.state
    const { id: idUpload } = fileForDeletion

    await deleteUpload({ idOrg, idUpload })

    setTimeout(this.toggleIsDeletePopupOpen, 1000)
  }

  renderDeleteConfirmation = () => {
    const { isDeletePopupOpen, fileForDeletion } = this.state
    const { fileName = '', transactions = 0 } = fileForDeletion || {}
    const transactionsText = transactions > 0 ? ` and ${pluralize('transaction', transactions, true)}` : ''

    const text = <div {...CSS.confirmationText}>Are you sure you want to delete <strong>{fileName}</strong>{transactionsText}?</div>

    return (
      <Confirmation
        isOpen={isDeletePopupOpen}
        header={`Delete expenses report?`}
        text={text}
        confirmText={`Delete file${transactionsText}`}
        declineText='Cancel'
        decline={this.toggleIsDeletePopupOpen}
        confirm={this.onDelete}
        close={this.toggleIsDeletePopupOpen}
        mainButtonType={ButtonType.destructive}
        modalWidth='450px'
      />
    )
  }

  render () {
    const { uploads, loading, defaultSort } = this.props

    return (
      <>
        {this.renderDeleteConfirmation()}

        <Table
          tableKey={TABLES.uploadsTable.key}
          data={uploads}
          header={({ data }) => data.length > 0 ? `Uploads (${data.length})` : 'Uploads'}
          columns={this.columns}
          loading={loading}
          defaultSort={defaultSort}
          emptyStateMessage='No expenses uploaded yet'
          clientPaging
          pageSize={30}
        />
      </>
    )
  }
}

UploadsTable.propTypes = {
  uploads: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  defaultSort: PropTypes.array
}

UploadsTable.defaultProps = {
  defaultSort: [{ id: 'creationTime', desc: true }]
}

export default UploadsTable
