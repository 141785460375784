import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NotFound from '../notFound'
class TeamFallbackRoute extends React.Component {
  constructor (props) {
    super(props)
    this.redirectWhenIdOrgIsUnknown = this.redirectWhenIdOrgIsUnknown.bind(this)
    this.redirectToDefaultPath = this.redirectToDefaultPath.bind(this)
  }

  getIdOrgFromPath () {
    const { idOrg } = this.props.match.params
    const isValid = Number.isInteger(parseInt(idOrg, 10))
    return isValid ? idOrg : null
  }

  redirectToDefaultPath () {
    const idOrg = this.getIdOrgFromPath()
    const { isOnlyAppOwner } = this.props
    const defaultPath = isOnlyAppOwner ? 'applications' : 'insights'
    return <Redirect to={`/team/${idOrg}/${defaultPath}`} />
  }

  redirectWhenIdOrgIsUnknown () {
    const { me } = this.props
    const path = window.location.pathname
    const to = path.replace('/team', `/team/${me.idOrg}`)
    return <Redirect to={to} />
  }

  render () {
    const idOrg = this.getIdOrgFromPath()
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        {idOrg && <Route exact path='/team/:idOrg' render={this.redirectToDefaultPath} />}
        {!idOrg && <Route path='/team/:rest' render={this.redirectWhenIdOrgIsUnknown} />}
        <Route exact path='/team' render={this.redirectWhenIdOrgIsUnknown} />
        <Route component={NotFound} />
      </div>
    )
  }
}

export default TeamFallbackRoute
