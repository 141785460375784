import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import moment from 'moment'
import config from '../../../config'
import { getDisplayName } from '../../../lenses/users'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { Link } from '@toriihq/design-system'

const CSS = {
  header: css({
    display: 'flex',
    fontSize: fontSize.small,
    fontWeight: fontWeight.normal,
    color: colors.text,
    ' > div:not(:last-child)': {
      ':after': {
        content: `''`,
        display: 'inline-block',
        width: '1px',
        height: '10px',
        backgroundColor: colors.border,
        margin: '0 20px'
      }
    }
  }),
  headerValue: css({
    color: colors.darkText
  })
}

class ExpensesActivityHeader extends React.PureComponent {
  render () {
    const { fileName = '', creationTime, idOrg, idUpload, idUser, usersById } = this.props

    const decodedFileName = decodeURIComponent(fileName)
    const filePath = `${config.apiBaseUrl}/api/uploads/${idOrg}/${idUpload}/${fileName}`

    return (
      <div {...CSS.header}>
        <div>
          Expenses from uploaded file: <Link href={filePath} target='_blank'>{decodedFileName}</Link>
        </div>
        <div>
          Uploaded by: <RelativeTeamUserLink idUser={idUser}><Link>{getDisplayName(usersById[idUser] || {})}</Link></RelativeTeamUserLink>
        </div>
        <div>
          Uploaded on: <span {...CSS.headerValue}>{moment(creationTime).format('MMM DD, YYYY')}</span>
        </div>
      </div>
    )
  }
}

ExpensesActivityHeader.propTypes = {
  fileName: PropTypes.string,
  creationTime: PropTypes.string,
  idOrg: PropTypes.number,
  idUpload: PropTypes.number,
  idUser: PropTypes.number,
  usersById: PropTypes.object
}

export default ExpensesActivityHeader
