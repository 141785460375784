import React from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import pick from 'lodash/pick'

const standardProps = ['className', 'style', 'onClick']
const linkPropTypes = {
  onClick: PropTypes.func,
  target: PropTypes.string,
  replace: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  search: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  supportDisabledLink: PropTypes.bool,
  className: PropTypes.string,
  state: PropTypes.object
}
const navLinkPropTypes = {
  to: linkPropTypes.to,
  supportDisabledLink: linkPropTypes.supportDisabledLink,
  className: linkPropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  location: PropTypes.object,
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  style: PropTypes.object,
  isActive: PropTypes.func,
  'aria-current': PropTypes.oneOf(['page', 'step', 'location', 'date', 'time', 'true'])
}

const linkAllowedProps = Object.keys(linkPropTypes).concat(standardProps)
const navLinkAllowedProps = Object.keys(navLinkPropTypes).concat(standardProps)

const RelativeTeamLink = ({ idOrg, to, search, children, nav, overrideStyle, disabled, supportDisabledLink, className, state, ...rest }) => {
  if (disabled) {
    return supportDisabledLink ? <button className={className} disabled={disabled}>{children}</button> : (children)
  }

  return (nav
    ? <NavLink {...overrideStyle} to={{
      pathname: `/team/${idOrg}${to}`,
      search,
      state: state
    }} className={className} {...pick(rest, navLinkAllowedProps)}>{children}</NavLink>
    : <Link {...overrideStyle} to={{
      pathname: `/team/${idOrg}${to}`,
      search,
      state: state
    }} className={className} {...pick(rest, linkAllowedProps)}>{children}</Link>
  )
}

RelativeTeamLink.propTypes = {
  ...linkPropTypes,
  nav: PropTypes.bool
}

export default RelativeTeamLink
