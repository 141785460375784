import styled from 'styled-components'
import { fontSize, fontWeight } from '@shared/style/sizes'

export const Header = styled.div`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.semiBold};
`

export const Description = styled.div`
  font-size: ${fontSize.medium};
`

export const DescriptionRow = styled.div`
  display: flex;
   span {
     color: ${({ theme }) => theme.palette.icon.interactive}
   }
`
