import { css } from 'glamor'
import { theme } from '@toriihq/design-system'

export const KeyOpValueVerticalFilterCSS = {
  selectKey: css({
    width: '100%'
  }),
  propertyInput: css({
    width: '100%'
  }),
  values: css({
    width: '100%'
  }),
  relativeDayValue: css({
    width: '100%'
  }),
  selectWithError: css({
    border: `1px solid ${theme.palette.border.error}`
  })
}
