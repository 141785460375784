import styled from 'styled-components'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'

export const ROW_HEIGHT = 60

export const CSS = {
  header: css(texts.headers.small, {
    textTransform: 'uppercase'
  }),
  expandableRowWrapper: css({
    height: ROW_HEIGHT,
    ':not(:first-of-type)': {
      borderTop: `1px solid ${colors.border}`
    }
  })
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${({ height }: { height: number }) => `${height}px`};
`

export const SelectWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing('space-200')}
`

export const GlobalActionWrapper = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  margin: 16px 0;
  width: 100%;
  overflow: hidden;
  &:hover {
    border-color: ${({ theme }) => theme.palette.border.primaryHover};
  }

  > span {
    display: inherit;
  }
`

export const OptionsWrapper = styled.div`
  :first-child {
    border-top: none;
  }
`
