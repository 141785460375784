import React from 'react'
import { css } from 'glamor'
import moment from 'moment'
import get from 'lodash/get'
import * as Style from './style'

const Tooltip = ({ payload, active, hideOnZeroValue = false, formatter = value => value, keyValueFormatter = value => value }) => {
  if (!active) {
    return null
  }

  const { date, value: totalAmount, keyValueList = [], tooltipHeader, tooltipSubHeader } = get(payload, ['0', 'payload'], {})

  if (hideOnZeroValue && !totalAmount) {
    return null
  }

  const now = moment().startOf('month')
  const isIncompleteMonth = now.isSame(moment(date).startOf('month'))

  return (
    <div {...Style.Tooltip}>
      <div {...Style.HeaderContainer}>
        <div {...Style.DateContainer}>
          <span {...css(Style.Date, { textTransform: 'uppercase' })}>{moment.utc(date).format('MMMM YYYY')}</span>
          {isIncompleteMonth && <span {...Style.Date}> (Incomplete month)</span>}
        </div>
        <div {...Style.Header}>
          <span>{tooltipHeader}</span>
          <span>{`${formatter(totalAmount)}`}</span>
        </div>
        <div {...Style.SubHeader}>{tooltipSubHeader}</div>
      </div>
      <div {...Style.KeyValueList}>
        {keyValueList.map(({ key, value }) => (
          <div key={key} {...Style.KeyValuePair}>
            <span>{key}</span>
            <span {...Style.Value}>{keyValueFormatter(value)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tooltip
