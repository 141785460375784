export const categoryColors = {
  'Operations': '#F10303',
  'Sales & Marketing': '#7D1AA6',
  'Developer Tools': '#CDC64A',
  'Design': '#DF4791',
  'Project Management': '#617A9A',
  'Customer Success': '#0054FF',
  'Human Resource': '#D36FFD',
  'IT & Security': '#47EF88',
  'Finance': '#44EEFF',
  'Productivity': '#0EBBFF',
  'Analytics & BI': '#F99479',
  'Other': '#BED3FC'
}

export const baseColors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  blue: '#0054FF',
  darkBlue: '#00154E',
  darkBlue2: '#7189B8',
  darkBlue3: '#0039AC',
  navy: '#1A31AE',
  black: '#333333',
  grey1: '#757575',
  grey2: '#A5A5A5',
  grey3: '#EBEBEB',
  grey4: '#F9F9F9',
  grey5: '#C2C9D2',
  lightBlue1: '#7AACF4',
  lightBlue2: '#E6EBF4',
  lightBlue3: '#F7FAFF',
  lightBlue4: '#0DD1FF',
  lightBlue5: '#EAF1FE',
  lightBlue6: '#CEE1FC',
  green: '#00E0CF',
  lightGreen: '#E6FFF7',
  darkGreen: '#228B22',
  glowGreen: '#02D647',
  red: '#F53059',
  darkRed: '#C9182F',
  orange: '#FFA31E',
  orange2: '#F56B30',
  orange3: '#BA7C00',
  purple: '#A200FF',
  yellow: '#D2AA16',
  lightYellow: '#FFFBEB',
  lightTeal: '#D9FBFB',
  darkTeal: '#0E1E1F'
}

export const colors = {
  ...baseColors,
  success: baseColors.green,
  error: baseColors.red,
  errorDark: baseColors.darkRed,
  warning: baseColors.orange2,
  background: baseColors.lightBlue3,
  border: baseColors.lightBlue2,
  darkBorder: baseColors.lightBlue1,
  text: baseColors.grey1,
  darkText: baseColors.black
}

export const stateColors = [
  '#0054FF',
  '#0DD1FF',
  '#1A31AE',
  '#7AACF4',
  '#00154E',
  '#7189B8',
  '#A200FF',
  '#F53059',
  '#47EF88',
  '#228B22',
  '#FFA31E',
  '#F56B30'
]

export default colors
