import React from 'react'
import ToriiSelect from '@components/select'
import SourceIcon from '@components/sourceIcon'
import SelectApps from '@components/selectApps'
import colors from '@shared/style/colors'
import { Container, NameStyleHover, ExternalId, AppSelectorWrapper } from './styles'
import { DATE_FORMAT, DISCOVERED_APP_MAPPING_LOGIC, SCOPES } from '@root/constants'
import EnableFor from '../enableFor'
import moment from 'moment'

export const getColumns = ({ displayAppColumn, onMappingLogicChange, onMappedIdAppChange, appsById, columnsConfiguration }) => [
  {
    Header: 'Discovered App',
    accessor: 'name',
    Cell: ({ value: name, row: { externalId } }) => <Container>
      <NameStyleHover>{name}</NameStyleHover>
      {externalId && <ExternalId>{externalId}</ExternalId>}
    </Container>,
    width: 240
  },
  {
    accessor: 'userCount',
    show: false
  },
  {
    Header: 'Source',
    accessor: 'source',
    Cell: ({ value: source }) => (
      <div>
        <SourceIcon isAppSource key={source} sourceType={source} />
      </div>
    ),
    width: 100,
    show: displayAppColumn && Boolean(columnsConfiguration['source'])
  },
  {
    Header: 'Account Name',
    accessor: 'accountName',
    show: Boolean(columnsConfiguration['accountName'])
  },
  {
    Header: 'Discovery date',
    accessor: 'creationTime',
    show: Boolean(columnsConfiguration['creationTime']),
    Cell: ({ value: date }) => <span>{moment.utc(date).format(DATE_FORMAT)}</span>
  },
  {
    Header: 'Matching Logic',
    accessor: 'mappingLogic',
    Cell: ({ value: mappingLogic, row: { id } }) =>
      <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
        <ToriiSelect
          options={[
            { label: 'Torii AI', value: DISCOVERED_APP_MAPPING_LOGIC.TORII_AI },
            { label: 'Manually', value: DISCOVERED_APP_MAPPING_LOGIC.MANUALLY },
            { label: 'Ignored', value: DISCOVERED_APP_MAPPING_LOGIC.IGNORED }
          ]}
          value={mappingLogic}
          onChange={option => onMappingLogicChange(id, (option as { value: string }).value)}
          clearable={false}
          searchable={false}
          openOnFocus
        />
      </EnableFor>,
    minWidth: 240
  },
  {
    Header: 'Matched App',
    accessor: 'mappedIdApp',
    minWidth: 240,
    Cell: ({ value: mappedIdApp, row: { mappingLogic, id } }) => {
      if (mappingLogic === DISCOVERED_APP_MAPPING_LOGIC.IGNORED) {
        return null
      }
      return (
        <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
          <AppSelectorWrapper>
            <SelectApps
              disabled={mappingLogic === DISCOVERED_APP_MAPPING_LOGIC.TORII_AI}
              placeholder='No matching SaaS app found'
              value={appsById[mappedIdApp] || mappedIdApp}
              disableHiddenApps
              onChange={(app, searchValue) => onMappedIdAppChange(id, app, searchValue)}
              specialOption={{
                value: 'customApp',
                render: (searchValue) => <div style={{ color: colors.blue }}>Add a custom app "{searchValue}"</div>
              }}
            />
          </AppSelectorWrapper>
        </EnableFor>
      )
    }
  },
  {
    accessor: 'externalId',
    show: false
  },
  {
    accessor: 'id',
    show: false
  }
]
