import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@toriihq/design-system'

class TableSearch extends React.PureComponent {
  constructor (props) {
    super(props)
    this.onKeydown = this.onKeydown.bind(this)
  }

  componentDidMount () {
    this.bindKeyboardShortcuts()
  }

  componentWillUnmount () {
    this.unbindKeyboardShortcuts()
  }

  bindKeyboardShortcuts () {
    window.addEventListener('keydown', this.onKeydown)
  }

  unbindKeyboardShortcuts () {
    window.removeEventListener('keydown', this.onKeydown)
  }

  onKeydown (e) {
    const F_KEYCODE = 70
    const isCtrlOrCmd = (e.ctrlKey || e.metaKey)
    const isNotShift = !e.shiftKey
    const isFKey = (e.keyCode === F_KEYCODE)
    const isNotAlreadyFocused = (this.input !== document.activeElement)

    if (isCtrlOrCmd && isNotShift && isFKey && isNotAlreadyFocused) {
      this.input.focus()
      e.preventDefault()
    }
  }

  render () {
    const { onSearch, placeholder, disabled, searchValue } = this.props

    return (
      <Input
        prefix='Search'
        placeholder={placeholder}
        onChange={onSearch}
        value={searchValue}
        ref={(c) => (this.input = c)}
        disabled={disabled}
        maxLength={100}
      />
    )
  }
}

TableSearch.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  searchValue: PropTypes.string
}

TableSearch.defaultProps = {
  placeholder: 'Search',
  disabled: false
}

export default TableSearch
