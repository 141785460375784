import React from 'react'
import { css } from 'glamor'

const CSS = {
  riskLeverOuter: css({
    width: '17px',
    height: '17px',
    borderRadius: '17px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px'

  }),
  riskLeverInner: css({
    width: '7px',
    height: '7px',
    borderRadius: '4px'
  })
}

const low = {
  innerColor: '#00E0CF',
  outerColor: '#D9FBF8',
  text: 'Low'
}

const meduim = {
  innerColor: '#FFE31E',
  outerColor: '#FFFBDE',
  text: 'Medium'
}

const high = {
  innerColor: '#FB1D73',
  outerColor: '#FFDDEA',
  text: 'High'
}

export const permissionsLegend = {
  1: low,
  2: meduim,
  3: high
}

const permissionsLegendByLabel = {
  Low: low,
  Medium: meduim,
  High: high
}

class RiskIcon extends React.Component {
  render () {
    const { riskLevel } = this.props
    const { outerColor, innerColor } = permissionsLegend[riskLevel] || permissionsLegendByLabel[riskLevel] || {}

    return (
      <div {...CSS.riskLeverOuter} style={{ backgroundColor: outerColor }}>
        <div {...CSS.riskLeverInner} style={{ backgroundColor: innerColor }} />
      </div>
    )
  }
}

export default RiskIcon
