import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Tooltip } from '@toriihq/design-system'

const CheckButton = ({
  id,
  isChecked,
  showTooltip,
  tooltipHtml,
  onChange,
  disabled,
  indeterminate
}) => {
  return (
    <Tooltip
      label={tooltipHtml}
      hide={!showTooltip}
    >
      <Checkbox
        disabled={disabled}
        key={`check_${id}`}
        checked={isChecked}
        onChange={() => onChange(id, !isChecked)}
        indeterminate={indeterminate}
      />
    </Tooltip>
  )
}

CheckButton.propTypes = {
  id: PropTypes.any.isRequired,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipHtml: PropTypes.node,
  onChange: PropTypes.func
}

CheckButton.defaultProps = {
  showTooltip: false,
  showOnHoverOnly: false
}

export default CheckButton
