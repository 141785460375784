import React from 'react'
import Analytics from '../../helpers/analytics'
import * as Style from './style'
import { Icon } from '@toriihq/design-system'

type Props = {
  onGoBack?: () => void,
  onGoNext?: () => void,
  analyticsEvent: string
  label: string,
  linkPrefix: string,
  searchParams?: string
  backLink: number | string | null
  nextLink: number | string | null
}

const BackAndForwardNavigator = ({ onGoBack, onGoNext, analyticsEvent, label, searchParams, linkPrefix, backLink, nextLink }: Props): JSX.Element => {
  const isBackDisabled = !backLink
  const isNextDisabled = !nextLink

  const goBack = (e): void => {
    if (isBackDisabled) {
      e.preventDefault()
      return
    }

    onGoBack?.()
    Analytics.track(analyticsEvent, {
      'Direction': 'Back'
    })
  }

  const goNext = (e): void => {
    if (isNextDisabled) {
      e.preventDefault()
      return
    }

    onGoNext?.()
    Analytics.track(analyticsEvent, {
      'Direction': 'Next'
    })
  }

  const backTo = searchParams ? `${linkPrefix}` : `${linkPrefix}${backLink}`
  const backSearch = searchParams ? `${searchParams}${backLink}` : ''

  const nextTo = searchParams ? `${linkPrefix}` : `${linkPrefix}${nextLink}`
  const nextSearch = searchParams ? `${searchParams}${nextLink}` : ''

  return (
    <Style.Wrapper>
      <Style.Button supportDisabledLink disabled={isBackDisabled} nav to={backTo} onClick={goBack} search={backSearch}>
        <Icon name='ChevronLeft' />
      </Style.Button>
      <Style.Label>{label}</Style.Label>
      <Style.Button supportDisabledLink disabled={isNextDisabled} nav to={nextTo} onClick={goNext} search={nextSearch}>
        <Icon name='ChevronRight' />
      </Style.Button>
    </Style.Wrapper>
  )
}

export default BackAndForwardNavigator
