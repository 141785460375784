import React from 'react'
import TextControl from '../../form/text-control'
import Headers from '../headers/index'
import { Field } from 'react-final-form'
import { withRouter } from 'react-router-dom'
import { css } from 'glamor'

const CSS = {
  form: css({
    ' .form-group': {
      marginBottom: '15px'
    }
  })
}

const required = fieldName => value => (value ? undefined : `Please choose a ${fieldName}`)

class BasicInformationSignupStep extends React.Component {
  input = {}

  componentDidMount () {
    if (this.input.firstName) {
      this.input.firstName.focus()
    }
  }

  renderField = (props) => {
    const { meta, input, ...rest } = props
    return (
      <TextControl
        ref={(c) => (this.input[input.name] = c)}
        hasError={meta.touched && Boolean(meta.error)}
        error={meta.error}
        {...input}
        {...rest}
      />
    )
  }

  render () {
    return (
      <div>
        <Headers
          headerText='Welcome to Torii'
          subHeaderText='What should we call you?'
        />
        <div {...CSS.form}>
          <fieldset>
            <Field
              name='firstName'
              placeholder='First Name'
              validate={required('First Name')}
              component={this.renderField} />
            <Field
              name='lastName'
              placeholder='Last Name'
              validate={required('Last Name')}
              component={this.renderField} />
            <Field
              name='companyName'
              placeholder='Company Name'
              validate={required('Company Name')}
              component={this.renderField}
              disabled={Boolean(this.props.org)} />
          </fieldset>
        </div>
      </div>
    )
  }
}

export default withRouter(BasicInformationSignupStep)
