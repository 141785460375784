import styled from 'styled-components'

import colors from '../../shared/style/colors'
import { fontSize, fontWeight } from '../../shared/style/sizes'

export const Wrapper = styled.div`
  position: relative;
  padding: ${({ badge }) => badge && '2px 0'};
`
export const Badge = styled.div`
  position: absolute;
  bottom: 37px;
  left: 9px;
  padding: 4px 9px;
  background-color: ${colors.lightBlue1};
  border-radius: 4px;
  color: ${colors.white};
  font-size: ${fontSize.extraSmall};
  font-weight: ${fontWeight.bold};
  letter-spacing: 0.5px;
`
