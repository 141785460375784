import React, { useMemo } from 'react'
import moment from 'moment'
import BackAndForwardNavigatorByOptionsArray from '@components/BackAndForwardNavigatorByOptionsArray'
import range from 'lodash/range'

type Props = {
  month: number,
  year: number
}

const MonthSelector = ({ month, year }: Props): JSX.Element => {
  const now = moment().startOf('month')
  const MAX_MONTHS_AMOUNT = 12

  const options = useMemo(() => {
    return range(MAX_MONTHS_AMOUNT).map(index => {
      const monthsToSubtract = MAX_MONTHS_AMOUNT - index - 1
      const newDate = moment().subtract(monthsToSubtract, 'month')
      return { linkSuffix: `${newDate.year()}/${newDate.month() + 1}`, label: newDate.format('MMMM YYYY') }
    })
  }, [])

  const date = moment(`01/${month}/${year}`, 'DD/MM/YYYY').startOf('month')
  const optionIndex = options.length - now.diff(date, 'month') - 1

  const backAndForwardNavigatorProps = {
    analyticsEvent: 'Switch month',
    label: '',
    linkPrefix: `/expenses/overview/`,
    options,
    optionIndex
  }

  return <BackAndForwardNavigatorByOptionsArray {...backAndForwardNavigatorProps} />
}

export default MonthSelector
