import { GET_ALL_ORGS } from '@root/constants'

const initialState = { orgs: [], toriiAdminScopes: {} }

const orgsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ALL_ORGS}_RESPONSE`: {
      return {
        orgs: action.payload.orgs,
        toriiAdminScopes: action.payload.resources.toriiAdminScopes
      }
    }
    default:
      return state
  }
}

export default orgsReducer
