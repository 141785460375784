import styled from 'styled-components'
import colors from '@shared/style/colors'

export namespace Styles {
  export const Option = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `

  export const Value = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  `

  export const DividerLineWrapper = styled.div`
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.palette.border.secondary};

    pointer-events: none;
  `

  export const DividerLine = styled.div`
    height: 1px;    
    background-color: ${({ theme }) => theme.palette.border.primary};
  `

  export const AccountInfo = styled.div`
    font: ${({ theme }) => theme.typography.font.caption01};
    color: ${colors.grey2};
  `
}
