import React from 'react'
import PropTypes from 'prop-types'
import EnableFor from '../../../enableFor'
import MentionsTextarea from '../../../form/mentionsTextarea'

class EmailContent extends React.Component {
  constructor (props) {
    super(props)

    this.state = { input: props.value || '' }
    this.saveData = this.saveData.bind(this)
  }

  saveData () {
    const { value, onChange } = this.props
    const { input } = this.state
    if (input !== value) {
      onChange(input)
    }
  }

  render () {
    const { overrideStyle, mentions, disabled, allowedScopes } = this.props
    const { input } = this.state

    return (
      <div {...overrideStyle}>
        <EnableFor scopes={allowedScopes}>
          <MentionsTextarea
            disabled={disabled}
            value={input}
            mentions={mentions}
            onChange={e => this.setState({ input: e.target.value })}
            onBlur={this.saveData}
          />
        </EnableFor>
      </div>
    )
  }
}

EmailContent.propTypes = {
  overrideStyle: PropTypes.object,
  value: PropTypes.node,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default EmailContent
