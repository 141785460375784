import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import Table from '../table'
import RelativeTeamLink from '../relativeTeamLink'
import AppDetails from '../appDetails'
import { LineChart, Line } from 'recharts'
import moment from 'moment'
import { TABLES } from '../../constants'
import get from 'lodash/get'
import Analytics from './analytics'
import { Spacer, Link, StatusDot } from '@toriihq/design-system'
import Currency from '../currency'

const shouldHaveBorderRight = {
  name: true,
  firstMonth: true,
  secondMonth: true,
  thirdMonth: true
}
const BORDER = 'solid 1px rgba(0,0,0,0.05)'
const CSS = {
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  appName: css({
    display: 'flex',
    alignItems: 'center'
  }),
  chart: css({
    ' .recharts-wrapper': {
      cursor: 'pointer !important'
    }
  })
}

CSS.icon = css({
  width: '30px',
  marginRight: '15px',
  borderRadius: '4px',
  border: '1px solid transparent',
  transition: 'border-color .2s',
  [`.${CSS.nameAndIcon}:hover &, [data-${CSS.nameAndIcon}]:hover &`]: {
    borderColor: colors.darkBorder
  }
})

class Expenses extends React.Component {
  getColumns = (stateInfo) =>
    [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        width: 220,
        className: CSS.nameAndIcon.toString(),
        Cell: ({ value: name, row: { id, category, imageUrl, isNew } }) => (
          <AppDetails
            id={id}
            name={name}
            category={category}
            imageUrl={imageUrl}
            isNew={isNew}
            component='Expenses list'
          />
        )
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: ({ value, row: { id } }) => {
          const state = stateInfo && stateInfo.options.find(o => o.value === value)
          return state ? <StatusDot {...state} singleLine /> : null
        },
        style: { overflow: 'inherit' },
        width: 160
      },
      {
        Header: () => (
          this.props.apps[0] && this.props.apps[0].firstMonth ? <span>{moment.utc(this.props.apps[0].firstMonth.date).format('MMMM YYYY') || ''}</span> : <span>Expenses</span>
        ),
        accessor: 'firstMonth',
        Cell: ({ value: firstMonth }) => firstMonth ? <Currency value={firstMonth.total} /> : null,
        sortMethod: monthExpenseSorter,
        ...Table.numericFieldProps
      },
      {
        Header: () => (
          this.props.apps[0] && this.props.apps[0].secondMonth ? <span>{moment.utc(this.props.apps[0].secondMonth.date).format('MMMM YYYY') || ''}</span> : <span>Expenses</span>
        ),
        accessor: 'secondMonth',
        Cell: ({ value: secondMonth }) => secondMonth ? <Currency value={secondMonth.total} /> : null,
        sortMethod: monthExpenseSorter,
        ...Table.numericFieldProps
      },
      {
        Header: () => (
          this.props.apps[0] && this.props.apps[0].thirdMonth ? <span>{moment.utc(this.props.apps[0].thirdMonth.date).format('MMMM YYYY') || ''}</span> : <span>Expenses</span>
        ),
        accessor: 'thirdMonth',
        Cell: ({ value: thirdMonth }) => thirdMonth ? <Currency value={thirdMonth.total} /> : null,
        sortMethod: monthExpenseSorter,
        ...Table.numericFieldProps
      },
      {
        Header: 'Last 12 months',
        accessor: 'total',
        Cell: ({ value: total, row }) => <RelativeTeamLink key={`${row.path}/expenses`} to={`${row.path}/expenses`}><Link><Currency value={total} format='$0a' /></Link></RelativeTeamLink>,
        ...Table.numericFieldProps
      },
      {
        Header: 'Trend',
        accessor: 'trendInfo',
        width: 130,
        Cell: ({ value: trendInfo, row }) => (
          <RelativeTeamLink key={`${row.path}/expenses`} to={`${row.path}/expenses`}>
            <div {...CSS.chart}>
              <LineChart width={100} height={28} data={trendInfo}>
                <Line type='monotone' dataKey='total' stroke={colors.purple} strokeWidth={2} isAnimationActive={false} dot={false} />
              </LineChart>
            </div>
          </RelativeTeamLink>
        )
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'path',
        show: false
      },
      {
        accessor: 'id',
        show: false
      }
    ]

  searchFilterMethod (row, search) {
    const values = [
      row.name
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  render () {
    const { apps, loading, stateInfo } = this.props

    const columns = this.getColumns(stateInfo)
    return (
      <Table
        tableKey={TABLES.expensesTable.key}
        getTheadThProps={injectTheadThSortOrder}
        getTdProps={setTdStyle}
        data={apps}
        header={({ data }) => <div>
          {`PAID APPLICATIONS (${data.length}) `}
          <Spacer left={'space-050'}><RelativeTeamLink key={'reports/monthly'} to={'/reports/monthly'} onClick={() => Analytics.onSeeFullReport()}>
            <Link>See full report</Link>
          </RelativeTeamLink></Spacer>

        </div>}
        columns={columns}
        loading={loading}
        clientPaging
        pageSize={20}
        searchable
        searchFilterMethod={this.searchFilterMethod}
        draggable={false}
      />
    )
  }
}

Expenses.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    firstMonth: PropTypes.object.isRequired,
    secondMonth: PropTypes.object.isRequired,
    thirdMonth: PropTypes.object.isRequired,
    trendInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
    total: PropTypes.number.isRequired
  }))
}

const injectTheadThSortOrder = (table, _, column) => {
  const sortOrder = table.sorted.find(sorted => sorted.id === column.id)
  let specificStyle = {}

  if (['firstMonth', 'secondMonth', 'thirdMonth'].includes(column.id)) {
    specificStyle.background = `${colors.lightBlue3}`
  }

  return {
    style: {
      textAlign: 'left',
      display: 'flex',
      padding: '14px 20px',
      color: sortOrder && colors.darkText,
      boxShadow: 'none',
      ...specificStyle
    },
    sortOrder: sortOrder && (sortOrder.desc ? 'desc' : 'asc')
  }
}

const setTdStyle = (state, rowInfo, column) => {
  let specificStyle = {}
  if (shouldHaveBorderRight[column.id]) {
    specificStyle.borderRight = BORDER
  }

  if (['firstMonth', 'secondMonth', 'thirdMonth'].includes(column.id)) {
    specificStyle.height = '60px'
    specificStyle.padding = '21px 20px'
    specificStyle.backgroundColor = `${colors.lightBlue3}`
  }

  return {
    style: {
      padding: '10px 20px',
      color: colors.black,
      ...specificStyle
    }
  }
}

const monthExpenseSorter = (a, b) => (get(a, 'total') || 0) - (get(b, 'total') || 0)

export default Expenses
