import React, { ReactElement } from 'react'
import RelativeTeamLink from '@components/relativeTeamLink'
import { Link } from './styles'
import { analytics } from '@shared/services/workflows/analytics'
import { WORKFLOW_TRIGGER_TYPE } from '@root/shared/types'

interface Props {
  triggerType: WORKFLOW_TRIGGER_TYPE
}

export const UsersAndEmployeesLink = ({
  triggerType
}: Props): ReactElement => {
  return <Link>
    <RelativeTeamLink to='/settings/usersAndEmployees' onClick={() => analytics.trigger.clickOnSettingsLink({ triggerType })}>Settings</RelativeTeamLink>
  </Link>
}
