import React from 'react'
import { css } from 'glamor'
import ToriiSelect from '../../select'
import { APP_PERMISSIONS, SCOPES } from '../../../constants'
import colors from '../../../shared/style/colors'
import ConnectDirectIntegrationPopup from '../../popups/connectDirectIntegrationPopup'
import get from 'lodash/get'
import { capitalize } from 'lodash'
import Connect from '@media/connect.svg'
import VisibleFor from '@components/visibleFor'
import { AlertBox, AlertBoxType, AlertBoxLayout } from '@toriihq/design-system'
import ConnectSCIMIntegrationPopup from '@components/popups/connectSCIMIntegrationPopup'
import { INTEGRATION_TYPE } from '@shared/types'
import isObject from 'lodash/isObject'

const CSS = {
  container: css({
    height: '32px'
  }),
  permissionMissing: css({
    color: colors.error,
    marginLeft: '10px'
  }),
  addNewAccountContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.blue
  }),
  option: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  valueRenderer: css({
    paddingLeft: 0
  }),
  alertBox: css({
    marginTop: 8
  })
}

class SelectAccount extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    const { idOrg } = this.props
    if (prevProps.idOrg !== idOrg) {
      this.fetchData()
    }

    const { selectedOptionValue, options } = this.props
    if (selectedOptionValue && !isObject(selectedOptionValue) && options && options.length > 0) {
      this.onChange(options.find(option => option.id === selectedOptionValue))
    }
  }

  fetchData () {
    const { idOrg, getServicesSyncData, getServicesConfig } = this.props
    if (idOrg) {
      getServicesSyncData({ idOrg })
      getServicesConfig({ idOrg })
    }
  }

  onChange = (option) => {
    const { onChange } = this.props
    onChange(option)
  }

  renderAddNewAccount = () => {
    const { options } = this.props
    return (
      <VisibleFor scopes={[SCOPES.INTEGRATIONS_WRITE]}>
        <div {...css(CSS.option, CSS.addNewAccountContainer)} onClick={() => this.openConnectPopup({ isReconnect: false })}>
          <span>{options.length > 0 ? 'Connect another account...' : 'Connect a new account...'}</span>
          <span>
            <img alt='add another account' src={Connect} width='18px' height='18px' style={{ marginRight: 'auto' }} />
          </span>
        </div>
      </VisibleFor>
    )
  }

  optionRenderer = (option, isValueRenderer) => {
    const errorMsg = option.config.message ? (
      option.config.reason === 'permissions' ? 'Missing permissions' : option.config.message
    ) : 'Account is not supported'

    return <div {...css(CSS.option, (isValueRenderer ? CSS.valueRenderer : null))}>
      <span>{option.label}</span>
      {!option.config.isValid ? <span {...CSS.permissionMissing}>{errorMsg}</span> : null}
    </div>
  }

  renderConnectPopup = () => {
    const { isConnectSourceOpen, refreshDynamicFieldsOptions, service } = this.props

    if (!isConnectSourceOpen) {
      return null
    }

    const ConnectIntegrationPopup = service.integrationType === INTEGRATION_TYPE.SCIM ? ConnectSCIMIntegrationPopup : ConnectDirectIntegrationPopup
    return (
      <ConnectIntegrationPopup
        permission={APP_PERMISSIONS.read_write}
        disableReadOption
        callback={refreshDynamicFieldsOptions}
      />
    )
  }

  openConnectPopup = ({ isReconnect = false }) => {
    const { idApp, toggleConnectSource, toggleConnectSCIMService, selectedOptionValue, service } = this.props

    if (service.integrationType === INTEGRATION_TYPE.SCIM) {
      toggleConnectSCIMService({ isOpen: true, isReconnect, idApp, idAppAccount: selectedOptionValue.id })
    } else {
      toggleConnectSource({ isConnectSourceOpen: true, sourceIdApp: idApp, isReconnect, idAppAccount: selectedOptionValue.id })
    }
  }

  render () {
    const { options, selectedOptionValue, app, disabled, isLoadingOptions, onChange } = this.props
    const accountConfig = get(selectedOptionValue, 'config')
    const showWarningMsg = selectedOptionValue && accountConfig && (accountConfig.isValid === false && (accountConfig.reason === 'permissions' || accountConfig.reason === 'scopes'))

    return (
      <div>
        <ToriiSelect
          options={options}
          placeholder='Select account'
          onChange={(newValue => onChange(newValue))}
          clearable={false}
          value={selectedOptionValue}
          isLoadingOptions={isLoadingOptions}
          optionRenderer={({ data: option }) => this.optionRenderer(option, false)}
          valueRenderer={({ data: option }) => this.optionRenderer(option, true)}
          disabled={disabled}
          customMenuOption={this.renderAddNewAccount()}
        />
        {!isLoadingOptions && showWarningMsg && <div {...CSS.alertBox}>
          <AlertBox
            layout={AlertBoxLayout.VERTICAL}
            type={AlertBoxType.NOTICE}
            title={capitalize(`${accountConfig.message ? `${accountConfig.message}` : ''}`)}
            description='Please update your integration to allow taking action.'
            secondaryButton={{
              label: `Reconnect ${app.name}`,
              onClick: () => this.openConnectPopup({ isReconnect: true })
            }}
          />
        </div>
        }
        {this.renderConnectPopup()}
      </div>
    )
  }
}

export default SelectAccount
