import { CHARGEBACK_ALLOCATION_COST, CHARGEBACK_ALLOCATION_STRATEGY } from '../constants'

export const filterNoCostChargebackData = (data) => {
  return data.filter((appChargeback) => {
    const hasUserCost = appChargeback.allocationCost === CHARGEBACK_ALLOCATION_COST.user && (appChargeback.costPerUser !== 0 && appChargeback.costPerUser !== null)
    const hasLicenseCost = appChargeback.allocationCost === CHARGEBACK_ALLOCATION_COST.license && (appChargeback.licenseCost !== 0 && appChargeback.licenseCost !== null)
    const hasAllocatedPrecentage = appChargeback.allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.topDown && (appChargeback.percentage !== 0 && appChargeback.percentage !== null)
    return hasUserCost || hasLicenseCost || hasAllocatedPrecentage
  })
}
