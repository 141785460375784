import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize } from '@shared/style/sizes'
import { Field } from 'react-final-form'
import Select from '../select'
import get from 'lodash/get'
import FormGroup from '../form/formGroup'
import Input from '../form/input'
import { FORM_ERROR } from 'final-form'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2'
import { Button, ButtonType, ButtonSize, Tooltip } from '@toriihq/design-system'

const emailValidation = value =>
  value && !/^\S+@\S+\.\S+/.test(value) ? 'Invalid email address' : undefined

class InviteUser extends React.Component {
  input = {}

  componentDidMount () {
    const { formInitialValues } = this.props

    setTimeout(() => this.input.email.focus(), 300)
    this.context.submitAction.current = this.onSubmit
    this.context.setInitialValues(formInitialValues)
  }

  onSubmit = (form) => {
    const { invite, onSuccess, reset } = this.props
    const { email, idRole } = form
    if (!email) {
      return
    }

    return Promise.resolve(invite && invite(email.trim(), idRole))
      .then(res => {
        const error = get(res, 'response.error')
        if (!error) {
          setTimeout(reset, 1000)
          return onSuccess()
        }
        return { [FORM_ERROR]: 'We could not send the invitation, please try again' }
      })
      .catch(e => {
        if (e.message.includes('match the org domain')) {
          return { [FORM_ERROR]: 'You can only invite users from the list of your approved domains' }
        }
        if (e.message.includes('Cannot invite a child user')) {
          return { [FORM_ERROR]: 'It seems you are trying to invite a user to Torii using their email alias, please invite them using their primary email' }
        }
        return { [FORM_ERROR]: 'We could not send the invitation, please try again' }
      })
  }

  emailFieldComponent = (props) => {
    const { meta } = props
    const { domain } = this.props
    const suffix = `@${domain}`

    return (
      <FormGroup label='Email' error={meta.touched && meta.error}>
        <Input
          {...props.input}
          placeholder={`name${suffix}`}
          ref={(c) => (this.input.email = c)}
        />
      </FormGroup>
    )
  }

  roleFieldComponent = ({ label, input, meta, ...props }) => {
    const { adminRoleOptions, toggleRolePopup, isRoleSelectionInPlan } = this.props
    const selectedRole = adminRoleOptions.find(role => role.value === input.value)

    return (
      <FormGroup label='Role' error={meta.touched && meta.error}>
        <Tooltip hide={isRoleSelectionInPlan} label={'Role selection is not available in the Basic plan.'} fullWidth>
          <div style={{ width: '100%' }}>
            <Select
              options={adminRoleOptions}
              labelKey='label'
              valueKey='value'
              clearable={false}
              searchable={false}
              placeholder={label}
              name='idRole'
              {...input}
              {...props}
              disabled={!isRoleSelectionInPlan}
              onChange={selection => {
                input.onChange(selection.value)
              }}
            />
          </div>
        </Tooltip>

        <div {...CSS.footnotes}>
          <div><strong>{selectedRole && selectedRole.label}</strong> - {selectedRole && selectedRole.description}</div>
        </div>
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => toggleRolePopup({ isOpen: true, idRole: input.value, isReadOnly: true })} label='See permissions' />
      </FormGroup>
    )
  }

  render () {
    const { handleSubmit, submitError } = this.props

    return (
      <form {...CSS.form} onSubmit={handleSubmit}>
        <Field
          name='email'
          label='Email Address'
          component={this.emailFieldComponent}
          validate={emailValidation}
        />
        <Field
          name='idRole'
          component={this.roleFieldComponent}
          label='Role'
        />
        <div
          {...CSS.errorText}
          style={{ visibility: submitError ? 'visible' : 'hidden' }}>
          {submitError || 'no error'}
        </div>
      </form>
    )
  }
}

InviteUser.propTypes = {
  domain: PropTypes.string,
  onSuccess: PropTypes.func,
  invite: PropTypes.func,
  reset: PropTypes.func,
  style: PropTypes.object
}

InviteUser.contextType = ToriiPopupContext

InviteUser.defaultProps = {
  onSuccess: () => {}
}

export const validate = ({ email, idRole }) => {
  const errors = {}
  if (!email) {
    errors.email = 'Required'
  }
  if (!idRole) {
    errors.role = 'Required'
  }
  return errors
}

const CSS = {
  form: css({
    height: '320px'
  }),
  errorText: css({
    color: colors.error,
    fontSize: fontSize.small
  }),
  footnotes: css({
    marginTop: '40px',
    fontSize: fontSize.small,
    color: colors.grey1,
    ' > div': {
      marginBottom: '15px'
    }
  })
}

export default InviteUser
