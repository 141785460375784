import { Button, ButtonSize, ButtonType, TextBadge } from '@toriihq/design-system'
import React from 'react'
import { Styles } from './style'
import { propTypes } from './types'

const PluginContentHeader = ({ name, isInstalled, onAction }: propTypes) => {
  const btnType: typeof ButtonType[keyof typeof ButtonType] = !isInstalled ? ButtonType.primary : ButtonType.secondary
  return (
    <Styles.PluginContentHeader>
      <Styles.PluginContentHeaderTitle>
        <Styles.PluginContentHeaderTitleName>
          { name }
        </Styles.PluginContentHeaderTitleName>
        <Button type={btnType} label={isInstalled ? 'Uninstall' : 'Install'} onClick={onAction} size={ButtonSize.medium} />
      </Styles.PluginContentHeaderTitle>
      <Styles.PluginContentHeaderVendor>
        Verified by Torii
        {isInstalled ? <TextBadge color='success' size='Small'>Installed</TextBadge> : null }
      </Styles.PluginContentHeaderVendor>
    </Styles.PluginContentHeader>
  )
}

export default PluginContentHeader
