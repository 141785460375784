import styled from 'styled-components'

export namespace Styles {
  export const PluginContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
  `
}
