import { WORKFLOW_FIELD_TYPE } from '@shared/workflows/actions/types'

interface Field {
  id: string
  name: string
}

export const isActionHasFixedBranches = (fields: Field[]): boolean => {
  return fields.some(field => field.id === WORKFLOW_FIELD_TYPE.FIXED_BRANCHES)
}
