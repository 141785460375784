import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDeleteImportedUsers, toggleImportUsers } from '@actions/'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import { Container, ImportStatusContainer, LastImportDate, LastImportDateTitle } from '@components/appUsers/usersImportPopover/styles'
import { getAppAccountStatuses, getCurrentApp } from '@selectors/apps'
import Placeholder from '@components/placeholder'
import moment from 'moment'
import { DATE_FORMAT, INTEGRATION_CATEGORY, SCOPES, SYNC_STATUS, SYNC_TYPE } from '@root/constants'
import { getScopeByIdOrgAndIdApp } from '@lenses/scopes'
import { getCurrentOrg } from '@selectors/org'
import { getConstantServicesByIdApp, getServicesResources } from '@selectors/services'
import { getDisplayName } from '@root/lenses/users'
import { Stack, Subtitle2 } from '@toriihq/design-system'
import ToriiSelect from '../../select'
import { IMPORT_TYPE } from '@selectors/importUsers/types'
import { keyBy, isEmpty } from 'lodash'
import { UsersImportPopoverButton } from './usersImportPopoverButton'

type Props = {
  onClose: () => void
}

const UsersImportPopover = ({ onClose }: Props): JSX.Element | null => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const currentApp = useSelector(getCurrentApp)
  const { loading: appAccountLoading, appAccounts: allAppAccounts = [] } = useSelector(getAppAccountStatuses)
  const idApp = currentApp?.app?.id
  const serviceConfig = useSelector(getConstantServicesByIdApp)[idApp] || {}
  const isAppFinanceOrContractsCategory = [INTEGRATION_CATEGORY.EXPENSE_SOFTWARE, INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE].includes(serviceConfig.integrationCategory)
  const allAppAccountsWithoutUnSyncedCustom = allAppAccounts.filter(({ source, lastSyncTime }) => source !== SYNC_TYPE.CUSTOM || lastSyncTime)
  const appAccounts = isAppFinanceOrContractsCategory ? allAppAccountsWithoutUnSyncedCustom.filter(({ source }) => source === SYNC_TYPE.MANUAL) : allAppAccountsWithoutUnSyncedCustom

  const allAppAccountById = keyBy(appAccounts, 'idAppAccount')
  const { users } = useSelector(getServicesResources)
  const [selectedAppAccount, setSelectedAppAccount] = useState(appAccounts?.[0] || {})
  const loading = currentApp.loading || appAccountLoading.loading
  const appName = currentApp?.app?.name
  const { lastSyncTime, lastSyncBy, source, idAppAccount, workflowsToInvalidate, syncStatus, lastUsersAndLicensesFileLastSyncTime } = selectedAppAccount
  const importMode = !source || source === SYNC_TYPE.MANUAL || isAppFinanceOrContractsCategory ? IMPORT_TYPE.USERS : IMPORT_TYPE.LICENSES

  const lastImportDate = useMemo(() => {
    const NO_FILE_MESSAGE = 'No files imported'
    if (source === SYNC_TYPE.MANUAL) {
      if (!lastUsersAndLicensesFileLastSyncTime) {
        return NO_FILE_MESSAGE
      }
      return moment(lastUsersAndLicensesFileLastSyncTime).format(DATE_FORMAT)
    } else {
      if (!lastSyncTime) {
        return NO_FILE_MESSAGE
      }
      return moment(lastSyncTime).format(DATE_FORMAT)
    }
  }, [lastSyncTime, lastUsersAndLicensesFileLastSyncTime, source])

  const scopes = [SCOPES.APPLICATIONS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.APPLICATIONS_WRITE, idOrg, idApp)]

  useEffect(() => {
    const defaultAppAccount = appAccounts?.[0]
    if (defaultAppAccount && isEmpty(selectedAppAccount)) {
      setSelectedAppAccount(defaultAppAccount)
    }
  }, [appAccounts, selectedAppAccount])

  const lastSyncByUserName = useMemo(() => {
    const lastSyncByUser = lastSyncBy && users[lastSyncBy]
    return lastImportDate && lastSyncByUser ? getDisplayName({ firstName: lastSyncByUser.firstName, lastName: lastSyncByUser.lastName, email: lastSyncByUser.email }) : ''
  }, [lastSyncBy, users, lastImportDate])

  const isFirstTimeImport = useMemo(() => {
    if (loading || !idAppAccount) {
      return true
    }
    if (importMode === IMPORT_TYPE.USERS) {
      return false
    }
    return !allAppAccountById[idAppAccount].licensesManagedManually
  }, [loading, allAppAccountById, idAppAccount, importMode])

  const getAppAccountWithType = useCallback((appAccount) => {
    const { appAccountName, source } = appAccount
    if (appAccountName) {
      return appAccountName + (source === SYNC_TYPE.MANUAL ? ' (Manual)' : ' (Integrated)')
    } else {
      return `${appName} imported account (Manual)`
    }
  }, [appName])

  const appAccountName = useMemo(() => {
    return getAppAccountWithType(selectedAppAccount)
  }, [getAppAccountWithType, selectedAppAccount])

  const handleDeleteUsersClick = () => {
    dispatch(toggleDeleteImportedUsers({ isOpen: true, idApp, importMode, idAppAccount, appAccountName, workflowsToInvalidate, lastSyncTime }))
    ImportUsersAnalytics.onOpenDeletePopup(importMode)
    onClose()
  }

  const handleFileImportClick = () => {
    const lastSyncTime = idAppAccount ? allAppAccountById[idAppAccount].lastSyncTime : undefined

    dispatch(toggleImportUsers({ isOpen: true, idApp, appName, importMode, isFirstTimeImport, lastSyncTime, idAppAccount, appAccountName }))
    ImportUsersAnalytics.onOpenImportPopup(importMode, 'Import')
    onClose()
  }

  const showAccounts = () => {
    const totalAppAccount = appAccounts?.length
    if (!totalAppAccount) {
      return null
    } else if (totalAppAccount === 1) {
      return <Subtitle2>{appAccountName}</Subtitle2>
    } else {
      const optionRenderer = (props) => {
        const option = props.data
        return getAppAccountWithType(option)
      }

      return <Stack gap='space-050'>
        <Subtitle2>Import to account</Subtitle2>
        <ToriiSelect
          options={appAccounts}
          valueRenderer={optionRenderer}
          optionRenderer={optionRenderer}
          valueKey='idAppAccount'
          labelKey='appAccountName'
          value={selectedAppAccount}
          onChange={setSelectedAppAccount} />
      </Stack>
    }
  }

  const isImportButtonDisabled = importMode === IMPORT_TYPE.LICENSES && syncStatus === SYNC_STATUS.FINISHED_FAILED

  return (
    <Container>
      <Placeholder loading={loading}>
        <Stack gap='space-200'>
          {showAccounts()}
          <Stack gap='space-050'>
            <UsersImportPopoverButton
              icon='FileImport'
              text={importMode === IMPORT_TYPE.USERS ? 'Import users' : 'Adjust user licenses'}
              onClick={handleFileImportClick}
              disabled={isImportButtonDisabled}
              showTooltip={isImportButtonDisabled}
              tooltipText={'Account sync failed. To adjust licenses, please resolve the integration connection issue.'}
              scopes={scopes} />
            <UsersImportPopoverButton
              icon={importMode === IMPORT_TYPE.USERS ? 'Trash' : 'RotateClockwise'}
              text={importMode === IMPORT_TYPE.USERS ? 'Delete all imported users' : 'Reset manual changes'}
              onClick={handleDeleteUsersClick}
              disabled={isFirstTimeImport || isImportButtonDisabled}
              showTooltip={isImportButtonDisabled}
              tooltipText={'Account sync failed. To reset manual changes, please resolve the integration connection issue.'}
              scopes={scopes} />
          </Stack>
        </Stack>
        {!isFirstTimeImport && <ImportStatusContainer>
          <LastImportDateTitle>Last import</LastImportDateTitle>
          <LastImportDate>{lastImportDate}{lastSyncByUserName && ` by ${lastSyncByUserName}`}</LastImportDate>
        </ImportStatusContainer>}
      </Placeholder>
    </Container>
  )
}

export default UsersImportPopover
