import React, { ReactElement } from 'react'
import { NodeProps } from 'reactflow'
import { WorkflowNodeData } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import ActionIconUrl from '@media/ifElseActionIcon.svg'
import { ActionNodeBase } from '../actionNodeBase'

type BranchNodeProps = NodeProps<WorkflowNodeData>

export const BranchActionNode = ({ data }: BranchNodeProps): ReactElement => {
  return (
    <ActionNodeBase
      data={data}
      iconUrl={ActionIconUrl}
    />
  )
}
