import View from './view'
import { connect } from 'react-redux'
import { getOffboardingUserResources } from '@selectors/offboarding'

const mapStateToProps = (state) => {
  const { users, assignedUsersToTicketByEmail } = getOffboardingUserResources(state)

  return {
    usersById: users,
    assignedUsersToTicketByEmail
  }
}

export default connect(mapStateToProps)(View)
