import { css } from 'glamor'
import Table from '../table'
import { SCOPES } from '../../constants'
import { CSS, ExpanderChevron, FlexDiv, PaddingText } from './styles'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import { Icon, Spacer, Tooltip, Benchmark,
  AppIcon, Button, ButtonType, ButtonSize,
  Link, LinkSize, Popover, Stack, Subtitle2, Body2
} from '@toriihq/design-system'
import LicensePie from '../licensesChart/licensePie'
import LicenseInfoTooltip from '@components/licenses/licenseInfoTooltip'
import { formatNumber, isLicenseTypePaid } from '@root/shared/utils'
import EnableFor from '@components/enableFor'
import RelativeTeamLink from '../relativeTeamLink'
import GraySpinner from '@media/spinnerGray.svg'
import OriginalAndConvertedCurrencies from '../originalAndConvertedCurrencies'
import { LicenseEditSelectedColumn } from '@components/licenseEdit'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import Currency from '@components/currency'

const NAME_COLUMN_WIDTH = 200
const APP_ICON_WIDTH = 45
const PADDING = 5

export const getColumns = ({ idOrg, hasLicensesCostAndChargebackAccess, displayBenchmark, togglePopup, toggleEditLicensePopup, isApps }) => {
  return [
    {
      expander: true,
      width: 35,
      style: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      Expander: ({ isExpanded, original }) => {
        const { licenseTypes } = original
        if (licenseTypes) {
          return (
            <ExpanderChevron isExpanded={isExpanded}>
              <Icon name='ChevronDown' color='secondary' />
            </ExpanderChevron>
          )
        } else {
          return null
        }
      },
      show: isApps
    },
    {
      Header: 'Application Name',
      id: 'name',
      Cell: ({ value: name, row: { idApp, isParent, imageUrl } }) => (
        <FlexDiv>
          <RelativeTeamLink idOrg={idOrg} to={`/app/${idApp}`}>
            <AppIcon
              appImageUrl={imageUrl}
              appName={name}
              hoverable
            />
          </RelativeTeamLink>
          <PaddingText {...CSS.appNameColumnValue}>{name}</PaddingText>
          <span {...CSS.licenseTooltipIcon}>{ isParent && <LicenseInfoTooltip idApp={idApp} /> }</span>
        </FlexDiv>
      ),
      resizable: false,
      width: NAME_COLUMN_WIDTH,
      show: isApps
    },
    {
      Header: 'License Name',
      id: 'name',
      Cell: ({ row: { licenseName } }) => (
        <div {...CSS.appNameColumnValue}>{licenseName}</div>
      ),
      show: !isApps,
      getProps: () => {
        return {
          style: {
            paddingLeft: `${APP_ICON_WIDTH + PADDING}px`
          }
        }
      },
      width: NAME_COLUMN_WIDTH
    },
    {
      Header: 'Total licenses',
      id: 'validTotalAmount',
      Cell: ({ value: validTotalAmount, row: { isParent, totalAmount, assignedAmount, unassignedAmount, isTotalAmountEnteredByUser, isAppTotalAmountPartialCalculated, id, idApp, name, appName } }) => {
        const renderNumberWithLabel = () =>
          <div {...CSS.totalAmountWithLabel}>
            <div>{formatNumber(validTotalAmount)}</div>
            {isTotalAmountEnteredByUser && <div {...CSS.totalAmountEditedLabel}>edited</div>}
          </div>

        const renderTotalLicensesNumberAndIconWithTooltip = ({ label, iconName, iconColor }) => <div {...CSS.totalLicensesNumberAndIconWithTooltip}>
          <Tooltip label={label}>
            <Icon name={iconName} color={iconColor} />
          </Tooltip>
          {renderNumberWithLabel()}
        </div>

        const renderEditButton = () =>
          <div {...CSS.editButton}>
            <EnableFor scopes={[SCOPES.LICENSE_AND_CHARGEBACK_WRITE]}>
              <Button type={ButtonType.compact} icon='Edit' onClick={() => toggleEditLicensePopup({ selectedIdApp: idApp, selectedIdLicense: id, selectedAppName: appName, selectedColumn: LicenseEditSelectedColumn.TotalAmount })} />
            </EnableFor>
          </div>
        const renderChild = () =>
          <div {...CSS.licenseRowIconAndLabel}>
            {(isNil(unassignedAmount) || isTotalAmountEnteredByUser) && renderEditButton()}
            {!isTotalAmountEnteredByUser && isNil(unassignedAmount)
              ? renderTotalLicensesNumberAndIconWithTooltip({ label: `This number doesn't include unassigned licenses because ${appName} can't provide this data. Click the Edit button to enter total purchased licenses and get the unassigned count.`, iconName: 'Info' })
              : isNumber(totalAmount) && totalAmount < assignedAmount
                ? renderTotalLicensesNumberAndIconWithTooltip({ label: `The total number entered (${totalAmount}) is no longer valid as it is lower than the total assigned licenses.  Click the Edit button to enter an updated number.`, iconName: 'Danger', iconColor: 'error' })
                : renderNumberWithLabel()
            }
          </div>

        return <div {...CSS.totalLicensesContainer}>
          {isParent && isAppTotalAmountPartialCalculated && renderTotalLicensesNumberAndIconWithTooltip({ label: `This number doesn't include all unassigned licenses because ${name} can't provide this data. To ensure accuracy, expand and input the total purchased licenses for any license lacking this data.`, iconName: 'Info' })}
          {isParent && !isAppTotalAmountPartialCalculated && renderNumberWithLabel()}
          {!isParent && renderChild()}
        </div>
      },
      ...Table.numericFieldProps,
      resizable: false,
      width: 120
    },
    {
      Header: <div {...CSS.columnMarginLeft}>License Breakdown</div>,
      id: 'LicensesBreakdown',
      accessor: 'validTotalAmount',
      resizable: false,
      Cell: ({ value: totalAmount, row: { isParent, activeAmount, inactiveAmount, unassignedAmount, validTotalAmount, idApp, savingsConverted, name, id } }) => {
        const hasInactiveLicenses = inactiveAmount > 0
        return <div {...css(CSS.regular, CSS.columnMarginLeft)}>
          <div>
            <span {...CSS.licenseStatusLabel}>Active</span>
            <span {...CSS.licenseAmount}>{formatNumber(activeAmount)}</span>
          </div>
          <EnableFor
            scopes={[
              `${SCOPES.USERS_READ}`,
              `${getScopeByIdOrgAndIdApp(SCOPES.USERS_READ, idOrg, idApp)}`
            ]}
            allowForToriiAdmin
            showAsDisabled={false}
          >
            <div role={hasInactiveLicenses ? 'button' : ''} {...css(hasInactiveLicenses && CSS.inactiveMoreThenZero)} onClick={() => hasInactiveLicenses && togglePopup({
              selectedIdApp: idApp,
              selectedAppName: name,
              selectedIdLicense: id,
              savingsConverted,
              inactiveAmount
            })}>
              <span {...CSS.licenseStatusLabel}>Inactive</span>
              <span {...CSS.licenseAmount} className='inactiveAmount'>{formatNumber(inactiveAmount)}</span>
            </div>
          </EnableFor>
          <div>
            <span {...CSS.licenseStatusLabel}>Unassigned </span>
            <span {...CSS.licenseAmount}>{isNil(unassignedAmount) || (!isParent && totalAmount !== validTotalAmount) ? '-' : formatNumber(unassignedAmount)}</span>
          </div>
        </div>
      },
      width: 150
    },
    {
      Header: <div>Annual cost<br />per license</div>,
      accessor: 'pricePerUser',
      resizable: false,
      Cell: ({ value: pricePerUser, row: { id, isParent, priceNote, idApp, pricePerUserConverted, currency, appName, isPaid } }) => {
        const ANNUAL_COST_BADGE_TEXT = {
          FREE: 'FREE',
          UNKNOWN: 'UNKNOWN',
          EXPAND_TO_EDIT: 'Expand to edit'
        }

        const renderBadge = (text) => <span {...CSS.badge}>{text}</span>
        const renderTooltip = (label, children) => <Tooltip label={label}>{children}</Tooltip>

        const renderAnnualCost = (pricePerUser, isPaid) => {
          if (!isLicenseTypePaid({ pricePerUser, isPaid })) {
            return renderBadge(ANNUAL_COST_BADGE_TEXT.FREE)
          }

          return pricePerUser > 0
            ? <OriginalAndConvertedCurrencies value={pricePerUser} convertedValue={pricePerUserConverted} currency={currency} />
            : renderBadge(ANNUAL_COST_BADGE_TEXT.UNKNOWN)
        }

        const renderLicenseRowIconAndLabel = () => <div {...CSS.licenseRowIconAndLabel}>
          <div {...CSS.editButton}>
            <EnableFor scopes={[SCOPES.LICENSE_AND_CHARGEBACK_WRITE]}>
              <Button type={ButtonType.compact} size={ButtonSize.small} icon='Edit' onClick={() => toggleEditLicensePopup({ selectedIdApp: idApp, selectedIdLicense: id, selectedAppName: appName, selectedColumn: LicenseEditSelectedColumn.PricePerUser })} />
            </EnableFor>
          </div>
          {renderAnnualCost(pricePerUser, isPaid)}
        </div>

        return <div {...CSS.yearlyLicenseCost}>
          {isParent && pricePerUser === 0 && renderBadge(ANNUAL_COST_BADGE_TEXT.FREE)}
          {isParent && pricePerUser === null && renderBadge(ANNUAL_COST_BADGE_TEXT.EXPAND_TO_EDIT)}
          {isParent && pricePerUser > 0 && renderTooltip('Average cost of paid licenses', <OriginalAndConvertedCurrencies value={pricePerUser} convertedValue={pricePerUserConverted} currency={currency} />)}
          {isParent && priceNote && renderTooltip(priceNote, <Spacer right={'space-050'}><Icon name='Info' /></Spacer>)}
          {!isParent && renderLicenseRowIconAndLabel()}
        </div>
      },
      width: 160,
      ...Table.numericFieldProps,
      show: hasLicensesCostAndChargebackAccess
    },
    {
      Header: <div {...css(CSS.benchmarkContainer, CSS.headerWithIcon)}>
        <span>License cost<br />benchmark</span>
        <Spacer left={'space-050'}>
          <Tooltip label='Our Benchmarks feature compares your license costs to those of similar companies using anonymized data' maxWidth={300}>
            <Icon name='Info' {...CSS.headerIcon} />
          </Tooltip>
        </Spacer>
      </div>,
      accessor: 'benchmark',
      resizable: false,
      Cell: ({ value: benchmark, row: { isParent, pricePerUser, isUpdatingLicense, isBenchmarkDataExist } }) => {
        const renderBenchmark = (benchmark) => {
          const thresholdValue = (value) => <Currency value={value} shouldRoundValue />
          const lowerLevelThreshold = thresholdValue(benchmark.lowerValueConverted)
          const upperLevelThreshold = thresholdValue(benchmark.upperValueConverted)

          const costComparisonWord = benchmark.value === 0 ? 'below' : benchmark.value === 1 ? 'within' : 'above'

          const benchmarkPopoverContent = (
            <Stack gap='space-200'>
              <Stack gap='space-050'>
                <Subtitle2>Benchmark range</Subtitle2>
                <Body2>Your license cost is {costComparisonWord} the <br /> benchmark range. <Link size={LinkSize.Medium} href='https://support.toriihq.com/hc/en-us/articles/11556671557147' target='_blank' rel='noopener noreferrer'>Learn more</Link></Body2>
              </Stack>
              <Benchmark {...CSS.benchmark} value={benchmark.value} levels={3} size={'large'} levelCategories={['Below', 'At benchmark', 'Above']} levelThresholds={[lowerLevelThreshold, upperLevelThreshold]} />
            </Stack>)

          return <Popover showArrow position='top' content={benchmarkPopoverContent} maxWidth={288}>
            <Benchmark {...CSS.benchmark} value={benchmark.value} levels={3} size={'small'} levelCategories={['Below benchmark', 'At benchmark', 'Above benchmark']} />
          </Popover>
        }

        const renderNoBenchmark = () => {
          return <div {...CSS.unavailableOrLoadingBenchmark}>
            <span>Not available yet</span>
            <Spacer left={'space-050'}>
              <Tooltip maxWidth={300} label={<div>We don't have enough data to generate a meaningful benchmark yet.
                <br />Please check back soon as we're continually updating our benchmarks with new data</div>}>
                <Icon name='Info' />
              </Tooltip>
            </Spacer>
          </div>
        }

        return <div {...CSS.benchmarkContainer}>
          <span {...css(CSS.unavailableOrLoadingBenchmark, !isUpdatingLicense && CSS.hidden)}><img {...CSS.spinner} alt='Loading benchmark...' src={GraySpinner} />Loading benchmark...</span>
          { isParent ? isBenchmarkDataExist ? <span {...CSS.benchmarkBadge}>Expand to view</span> : <span {...CSS.benchmarkBadge}>Not available yet</span>
            : !isUpdatingLicense && pricePerUser
              ? isNumber(benchmark.value)
                ? renderBenchmark(benchmark)
                : renderNoBenchmark()
              : '-'
          }
        </div>
      },
      width: 160,
      sortable: false,
      show: displayBenchmark
    },
    {
      Header: <div {...CSS.tableHeader}>
        <Tooltip label='In last 12 months'>
          <div>Total Cost <div {...CSS.estimatedLabel}>(estimated)</div></div>
        </Tooltip>
      </div>,
      accessor: 'totalCost',
      resizable: false,
      Cell: ({ value: totalCost, row: { totalCostConverted, currency } }) =>
        <div {...CSS.regular}> { totalCost === null ? '-' : <OriginalAndConvertedCurrencies value={totalCost} convertedValue={totalCostConverted} currency={currency} /> } </div>,
      width: 100,
      ...Table.numericFieldProps,
      show: hasLicensesCostAndChargebackAccess
    },
    {
      Header: <div>Savings <div {...CSS.estimatedLabel}>(estimated)</div></div>,
      accessor: 'savings',
      resizable: false,
      Cell: ({ value: savings, row: { savingsConverted, currency } }) =>
        <div {...CSS.regular}> { savings === null ? '-' : <OriginalAndConvertedCurrencies value={savings} convertedValue={savingsConverted} currency={currency} /> } </div>,
      width: 100,
      ...Table.numericFieldProps,
      show: hasLicensesCostAndChargebackAccess
    },
    {
      Header: '',
      sortable: false,
      id: 'licenseStatus',
      resizable: false,
      Cell: ({ row: { unassignedAmount, inactiveAmount, activeAmount, inactiveReasons } }) =>
        <LicensePie {...{ unassignedAmount, inactiveAmount, activeAmount, inactiveReasons }} />,
      width: 100,
      getProps: () => {
        return {
          style: {
            paddingLeft: '20px',
            overflow: 'visible'
          }
        }
      }
    },
    {
      accessor: 'idApp',
      show: false
    },
    {
      accessor: 'name',
      show: false
    },
    {
      accessor: 'category',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'inactiveAmount',
      show: false
    },
    {
      accessor: 'unassignedAmount',
      show: false
    },
    {
      accessor: 'assignedAmount',
      show: false
    },
    {
      accessor: 'activeAmount',
      show: false
    },
    {
      accessor: 'totalAmount',
      show: false
    },
    {
      accessor: 'validTotalAmount',
      show: false
    },
    {
      accessor: 'inactiveReasons',
      show: false
    },
    {
      accessor: 'licenseTypes',
      show: false
    },
    {
      accessor: 'licenseName',
      show: false
    },
    {
      accessor: 'appName',
      show: false
    },
    {
      accessor: 'isUpdatingLicense',
      show: false
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'isParent',
      show: false
    },
    {
      accessor: 'priceNote',
      show: false
    },
    {
      accessor: 'isPaid',
      show: false
    },
    {
      accessor: 'isBenchmarkDataExist',
      show: false
    },
    {
      accessor: 'pricePerUserConverted',
      show: false
    },
    {
      accessor: 'totalCostConverted',
      show: false
    },
    {
      accessor: 'savingsConverted',
      show: false
    },
    {
      accessor: 'currency',
      show: false
    },
    {
      accessor: 'isTotalAmountEnteredByUser',
      show: false
    },
    {
      accessor: 'isAppTotalAmountPartialCalculated',
      show: false
    }
  ]
}
