import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import { AppIcon, Spacer, Icon, Badge, TextBadge, Popover } from '@toriihq/design-system'
import { Wrapper } from './styles'
import RelativeTeamLink from '../../relativeTeamLink'
import Analytics from '../../../helpers/analytics'
import { fontSize, fontWeight } from '@shared/style/sizes'
import OriginalAndConvertedCurrencies from '@components/originalAndConvertedCurrencies'
import { getContractCurrencyValue, getContractCurrencyConvertedValue, getContractCurrencyCode } from '@components/contracts/utils'
import moment from 'moment'

const CSS = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '370px',
    height: '135px'
  }),
  calenderSection: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    gap: '2px',
    rowGap: '2px',
    width: '100%'
  }),
  contractEndDate: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    fontSize: '15px',
    fontWeight: fontWeight.normal,
    color: colors.black,
    whiteSpace: 'nowrap'
  }),
  cancellationDeadline: css({
    fontSize: '10px',
    fontWeight: fontWeight.normal,
    color: colors.black,
    padding: '0px 28px',
    lineHeight: '16px'
  }),
  badge: css({
    backgroundColor: colors.darkBlue2,
    position: 'absolute',
    borderRadius: '3px',
    color: colors.white,
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.bold,
    lineHeight: '11px',
    top: '12px',
    padding: '2px 3px',
    right: `10px`
  }),
  colorIndication: css({
    width: '9px',
    height: '9px',
    borderRadius: '10px',
    marginRight: '5px',
    marginBottom: '2px'
  }),
  option: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: fontSize.large
  }),
  optionLabel: css({
    fontSize: fontSize.mediumLarge,
    fontWeight: fontWeight.semiBold,
    lineHeight: '24px'
  }),
  appName: css({
    fontSize: fontSize.medium,
    fontWeight: fontWeight.normal,
    lineHeight: '20px'
  }),
  currencyAndContractStatus: css({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
}

const renderContractStatus = (option) => {
  return <div key={option.value} {...CSS.option}>
    <div {...CSS.colorIndication} style={{ backgroundColor: option.color }} />
    <div {...CSS.optionLabel}>{option.label}</div>
  </div>
}

const generateAppTooltip = ({ appName, amount, date, isPassed, showPassedBadge, status, contractStatusOptionsByValue, isAutoRenewal, cancellationDeadline }) => {
  const dateFormat = 'dddd, LL'
  const contractStatusOption = contractStatusOptionsByValue && status ? contractStatusOptionsByValue[status] : null
  const value = getContractCurrencyValue(amount)
  const convertedValue = getContractCurrencyConvertedValue(amount)
  const contractCurrencyCode = getContractCurrencyCode(amount)

  const currencyElement = <OriginalAndConvertedCurrencies value={value} convertedValue={convertedValue} currency={contractCurrencyCode} isForRenewalTooltip />
  const shouldShowCancellationDeadline = isAutoRenewal && cancellationDeadline
  return (
    <div {...CSS.container}>
      {showPassedBadge && isPassed && <span {...CSS.badge}>PAST DUE</span>}
      <div {...CSS.appName}>{appName}</div>
      <div {...CSS.currencyAndContractStatus}>
        {currencyElement}
        {contractStatusOption && renderContractStatus(contractStatusOption)}
      </div>
      <div {...CSS.calenderSection}>
        <div {...CSS.contractEndDate}>
          <Icon name='Calendar' />
          <span>{date.format(dateFormat)}</span>
          {isAutoRenewal && <TextBadge color='blue' size='Small'>Auto Renews</TextBadge>}
        </div>
        {shouldShowCancellationDeadline && <span {...CSS.cancellationDeadline}>Cancellation deadline: {moment(cancellationDeadline).format(dateFormat)}</span>}
      </div>
    </div>
  )
}

const onNavToApp = (name) => {
  Analytics.track('Click on app', {
    Component: 'Renewals',
    'App name': name
  })
}

const App = ({ app, renewal, contractStatusOptionsByValue, linkToTab = '' }) => {
  const { name, imageUrl, id } = app
  const { autoRenewal } = renewal
  const isAutoRenewal = autoRenewal === 'Yes'
  const badge = isAutoRenewal ? <Badge name='Autorenew' size='medium' /> : null

  return (
    <Spacer right={'space-150'} bottom={'space-150'}>
      <Popover
        showArrow
        position='top'
        content={generateAppTooltip({ appName: name, ...renewal, contractStatusOptionsByValue, isAutoRenewal })}
      >
        <RelativeTeamLink key={id} to={`/app/${id}${linkToTab}`} onClick={() => onNavToApp(name)}>
          <Wrapper isPassed={renewal.isPassed}>
            <AppIcon
              appImageUrl={imageUrl}
              appName={name}
              size='large'
              hoverable
              badge={badge} />
          </Wrapper>
        </RelativeTeamLink>
      </Popover>
    </Spacer>
  )
}

App.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  renewal: PropTypes.shape({
    date: PropTypes.object.isRequired,
    amount: PropTypes.object,
    isPassed: PropTypes.bool.isRequired
  }),
  showPassedBadge: PropTypes.bool.isRequired,
  contractStatusOptionsByValue: PropTypes.object.isRequired
}

export default App
