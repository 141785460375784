import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Avatar, Tooltip, Icon, Button, ButtonType, H4, Spacer, EmptyState } from '@toriihq/design-system'
import Table from '../table'
import emptyImage from '@media/sofa.svg'
import AppDetails from '../appDetails'
import colors from '../../shared/style/colors'
import { getDisplayName } from '@lenses/users'
import Analytics from '../../helpers/analytics'
import { SCOPES } from '../../constants'
import EnableFor from '../enableFor'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getFormattedDate } from '@lenses/utils'
import moment from 'moment'

const CSS = {
  row: css({}),
  connectServiceButton: css({
    pointerEvents: 'auto'
  }),
  header: css({
    display: 'flex',
    alignContent: 'center'
  }),
  infoIcon: css({
    marginLeft: '8px'
  }),
  actions: css({
    minWidth: '250px',
    textAlign: 'right'
  }),
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  })
}

CSS.hoverActions = css({
  opacity: 0,
  [`.${CSS.row}:hover &, [data-${CSS.row}]:hover &`]: {
    opacity: 1
  }
})

const setTrGroupStyle = () =>
  ({
    className: CSS.row.toString()
  })

const getHeader = (props) => <div {...CSS.header}>
  <H4>Hidden Applications ({props.data.length})</H4>
  <Spacer left={'space-100'}>
    <Tooltip
      placement='right'
      label='Hidden applications will not be shown in any report, dashboard, summaries or emails.
      Application data is still being collected.'>
      <Icon name='Info' />
    </Tooltip>
  </Spacer>
</div>

const getColumns = (usersById, onUnHide, isSmallScreen) =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 300,
      minWidth: 200,
      className: CSS.nameAndIcon.toString(),
      Cell: ({ value: name, row: { id, category, imageUrl } }) => (
        <AppDetails
          id={id}
          name={name}
          category={category}
          imageUrl={imageUrl}
          component='Hidden apps'
        />
      )
    },
    {
      Header: 'Date hidden',
      accessor: 'hiddenTime',
      textValue: ({ value }) => value ? moment.utc(value).format('YYYY-MM-DD') : '-',
      Cell: ({ value }) => getFormattedDate({ date: value })
    },
    {
      Header: 'Hidden by',
      accessor: 'hiddenByIdUser',
      textValue: ({ value: idUser }) => getDisplayName({ ...(usersById[idUser] || {}), emptyValue: '-' }),
      Cell: ({ value: idUser }) => {
        if (!idUser || !usersById[idUser]) {
          return '-'
        }
        const user = usersById[idUser] || {}
        const { firstName, lastName, photoUrl } = usersById[idUser]
        const userIcon =
          <Tooltip label={getDisplayName(user)} hide={!user}>
            <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
          </Tooltip>
        return usersById[idUser] && !usersById[idUser].isSupport ? <RelativeTeamUserLink idUser={idUser}><div style={{ width: '30px' }}>{userIcon}</div></RelativeTeamUserLink> : userIcon
      },
      sortMethod: (a, b) => Table.sortMethods.user(usersById, a, b)
    },
    {
      id: 'action',
      Header: '',
      sortable: false,
      minWidth: 100,
      className: css(CSS.actions, !isSmallScreen && CSS.hoverActions).toString(),
      Cell: ({ row: { id, name } }) => <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}><Button type={ButtonType.secondary} onClick={() => onUnHide(id, name)} label='Un-hide' /></EnableFor>
    },
    {
      accessor: 'category',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'id',
      show: false
    }
  ]

class HiddenApps extends React.Component {
  componentDidMount () {
    const { getHiddenApps, idOrg } = this.props
    getHiddenApps({ idOrg })
  }

  onUnHide = async (idApp, name) => {
    const { idOrg, changeAppHiddenState } = this.props
    Analytics.track('Clicked to un-hide application', {
      idApp,
      name
    })
    await changeAppHiddenState({ idOrg, idApp, isHidden: false })
  }

  renderNoApps = () => {
    return (
      <EmptyState
        image={<img alt='no apps' src={emptyImage} />}
        title='No hidden applications yet'
      />
    )
  }

  searchFilterMethod (row, search) {
    const values = [
      row.name,
      row.category
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  render () {
    const { apps, loading, usersById, tableKey, isSmallScreen } = this.props
    const columns = getColumns(usersById, this.onUnHide, isSmallScreen)
    return (
      <Table
        tableKey={tableKey}
        getTrGroupProps={setTrGroupStyle}
        emptyStateMessage={this.renderNoApps()}
        data={apps}
        columns={columns}
        header={getHeader}
        loading={loading}
        exportable
        searchable
        searchFilterMethod={this.searchFilterMethod}
        clientPaging
      />
    )
  }
}

HiddenApps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    hiddenTime: PropTypes.string.isRequired,
    hiddenByIdUser: PropTypes.number.isRequired
  })),
  tableKey: PropTypes.string.isRequired
}

export default HiddenApps
