import React from 'react'
import { css } from 'glamor'
import { APP_COMPLIANCE_FIELDS_SYSTEM_KEYS, APP_OWNERS_FIELDS, EMPTY_ARRAY, EMPTY_OBJECT, formFieldTypes } from '../../constants'
import { getDisplayName } from '@lenses/users'
import Table from '../table'
import moment from 'moment'
import AssignUserAppDetailButton from '../assignUserAppDetailButton'
import CurrencyOld from '../currencyOld'
import Currency from '../currency'
import isNumber from 'lodash/isNumber'
import config from '../../config'
import { Tooltip, Avatar, AvatarGroup, Link, Icon } from '@toriihq/design-system'
import { Wrapper } from './styles'
import NumberIcon from '../numberIcon'
import Analytics from '../../helpers/analytics'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { fontWeight } from '@root/shared/style/sizes'
import colors from '@root/shared/style/colors'
import get from 'lodash/get'
import { getFormattedDate } from '@lenses/utils'
import UsersIconsList from '@components/usersIconsList'
import AssignAppOwnersButton from '../assignAppOwnersButton'
import ContractImg from '@media/contract.svg'
import * as Style from '@components/appsV2/styles'

const MAX_FILES_ICONS = 5
const insideTableRowsClass = '.rt-tr:hover &'
const CSS = {
  onHover: css({
    ':hover': {
      textDecoration: 'underline'
    }
  }),
  numberIcon: css({
    margin: '12px 10px 0 0'
  }),
  nameCellContainer: css({
    '&:first-child': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block'
    }
  }),
  contractButton: css({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    gap: '4px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    fontWeight: fontWeight.semiBold,
    outline: 'none',
    [insideTableRowsClass]: {
      color: colors.blue
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }),
  contractIcon: css({
    verticalAlign: 'middle',
    fontSize: 26,
    color: '#C7C7C7',
    marginLeft: 10,
    transform: 'none'
  }),
  fileUploadCellWrapper: css({
    display: 'flex',
    alignItems: 'center',
    gap: '3px'
  })
}

const getColumnByFieldType = ({ columnsInfo, usersById, customFieldPropsByType = EMPTY_OBJECT, tableName, fieldIdentifier, assignUserCB, onEdit, contractsById, isOnlyAppOwner }) => {
  return columnsInfo.map(field => {
    const identifier = (field[fieldIdentifier]).toString()
    return {
      Header: field.name,
      accessor: (field[fieldIdentifier]).toString(),
      minWidth: 150,
      ...getPropsByType({ field, usersById, customFieldPropsByType, tableName, identifier, assignUserCB, onEdit, contractsById, isOnlyAppOwner })
    }
  })
}

const getText = (value, field) => ([].concat(value))
  .map(value => field.options ? (field.options.find(item => item.value === value) || {}).label || value : value)
  .join(', ')

const trackFileAnalytics = (tableName) => {
  const props = tableName ? { Table: tableName } : {}
  Analytics.track('Downloaded file', props)
}

const UserCell = ({ value: user }) => {
  if (!user) {
    return null
  }
  const { id, firstName, lastName, photoUrl, email } = user || {}
  const tooltipText =
    <Wrapper>
      <div>{getDisplayName(user)}</div>
      <div>{email}</div>
    </Wrapper>
  return (
    <Tooltip label={tooltipText}>
      <RelativeTeamUserLink idUser={id}>
        <Avatar
          firstName={firstName}
          lastName={lastName}
          imageUrl={photoUrl}
        />
      </RelativeTeamUserLink>
    </Tooltip>
  )
}
export const NewCurrencyCell = ({ value, format = '$0,' }) => isNumber(value) ? <Currency value={value} format={format} /> : null
export const OldCurrencyCell = ({ value }) => isNumber(value) ? <CurrencyOld value={value} /> : null
export const DatePickerCell = ({ value }) => getFormattedDate({ date: value, emptyValueDefaultDisplay: null })
const CardNumberCell = ({ value }) => value ? `XXXX XXXX XXXX ${value}` : null
const UsersCell = ({ value, row: { id }, columnProps: { rest: { field } }, usersById, assignUserCB }) => <AssignUserAppDetailButton idApp={id} user={value ? usersById[value] || value : null} idField={field.idField} label={field.name} size='small' assignUserCB={assignUserCB} />
const MultipleUsersCell = ({ value }) => value && value[0] ? <UsersIconsList users={value} maxUsers={4} /> : null
const FileUploadCell = ({ value: files, row: { idOrg } }, tableName) => {
  const renderedFiles = (files || EMPTY_ARRAY).slice(0, MAX_FILES_ICONS).map((file, index) => {
    return <Tooltip
      key={index}
      label={file.name}
    >
      <Link href={`${config.apiBaseUrl}/api/uploads/${idOrg}/${file.idUpload}`} target='_blank' onClick={() => trackFileAnalytics(tableName)}>
        <Icon name='File' />
      </Link>
    </Tooltip>
  })

  if (renderedFiles.length > MAX_FILES_ICONS) {
    renderedFiles.push(<NumberIcon key='numberIcon' size={30} {...CSS.numberIcon} number={files.length - MAX_FILES_ICONS} noBorder />)
  }

  return renderedFiles.length > 0 ? <div {...CSS.fileUploadCellWrapper}>{renderedFiles}</div> : null
}

const AppOwnersCell = ({ row, value, usersById, assignUserCB }) => {
  const { id: idApp } = row
  const existingPrimaryOwner = value ? (usersById[value] || value) : null
  return (
    <AvatarGroup max={1}>
      <AssignAppOwnersButton
        idApp={idApp}
        existingPrimaryOwner={existingPrimaryOwner}
        addButtonTooltipLabel={'Assign primary app owner'}
        onSubmitSuccess={assignUserCB} />
    </AvatarGroup>
  )
}

const ContractNameCell = ({ value, row }, onEditContract, contractsById) => {
  return (value || []).length
    ? (<div {...CSS.nameCellContainer}>
      {value.map((idContract) => (<button key={`${row.id}_${idContract}`} type='button' {...CSS.contractButton} onClick={() => onEditContract(idContract)}>
        <img alt='contract' src={ContractImg} width={24} height={24} /> {get(contractsById, [idContract, 'name'], '')}
      </button>))}
    </div>) : <></>
}

const getPropsByType = ({ field, usersById, customFieldPropsByType, tableName, identifier, assignUserCB, onEdit, contractsById, isOnlyAppOwner }) => {
  const { type, systemKey } = field
  const customPropsFunc = customFieldPropsByType[type] || (() => EMPTY_OBJECT)
  const isComplianceField = Object.values(APP_COMPLIANCE_FIELDS_SYSTEM_KEYS).includes(systemKey)
  const ComplianceCell = (value) => (value === 'Yes' ? <Style.StyledIcon name='CheckCircleFill' color='interactive' /> : '-')
  switch (type) {
    case formFieldTypes.multiLine:
    case formFieldTypes.email:
    case formFieldTypes.singleLine: {
      return {}
    }
    case formFieldTypes.multiValueString:
    case formFieldTypes.dropdown:
    case formFieldTypes.dropdownMulti: {
      return {
        id: identifier,
        accessor: (row) => row[identifier],
        Cell: ({ value }) => isComplianceField ? ComplianceCell(value) : getText(value, field),
        textValue: ({ value }) => getText(value, field),
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.number: {
      return {
        sortMethod: (a, b) => (a || 0) - (b || 0),
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.currency: {
      return {
        id: identifier,
        accessor: (row) => {
          const value = row[identifier]
          const number = isNumber(value) ? value : parseInt(value)
          return isNaN(number) ? null : number / 100
        },
        Cell: OldCurrencyCell,
        textValue: ({ value }) => isNumber(value) ? value : '',
        sortMethod: (a, b) => (a || 0) - (b || 0),
        ...Table.numericFieldProps,
        ...customPropsFunc({ field, usersById, identifier })
      }
    }
    case formFieldTypes.cardNumber: {
      return {
        Cell: CardNumberCell,
        textValue: ({ value }) => value && `XXXX XXXX XXXX ${value}`,
        width: 200,
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.datePicker: {
      return {
        id: identifier,
        accessor: (row) => row[identifier] ? moment.utc(row[identifier]).toDate().getTime() : null,
        Cell: DatePickerCell,
        textValue: ({ value }) => (value && moment.utc(value).format('YYYY-MM-DD')),
        sortMethod: Table.sortMethods.date,
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.usersDropdown: {
      let cell = UsersCell
      if (field.systemKey === APP_OWNERS_FIELDS.primary) {
        cell = AppOwnersCell
      }
      return {
        Cell: data => cell({ ...data, usersById, assignUserCB }),
        getProps: () => ({ field }),
        textValue: ({ value: idOwner }) => idOwner ? `${getDisplayName(usersById[idOwner] || {})} <${usersById[idOwner].email}>` : '',
        sortMethod: (a, b) => Table.sortMethods.user(usersById, a, b),
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.usersDropdownMulti: {
      return {
        id: identifier,
        accessor: (row) => row[identifier] && row[identifier].length ? row[identifier] : null,
        Cell: MultipleUsersCell,
        getProps: () => ({ field }),
        textValue: ({ value: users }) => (users || []).map(idOwner => idOwner ? `${getDisplayName(usersById[idOwner] || {})} <${usersById[idOwner].email}>` : '').join(', '),
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.fileUpload: {
      return {
        id: identifier,
        accessor: (row) => row[identifier],
        filterAccessor: (row) => {
          const value = row[identifier] || []
          return Array.isArray(value) ? value.length : value
        },
        Cell: props => FileUploadCell(props, tableName),
        textValue: ({ value: files }) => (files || EMPTY_ARRAY).length || '',
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.user: {
      return {
        Cell: UserCell,
        textValue: ({ value: user }) => getDisplayName(user || {}),
        sortMethod: Table.sortMethods.users,
        ...customPropsFunc({ field, usersById })
      }
    }
    case formFieldTypes.contractsDropdownMulti: {
      return {
        id: identifier,
        accessor: (row) => row[identifier] && row[identifier].length ? row[identifier] : null,
        Cell: props => ContractNameCell(props, onEdit, contractsById),
        textValue: ({ value: contracts }) => (contracts || []).map(idContract => get(contractsById, [idContract, 'name'], '')).join(', '),
        ...customPropsFunc({ field, usersById, contractsById })
      }
    }
    default: {
      return null
    }
  }
}

export default getColumnByFieldType
