import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import texts from '../../../../shared/style/texts'
import colors from '../../../../shared/style/colors'
import { TextArea } from '@toriihq/design-system'

const CSS = {
  main: css({
    marginBottom: '25px',
    position: 'relative'
  }),
  fieldContainer: css({
    display: 'flex',
    alignItems: 'center'
  }),
  label: css(texts.subheading, {
    width: '75px',
    marginRight: '26px',
    color: colors.grey1
  }),
  input: css({
    width: '307px'
  }),
  errorMessage: css(texts.body, {
    color: colors.red,
    top: '35px',
    position: 'absolute'
  }),
  showArea: css({
    marginTop: '20px'
  }),
  hideArea: css({
    display: 'none'
  })
}

class FormQuestion extends React.Component {
  render () {
    const { show, autoFocus = true, input, meta: { touched, error } } = this.props
    return <div {...CSS.main}>
      <div {...css(CSS.showArea, !show && CSS.hideArea)} >
        <TextArea
          {...input}
          name='formQuestion'
          autoFocus={autoFocus}
          resize='vertical'
        />
      </div>
      {touched && error && <div {...CSS.errorMessage}>{error}</div>}
    </div>
  }
}

FormQuestion.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  extraInfo: PropTypes.element,
  show: PropTypes.bool
}

export default FormQuestion
