import styled from 'styled-components'
import { fontWeight } from '@shared/style/sizes'

export const ActionButton = styled.div`
  margin-left: auto;
  display: none;
`

export const Wrapper = styled.div`
  padding: 0px 20px;
  &:hover ${ActionButton} {
    display: inherit;
  }
`

export const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const TextContainer = styled.div`
  padding: 0 20px;
`

export const Bold = styled.span`
  font-weight: ${fontWeight.semiBold};
`

export const Date = styled.span`
  color: ${({ theme }) => theme.palette.text.tertiary}
`
