import View from './view'
import { connect } from 'react-redux'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import { isCatalog } from '../../lenses/utils'

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps
  const query = qs.parse(location.search)

  return {
    isFromCatalog: isCatalog(query.next)
  }
}

export default withRouter(connect(mapStateToProps)(View))
