import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import Table from '../../table'
import colors from '../../../shared/style/colors'
import { scoreSpecialValues, TABLES } from '@root/constants'
import { AvatarGroup, Icon, Link, Tooltip } from '@toriihq/design-system'
import texts from '@shared/style/texts'
import { getDisplayName } from '@lenses/users'
import numeral from 'numeral'
import moment from 'moment'
import uniq from 'lodash/uniq'
import AppDetails from '@components/appDetails'
import RelativeTeamLink from '@components/relativeTeamLink'
import UsageIcon from '@components/usageIcon'
import SourceIcon from '@components/sourceIcon'
import appsToolTip from '@shared/appToolTip'
import { fontSize, fontWeight } from '@shared/style/sizes'
import Analytics from '@helpers/analytics'
import { getFormattedDate } from '@lenses/utils'
import * as Style from './styles'
import AssignAppOwnersButton from '@components/assignAppOwnersButton'

const CSS = {
  mainPopupArea: css({
    width: '100%',
    height: '476px'
  }),
  popup: {
    modal: {
      width: '1165px',
      height: '700px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  container: css({
    margin: 'auto',
    maxHeight: '90%'
  }),
  subtitle: css(texts.body, {
    marginTop: '6px'
  }),
  tableWrap: css({
    maxHeight: '505px',
    overflowY: 'auto',
    padding: '20px 20px'
  }),
  modalHeader: css({
    paddingBottom: 15
  }),
  message: css({
    color: colors.black,
    padding: '27px 50px',
    fontSize: 12
  }),
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  colorIndication: css({
    width: '10px',
    height: '10px',
    borderRadius: '10px',
    marginRight: '10px',
    flex: '0 0 10px'
  }),
  option: css({
    display: 'flex',
    alignItems: 'center',
    lineHeight: fontSize.small,
    height: '100%',
    padding: '8px 10px'
  }),
  title: css(texts.headers.regular, {
    textTransform: 'uppercase'
  }),
  table: {
    minHeight: '391px'
  }
}

CSS.appName = css({
  display: 'flex',
  alignItems: 'center',
  transition: 'color .2s',
  fontWeight: fontWeight.semiBold,
  [`.${CSS.nameAndIcon}:hover &, [data-${CSS.nameAndIcon}]:hover &`]: {
    color: colors.blue
  }
})

class CatalogAppsPopup extends Component {
  searchFilterMethod (row, search) {
    Analytics.track('Search from the "Applications in catalog" popup')

    const values = [
      row.name,
      row.category
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  getHeader = () => {
    const { apps } = this.props
    return <span>{`APPLICATIONS (${apps.length})`}</span>
  }

  getColumns () {
    const { stateOrder, stateOptions } = this.props

    return [
      {
        Header: 'Name',
        accessor: 'name',
        width: 190,
        className: CSS.nameAndIcon.toString(),
        Cell: ({ value: name, row: { id, category, imageUrl, isNew } }) => (
          <AppDetails
            id={id}
            name={name}
            category={category}
            imageUrl={imageUrl}
            isNew={isNew}
            component='Apps list'
          />
        ),
        show: true,
        style: {
          paddingLeft: '5px'
        }
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        textValue: ({ value: owner }) => getDisplayName(owner || {}),
        width: 60,
        Cell: ({ value: owner, row: { id } }) => owner && (
          <AvatarGroup max={1}>
            <AssignAppOwnersButton
              idApp={id}
              existingPrimaryOwner={owner}
              addButtonTooltipLabel={'Assign app owners'}
              onSubmitSuccess={() => null} />
          </AvatarGroup>
        ),
        sortMethod: (a, b) => Table.sortMethods.users(a, b),
        show: true
      },
      {
        Header: 'Users',
        accessor: 'activeUsersCount',
        width: 60,
        Cell: ({ value: users }) => <RelativeTeamLink key={`/users`} to={`/users`}><Link>{numeral(users).format('0')}</Link></RelativeTeamLink>,
        show: true,
        ...Table.numericFieldProps
      },
      {
        Header: 'Discovery date',
        id: 'creationTime',
        accessor: ({ creationTime }) => creationTime ? moment.utc(creationTime).toDate().getTime() : null,
        Cell: ({ row: { creationTime } }) => getFormattedDate({ date: creationTime, emptyValueDefaultDisplay: null }),
        textValue: ({ creationTime }) => (creationTime && moment.utc(creationTime).format('YYYY-MM-DD')),
        sortMethod: Table.sortMethods.date,
        minWidth: 160,
        show: true
      },
      {
        Header: (
          <Tooltip
            label={<Style.UsageLabelTooltipLabel>Usage is calculated based on visit frequency and total number of users in the last 30 days</Style.UsageLabelTooltipLabel>}
          >
            Usage
          </Tooltip>
        ),
        textHeader: 'Usage',
        textValue: ({ score }) => {
          return (score !== scoreSpecialValues.notCollectingUsage) ? score : null
        },
        accessor: 'score',
        maxWidth: 140,
        style: { display: 'flex', alignItems: 'center ' },
        Cell: ({ original: { score, isCore, lastVisitTime } }) => <UsageIcon score={score} isCore={isCore} lastVisitTime={lastVisitTime} tooltipText='on average' />,
        show: true
      },
      {
        Header: 'State',
        accessor: 'state',
        textValue: ({ state }) => state,
        Cell: ({ value: state }) => {
          const optionInfo = (stateOptions || []).find(option => state === option.label)

          return <div key={optionInfo.value} {...CSS.option}>
            <div {...CSS.colorIndication} style={{ backgroundColor: optionInfo.color }} />
            <div >{optionInfo.label}</div>
          </div>
        },
        style: { overflow: 'inherit' },
        sortMethod: (a, b) => (stateOrder[a] || 0) - (stateOrder[b] || 0),
        width: 170,
        show: true
      },
      {
        Header: 'Appears in catalog',
        id: 'appearsInCatalog',
        Cell: () => <Icon name='CheckCircleFill' color='interactive' />,
        style: { display: 'flex', alignItems: 'center' },
        width: 150,
        show: true
      },
      {
        Header: 'Sources',
        accessor: 'sources',
        textValue: ({ value: sources }) => uniq(sources).toString(),
        minWidth: 110,
        show: true,
        Cell: ({ row: { sources, name, addedBy } }) => (
          <div>
            {sources.map(source => (
              <SourceIcon isAppSource key={source} sourceType={source} tooltipText={appsToolTip({ appName: name, source, addedBy })} />
            ))}
          </div>
        )
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'addedBy',
        show: false
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'isNew',
        show: false
      },
      {
        accessor: 'name',
        show: false
      },
      {
        accessor: 'sources',
        show: false
      }
    ]
  }

  render () {
    const { cancel, isOpen, apps, loading } = this.props

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={CSS.popup} contentAreaStyle={CSS.mainPopupArea}>
        <ToriiPopup.Header header={'View applications in the catalog'} subHeader={'The following applications matched the filters and were added to the catalog'} />
        <ToriiPopup.Content>
          <Table
            tableKey={TABLES.catalogAppsPopup.key}
            style={CSS.table}
            data={apps}
            header={this.getHeader}
            columns={this.getColumns()}
            loading={loading}
            clientPaging
            pageSize={50}
            scrollObjectId='contentArea'
            searchable
            searchFilterMethod={this.searchFilterMethod}
          />
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          showCancelButton={false}
          mainButtonAction={cancel}
          mainButtonText={'Close'}
        />
      </ToriiPopup>
    )
  }
}

export default CatalogAppsPopup
