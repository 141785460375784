import styled from 'styled-components'

export const ContentAreaStyle = {
  overflowY: 'unset'
}

export const CreatableSelectOption = styled.span`
  margin-right: 8px;
  height: 16px;
`
