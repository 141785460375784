import React, { ReactElement, useEffect, useState } from 'react'
import type { PendingListResources, Props, AppAccountTasks, TasksByUniqueIdDictionary } from './types'
import { OFFBOARDING_TASKS_GROUP_TYPE } from './consts'
import PendingTasksGroup from './components/pendingTasksGroup'
import * as Style from './style'
import emptyImage from '@components/offboardingTabs/images/item-box.svg'
import { useSelector } from 'react-redux'
import { getPendingOffboardingResourcesSelector } from '@selectors/offboarding'
import { compact, isEqual, keyBy } from 'lodash'
import { EmptyState, Link } from '@toriihq/design-system'
import RelativeTeamLink from '@components/relativeTeamLink'

export const PendingTasksTab = ({
  idOrg,
  isLoading,
  tasksByAppAccount
}: Props): ReactElement => {
  const { appsById }: PendingListResources = useSelector(getPendingOffboardingResourcesSelector)
  const [sortedPendingTasksList, setSortPendingTasksList] = useState<AppAccountTasks[] | null>(null)

  useEffect(() => {
    if (isLoading) {
      return
    }

    const updateSortedPendingTasksList = () => {
      const tasksByByUniqueDictionary: TasksByUniqueIdDictionary = keyBy(tasksByAppAccount, 'uniqueId')
      const newSortedPendingTasksList = compact(sortedPendingTasksList!.map((tasksGroup) => {
        const newTasksGroup = tasksByByUniqueDictionary[tasksGroup.uniqueId]

        return newTasksGroup ? {
          ...tasksGroup,
          tasks: newTasksGroup.tasks
        } : null
      }))

      if (isEqual(newSortedPendingTasksList, sortedPendingTasksList)) {
        return
      }
      setSortPendingTasksList(newSortedPendingTasksList)
    }

    const createSortedPendingTasksList = () => {
      setSortPendingTasksList([...tasksByAppAccount].sort((taskGroupA, taskGroupB) => {
        const sizeDiff = taskGroupB.tasks.length - taskGroupA.tasks.length
        if (!sizeDiff) {
          return appsById[taskGroupA.idApp].name > appsById[taskGroupB.idApp].name ? 1 : -1
        }
        return sizeDiff
      }))
    }

    if (sortedPendingTasksList) {
      updateSortedPendingTasksList()
    } else {
      createSortedPendingTasksList()
    }
  }, [isLoading, tasksByAppAccount, sortedPendingTasksList, appsById])

  const renderEmptyState = () => {
    return <Style.EmptyState>
      <EmptyState
        image={<img src={emptyImage} alt='No pending tasks' />}
        title='All delegated tasks are complete'
        link={<RelativeTeamLink to={'/offboarding/progress/users'}><Link>View offboardings in progress</Link></RelativeTeamLink>}
      />
    </Style.EmptyState>
  }

  const renderTasks = () => {
    if (!sortedPendingTasksList?.length) {
      return renderEmptyState()
    }

    return sortedPendingTasksList.map((tasksGroup) => (
      <PendingTasksGroup
        idOrg={idOrg}
        key={`pending-task-${tasksGroup.idAppAccount}-${tasksGroup.idApp}`}
        isLoading={isLoading}
        {...tasksGroup}
      />
    ))
  }

  const renderPlaceHolders = () => (
    new Array(4).fill(0).map((value, index) => <PendingTasksGroup
      idOrg={idOrg}
      key={`pending-task-${index}`}
      appAccountName={null}
      idApp={0}
      idAppAccount={0}
      tasks={[]}
      taskType={OFFBOARDING_TASKS_GROUP_TYPE.TASKS}
      isLoading
      uniqueId={'loading-group'}
    />)
  )

  return (
    <Style.TasksGroupsContainer>
      {isLoading || !sortedPendingTasksList ? renderPlaceHolders() : renderTasks() }
    </Style.TasksGroupsContainer>
  )
}

export default PendingTasksTab
