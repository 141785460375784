import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AppName = styled.div`
  font-size: 25px;
  font-weight: 600;
`

export const Separator = styled.div`
  font-size: 25px;
`
