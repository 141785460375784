import React from 'react'
import colors from '../../../../shared/style/colors'
import texts from '../../../../shared/style/texts'
import { css } from 'glamor'
import pluralize from 'pluralize'
import RiskIcon from '../../../riskIcon'
import { fontSize } from '../../../../shared/style/sizes'

const CustomTooltip = ({ payload, active }) => {
  const CSS = {
    main: css({
      padding: '16px',
      border: `1px solid ${colors.border}`,
      borderRadius: '4px',
      backgroundColor: colors.white,
      transform: 'translateX(-90px) translateY(-20px)'
    }),
    header: css(texts.heading, {
      display: 'flex',
      width: '100px',
      color: colors.grey1,
      marginBottom: '4px',
      textTransform: 'uppercase'
    }),
    apps: css(texts.headers.regular, {
      color: colors.black,
      fontSize: fontSize.medium
    })
  }

  if (!active || !payload.length) {
    return null
  }

  const { name, value } = payload[0]

  const riskLevel = {
    Low: 1,
    Medium: 2,
    High: 3
  }[name]

  if (!riskLevel) {
    return null
  }

  return (
    <div {...CSS.main}>
      <div {...CSS.header}><RiskIcon riskLevel={riskLevel} /> {name}</div>
      <div {...CSS.apps}>{`${pluralize('app', value, true)}`}</div>
    </div>
  )
}

export default CustomTooltip
