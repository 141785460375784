import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontWeight } from '@shared/style/sizes'

const TOOLTIP_PADDING = '12px'
const SOURCE_NAME_FONT_SIZE = '10px'
const COLUMN_GAP = '4px'
const SOURCE_WIDTH = '106px'
const TOOLTIP_MAX_WIDTH = `calc(2 * ${SOURCE_WIDTH} + ${COLUMN_GAP} + ${TOOLTIP_PADDING})`

export const SourceTooltip = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: TOOLTIP_MAX_WIDTH,
  color: colors.grey1
})

export const Header = css({
  marginBottom: '12px',
  fontSize: 12,
  fontWeight: fontWeight.semiBold
})

export const SourceItem = css({
  display: 'flex',
  alignItems: 'center',
  width: SOURCE_WIDTH
})

export const SourceName = css({
  lineHeight: 'initial',
  paddingTop: '1px',
  fontSize: SOURCE_NAME_FONT_SIZE,
  fontWeight: fontWeight.normal
})

export const SourcesList = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: `6px ${COLUMN_GAP} `
})

export const SourceIcon = css({
  height: `calc(${SOURCE_NAME_FONT_SIZE} + 1px)`,
  marginRight: '5px'
})
