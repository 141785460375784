import React from 'react'
import PageHeader from '../../components/pageHeader'
import Page from '../../components/page'
import Settings from '../../components/settings'

class NotificationsPage extends React.Component {
  render () {
    return (
      <Page title='Notifications Settings'>
        <PageHeader title='Notifications Settings' />
        <Settings />
      </Page>
    )
  }
}

export default NotificationsPage
