import React from 'react'
import moment from 'moment'
import CreditCard from './creditCard'
import { WIDGET_FIELD_TYPES } from '@root/widgets/constants'
import { Card, Link as CustomWidgetLink, User } from '@root/widgets/customWidget/types'
import OverflowTooltip from '@components/overflowTooltip'
import Currency, { CurrencyFormat } from '@components/currency'
import { DATE_FORMAT } from '@root/constants'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import UsersIconsList from '@components/usersIconsList'
import * as Style from '@root/widgets/customWidget/style'
import { Link } from '@toriihq/design-system'

const FieldValue = ({ type, value }: { type: typeof WIDGET_FIELD_TYPES[keyof typeof WIDGET_FIELD_TYPES], label?: string, value: string | User | User[] | CustomWidgetLink | Card }) => {
  const getValueByType = () => {
    switch (type) {
      case WIDGET_FIELD_TYPES.singleLine:
      case WIDGET_FIELD_TYPES.multiLine:
      case WIDGET_FIELD_TYPES.email:
      case WIDGET_FIELD_TYPES.number:
        return <OverflowTooltip label={value}>{value}</OverflowTooltip>
      case WIDGET_FIELD_TYPES.currency: {
        const currency = value as string
        const currencySymbol = currency.slice(0, 1)
        const amount = currency.slice(1)
        return <Currency value={Number(amount)} format={CurrencyFormat.FLOAT} currencySymbol={currencySymbol} />
      }
      case WIDGET_FIELD_TYPES.datePicker: {
        const date = value as string
        return moment(date).format(DATE_FORMAT)
      }
      case WIDGET_FIELD_TYPES.usersDropdown: {
        const user: User = value as User
        return user?.email ? <RelativeTeamUserLink idUser={user.id}>{user.email}</RelativeTeamUserLink> : value
      }
      case WIDGET_FIELD_TYPES.usersDropdownMulti: {
        const users: User[] = value as User[]
        return users.length ? <UsersIconsList users={users} maxUsers={4} /> : value
      }
      case WIDGET_FIELD_TYPES.deepLink:
        const link: CustomWidgetLink = value as CustomWidgetLink
        return <Link href={link.href} target='_blank'>{link.label}</Link>
      case WIDGET_FIELD_TYPES.horizontalRule:
        return <hr />
      case WIDGET_FIELD_TYPES.creditCard:
        return <CreditCard card={value as Card} />
      default:
        return value === undefined ? '-' : value
    }
  }

  return <Style.Value>{getValueByType()}</Style.Value>
}

export default FieldValue
