import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { Icon, Tooltip } from '@toriihq/design-system'
import {
  Col,
  CoreRow,
  ExplanationArea,
  ExplanationHeader,
  ExplanationCol,
  ExplanationRow,
  ExplanationTable,
  InfoIconWrapper,
  ListItem,
  MainArea,
  ObjectsRow,
  Popup,
  InnerCol,
  TitleRow,
  UnorderedObjectList
} from './style'

const SalesforceUsageExplanationPopup = ({ isOpen, onClose }) => {
  const coreSalesforceObjects = ['Accounts', 'Contacts', 'Custom Tabs', 'Documents']
  const salesforceCRMObjects = ['Cases', 'Contracts', 'Dashboards', 'Leads', 'Opportunities', 'Reports', 'Tasks']

  const renderHeader = () => {
    return (
      <div>
        <div>Learn how Torii maps usage to license tiers</div>
      </div>)
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={() => onClose()} styles={Popup}>
      <ToriiPopup.Header header={renderHeader()} overrideStyle={{ padding: 30 }} />
      <ToriiPopup.Content contentAreaStyle={MainArea}>
        <ExplanationHeader>Torii identifies users who only used core platform functionality and recommends downgrading these users to Salesforce Platform licenses instead.</ExplanationHeader>
        <ExplanationArea>
          <ExplanationTable>
            <TitleRow>
              <Col sm={3} />
              <InnerCol sm={3}>
                <p>Salesforce Platform</p>
              </InnerCol>
              <InnerCol sm={3}>
                <p>Service Cloud</p>
              </InnerCol>
              <InnerCol sm={3}>
                <p>Sales Cloud</p>
              </InnerCol>
            </TitleRow>
            <ExplanationRow>
              <ExplanationCol sm={12}>
                <div>Core platform functionality</div>
                <Tooltip
                  placement='top'
                  maxWidth='280'
                  label='Viewing, editing or adding core standard Salesforce objects only requires a Salesforce Platform license.'
                >
                  <InfoIconWrapper>
                    <Icon name='Info' />
                  </InfoIconWrapper>
                </Tooltip>
              </ExplanationCol>
            </ExplanationRow>
            <CoreRow>
              <Col sm={3}>
                <UnorderedObjectList>
                  {coreSalesforceObjects.map(coreSalesfoceObj => <ListItem key={coreSalesfoceObj}>{coreSalesfoceObj}</ListItem>)}
                </UnorderedObjectList>
              </Col>
              <InnerCol sm={3}>
                <Icon name='CheckCircleFill' color='interactive' />
              </InnerCol>
              <InnerCol sm={3}>
                <Icon name='CheckCircleFill' color='interactive' />
              </InnerCol>
              <InnerCol sm={3}>
                <Icon name='CheckCircleFill' color='interactive' />
              </InnerCol>
            </CoreRow>
            <ExplanationRow>
              <ExplanationCol sm={12}>
                <div>Common Salesforce CRM objects</div>
                <Tooltip
                  placement='top'
                  maxWidth='320'
                  label='Viewing, editing or adding Salesforce CRM objects require a high tier license such as Sales Cloud, Service Cloud or Marketing Cloud.'
                >
                  <InfoIconWrapper>
                    <Icon name='Info' />
                  </InfoIconWrapper>
                </Tooltip>
              </ExplanationCol>
            </ExplanationRow>
            <ObjectsRow>
              <Col sm={3}>
                <UnorderedObjectList>
                  {salesforceCRMObjects.map(salesforceCRMObj => <ListItem key={salesforceCRMObj}>{salesforceCRMObj}</ListItem>)}
                </UnorderedObjectList>
              </Col>
              <InnerCol sm={3} />
              <InnerCol sm={3}>
                <Icon name='CheckCircleFill' color='interactive' />
              </InnerCol>
              <InnerCol sm={3}>
                <Icon name='CheckCircleFill' color='interactive' />
              </InnerCol>
            </ObjectsRow>
          </ExplanationTable>
        </ExplanationArea>
      </ToriiPopup.Content>
    </ToriiPopup>
  )
}

export default SalesforceUsageExplanationPopup
