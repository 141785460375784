import React from 'react'
import PropTypes from 'prop-types'
import AccessControl from '@lenses/accessControl'
import SuggestToUpgrade from '@components/suggestToUpgrade'

const VisibleFor = (props) => {
  const { scopes = [], feature, children, fallback = <React.Fragment />, fallbackToSuggestToUpgrade } = props

  if (children.length > 1) {
    throw new Error(`VisibleFor element should contain only one direct child element`)
  }

  const isVisibleForScopes = AccessControl.useIsAccessible({ scopes })
  const isVisibleForFeature = AccessControl.useIsFeatureEnabledInPlan({ feature })

  if (isVisibleForScopes && isVisibleForFeature) {
    return children
  }

  if (!isVisibleForFeature && isVisibleForScopes && fallbackToSuggestToUpgrade && feature) {
    return <SuggestToUpgrade feature={feature} topSpace={8} />
  }

  return fallback
}

VisibleFor.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string),
  feature: PropTypes.string,
  children: PropTypes.node,
  fallback: PropTypes.node,
  fallbackToSuggestToUpgrade: PropTypes.bool
}

export default VisibleFor
