import LicensesRecommendationDrillDown from '@root/components/licensesRecommendations/licenseRecommendationDrilldown'
import Page from '@root/components/page'
import React from 'react'
import { withRouter } from 'react-router-dom'

const RecommendationPage = withRouter(({ match }) => {
  const idOrg = parseInt(match.params.idOrg, 10)
  const idRecommendation = parseInt(match.params.idRecommendation, 10)

  return (
    <Page title='Recommendation drill down'>
      <LicensesRecommendationDrillDown idOrg={idOrg} idRecommendation={idRecommendation} />
    </Page>
  )
})

export default RecommendationPage
