import { useEffect } from 'react'

// React hook for delaying calls with time
// returns callback to use for cancelling

/*
  @callback timeOutCallback
  @param {timeOutCallback} callback - function to call. No args passed.
  @param {number} timeout - delay, ms (default: immediately put into JS Event Queue)
  @param {object} options - { persistRenders }

  persistRenders - manage re-render behavior.
  by default, a re-render in your component will re-define the callback,
  which will cause this timeout to cancel itself.
  to avoid cancelling on re-renders (but still cancel on unmounts),
  set `persistRenders: true,`.
  default persistRenders = false
*/

const useTimeout = (callback, timeout = 0, { persistRenders = false } = {}) => {
  let timeoutId
  const cancel = () => timeoutId && clearTimeout(timeoutId)

  useEffect(
    () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(callback, timeout)
      return cancel
    },
    persistRenders
      ? [setTimeout, clearTimeout]
      : [callback, timeout, setTimeout, clearTimeout]
  )

  return cancel
}

export default useTimeout
