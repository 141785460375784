import { AvailableRule } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'

export const getRule = ({ appRule, isEnabled }: { appRule: AvailableRule, isEnabled: boolean }) => {
  const { idApp, name: appName, mergeUsersFieldName } = appRule
  return [
    {
      name: 'rule',
      options: [
        {
          value: idApp,
          label: appName,
          description: `Merged based on data found in the “${mergeUsersFieldName}” field`,
          isDisabled: !isEnabled
        }
      ]
    }
  ]
}
