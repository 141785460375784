import styled from 'styled-components'

export const DescriptionLine = styled.div`
  padding-top: 10px;
`

export const DescriptionText = styled.span`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const SelectSlot = styled.div`
  padding-bottom: 24px;
`

export const SelectLabel = styled.div`
  padding-bottom: 6px;
  font: ${({ theme }) => theme.typography.font.subtitle02};
  color: ${({ theme }) => theme.palette.text.primary};
`
