import React from 'react'
import PropTypes from 'prop-types'
import NoResults from '@media/no-results.svg'
import { EmptyState } from '@toriihq/design-system'

const NoResultsFound = ({ search }) => {
  return <EmptyState
    image={<img src={NoResults} alt='No results found' />}
    description={`No results found for "${search}"`}
  />
}

NoResultsFound.propTypes = {
  search: PropTypes.string.isRequired
}

export default React.memo(NoResultsFound)
