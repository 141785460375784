import { Edge } from 'reactflow'
import { WORKFLOW_UI_EDGE_TYPE } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'

interface GetEdgeParams {
  source: string
  target: string
}

export const getEdge = ({ source, target }: GetEdgeParams): Edge => ({
  type: WORKFLOW_UI_EDGE_TYPE.DEFAULT_EDGE,
  id: `e${source}-${target}`,
  source,
  target
})

export const getEdgeWithAddButton = ({ source, target }: GetEdgeParams): Edge => ({
  ...getEdge({ source, target }),
  type: WORKFLOW_UI_EDGE_TYPE.EDGE_WITH_ADD_BUTTON
})
