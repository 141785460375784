export const updateActionFieldValue = (action, value, fieldId, isValid) => {
  const existingField = action.fields.find(field => field.id === fieldId)
  if (existingField) {
    existingField.value = value
    existingField.isValid = isValid
  } else {
    action.fields = [...action.fields, { id: fieldId, value, isValid }]
  }

  return {
    ...action
  }
}
