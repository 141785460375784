import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import pluralize from 'pluralize'
import defer from 'lodash/defer'
import colors from '../../../shared/style/colors'
import { Tooltip } from '@toriihq/design-system'

const CSS = {
  main: css({
    display: 'flex',
    alignItems: 'center'
  }),
  bar: css({
    position: 'relative',
    display: 'flex',
    verticalAlign: 'middle',
    overflow: 'hidden',
    borderRadius: '2px',
    height: '11px',
    margin: '5px 0'
  }),
  progress: css({
    transition: 'width .3s ease-in-out',
    height: '11px'
  }),
  managed: css({
    backgroundColor: colors.lightBlue1
  }),
  unmanaged: css({
    backgroundColor: colors.error
  })
}

class SSOAuditBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      rendered: false
    }

    this.initialAnimation = this.initialAnimation.bind(this)
  }

  componentDidMount () {
    defer(this.initialAnimation)
  }

  initialAnimation () {
    this.setState({
      rendered: true
    })
  }

  render () {
    const { rendered } = this.state
    const { unmanaged, managed, barWidth } = this.props
    const total = unmanaged + managed
    const managedWidth = (managed / total) * barWidth
    const unmanagedWidth = (unmanaged / total) * barWidth
    return (
      <div {...CSS.main}>
        <div {...CSS.bar}>
          <Tooltip
            label={`${pluralize('Managed users', managed, true)}`}
          >
            <div {...css(CSS.progress, CSS.managed)} style={{ width: `${rendered ? managedWidth : 0}px` }} />
          </Tooltip>
          <Tooltip
            label={`${pluralize('Unmanaged users', unmanaged, true)}`}
          >
            <div {...css(CSS.progress, CSS.unmanaged)} style={{ width: `${rendered ? unmanagedWidth : 0}px` }} />
          </Tooltip>
        </div>
      </div>
    )
  }
}

SSOAuditBar.propTypes = {
  unmanaged: PropTypes.number,
  managed: PropTypes.number,
  barWidth: PropTypes.number
}

export default SSOAuditBar
