import React from 'react'
import texts from '../../../shared/style/texts'
import { css } from 'glamor'
import { Button, RadioButton, Link, Icon } from '@toriihq/design-system'
import colors from '../../../shared/style/colors'
import Select from '../../select'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import omit from 'lodash/omit'
import { UploadExpensesPopupContext } from '@root/components/popups/uploadExpensesPopup/uploadExpensesPopupContext'

const CSS = {
  main: css({
    background: colors.white,
    padding: '0 0 10px'
  }),
  body: css(texts.body, {
    height: 300
  }),
  fileNameContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: fontSize.small,
    marginBottom: '20px'
  }),
  input: css({
    marginBottom: '24px',
    display: 'flex',
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semiBold,
    width: '100%',
    justifyContent: 'space-between',
    ':last-child': {
      marginBottom: '0'
    }
  }),
  currencyInput: css({
    flexDirection: 'column'
  }),
  footer: css(texts.body, {
    borderTop: `1px solid ${colors.border}`,
    padding: '22px 30px 0 22px',
    display: 'flex',
    alignItems: 'center',
    ' Button': {
      marginLeft: 'auto'
    }
  }),
  currencyListItem: css({
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':last-child': {
      marginBottom: '0'
    }
  }),
  currencyList: css({
    width: '100%',
    padding: '0'
  }),
  errorMessage: css(texts.body, {
    color: colors.red,
    fontWeight: fontWeight.bold
  })
}

const DATE_FORMATS = ['DD/MM/YYYY', 'MM/DD/YYYY', 'DD/MM/YY', 'MM/DD/YY', 'YYYY/MM/DD', 'DD.MM.YYYY', 'MM.DD.YYYY', 'DD.MM.YY', 'MM.DD.YY', 'YYYY.MM.DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'DD-MM-YY', 'MM-DD-YY', 'YYYY-MM-DD', 'YYYY-MM-DD[Z]', 'YYYYMMDD']
  .map(format => ({ label: format, value: format }))

const COLUMNS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(char => ({ label: char, value: char }))
const FIELDS = [
  { id: 'transactionDateColumn', label: 'Transaction Date Column', values: COLUMNS },
  { id: 'dateFormat', label: 'Date Format', values: DATE_FORMATS },
  { id: 'descriptionColumn', label: 'Description / App Name Column', values: COLUMNS },
  { id: 'amountColumn', label: 'Amount Column', values: COLUMNS },
  { id: 'reportingUserColumn', label: 'Reporting user (optional)', values: COLUMNS }
]

class ManualMatch extends React.Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.renderCurrencyOptions = this.renderCurrencyOptions.bind(this)

    this.state = {
      transactionDateColumn: 'B',
      descriptionColumn: 'A',
      amountColumn: 'C',
      dateFormat: 'MM/DD/YYYY',
      currencyValue: 'USD',
      currencyColumnValue: 'D',
      currencyOption: 'currency',
      reportingUserColumn: 'E'
    }
  }

  componentDidMount () {
    this.context.action.current = this.handleSubmit
  }

  handleSubmit () {
    const { onSubmit } = this.props
    const parseConfig = { ...omit(this.state, ['currencyValue', 'currencyColumnValue', 'currencyOption']) }
    parseConfig[this.state.currencyOption] = this.state[this.state.currencyOption + 'Value']
    onSubmit({ parseConfig })
  }

  onChange ({ fieldId, value }) {
    this.setState({ [fieldId]: value })
  }

  renderCurrencyOptions () {
    const currencies = this.props.currencies
      .map(currency => ({ label: currency, value: currency }))
    const currencyInputTypes = [
      { id: 'currency', values: currencies, label: 'One currency for all' },
      { id: 'currencyColumn', values: COLUMNS, label: 'Currency columns' }
    ]

    return (
      <div {...css(CSS.input, CSS.currencyInput)} >
        <span>Currency</span>
        <ul {...CSS.currencyList}>
          {
            currencyInputTypes.map(currencyInputType => (
              <li {...CSS.currencyListItem} key={currencyInputType.id}>
                <RadioButton onChange={() => this.setState({ currencyOption: currencyInputType.id })} checked={this.state.currencyOption === currencyInputType.id} label={`${currencyInputType.label}:`} labelSize='small' />
                <Select
                  options={currencyInputType.values}
                  value={this.state[`${currencyInputType.id}Value`]}
                  clearable={false}
                  openOnFocus
                  disabled={this.state.currencyOption !== currencyInputType.id}
                  onChange={(selected) => {
                    const value = (selected && selected.value)
                    this.onChange({ value, fieldId: `${currencyInputType.id}Value` })
                  }}
                />
              </li>
            ))
          }
        </ul>
      </div>
    )
  }

  renderFields () {
    return (
      FIELDS.map(field => {
        return (
          <div {...CSS.input} key={field.id}>
            <span>{field.label}</span>
            <Select
              options={field.values}
              value={this.state[field.id]}
              clearable={false}
              openOnFocus
              onChange={(selected) => {
                const value = (selected && selected.value)
                this.onChange({ value, fieldId: field.id })
              }}
            />
          </div>
        )
      })
    )
  }

  render () {
    const { style, filename, showManualMatchErrorMessage, isInsidePopup } = this.props
    return (
      <div {...CSS.main} style={style}>
        <div {...CSS.body}>
          {showManualMatchErrorMessage && <div {...CSS.errorMessage} >
            Matching failed. Please check the columns matching and try again or contact our support at <Link href='mailto:support@toriihq.com'>support@toriihq.com</Link>
          </div>}
          <div {...CSS.fileNameContainer}>
            <Icon name='File' />
            {filename}
          </div>
          <div {...CSS.inputContainer}>
            {this.renderFields()}
            {this.renderCurrencyOptions()}
          </div>
        </div>
        {!isInsidePopup && <footer {...CSS.footer}>
          <Button recoveryTime={0} onClick={this.handleSubmit} label='Finish' />
        </footer> }
      </div>
    )
  }
}

ManualMatch.contextType = UploadExpensesPopupContext

export default ManualMatch
