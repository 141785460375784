import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'

export const getInsightsStats = createSelector(
  state => get(state, ['stats', 'insights', 'data'], {}),
  identity
)

export const isLoadingInsightsStats = createSelector(
  state => get(state, ['stats', 'insights', 'loading'], false),
  identity
)

export const isLoadedInsightsStats = createSelector(
  state => get(state, ['stats', 'insights', 'isLoaded'], false),
  identity
)
