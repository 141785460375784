import { EMPTY_ACTIONS_MODEL } from '@shared/workflows/actions/consts'
import {
  Action,
  IdWorkflowNode,
  WORKFLOW_NODE_TYPE,
  WorkflowActionsModel,
  WorkflowNodeModel,
  Workflow
} from '@shared/workflows/types'

interface Props {
  workflow: Workflow
  idNode: IdWorkflowNode
  updatedContinueOnError: boolean
}

export const updateWorkflowActionContinueOnError = ({ workflow, idNode, updatedContinueOnError }: Props): Workflow => {
  const actions = workflow.actions ?? EMPTY_ACTIONS_MODEL
  const actionNode: WorkflowNodeModel = actions.nodes[idNode]

  if (actionNode.type !== WORKFLOW_NODE_TYPE.ACTION) {
    throw new Error('Continue on error is supported only for `action` nodes')
  }

  const updatedAction: Action = {
    ...actionNode.action,
    continueOnError: updatedContinueOnError
  }

  const updatedActions: WorkflowActionsModel = {
    ...actions,
    nodes: {
      ...actions.nodes,
      [idNode]: {
        ...actionNode,
        action: updatedAction
      }
    }
  }

  return { ...workflow, actions: updatedActions }
}
