import colors from '@shared/style/colors'
import { css } from 'glamor'
import { fontWeight } from '@shared/style/sizes'
import texts from '@shared/style/texts'
import styled from 'styled-components'

export const AppsColumn = css({ display: 'flex', alignItems: 'center' })

export const AppIcon = css({
  border: `1px solid ${colors.border}`,
  borderRadius: '2px',
  transition: 'border-color .2s',
  ':hover': {
    borderColor: colors.blue
  }
})

export const NoRecommendationsState = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
})

export const EmptyStateText = css(texts.subheading, {
  fontWeight: fontWeight.normal,
  marginTop: '21px'
})

export const AppName = css({
  marginLeft: 11,
  fontWeight: fontWeight.semiBold
})

export const AppsSeparator = css({
  margin: '0 30px',
  fontWeight: fontWeight.semiBold
})

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
export const Name = styled.div`
  font-weight: 600;
`
export const Separator = styled.div`
  font-weight: 600;
`
