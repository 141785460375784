import React, { Fragment } from 'react'
import { Row, Col, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import texts from '../../../shared/style/texts'
import UserDetails from '../userDetails'
import moment from 'moment'
import EnableFor from '../../../components/enableFor'
import { DATE_FORMAT, SCOPES } from '../../../constants'
import { getDisplayName } from '../../../lenses/users'
import VisibleFor from '@root/components/visibleFor'

class OffboardedUser extends React.Component {
  render () {
    const { user, reopen, audit, startedByUser } = this.props
    const startedBy = startedByUser ? getDisplayName(startedByUser) : user.offboardingStartedByIdWorkflowAction ? 'Workflow' : null
    const startedByMessage = startedBy ? <span>Offboarding started By <strong>{startedBy}</strong> on <strong>{moment(user.offboardingStartTime).format(DATE_FORMAT)}</strong></span> : ''
    const offboardedAudit = audit ? <span>Marked as completed by <strong>{audit.performedBy}</strong> on <strong>{moment(audit.date).format(DATE_FORMAT)}</strong></span> : ''
    return (
      <Fragment>
        <Row>
          <Col>
            <UserDetails user={user} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div {...texts.subheading}>{startedByMessage}</div>
            <div {...texts.subheading}>
              {offboardedAudit}{' '}
              <VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
                <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                  <Button type={ButtonType.compact} size={ButtonSize.small}
                    onClick={() => reopen()}
                    label='Re-open'
                  />
                </EnableFor>
              </VisibleFor>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default OffboardedUser
