import React from 'react'
import EmailForm from '../../emailForm'
import Analytics from '@helpers/analytics'

class TrialSignup extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (email) {
    const { trialSignup } = this.props

    const queryParams = new URLSearchParams(this.props.location.search)
    const utmParams = {
      'utm_source': queryParams.get('utm_source'),
      'utm_medium': queryParams.get('utm_medium'),
      'utm_campaign': queryParams.get('utm_campaign'),
      'utm_content': queryParams.get('utm_content'),
      'utm_starting_url': document.referrer
    }

    await trialSignup(email, utmParams)

    Analytics.track('Requested to signup for trial', {
      'Email': email,
      ...utmParams
    })
  }

  render () {
    const { loginInfo } = this.props
    return <EmailForm
      header='Start your 14 day free trial'
      buttonLabel='Start trial'
      loginInfo={loginInfo}
      onSubmit={this.handleSubmit}
      backToLoginText='Already have an account?'
      onSuccessMsg='Please check your email to activate your account.'
      onAuthenticatedMsg='You are already logged in. Redirecting...'
    />
  }
}

export default TrialSignup
