import React from 'react'
import { Styles } from './style'

const PluginDetail = ({ label, value }: { label: string, value: any }) => {
  return (
    <Styles.PluginDetail>
      <Styles.PluginDetailLabel>{label}</Styles.PluginDetailLabel>
      <Styles.PluginDetailValue>{value}</Styles.PluginDetailValue>
    </Styles.PluginDetail>
  )
}

export default PluginDetail
