import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import ComputerScreen from '@media/computer_screen.svg'
import { Button, ButtonType, ButtonSize, Tooltip, Icon } from '@toriihq/design-system'

const CSS = {
  main: css(texts.body, {
    background: colors.darkBlue,
    color: colors.white,
    '&:hover .actions': {
      opacity: 1
    },
    display: 'flex'
  }),
  container: css({
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    height: '50px',
    padding: '0 25px',
    ' a, a:hover': {
      textDecoration: 'none',
      color: colors.white
    },
    ' a': {
      opacity: 1,
      transition: 'opacity 0.2s'
    },
    ' a:hover': {
      opacity: 0.9
    },
    ' > span > img': {
      marginRight: '10px'
    }
  }),
  containerSmallScreen: css({
    padding: '5px 20px',
    flexWrap: 'wrap'
  }),
  actions: css({
    opacity: 0
  }),
  navigationButtonsContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  hiddenWorkflowContainer: css({
    marginLeft: '20px'
  }),
  scopesIcon: css({
    position: 'relative',
    top: '6px',
    paddingLeft: '5px'
  })
}

class DemoBar extends React.Component {
  changeUnsafeMode = (unsafeMode) => {
    const { toggleUnsafeMode } = this.props

    toggleUnsafeMode({ unsafeMode })
  }

  changeWorkflowHiddenMode = (showHiddenWorkflows) => {
    const { toggleShowHiddenWorkflows } = this.props

    toggleShowHiddenWorkflows({ showHiddenWorkflows })
  }

  render () {
    const { isSmallScreen, style, companyName, unsafeMode, isWorkflowPage, showHiddenWorkflows, showActions, onClose, isUserOwnOrg, isPreviewMode, toriiAdminScopes } = this.props

    const containerStyle = css(
      CSS.container,
      isSmallScreen && CSS.containerSmallScreen
    )

    const calculatedStyle = unsafeMode ? { background: colors.red } : {}

    return (
      <div data-component='DemoBar' {...css(CSS.main, style, calculatedStyle)}>
        <div {...containerStyle}>
          <span>
            {!isSmallScreen && <img src={ComputerScreen} alt='Computer screen' />}
            Showing <strong>{companyName}</strong> data
            <Tooltip label='Scopes list' description={<div>{toriiAdminScopes.map((scope, i) => <div key={i}>{scope}</div>)}</div>} >
              <span {...CSS.scopesIcon}>
                <Icon name='Info' color='onColor' />
              </span>
            </Tooltip>
          </span>

          {showActions && <div className='actions' {...CSS.actions}>
            {unsafeMode && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.changeUnsafeMode(false)} label='Change to "Safe mode"' />}
            {!isPreviewMode && !unsafeMode && <Button type={ButtonType.compactDestructive} size={ButtonSize.small} onClick={() => this.changeUnsafeMode(true)} label='Change to "Unsafe mode"' />}
            <span {...CSS.hiddenWorkflowContainer}>
              {isWorkflowPage && showHiddenWorkflows &&
              <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.changeWorkflowHiddenMode(false)} label='Hide hidden workflows' />}
              {isWorkflowPage && !showHiddenWorkflows &&
              <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.changeWorkflowHiddenMode(true)} label='Show hidden workflows' />}
            </span>
          </div>}

          <div {...CSS.navigationButtonsContainer}>
            {!isUserOwnOrg && <a href='/team'>Go to your team’s view</a>}
            {!unsafeMode &&
              <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={onClose} icon='Close' /> }
          </div>
        </div>
      </div>
    )
  }
}

DemoBar.propTypes = {
  isSmallScreen: PropTypes.bool,
  style: PropTypes.object,
  companyName: PropTypes.string,
  onClose: PropTypes.func,
  isUserOwnOrg: PropTypes.bool
}

export default DemoBar
