import React, { ReactElement } from 'react'
import { Button, ButtonType, ButtonSize, IconName, useDropzone } from '@toriihq/design-system'
import { convertFileTypesToAccept } from '@shared/utils'
import EnableFor from '@components/enableFor'

interface Props {
  onFileSelect: (file: File) => void
  types?: string[]
  icon?: IconName
  label?: string
  scopes: string[]
}

const FileUploadButton = ({ label, icon, onFileSelect, types = [], scopes }: Props): ReactElement => {
  const handleChange = (files: File[]) => {
    const file = files[0]
    if (file) {
      onFileSelect(file)
    }
  }

  const { getRootProps } = useDropzone({
    onDrop: handleChange,
    accept: convertFileTypesToAccept(types)
  })

  return (
    <EnableFor scopes={scopes}>
      <Button {...getRootProps()} type={ButtonType.compact} size={ButtonSize.small} htmlButtonType='button' icon={icon} label={label} />
    </EnableFor>
  )
}

export default FileUploadButton
