import React, { ReactElement, useEffect, useState } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useDispatch, useSelector } from 'react-redux'
import { getShareReportPopup } from '@selectors/ui'
import {
  getWorkflowsActionsConfig,
  toggleShareReportPopup
} from '@actions/'
import { WORKFLOW_ACTION_TYPES } from '@root/constants'
import Placeholder from '@components/placeholder'
import EditWorkflowAction from '@components/popups/configureAppForOffboardingPopup/editWorkflowAction'
import {
  getActionsConfigByType,
  getFieldsDefaultValues,
  getFlattenActionsConfig
} from '@selectors/workflows'
import { getIdOrg } from '@selectors/org'
import { updateActionFieldValue } from '@shared/workflows/updateActionFieldValue'
import { Styles } from '@components/popups/shareReportPopup/style'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import { type Dictionary } from 'lodash'
import { ActionShareReport, InputSchemaFieldWithValue } from './types'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { createReport } from '@actions/reports/reports'
import { toast, ToastType } from '@toriihq/design-system'
import { buildCron, createAction } from '@components/popups/shareReportPopup/utils'
import { REPORT_KEY, SCHEDULE_FREQUENCY } from '@actions/reports/reports.types'

const ShareReportPopup = (): ReactElement => {
  const dispatch = useDispatch()
  const actionType = WORKFLOW_ACTION_TYPES.SHARE_REPORT

  const idOrg = useSelector(getIdOrg)
  const { isOpen, config, reportName, reportKey }: { isOpen: boolean, config: Record<string, any>, reportName: string, reportKey: REPORT_KEY } = useSelector(getShareReportPopup)
  const actionsConfig = useSelector(getFlattenActionsConfig)
  const fieldsDefaultValues = useSelector(getFieldsDefaultValues)
  const actionsConfigByType = useSelector(getActionsConfigByType)

  const [action, setAction] = useState<ActionShareReport>()

  useEffect(() => {
    if (idOrg) {
      dispatch(getWorkflowsActionsConfig(idOrg))
    }
  }, [dispatch, idOrg])

  useEffect(() => {
    const fieldsToValue = {
      'Report name': reportName
    }

    if (!isEmpty(actionsConfig)) {
      const newAction = createAction({ actionType, actionsConfig, fieldsDefaultValues, actionsConfigByType, fieldsToValue })
      setAction(newAction)
    }
  }, [actionsConfig, actionType, actionsConfigByType, fieldsDefaultValues, reportName])

  const closePopup = () => {
    dispatch(toggleShareReportPopup({ isOpen: false, config: {}, reportName: '' }))
  }

  const handleCancel = () => {
    closePopup()
  }

  const handleSubmit = () => {
    const fieldsById: Dictionary<InputSchemaFieldWithValue> = keyBy(action?.fields, 'id')
    const reportName = fieldsById.reportName.value
    const sharingMethod = fieldsById.shareMethod.value.value
    const messageDetails = sharingMethod === 'slack' ? {
      sharingMethod,
      account: fieldsById.account.value,
      users: fieldsById.users.value,
      channels: fieldsById.channels.value,
      messageTitle: fieldsById.messageTitle.value,
      messageText: fieldsById.messageText.value
    } : {
      sharingMethod,
      to: fieldsById.to.value,
      cc: fieldsById.cc.value,
      subject: fieldsById.subject.value,
      body: fieldsById.content.value
    }

    const scheduleFrequency = fieldsById.shareOption.value.value === SCHEDULE_FREQUENCY.ONCE ? SCHEDULE_FREQUENCY.ONCE : fieldsById.scheduleFrequency.value.value
    const payload = {
      reportKey,
      reportName,
      config,
      messageDetails,
      scheduleFrequency,
      ...scheduleFrequency === SCHEDULE_FREQUENCY.ONCE ? {} : {
        timeZone: fieldsById.timeZone.value.value,
        cron: buildCron({
          scheduleFrequency,
          dayOfMonth: fieldsById.dayOfMonth.value.value,
          dayOfWeek: fieldsById.dayOfWeek.value.value,
          timeOfDay: fieldsById.timeOfDay.value.value
        })
      }
    }

    dispatch(createReport({ idOrg, payload }))
    toast({
      message: 'Report scheduled successfully',
      type: ToastType.SUCCESS
    })
    closePopup()
  }

  const handleFieldChange = ({ selectedValue, fieldId }) => {
    const isValid = true
    const updatedAction = updateActionFieldValue(action, selectedValue, fieldId, isValid)
    setAction(updatedAction)
  }

  const isDataValid = (): boolean => {
    if (!action?.type) {
      return false
    }

    let isDataValid = true
    const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === action.type) || { inputSchema: [] }

    Object.keys(actionConfig.inputSchema).forEach(fieldId => {
      const fieldConfig = actionConfig.inputSchema[fieldId]
      const fieldInAction = action.fields.find(field => field.id === fieldConfig.id)

      let isHiddenField = false
      if (fieldConfig.showOnFieldSpecificValue) {
        const dependsOnField = action.fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
        const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField?.value
        isHiddenField = dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
      }

      if (!isHiddenField && fieldConfig.validations.includes('required') && (!fieldInAction?.value || (isArray(fieldInAction.value) && fieldInAction.value.length === 0))) {
        isDataValid = false
      }

      if (!isHiddenField && fieldConfig.validations.includes('notEmptyArray') && (!fieldInAction?.value || fieldInAction.value.length === 0)) {
        isDataValid = false
      }
    })

    return isDataValid
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={handleCancel}
    >
      <ToriiPopup.Header header={'Share as report'} />
      <ToriiPopup.Content contentAreaStyle={{ paddingBottom: '0px' }}>
        <div>Share {reportName} as a CSV report</div>
        <Placeholder loading={!action?.type} rows={20} type={'text'} style={Styles.placeholderStyle}>
          <EditWorkflowAction action={action} onChange={handleFieldChange} noBorder />
        </Placeholder>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={handleCancel}
        cancelButtonText='Cancel'
        mainButtonAction={handleSubmit}
        mainButtonText='Share'
        isMainButtonDisabled={!isDataValid()}
      />
    </ToriiPopup>
  )
}

export default ShareReportPopup
