import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontWeight } from '@shared/style/sizes'

export const Page = css({
  minHeight: '100%',
  minWidth: '100%',
  background: colors.white,
  display: 'flex',
  flex: 1
})

export const Main = css({
  minHeight: '100%',
  minWidth: '100%',
  background: colors.white,
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center'
})

export const Card = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 'auto',
  textAlign: 'center',
  padding: '40px',
  marginTop: '100px'
})

export const Header = css({
  marginTop: '20px',
  color: colors.black,
  fontSize: '30px',
  lineHeight: '40px',
  fontWeight: fontWeight.bold
})

export const Info = css({
  color: colors.grey1,
  fontSize: '16px',
  lineHeight: '30px',
  fontWeight: fontWeight.semiBold
})
