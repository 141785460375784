import styled from 'styled-components'

export namespace Styles {
  export const PluginContentHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  `

  export const PluginContentHeaderTitle = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  `

  export const PluginContentHeaderTitleName = styled.span`
    font: ${({ theme }) => theme.typography.font.header03};
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  `

  export const PluginContentHeaderVendor = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font: ${({ theme }) => theme.typography.font.subtitle02};
    color: ${({ theme }) => theme.palette.text.tertiary};
  `
}
