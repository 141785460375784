import EnableFor from '@root/components/enableFor'
import { ButtonSize, ButtonType, Button, Tooltip } from '@toriihq/design-system'
import PropTypes from 'prop-types'
import React from 'react'

const TableActionButton = (props) => {
  const { icon, isActionDisabled, enableForScopes, actionFunc, dataTestId, tooltipProps } = props
  const buttonElement = (
    <EnableFor scopes={enableForScopes} allowForToriiAdmin>
      <Button
        type={ButtonType.tertiary}
        size={ButtonSize.small}
        disabled={isActionDisabled}
        onClick={actionFunc}
        icon={icon}
        dataTestId={dataTestId}
      />
    </EnableFor>
  )
  return (
    (!tooltipProps || !tooltipProps.label) ? buttonElement : <Tooltip {...tooltipProps}>
      { buttonElement }
    </Tooltip>
  )
}

TableActionButton.propTypes = {
  icon: PropTypes.string,
  actionFunc: PropTypes.func,
  enableForScopes: PropTypes.array,
  isActionDisabled: PropTypes.bool,
  color: PropTypes.string
}

TableActionButton.defaultProps = {
  enableForScopes: [],
  isActionDisabled: false,
  color: 'inherit'
}

export default TableActionButton
