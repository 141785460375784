import styled from 'styled-components'

export namespace Styles {
    export const TextWithImageAside = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      & > :first-child {
        flex-grow: 1;
      }
    `

    export const MultilineText = styled.div`
      display: flex;
      flex-direction: column;
    `
}
