import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import { ButtonSize, ButtonType, Button, theme } from '@toriihq/design-system'
import { ops } from '@lenses/filters'
import { fieldTypes } from '@root/constants'
import KeyOpValueFilter from '@components/filters/keyOpValueFilter'
const CSS = {
  main: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.transparent,
    padding: 0
  }),
  filters: css({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  }),
  deleteButton: css({
    alignSelf: 'flex-start',
    marginLeft: '-8px'
  })
}

const valuesWidth = 246

const KeyOpValueFilterCSS = {
  selectKey: css({
    width: 220
  }),
  propertyInput: css({
    width: valuesWidth
  }),
  values: css({
    width: valuesWidth
  }),
  relativeDayValue: css({
    width: 235,
    gap: 16
  }),
  selectWithError: css({
    border: `1px solid ${theme.palette.border.error}`
  })
}

const ActionButton = ({ iconName, onClick, disabled }) => (
  <Button type={ButtonType.compact} size={ButtonSize.small} icon={iconName} onClick={onClick} disabled={disabled} />
)

class AdvancedFiltersRow extends React.Component {
  render () {
    const { onRemove, disabled } = this.props
    return (
      <div {...CSS.main}>
        <div {...CSS.filters}>
          <KeyOpValueFilter
            filter={this.props.filter}
            optionsKey={this.props.optionsKey}
            optionsValues={this.props.optionsValues}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            fieldsAutoFocus={this.props.fieldsAutoFocus}
            CSS={KeyOpValueFilterCSS}
            layout='horizontal'
          />
        </div>
        {onRemove && <div {...CSS.deleteButton}>
          <ActionButton onClick={onRemove} iconName='Trash' disabled={disabled} />
        </div>}
      </div>
    )
  }
}

const dropdownObject = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.any
})

AdvancedFiltersRow.propTypes = {
  children: PropTypes.node,
  filter: PropTypes.shape({
    id: PropTypes.any,
    key: PropTypes.oneOfType([PropTypes.string, dropdownObject]),
    op: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(ops)), dropdownObject]),
    value: PropTypes.any
  }),
  optionsKey: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(fieldTypes))
  })),
  optionsValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    label: PropTypes.string
  })),
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
  onChange: PropTypes.func
}

AdvancedFiltersRow.defaultProps = {
  optionsKey: [],
  filter: { id: 'default' },
  fieldsAutoFocus: true
}

export default AdvancedFiltersRow
