import View from './view'
import { getCurrentApp } from '@selectors/apps'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'
import { getUsageAndUsersSources } from '@lenses/sources'
import get from 'lodash/get'

const mapStateToProps = (state, ownProps) => {
  const { service } = ownProps
  const { app = EMPTY_OBJECT } = getCurrentApp(state) || EMPTY_OBJECT

  const appName = get(app, ['name'])
  const usageSources = get(app, ['usageSources']) || EMPTY_ARRAY
  const userSources = get(app, ['userSources']) || EMPTY_ARRAY
  const expensesSources = get(app, ['expensesSources']) || EMPTY_ARRAY
  const contractSources = get(app, ['contractSources']) || EMPTY_ARRAY

  return {
    appName,
    usersSources: getUsageAndUsersSources({ service, defaultSources: userSources, dataSourceCategory: 'users' }),
    usageSources: getUsageAndUsersSources({ service, defaultSources: usageSources, dataSourceCategory: 'usage', usageType: 'application usage' }),
    expensesSources: { sources: expensesSources },
    contractSources: { sources: contractSources }
  }
}

export default connect(mapStateToProps)(View)
