import {
  IdWorkflowNode,
  WorkflowNodes
} from '@shared/workflows/types'
import { dfsTraverseActions } from './dfsTraverseActions'

export const getNodeDescendants = (
  idNode: IdWorkflowNode | null,
  nodes: WorkflowNodes
): IdWorkflowNode[] => {
  let idNodesOfBranchDescendants: IdWorkflowNode[] = []
  const handleNode = ({ idNode }: { idNode: IdWorkflowNode }) => idNodesOfBranchDescendants.push(idNode)

  if (idNode) {
    dfsTraverseActions({
      idCurrentNode: idNode,
      nodes,
      handleAction: handleNode,
      handleBranchAction: handleNode,
      handleActionWithFixedBranches: handleNode
    })
  }

  return idNodesOfBranchDescendants
}
