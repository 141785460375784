import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { css } from 'glamor'
import colors, { stateColors } from '../../shared/style/colors'
import * as Style from './style'
import { Icon } from '@toriihq/design-system'

const ColorPicker = ({ color, onChange, disabled, postOpen, postClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    if (!disabled) {
      setIsOpen(prevIsOpen => !prevIsOpen)
      postOpen && postOpen(!isOpen)
    }
  }

  const handleClose = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
    postClose && postClose()
  }

  return (
    <div {...Style.Container}>
      <div {...Style.Swatch} disabled={disabled} onClick={handleOpen}>
        <div {...css(Style.Color, { background: color })} />
        <Icon name='ChevronDown' color='inherit' />
      </div>
      { isOpen ? <div {...Style.Popover}>
        <div {...Style.Cover} onClick={handleClose} />
        <SketchPicker color={color} onChange={color => onChange(color.hex)} presetColors={stateColors} disableAlpha />
      </div> : null }
    </div>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  postOpen: PropTypes.func,
  postClose: PropTypes.func
}

ColorPicker.defaultProps = {
  color: colors.white,
  disabled: false,
  postOpen: () => {},
  postClose: () => {}
}

export default ColorPicker
