import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontSize, fontWeight } from '@shared/style/sizes'

export const Main = css({
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginRight: '10px',
  width: '100%',
  backgroundColor: 'transparent'
})

export const Button = css({
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal,
  color: colors.darkText,
  verticalAlign: 'middle'
})
