import React from 'react'
import ToriiPopup from '../ToriiPopupV2'
import * as Style from './style'
import PropTypes from 'prop-types'
import upgradePlanPopupImage from '@media/upgrade_plan_popup.svg'
import { css } from 'glamor'
import { Icon, Spacer } from '@toriihq/design-system'

export type Props = {
  onCancel: () => void
  onConfirm: () => void
}

const UpgradePlanPopup = ({ onCancel, onConfirm }: Props): JSX.Element => {
  return <ToriiPopup isOpen onCloseAction={onCancel} width='424' showCloseIcon={false}>
    <ToriiPopup.Header header={<img height='218px' width='422px' src={upgradePlanPopupImage} alt='' />} overrideStyle={css({ borderBottom: 0, padding: 0 })} />

    <ToriiPopup.Content contentAreaStyle={{ padding: '0px 24px 30px 24px' }}>
      <Spacer bottom={'space-100'}>
        <Style.Header>Upgrade your plan to unlock this feature and more</Style.Header>
      </Spacer>
      <Style.Description>
        <Spacer bottom={'space-100'}>
          <Style.DescriptionRow>
            <Icon name='CheckCircle' color='inherit' />
            <Spacer left={'space-100'}>
              Automate Employee Lifecycle Management
            </Spacer>
          </Style.DescriptionRow>
        </Spacer>

        <Spacer bottom={'space-100'}>
          <Style.DescriptionRow>
            <Icon name='CheckCircle' color='inherit' />
            <Spacer left={'space-100'}>
              Simplify App Administration With Seamless Automation
            </Spacer>
          </Style.DescriptionRow>
        </Spacer>

        <Style.DescriptionRow>
          <Icon name='CheckCircle' color='inherit' />
          <Spacer left={'space-100'}>
            And much more...
          </Spacer>
        </Style.DescriptionRow>
      </Style.Description>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      cancelButtonText={'Cancel'}
      cancelButtonAction={onCancel}
      mainButtonText={'Contact us'}
      mainButtonAction={onConfirm}
    />
  </ToriiPopup>
}

UpgradePlanPopup.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default UpgradePlanPopup
