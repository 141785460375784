import { uniq } from 'lodash'

const generateOption = (currency: string) => ({ label: currency, value: currency })

export const currenciesOptions = (currencySymbols: Record<string, string>): { label: string, value: string }[] => Object.keys(currencySymbols).map(currency => generateOption(currency))

export const getCurrenciesOptions = ({ orgDisplayCurrency, displayCurrencies, currencySymbols }: { orgDisplayCurrency: string, displayCurrencies: string[], currencySymbols: Record<string, string> }) => {
  const currencies = uniq([orgDisplayCurrency, ...displayCurrencies, ...Object.keys(currencySymbols)]).map(currency => generateOption(currency))
  return currencies
}
