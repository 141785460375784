import colors from '@shared/style/colors'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import { fontWeight } from '@shared/style/sizes'

export const Tooltip = css({
  padding: '16px',
  border: `1px solid ${colors.border}`,
  backgroundColor: colors.white,
  minWidth: '236px'
})

export const Date = css(texts.caption, {
  lineHeight: '16px',
  color: colors.grey1
})

export const header = css(texts.heading, {
  margin: '4px 0',
  color: colors.black
})

export const details = css(texts.headers.regular)

export const diff = css(texts.subheading, {
  marginLeft: '3px '
})

export const DateContainer = css({
  marginBottom: '4px'
})
export const Header = css(texts.heading, {
  margin: '4px 0',
  color: colors.black,
  marginBottom: '4px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

export const SubHeader = css(texts.caption, {
  marginBottom: '8px',
  whiteSpace: 'pre-line',
  color: colors.grey2
})

export const KeyValueList = css({
  borderTop: `1px solid ${colors.border}`,
  paddingTop: '8px',
  color: colors.grey1
})

export const Value = css({
  fontWeight: fontWeight.bold
})

export const KeyValuePair = css(texts.caption, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '8px'
})

export const HeaderContainer = css({
  display: 'flex',
  flexDirection: 'column'
})
