import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Legend, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import RelativeTeamLink from '../../relativeTeamLink'
import Analytics from '@helpers/analytics'
import omit from 'lodash/omit'
import range from 'lodash/range'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'
import pick from 'lodash/pick'
import get from 'lodash/get'
import texts from '@shared/style/texts'
import { fontSize, fontWeight } from '@shared/style/sizes'
import isEqual from 'lodash/isEqual'

const CSS = {
  main: css({
    padding: '10px 21px 24px 0px',
    ' .recharts-surface': {
      overflow: 'visible'
    }
  }),
  legend: css({
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '60px',
    maxWidth: '480px'
  }),
  square: css({
    display: 'inline-block',
    width: '10px',
    height: '10px',
    marginRight: '8px',
    borderRadius: '3px'
  }),
  tooltip: css({
    padding: '12px 16px 2px 16px',
    border: `1px solid ${colors.border}`,
    backgroundColor: colors.white
  }),
  tooltipHeader: css(texts.subheading, {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    paddingBottom: '12px',
    color: colors.black,
    textTransform: 'uppercase',
    borderBottom: `1px solid ${colors.grey3}`
  }),
  tooltipRow: css({
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  nameContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px'
  }),
  categoryName: css(texts.subheading, {
    fontWeight: fontWeight.normal,
    color: colors.text
  }),
  amount: css(texts.subheading, {
    fontWeight: fontWeight.semiBold,
    color: colors.black,
    lineHeight: fontSize.small,
    height: fontSize.small
  })
}

const barCellsColors = [
  '#00257B',
  '#0054FF',
  '#B0CAFF',
  '#684AFF',
  '#B6A8FF',
  '#E2DDFF',
  '#00768E',
  '#00C3EB',
  '#5EE4FF',
  '#636C7F',
  '#ADB3BF',
  '#D0D3DA'
]

const onCustomImageClick = (analyticsEvent, customImageRank) => {
  analyticsEvent && Analytics.track(analyticsEvent, {
    'Rank': customImageRank
  })
}

const CustomImagesXAxis = (props) => {
  const { x, y, payload, dataByName, analyticsEvent } = props

  const customImageRank = payload.index + 1
  const { imageUrl, idApp } = dataByName[payload.value] || {}
  return idApp ? (
    <RelativeTeamLink to={`/app/${idApp}`} onClick={() => onCustomImageClick(analyticsEvent, customImageRank)} >
      <g transform={`translate(${x - 12},${y})`}>
        <rect fill='white' strokeWidth='1' height='22' width='22' stroke='#E6EBF4' rx='5' />
        <image transform={`translate(${1.5},${1.5})`} href={imageUrl} width='19px' height='19px' />
      </g>
    </RelativeTeamLink>
  ) : (
    <g transform={`translate(${x - 12},${y})`}>
      <rect fill='white' strokeWidth='1' height='22' width='22' stroke='#E6EBF4' rx='5' />
    </g>
  )
}

const CustomXAxis = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='middle' fill={colors.grey2} style={{ fontFamily: 'Open Sans' }} fontSize={10}>{payload.value}</text>
    </g>
  )
}

const CustomYAxis = ({ x, y, payload, formatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-16} y={0} dy={0} textAnchor='middle' fill={colors.grey2} style={{ fontFamily: 'Open Sans' }} fontSize={10}>{formatter(payload.value)}</text>
    </g>
  )
}

const renderLegend = ({ payload }) => {
  return (
    <div {...CSS.legend}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`}>
          <span {...css(CSS.square, { backgroundColor: entry.color })} />
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  )
}

class StackedBarChart extends React.Component {
  state = { selectedBarName: null, data: [], fill: [] }

  getUpdatedDataAndFill = () => {
    const { data, showCustomDynamicBarsChart, fill } = this.props
    const updatedData = showCustomDynamicBarsChart ? this.getCustomDynamicBarsChartCellsData(data) : data
    const updatedFill = !fill ? this.getRandomFillColors(updatedData) : fill

    return { updatedData, updatedFill }
  }
  componentDidMount () {
    const { updatedData, updatedFill } = this.getUpdatedDataAndFill()
    this.setState({ selectedBarName: null, data: updatedData, fill: updatedFill })
  }

  componentDidUpdate (prevProps) {
    const { data } = this.props
    if (data && !isEqual(data, prevProps.data)) {
      const { updatedData, updatedFill } = this.getUpdatedDataAndFill()

      this.setState({ data: updatedData, fill: updatedFill })
    }
  }

  getRandomFillColors = (data) => {
    const { customDynamicBarsChartMaxCells } = this.props

    let colorIndex = 0
    const fillObject = data.reduce((fillObject, barData) => {
      range(customDynamicBarsChartMaxCells).forEach(cellKey => {
        if (barData[cellKey] && barData[cellKey].key && !fillObject[barData[cellKey].key]) {
          fillObject[barData[cellKey].key] = { key: barData[cellKey].key, color: barCellsColors[colorIndex % barCellsColors.length] }
          colorIndex++
        }
      })

      return fillObject
    }, {})

    return Object.values(fillObject)
  }

  getCustomDynamicBarsChartCellsData = (data) => {
    const { customDynamicBarsChartMaxCells } = this.props

    return data.map(barData => {
      const sortedBarCellsDataByValue = orderBy(Object.entries(omit(barData, ['name'])).map(([key, value]) => ({ key, value })), 'value', ['desc'])
      const maxValuesCellsInfo = sortedBarCellsDataByValue.slice(0, sortedBarCellsDataByValue.length <= customDynamicBarsChartMaxCells ? customDynamicBarsChartMaxCells : customDynamicBarsChartMaxCells - 1)
      const otherCellsInfo = [{ key: 'other', value: sumBy(sortedBarCellsDataByValue.slice(customDynamicBarsChartMaxCells - 1), 'value') }]
      const barCellsValues = sortedBarCellsDataByValue.length <= customDynamicBarsChartMaxCells ? maxValuesCellsInfo : maxValuesCellsInfo.concat(otherCellsInfo)

      return {
        ...barCellsValues,
        name: barData.name
      }
    })
  }

  renderCustomDynamicBarsChartTooltip = ({ payload, label }) => {
    const { selectedBarName, fill } = this.state
    const { tooltipContent, customDynamicBarsChartMaxCells } = this.props

    if (!payload.length || !selectedBarName || payload.every(cellInfo => !cellInfo.value)) {
      return null
    }

    const cellsDataArray = Object.values(pick(get(payload, [0, 'payload']), range(customDynamicBarsChartMaxCells))).reverse()

    return tooltipContent ? tooltipContent({ payload: cellsDataArray, label, fill })
      : (<div {...CSS.tooltip}>
        <div {...CSS.tooltipHeader}><div>{label}</div><div>{sumBy(cellsDataArray, 'value')}</div></div>
        {cellsDataArray.map(cellInfo => {
          const itemFillColor = fill.find(f => f.key === cellInfo.key).color

          return <div key={cellInfo.id} {...CSS.tooltipRow}>
            <div {...CSS.nameContainer}>
              <span {...css(CSS.square, { backgroundColor: itemFillColor })} />
              <span {...CSS.categoryName}>{cellInfo.key}</span>
            </div>
            <div {...CSS.amount}>{cellInfo.value}</div>
          </div>
        })}
      </div>)
  }

  setSelectedBarName = (selectedBarName) => {
    selectedBarName !== this.state.selectedBarName && this.setState({ selectedBarName })
  }

  getCustomBar = (barProps, cellKey, fillByKey) => {
    const { selectedBarName } = this.state
    const { customDynamicBarsChartDefaultFill } = this.props
    const { x, y, width, height, name } = barProps

    if (!barProps[cellKey]) {
      return undefined
    }

    return <g>
      <path
        d={`M${x + 4},${y - 8} h${width - 8} a4,4 0 0 1 4,4 v${height} a4,4 0 0 1 -4,4 h-${width - 8} a4,4 0 0 1 -4,-4 v-${height} a4,4 0 0 1 4,-4 z`}
        fill={selectedBarName === name ? fillByKey[barProps[cellKey].key].color : (selectedBarName ? '#EAF3F8' : customDynamicBarsChartDefaultFill)} />
    </g>
  }

  render () {
    const { barSize, showLegend, tooltipContent, chartHeight = 180, imagesAxis, overrideStyle, analyticsEvent, showCustomDynamicBarsChart, interval, customDynamicBarsChartMaxCells, formatter } = this.props
    const { data, fill } = this.state

    const fillByKey = keyBy(fill, 'key')

    const configuredXAxis = imagesAxis ? <CustomImagesXAxis dataByName={keyBy(data, 'name')} analyticsEvent={analyticsEvent} /> : <CustomXAxis />
    return (
      <div {...css(CSS.main, overrideStyle)}>
        <ResponsiveContainer width='100%' height={chartHeight}>
          <BarChart data={data} barSize={barSize} reverseStackOrder={showCustomDynamicBarsChart} onMouseMove={showCustomDynamicBarsChart ? ({ isTooltipActive, activeLabel }) => { this.setSelectedBarName(isTooltipActive && activeLabel ? activeLabel : null) } : undefined} onMouseLeave={showCustomDynamicBarsChart ? () => { this.setSelectedBarName(null) } : undefined} >
            <CartesianGrid stroke={colors.lightBlue2} vertical={false} />
            <XAxis axisLine={false} tickLine={false} interval={interval} dataKey='name' tick={configuredXAxis} />
            <YAxis axisLine={false} tickLine={false} tick={<CustomYAxis formatter={formatter} />} />
            <Tooltip wrapperStyle={{ zIndex: 10 }} position={showCustomDynamicBarsChart ? { y: -150 } : undefined} content={showCustomDynamicBarsChart ? this.renderCustomDynamicBarsChartTooltip : tooltipContent} cursor={{ fill: 'transparent' }} />
            {(showLegend && !showCustomDynamicBarsChart) && <Legend content={renderLegend} wrapperStyle={{ paddingTop: '15px', right: 0 }} />}
            {showCustomDynamicBarsChart ? range(customDynamicBarsChartMaxCells).reverse().map(cellKey => (
              <Bar
                dataKey={barInfo => get(barInfo, [cellKey, 'value'], 0)}
                stackId='a'
                shape={(props) => this.getCustomBar(props, cellKey, fillByKey)}
              />
            )) : fill.map(f => <Bar key={f.key} name={f.name} dataKey={f.key} stackId='a' fill={f.color} />) }
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export const stackedBarChartType = {
  barSize: PropTypes.number,
  showLegend: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })),
  fill: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    name: PropTypes.string
  })),
  imagesAxis: PropTypes.bool,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCustomDynamicBarsChart: PropTypes.bool,
  customDynamicBarsChartMaxCells: PropTypes.number,
  customDynamicBarsChartDefaultFill: PropTypes.string,
  formatter: PropTypes.func
}

StackedBarChart.propTypes = {
  ...stackedBarChartType
}

StackedBarChart.defaultProps = {
  showLegend: true,
  showCustomDynamicBarsChart: false,
  customDynamicBarsChartMaxCells: 4,
  customDynamicBarsChartDefaultFill: colors.navy,
  interval: 0,
  formatter: (value) => value
}

export default StackedBarChart
