import { css } from 'glamor'
import texts from '@shared/style/texts'
import { fontWeight } from '@shared/style/sizes'
import colors from '@shared/style/colors'
import * as SharedStyle from '../shared/style'

const RIGHT_MARGIN = '4px'

export const InfoLine = css(texts.body, {
  fontWeight: fontWeight.normal,
  color: colors.blue,
  cursor: 'pointer',
  marginRight: RIGHT_MARGIN,
  '[disabled]': {
    color: colors.black,
    cursor: 'text'
  }
})

export const NonActiveUsers = css(texts.body, {
  fontWeight: fontWeight.normal,
  color: colors.grey1,
  marginRight: RIGHT_MARGIN
})

export const { EmptyStateContainer, MissingDataText } = SharedStyle
