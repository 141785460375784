import React, { useMemo } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import PropTypes from 'prop-types'
import colors from '@shared/style/colors'
import { COLOR_SCHEME } from '../shared/style'
import groupBy from 'lodash/groupBy'
import maxBy from 'lodash/maxBy'
import * as Style from './style'
import moment from 'moment'
import CustomGraphTooltip from './customGraphTooltip'
import Legend from './legend'
import CustomGraphLabel from './customGraphLabel'

const ChartWidget = (props) => {
  const {
    data = [],
    title,
    subHeaderText = '',
    yAxisLabel,
    trendTitle = '',
    tooltipTitle,
    orderBy,
    emptyStateComponent: EmptyStateComponent = () => {},
    renderLegendInfo = () => {},
    renderLegendDataSeparator,
    renderTooltipInfo = () => {},
    loading = false,
    valueKey = 'value',
    alerts
  } = props

  const { appList = [], maxTickValue = 0, ticks = [], legendSubtitle = '' } = useMemo(() => {
    if (data.length === 0) {
      return {
        appList: [],
        maxTickValue: 0,
        ticks: [],
        legendSubtitle: ''
      }
    }

    const itemWithMaxValue = maxBy(data, item => item[valueKey] || 0)
    const ceilValue = Math.ceil(itemWithMaxValue[valueKey] / 10)
    const maxTickValue = ceilValue * 10

    const ticks = []
    if (maxTickValue !== 0) {
      for (let i = 0; i <= maxTickValue; i = i + (maxTickValue / 10)) {
        ticks.push(i)
      }
    }

    const legendSubtitle = `Last 30 days: ${moment.utc().subtract(30, 'days').format('MMMM D')}  - ${moment().format('MMMM D')}`

    const filteredData = data.some(item => item[valueKey] !== undefined) ? data : data.filter(item => item[valueKey] !== undefined)
    const groups = groupBy(filteredData.map((item, index) => ({ ...item, value: item[valueKey], monthYear: moment(item.date).utc().format('MMM YYYY') })), (item) => item.idApp)
    let appsData = []
    if (orderBy) {
      orderBy.forEach((key, index) => {
        if (groups[key]) {
          appsData[index] = groups[key]
          if (appsData[index]) {
            appsData[index].forEach(item => { item.color = COLOR_SCHEME[index] })
          }
        }
      })
    } else {
      appsData = Object.values(groups)
    }

    return {
      appList: appsData.filter(Boolean),
      maxTickValue,
      ticks,
      legendSubtitle
    }
  }, [data, orderBy, valueKey])

  const CustomXTick = ({ x, y, payload }) => {
    if (typeof payload.value === 'string' && payload.value !== 'auto') {
      const parts = payload.value.split(' ')
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={10} textAnchor='middle' fill={colors.grey1} style={{ fontFamily: 'Nunito' }} fontSize={13}>{parts[0]}</text>
          <text x={0} y={25} textAnchor='middle' fill={colors.grey1} style={{ fontFamily: 'Nunito' }} fontSize={13}>{parts[1]}</text>
        </g>
      )
    }
    return null
  }

  const CustomYTick = ({ x, y, payload }) => {
    if (payload.value !== 0 && payload.value !== maxTickValue) {
      return null
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-15} y={5} textAnchor='end' fill={colors.grey1} style={{ fontFamily: 'Nunito' }} fontSize={13}>{payload.value}</text>
      </g>
    )
  }

  return (
    <div {...Style.Container}>
      <div {...Style.Header}>
        <div {...Style.HeaderText}>{title}</div>
        <div {...Style.SubHeaderText}>{subHeaderText}</div>
      </div>
      <div {...Style.SubHeader}>
        <div {...Style.AlertBox}>{alerts}</div>
        <div {...Style.SubtitlesContainer}>
          {legendSubtitle}
          <span {...Style.TrendSubtitle}>{trendTitle}</span>
        </div>
      </div>
      {loading ? null : appList.length ? (
        <div {...Style.Main}>
          <div {...Style.Legend}>
            <Legend data={appList} renderLegendInfo={renderLegendInfo} renderLegendDataSeparator={renderLegendDataSeparator} component={title} loading={loading} />
          </div>
          <ResponsiveContainer width='100%' height={265}>
            <LineChart>
              <CartesianGrid stroke={colors.lightBlue2} vertical={false} height={'209px'} />
              <XAxis
                dataKey='monthYear'
                allowDuplicatedCategory={false}
                type='category'
                padding={{ left: 30, right: 30 }}
                tick={<CustomXTick />}
                interval={0}
                axisLine={false}
                tickLine={false}
                reversed
              />
              <YAxis
                type='number'
                ticks={ticks}
                tick={<CustomYTick />}
                interval={0}
                axisLine={false}
                tickLine={false}
                scale='linear'
                label={<CustomGraphLabel value={yAxisLabel} angle={-90} offset={0} style={Style.YAxisLabel} />}
              />
              <Tooltip allowEscapeViewBox={{ x: false, y: true }} content={<CustomGraphTooltip title={tooltipTitle} renderTooltipInfo={renderTooltipInfo} />} />
              {appList.map((item, index) => <Line key={index} data={item} type='monotone' dataKey='value' stroke={item[0].color} strokeWidth={2} dot={false} />)}
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : EmptyStateComponent()}
    </div>
  )
}

ChartWidget.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  subHeaderText: PropTypes.string,
  yAxisLabel: PropTypes.string,
  tooltipTitle: PropTypes.string,
  renderLegendInfo: PropTypes.func,
  renderLegendDataSeparator: PropTypes.func,
  renderTooltipInfo: PropTypes.func,
  loading: PropTypes.bool,
  valueKey: PropTypes.string,
  orderBy: PropTypes.arrayOf(PropTypes.number).isRequired,
  emptyStateComponent: PropTypes.func,
  alerts: PropTypes.object
}

export default ChartWidget
