import React from 'react'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import WorkflowExecutions from '../../components/workflowExecutions'
import BackLink from '@components/backLink'
import PropTypes from 'prop-types'
import { getWorkflowPathPrefix, getWorkflowsPathPrefix } from '@lenses/workflows'

class WorkflowExecutionsPage extends React.Component {
  render () {
    const { idWorkflow, idOrg, workflow, isAppCatalogPoliciesView, backLinkPath, backlinkText, executionsPathPrefix } = this.props
    const title = workflow.name && `${workflow.name}${workflow.isDeleted ? ' (deleted)' : ''}`

    return (
      <Page title={title}>
        <div style={{ marginTop: '20px' }}>
          <BackLink navigateTo={`/${backLinkPath}`} linkText={backlinkText} />
        </div>
        <PageHeader title={title} />
        <WorkflowExecutions idOrg={idOrg} idWorkflow={idWorkflow} executionsPathPrefix={executionsPathPrefix} isAppCatalogPoliciesView={isAppCatalogPoliciesView} />
      </Page>
    )
  }
}

WorkflowExecutionsPage.propTypes = {
  backLinkPath: PropTypes.string,
  backlinkText: PropTypes.string,
  executionsPathPrefix: PropTypes.string
}

WorkflowExecutionsPage.defaultProps = {
  backLinkPath: getWorkflowsPathPrefix(),
  backlinkText: 'Back to workflows',
  executionsPathPrefix: getWorkflowPathPrefix()
}

export default WorkflowExecutionsPage
