import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import pluralize from 'pluralize'
import { css } from 'glamor'
import UsersIconsList from '../../usersIconsList'
import Analytics from '@helpers/analytics'
import { SCOPES } from '@root/constants'
import { AlertBox, AlertBoxType } from '@toriihq/design-system'

const CSS = {
  popup: css({
    width: '500px'
  })
}
class ManualActionStartedPopup extends Component {
  viewLog = () => {
    const { history, idWorkflow, idOrg, close, users, triggerType } = this.props
    history.push(`/team/${idOrg}/workflow/${idWorkflow}/executions?entitiesAmount=${users.length}`)

    Analytics.track('Click on view-logs-button', {
      'Workflow ID': idWorkflow,
      'Dialog name': 'Action execution started',
      'Trigger name': triggerType
    })

    close()
  }

  render () {
    const { isOpen, cancel, users } = this.props

    return <ToriiPopup
      isOpen={isOpen}
      onCloseAction={cancel}
      styles={CSS.popup}
    >
      <ToriiPopup.Header header={`Action execution started for ${pluralize('user', users.length, true)}`} />
      <ToriiPopup.Content>
        <AlertBox type={AlertBoxType.INFORMATIVE} description='Updates may take a few minutes to complete.' />
        <UsersIconsList users={users} maxUsers={5} />
      </ToriiPopup.Content>
      <ToriiPopup.Footer mainButtonAction={this.viewLog} mainButtonText={'View logs'} cancelButtonText={'Close'} mainButtonVisibilityScopes={[SCOPES.AUTOMATION_READ]} />
    </ToriiPopup>
  }
}

export default ManualActionStartedPopup
