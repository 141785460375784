import React from 'react'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import FieldType from './fieldType'
import { FIELDS_CONTAINER_ENTITY, formFieldTypes, ICON_NAMES } from '@root/constants'

const CSS = {
  main: css({
    margin: '0px -10px -20px -10px'
  })
}

const SelectFieldTypePopup = ({ cancel, isOpen, addDetailsField, groupsForSelectGroup, hideFormQuestion, entityType }) => {
  const baseProps = {
    addDetailsField,
    groupsForSelectGroup,
    hideFormQuestion
  }
  const fieldTypeProps = fieldTypePropsByEntity(entityType)

  return <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width={entityType === FIELDS_CONTAINER_ENTITY.USERS ? '665' : '510'}>
    <ToriiPopup.Header header='Choose field type' />
    <ToriiPopup.Content>
      <div {...CSS.main}>
        {fieldTypeProps.map(
          fieldType => <FieldType {...baseProps} key={fieldType.type} iconName={fieldType.iconName} type={fieldType.type} toolTip={fieldType.toolTip} />)
        }
      </div>
    </ToriiPopup.Content>
  </ToriiPopup>
}

const fieldTypePropsByEntity = (entityType) => {
  const commonInfo = [
    { iconName: ICON_NAMES.SINGLE_LINE_TEXT, type: formFieldTypes.singleLine, toolTip: 'Basic text input field' },
    { iconName: ICON_NAMES.MULTI_LINE_TEXT, type: formFieldTypes.multiLine, toolTip: 'Basic multi line text input field' },
    { iconName: ICON_NAMES.HASH, type: formFieldTypes.number, toolTip: 'A number field that represents amount' },
    { iconName: ICON_NAMES.SINGLE_SELECT, type: formFieldTypes.dropdown, toolTip: 'A drop-down menu for selecting a single item from a list' },
    { iconName: ICON_NAMES.MULTI_SELECT, type: formFieldTypes.dropdownMulti, toolTip: 'A drop-down menu for selecting multiple items from a list' },
    { iconName: ICON_NAMES.CURRENCY, type: formFieldTypes.currency, toolTip: 'A currency field that represents price' },
    { iconName: ICON_NAMES.CALANDER_DAY, type: formFieldTypes.datePicker, toolTip: 'A popup calendar for selecting a date' },
    { iconName: ICON_NAMES.EMPLOYEE, type: formFieldTypes.usersDropdown, toolTip: 'Selecting a single employee from a list of all employees' }
  ]
  if (entityType !== FIELDS_CONTAINER_ENTITY.USERS) {
    return [...commonInfo, { iconName: ICON_NAMES.USERS, type: formFieldTypes.usersDropdownMulti, toolTip: 'Selecting multiple employees from a list of all employees' }]
  }
  return commonInfo
}

SelectFieldTypePopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default SelectFieldTypePopup
