import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { css } from 'glamor'
import PieChart from './pieChart'
import { Tooltip, Link, Icon } from '@toriihq/design-system'
import RelativeTeamLink from '../relativeTeamLink'

const CSS = {
  main: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  }),
  chartsContainer: css({
    padding: '18px 21px'
  }),
  headerContainer: css({
    borderBottom: `1px solid ${colors.border}`,
    padding: '18px 21px',
    display: 'flex'
  }),
  header: css(texts.heading, {
    textTransform: 'uppercase',
    marginRight: '4px',
    lineHeight: '22px'
  }),
  link: css({
    marginLeft: 'auto'
  })
}

const PieChartBox = ({ chartsInfo, header, loading, customTooltip, description, link }) => {
  return (
    <div {...CSS.main}>
      {!loading && header && <div {...CSS.headerContainer}>
        <span {...CSS.header}>{header}</span>
        {description && <Tooltip
          placement='top'
          label={description}>
          <Icon name='Info' />
        </Tooltip>}
        {link && <RelativeTeamLink to={link.path} className={CSS.link}>
          <Link>{link.text}</Link>
        </RelativeTeamLink>}
      </div>}
      <div {...CSS.chartsContainer}>
        {chartsInfo.map((chart, index) => <PieChart key={index} data={chart.data} hasData={chart.hasData} header={chart.header} loading={loading} customTooltip={customTooltip} overrideStyle={{ '> div:first-child': { flex: 1, '@media(max-width: 300px)': { flex: `0 0 100%`, marginTop: '20px' } } }} />)}
      </div>
    </div>
  )
}

const chartInfo = PropTypes.shape({
  header: PropTypes.string,
  hasData: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })),
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })
})

PieChartBox.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  chartsInfo: PropTypes.arrayOf(chartInfo),
  loading: PropTypes.bool,
  customTooltip: PropTypes.element
}

export default PieChartBox
