import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Table from '../table'
import AppDetails from '../appDetails'
import colors from '../../shared/style/colors'
import { Avatar, Tooltip, Spacer, Button, ButtonType, ButtonSize, H4, Link, EmptyState } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getFullUrl } from '../../shared/utils'
import EnableFor from '../enableFor'
import { SCOPES } from '../../constants'
import { getFormattedDate } from '@lenses/utils'
import moment from 'moment'
import emptyImage from '@media/emptyState.svg'

const CSS = {
  row: css({}),
  actions: css({
    textAlign: 'right'
  }),
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  actionsWrapper: css({
    display: 'flex'
  })
}

CSS.hoverActions = css({
  opacity: 0,
  [`.${CSS.row}:hover &, [data-${CSS.row}]:hover &`]: {
    opacity: 1
  }
})

const setTrGroupStyle = () =>
  ({
    className: CSS.row.toString()
  })

const EditButton = ({ app, openEditApplication }) =>
  <Spacer right={'space-150'}>
    <Tooltip label='Edit'>
      <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
        <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={() => openEditApplication(app)} icon='Edit' />
      </EnableFor>
    </Tooltip>
  </Spacer>
const getColumns = (onHide, isSmallScreen, openEditApplication) =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: 250,
      className: CSS.nameAndIcon.toString(),
      Cell: ({ value: name, row: { id, imageUrl, category } }) => (
        <AppDetails
          id={id}
          name={name}
          category={category}
          imageUrl={imageUrl}
          component='Custom apps'
        />
      )
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value: url }) => <Link href={getFullUrl(url)} target='_blank'>{url}</Link>,
      width: 250
    },
    {
      Header: 'Added by',
      accessor: 'addedBy',
      Cell: ({ value: addedBy }) => {
        const { firstName, lastName, photoUrl, id } = addedBy || {}
        const displayName = (firstName ? firstName + ' ' : '') + lastName || ''
        const content = <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
        return (
          <Tooltip label={displayName} hide={!firstName || !lastName}>
            {id
              ? <RelativeTeamUserLink idUser={id}>{content}</RelativeTeamUserLink>
              : content
            }
          </Tooltip>

        )
      },
      width: 100
    },
    {
      Header: 'Date added',
      accessor: 'creationTime',
      textValue: ({ value }) => value ? moment.utc(value).format('YYYY-MM-DD') : '-',
      Cell: ({ value }) => getFormattedDate({ date: value }),
      width: 170
    },
    {
      Header: '',
      sortable: false,
      className: css(CSS.actions, !isSmallScreen && CSS.hoverActions).toString(),
      Cell: ({ row: { id, name, category, url, imageUrl, tags, description } }) => <div {...CSS.actionsWrapper}>
        <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
          <EditButton app={{ id, name, category, url, imageUrl, tags, description }} openEditApplication={openEditApplication} />
        </EnableFor>
        <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
          <Button type={ButtonType.secondary} onClick={() => onHide(id, name)} label='Hide' />
        </EnableFor>
      </div>
    },
    {
      accessor: 'category',
      show: false
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'description',
      show: false
    },
    {
      accessor: 'tags',
      show: false
    }
  ]

class CustomApps extends React.Component {
  componentDidMount () {
    const { getCustomApps, idOrg } = this.props
    getCustomApps({ idOrg })
  }

  onHide = async (idApp, name) => {
    const { idOrg, changeAppHiddenState } = this.props
    Analytics.track('Clicked to hide application', {
      idApp,
      name
    })
    await changeAppHiddenState({ idOrg, idApp, isHidden: true })
  }

  renderNoApps = () => {
    return (
      <EmptyState
        image={<img src={emptyImage} alt='No custom applications' />}
        description='No custom applications were added yet'
      />
    )
  }

  openAddApplication = () => {
    const { toggleAddApplication } = this.props
    toggleAddApplication({ isAddApplicationOpen: true, customApp: true, openFrom: 'Add custom application' })
  }

  openEditApplication = (app) => {
    const { toggleAddApplication } = this.props
    Analytics.track('Clicked to open popup: edit custom application', {
      'App id': app.id,
      'App name': app.name
    })
    toggleAddApplication({ isAddApplicationOpen: true, customApp: true, editMode: true, app })
  }

  render () {
    const { apps, loading, tableKey, isSmallScreen } = this.props
    const addApp = <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}><Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.openAddApplication} label='+ Add application' /></EnableFor>
    const columns = getColumns(this.onHide, isSmallScreen, this.openEditApplication)
    return (
      <Table
        tableKey={tableKey}
        getTrGroupProps={setTrGroupStyle}
        emptyStateMessage={this.renderNoApps()}
        data={apps}
        columns={columns}
        header={<H4>Custom Applications</H4>}
        loading={loading}
        extraHeaderComponent={addApp}
        pageSize={50}
        clientPaging
      />
    )
  }
}

CustomApps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    url: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    creationTime: PropTypes.string.isRequired,
    addedBy: PropTypes.object.isRequired
  })),
  tableKey: PropTypes.string.isRequired
}

export default CustomApps
