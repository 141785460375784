import React from 'react'
import { css } from 'glamor'
import ToriiSelect from '../../select'
import Input from '../../form/input'

const CSS = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left'
  }),
  input: css({
    width: '100%',
    marginLeft: '5px'
  }),
  selectContainer: css({
    width: '100%'
  })
}

class Threshold extends React.Component {
  onChange = (value, locallyOnly = false) => {
    const { threshold, onChange } = this.props
    onChange({ ...threshold, ...value }, locallyOnly)
  }

  render () {
    const { options, threshold, disabled } = this.props

    return (
      <div>
        <div {...CSS.container}>
          <div {...CSS.selectContainer}>
            <ToriiSelect
              options={options}
              placeholder='Threshold'
              onChange={(option) => this.onChange({ type: option.value })}
              clearable={false}
              value={threshold.type}
              disabled={disabled}
            />
          </div>
          <Input
            {...CSS.input}
            type='number'
            value={threshold.number}
            onBlur={(e) => this.onChange({ number: e.target.value })}
            onChange={(e) => this.onChange({ number: e.target.value }, true)}
            disabled={disabled}
          />
        </div>

      </div>
    )
  }
}

export default Threshold
