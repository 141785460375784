import styled from 'styled-components'

export const RightSide = styled.div<{ $width: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: -20px 0 20px -20px rgba(206, 225, 252, 0.5);
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  width: ${({ $width }) => $width};
`

export const StyledLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.primary};
  overscroll-behavior: none;
`
