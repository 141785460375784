import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@toriihq/design-system'
import * as Style from './style'

const OverflowTooltip = ({ children, ...rest }) => {
  const [isOverflown, setIsOverflown] = useState(false)
  const textElementRef = useRef(undefined)

  useEffect(() => {
    setIsOverflown(textElementRef.current.scrollWidth > textElementRef.current.offsetWidth)
  }, [])

  return <Tooltip hide={!isOverflown} fullWidth {...rest}>
    <div ref={textElementRef} {...Style.TextContainer}>{children}</div>
  </Tooltip>
}

OverflowTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
}

export default OverflowTooltip
