export const PLANS = {
  BASIC: 'basic',
  PROFESSIONAL: 'professional',
  ENTERPRISE: 'enterprise'
}

export const FEATURES = {
  INSIGHTS: {
    PAGE: 'page.insights'
  },
  APPLICATIONS: {
    PAGE: 'page.applications',
    TABS: {
      REVIEW: 'page.applications:tab.review',
      COMPARE: 'page.applications:tab.compare',
      REFINE: 'page.applications:tab.refine'
    }
  },
  APPLICATION: {
    PAGE: 'page.application',
    TABS: {
      OVERVIEW: 'page.application:tab.overview',
      INFO: 'page.application:tab.info',
      USERS: 'page.application:tab.users',
      CONTRACTS: 'page.application:tab.contracts',
      EXPENSES: 'page.application:tab.expenses',
      CHARGEBACK: 'page.application:tab.chargeback',
      DISCOVERED_APPS: 'page.application:tab.discoveredApps',
      RECOMMENDATIONS: 'page.application:tab.recommendations'
    },
    COMPONENTS: {
      APP_COMPLIANCE: 'appCompliance'
    }
  },
  LICENSES: {
    PAGE: 'page.licenses',
    TABS: {
      TREND: 'page.licenses:tab.trend',
      CHARGEBACK: 'page.licenses:tab.chargeback',
      RECOMMENDATIONS: 'page.licenses:tab.recommendations',
      CURRENT: 'page.licenses:tab.current'
    },
    COMPONENTS: {
      BENCHMARK: 'page.licenses:component.benchmark'
    }
  },
  USERS: {
    PAGE: 'page.users',
    TABS: {
      EMPLOYEES: 'page.users:tab.employees',
      USERS: 'page.users:tab.users',
      EXTERNAL_USERS: 'page.users:tab.externalUsers'
    }
  },
  USER: {
    PAGE: 'page.user'
  },
  EXPENSES: {
    PAGE: 'page.expenses',
    TABS: {
      OVERVIEW: 'page.expenses:tab.overview',
      MATCHING_RULES: 'page.expenses:tab.matchingRules',
      EXPENSE_FILES: 'page.expenses:tab.expenseFiles',
      ALL_EXPENSES: 'page.expenses:tab.allExpenses'
    }
  },
  RENEWALS: {
    PAGE: 'page.renewals',
    COMPONENTS: {
      CONTRACT_AI: 'page.renewals:component.contractAI',
      EMAIL_TO_CONTRACT: 'feature.emailToContract'
    },
    TABS: {
      CALENDAR: 'page.renewals:tab.calendar',
      CONTRACTS: 'page.renewals:tab.contracts',
      RENEWALS_SUMMARY: 'page.renewals:tab.renewalsSummary'
    }
  },
  WORKFLOWS: {
    PAGE: 'page.workflows'
  },
  OFFBOARDING: {
    PAGE: 'page.offboarding'
  },
  SECURITY: {
    PAGE: 'page.security'
  },
  REPORTS: {
    PAGE: 'page.reports',
    TYPES: {
      APP_COMPLIANCE: 'appCompliance'
    }
  },
  APP_CATALOG: {
    PAGE: 'page.appCatalog',
    TABS: {
      SETTINGS: 'page.appCatalog:tab.settings',
      POLICIES: 'page.appCatalog:tab.policies'
    }
  },
  INTEGRATIONS: {
    PAGE: 'page.integrations',
    TYPES: {
      EXPENSES: 'page.integrations:type.expenses',
      CONTRACTS: 'page.integrations:type.contracts',
      CUSTOM: 'page.integrations:type.custom'
    }
  },
  PLUGIN_MARKETPLACE: {
    PAGE: 'page.pluginMarketplace'
  },
  APPLICATION_DETAILS: {
    APP_COMPLIANCE: 'appCompliance'
  },
  SETTINGS: {
    PAGE: 'page.settings',
    TABS: {
      GENERAL: 'page.settings:tab.general',
      USERS_AND_EMPLOYEES: {
        PAGE: 'page.settings:tab.usersAndEmployees',
        COMPONENTS: {
          MERGE_USERS: 'mergeUsers'
        }
      },
      MEMBERS: {
        PAGE: 'page.settings:tab.members',
        TABS: {
          TORII_USERS: 'page.settings:tab.members:tab.toriiUsers',
          APP_OWNERS: 'page.settings:tab.members:tab.appOwners'
        },
        COMPONENTS: {
          INVITE_MEMBERS_BY_ROLE: 'page.settings:tab.members:component.inviteMembersByRole'
        }
      },
      ROLES: {
        PAGE: 'page.settings:tab.roles',
        COMPONENTS: {
          CUSTOM_ROLES: 'page.settings:tab.roles:component.customRoles'
        }
      },
      APPLICATION_DETAILS: 'page.settings:tab.applicationDetails',
      CONTRACT_DETAILS: 'page.settings:tab.contractDetails',
      CUSTOM_APPS: 'page.settings:tab.customApps',
      HIDDEN_APPS: 'page.settings:tab.hiddenApps',
      EMAILS: 'page.settings:tab.emails',
      SECURITY: 'page.settings:tab.security',
      SECRETS_VAULT: 'page.settings:tab.secretsVault',
      BROWSER_EXTENSION: 'page.settings:tab.browserExtension',
      API_ACCESS: 'page.settings:tab.apiAccess',
      LABS: 'page.settings:tab.labs'
    }
  },
  THIRD_PARTY_APPS: {
    BLOCK_ACCESS: 'blockAccess'
  }
}

export const FEATURE_TO_PLAN = {
  [FEATURES.WORKFLOWS.PAGE]: PLANS.PROFESSIONAL,
  [FEATURES.INTEGRATIONS.TYPES.EXPENSES]: PLANS.PROFESSIONAL,
  [FEATURES.SETTINGS.TABS.ROLES.COMPONENTS.CUSTOM_ROLES]: PLANS.ENTERPRISE,
  [FEATURES.LICENSES.COMPONENTS.BENCHMARK]: PLANS.ENTERPRISE,
  [FEATURES.SETTINGS.TABS.API_ACCESS]: PLANS.PROFESSIONAL,
  [FEATURES.RENEWALS.COMPONENTS.EMAIL_TO_CONTRACT]: PLANS.PROFESSIONAL,
  [FEATURES.OFFBOARDING.PAGE]: PLANS.PROFESSIONAL,
  [FEATURES.SETTINGS.TABS.USERS_AND_EMPLOYEES.COMPONENTS.MERGE_USERS]: PLANS.PROFESSIONAL,
  [FEATURES.REPORTS.TYPES.APP_COMPLIANCE]: PLANS.ENTERPRISE,
  [FEATURES.THIRD_PARTY_APPS.BLOCK_ACCESS]: PLANS.ENTERPRISE
}
