import { css } from 'glamor'
import texts from '@shared/style/texts'
import { fontWeight } from '@shared/style/sizes'
import colors from '@shared/style/colors'
import * as SharedStyle from '../shared/style'

export const NonActiveUsers = css(texts.body, {
  fontWeight: fontWeight.normal,
  color: colors.grey1
})

export const Legend = css({
  color: colors.grey1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  whiteSpace: 'nowrap'
})

export const LegendIcon = css({
  marginRight: '5px'
})

export const LegendLabel = css({
  marginRight: '4px'
})

export const { EmptyStateContainer, MissingDataText } = SharedStyle
