import styled from 'styled-components'
import { fontWeight, fontSize } from '@shared/style/sizes'
import { theme } from '@toriihq/design-system'
import { body } from '@shared/style/textsStyled'

export const OuterDiv = styled.div`
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: right;
`

export const ConvertedAmount = styled.div<{isForRenewalTooltip?: boolean}>`
  font-size: ${props => props.isForRenewalTooltip ? '20px' : fontSize.small};
  font-weight: ${props => props.isForRenewalTooltip ? fontWeight.semiBold : fontWeight.normal};
  margin-top: ${props => props.isForRenewalTooltip ? '2px' : '0'};
  text-align: right;
`

export const OriginalAmount = styled.div<{isForRenewalTooltip?: boolean}>`
  font-size: ${props => props.isForRenewalTooltip ? '12px' : '10px'};
  margin-top: ${props => props.isForRenewalTooltip ? '-1px' : '-4px'};
  font-weight: ${fontWeight.normal};
  text-align: right;
  color: ${theme.palette.text.disabled};
  float: right;
`

export const NoAmountData = styled.div`
  margin-top: 2px;
  ${body};
`
