import React from 'react'
import VisibleFor from '@components/visibleFor'
import { FEATURES } from '@shared/features'
import { pluralizeWithFormat } from '@shared/utils'
import { Icon, Tooltip } from '@toriihq/design-system'
import { GetBlockAccessWarningsProps, SourceWithAppsTitleProps } from './types'

const getBlockAccessWarnings = ({
  shouldDisplayAlertsForSource,
  isBlockThirdPartyAppsEnabled
}: GetBlockAccessWarningsProps) => {
  if (!shouldDisplayAlertsForSource || isBlockThirdPartyAppsEnabled) {
    return null
  }

  return <VisibleFor feature={FEATURES.THIRD_PARTY_APPS.BLOCK_ACCESS}>
    <Tooltip label={'Closed apps have access to sensitive data'}>
      <Icon name='Alert' color='warning' />
    </Tooltip>
  </VisibleFor>
}

const SourceWithAppsTitle = ({
  source,
  shouldDisplayAlertsForSource,
  isBlockThirdPartyAppsEnabled
}: SourceWithAppsTitleProps) => {
  return (
    <>
      <span>
        {source.name} ({pluralizeWithFormat('app', source.apps ? source.apps.length : 0, true)})
      </span>
      {getBlockAccessWarnings({
        shouldDisplayAlertsForSource,
        isBlockThirdPartyAppsEnabled
      })}
    </>
  )
}

export default SourceWithAppsTitle
