import View from './view'
import { connect } from 'react-redux'
import { getCurrentApp } from '@selectors/apps'
import { getIsSmallScreen } from '@selectors/ui'
import { getActiveUsersTrends, isLoadingActiveUsersTrends } from '@selectors/trends'
import moment from 'moment'
import { getUsageAndUsersSources, sortSourcesList, getSourceName } from '@lenses/sources'
import { getSourceByType } from '@root/sourcesConfig'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import { EMPTY_ARRAY, EMPTY_OBJECT, scoreSpecialValues } from '@root/constants'
import get from 'lodash/get'

const getMonthlyActiveUsersPerSources = ({ monthUsageSources, appName, service, isDirectUsageSynced }) => {
  if (!monthUsageSources) {
    return []
  }
  const { sources: currentMonthSources, disabledSource } = getUsageAndUsersSources({ service, defaultSources: Object.keys(monthUsageSources) })
  const { sourceToDisable, reason } = disabledSource
  const sortedCurrentMonthSources = sortSourcesList({ sourcesList: currentMonthSources, appName })

  return sortedCurrentMonthSources.map(source => {
    const sourceConfig = getSourceByType(source)
    const sourceName = source === 'extension' ? source : getSourceName({ sourceConfig, appName })

    const isDirectSourceDisabledInCurrentMonth = !isEmpty(disabledSource) && source === sourceToDisable
    const hasZeroActiveUsers = isDirectUsageSynced && isDirectSourceDisabledInCurrentMonth

    const activeUsers = hasZeroActiveUsers ? 0 : (isDirectSourceDisabledInCurrentMonth ? reason : monthUsageSources[source])
    const key = isNumber(activeUsers) ? `Reported by ${sourceName}` : `${sourceName}`

    return { key, value: activeUsers }
  })
}

const mapStateToProps = (state, ownProps) => {
  const { idApp, service, pageName } = ownProps
  const { app = EMPTY_OBJECT, loading: isLoadingApp } = getCurrentApp(state) || EMPTY_OBJECT
  const activeUsersRaw = getActiveUsersTrends(state)[idApp] || EMPTY_ARRAY

  const appName = get(app, ['name'])
  const score = get(app, ['score'])
  const usageSources = get(app, ['usageSources']) || EMPTY_ARRAY

  const isOnlyExtensionLowUsage = score === scoreSpecialValues.onlyExtensionLowUsage
  const isDirectUsageSynced = usageSources.includes(service.source)
  const { sources, disabledSource } = getUsageAndUsersSources({ service, defaultSources: usageSources, usageType: 'user activity', pageName })

  const data = activeUsersRaw
    .slice()
    .reverse()
    .map((activeUsers) => {
      const activeUsersPerSource = getMonthlyActiveUsersPerSources({ monthUsageSources: activeUsers.usersSourcesCount, appName, service, isDirectUsageSynced })
      return {
        date: activeUsers.date,
        value: activeUsers.activeUsers,
        name: moment.utc(activeUsers.date).format('MMM YYYY'),
        keyValueList: activeUsersPerSource,
        tooltipSubHeader: `users reported by multiple sources\nare counted once`,
        tooltipHeader: 'Unique users'
      }
    })

  const isChartDataLoading = isLoadingActiveUsersTrends(state) && !data.length
  const isNewAppLoading = get(app, ['id']) !== idApp
  const loading = isChartDataLoading || (isLoadingApp && isNewAppLoading)

  const noActiveUsersInLast12Months = !isEmpty(usageSources) && data.every(monthlyActiveUsers => monthlyActiveUsers.value === 0)
  const showEmptyState = !loading && (isEmpty(usageSources) || isOnlyExtensionLowUsage || noActiveUsersInLast12Months)

  return {
    loading,
    isInUse: !isEmpty(app),
    appName,
    data,
    isSmallScreen: getIsSmallScreen(state),
    sources,
    disabledSource,
    isOnlyExtensionLowUsage,
    noActiveUsersInLast12Months,
    showEmptyState
  }
}

export default connect(mapStateToProps)(View)
