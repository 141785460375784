import React from 'react'
import WorkflowActionExecutionsPage from '@pages/workflowActionExecutions'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { WORKFLOW_TYPE_TO_ITEM_NAME, WORKFLOW_TYPES } from '@root/constants'

const AppCatalogPolicyActionExecutionsPage = () => {
  const workflowType = WORKFLOW_TYPES.appCatalog
  const itemName = WORKFLOW_TYPE_TO_ITEM_NAME[workflowType]

  return <WorkflowActionExecutionsPage
    isAppCatalogPoliciesView
    itemName={itemName}
    backlinkText={'Back to policy runs'}
    backLinkPathPrefix={getWorkflowPathPrefix({ workflowType })}
  />
}

export default AppCatalogPolicyActionExecutionsPage
