import {
  OverridableDropdownInput,
  OverrideInput, OverrideValue
} from './types'

export const isOverrideInput = (value?: OverridableDropdownInput): value is OverrideInput => {
  return Boolean((value as any)?.value?.override)
}

export const getOverrideValue = (value: any): OverrideValue => ({
  override: true,
  value
})
