import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { AppIcon, Avatar, Spacer, RadioButton } from '@toriihq/design-system'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { fontWeight } from '@shared/style/sizes'
import { oneLiner } from '@shared/style/mixins'
import Placeholder from '../placeholder'
import capitalize from 'lodash/capitalize'
import { Wrapper } from './styles'

const insideTableRowsClass = '.rt-tr:hover &'
const containerHoverStyle = '.userDetailsContainer:hover &'
const containerStyle = css({
  padding: '4px 0'
})

const nameStyleHover = css({
  color: colors.blue,
  transform: 'translateY(0)'
})
const nameStyle = css(oneLiner, {
  color: colors.black,
  transition: 'color .2s, transform.2s',
  ':not(:last-child)': {
    transform: 'translateY(13px)',
    marginBottom: '3px'
  },
  [insideTableRowsClass]: nameStyleHover,
  [containerHoverStyle]: nameStyleHover
})

const emailStyleHover = css({
  opacity: 1
})
const emailStyle = css(oneLiner, {
  marginBottom: '3px',
  fontWeight: fontWeight.normal,
  opacity: 0,
  transition: 'opacity .2s',
  [insideTableRowsClass]: emailStyleHover,
  [containerHoverStyle]: emailStyleHover
})

const CSS = {
  name: nameStyle,
  email: emailStyle,
  container: containerStyle,
  main: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px'
  }),
  selectIndication: css({
    cursor: 'pointer',
    padding: '8px 21px 8px 11px',
    background: colors.white,
    ':hover': {
      background: `${colors.lightBlue3}`
    },
    ':hover >div': {
      borderColor: `${colors.blue}`
    }
  }),
  content: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  }),
  details: css(texts.subheading, {
    flex: 1,
    overflow: 'hidden'
  })
}

class CustomOptionDetails extends React.Component {
  render () {
    const { label, description, optional, group, onSelect, isSelected, selectIndication, loading, overrideStyle, app } = this.props

    const content = (
      <Wrapper>
        <Spacer right={'space-100'}>
          { app
            ? <AppIcon appName={app.name} appImageUrl={app.imageUrl} />
            : <Avatar />
          }
        </Spacer>
        <div {...CSS.details}>
          <div {...CSS.name}>{label} {group && `(${capitalize(group)})`}</div>
          {description && <div title={description} {...CSS.email}>
            {description}
            {optional && <span>. If there are none, a notification will be sent to admins.</span>}
          </div>}
        </div>
      </Wrapper>
    )

    return (
      <div className='userDetailsContainer' {...css(CSS.container, overrideStyle)}>
        <Placeholder loading={loading} type='text' rows={2} style={{ height: '40px', width: '50px' }}>
          <div {...css(CSS.main, selectIndication && CSS.selectIndication)} data-is-selected={isSelected} onClick={onSelect}>
            {content}
            {selectIndication && (
              <RadioButton checked={isSelected} />
            )}
          </div>
        </Placeholder>
      </div>
    )
  }
}

CustomOptionDetails.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  optional: PropTypes.bool,
  loading: PropTypes.bool,
  overrideStyle: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  selectIndication: PropTypes.bool,
  group: PropTypes.string
}

CustomOptionDetails.defaultProps = {
  selectIndication: false,
  onSelect: () => {},
  group: ''
}

export default CustomOptionDetails
