import UserDetails from '@components/userDetails'
import { Icon, Tooltip } from '@toriihq/design-system'
import * as Style from '@components/appUsers/styles'
import UsageIcon from '@components/usageIcon'
import { getFormattedDate } from '@lenses/utils'
import SourceIcon from '@components/sourceIcon'
import userToolTip from '@shared/userToolTip'
import { getDisplayName } from '@lenses/users'
import pluralize from 'pluralize'
import uniq from 'lodash/uniq'
import startCase from 'lodash/startCase'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import React from 'react'

export const getColumns = ({ tableInfo, appName }) => {
  const { columnsConfiguration, dynamicColumnsInfo } = tableInfo
  return [
    {
      Header: 'User',
      id: 'fullName',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 220,
      Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, lifecycleStatus, isDeletedInIdentitySources } }) => (
        <UserDetails
          firstName={firstName}
          lastName={lastName}
          email={email}
          idUser={idUser}
          isExternal={isExternal}
          photoUrl={photoUrl}
          lifecycleStatus={lifecycleStatus}
          isDeletedInIdentitySources={isDeletedInIdentitySources}
          showPastUserBadge
        />
      )
    },
    {
      id: 'originEmail',
      Header: 'Email',
      accessor: 'originEmail',
      Cell: ({ row: { originEmail } }) => originEmail,
      show: Boolean(columnsConfiguration['originEmail'])
    },
    {
      Header: 'Has license',
      idForColumnsOrder: 'licenses.name',
      accessor: 'licenses',
      style: { display: 'flex', alignItems: 'center' },
      Cell: ({ row: { licenses = [] } }) => {
        if (!licenses.length) {
          return '-'
        }
        return <Tooltip
          label={licenses.map(license => <div key={license.type}>{license.name}</div>)}
          placement='top'>
          <Icon name='CheckCircleFill' color='interactive' />
        </Tooltip>
      },
      show: Boolean(columnsConfiguration['licenses.name'])
    },
    {
      id: 'licenseName',
      Header: 'Licenses',
      Cell: ({ row: { licenses = [] } }) => {
        if (!licenses.length) {
          return '-'
        }
        return licenses.map(license => <div key={license.type}>{license.name}</div>)
      },
      show: Boolean(columnsConfiguration['licenseName'])
    },
    {
      Header: (
        <Tooltip
          label={<Style.UsageHeaderTooltipLabel>Usage is based on visits to {appName} in the last 30 days</Style.UsageHeaderTooltipLabel>}
        >
          Usage
        </Tooltip>
      ),
      textHeader: 'Usage',
      accessor: 'score',
      style: { display: 'flex', alignItems: 'center' },
      Cell: ({ value: score, row: { lastVisitTime } }) => <UsageIcon isUserUsage score={score} lastVisitTime={lastVisitTime} />,
      show: Boolean(columnsConfiguration.score)

    },
    {
      Header: 'Account',
      accessor: 'appAccountName',
      Cell: ({ row: { appAccountName } }) => appAccountName || '-',
      show: Boolean(columnsConfiguration.appAccountName)
    },
    {
      Header: 'Last used date',
      accessor: 'lastVisitTime',
      Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
      minWidth: 200,
      show: Boolean(columnsConfiguration.lastVisitTime)
    },
    {
      Header: 'Sources',
      accessor: 'sources',
      Cell: ({ row: { firstName, lastName, sources, email } }) => (
        <div>
          {(sources || []).map(source => (<SourceIcon key={source} sourceType={source} tooltipText={userToolTip({ displayName: getDisplayName({ firstName, lastName, email }), appName, source })} />))}
        </div>
      ),
      show: Boolean(columnsConfiguration.sources)
    },
    {
      Header: 'License source',
      accessor: 'licenses.source',
      Cell: ({ row: { licenses = [], firstName, lastName, email } }) => {
        const getTooltipText = ({ firstName, lastName, email, appName, source }) => {
          const displayName = getDisplayName({ firstName, lastName, email })
          const LICENSE_SOURCE_MANUAL_TYPE = 'manual'
          if (source === LICENSE_SOURCE_MANUAL_TYPE) {
            return `${displayName}'s ${pluralize('license', licenses.length)} ${pluralize('was', licenses.length)} imported via a file upload`
          } else {
            return `${displayName}'s ${pluralize('license', licenses.length)} ${pluralize('was', licenses.length)} synced from ${appName}`
          }
        }

        const uniqueLicenseSources = uniq(licenses.map(license => license.source))

        return <div>
          {uniqueLicenseSources.map(source => (<SourceIcon key={source} sourceType={source} tooltipText={getTooltipText({ firstName, lastName, email, appName, source })} />))}
        </div>
      },
      show: Boolean(columnsConfiguration['licenses.source']),
      sortable: false
    },
    {
      Header: 'Status in app',
      accessor: 'externalStatus',
      Cell: ({ value: status }) => status || '-',
      show: Boolean(columnsConfiguration.externalStatus)
    },
    {
      Header: 'Is past user',
      accessor: 'isDeletedInIdentitySources',
      Cell: ({ value: isDeletedInIdentitySources }) => isDeletedInIdentitySources ? 'Yes' : 'No',
      show: Boolean(columnsConfiguration.isDeletedInIdentitySources)
    },
    {
      Header: 'Role',
      id: 'role',
      accessor: ({ role }) => {
        const roleSplit = (role || '-').split(',')
        return roleSplit.map(role => startCase(role).replace('_', ' ')).join(', ')
      },
      show: Boolean(columnsConfiguration.role)
    },
    {
      Header: 'User type',
      accessor: 'userType',
      Cell: ({ row: { userType } }) => userType || '-',
      show: Boolean(columnsConfiguration.userType)
    },
    {
      accessor: 'reason',
      show: false
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'docId',
      show: false
    },
    {
      accessor: 'lifecycleStatus',
      show: false
    }
  ].concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
}
