import { css } from 'glamor'

export const Container = css({
  display: 'flex',
  flexShrink: 1,
  width: '185px',
  flexDirection: 'column'
})

export const Row = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  width: '185px',
  maxHeight: '32px'
})

export const AppDetails = css({
  width: '160px'
})
