import { css } from 'glamor'

export const ACTIVE_USERS_WIDGET = 'Active Users'
export const USAGE_WIDGET = 'Usage'

export const MAP_WIDGET_TO_NAME = {
  [ACTIVE_USERS_WIDGET]: 'user activity',
  [USAGE_WIDGET]: 'usage'
}

export const WidgetContainer = css({
  minWidth: 1024
})

export const AlertBox = css({
  marginTop: '5px'
})
