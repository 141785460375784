import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, AvatarGroup, Tooltip } from '@toriihq/design-system'
import { Wrapper } from './styles'
import { getDisplayName } from '@lenses/users'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import compact from 'lodash/compact'

const UsersIconsList = ({
  center = false,
  users,
  maxUsers = users.length
}) => {
  const showMoreUsers = maxUsers < users.length
  const getAllUsersLabel = () => {
    return compact(users).map(({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email })).join(', ')
  }

  const getTooltipText = ({ firstName, lastName, email }) => (
    <>
      <div>{getDisplayName({ firstName, lastName })}</div>
      <div>{email}</div>
    </>
  )

  return (
    <Wrapper center={center}>
      <Tooltip hide={!showMoreUsers} label={getAllUsersLabel()}>
        <AvatarGroup max={maxUsers}>
          {compact(users).map(({ id, firstName, lastName, photoUrl, email }) => {
            return (
              <Tooltip key={id} hide={showMoreUsers} label={getTooltipText({ firstName, lastName, email })}>
                <RelativeTeamUserLink idUser={id}>
                  <Avatar
                    key={id}
                    firstName={firstName}
                    lastName={lastName}
                    imageUrl={photoUrl}
                  />
                </RelativeTeamUserLink>
              </Tooltip>
            )
          })}
        </AvatarGroup>
      </Tooltip>
    </Wrapper>
  )
}

UsersIconsList.propTypes = {
  center: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  maxUsers: PropTypes.number,
  totalUsersCount: PropTypes.number
}

export default UsersIconsList
