import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@toriihq/design-system'

const TicketCompletionStatusInfoTooltip = ({ tooltipText }) => {
  if (!tooltipText) {
    return null
  }

  return (
    <Tooltip label={tooltipText}>
      <Icon name='Info' color='secondary' />
    </Tooltip>
  )
}

TicketCompletionStatusInfoTooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired
}

export default TicketCompletionStatusInfoTooltip
