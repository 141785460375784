import React from 'react'
import BarsChartBox from '../barsChartBox'
import colors from '../../shared/style/colors'
import { safeWindowOpen } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import NotEnoughUsageChart from '@media/availability.svg'
import { Button, ButtonType, Link } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'
import { useHistory } from 'react-router-dom'

const getEmptyStateInfoNoUsage = (appName) => {
  const onClick = () => {
    Analytics.track('Click on learn how application usage data is synced with Torii', {
      from: 'Empty state - usage trend'
    })
    safeWindowOpen('https://support.toriihq.com/hc/en-us/articles/5129309596955')
  }
  return {
    image: <img src={NotEnoughUsageChart} alt='No usage data' />,
    description: <div>Torii does not have data about application usage.<br />Make sure {appName} is connected to your SSO provider and browser extension is fully deployed.</div>,
    link: <Link onClick={onClick}>Read how to enrich app usage data</Link>
  }
}

const getEmptyStateInfoNoUsageInLast12Months = (service = {}, appName) => {
  const { supportsUsage, helpCenterLink } = service
  const isServiceSupportsUsage = !isEmpty(service) && supportsUsage

  const baseText = `Torii did not detect any app usage in last 12 months..`
  const subHeaderText = `${baseText} ${isServiceSupportsUsage ? `Make sure you are syncing usage from ${appName} API.` : `Make sure  ${appName} is connected to your to SSO provider and browser extension is fully deployed.`}`

  const linkText = isServiceSupportsUsage ? `Read more in Torii help center` : `Read how to enrich app usage data`
  const link = isServiceSupportsUsage ? helpCenterLink : 'https://support.toriihq.com/hc/en-us/articles/5129309596955'

  return {
    image: <img src={NotEnoughUsageChart} alt='No usage data in last 12 months' />,
    description: subHeaderText,
    link: <Link href={link} target='_blank'>{linkText}</Link>
  }
}

const getEmptyStateInfoOnlyExtensionLowUsage = (idOrg, history) => {
  const onClickButton = () => {
    Analytics.track('Click on view extension report link', {
      from: 'Empty state - App usage (app page chart)'
    })
    history.push(`/team/${idOrg}/reports/extensionRollout`)
  }

  const onClickLink = () => {
    Analytics.track('Click on Read more in Torii help center', {
      from: 'Empty state - App usage (app page chart)'
    })
    safeWindowOpen('https://support.toriihq.com/hc/en-us/articles/5129309596955')
  }
  return {
    image: <img src={NotEnoughUsageChart} alt='Not enough data to display in app usage trend chart' />,
    description: 'Torii does not have enough data to display app usage trend. The app has usage only from browser extension that is not fully deployed.',
    buttons: [<Button type={ButtonType.secondary} onClick={onClickButton} label='View extension status report' />],
    link: <Link onClick={onClickLink}>Read how to enrich app usage data</Link>
  }
}

const UsageTrendChartBox = ({ idOrg, usage, isInUse, loading, appName, service, overrideStyle, sources, isSmallScreen, disabledSource, isOnlyExtensionLowUsage, noAppUsageInLast12Months, showEmptyState }) => {
  const history = useHistory()
  if (!isInUse && !loading) {
    return null
  }

  const emptyStateInfo = isOnlyExtensionLowUsage
    ? getEmptyStateInfoOnlyExtensionLowUsage(idOrg, history)
    : noAppUsageInLast12Months
      ? getEmptyStateInfoNoUsageInLast12Months(service, appName)
      : getEmptyStateInfoNoUsage(appName)

  return (
    <div {...overrideStyle}>
      <BarsChartBox
        loading={loading}
        header='Application usage'
        description='Average number of days with user activity'
        data={usage}
        sources={sources}
        emptyStateInfo={emptyStateInfo}
        showEmptyState={showEmptyState}
        fill={colors.lightBlue4}
        yMaxVal={30}
        yNumberOfTicks={4}
        appName={appName}
        isSmallScreen={isSmallScreen}
        disabledSource={disabledSource}
      />
    </div>
  )
}

export default UsageTrendChartBox
