import React from 'react'
import WorkflowExecutionsPage from '@pages/workflowExecutions'
import { WORKFLOW_TYPES } from '@root/constants'
import { getWorkflowPathPrefix, getWorkflowsPathPrefix } from '@lenses/workflows'

const AppCatalogPolicyExecutionsPage = () => {
  const workflowType = WORKFLOW_TYPES.appCatalog
  return <WorkflowExecutionsPage
    isAppCatalogPoliciesView
    backlinkText={'Back to policies'}
    backLinkPath={getWorkflowsPathPrefix({ workflowType })}
    executionsPathPrefix={getWorkflowPathPrefix({ workflowType })}
  />
}

export default AppCatalogPolicyExecutionsPage
