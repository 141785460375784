import styled from 'styled-components'
import { Col } from '@toriihq/design-system'

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const ColWithMargin = styled(Col)`
  margin-bottom: ${({ theme }) => theme.corner(['space-400'])}
`
