import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import * as Style from './style'

type Props = {
  isOpen: boolean,
  onCloseAction: () => void,
  text: string
}

const TermsAndConditionsPopup = ({ isOpen, onCloseAction, text }: Props): JSX.Element => {
  return <ToriiPopup isOpen={isOpen} onCloseAction={onCloseAction}>
    <ToriiPopup.Header header='Terms and conditions' />
    <ToriiPopup.Content>
      <Style.Body>
        <div>
          <Style.Text>{text}</Style.Text>
        </div>
      </Style.Body>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      showCancelButton={false}
      mainButtonAction={onCloseAction}
      mainButtonText='Got it'
    />
  </ToriiPopup>
}

export default TermsAndConditionsPopup
