import { INACTIVE_REASON } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import maxBy from 'lodash/maxBy'
import moment from 'moment'

const getNameFromEmail = (email, emptyValue) => {
  return email ? email.split('@')[0] : emptyValue
}

export const getDisplayName = ({ firstName, lastName, email, emptyValue = 'N/A' }) => {
  return (firstName) ? (lastName ? [firstName, lastName].join(' ') : firstName) : getNameFromEmail(email, emptyValue)
}

export const hasActiveAppsFilter = user => (user.activeAppsCount > 0)

export const inactiveLicenseReasonToText = ({ reason, externalStatus, appNotInUsePeriod }) => {
  if ((reason === 'noAccess') && externalStatus) {
    return `User is ${externalStatus.toLowerCase()}`
  }

  if (!INACTIVE_REASON[reason]) {
    return ''
  }

  return INACTIVE_REASON[reason](appNotInUsePeriod)
}

export const getOffboardingByUserFromAudit = (allOffBoardingDoneAudit) => {
  const offboardedAudits = allOffBoardingDoneAudit.filter(auditLog => (JSON.parse(auditLog.properties) || {}).status === USER_LIFECYCLE_STATUS.OFFBOARDED)
  const offboardedMaxAudit = maxBy(offboardedAudits, 'creationTime')
  const audit = maxBy(allOffBoardingDoneAudit, 'creationTime')

  return offboardedMaxAudit && moment(offboardedMaxAudit.creationTime).isSameOrAfter(audit.creationTime) ? offboardedMaxAudit : audit
}
