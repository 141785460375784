import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'

const CSS = {
  main: css({
    label: 'Card',
    backgroundColor: colors.white,
    border: `solid 1px ${colors.border}`,
    padding: '15px',
    borderRadius: '4px',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.05)'
  })
}

const Card = (props) => {
  const { children, ...rest } = props
  return (
    <div {...CSS.main} {...rest}>
      {children}
    </div>
  )
}

export default Card
