import styled from 'styled-components'

export namespace Styles {
  export const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
  `

  export const Header = styled.div`
    font: ${({ theme }) => theme.typography.font.header04};
    padding-bottom: 24px;
  `

  export const MainContent = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  `

  export const InstallDescription = styled.div`
    font: ${({ theme }) => theme.typography.font.body02};
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};

    a {
      color: ${({ theme }) => theme.palette.link.primary};
      font: ${({ theme }) => theme.typography.font.component03};
    }
  `

  export const Link = styled.a`
    color: ${({ theme }) => theme.palette.link.primary} !important;
    font: ${({ theme }) => theme.typography.font.component03} !important;
    font-weight: normal;
    cursor: pointer;
    transition: color .2s;
    
    &:active,
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.link.primary} !important;
    }
  `
}
