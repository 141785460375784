export const isTriggerStepValid = ({ workflow, step, currentStepIndex, supportSchedule }) => {
  let isValid = step.fields.every(field => ((workflow.triggerConfiguration || []).find(fieldValue => fieldValue.id === field) || {}).isValid)

  const scheduleStepIndex = 0
  if (supportSchedule && currentStepIndex === scheduleStepIndex) {
    const isScheduleValid = !workflow.schedules || workflow.schedules.isValid
    isValid = isValid && isScheduleValid
  }

  return isValid
}

export const getTriggerValidationObj = ({ isTriggerValid }) => {
  return isTriggerValid
    ? { isValid: true, errorMsg: '' }
    : { isValid: false, errorMsg: 'Some fields are missing or invalid' }
}
