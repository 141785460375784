import {
  ActionNodeModel,
  BranchNodeModel,
  WORKFLOW_NODE_TYPE,
  WORKFLOW_ACTION_TYPE
} from '@shared/workflows/types'
import { getNewBranchFilter } from '@components/filters/ifElseBranchFilters/utils/getNewBranchFilter'
import { EntityOption } from '@components/filters/ifElseBranchFilters/types'

export const getNewBranchActionNode = (
  currentActionNode: ActionNodeModel,
  entityOptions: EntityOption[]
): BranchNodeModel => {
  const defaultFilter = getNewBranchFilter({ entityOptions })
  const validationProperties = { isValid: false, errorMsg: '' }

  return {
    type: WORKFLOW_NODE_TYPE.BRANCH_ACTION,
    action: {
      type: WORKFLOW_ACTION_TYPE.IF_ELSE_BRANCH,
      fields: [{
        id: 'filters',
        value: {
          branches: [{
            label: 'Branch 1',
            filters: [defaultFilter],
            next: currentActionNode.next,
            ...validationProperties
          }],
          default: { next: null }
        },
        ...validationProperties
      }],
      ...validationProperties
    }
  }
}
