import { Workflow } from '@shared/workflows/types'
import { useParamSelector } from '@shared/utils'
import { getAppCatalogPolicySelector, getAppCatalogRequestNewAppPolicySelector, getWorkflow } from '@selectors/workflows'
import { useParams } from 'react-router-dom'
import { useWorkflowEditorContext } from '../workflowEditor/context'

export const useSelectedWorkflow = (): Workflow => {
  const { idWorkflow } = useParams()
  const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = useWorkflowEditorContext()
  const selector = isAppCatalogPoliciesView ? getAppCatalogPolicySelector : isRequestNewAppPoliciesView ? getAppCatalogRequestNewAppPolicySelector : getWorkflow

  const workflow = useParamSelector(selector, { idWorkflow })
  if (!workflow) {
    throw new Error(`Failed to get selected workflow for idWorkflow:[${idWorkflow}]`)
  }
  return workflow
}
