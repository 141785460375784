import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import SelectDraggableOptions from '../../selectDraggableOptions'
import { SCOPES } from '../../../constants'

const CSS = {
  optionDescription: css({
    color: colors.grey1,
    textTransform: 'none'
  })
}

class SelectFormFields extends React.Component {
  render () {
    const { options, value, onChange, overrideStyle } = this.props
    const optionRenderer = (option) => {
      return <div>
        <div>{option.label}</div>
        <div {...CSS.optionDescription}>{option.formQuestion}</div>
      </div>
    }

    return (
      <SelectDraggableOptions
        overrideStyle={overrideStyle}
        optionRenderer={optionRenderer}
        onChange={onChange}
        value={value}
        options={options}
        placeholderText={'+ Add field'}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
      />
    )
  }
}

SelectFormFields.propTypes = {
  overrideStyle: PropTypes.object,
  value: PropTypes.array,
  onChange: PropTypes.func
}

export default SelectFormFields
