import React from 'react'
import PropTypes from 'prop-types'
import {
  AppIcon,
  Button,
  ButtonType,
  ExpandableSection,
  Counter,
  AlertBox,
  AlertBoxType
} from '@toriihq/design-system'
import Table from '../../table'
import { css } from 'glamor'
import AppDetails from '../../appDetails'
import colors from '../../../shared/style/colors'
import ErrorMessage from '../errorMessage'
import { hoverActions } from '../../../shared/style/mixins'
import EnableFor from '../../enableFor'
import { SCOPES } from '../../../constants'
import * as Style from '../styles'

const MAX_APPS_ICONS = 20
const APP_ICONS_HEIGHT = 32

const CSS = {
  container: css({
    border: `1px solid ${colors.grey3}`,
    borderRadius: '4px',
    padding: '24px 21px'
  }),
  actionButton: css({
    maxWidth: '150px',
    textAlign: 'right'
  }),
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  errorMessage: css({
    textAlign: 'right'
  })
}

class NotConfigured extends React.Component {
  state = {
    isOpen: false
  }
  columns = this.getColumns()

  onConfigureAppClick = (app, showAlertBox) => {
    const { toggleConfigureAppForOffboarding, refreshDataFunc } = this.props

    toggleConfigureAppForOffboarding({
      isOpen: true,
      idApp: app.id,
      idAppAccount: app.idAppAccount,
      appAndAccountName: app.appAndAccountName,
      actions: app.actions,
      onClose: refreshDataFunc,
      pendingOffboardingAmount: app.pendingOffboardingAmount,
      showAlertBox
    })
  }

  getColumns () {
    const { showButtonsOnlyOnHover, showAlertBox } = this.props

    return [
      {
        id: 'name',
        accessor: ({ idAppAccount, appAccountName }) => [idAppAccount, appAccountName].join('-').trim().toLowerCase(),
        Header: 'Name',
        maxWidth: 300,
        minWidth: 200,
        className: CSS.nameAndIcon.toString(),
        Cell: ({ row: { idApp, name, category, imageUrl, appAndAccountName } }) => {
          return <AppDetails
            id={idApp}
            name={appAndAccountName}
            category={category}
            imageUrl={imageUrl}
            component='Offboarding settings'
          />
        }
      },
      {
        Header: 'Pending offboardings',
        accessor: 'pendingOffboardingAmount',
        showFunc: () => this.props.showPendingAmount,
        Cell: ({ value: pendingOffboardingAmount }) => pendingOffboardingAmount || null
      },
      {
        id: 'errorMessage',
        Header: '',
        sortable: false,
        className: CSS.errorMessage.toString(),
        minWidth: 100,
        Cell: ({ row: { executionErrorType, name } }) => (executionErrorType && <ErrorMessage errorType={executionErrorType} appName={name} />) || null
      },
      {
        id: 'configButton',
        Header: '',
        sortable: false,
        className: `${CSS.actionButton.toString()} ${showButtonsOnlyOnHover ? hoverActions : ''}`,
        Cell: ({ row: { idApp, name, idAppAccount, appAndAccountName, actions, pendingOffboardingAmount } }) => {
          return <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <Button
              type={ButtonType.secondary}
              onClick={() => this.onConfigureAppClick({ id: idApp, idAppAccount, name, appAndAccountName, actions, pendingOffboardingAmount }, showAlertBox)}
              label='Configure'
            />
          </EnableFor>
        }
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'name',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        id: 'idApp',
        accessor: 'id',
        show: false
      },
      {
        accessor: 'idAppAccount',
        show: false
      },
      {
        accessor: 'executionErrorType',
        show: false
      },
      {
        accessor: 'appAndAccountName',
        show: false
      },
      {
        accessor: 'actions',
        show: false
      }
    ]
  }

  alertBoxContent = () => {
    const { alertBoxContent } = this.props

    if (!alertBoxContent) {
      return null
    }

    return <AlertBox type={AlertBoxType.INFORMATIVE} description={alertBoxContent} />
  }

  bottomSlot = () => {
    const { isOpen } = this.state
    const { apps, forceOpen, maxAppIcons } = this.props

    const hasData = apps.length > 0

    if (!hasData) {
      return null
    }

    if (isOpen || forceOpen) {
      return this.alertBoxContent()
    }

    const renderedApps = apps.slice(0, maxAppIcons).map(app =>
      <Style.AppIconsWrapper>
        <AppIcon key={`${app.id}-${app.idAppAccount}`} appName={app.name} appImageUrl={app.imageUrl} />
      </Style.AppIconsWrapper>
    )

    return <Style.BottomSlotWrapper>
      {this.alertBoxContent()}
      <Style.AppIconsWrapper>{renderedApps} {apps.length > maxAppIcons ? `+${apps.length - maxAppIcons}` : null}</Style.AppIconsWrapper>
    </Style.BottomSlotWrapper>
  }

  render () {
    const { isOpen } = this.state
    const { header, description, apps, tableKey, startAsOpen, loading, forceOpen, tableWrapperId = 'notConfiguredTableWrapper', headerHeight } = this.props
    const hasData = apps.length > 0
    const headerElement = <div>{header} <Counter value={loading ? 0 : apps.length} /></div>

    return (
      <ExpandableSection
        key={tableWrapperId}
        isOpen={!hasData ? false : (isOpen || startAsOpen || forceOpen)}
        bottomSlot={this.bottomSlot()}
        title={headerElement}
        subtitle={description}
        headerHeight={(isOpen || forceOpen) ? headerHeight - APP_ICONS_HEIGHT : headerHeight}
        disabled={!hasData}
        onToggle={isOpen => { this.setState({ isOpen }) }}
        maxHeight={450}
      >
        <Table
          tableKey={tableKey}
          data={apps}
          columns={this.columns}
          scrollObjectId={tableWrapperId}
          style={{ minHeight: '100px' }}
          tableHeaderStyle={{ padding: 0, paddingTop: 8 }}
          forceShowNumberOfResults
        />
      </ExpandableSection>
    )
  }
}

NotConfigured.propTypes = {
  idOrg: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.node,
  apps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    pendingOffboardingAmount: PropTypes.number
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  tableKey: PropTypes.string.isRequired,
  startAsOpen: PropTypes.bool,
  forceOpen: PropTypes.bool,
  showAlertBox: PropTypes.bool,
  maxAppIcons: PropTypes.number
}

NotConfigured.defaultProps = {
  showPendingAmount: false,
  startAsOpen: false,
  forceOpen: false,
  showAlertBox: false,
  maxAppIcons: MAX_APPS_ICONS
}

export default NotConfigured
