import React from 'react'
import { css } from 'glamor'
import { safeRedirect } from '../../shared/utils'
import Spinner from '@media/spinner.gif'
import NotFoundImg from '@media/404.svg'
import { H3 } from '@toriihq/design-system'

const CSS = {
  textAndIconBox: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
  }),
  loading: css({
    width: '100%',
    textAlign: 'center',
    margin: '10px 0 10px 0'
  })
}

class InstantAccessLogin extends React.Component {
  componentDidMount () {
    const { instantAccessLogin, token } = this.props
    instantAccessLogin({ token })
  }

  componentDidUpdate (prevProps) {
    if (this.props.loading !== prevProps.loading && this.props.isLoggedIn) {
      safeRedirect(this.props.redirectUrl)
    }
  }

  renderAuthError () {
    return <div {...CSS.textAndIconBox}>
      <img alt='Not found' src={NotFoundImg} width='110' />
      <H3>Something went wrong</H3>
    </div>
  }

  render () {
    const { loading, isLoggedIn } = this.props
    const spinner = <div {...CSS.loading}><img alt='Loading...' src={Spinner} width='40px' height='40px' /></div>

    return <>
      { loading && spinner }
      { !loading && !isLoggedIn && this.renderAuthError()}
    </>
  }
}

export default InstantAccessLogin
