import { getSourceByType } from '../sourcesConfig'

const userToolTip = ({ displayName, appName, source }) => {
  const sourceConfig = getSourceByType(source)
  if (sourceConfig.userTooltip) {
    return sourceConfig.userTooltip({ displayName, appName, source })
  }

  return `${displayName} is a ${sourceConfig.name} user`
}

export default userToolTip
