
export enum DATA_CHANGE_TYPE {
  DELETE = 'delete',
  RESET = 'reset',
  REGULAR = 'regular',
  UPDATE = 'update'
}

export interface PreviewDataRaw {
  id: number
  originalIdApp: number
  originalAppName: string
  idAppAccount: number
  appAccountName: string
  source: string
  useCase: DATA_CHANGE_TYPE
}

export interface DiscoveredAppsPreviewPopupProps {
  isOpen: boolean
  onMainActionButton?: any,
  onCancelButton?: any,
}
