import { barCellsColors } from '@components/waterfallChartBox/chart'
import { bucketDisplayName, RenewalBuckets, TRANSPARENT } from './utils'

export const barsConfig = ({ CustomBar, CustomizedLabel, onBucketChange }) => [
  {
    dataKey: 'pv',
    fill: TRANSPARENT,
    shape: CustomBar
  },
  {
    dataKey: 'Not renewing this year',
    fill: barCellsColors[0],
    shape: CustomBar,
    onClick: () => onBucketChange({
      value: RenewalBuckets.notRenewingThisYear,
      label: bucketDisplayName[RenewalBuckets.notRenewingThisYear]
    })
  },
  {
    dataKey: 'Up for renewal',
    fill: barCellsColors[1],
    shape: CustomBar
  },
  {
    dataKey: 'New contracts',
    fill: barCellsColors[7],
    shape: CustomBar,
    onClick: () => onBucketChange({
      value: RenewalBuckets.newContract,
      label: bucketDisplayName[RenewalBuckets.newContract]
    })
  },
  {
    dataKey: 'Cost increase',
    fill: barCellsColors[7],
    shape: CustomBar,
    onClick: () => onBucketChange({
      value: RenewalBuckets.costIncreased,
      label: bucketDisplayName[RenewalBuckets.costIncreased]
    })
  },
  {
    dataKey: 'Renewed contracts',
    fill: barCellsColors[1],
    shape: CustomBar
  },
  {
    dataKey: 'Renewal forecast',
    fill: barCellsColors[2],
    shape: CustomBar
  },
  {
    dataKey: 'totalLabel',
    fill: TRANSPARENT,
    shape: CustomBar,
    labelList: { dataKey: 'total', position: 'centerTop', content: CustomizedLabel }
  },
  {
    dataKey: 'Cost decrease',
    fill: barCellsColors[12],
    shape: CustomBar,
    onClick: () => onBucketChange({
      value: RenewalBuckets.costDecreased, label: bucketDisplayName[RenewalBuckets.costDecreased]
    })
  },
  {
    dataKey: 'Closed app',
    fill: barCellsColors[12],
    shape: CustomBar,
    onClick: () => onBucketChange({
      value: RenewalBuckets.appIsClosed, label: bucketDisplayName[RenewalBuckets.appIsClosed]
    })
  }
]

export const legendMapping = [
  { value: 'Not renewing this year', color: barCellsColors[0] },
  { value: 'Up for renewal / Renewed', color: barCellsColors[1] },
  { value: 'Renewal forecast', color: barCellsColors[2] },
  { value: 'Cost decrease / Closed app', color: barCellsColors[12] },
  { value: 'Cost increase / New contracts', color: barCellsColors[7] }
]
