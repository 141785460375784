import React, { ReactElement } from 'react'
import Placeholder from '@components/placeholder'
import { AnalyticsInfo, WorkflowEditHistoryInfo } from './auditLog.types'
import Table from '@components/table'
import { EMPTY_ARRAY, EMPTY_OBJECT, TABLES } from '@root/constants'
import { getColumns } from '@components/auditLogs/columns'
import { getWorkflowEditHistoryLogs } from '@actions/workflows'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingMoreWorkflowsEditHistoryLogs, getWorkflowsEditHistoryLogs, isLoadingWorkflowsEditHistoryLogs } from '@selectors/workflows'
import { last } from 'lodash'

interface Props {
  idOrg: number,
  idWorkflow: number,
  openURLPrefix: string,
  openURLAnalytics: AnalyticsInfo
}

const AuditLogs = ({ idOrg, idWorkflow, openURLPrefix, openURLAnalytics }: Props): ReactElement => {
  const dispatch = useDispatch()

  const loading = useSelector(isLoadingWorkflowsEditHistoryLogs)
  const loadingMore = useSelector(isLoadingMoreWorkflowsEditHistoryLogs)
  const workflowEditHistoryInfo: WorkflowEditHistoryInfo = useSelector(getWorkflowsEditHistoryLogs)[idWorkflow]

  const { users, workflowEditHistoryLogs, total } = workflowEditHistoryInfo || { users: EMPTY_OBJECT, workflowEditHistoryLogs: EMPTY_ARRAY, total: null }
  const isLoading = loading && workflowEditHistoryLogs.length === 0

  const fetchEditHistoryLogs = (reset = false) => {
    const lastIdWorkflowEditHistory = reset ? null : last(workflowEditHistoryLogs)?.id
    dispatch(getWorkflowEditHistoryLogs({ idOrg, idWorkflow, lastIdWorkflowEditHistory, reset }))
  }

  return (
    <Placeholder loading={isLoading} rows={10} style={{ maxWidth: '80%', marginTop: '10px' }}>
      <Table
        tableKey={TABLES.workflowEditHistoryLogs.key}
        loading={isLoading}
        emptyStateMessage='No history edits found'
        data={workflowEditHistoryLogs}
        fetchData={fetchEditHistoryLogs}
        columns={getColumns({ users, openURLPrefix, openURLAnalytics })}
        manual
        totalCount={total}
        scrollObjectId='contentArea'
        loadingMore={loadingMore}
        alternativeHeader={<div />}
        overrideTheadStyle={{ display: 'none' }}
        style={{ borderTop: 'none' }}
      />
    </Placeholder>
  )
}

export default AuditLogs
