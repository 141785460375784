import React from 'react'
import PropTypes from 'prop-types'
import { WORKFLOW_STEP_TYPES, WORKFLOW_TYPES } from '../../../../constants'
import StepSetup from '../../../workflows/stepSetup'

const typeToComponent = {
  [WORKFLOW_STEP_TYPES.STEP_SETUP]: StepSetup
}

class EditWorkflowAction extends React.Component {
  render () {
    const {
      onChange,
      componentType,
      actionConfig,
      action,
      fields,
      actionLabel,
      triggerType,
      noBorder,
      hiddenFieldsIds = [],
      triggerAppAndAccount
    } = this.props
    const Component = typeToComponent[componentType]
    return <>
      {Component && <Component
        actionConfig={actionConfig}
        action={action}
        fields={fields}
        header={actionLabel}
        triggerType={triggerType}
        onChange={onChange}
        prevActions={[]}
        noBorder={noBorder}
        hiddenFieldsIds={hiddenFieldsIds}
        workflowType={WORKFLOW_TYPES.offboarding}
        triggerAppAndAccount={triggerAppAndAccount}
      />}
    </>
  }
}

EditWorkflowAction.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    idApp: PropTypes.number,
    fields: PropTypes.array
  }).isRequired,
  actionLabel: PropTypes.string,
  triggerType: PropTypes.string,
  triggerAppAndAccount: PropTypes.shape({
    idApp: PropTypes.number,
    idAppAccount: PropTypes.number
  })
}

export default EditWorkflowAction
