import React from 'react'
import PropTypes from 'prop-types'
import emptyImage from '@media/cage.svg'
import { INACCESSIBLE_PAGE } from '@root/constants'
import { EmptyState } from '@toriihq/design-system'
import * as Style from './style'

const InaccessiblePage = ({ title = INACCESSIBLE_PAGE.TITLE, description = INACCESSIBLE_PAGE.DESCRIPTION }) => {
  return (
    <Style.Container>
      <EmptyState
        image={<img src={emptyImage} alt={title} />}
        title={title}
        description={description}
      />
    </Style.Container>
  )
}

InaccessiblePage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export default InaccessiblePage
