import React from 'react'
import { type Props } from './types'
import * as Style from './style'
import { Grid, Row, Col, RowWithoutMargin } from '@toriihq/design-system'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import Markdown from 'markdown-to-jsx'
import isEmpty from 'lodash/isEmpty'
import FieldValue from './components/fieldValue'

const LAYOUT_TO_COMPONENT = {
  row: RowWithoutMargin,
  column: Col,
  columnCenter: Style.ColCenter,
  columnEnd: Style.ColEnd
}

const CustomWidget = ({ header, fields, emptyState }: Props) => {
  const renderEmptyState = () => {
    return <Markdown
      children={emptyState || ''}
      options={{
        wrapper: 'article',
        overrides: {
          a: (props) => <Style.Link {...props} target={'_blank'} rel={'noreferrer noopener'} />
        }
      }}
    />
  }

  return (
    <Style.Layout>
      <Grid>
        <Row>
          <Style.Header>{header}</Style.Header>
        </Row>
        <Grid>
          {isEmpty(fields)
            ? renderEmptyState()
            : fields.map((field, index) => {
              const Layout = field.layout === 'row' ? RowWithoutMargin : Col
              const columns = 12 / field.elements.length
              return <Layout key={`${field.elements[0].layout}-${index}`}>
                {field.elements.map((element, index) => {
                  const Layout = LAYOUT_TO_COMPONENT[element.layout]
                  return <Layout sm={columns} key={`${element.type}-${index}`}>
                    <FieldValue type={element.type} value={element.value} />
                  </Layout>
                })}
              </Layout>
            })}
        </Grid>
      </Grid>
    </Style.Layout>
  )
}

export default CustomWidget
