import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import AdvancedFiltersRow from '../advancedFilters/advancedFiltersRow'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { getValue } from '@shared/utils'
import Select from '../select'
import partition from 'lodash/partition'
import get from 'lodash/get'
import EnableFor from '../enableFor'
import { SCOPES } from '../../constants'
import OverflowTooltip from '@components/overflowTooltip'
import isEmpty from 'lodash/isEmpty'
import { Button, ButtonType, ButtonSize, Spacer } from '@toriihq/design-system'
import { removeItemAtIndex, replaceItemAtIndex } from '@lenses/utils'

const CSS = {
  main: css({
    borderRadius: '4px'
  }),
  buttonsContainer: css({
    display: 'flex'
  }),
  filtersBottomActions: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px'
  }),
  criteria: css({
    borderRadius: '4px',
    border: `1px solid ${colors.border}`,
    padding: '15px'
  }),
  and: css(texts.heading, {
    textTransform: 'uppercase',
    padding: '15px'
  }),
  filtersContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  filters: css({
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap'
  }),
  spanError: css({
    color: colors.error,
    marginTop: '-20px'
  }),
  divWrapSelectWithError: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  notConnectedAccountError: css({
    color: colors.red,
    paddingTop: '5px'
  })
}

CSS.deleteButton = css({
  display: 'none',
  transition: 'display .2s',
  [`.${CSS.criteria}:hover &, [data-${CSS.criteria}]:hover &`]: {
    display: 'inline'
  }
})

const criteriaModelTypes = {
  app: 'app',
  account: 'account',
  userFields: 'userFields'
}

class userMeetsCriteriaFilters extends React.Component {
  clearFilters = () => {
    const { onChange } = this.props
    onChange([])
  }

  onChange = (key, value, index) => {
    const { onChange } = this.props

    let prevItem = get(this.props.criteria, index, {})
    if (key === 'type' && value && value.modelType === criteriaModelTypes.userFields) {
      prevItem = {
        filters: { id: Date.now() }
      }
    } else if (key === 'account') {
      const isAccountChanged = get(prevItem, ['account', 'value']) !== value.value
      prevItem = {
        ...prevItem,
        filters: isAccountChanged ? { id: Date.now() } : prevItem.filters
      }
    } else if (key === 'type') {
      prevItem = {}
    }

    const item = {
      ...prevItem,
      [key]: value
    }
    const criteria = replaceItemAtIndex(this.props.criteria, item, index)
    onChange && onChange(criteria)
  }

  onAdd = () => {
    const { onChange } = this.props
    const criterion = { id: Date.now() }
    const criteria = this.props.criteria.concat(criterion)
    onChange && onChange(criteria)
  }

  onRemove = (index) => {
    const { onChange } = this.props
    const criteria = removeItemAtIndex(this.props.criteria, index)
    onChange && onChange(criteria)
  }

  renderCriteriaType (criteriaTypeOptions, index, type, account, disabled) {
    let [accountOptions, criteriaOptions] = partition(criteriaTypeOptions, option => option.modelType === criteriaModelTypes.account)
    const hasTypeValue = type && (type.value !== undefined && type.value !== null && type.value !== '')
    let appAccountOptions = type && type.modelType === criteriaModelTypes.app ? accountOptions.filter(account => account.idApp === type.value) : null
    const hasAccountValue = account && (account.value !== undefined && account.value !== null && account.value !== '')

    const isAccountNotInCriteriaOptions = account && !isEmpty(criteriaOptions) && !criteriaOptions.find(option => option.value === account.idApp)
    const isAccountNotInAppAccountOptions = account && !isEmpty(accountOptions) && !appAccountOptions.find(option => option.value === account.value)

    let showError = false
    if (isAccountNotInAppAccountOptions) {
      const disabledAccount = { ...account, disabled: true }
      appAccountOptions = appAccountOptions.concat([disabledAccount])
      showError = true
    }
    if (isAccountNotInCriteriaOptions) {
      const disabledType = { ...type, disabled: true }
      criteriaOptions = criteriaOptions.concat([disabledType])
      showError = true
    }

    const renderValueWithOverFlowTooltip = (option) => {
      return (
        <OverflowTooltip label={option.label}>
          {option.label}
        </OverflowTooltip>
      )
    }

    return (
      <div {...CSS.filtersContainer}>
        <div {...CSS.filters}>
          <Select
            autoFocus={!hasTypeValue}
            options={criteriaOptions}
            value={type && type.value}
            onChange={type => this.onChange('type', type, index)}
            clearable={false}
            searchable
            openOnFocus
            disabled={disabled}
            valueRenderer={renderValueWithOverFlowTooltip}
          />
          {appAccountOptions && <div {...CSS.divWrapSelectWithError}>
            <Select
              autoFocus={hasTypeValue && appAccountOptions && !hasAccountValue}
              hasError={showError}
              options={appAccountOptions}
              value={account && account.value}
              onChange={account => this.onChange('account', account, index)}
              clearable={false}
              searchable
              openOnFocus
              disabled={disabled}
              valueRenderer={renderValueWithOverFlowTooltip}
            />
            {showError && <span {...CSS.spanError}>Integration with account was disconnected</span>}
          </div>}
        </div>
        <div {...CSS.deleteButton}>
          <Button type={ButtonType.compact} size={ButtonSize.small} disabled={disabled} onClick={() => this.onRemove(index)} icon='Trash' />
        </div>
      </div>
    )
  }

  getOptionsKeyByUserSelection = (optionsKey, account) => {
    const { value: idAppAccount } = account || {}
    return optionsKey.filter(option => option.idAppAccount === idAppAccount)
  }

  render () {
    const { criteria, optionsKey, optionsValuesPerKey, getTriggerPreview, disabled } = this.props
    const [criteriaTypeOptions, filtersOptions] = partition(optionsKey, option => Object.values(criteriaModelTypes).includes(option.modelType))

    const criteriaAmount = criteria.length

    return (
      <div {...CSS.main}>
        {criteria.map((criterion, index) => {
          const shouldRenderAnd = (index + 1) < criteriaAmount
          const { type, account, filters } = criterion
          const typeSelected = type && type.value
          const accountSelected = account && account.value
          const criterionTypeSelectionCompleted = typeSelected && (type.modelType === criteriaModelTypes.userFields || accountSelected)
          const selectionOptionsKey = criterionTypeSelectionCompleted ? this.getOptionsKeyByUserSelection(filtersOptions, account) : []
          const optionsValues = filters ? optionsValuesPerKey[getValue(filters.key)] : []
          return (
            <div key={index}>
              <div {...CSS.criteria}>
                {this.renderCriteriaType(criteriaTypeOptions, index, type, account, disabled)}
                {criterionTypeSelectionCompleted && <AdvancedFiltersRow
                  optionsKey={selectionOptionsKey}
                  optionsValues={optionsValues}
                  onChange={filter => this.onChange('filters', filter, index)}
                  filter={filters}
                  disabled={disabled}
                />}
              </div>
              {shouldRenderAnd && <div {...CSS.and}>and</div>}
            </div>
          )
        })}
        <div {...CSS.filtersBottomActions}>
          <div {...CSS.buttonsContainer}>
            <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
              <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.onAdd} icon='Plus' label='Add filter' />
            </EnableFor>
            <Spacer left={'space-100'}><Button type={ButtonType.secondary} onClick={getTriggerPreview} label='Preview' /></Spacer>
          </div>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <Button type={ButtonType.compact} size={ButtonSize.small} disabled={criteria.length === 0} onClick={this.clearFilters} label='Clear filters' />
          </EnableFor>
        </div>
      </div>
    )
  }
}

userMeetsCriteriaFilters.propTypes = {
  optionsKey: AdvancedFiltersRow.propTypes.optionsKey,
  optionsValuesPerKey: PropTypes.object,
  criteria: PropTypes.arrayOf(AdvancedFiltersRow.propTypes.filter),
  onChange: PropTypes.func
}

userMeetsCriteriaFilters.defaultProps = {
  criteriaTypeOptions: [],
  optionsKey: [],
  optionsValuesPerKey: {},
  criteria: []
}

export default userMeetsCriteriaFilters
