import { css } from 'glamor'
import { fontSize } from '@shared/style/sizes'

export const Header = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: fontSize.small
})

export const Placeholder = {
  width: '200px',
  height: '30px'
}
