import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import EnableFor from '../enableFor'
import ToriiSelect from '../select'
import colors from '../../shared/style/colors'
import DraggableOptions from '../draggableOptions'
import { isEmpty } from 'lodash'
import { Button, ButtonType, Tooltip } from '@toriihq/design-system'

const CSS = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  }),
  optionDescription: css({
    color: colors.grey1,
    textTransform: 'none'
  }),
  draggableOptions: {
    color: colors.black
  }
}

class SelectDraggableOptions extends React.Component {
  state = {
    showSelect: false
  }

  onChange = (selection) => {
    const { onChange, value } = this.props
    onChange && onChange((value || []).concat(selection.value))
  }

  onRemove = (index) => {
    const { onChange, value } = this.props

    const updatedValue = value.filter((field, i) => i !== index)

    onChange && onChange(updatedValue)
  }

  render () {
    const { showSelect } = this.state
    const { options, value, overrideStyle, allowedScopes = [], optionRenderer, placeholderText, onChange } = this.props

    const filteredValue = (value || []).filter(v => options.find(o => o.value === v))
    const notSelectedOptions = options.filter(o => !filteredValue.includes(o.value))
    const disableSelect = isEmpty(notSelectedOptions)

    return (
      <div {...css(CSS.container, overrideStyle)}>
        <EnableFor scopes={allowedScopes}>
          <DraggableOptions
            overrideStyle={CSS.draggableOptions}
            optionRenderer={optionRenderer}
            value={filteredValue}
            options={options}
            onChange={onChange}
            onRemove={this.onRemove}
          />
        </EnableFor>
        <div>

          <EnableFor scopes={allowedScopes}>
            {showSelect ? (
              <ToriiSelect
                options={notSelectedOptions}
                onChange={this.onChange}
                clearable={false}
                onBlur={() => this.setState({ showSelect: false })}
                searchable
                autoFocus
                openMenuOnFocus
                maxMenuHeight={250}
              />
            ) : (
              <Tooltip
                placement='top'
                hide={!disableSelect}
                label={'All available fields have been added'}
              >
                <Button type={ButtonType.compact} label={placeholderText} disabled={disableSelect} onClick={() => this.setState({ showSelect: true })} />
              </Tooltip>
            )}
          </EnableFor>
        </div>
      </div>
    )
  }
}

SelectDraggableOptions.propTypes = {
  overrideStyle: PropTypes.object,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string
  })).isRequired,
  optionRenderer: PropTypes.func.isRequired
}

export default SelectDraggableOptions
