import React from 'react'
import * as Style from '@components/appInCatalogBox/style'
import { SCOPES } from '@root/constants'
import RelativeTeamLink from '@components/relativeTeamLink'
import EnableFor from '@components/enableFor'
import UserDetails from '@components/userDetails'
import Analytics from '@helpers/analytics'
import get from 'lodash/get'
import { Link, Spacer, TextBadge } from '@toriihq/design-system'

const onNavToPolicyConfigurationPage = ({ isCustomPolicy, appName }) => {
  Analytics.track('Open policy', {
    'Policy type': isCustomPolicy ? 'Custom' : 'Default',
    'App name': appName,
    'Page name': 'App overview page'
  })
}

const onNavToExecutionLog = ({ isCustomPolicy }) => {
  Analytics.track('Navigate to policy-log', {
    'Policy type': isCustomPolicy ? 'Custom' : 'Default'
  })
}

export const PolicyName = ({ policy, pathPrefix, isCustomPolicy, appName }) => {
  const policyName = get(policy, ['name'])

  return (<>
    <EnableFor scopes={[SCOPES.AUTOMATION_READ]} allowForToriiAdmin>
      <RelativeTeamLink to={`/${pathPrefix}/1/0`} onClick={() => onNavToPolicyConfigurationPage({ isCustomPolicy, appName })}>
        <Link>{policyName}</Link>
      </RelativeTeamLink>
    </EnableFor>
    {!isCustomPolicy && (<Spacer left={'space-050'}>
      <TextBadge color='greyscale' size='Small'>
        Default
      </TextBadge>
    </Spacer>)}
  </>)
}

export const PolicyCreator = ({ policy, usersById }) => {
  const createdBy = get(policy, ['createdBy'])
  const user = usersById[createdBy] || usersById['toriiUser'] || {}
  const { id: idUser, firstName, lastName, photoUrl, email } = user

  return (
    <Style.CreatedBy>
      <Style.TableTitle>Created by</Style.TableTitle>
      <UserDetails
        id={idUser}
        firstName={firstName}
        lastName={lastName}
        email={email}
        idUser={idUser}
        photoUrl={photoUrl}
        linkToUserPage
        overrideStyle={Style.UserDetails}
      />
    </Style.CreatedBy>
  )
}

export const PolicyExecutions = ({ policy, pathPrefix, isCustomPolicy }) => {
  const executions = get(policy, ['executions'])
  return (
    <Style.Triggered>
      <Style.TableTitle>Triggered</Style.TableTitle>
      <div>{executions
        ? <EnableFor scopes={[SCOPES.AUTOMATION_READ]} allowForToriiAdmin>
          <RelativeTeamLink to={`/${pathPrefix}/executions`} onClick={() => onNavToExecutionLog({ isCustomPolicy })}>
            <Link>{executions}</Link>
          </RelativeTeamLink>
        </EnableFor>
        : '-'}
      </div>
    </Style.Triggered>
  )
}

export const PolicyActivation = ({ policy }) => {
  const isActive = get(policy, ['isActive'])
  return (
    <Style.Active>
      <Style.TableTitle>Active</Style.TableTitle>
      <span>{isActive ? 'Yes' : 'No'}</span>
    </Style.Active>
  )
}
