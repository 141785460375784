import styled from 'styled-components'

export namespace Styles {
    export const RequestSent = styled.div`
      color: ${({ theme }) => theme.palette.text.success};
      font: ${({ theme }) => theme.typography.font.subtitle02};
      display: flex;
      padding: 6px 12px 6px 0px;
      justify-content: center;
      align-items: center;
      gap: 4px;
    `
}
