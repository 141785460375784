import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { fontWeight } from '@shared/style/sizes'

export const Placeholder = (isBig) => ({ height: isBig ? '32px' : '25px', width: '50px' })

export const Main = css({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
  flexDirection: 'row',
  width: '100%',
  maxHeight: '32px',
  marginBottom: '45px'
})

export const ColorBar = (isBig, color) => css({
  borderColor: color,
  borderStyle: 'solid',
  borderLeftWidth: '2px',
  borderRightWidth: '0px',
  borderTopWidth: '0px',
  borderBottomWidth: '0px',
  width: isBig ? '8px' : '5px',
  height: isBig ? '32px' : '25px'
})

export const BigIconSize = 32
export const SmallIconSize = 25

export const IconSize = (isBig) => isBig ? BigIconSize : SmallIconSize

export const Icon = (isBig = true) => css({
  display: 'inline-block',
  width: `${isBig ? BigIconSize : SmallIconSize}px`,
  height: `${isBig ? BigIconSize : SmallIconSize}px`,
  border: `1px solid ${colors.border}`,
  borderRadius: isBig ? '4px' : '2px',
  transition: 'border-color .2s',
  ':hover': {
    borderColor: colors.blue
  }
})

export const DetailsContainer = css(texts.subheading, {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  width: '122px',
  color: colors.darkText
})

export const InfoLine = css(texts.body, {
  display: 'flex',
  fontWeight: fontWeight.normal,
  color: colors.blue
})

export const OverflowTooltipContainer = css({
  display: 'flex',
  alignItems: 'center',
  gap: 4
})
