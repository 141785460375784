import React, { ReactElement, useState } from 'react'
import { Button, ButtonState, Tooltip } from '@toriihq/design-system'
import { Styles } from './styles'
import Input from '@components/form/input'

interface Props {
  value: string
  onCopy?: () => void
}

export const ReadonlyCopyField = ({
  value,
  onCopy
}: Props): ReactElement => {
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
    onCopy?.()
  }

  return <Styles.Container>
    <Input value={value} disabled />
    <Tooltip label='Copy'>
      <Button
        type='secondary'
        size='small'
        icon='Copy'
        onClick={handleCopy}
        buttonState={copied ? ButtonState.success : ButtonState.neutral}
        htmlButtonType='button'
      />
    </Tooltip>
  </Styles.Container>
}
