import React from 'react'
import PropTypes from 'prop-types'
import { header, toggleCard, body, textContainer, toggleContainer } from '@components/toggleCard/style'
import ToriiToggle from '@components/toriiToggle'
import EnableFor from '@components/enableFor'
import { Link } from '@toriihq/design-system'

const ToggleCard = (props) => {
  const { title, description, infoLink, checked, onToggle, id, scopes } = props

  return (
    <div {...toggleCard}>
      <div {...textContainer}>
        <label {...header}>{title}</label>
        <div>
          <label {...body}>{description}</label>
          <Link href={infoLink} target='_blank'>Learn More</Link>
        </div>
      </div>
      <div {...toggleContainer}>
        <EnableFor scopes={scopes} showAsDisabled={false}>
          <ToriiToggle id={id} checked={checked} onToggle={onToggle} />
        </EnableFor>
      </div>
    </div>
  )
}

ToggleCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  infoLink: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  scopes: PropTypes.array.isRequired
}

export default ToggleCard
