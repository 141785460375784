import React from 'react'
import PageHeader from '../../components/pageHeader'
import Page from '../../components/page'
import ExpensesUploadActivity from '../../components/expensesUploadActivity'

class ExpensesPage extends React.Component {
  render () {
    const { idOrg, idUpload } = this.props

    return (
      <Page title='Expenses'>
        <PageHeader title='Review Expense Report' />
        <ExpensesUploadActivity idOrg={idOrg} idUpload={idUpload} />
      </Page>
    )
  }
}

export default ExpensesPage
