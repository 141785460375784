import React, { ReactElement, useEffect, useState } from 'react'
import Input from '@components/form/input'
import { blurInputWhenPressingEnter } from '@shared/utils'

interface Props {
  label: string
  disabled: boolean
  onBlurAfterChange: (label: string) => void
}

export const BranchLabelInput = ({
  label,
  disabled,
  onBlurAfterChange
}: Props): ReactElement => {
  const [labelInput, setLabelInput] = useState(label)

  useEffect(() => {
    setLabelInput(label)
  }, [label])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelInput(e.target.value)
  }

  const handleBlur = () => {
    if (labelInput !== label) {
      onBlurAfterChange(labelInput)
    }
  }

  return <Input
    value={labelInput}
    disabled={disabled}
    onChange={handleChange}
    onBlur={handleBlur}
    onKeyDown={blurInputWhenPressingEnter}
  />
}
