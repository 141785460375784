import styled from 'styled-components'

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActionButtonDisabledTooltipLabel = styled.div`
  text-align: center;
  max-width: 240px;
`

export const UsageHeaderTooltipLabel = styled.div`
  text-align: center;
  max-width: 200px;
`
