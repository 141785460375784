import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import EnableFor from '../../../enableFor'
import MentionsTextarea from '../../../form/mentionsTextarea'

const CSS = {
  container: css({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '51px'
  })
}

class EmailSubject extends React.Component {
  state = { input: this.props.value || '' }

  saveData = () => {
    const { value, onChange } = this.props
    const { input } = this.state
    if (input !== value) {
      onChange(input)
    }
  }

  render () {
    const { input } = this.state
    const { overrideStyle, mentions, disabled, allowedScopes } = this.props
    return (
      <div {...css(CSS.container, overrideStyle)}>
        <div>Subject:</div>
        <EnableFor scopes={allowedScopes}>
          <MentionsTextarea
            disabled={disabled}
            singleLine
            value={input}
            mentions={mentions}
            overrideStyle={{ width: '92%' }}
            onChange={e => this.setState({ input: e.target.value })}
            onBlur={this.saveData}
          />
        </EnableFor>
      </div>
    )
  }
}

EmailSubject.propTypes = {
  overrideStyle: PropTypes.object,
  value: PropTypes.node,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default EmailSubject
