import React from 'react'
import BasicTopBar from '../../components/basicTopBar'
import Placeholder from '../../components/placeholder'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import { fontWeight, fontSize } from '../../shared/style/sizes'
import Lottie from 'react-lottie'
import animationData from './thankYouLottie.json'
import coffeeCupImage from './coffeeCup.svg'
import Spinner from '@media/spinner.gif'
import { Button, Link } from '@toriihq/design-system'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const SMALL_SCREEN_SIZE = '505px'

const CSS = {
  main: css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  }),
  content: css({
    position: 'absolute',
    top: '14%',
    bottom: 0,
    left: 0,
    right: 0
  }),
  centerDiv: css({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  loggedInBackground: css({
    background: `linear-gradient(${colors.white} 55%, ${colors.blue} 45%)`
  }),
  loading: css({
    textAlign: 'center',
    margin: '50px 0 10px 0'
  }),
  textBoxHeader: css({
    color: colors.blue,
    fontSize: '50px',
    fontWeight: fontWeight.bold,
    lineHeight: '50px'
  }),
  textBoxNote: css({
    fontSize: fontSize.medium,
    color: '#808080'
  }),
  thankYouContainer: css({
    zIndex: '20',
    maxWidth: '100%',
    width: '526px',
    height: '266px',
    background: colors.lightBlue3,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
    marginTop: '20px',
    padding: '3% 4%'
  }),
  illustration: css({
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      display: 'none'
    }
  }),
  errorContent: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  thankYouHeader: css({
    color: colors.blue,
    fontSize: '40px',
    fontWeight: fontWeight.bold,
    lineHeight: '40px',
    marginBottom: '18px'
  }),
  thankYouDescription: css(texts.headers.large, {
    fontSize: fontSize.large
  }),
  thankYouMoreInfo: css({
    margin: '16px 0 25px 0'
  }),
  errorBackground: css({
    background: `linear-gradient(${colors.white} 50%, ${colors.lightBlue3} 50%)`
  }),
  errorContainer: css({
    maxWidth: '100%',
    width: '720px',
    height: '290px',
    background: colors.white,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
    padding: '3% 5%'
  }),
  errorDescription: css({
    fontSize: fontSize.medium,
    margin: '26px 0 17px 0'
  }),
  cupImage: css({
    marginTop: '-20px'
  }),
  contactUsButton: css({
    marginTop: '24px'
  })
}

const TIMEOUT_TO_ASSUME_ERROR_HAPPENED = 15 * 1000

class ExtensionLogin extends React.Component {
  constructor (props) {
    super(props)

    window.setExtensionSuccessfulLogin = this.setExtensionSuccessfulLogin
    window.setExtensionErrorLogin = this.setExtensionErrorLogin

    setTimeout(this.assumeErrorHappenedIfNothingChanged, TIMEOUT_TO_ASSUME_ERROR_HAPPENED)
  }

  state = {
    extensionSuccessfulLogin: false,
    error: null
  }

  assumeErrorHappenedIfNothingChanged = () => {
    const { extensionSuccessfulLogin, error } = this.state
    if (!extensionSuccessfulLogin && !error) {
      this.setState({ error: 'unknown' })
    }
  }

  setExtensionSuccessfulLogin = () => {
    this.setState({ extensionSuccessfulLogin: true, error: null })
  }

  setExtensionErrorLogin = (error) => {
    this.setState({ extensionSuccessfulLogin: false, error })
  }

  renderDoneMessage () {
    return <div {...CSS.thankYouContainer}>
      <div {...CSS.thankYouHeader}>You are all set!</div>
      <div {...CSS.thankYouDescription}>Torii browser extension has been enabled.</div>
      <div {...CSS.thankYouMoreInfo}>See
        <Link href='https://toriihq.com/extension' target='_blank'> here </Link>
        for more info.</div>
      <div {...CSS.textBoxNote}>You can now close this tab.</div>
    </div>
  }

  onContactUsClick = () => {
    const { emailForQuestions } = this.props
    return window.open(`mailto:${emailForQuestions}`)
  }

  renderAuthError ({ message = 'It seems you do not have the latest Torii extension installed' }) {
    const { emailForQuestions } = this.props

    if (message && message.message) {
      message = message.message
    }

    return <div {...CSS.errorContainer}>
      <div {...CSS.textBoxHeader}>Oops!</div>
      <div {...CSS.errorContent}>
        <div>
          <div {...CSS.errorDescription}>{message}</div>
          <div {...CSS.textBoxNote}>Please contact {emailForQuestions ? 'us' : 'your IT'} for help</div>
          {emailForQuestions && <div {...CSS.contactUsButton}><Button onClick={this.onContactUsClick} label='Contact us' /></div>}
        </div>
        <img alt='Error' src={coffeeCupImage} width='230px' {...css(CSS.cupImage, CSS.illustration)} />
      </div>
    </div>
  }

  render () {
    const { loading } = this.props
    const { extensionSuccessfulLogin, error } = this.state
    const windowExtensionSuccessfulLogin = window.__TORII_EXTENSION_AUTHENTICATED
    const windowError = window.__TORII_EXTENSION_AUTHENTICATION_ERROR
    const loggedIn = extensionSuccessfulLogin || windowExtensionSuccessfulLogin
    const loginError = error || windowError
    const isLoading = !loggedIn && !loginError
    const shouldRenderError = !loggedIn && loginError

    const spinner = <div {...CSS.loading}><img alt='Loading...' src={Spinner} width='40px' height='40px' /></div>

    return <div {...CSS.main}>
      <BasicTopBar transparent />
      <Placeholder loading={loading} type='text' rows={6} style={{ width: '100%' }}>
        {isLoading && spinner}
        <div {...css(CSS.content, loggedIn && CSS.loggedInBackground, shouldRenderError && CSS.errorBackground)}>
          {loggedIn && <div {...CSS.illustration}>
            <Lottie
              isClickToPauseDisabled
              width='1100px'
              style={{ cursor: 'default', position: 'absolute', top: 0, left: '50%', marginLeft: '-550px', zIndex: 10 }}
              options={defaultOptions}
            />
          </div>}
          <div {...CSS.centerDiv}>
            {loggedIn && this.renderDoneMessage()}
            {shouldRenderError && this.renderAuthError(loginError)}
          </div>
        </div>
      </Placeholder>
    </div>
  }
}

export default ExtensionLogin
