import React, { useMemo } from 'react'
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd'
import * as Style from './style'
import { DragEndResult, DraggableRowsTableParams } from './types'

let instances = 0
const DraggableRowsTable = ({ children, data, onRowsOrderChanged, disabled }: DraggableRowsTableParams) => {
  const onDragEnd = (result: DragEndResult) => {
    const isDroppedOutside = !result.destination
    if (isDroppedOutside) {
      return
    }

    const dataCopy = [...data]

    const [removed] = dataCopy.splice(result.source.index, 1)
    dataCopy.splice(result.destination.index, 0, removed)

    onRowsOrderChanged({ reorderedData: dataCopy, sourceIndex: result.source.index, destinationIndex: result.destination.index })
  }

  const droppableId = useMemo(() => `droppable-${instances++}`, [])

  if (disabled) {
    return children
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} >
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children[0].map((child, index) => {
              return (
                <Draggable key={child.key} draggableId={`item-${child.key}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...(snapshot.isDragging ? Style.draggingStyle : Style.nonDraggingStyle),
                        ...provided.draggableProps.style,
                        ...Style.wrapper
                      }}
                    >
                      {child}
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableRowsTable
