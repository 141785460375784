import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import ToriiSelect from '../../select'
import FormGroup from '../../form/formGroup'
import {
  APP_PERMISSIONS,
  EMPTY_OBJECT,
  SCOPES,
  WORKFLOW_ACTION_TYPES,
  WORKFLOW_TRIGGER_TYPES
} from '@root/constants'
import cloneDeep from 'lodash/cloneDeep'
import ConnectDirectIntegrationPopup from '../connectDirectIntegrationPopup'
import { fontWeight } from '@shared/style/sizes'
import EnableFor from '../../enableFor'
import { Icon, Tooltip, AlertBox, AlertBoxType } from '@toriihq/design-system'
import EditWorkflowAction from './editWorkflowAction'
import pluralize from 'pluralize'
import { noop } from 'lodash'
import { delegateOffboardingActions } from '@lenses/workflows'
import { updateActionFieldValue } from '@shared/workflows/updateActionFieldValue'

const CSS = {
  optionTitle: css(texts.subheading, {
    color: colors.black
  }),
  optionContainer: css({
    display: 'flex',
    flexDirection: 'row'
  }),
  disabledOption: css({
    opacity: 0.5,
    cursor: 'not-allowed !important',
    color: colors.grey2
  }),
  optionIcon: css({
    marginRight: '10px',
    fontSize: '15px',
    color: colors.black
  }),
  actions: css({
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  cancel: css({
    marginRight: '20px'
  }),
  previewButton: css({
    fontWeight: fontWeight.semiBold,
    lineHeight: '36px'
  }),
  alertBox: css({
    marginBottom: '12px'
  })
}

const SELECT_OPTIONS = {
  delegate: {
    value: 'delegate',
    label: 'Delegate',
    description: 'An email request will be sent to selected users, or a Jira issue will be open.',
    icon: 'Users'
  },
  automatic: {
    value: 'automatic',
    label: 'Automatic',
    description: 'An automatic action will be triggered.',
    icon: 'Torii',
    css: css({ color: colors.blue })
  },
  ignore: {
    value: 'ignore',
    label: 'Ignore',
    description: 'Offboarding will be skipped and the user will only be removed from the app’s user list in Torii.',
    icon: 'Ignore'
  }
}

const fieldTypes = {
  [SELECT_OPTIONS.delegate.value]: ['delegateAction'],
  [SELECT_OPTIONS.automatic.value]: ['automaticAction'],
  [SELECT_OPTIONS.ignore.value]: ['generic']
}

const actionToSelectOption = (action) => {
  if (action.type === SELECT_OPTIONS.ignore.value) {
    return action.type
  }

  if (delegateOffboardingActions.includes(action.type)) {
    return SELECT_OPTIONS.delegate.value
  }

  if (action.type) {
    return SELECT_OPTIONS.automatic.value
  }

  return null
}

class ConfigureAppForOffboardingPopup extends React.Component {
  state = {
    action: this.props.action,
    offboardingOption: actionToSelectOption(this.props.action),
    isDirty: false,
    updateVersion: null
  }

  componentDidMount () {
    const { getWorkflowsActionsConfig, getWorkflowsTriggersConfig, idOrg, getServicesSyncData, getServicesConfig, getWorkflowsPersonalizationsConfig } = this.props
    getWorkflowsActionsConfig(idOrg)
    getWorkflowsTriggersConfig()
    getServicesSyncData({ idOrg })
    getServicesConfig({ idOrg })
    getWorkflowsPersonalizationsConfig({ idOrg })
  }

  validateFields = (updateVersion) => {
    const { action } = this.state
    const { validateWorkflow } = this.props

    if (!action.fields) {
      return
    }

    validateWorkflow({ action }).then(({ workflow }) => {
      if (this.state.updateVersion && updateVersion !== this.state.updateVersion) {
        return
      }
      this.setState({ action: workflow.actions[0] })
    })
  }

  onFieldChange = ({ selectedValue, fieldId }, locallyOnly) => {
    const { action } = this.state

    const updatedAction = updateActionFieldValue(action, selectedValue, fieldId, false)
    const updateVersion = new Date().getTime()
    const callback = locallyOnly ? noop : () => this.validateFields(updateVersion)
    this.setState({ action: { ...updatedAction, isValid: false }, isDirty: true, updateVersion }, callback)
  }

  onSelectedActionChange = (option) => {
    const { createAction } = this.props
    const { action: previousAction } = this.state
    const { value: actionType } = (option || {})

    const didSelectionChanged = !previousAction || actionType !== previousAction.type

    if (!didSelectionChanged) {
      return
    }

    const action = createAction(actionType)
    this.setState({ action })
  }

  getActionSelectionComponent = ({ actionSelectOptions }) => {
    const { action } = this.state

    return (
      <>
        <div style={{ marginBottom: 32 }}>
          <ToriiSelect
            options={actionSelectOptions}
            value={action.type}
            onChange={this.onSelectedActionChange}
          />
        </div>
        {action.type && <EditWorkflowAction
          triggerType={WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING}
          action={action}
          onChange={this.onFieldChange}
          actionLabel='Action Setup'
          triggerAppAndAccount={this.props.app}
        />
        }
      </>
    )
  }

  getFields = () => {
    return {
      generic: {
        props: {},
        component: () => {
          const { action } = this.state

          return <EditWorkflowAction triggerType={WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING} action={action} onChange={this.onFieldChange} />
        }
      },
      delegateAction: {
        props: {
          key: 'delegateAction',
          label: 'Delegate Action'
        },
        component: () => {
          const { delegateOffboaringActionSelectOptions } = this.props
          return this.getActionSelectionComponent({ actionSelectOptions: delegateOffboaringActionSelectOptions })
        }
      },
      automaticAction: {
        props: {
          key: 'automaticAction',
          label: 'Action'
        },
        component: () => {
          const { automaticOffboaringActionSelectOptions } = this.props
          return this.getActionSelectionComponent({ actionSelectOptions: automaticOffboaringActionSelectOptions })
        }
      }
    }
  }

  fields = this.getFields()

  renderOption = ({ data: option }) => {
    return (
      <div {...css(CSS.optionContainer, option.disabled && CSS.disabledOption)}>
        <div {...css(CSS.optionIcon, option.css)}>
          <Icon name={option.icon} />
        </div>
        <Tooltip
          label='User provisioning is not supported'
          hide={!option.disabled}>
          <div>
            <div {...CSS.optionTitle}>{option.label}</div>
            <div {...texts.body}>{option.description}</div>
          </div>
        </Tooltip>
      </div>
    )
  }

  renderSelectMethod = () => {
    const options = cloneDeep(SELECT_OPTIONS)

    return <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
      <ToriiSelect
        options={Object.values(options)}
        placeholder={<div>Select Method...</div>}
        optionRenderer={this.renderOption}
        onChange={(params) => this.onOptionChanged(params)}
        value={this.state.offboardingOption}
        clearable={false}
        searchable={false}
        key={`configureAppForOffboardingPopup`}
        name={`configureAppForOffboardingPopup`}
        openOnFocus
      />
    </EnableFor>
  }

  onOptionChanged = async (option) => {
    const { createAction, autoSelectSingleAutomaticAction } = this.props
    const actionType = option ? option.value : null

    const newState = { offboardingOption: actionType, action: EMPTY_OBJECT }

    if (actionType === SELECT_OPTIONS.automatic.value) {
      newState.action = autoSelectSingleAutomaticAction
    }

    if (actionType === SELECT_OPTIONS.ignore.value) {
      const action = createAction(actionType)
      newState.action = action
    }

    this.setState(newState, this.validateFields)
  }

  onSubmit = async (checked) => {
    const { setupActionWorkflow, close, onConfig = () => {}, toggleConfigureAppForOffboarding, app, executeWorkflow } = this.props
    const { action } = this.state

    toggleConfigureAppForOffboarding({ isOpen: true, actions: [action], idApp: app.idApp, executeWorkflow })
    await setupActionWorkflow({ action, executeWorkflow: checked })
    close()
    onConfig()
  }

  renderConnectPopup = () => {
    const { isConnectSourceOpen } = this.props

    if (!isConnectSourceOpen) {
      return null
    }

    return (
      <ConnectDirectIntegrationPopup
        permission={APP_PERMISSIONS.read_write}
        disableReadOption
        showDeleteExplanation
      />
    )
  }

  isDataValid = () => {
    const { action } = this.state

    return action.isValid
  }

  render () {
    const { cancel, isOpen, app, pendingOffboardingAmount, showAlertBox } = this.props
    const { action, offboardingOption, isDirty } = this.state
    const alertText = pendingOffboardingAmount ? `Offboarding process for ${pluralize('employee', pendingOffboardingAmount, true)} will continue once the action is configured` : 'Offboarding process will run once the action is configured'
    const isDataValid = this.isDataValid()
    const contentAreaStyle = offboardingOption ? {} : { overflow: 'visible' }
    const isMainButtonDisabled = !isDataValid || !isDirty

    return (
      <ToriiPopup
        isOpen={isOpen}
        onCloseAction={cancel}
        width='700px'
      >
        <ToriiPopup.Header header={`Offboarding Method for ${app.appAndAccountName || app.name}`} />
        <ToriiPopup.Content contentAreaStyle={contentAreaStyle}>
          <Fragment>
            {showAlertBox && <div {...CSS.alertBox}><AlertBox type={AlertBoxType.INFORMATIVE} description={alertText} /></div>}
            <FormGroup label='Offboarding Method'>
              {this.renderSelectMethod()}
            </FormGroup>
            {offboardingOption && fieldTypes[offboardingOption].map(fieldType => {
              const props = this.fields[fieldType].props
              return <FormGroup label={props.label} key={props.key || fieldType}>
                {this.fields[fieldType].component()}
              </FormGroup>
            })}
          </Fragment>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          scopes={[SCOPES.AUTOMATION_WRITE]}
          recoveryTime={1000}
          mainButtonAction={this.onSubmit}
          isMainButtonDisabled={isMainButtonDisabled}
          mainButtonText='Apply'
          cancelButtonText='Cancel'
          showCheckbox={action.type === WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK}
          isCheckboxDisabled={isMainButtonDisabled}
          checkboxLabel='Send requests about all users waiting to be offboarded'
        />
      </ToriiPopup>
    )
  }
}

ConfigureAppForOffboardingPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  app: PropTypes.shape({
    idApp: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isConnected: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    idAppAccount: PropTypes.number
  }),
  idOrg: PropTypes.number
}

export default ConfigureAppForOffboardingPopup
