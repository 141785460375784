import flatten from 'lodash/flatten'
import isObject from 'lodash/isObject'
import { formFieldTypes } from '@root/constants'

const FIELD_CONFIGURATION_TYPES = {
  actionOrTrigger: 'actionOrTrigger',
  triggerAndAction: 'triggerAndAction'
}

const MULTI_FORM_FIELD_TYPES = [formFieldTypes.users, formFieldTypes.dropdownMulti, formFieldTypes.selectFormFields]

export const getStepsAndFieldsWithDefaultValues = (triggerType, actionConfig, fieldsDefaultValues, actionsConfigByType, fieldsToValue) => {
  if (!actionConfig) {
    return { steps: [], fields: [] }
  }

  const actionType = actionConfig.type

  const getFieldValue = (field) => {
    const fieldConfiguration = field.templateConfiguration || FIELD_CONFIGURATION_TYPES.triggerAndAction
    let value = ''
    if (field.defaultValue) {
      value = field.defaultValue
    } else if (actionsConfigByType[actionType].ignoreTriggerTemplateData) {
      value = fieldsDefaultValues[`${triggerType}_${actionType}_${field.typeForTemplate}`] || fieldsDefaultValues[`${actionType}_${field.typeForTemplate}`]
    } else if (fieldConfiguration === FIELD_CONFIGURATION_TYPES.actionOrTrigger) {
      value = fieldsDefaultValues[`${triggerType}_${actionType}_${field.typeForTemplate}`] || fieldsDefaultValues[`${actionType}_${field.typeForTemplate}`] || fieldsDefaultValues[`${triggerType}_${field.typeForTemplate}`]
    } else if (fieldConfiguration === FIELD_CONFIGURATION_TYPES.triggerAndAction) {
      value = (fieldsDefaultValues[`${triggerType}_${field.typeForTemplate}`] || '').concat(' ', fieldsDefaultValues[`${triggerType}_${actionType}_${field.typeForTemplate}`] || fieldsDefaultValues[`${actionType}_${field.typeForTemplate}`] || '').trim()
      const isDefaultValueObject = isObject(fieldsDefaultValues[`${triggerType}_${field.typeForTemplate}`]) || isObject(fieldsDefaultValues[`${triggerType}_${actionType}_${field.typeForTemplate}`]) || isObject(fieldsDefaultValues[`${actionType}_${field.typeForTemplate}`])
      if (isDefaultValueObject) {
        value = {
          ...(fieldsDefaultValues[`${triggerType}_${field.typeForTemplate}`] || {}),
          ...(fieldsDefaultValues[`${triggerType}_${actionType}_${field.typeForTemplate}`] ||
          fieldsDefaultValues[`${actionType}_${field.typeForTemplate}`] || {})
        }
      }
    }

    if (value && fieldsToValue && fieldsToValue[value]) {
      value = fieldsToValue[value]
    }

    return value && MULTI_FORM_FIELD_TYPES.includes(field.type) ? [value] : value
  }

  const steps = actionConfig.uiConfig.steps.map(stepConfig => {
    const updatedFields = stepConfig.fields.map(fieldId => {
      const field = actionConfig.inputSchema[fieldId]
      return { ...field, value: getFieldValue(field) }
    })
    const step = { ...stepConfig, fields: updatedFields }
    step.fields = step.fields.map(field => {
      if (!field.fields) {
        return field
      }

      const subFields = Object.values(field.fields).map(subField => ({ ...subField, value: getFieldValue(subField) }))
      const value = {}
      subFields.forEach(subField => { value[subField.id] = subField.value })

      return {
        ...field,
        value,
        fields: Object.values(field.fields).map(subField => ({ ...subField, value: getFieldValue(subField) }))
      }
    })

    return step
  })

  const fields = flatten(steps.map(step => step.fields))
  return { steps, fields }
}
