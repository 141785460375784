import send from '@shared/redux-fetch'
import { CREATE_REPORT } from '@root/constants'
import { CreateReportParams } from './reports.types'

export const createReport = ({ idOrg, payload }: CreateReportParams) => {
  return send(CREATE_REPORT, {
    url: `/api/orgs/${idOrg}/reports`,
    method: 'POST',
    body: JSON.stringify(payload)
  })
}
