import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import omit from 'lodash/omit'
import EditableSelect from '../../editableSelect'
import ToriiPopup from '../../popups/ToriiPopupV2'
import { Tooltip, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { POPUP_MODES } from '../../editableSelect/view'
import { fontWeight } from '@shared/style/sizes'
import AddOrEditForm from '../../editableSelect/addOrEditForm'
import validate from '../../editableSelect/addOrEditForm/validation'
import Analytics from '../../../helpers/analytics'
import { SHARE_STATUS, TABLES } from '@root/constants'
import shared from './images/shared.svg'
import OverflowTooltip from '@components/overflowTooltip'

const CSS = {
  viewSelectContainer: css({
    display: 'flex',
    justifyContent: 'center',
    gap: 12
  }),
  templatesList: css({
    '>div:first-child': {
      borderTop: `1px solid ${colors.border} !important`
    }
  }),
  templatesContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  templateContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    height: '56px',
    border: `1px solid ${colors.border}`,
    borderTop: 'none',
    '&:hover': {
      color: colors.blue,
      backgroundColor: colors.lightBlue3,
      ' .createTemplateButton': {
        display: 'inline-flex !important'
      }
    }
  }),
  createTemplateButton: css({
    display: 'none !important'
  }),
  templatesTitle: css({
    fontSize: '14px',
    fontWeight: fontWeight.semiBold,
    marginBottom: '15px'
  }),
  modal: css({
    padding: 0
  }),
  renderTemplateBody: css({
    padding: '20px'
  }),
  option: css({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px'
  })
}

const TEMPLATES = {
  [TABLES.appsV2Table.key]: [
    {
      label: 'Apps without primary owner',
      view: {
        filters: [
          {
            id: 1,
            key: { value: 'primaryOwner', label: 'Primary Owner' },
            op: 'isNotSet'
          }
        ],
        defaultSort: [
          { id: 'activeUsersCount', desc: true }
        ],
        columnsConfiguration: ['name', 'primaryOwner', 'activeUsersCount', 'expenses', 'score', 'state']
      }
    },
    {
      label: 'New apps',
      view: {
        filters: [
          {
            id: 1,
            key: { value: 'isNew', label: 'New app' },
            op: 'equals',
            value: true
          }
        ],
        defaultSort: [
          { id: 'creationTime', desc: true }
        ],
        columnsConfiguration: ['name', 'primaryOwner', 'activeUsersCount', 'creationTime', 'score', 'state']
      }
    },
    {
      label: 'Apps to review',
      view: {
        filters: [
          {
            id: 1,
            key: { value: 'state', label: 'State' },
            op: 'equals',
            value: 'discovered'
          }
        ],
        defaultSort: [
          { id: 'activeUsersCount', desc: true }
        ],
        columnsConfiguration: ['name', 'primaryOwner', 'activeUsersCount', 'creationTime', 'category', 'state']
      }
    },
    {
      label: 'Paid applications',
      view: {
        filters: [
          {
            id: 1,
            key: { value: 'expenses', label: 'Expenses' },
            op: 'gt',
            value: '0'
          }
        ],
        defaultSort: [
          { id: 'expenses', desc: true }
        ],
        columnsConfiguration: ['name', 'primaryOwner', 'activeUsersCount', 'expenses', 'state', 'renewalDate', 'renewalAmount']
      }
    },
    {
      label: 'Closed applications',
      view: {
        filters: [
          {
            id: 1,
            key: { value: 'state', label: 'State' },
            op: 'equals',
            value: 'closed'
          }
        ],
        defaultSort: [
          { id: 'lastVisitTime', desc: true }
        ],
        columnsConfiguration: ['name', 'primaryOwner', 'activeUsersCount', 'state', 'lastVisitTime']
      }
    },
    {
      label: 'Recommended applications to connect',
      view: {
        filters: [
          {
            id: 1,
            key: { value: 'recommendedToConnect', label: 'Recommended to connect' },
            op: 'equals',
            value: true
          }
        ],
        defaultSort: [
          { id: 'activeUsersCount', desc: true }
        ],
        columnsConfiguration: ['name', 'activeUsersCount', 'expenses', 'activeContractsTotalValue', 'state', 'sources', 'tags']
      }
    }
  ]
}

class TableViews extends React.Component {
  state = { showChooseTemplate: false }
  input = {}

  onSelectedViewChanged = (option) => {
    const { tableKey, views, updateUserPreferences, onViewChanged } = this.props

    const selectedView = option && option.value ? views[option.value] : null
    if (!selectedView) {
      return
    }
    updateUserPreferences(tableKey, { ...selectedView.preferences, id: selectedView.id, shareStatus: selectedView.shareStatus, idUser: selectedView.idUser })

    Analytics.track('Switched view', {
      'Table name': TABLES[tableKey].name,
      'View name': selectedView.viewName
    })
    onViewChanged()
  }

  onCustomFilterChange = (option) => {
    const { tableKey, views, updateUserPreferences, onViewChanged, selectedViewId, applyCustomFilterOnTable } = this.props
    const selectedView = views[selectedViewId]
    const preferences = { ...selectedView.preferences, id: selectedView.id, shareStatus: selectedView.shareStatus, idUser: selectedView.idUser, customFilterSelectedValue: option.value }
    updateUserPreferences(tableKey, preferences)
    applyCustomFilterOnTable({ tableKey, customFilterKey: 'customFilterSelectedValue', customFilterSelectdValue: option.value, userPreferences: preferences, supportViews: true, shareViewPreferences: true })
    Analytics.track('Select custom-filter', {
      'Table name': TABLES[tableKey].name,
      'Filter value': option.value
    })
    onViewChanged()
  }

  onViewEdited = (option) => {
    const { tableKey, views, editTableView } = this.props

    const view = views[option.value]

    Analytics.track('Edited view', {
      'Table name': TABLES[tableKey].name,
      'View name': option.newLabel,
      'Old name': view.viewName
    })

    view.viewName = option.newLabel
    view.shareStatus = option.shareStatus

    editTableView(tableKey, view)
  }

  onViewDeleted = async (option) => {
    const { tableKey, selectedViewId, views, deleteTableView, updateUserPreferences, onViewChanged } = this.props

    const view = views[option.value]
    if (selectedViewId === option.value) {
      const defaultView = Object.values(views).filter(view => view.id !== selectedViewId)[0]
      updateUserPreferences(tableKey, { ...defaultView.preferences, id: defaultView.id })
    }

    await deleteTableView(tableKey, view)
    onViewChanged()
  }

  onViewAdded = async ({ label: viewName, properties: templateView, shareStatus }) => {
    const { tableKey, views, selectedViewId, addTableView, onViewChanged, self } = this.props

    const viewToCopy = templateView ? omit(templateView, ['templateName']) : omit(views[selectedViewId], ['id'])
    const view = { ...viewToCopy, viewName, shareStatus, idUser: self.id }

    await addTableView(tableKey, view, (templateView || {}).templateName, shareStatus)
    onViewChanged()
  }

  toggleShowTemplate = () => {
    const { showChooseTemplate } = this.state
    this.setState({ showChooseTemplate: !showChooseTemplate })
  }

  doesViewExist = ({ allOptions, nonDuplicateLabel }) => {
    return allOptions.some(option => option.label === nonDuplicateLabel)
  }

  createViewByTemplate = ({ allOptions, template, onSubmit }) => {
    const { tableKey } = this.props

    let nonDuplicateLabel = template.label
    let counter = 1

    while (this.doesViewExist({ allOptions, nonDuplicateLabel })) {
      nonDuplicateLabel = `${template.label} (${counter})`
      counter++
    }

    this.setState({ showChooseTemplate: false })
    onSubmit({ label: nonDuplicateLabel, properties: { preferences: template.view, tableKey, templateName: template.label } })
  }

  renderTemplateView = ({ allOptions, onSubmit }) => {
    const { tableKey } = this.props

    return (
      <React.Fragment>
        <ToriiPopup.Content>
          <div>
            <div {...CSS.templatesContainer}>
              <div {...CSS.templatesTitle}>Choose from template</div>
              <div {...CSS.templatesList}>
                {
                  TEMPLATES[tableKey].map(template => (
                    <div key={template.label} {...CSS.templateContainer}>
                      <div>{template.label}</div>
                      <div {...CSS.createTemplateButton} className='createTemplateButton'>
                        <Button label='Create new view' onClick={() => this.createViewByTemplate({ allOptions, template, onSubmit })} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

          </div>
        </ToriiPopup.Content>
        <ToriiPopup.Footer showMainButton={false} showCancelButton={false} showBackButton backButtonAction={this.toggleShowTemplate} />
      </React.Fragment>
    )
  }

  renderForm = ({ popupMode, option, name, allOptions, onSubmit, cancel }) => {
    const { tableKey } = this.props
    const tableTemplates = TEMPLATES[tableKey]
    const renderFooter = () => tableTemplates && popupMode === POPUP_MODES.add && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.toggleShowTemplate} label='Or choose from template' />

    const initialValues = {
      value: popupMode === POPUP_MODES.edit ? option.label : null,
      shareStatus: popupMode === POPUP_MODES.edit ? option.shareStatus : { label: SHARE_STATUS.notShared.label, value: SHARE_STATUS.notShared.type },
      id: popupMode === POPUP_MODES.edit ? option.id : null
    }

    return (
      <ToriiPopup.Form
        initialValues={initialValues}
        validate={(values) => validate(values, { addSharedStatusField: true, allOptions })}
        render={(formProps) => (
          <AddOrEditForm
            name={name}
            option={option}
            allOptions={allOptions}
            popupMode={popupMode}
            cancel={cancel}
            onAddOrEditPopupSubmit={onSubmit}
            renderFooter={renderFooter}
            addSharedStatusField
            {...formProps} />
        )}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            cancelButtonText={'Cancel'}
            mainButtonText={popupMode === POPUP_MODES.add ? `Create new ${name}` : `Update ${name}`}
            isMainSubmit
            formProps={formProps}
            isMainButtonDisabled={!formProps.valid}
          />
        )}
      />
    )
  }

  renderAddOrEditPopup = ({ popupMode, option, allOptions, name, onSubmit, cancel }) => {
    const { showChooseTemplate } = this.state
    const onCancel = () => {
      this.setState({ showChooseTemplate: false })
      cancel()
    }

    return <ToriiPopup
      isOpen
      onCloseAction={onCancel}>
      <ToriiPopup.Header header={popupMode === POPUP_MODES.add ? `Add new ${name}` : `Edit "${option.label}" view`} />
      {showChooseTemplate ? this.renderTemplateView({ allOptions, onSubmit }) : this.renderForm({ popupMode, option, allOptions, name, onSubmit, cancel })}
    </ToriiPopup>
  }

  optionRenderer = (option) => {
    const { self, usersById } = this.props
    const user = usersById[option.idUser] || { firstName: 'UNKNOWN' }
    const userFullName = `${user.firstName} ${user.lastName}`

    let tooltipText
    if (option.shareStatus === SHARE_STATUS.shared.type) {
      tooltipText = 'Shared view'
    } else if (option.shareStatus === SHARE_STATUS.sharedReadOnly.type) {
      if (self.id === option.idUser) {
        tooltipText = 'Shared view - read only, owned by you'
      } else {
        tooltipText = `Shared view (created by ${userFullName}) - read only`
      }
    }

    return tooltipText
      ? <div {...CSS.option}>
        <OverflowTooltip label={option.label}>{option.label}</OverflowTooltip>
        <Tooltip
          label={tooltipText}
          children={<img alt='Torii' src={shared} width='13px' />}
        />
      </div>
      : <OverflowTooltip label={option.label}>{option.label}</OverflowTooltip>
  }

  render () {
    const { selectedViewId, viewOptions, tableKey, disabled, editable, userTablePreferences } = this.props
    const { showChooseTemplate } = this.state
    const customFilterOptions = TABLES[tableKey].customFilterOptions
    const customFilterSelectedValue = userTablePreferences?.customFilterSelectedValue ?? customFilterOptions?.[0] ?? null

    return (
      <div {...CSS.viewSelectContainer}>
        {(customFilterOptions && customFilterSelectedValue) && <EditableSelect
          options={customFilterOptions}
          selectedOption={customFilterSelectedValue}
          onChange={this.onCustomFilterChange}
          optionRenderer={this.optionRenderer}
          valueRenderer={this.optionRenderer}
          disabled={disabled}
          editable={editable}
        />}
        <EditableSelect
          options={viewOptions}
          name='view'
          selectedOption={selectedViewId}
          showChooseTemplate={showChooseTemplate}
          onChange={this.onSelectedViewChanged}
          onOptionEdited={this.onViewEdited}
          onOptionDeleted={this.onViewDeleted}
          onOptionAdded={this.onViewAdded}
          renderAddOrEditPopup={this.renderAddOrEditPopup}
          optionRenderer={this.optionRenderer}
          valueRenderer={this.optionRenderer}
          analyticsProps={{ 'Table name': TABLES[tableKey].name }}
          disabled={disabled}
          editable={editable}
        />
      </div>
    )
  }
}

TableViews.propTypes = {
  tableKey: PropTypes.string,
  editable: PropTypes.bool
}

TableViews.defaultProps = {
  disabled: false,
  editable: true
}

export default TableViews
