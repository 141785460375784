import React from 'react'
import { css } from 'glamor'
import { Icon, Spacer, AppIcon } from '@toriihq/design-system'
import ToriiSelect from '../select'
import { fontSize, fontWeight } from '../../shared/style/sizes'
import colors from '../../shared/style/colors'
import groupBy from 'lodash/groupBy'
import { getAppWithAppAccountName } from '../../lenses/appAccounts'
import sortBy from 'lodash/sortBy'
import { EMPTY_ARRAY } from '../../constants'
import EnableFor from '../enableFor'
import { oneLiner } from '../../shared/style/mixins'

const CSS = {
  option: css({
    display: 'flex',
    alignItems: 'center',
    lineHeight: fontSize.small,
    height: '30px'
  }),
  selectedOption: css({
    flex: 1,
    paddingTop: 0,
    paddingBottom: 0,
    ' .Select-option.is-selected &': {
      color: colors.blue
    }
  }),
  app: css({
    display: 'flex',
    alignItems: 'center',
    ' > span': css(oneLiner, {
      lineHeight: '28px'
    })
  })
}

class AppAndAccountSelect extends React.Component {
  renderOption = ({ app, bold }) => {
    const { isSpecial, label, imageUrl, value } = app
    const appIcon = isSpecial
      ? <div><Icon name='Applications' /></div>
      : <Spacer right={'space-100'}><AppIcon size='small' appName={label} appImageUrl={imageUrl} /></Spacer>

    return <div key={value} {...CSS.option}>
      <div {...CSS.selectedOption}>
        <div {...CSS.app}>
          {appIcon}
          <span style={{ width: 'unset', fontWeight: (bold ? fontWeight.semiBold : fontWeight.normal) }}>{label}</span>
        </div>
      </div>
    </div>
  }

  generateOptionValue = (app) => `${app.id}${app.idAppAccount ? `_${app.idAppAccount}` : ''}`

  adjustOptions = () => {
    const { options = EMPTY_ARRAY } = this.props

    const appsByIdApp = groupBy(options, 'id')
    const specialApps = options.filter(option => option.isSpecial)
    const regularApps = options.filter(option => !option.isSpecial).map(app => {
      const label = getAppWithAppAccountName({ ...app, numberOfAppAccounts: appsByIdApp[app.id].length })
      return {
        ...app,
        value: this.generateOptionValue(app),
        label
      }
    })

    return specialApps.concat(sortBy((regularApps || []), 'label'))
  }

  adjustSelectedApp = (apps) => {
    const { selectedApp } = this.props

    if (!selectedApp) {
      return undefined
    } else if (selectedApp.value) {
      return apps.find(app => app.value === selectedApp.value)
    } else if (selectedApp.id && selectedApp.idAppAccount) {
      const value = this.generateOptionValue(selectedApp)
      return apps.find(app => app.value === value)
    }
  }

  render () {
    const { onChange, allowedScopes = [], disabled } = this.props
    const apps = this.adjustOptions()
    const selectedApp = this.adjustSelectedApp(apps)

    return (<EnableFor scopes={allowedScopes}>
      <ToriiSelect
        options={apps}
        value={selectedApp}
        onChange={onChange}
        optionRenderer={({ data: app }) => this.renderOption({ app, bold: true })}
        valueRenderer={({ data: app }) => this.renderOption({ app, bold: false })}
        disabled={disabled}
      />
    </EnableFor>
    )
  }
}

export default AppAndAccountSelect
