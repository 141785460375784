import styled from 'styled-components'
import { PENDING_TASK_ROW_CLASSNAME } from '../../consts'

export const TaskGroupContent = styled.div`
  padding: 16px 24px;
  flex-direction: column;
  align-self: stretch;
  max-height: 850px;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const ActionButton = styled.div`
  display: none;
  justify-content: center;
  .${PENDING_TASK_ROW_CLASSNAME}:hover & {
    display: flex;
  }

  & button {
    min-width: 110px;
  }
`
