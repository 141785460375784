import styled from 'styled-components'

export namespace Styles {
    export const ComplianceSectionHeader = styled.div`
        padding-top: 14px;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.palette.text.primary};
    `

    export const ComplianceHeaderText = styled.div`
        width: 100%;
        align-content: center;
    `

    export const ComplianceItem = styled.div`
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
    `

    export const ComplianceItems = styled.div`
        padding-top: ${({ theme }) => theme.spacing('space-125')};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
    `

    export const Label = styled.div<{ isCompliant: boolean }>`
        color: ${({ theme, isCompliant }) => isCompliant ? theme.palette.text.interactive : theme.palette.text.disabled};
    `
}
