import React from 'react'
import PropTypes from 'prop-types'
import { extractPlaceholderValue } from './utils'
import { placeholdersRegExp } from '@shared/utils/placeholderRegExp'

const mergeData = ({ data, highlightData }) => (part, index) => {
  const isPlaceholder = placeholdersRegExp.test(part)
  if (!isPlaceholder) {
    return <span key={index}>{part}</span>
  }

  const placeholderValue = extractPlaceholderValue({
    placeholder: part,
    data
  })

  const Wrapper = highlightData ? 'strong' : 'span'
  return <Wrapper key={index}>{placeholderValue}</Wrapper>
}

const SafeTemplate = ({ template, data, highlightData }) => {
  const mergedWithData = template
    .split(placeholdersRegExp)
    .map(mergeData({ data, highlightData }))

  return <>{mergedWithData}</>
}

SafeTemplate.propTypes = {
  template: PropTypes.string,
  data: PropTypes.object,
  highlightData: PropTypes.bool
}

SafeTemplate.defaultProps = {
  template: '',
  data: {},
  highlightData: false
}

export default SafeTemplate
