import {
  CREATE_SAML_CONFIGURATION,
  GET_SAML_CONFIGURATION,
  UPDATE_SAML_CONFIGURATION,
  DELETE_SAML_CONFIGURATION,
  CREATE_SAML_CONFIGURATION_BY_SOURCE
} from '@root/constants'

const initialState = {
  loading: false,
  enabled: false,
  allowLoginsViaEmailAndPassword: true,
  source: null,
  idUpload: null
}

const samlConfigurationReducer = (state = initialState, action) => {
  const actionType = actionBaseType(action)
  switch (actionType) {
    case GET_SAML_CONFIGURATION:
      return samlConfigurationGetReducer(state, action)
    case CREATE_SAML_CONFIGURATION:
      return samlConfigurationCreateReducer(state, action)
    case UPDATE_SAML_CONFIGURATION:
      return samlConfigurationUpdateReducer(state, action)
    case DELETE_SAML_CONFIGURATION:
      return samlConfigurationDeleteReducer(state, action)
    case CREATE_SAML_CONFIGURATION_BY_SOURCE:
      return samlConfigurationBySourceCreateReducer(state, action)
    default: {
      return state
    }
  }
}

const samlConfigurationGetReducer = (state, action) => {
  switch (action.type) {
    case `${GET_SAML_CONFIGURATION}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_SAML_CONFIGURATION}_RESPONSE`: {
      const samlConfigStatus = action.payload
      return {
        ...state,
        ...samlConfigStatus,
        loading: false
      }
    }
    case `${GET_SAML_CONFIGURATION}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return state
    }
  }
}

const samlConfigurationBySourceCreateReducer = (state, action) => {
  switch (action.type) {
    case `${CREATE_SAML_CONFIGURATION_BY_SOURCE}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${CREATE_SAML_CONFIGURATION_BY_SOURCE}_RESPONSE`: {
      const samlConfigBySource = action.payload
      return {
        ...state,
        ...samlConfigBySource,
        loading: false
      }
    }
    case `${CREATE_SAML_CONFIGURATION_BY_SOURCE}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return state
    }
  }
}

const samlConfigurationCreateReducer = (state, action) => {
  switch (action.type) {
    case `${CREATE_SAML_CONFIGURATION}_RESPONSE`: {
      const samlConfigStatus = action.payload
      return {
        ...state,
        ...samlConfigStatus
      }
    }
    case `${CREATE_SAML_CONFIGURATION}_PENDING`:
    case `${CREATE_SAML_CONFIGURATION}_FAILED`:
    default: {
      return state
    }
  }
}

const samlConfigurationUpdateReducer = (state, action) => {
  switch (action.type) {
    case `${UPDATE_SAML_CONFIGURATION}_PENDING`: {
      return {
        ...state,
        allowLoginsViaEmailAndPassword: action.meta.allowLoginsViaEmailAndPassword
      }
    }
    case `${UPDATE_SAML_CONFIGURATION}_RESPONSE`: {
      const samlConfigStatus = action.payload
      return {
        ...state,
        ...samlConfigStatus
      }
    }
    case `${UPDATE_SAML_CONFIGURATION}_FAILED`: {
      return {
        ...state,
        allowLoginsViaEmailAndPassword: !action.meta.allowLoginsViaEmailAndPassword
      }
    }
    default: {
      return state
    }
  }
}

const samlConfigurationDeleteReducer = (state, action) => {
  switch (action.type) {
    case `${DELETE_SAML_CONFIGURATION}_RESPONSE`: {
      return {
        ...initialState
      }
    }
    case `${DELETE_SAML_CONFIGURATION}_PENDING`:
    case `${DELETE_SAML_CONFIGURATION}_FAILED`:
    default: {
      return state
    }
  }
}

const actionBaseType = (action) => action.type.substr(0, action.type.lastIndexOf('_'))

export default samlConfigurationReducer
