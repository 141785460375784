import React from 'react'
import EnableFor from '@root/components/enableFor'
import RelativeTeamLink from '@root/components/relativeTeamLink'
import { SCOPES } from '@root/constants'
import { formatNumber } from '@root/shared/utils'
import PropTypes from 'prop-types'
import Placeholder from '@components/placeholder'
import { Link } from '@toriihq/design-system'

const ActiveUsersColumn = (props) => {
  const { activeUsersCount, idApp } = props

  return (
    <Placeholder>
      <EnableFor scopes={[SCOPES.BASIC_ADMIN, SCOPES.USERS_READ]} allowForToriiAdmin>
        <RelativeTeamLink key={`/app/${idApp}/users`} to={`/app/${idApp}/users`}>
          <Link>{formatNumber(activeUsersCount)}</Link>
        </RelativeTeamLink>
      </EnableFor>
    </Placeholder>
  )
}

ActiveUsersColumn.propTypes = {
  idApp: PropTypes.number.isRequired,
  activeUsersCount: PropTypes.number,
  integrationActiveUsersCount: PropTypes.number
}

ActiveUsersColumn.defaultProps = {
  activeUsersCount: 0,
  integrationActiveUsersCount: 0
}

export default ActiveUsersColumn
