import React from 'react'
import MessagePage from '../../components/signup/messagePage'

const EmailValidation = ({ match: { params: { email } } }) => (
  <MessagePage headerText='Check your email'>
    We've sent a confirmation email to <em>{email}</em>
    <br />
    Open it and click “Activate account”
    <br />
    We'll take it from there
  </MessagePage>
)

export default EmailValidation
