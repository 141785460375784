import styled from 'styled-components'

export const BRANCH_CLASS_NAME = 'branchClass'
export const DELETE_BRANCH_BUTTON_WIDTH = 91

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Branches = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const BranchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const BranchWrapperTitle = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle01};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const Branch = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  background-color: ${({ theme }) => theme.palette.background.primary};
  &:hover {
    border-color: ${({ theme }) => theme.palette.border.primaryHover};
  }
`

export const BranchTitle = styled.div`
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
`

export const DeleteButtonContainer = styled.div`
  position: absolute;
  width: ${DELETE_BRANCH_BUTTON_WIDTH}px;
  padding: 6px 12px;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  .${BRANCH_CLASS_NAME}:is(:hover) & {
    opacity: 1;
  }
`

export const BranchFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BranchFiltersAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const BranchesActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const DefaultBranch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DefaultBranchTitle = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle01};  
  color: ${({ theme }) => theme.palette.text.primary};
  height: 20px;
`

export const DefaultBranchLabel = styled.div`
  font: ${({ theme }) => theme.typography.font.component01};  
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 6px 0;
`
