import React from 'react'
import WorkflowExecutionsPage from '@pages/workflowExecutions'
import { WORKFLOW_TRIGGER_TYPES, WORKFLOW_TYPES } from '@root/constants'
import { getWorkflowPathPrefix, getWorkflowsPathPrefix } from '@lenses/workflows'

const AppCatalogNewAppPolicyExecutionsPage = () => {
  const workflowType = WORKFLOW_TYPES.appCatalog
  const triggerType = WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP
  return <WorkflowExecutionsPage
    isAppCatalogPoliciesView
    backlinkText={'Back to policies'}
    backLinkPath={getWorkflowsPathPrefix({ workflowType, triggerType })}
    executionsPathPrefix={getWorkflowPathPrefix({ workflowType, triggerType })}
  />
}

export default AppCatalogNewAppPolicyExecutionsPage
