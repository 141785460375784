import React, { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Page from '@components/page'
import { WorkflowEditor } from './workflowEditor'
import {
  getContracts,
  getContractsFields,
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig,
  toggleNavigationForceCollapse
} from '@actions/'
import { getWorkflow, getWorkflowEditHistoryInfo } from '@actions/workflows'
import { useIsMounted } from '@shared/hooks'
import { Workflow } from '@shared/workflows/types'
import { isWorkflowUnavailableSelector } from '@selectors/workflows'
import { useParamSelector } from '@shared/utils'
import { WorkflowEmptyState } from './workflowEmptyState'

interface Props {
  idOrg: number
  idWorkflow: number
  workflow: Workflow
  loading: boolean
  isAppCatalogPoliciesView?: boolean
  isRequestNewAppPoliciesView?: boolean
  idAudit?: number
}

const WorkflowV2Page = ({
  idOrg,
  idWorkflow,
  workflow,
  loading,
  isAppCatalogPoliciesView = false,
  isRequestNewAppPoliciesView = false,
  idAudit
}: Props): ReactElement => {
  const dispatch = useDispatch()

  const isMounted = useIsMounted()

  const isWorkflowUnavailable = useParamSelector(isWorkflowUnavailableSelector, { idWorkflow })

  useEffect(() => {
    if (isMounted) {
      dispatch(toggleNavigationForceCollapse(true))
    }

    return () => {
      dispatch(toggleNavigationForceCollapse(false))
    }
  }, [isMounted, dispatch])

  useEffect(() => {
    const fetchWorkflowData = () => {
      dispatch(getWorkflow({ idOrg, idWorkflow, isAppCatalogPoliciesView, isRequestNewAppPoliciesView }))
      dispatch(getWorkflowsTriggersConfig())
      dispatch(getWorkflowsActionsConfig(idOrg))
      dispatch(getContractsFields({ idOrg }))
      dispatch(getContracts({ idOrg }))
    }

    fetchWorkflowData()
  }, [dispatch, idOrg, idWorkflow, isAppCatalogPoliciesView, isRequestNewAppPoliciesView])

  useEffect(() => {
    idAudit && dispatch(getWorkflowEditHistoryInfo({ idOrg, idWorkflow, idWorkflowEditHistory: idAudit }))
  }, [dispatch, idOrg, idWorkflow, idAudit])

  let pageContent = <></>

  if (isWorkflowUnavailable) {
    pageContent = <WorkflowEmptyState
      isAppCatalogPoliciesView={isAppCatalogPoliciesView}
      isRequestNewAppPoliciesView={isRequestNewAppPoliciesView}
    />
  } else if (!loading) {
    pageContent = <WorkflowEditor
      workflow={workflow}
    />
  }

  return <Page title='Workflow' fillContainerHeight>
    {pageContent}
  </Page>
}

export default WorkflowV2Page
