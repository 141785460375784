import View from './view'
import { connect } from 'react-redux'
import { getMe } from '@pages/login/actions'
import { getSelf, isPaidPlanExpired } from '@selectors/me'
import { getOrg } from '@store/actions'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const me = getSelf(state)
  const org = getCurrentOrg(state)
  const paidPlanExpired = isPaidPlanExpired(state)
  const idOrg = parseInt(ownProps.computedMatch.params.idOrg, 10)

  return {
    authState: me.authState,
    me,
    org,
    idOrg: isNaN(idOrg) ? null : idOrg,
    paidPlanExpired
  }
}

const mapDispatchToProps = {
  getMe,
  getOrg
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
