import { createSelector } from 'reselect'
import { getPaidApps, deprecatedGetApps, getAppDetailsValues, getExpensesReportApps } from './apps'
import { getUserPreferences } from './ui'
import { getAppDetailsBySystemKey } from '@lenses/apps'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import range from 'lodash/range'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { getExpensesByQuarters } from '@shared/expenses'
import moment from 'moment'
import { APP_OWNERS_FIELDS, MONTHLY_EXPENSES_REPORT_RANGE } from '@root/constants'

export const getMonthlyExpenses = createSelector(
  [getPaidApps, getExpensesReportApps, getUserPreferences],
  (appsWithExpenses, { apps }, userPreferences) => {
    const showOnlyThisYear = userPreferences?.monthlyExpensesReport?.customFilterSelectedValue === MONTHLY_EXPENSES_REPORT_RANGE.THIS_YEAR ?? false
    const appsWithExpensesByIdApp = keyBy(appsWithExpenses, 'id')

    return apps.reduce((appsWithMonthlyExpensesData, app) => {
      const expensesData = get(appsWithExpensesByIdApp, [app.id, 'expenses'])

      if (!expensesData) {
        return appsWithMonthlyExpensesData
      }

      const filteredExpensesData = expensesData.filter(expenseData => showOnlyThisYear ? expenseData.year === moment().year() : expenseData)

      const monthlyExpenses = filteredExpensesData.slice().reverse().reduce((result, value, key) => {
        result[key] = value
        return result
      }, {})

      const quarterExpenses = getExpensesByQuarters(filteredExpensesData)

      appsWithMonthlyExpensesData.push({
        id: app.id,
        name: app.name,
        category: app.category,
        ...monthlyExpenses,
        ...quarterExpenses,
        imageUrl: app.imageUrl,
        path: `/app/${app.id}`,
        total: sumBy(filteredExpensesData, month => month.total),
        quarterlyTotal: sumBy(Object.values(quarterExpenses)),
        primaryOwner: app[APP_OWNERS_FIELDS.primary],
        state: app.state
      })

      return appsWithMonthlyExpensesData
    }, [])
  }
)

export const getExpensesSummary = createSelector(
  [deprecatedGetApps],
  (usage) => {
    const usageWithExpenses = usage.filter(app => !isEmpty(app.expenses))
    if (!usageWithExpenses.length) {
      return []
    }

    const groupedByCategories = groupBy(usageWithExpenses, app => app.category)

    return range(12)
      .map(index => {
        const { year, month, date } = usageWithExpenses[0].expenses[index] || {}
        const total = sumBy(usageWithExpenses, app => get(app, ['expenses', index, 'total'], 0))

        const categories = Object
          .keys(groupedByCategories)
          .reduce((sum, categoryName) => {
            const appsInCategory = groupedByCategories[categoryName]
            const total = sumBy(appsInCategory, app => get(app, ['expenses', index, 'total'], 0))
            return {
              ...sum,
              [categoryName]: total
            }
          }, {})

        return {
          total,
          year,
          month,
          date,
          categories
        }
      })
  }
)

export const getExpensesSummaryByYear = createSelector(
  [getExpensesSummary],
  (summary) => {
    return mapValues(
      groupBy(summary, 'year'),
      month => sumBy(month, 'total')
    )
  }
)

export const getTotalYearlyCost = createSelector(
  [getAppDetailsValues],
  (values) => {
    const yearlyCostPerApp = getAppDetailsBySystemKey({ systemKey: 'yearlyCost', values })
      .map(detail => Number(first(detail.values)))
      .filter(value => (value > 0))
    return sum(yearlyCostPerApp)
  }
)

export const getYearlyCostPerApp = createSelector(
  [getAppDetailsValues],
  (values) => {
    const yearlyCostsByApp = keyBy(
      getAppDetailsBySystemKey({ systemKey: 'yearlyCost', values }),
      'idApp'
    )

    return mapValues(
      yearlyCostsByApp,
      detail => Number(first(detail.values))
    )
  }
)
