import React, { ReactElement } from 'react'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { ActivationToggleContainer } from '@pages/workflow_v2/workflowEditor/workflowHeader/styles'
import WorkflowActivationToggle from '@components/workflowActivationToggle'
import { getWorkflowPageReferrer } from '@pages/workflow_v2/workflowEditor/utils/utils'

export const ActiveWorkflowToggle = (): ReactElement => {
  const workflow = useSelectedWorkflow()
  const { isAppCatalogPoliciesView } = useWorkflowEditorContext()
  const referrer = getWorkflowPageReferrer(isAppCatalogPoliciesView)
  const title = `Workflow is ${workflow.isActive ? 'on' : 'off'}`
  return (<ActivationToggleContainer>
    {title}
    <WorkflowActivationToggle
      referrer={referrer}
      workflowInfo={workflow}
      isAppCatalogPoliciesView={isAppCatalogPoliciesView}
    />
  </ActivationToggleContainer>)
}
