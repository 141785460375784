import React from 'react'
import moment from 'moment'
import { DatePicker as DSDatePicker, DatePickerProps } from '@toriihq/design-system'

type OnDayChange = {
  onDayChange: (date: string | undefined) => void
}

const DatePicker = ({ format, onDayChange, disabled, ...props }: Omit<DatePickerProps, 'onDayChange'> & OnDayChange) => (
  <DSDatePicker
    format={format}
    onDayChange={(date) => {
      const dateOnly = date ? moment(date).local().format('YYYY-MM-DD') : date
      onDayChange?.(dateOnly)
    }}
    {...props}
    inputProps={{ disabled }}
  />
)

DatePicker.defaultProps = {
  format: 'MM/DD/YYYY'
}

export default DatePicker
