import colors from '@root/shared/style/colors'
import styled from 'styled-components'
export namespace Styles {
  export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
  `
  export const Link = styled.a`
    color: ${colors.blue} !important;
    font-weight: normal;
    cursor: pointer;
    transition: color .2s;
    
    &:active,
    &:hover,
    &:focus {
      color: ${colors.lightBlue1} !important;
    }
  `
}
