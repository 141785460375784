import React from 'react'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

class EmailPreviewButton extends React.Component {
  openPopup = () => {
    const { actionType, toggleWorkflowPreviewPopup } = this.props
    toggleWorkflowPreviewPopup({ isOpen: true, actionType })
  }

  render () {
    const { overrideStyle } = this.props
    return <div style={{ zIndex: 10 }} {...overrideStyle}>
      <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.openPopup} label='Sample email preview' />
    </div>
  }
}

export default EmailPreviewButton
