import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import toArray from 'lodash/toArray'
import flatten from 'lodash/flatten'
import find from 'lodash/find'
import moment from 'moment'
import { numberOfDaysToConsiderAsNew } from '../constants'
import { getAppDetailsFields, getAppDetailsValues } from '@selectors/apps'

const now = moment()

export const isInUse = app => !isEmpty(get(app, 'sources'))

export const isNew = app => (now.diff(moment(app.creationTime), 'days') <= numberOfDaysToConsiderAsNew)

export const getAppDetailsByIdApp = (state, idApp) => {
  const fields = getAppDetailsFields(state)
  const values = getAppDetailsValues(state)
  return fields.map(field => {
    const appValues = getAppDetailsByIdAppIdField({ idApp, idField: field.idField, values })
    return {
      ...field,
      idApp,
      values: appValues
    }
  })
}

export const getAppDetailsByIdAppIdField = ({ idApp, idField, values }) => {
  return ((values[idApp] || []).find(value => value.idField === idField) || {}).values || []
}

export const getAppDetailsBySystemKey = ({ systemKey, values }) => {
  const fieldValues = flatten(toArray(values || {}))
  return fieldValues.filter(field => field.systemKey === systemKey)
}

export const getAppDetailsByIdAppSystemKey = ({ idApp, systemKey, values }) => {
  return ((values[idApp] || []).find(value => value.systemKey === systemKey) || {}).values || []
}

export const getFieldBySystemKey = ({ fields, systemKey }) => {
  return find(fields, field => field.systemKey === systemKey) || {}
}

export const getAppStateInfo = ({ values, idApp, fields }) => {
  const systemKey = 'state'
  const appState = getAppDetailsByIdAppSystemKey({ idApp, systemKey, values })[0] || ''
  const { options = [], idField } = getFieldBySystemKey({ fields, systemKey })
  const isLoading = options.length === 0 || appState.length === 0

  return { options, selectedValue: appState, idField, idApp, isLoading }
}

export const getStateInfoForEsFilters = ({ fields }) => {
  const systemKey = 'state'
  const { options = [] } = getFieldBySystemKey({ fields, systemKey })
  return options.map(o => ({ ...o, value: o.label }))
}
