import { WORKFLOW_FIELD_TYPE } from '@shared/workflows/actions/types'
import {
  Action,
  ActionNodeModel,
  ActionWithFixedBranchesNodeModel,
  WORKFLOW_NODE_TYPE
} from '@shared/workflows/types'

interface GetNewActionWithFixedBranchesParams {
  currentActionNode: ActionNodeModel,
  newAction: Action
}

export const getNewActionWithFixedBranchesNode = ({
  currentActionNode,
  newAction
}: GetNewActionWithFixedBranchesParams): ActionWithFixedBranchesNodeModel => {
  const validationProperties = { isValid: false, errorMsg: '' }

  const fieldsWithoutFixedBranches = newAction.fields.filter(field => field.id !== WORKFLOW_FIELD_TYPE.FIXED_BRANCHES)

  return {
    type: WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES,
    action: {
      ...newAction,
      fields: [
        ...fieldsWithoutFixedBranches,
        {
          id: WORKFLOW_FIELD_TYPE.FIXED_BRANCHES,
          value: {
            branches: [{
              next: currentActionNode.next
            }],
            default: { next: null }
          },
          ...validationProperties
        }],
      ...validationProperties
    }
  }
}
