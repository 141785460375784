import React, { Component } from 'react'
import { css } from 'glamor'
import Analytics from '../../../helpers/analytics'
import Confirmation from '@components/confirmation'
import { ButtonType } from '@toriihq/design-system'

const CSS = {
  confirmationText: css({
    maxWidth: '400px',
    '>div': {
      marginTop: '10px'
    }
  })
}

class HideAppPopup extends Component {
  closeConfirmation = () => {
    const { hideAppPopup: { idApp, name }, toggleHideAppPopup } = this.props
    Analytics.track('Clicked to close popup: hide application', {
      idApp,
      name
    })
    toggleHideAppPopup({ open: false, idApp, name })
  }

  hideApp = async () => {
    const { changeAppHiddenState, idOrg, hideAppPopup: { idApp }, name, getSingleApp, toggleHideAppPopup } = this.props
    Analytics.track('Clicked to hide application', {
      idApp,
      name
    })
    await changeAppHiddenState({ idOrg, idApp, isHidden: true })
    await getSingleApp({ idOrg, idApp })
    toggleHideAppPopup({ open: false })
  }

  render () {
    const { hideAppPopup = {} } = this.props

    return <Confirmation
      isOpen={!!hideAppPopup.open}
      header={`Hide ${hideAppPopup.name}`}
      text={<div {...CSS.confirmationText}>Hidden applications will not be shown in any report, dashboard, summaries or emails. You can always un-hide an application from the Settings screen.<div>Application data is still being collected.</div></div>}
      confirmText={`Hide ${hideAppPopup.name}`}
      confirm={this.hideApp}
      close={this.closeConfirmation}
      declineText='Cancel'
      decline={this.closeConfirmation}
      mainButtonType={ButtonType.destructive}
    />
  }
}

export default HideAppPopup
