import AuditLogRow from '@components/auditLogs/auditLogRow'
import React from 'react'

export const getColumns = ({ users, openURLPrefix, openURLAnalytics }) => {
  return [
    {
      title: '',
      accessor: 'log',
      width: 600,
      Cell: ({ row: { id, editedBy, editTime, editType } }) => {
        return <AuditLogRow key={id} id={id} editedBy={editedBy} editTime={editTime} editType={editType} users={users} openURLPrefix={openURLPrefix} openURLAnalytics={openURLAnalytics} />
      }
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'editedBy',
      show: false
    },
    {
      accessor: 'editTime',
      show: false
    },
    {
      accessor: 'editType',
      show: false
    }
  ]
}
