const { default: analytics } = require('@root/helpers/analytics')

export const onClickAnalytics = (eventName, fromPage, recommendationType, appName) => {
  const props = {
    'Page name': fromPage,
    'Recommendation type': recommendationType
  }

  if (appName) {
    props['Application name'] = appName
  }

  analytics.track(eventName, props)
}
