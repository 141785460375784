import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { oneLiner } from '../../shared/style/mixins'
import { fontWeight } from '../../shared/style/sizes'

const headerHoverStyle = {
  transform: 'translateY(0)'
}
const subHeaderHoverStyle = {
  opacity: 1
}

const CSS = {
  container: css(texts.subheading, {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden'
  })
}

const HoverableHeader = ({ header, subHeader, overrideStyle, hoverClass, overrideHeaderStyle, overrideSubHeaderStyle, rightContent, overrideRightContentStyle, forceHoverStyle }) => {
  const hoverStyle = `.${hoverClass}:hover &`
  CSS.header = css(oneLiner, {
    color: colors.black,
    textTransform: 'uppercase',
    transition: 'color .2s, transform.2s',
    transform: 'translateY(10px)',
    marginBottom: '3px',
    [hoverStyle]: headerHoverStyle,
    ...(forceHoverStyle ? headerHoverStyle : {})
  })

  CSS.subHeader = css(oneLiner, {
    marginBottom: '3px',
    fontWeight: fontWeight.normal,
    opacity: 0,
    transition: 'opacity .2s',
    [hoverStyle]: subHeaderHoverStyle,
    ...(forceHoverStyle ? subHeaderHoverStyle : {})
  })

  CSS.rightContent = css({
    opacity: 0,
    transition: 'opacity .2s',
    lineHeight: '28px',
    [hoverStyle]: subHeaderHoverStyle
  })

  return (<div className='headerContainer' {...css(CSS.container, overrideStyle)}>
    <div>
      <div {...css(CSS.header, overrideHeaderStyle)}>{header}</div>
      <div {...css(CSS.subHeader, overrideSubHeaderStyle)}>{subHeader}</div>
    </div>
    {
      rightContent && <div {...css(CSS.rightContent, overrideRightContentStyle)}>
        {rightContent}
      </div>
    }
  </div>)
}

HoverableHeader.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  hoverClass: PropTypes.string,
  rightContent: PropTypes.node
}

HoverableHeader.defaultProps = {
  hoverClass: 'headerContainer'
}

export default HoverableHeader
