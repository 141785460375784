import styled from 'styled-components'

export const Wrapper = styled.span`
    padding-top: 10px;
    padding-right: 10px;
    height: 100%;
    display: inline-flex;
`

export const Label = styled.div`
    text-align: center;
`
