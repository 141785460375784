import React from 'react'
import Table from '../../../table/index'
import { TABLES } from '../../../../constants'
import colors from '../../../../shared/style/colors'
import { getDisplayName } from '../../../../lenses/users'
import UserDetails from '../../../userDetails'
import SourceIcon from '../../../sourceIcon'
import userToolTip from '../../../../shared/userToolTip'

const getNameColumn = () => ({
  Header: 'Name',
  id: 'name',
  accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
  minWidth: 220,
  maxWidth: 250,
  Cell: ({ row: { firstName, lastName, id, email, isExternal, photoUrl } }) => (
    <UserDetails
      firstName={firstName}
      lastName={lastName}
      email={email}
      isExternal={isExternal}
      idUser={id}
      photoUrl={photoUrl}
    />
  ),
  textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email })
})

class UsersList extends React.Component {
  columns = this.getColumns()

  getHeader = ({ data }) => `UNMANAGED USERS (${data.length})`

  getColumns () {
    return [
      getNameColumn(),
      {
        accessor: 'email',
        show: false,
        textValue: ({ email }) => email,
        textHeader: 'Email'
      },
      {
        Header: 'Email',
        accessor: 'originEmail',
        show: true,
        Cell: ({ original: { originEmail } }) => originEmail
      },
      {
        Header: 'Sources',
        accessor: 'source',
        Cell: ({ value: sources, row: { firstName, lastName, email } }) => (
          <div>
            {(sources || []).map(source => (
              <SourceIcon
                key={source}
                sourceType={source}
                tooltipText={userToolTip({ displayName: getDisplayName({ firstName, lastName, email }), source, appName: this.props.app.name })}
              />))}
          </div>
        ),
        textValue: ({ value: sources }) => (sources || []).toString()
      },
      {
        id: 'uid',
        accessor: (row) => row.docId ?? undefined,
        show: false
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      }
    ]
  }

  searchFilterMethod (row, search) {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.originEmail
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  render () {
    const { users, loading, scrollId } = this.props
    return (
      <Table
        tableKey={TABLES.usersListPopup.key}
        emptyStateMessage='No unmanaged users '
        data={users}
        columns={this.columns}
        header={this.getHeader}
        loading={loading}
        exportable
        searchable
        searchFilterMethod={this.searchFilterMethod}
        getTdProps={setTdStyle}
        clientPaging
        scrollObjectId={scrollId}
      />
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

export default UsersList
