import React from 'react'
import EmailForm from '../../emailForm'
import { safeRedirect } from '../../../shared/utils'

class CatalogSignup extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (email) {
    const { catalogSignup } = this.props
    const response = await catalogSignup(email)
    if (response.loginUrl) {
      safeRedirect(response.loginUrl)
    }
    return response
  }

  render () {
    const { loginInfo } = this.props
    return <EmailForm
      header='Create your account'
      buttonLabel='Confirm'
      loginInfo={loginInfo}
      onSubmit={this.handleSubmit}
      backToLoginText='Already have an account?'
      onSuccessMsg={loginInfo && loginInfo.isSAML ? 'Redirecting...' : 'Please check your email to activate your account.'}
      onAuthenticatedMsg='You are already logged in. Redirecting...'
    />
  }
}

export default CatalogSignup
