import { css } from 'glamor'
import colors from '@shared/style/colors'
import { borderStyle } from '@components/form/formStyle'

export const Container = css({
  position: 'relative'
})
export const Color = css({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  border: `1px solid ${colors.grey3}`
})
export const Swatch = css(borderStyle, {
  padding: '5px',
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  width: '51px',
  marginRight: '4px',
  justifyContent: 'space-around',
  cursor: 'pointer'
})
export const Popover = css({
  position: 'absolute',
  zIndex: '2',
  cursor: 'pointer'
})
export const Cover = css({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px'
})
