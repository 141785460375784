import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { css } from 'glamor'
import { fontSize, fontWeight } from '@shared/style/sizes'

export const HEADER_HEIGHT = '64px'
export const SMALL_DEVICE_WIDTH = '768px'

export const badge = css({
  borderRadius: '2px',
  padding: '0px 3px',
  color: colors.white,
  fontSize: fontSize.extraSmall,
  fontWeight: fontWeight.bold,
  textTransform: 'uppercase',
  height: '16px',
  lineHeight: '16px',
  marginLeft: '14px'
})

export const toggleBoxContainer = css({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '10px'
})

export const disabled = css({
  opacity: 0.5
})

export const explain = css({
  color: colors.black,
  flex: 1
})

export const toggle = css({
  marginLeft: 10
})

export const text = css(texts.body, {
  color: colors.black
})

export const collapsableContent = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '20px',
  [`@media(max-width: ${SMALL_DEVICE_WIDTH})`]: {
    flexDirection: 'column'
  }
})

export const image = css({
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  width: '40%',
  [`@media(max-width: ${SMALL_DEVICE_WIDTH})`]: {
    width: '100%'
  }
})
