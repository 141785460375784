import React from 'react'
import EnableFor from '@components/enableFor'
import { Button, Icon, ButtonType, ButtonSize } from '@toriihq/design-system'

type IconColor = Parameters<typeof Icon>['0']['color']

type Props = {
  onDeleteClick?: () => void,
  onEditClick?: () => void,
  enableForScopes: string[],
  showDeleteAction: boolean,
  showEditAction: boolean,
  iconColor?: IconColor
}

const doNothing = () => {}

const ButtonActions = ({ onDeleteClick = doNothing, onEditClick = doNothing, showDeleteAction, showEditAction, enableForScopes, iconColor }: Props): JSX.Element => {
  return <>
    {showEditAction && renderActionButton({ icon: 'Edit', enableForScopes, actionFunction: onEditClick, iconColor })}
    {showDeleteAction && renderActionButton({ icon: 'Trash', enableForScopes, actionFunction: onDeleteClick, iconColor })}
  </>
}

const renderActionButton = ({ icon, enableForScopes, actionFunction, iconColor = 'inherit' }: { icon: string | any, enableForScopes: string[], actionFunction: () => void, iconColor?: IconColor }): JSX.Element => {
  return <EnableFor scopes={enableForScopes}>
    <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={actionFunction} icon={icon} />
  </EnableFor>
}

export default ButtonActions
