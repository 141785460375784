import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import arrow from './images/arrow_icon.svg'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import Placeholder from '../../placeholder'
import { Button, ButtonSize } from '@toriihq/design-system'

const boxHeight = 200
const boxWidth = 221
const innerBoxHeight = 260
const appIconHeight = 30
const appIconWidth = 30
const buttonHeight = 30
const buttonWidth = 138

const CSS = {
  mainBox: css({
    position: 'relative',
    zIndex: '3',
    width: `${boxWidth}px`,
    height: `${boxHeight}px`,
    textAlign: 'center',
    borderRadius: '4px',
    border: `1px solid ${colors.border}`,
    overflow: 'hidden',
    transition: 'boxShadow .2s, borderColor, .2s',
    '&:hover,&:focus': {
      boxShadow: '0 9px 12px 0 rgba(0,0,0,0.05)',
      borderColor: colors.darkBorder
    }
  }),
  imagesContainer: css({
    display: 'flex',
    justifyContent: 'center',
    height: `${appIconHeight}px`,
    marginBottom: '33px'
  }),
  icon: css({
    shapeRendering: 'crispEdges',
    margin: '0px 12px',
    width: `${appIconWidth}px`,
    height: `${appIconHeight}px`,
    verticalAlign: 'center'
  }),
  innerBox: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: `${innerBoxHeight}px`,
    width: 'auto',
    padding: '27px 20px 30px 20px',
    zIndex: '2',
    backgroundColor: colors.white
  }),
  boxContent: css({
    marginBottom: '25px'
  }),
  textContainer: css({
    fontSize: fontSize.medium,
    fontWeight: fontWeight.normal,
    position: 'relative',
    maxHeight: '80px',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: '20px'
  }),
  button: css({
    height: `${buttonHeight}px`,
    width: `${buttonWidth}px`
  })
}

CSS.innerBoxPosition = css({
  transform: 'translateY(0px)',
  transition: 'transform .3s',
  [`.${CSS.mainBox}:hover &, [data-${CSS.mainBox}]:hover &`]: {
    transform: `translateY(${boxHeight - innerBoxHeight}px)`
  }
})

const WorkflowTemplateBox = ({ apps, text, loading, onClick }) => (
  <div {...CSS.mainBox}>
    <Placeholder loading={loading} type='rect' style={{ width: '100%', height: '100%', maxWidth: '100%', margin: 0 }}>
      <Fragment>
        {apps && text && <div {...css(CSS.innerBox, CSS.innerBoxPosition)}>
          <div {...CSS.boxContent}>
            <div {...CSS.imagesContainer}>
              {apps.map((app, index) => (index === apps.length - 1 ? <img {...CSS.icon} src={app.src} alt={app.name} key={index} /> : <span key={index}><img {...CSS.icon} src={app.src} alt={app.name} /><img src={arrow} alt={'to'} /></span>))}
            </div>
            <div {...CSS.textContainer} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <Button size={ButtonSize.small} onClick={onClick} label='Create workflow' />
        </div>}
      </Fragment>
    </Placeholder>
  </div>
)

WorkflowTemplateBox.propTypes = {
  text: PropTypes.node,
  apps: PropTypes.array,
  onClick: PropTypes.func,
  loading: PropTypes.bool
}

export default WorkflowTemplateBox
