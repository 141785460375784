import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { fontWeight } from '@shared/style/sizes'
import { oneLiner } from '@shared/style/mixins'
import { Avatar, Spacer, Tooltip, RadioButton, TextBadge } from '@toriihq/design-system'
import Placeholder from '../placeholder'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getDisplayName } from '@lenses/users'
import Analytics from '../../helpers/analytics'
import { SCOPES } from '../../constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import VisibleFor from '../visibleFor'
import capitalize from 'lodash/capitalize'

const insideTableRowsClass = '.rt-tr:hover &'
const containerHoverStyle = '.userDetailsContainer:hover &'
const containerStyle = css({
  padding: '4px 0'
})

const nameStyleHover = css({
  color: colors.blue,
  transform: 'translateY(0)'
})
const nameStyle = css(oneLiner, {
  // overflow: 'clip visible',
  color: colors.black,
  transition: 'color .2s, transform.2s',
  ':not(:last-child)': {
    transform: 'translateY(13px)',
    marginBottom: '3px'
  },
  [insideTableRowsClass]: nameStyleHover,
  [containerHoverStyle]: nameStyleHover
})

const nameStyleHoverNoBlue = css({
  transform: 'translateY(0)'
})
const nameStyleNoBlueHover = css(oneLiner, {
  // overflow: 'clip visible',
  color: colors.black,
  transition: 'color .2s, transform.2s',
  ':not(:last-child)': {
    transform: 'translateY(13px)',
    marginBottom: '3px'
  },
  [insideTableRowsClass]: nameStyleHoverNoBlue,
  [containerHoverStyle]: nameStyleHoverNoBlue
})

const emailStyleHover = css({
  opacity: 1
})
const emailStyle = css(oneLiner, {
  marginBottom: '3px',
  fontWeight: fontWeight.normal,
  opacity: 0,
  transition: 'opacity .2s',
  [insideTableRowsClass]: emailStyleHover,
  [containerHoverStyle]: emailStyleHover
})

const CSS = {
  name: nameStyle,
  nameNoBlueHover: nameStyleNoBlueHover,
  email: emailStyle,
  container: containerStyle,
  main: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px'
  }),
  selectIndication: css({
    cursor: 'pointer',
    padding: '8px 21px 8px 11px',
    background: colors.white,
    ':hover': {
      background: `${colors.lightBlue3}`
    },
    ':hover >div': {
      borderColor: `${colors.blue}`
    }
  }),
  content: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    width: '100%'
  }),
  details: css(texts.subheading, {
    flex: 1,
    overflow: 'hidden'
  }),
  nameAndIcon: css({
    width: '100%'
  })
}

const onClick = (id, email, additionalAnalyticsInfo) => {
  Analytics.track('Click on user', {
    'Component': 'UserDetails',
    'UserId': id,
    'UserEmail': email,
    ...additionalAnalyticsInfo
  })
}

class UserDetails extends React.Component {
  render () {
    const { tooltipText, idUser, firstName, lastName, email, photoUrl, isExternal, onSelect, isSelected, selectIndication, linkToUserPage, loading, overrideStyle, showExternalUserBadge, badge, additionalAnalyticsInfo, lifecycleStatus, blueNameOnHover, isSupportTeamUser, isDeletedInIdentitySources, showPastUserBadge } = this.props
    let { emailColor } = this.props
    const userName = getDisplayName({ firstName, lastName, email })
    const isUserOffboardingOrOffboarded = [USER_LIFECYCLE_STATUS.OFFBOARDING, USER_LIFECYCLE_STATUS.OFFBOARDED].includes(lifecycleStatus)

    let badgeComponent = badge ? [].concat(badge).map(badge => <TextBadge color={badge.color} size='Small' key={badge.text}>{badge.text}</TextBadge>) : []

    if (!isSupportTeamUser) {
      if (showExternalUserBadge && isExternal) {
        emailColor = colors.orange
        badgeComponent.push(<TextBadge color='orange' size='Small' key='externalUserBadge'>External</TextBadge>)
      }

      if (isUserOffboardingOrOffboarded) {
        badgeComponent.push(
          lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
            ? <VisibleFor key={lifecycleStatus} scopes={[SCOPES.OFFBOARDING_READ]}><TextBadge key={lifecycleStatus} color='gray' size='Small'>{capitalize(lifecycleStatus)}</TextBadge></VisibleFor>
            : <TextBadge key={lifecycleStatus} color='gray' size='Small'>{capitalize(lifecycleStatus)}</TextBadge>
        )
      } else if (showPastUserBadge && isDeletedInIdentitySources) {
        badgeComponent.push(<TextBadge color='gray' size='Small' key='pastUserBadge'>Past user</TextBadge>)
      }
    }

    badgeComponent = badgeComponent.map(badge => <Spacer left={'space-100'}>{badge}</Spacer>)

    const content = (
      <div {...CSS.content}>
        <Spacer right={'space-150'}>
          <Tooltip label={tooltipText} hide={!tooltipText}>
            <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
          </Tooltip>
        </Spacer>
        <div {...CSS.details}>
          <div {...(blueNameOnHover ? CSS.name : CSS.nameNoBlueHover)}>{userName}{badgeComponent}</div>
          {email && <div {...css(CSS.email, emailColor && { color: emailColor })}>{email}</div>}
        </div>
      </div>
    )

    return (
      <div className='userDetailsContainer' {...css(CSS.container, overrideStyle)}>
        <Placeholder loading={loading} type='text' rows={2} style={{ height: '40px', width: '50px' }}>
          <div {...css(CSS.main, selectIndication && CSS.selectIndication)} data-is-selected={isSelected} onClick={onSelect}>
            {linkToUserPage && idUser && !isSupportTeamUser ? <RelativeTeamUserLink idUser={idUser} className={CSS.nameAndIcon.toString()} onClick={() => onClick(idUser, email, additionalAnalyticsInfo)}>{content}</RelativeTeamUserLink> : content}
            {selectIndication && <RadioButton checked={isSelected} />}
          </div>
        </Placeholder>
      </div>
    )
  }
}

const badgeObject = PropTypes.shape({
  text: PropTypes.string,
  color: PropTypes.string
})

UserDetails.propTypes = {
  idUser: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  isSupportTeamUser: PropTypes.bool,
  emailColor: PropTypes.string,
  badge: PropTypes.oneOfType([
    badgeObject,
    PropTypes.arrayOf(badgeObject)
  ]),
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  selectIndication: PropTypes.bool,
  linkToUserPage: PropTypes.bool,
  showExternalUserBadge: PropTypes.bool,
  tooltipText: PropTypes.element,
  blueNameOnHover: PropTypes.bool
}

UserDetails.defaultProps = {
  selectIndication: false,
  linkToUserPage: true,
  showExternalUserBadge: true,
  onSelect: () => {},
  blueNameOnHover: true
}

export default UserDetails
