import { Edge } from 'reactflow'
import { Workflow } from '@shared/workflows/types'
import { ReactflowGraphData, WorkflowUiNode } from '../../types'
import { getActionsGraphWithoutPositioning } from './getActionsGraphWithoutPositioning'
import { getTriggerUiNode } from './nodeFactory'
import { getEdgeWithAddButton } from './edgeFactory'
import { getEndNodeData } from '@pages/workflow_v2/workflowEditor/workflowGraph/utils/getReactflowGraphData/utils'
import { getPositionedNodes } from './getPositionedNodes'

export const getReactflowGraphData = ({ workflow }: { workflow: Workflow }): ReactflowGraphData => {
  const triggerNode = getTriggerUiNode()
  const nodes: WorkflowUiNode[] = [triggerNode]
  const edges: Edge[] = []

  const { actions } = workflow
  const idRootNode = actions.idRootNode
  if (idRootNode) {
    const triggerToRootEdge = getEdgeWithAddButton({ source: triggerNode.id, target: idRootNode })
    edges.push(triggerToRootEdge)

    const actionsNodesAndEdges = getActionsGraphWithoutPositioning(actions)
    nodes.push(...actionsNodesAndEdges.nodes)
    edges.push(...actionsNodesAndEdges.edges)
  } else {
    const { endNode, endNodeEdge } = getEndNodeData({ idLastNode: triggerNode.id })
    nodes.push(endNode)
    edges.push(endNodeEdge)
  }

  const positionedNodes = getPositionedNodes({ nodes, edges })

  return { nodes: positionedNodes, edges }
}
