import styled from 'styled-components'

export namespace Styles {
  export const HeaderText = styled.span<{ isSmallHeader?: boolean }>`
    ${({ theme, isSmallHeader }) => isSmallHeader
    ? `font: ${theme.typography.font.header04};`
    : `font: ${theme.typography.font.header03};`
  }
  `

  export const ConfirmationPopup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
}
