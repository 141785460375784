import React from 'react'
import { Styles } from './styles'
import Markdown from 'markdown-to-jsx'
import PluginModel from '@pages/pluginMarketplace/types'

const PluginDescription = ({ plugin }: { plugin: PluginModel }) => {
  return (
    <Styles.Wrapper>
      <Markdown children={plugin?.description || ''} options={{
        wrapper: 'article',
        overrides: {
          a: (props) => <Styles.Link {...props} target={'_blank'} rel={'noreferrer noopener'} />
        }
      }} />
    </Styles.Wrapper>
  )
}

export default PluginDescription
