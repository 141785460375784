import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { getCurrencySymbol } from '@selectors/org'
import { round } from 'lodash'

export enum CurrencyFormat {
  FLOAT = '$0,0[.]00',
  DEFAULT = '$0,'
}
export interface CurrencyProps {
    value: number
    format?: CurrencyFormat
    shouldRoundValue?: boolean
    currencySymbol?: string,
    toUpperCase?: boolean
}
export const Currency = (props: CurrencyProps): JSX.Element => {
  const {
    value,
    shouldRoundValue,
    format = CurrencyFormat.DEFAULT,
    currencySymbol = '',
    toUpperCase = false
  } = props

  const orgDefaultCurrencySymbol = useSelector(getCurrencySymbol)
  let valueInMajorUnit = value / 100

  const currency = numeral(shouldRoundValue ? round(valueInMajorUnit) : valueInMajorUnit)
    .format(format)
    .replace('$', currencySymbol || orgDefaultCurrencySymbol)
  return toUpperCase ? currency.toUpperCase() : currency
}
export default Currency
