import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  return {
    idOrg
  }
}

export default withRouter(connect(mapStateToProps)(View))
