import styled from 'styled-components'

export const AppNameAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AppName = styled.span`
  margin-left: 9px;
`
