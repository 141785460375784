import { css } from 'glamor'
import colors from '@shared/style/colors'

export const Header = css({
  flexShrink: 0,
  height: 26,
  padding: '20px 12px 12px 20px',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  boxSizing: 'content-box'
})

export const HeaderShadow = css({
  borderBottom: `1px solid ${colors.border}`
})

export const Logo = css({
  marginLeft: 16
})

export const NavPanelContainer = css({
  position: 'relative',
  zIndex: 3
})

export const NavPanel = css({
  width: '242px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundColor: colors.lightBlue3
})

export const Panel = css({
  position: 'fixed',
  width: '100%',
  backgroundColor: 'rgba(54, 62, 103, 0.42)',
  zIndex: 10,
  display: 'flex'
})

export const Body = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 24,
  overflowY: 'auto',
  overflowX: 'hidden'
})

export const Footer = css({
  display: 'flex',
  flexDirection: 'column'
})

export const WhiteLabel = css({
  background: colors.lightBlue3
})

export const Image = css({
  maxWidth: 130,
  height: 'auto',
  maxHeight: 35,
  width: 'auto'
})
