import styled from 'styled-components'
import { DEFAULT_NODE_DIMENSIONS } from '@pages/workflow_v2/workflowEditor/workflowGraph/consts'
import { ELEVATION_WEIGHT } from '@toriihq/design-system'

const { width, height } = DEFAULT_NODE_DIMENSIONS

export const Container = styled.div`
  height: ${height}px;
  width: ${width}px;
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.card.secondary};
  pointer-events: all;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  box-shadow: ${({ theme }) => theme.elevation(ELEVATION_WEIGHT.SHADOW_100)};

  &:hover:not(.selected) {
    border-color: ${({ theme }) => theme.palette.border.primaryHover};
    box-shadow: ${({ theme }) => theme.elevation(ELEVATION_WEIGHT.SHADOW_200)}
  }

  &.selected {
    border-color: ${({ theme }) => theme.palette.border.active};
    box-shadow: ${({ theme }) => theme.elevation(ELEVATION_WEIGHT.SHADOW_300)};
  }
`
