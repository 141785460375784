import React from 'react'
import Licenses from '../../components/licenses'
import LicenseTrendsChart from '../../components/licenseTrendsChart'
import TabsPage from '../tabs'
import ChargeBackReport from '../../components/chargeBackReport'
import LicensesRecommendations from '@root/components/licensesRecommendations'
import { SCOPES } from '@root/constants'
import { getIncludeAppsInPreview } from '../../components/licensesRecommendations/utils'
import { FEATURES } from '@root/shared/features'

class LicensesPage extends React.Component {
  componentDidMount () {
    const { idOrg, getAllRecommendations } = this.props
    const includeAppsInPreview = getIncludeAppsInPreview()
    getAllRecommendations({ idOrg, includeAppsInPreview })
  }

  menuButtonOnClick = () => {
    const { history, idOrg } = this.props
    history.push(`/team/${idOrg}/services/all`)
  }

  generateTabHeader = (visibleTabs, totalRecommendations) => {
    return visibleTabs.map(tabDetails => {
      const tabHeader = { header: tabDetails.header }
      if (tabDetails.name === 'recommendations') {
        tabHeader.counter = totalRecommendations
      }
      return tabHeader
    })
  }

  render () {
    const { idOrg, isTabEnabled, totalRecommendations } = this.props
    const licensesTabs = [
      { name: 'currentStatus', header: 'Current', content: <Licenses />, feature: FEATURES.LICENSES.TABS.CURRENT },
      { name: 'recommendations', header: 'Recommendations', content: <LicensesRecommendations fromPage={'Licenses'} />, feature: FEATURES.LICENSES.TABS.RECOMMENDATIONS },
      { name: 'trend', header: 'Trend', content: <LicenseTrendsChart />, feature: FEATURES.LICENSES.TABS.TREND },
      { name: 'chargeBack', header: 'Chargeback', content: <ChargeBackReport />, minimalRequiredScopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ, SCOPES.EXPENSE_READ, SCOPES.CONTRACTS_READ], mustHaveAllScopes: true, feature: FEATURES.LICENSES.TABS.CHARGEBACK }
    ]
    const licensesVisibleTabs = {}
    const visibleTabs = licensesTabs.filter(tabDetails => !tabDetails.minimalRequiredScopes || isTabEnabled(tabDetails.minimalRequiredScopes, tabDetails.mustHaveAllScopes))
    licensesVisibleTabs.tabsName = visibleTabs.map(tabDetails => tabDetails.name)
    licensesVisibleTabs.tabsHeader = this.generateTabHeader(visibleTabs, totalRecommendations)
    licensesVisibleTabs.tabsContent = visibleTabs.map(tabDetails => tabDetails.content)
    licensesVisibleTabs.tabsFeature = visibleTabs.map(tabDetails => tabDetails.feature)

    return (
      <TabsPage
        pageHeader='Licenses'
        pageTitle='Licenses'
        pageName='Licenses'
        path={`/team/${idOrg}/licenses`}
        tabsName={licensesVisibleTabs.tabsName}
        tabsHeader={licensesVisibleTabs.tabsHeader}
        tabsContent={licensesVisibleTabs.tabsContent}
        tabsFeature={licensesVisibleTabs.tabsFeature}
        forceShowTabs
        menuButtonText={'Connect new integration'}
        menuButtonOnClick={this.menuButtonOnClick}
      />
    )
  }
}

export default LicensesPage
