import styled from 'styled-components'
import { Grid, RowWithoutMargin as RowWithoutBottomMargin } from '@toriihq/design-system'

export const Tile = styled.div`
  height: 210px;
  border-radius: 8px;
  border: 1px solid #D8DFE7;
  background: #FFF;
  box-shadow: 0 1px 3px 0 rgba(76, 85, 96, 0.05);
  padding: 16px 24px 0;

  &:focus {
   border: ${({ theme }) => `3px solid ${theme.palette.border.focus}`};
  }

  :hover, :active {
    border: ${({ theme }) => `1px solid ${theme.palette.border.active}`};
  }
`

export const Layout = styled(Grid)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Name = styled(RowWithoutBottomMargin)`
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.subtitle02};
`

export const Description = styled.div`
  overflow: hidden;
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

export const Vendor = styled.div`
 color: ${({ theme }) => theme.palette.text.tertiary};
 font: ${({ theme }) => theme.typography.font.body02};
`

export const Badge = styled(RowWithoutBottomMargin)`
  align-items: center;
  justify-content: flex-end !important;
`
