import styled from 'styled-components'
import { WORKFLOW_TAG_ROW_CLASSNAME } from './consts'

export const ActionsContainer = styled.div`
  display: none;
  justify-content: right;
  width: 100%;

  .${WORKFLOW_TAG_ROW_CLASSNAME}:hover & {
    display: flex;
  }
`
