import React from 'react'
import PropTypes from 'prop-types'
import SelectUserButton from '../selectUserButton'
import { SCOPES } from '../../constants'

class AssignUserAppDetailButton extends React.Component {
  render () {
    const { onSelectUser, idApp, user, size, label, assignUserCB } = this.props
    return <SelectUserButton
      onSelectUser={onSelectUser}
      idApp={idApp}
      user={user}
      actionDescription={`Select ${label}`}
      size={size}
      allowedScopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}
      assignUserCB={assignUserCB}
    />
  }
}

AssignUserAppDetailButton.propTypes = {
  size: PropTypes.string,
  idApp: PropTypes.number.isRequired,
  idUser: PropTypes.string,
  idField: PropTypes.number.isRequired
}

export default AssignUserAppDetailButton
