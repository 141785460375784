import styled, { css } from 'styled-components'

export const MainContainer = styled.div<{ overrideStyle }>`
    padding: 10px 21px 0px 0px;
    .recharts-surface {
        overflow: visible;
    }
    ${({ overrideStyle }) => overrideStyle && css(overrideStyle)}
`

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 60px;
  max-width: 1024px;
`

export const Square = styled.span<{ entry: Record<string, any> }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 3px;
  background-color: ${({ entry }) => (entry.color)};
`
