import send from '@shared/redux-fetch'
import {
  GET_DISCOVERED_APPS,
  RESET_DISCOVERED_APPS,
  MODIFY_DISCOVERED_APP,
  RESET_MODIFY_DISCOVERED_APP,
  UPDATE_DISCOVERED_APPS,
  MODIFY_DISCOVERED_APP_RESOURCES,
  UPDATE_DISCOVERED_APPS_PREVIEW
} from '@root/constants'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getOrgDiscoveredApps = ({ idOrg, source, reset, limit, offset, fields, q, sort, status = 'active', mappingStatus = 'all' }) => {
  return send(GET_DISCOVERED_APPS, {
    url: `/api/orgs/${idOrg}/externalApps`,
    meta: {
      reset
    },
    query: omitBy({
      limit,
      offset,
      fields,
      q,
      sort,
      source,
      mappingStatus,
      status
    }, isUndefined)
  })
}

export const resetDiscoveredApps = () => dispatch => {
  dispatch({
    type: RESET_DISCOVERED_APPS
  })
}

export const modifyDiscoveredApps = ({ id, changes }) => dispatch => {
  dispatch({
    type: MODIFY_DISCOVERED_APP, payload: { id, changes }
  })
}

export const modifyDiscoveredAppsResources = ({ app }) => dispatch => {
  dispatch({
    type: MODIFY_DISCOVERED_APP_RESOURCES, payload: { app }
  })
}

export const resetModifyDiscoveredApps = ({ isCanceledByUser = false }) => dispatch => {
  dispatch({
    type: RESET_MODIFY_DISCOVERED_APP,
    payload: {
      isCanceledByUser
    }
  })
}

export const updateDiscoveredApps = ({ idOrg, externalApps }) => {
  return send(UPDATE_DISCOVERED_APPS, {
    url: `/api/orgs/${idOrg}/externalApps/bulk`,
    method: 'PUT',
    body: {
      externalApps
    },
    meta: {
      externalApps
    }
  })
}

export const generateUpdateDiscoveredAppsPreview = ({ idOrg, externalApps }) => {
  return send(UPDATE_DISCOVERED_APPS_PREVIEW, {
    url: `/api/orgs/${idOrg}/externalApps/generateUpdatePreview`,
    method: 'POST',
    body: {
      externalApps
    },
    meta: {
      externalApps
    }
  })
}
