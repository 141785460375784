import { css } from 'glamor'
import texts from '@shared/style/texts'
import { fontWeight } from '@shared/style/sizes'

export const Main = css(texts.heading, {
  fontWeight: fontWeight.normal,
  borderRadius: '4px',
  padding: '10px 10px 0px 10px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

export const Description = css(texts.heading, {
  fontWeight: fontWeight.normal,
  width: '700px',
  marginTop: '16px'
})
