import { createSelector } from 'reselect'
import identity from 'lodash/identity'

export const getLogin = createSelector(
  state => state.login,
  identity
)

export const getLogout = createSelector(
  state => state.logout,
  identity
)
