import View from './view'
import { connect } from 'react-redux'
import { getMe } from '../../pages/login/actions'
import { withRouter } from 'react-router-dom'
import { safeRedirect } from '../../shared/utils'
import qs from 'query-string'

const mapStateToProps = (state, ownProps) => ({
  authState: state.me.authState,
  onAuthenticated: () => {
    const { location } = ownProps
    const query = qs.parse(location.search)
    safeRedirect(query.next || window.location.origin + '/team')
  }
})

const mapDispatchToProps = {
  getMe
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
