import EnableFor from '@components/enableFor'
import React from 'react'

const EnableForIfScopes = ({ scopes, children }) => {
  if (scopes) {
    return (
      <EnableFor scopes={scopes} allowForToriiAdmin>
        {children}
      </EnableFor>
    )
  }
  return children
}

export default EnableForIfScopes
