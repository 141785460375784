import View from './view'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  const { validation, org, isSAML } = state.emailValidation
  const passedEmailValidation = Boolean(validation)
  return {
    ...state.signup,
    passedEmailValidation,
    org,
    isSAML
  }
}

export default connect(mapStateToProps, null)(View)
