import React from 'react'
import emptyImage from '@media/no-results.svg'
import Placeholder from '@components/placeholder'
import Tile from '../tile'
import range from 'lodash/range'
import { Grid, Row, EmptyState } from '@toriihq/design-system'
import * as Style from './style'

const TileList = ({ plugins, loading }) => {
  if (!plugins.length && !loading) {
    return (
      <Style.EmptyState>
        <EmptyState
          image={<img alt='No plugins' src={emptyImage} />}
          description="Looks like there are no plugins that match what you're looking for."
        />
      </Style.EmptyState>
    )
  }

  return (
    <Grid>
      <Row>
        {loading ? (
          range(12).map((_, index) =>
            <Style.ColWithMargin sm={4} lg={3} key={`col-${index}`}>
              <Placeholder key={index} loading type='rect' style={{ width: '100%', height: 210, maxWidth: '100%', borderRadius: 8 }} />
            </Style.ColWithMargin>
          )) : (
          plugins.map(plugin => <Style.ColWithMargin sm={4} lg={3} key={`col-${plugin.id}`}><Tile key={plugin.id} {...plugin} /></Style.ColWithMargin>)
        )
        }
      </Row>
    </Grid>
  )
}

export default TileList
