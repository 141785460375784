export enum FILTER_OPERATORS {
  equals = 'equals',
  notEquals = 'notEquals',
  contains = 'contains',
  notContains = 'notContains',
  anyOf = 'anyOf',
  noneOf = 'noneOf',
  allOf = 'allOf',
  isSet = 'isSet',
  isNotSet = 'isNotSet',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  dayAfter = 'dayAfter',
  dayOnOrAfter = 'dayOnOrAfter',
  dayBefore = 'dayBefore',
  dayOnOrBefore = 'dayOnOrBefore',
  relativeDateMore = 'relativeDateMore',
  relativeDateOn = 'relativeDateOn',
  relativeDateLess = 'relativeDateLess',
  relativeDateToday = 'relativeDateToday',
  exists = 'exists',
  notExists = 'notExists',
  default = 'default'
}
