import React from 'react'
import PropTypes from 'prop-types'
import Services from '../../services'
import Headers from '../headers/index'
import { css } from 'glamor'
import { Button, ButtonType } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'
import Signup from '@components/signup'
import { PLANS } from '@shared/features'

const signupCSS = { margin: '10% auto' }

class ConnectServicesSignupStep extends React.Component {
  state = { isConfirmationShown: false }

  componentDidMount () {
    const { idOrg, shouldSkipStep, getMe, getOrg } = this.props
    getMe()
    idOrg && getOrg({ idOrg })

    if (shouldSkipStep) {
      this.redirectToInsightPage()
    }
  }

  componentDidUpdate (prevProps) {
    const { idOrg, shouldSkipStep, getOrg } = this.props

    if (this.props.idOrg !== prevProps.idOrg) {
      getOrg({ idOrg })
    }

    if (shouldSkipStep) {
      this.redirectToInsightPage()
    }
  }

  redirectToInsightPage () {
    const { idOrg, planType } = this.props
    let query = planType === PLANS.BASIC ? '?product_tour_id=509610' : ''

    window.history.replaceState(null, '', `/team/${idOrg}/insights${query}`)
    window.location.reload()
  }

  render () {
    const { isAnyServiceConnected, onUserSkip, onUserConvinced, idOrg } = this.props
    const { isConfirmationShown } = this.state
    const onCompleteSignup = () => isAnyServiceConnected ? this.redirectToInsightPage() : this.setState({ isConfirmationShown: true })
    return (
      <Signup overrideCss={signupCSS}>
        <div>
          <Headers
            headerText='Get Started:'
            subHeaderText='Connect Google or Azure to instantly uncover shadow IT in your organization.'
          />
          <div {...CSS.body}>
            <Services allowDisconnection={false} idOrg={idOrg} hideConnected isSignupFlow hideBeta hideConnectByLink />
            <div {...CSS.actions}>
              <Button
                htmlButtonType='button'
                onClick={onCompleteSignup}
                label="I'll do it later"
                type={ButtonType.tertiary}
              />
            </div>
          </div>
          <Confirmation
            isOpen={isConfirmationShown}
            header='Whoops! You Missed a Step'
            text={<span>Connect Google Workspace or Azure AD to reveal hidden apps from across your organization</span>}
            confirmText='OK, take me back'
            declineText="I'll do it later"
            decline={() => {
              onUserSkip && onUserSkip()
              this.redirectToInsightPage()
            }}
            confirm={() => {
              onUserConvinced && onUserConvinced()
              this.setState({ isConfirmationShown: false })
            }}
            close={() => {
              onUserConvinced && onUserConvinced()
              this.setState({ isConfirmationShown: false })
            }}
          />
        </div>
      </Signup>
    )
  }
}

ConnectServicesSignupStep.propTypes = {
  onUserSkip: PropTypes.func,
  onUserConvinced: PropTypes.func
}

const CSS = {
  body: css({
    marginTop: '35px'
  }),
  actions: css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '35px'
  })
}

export default ConnectServicesSignupStep
