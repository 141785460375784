import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 8px;
`

export const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.secondary};
  border-top-left-radius: ${({ theme }) => theme.corner('radius-100')};
  border-top-right-radius: ${({ theme }) => theme.corner('radius-100')};
  border-bottom: none;
`
