import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import App from './app'
import Placeholder from '../placeholder'

const CSS = {
  container: css({
    minHeight: '165px',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
    width: '100%',
    height: 'calc(100% - 1px)'
  }),
  selected: css({
    backgroundColor: colors.lightBlue3
  }),
  header: css(texts.subheading, {
    color: colors.black,
    textTransform: 'uppercase'
  }),
  disabledHeader: css({
    color: colors.grey2
  }),
  selectedHeader: css({
    color: colors.grey1
  }),
  subHeader: css(texts.body, {
    color: colors.grey2,
    marginTop: '5px'
  }),
  appsContainer: css({
    marginTop: '52px',
    display: 'flex',
    flexWrap: 'wrap'
  }),
  selectionIndication: css({
    backgroundColor: colors.blue,
    width: '8px',
    height: '8px',
    display: 'inline-block',
    borderRadius: '8px',
    marginRight: '6px',
    marginLeft: '-14px'
  })
}

class MonthlyRenewalBox extends React.Component {
  render () {
    const { loading, header, subHeader, renewals, appsInfo, isSelected, isPassed, showPassedBadge, contractStatusOptionsByValue } = this.props

    const containerStyle = css(CSS.container, isSelected && CSS.selected)
    const headerStyle = css(CSS.header, isPassed && CSS.disabledHeader, isSelected && CSS.selectedHeader)

    const noRenewals = renewals && renewals.length === 0
    const hasRenewals = !noRenewals

    return (
      <div {...containerStyle}>
        <Placeholder loading={loading} type='text' rows={2} style={{ height: '50px', width: '50px' }}>
          <div>
            <div {...headerStyle}>
              {isSelected && <div {...CSS.selectionIndication} />}
              {header}
            </div>
            {!noRenewals && subHeader && <div {...CSS.subHeader}>{subHeader}</div>}
            {noRenewals && <div {...CSS.subHeader}>No renewals</div>}
            {hasRenewals && <div {...CSS.appsContainer}>
              {renewals.map(renewal => {
                const app = appsInfo[renewal.idApp]
                return app ? <App key={renewal.id} app={app} renewal={renewal} showPassedBadge={showPassedBadge} contractStatusOptionsByValue={contractStatusOptionsByValue} linkToTab='/contracts' /> : null
              })}
            </div>
            }
          </div>
        </Placeholder>
      </div>
    )
  }
}

MonthlyRenewalBox.propTypes = {
  loading: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPassed: PropTypes.bool.isRequired,
  appsInfo: PropTypes.object.isRequired,
  showPassedBadge: PropTypes.bool.isRequired,
  renewals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    idApp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    date: PropTypes.object.isRequired,
    amount: PropTypes.object,
    isPassed: PropTypes.bool.isRequired
  })),
  contractStatusOptionsByValue: PropTypes.object
}

MonthlyRenewalBox.defaultProps = {
  showPassedBadge: false
}

export default MonthlyRenewalBox
