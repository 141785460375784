import { LICENSES_RECOMMENDATIONS_TYPES } from '@root/constants'
import pluralize from 'pluralize'
import { startCase, upperFirst } from 'lodash'

export const generateTextForType = (recommendation, appName) => {
  let headerText
  let insightText
  let recommendedActionText

  const pluralizeInsightLicense = (count) => {
    return count === 1 ? 'license is' : 'licenses are'
  }

  switch (recommendation.type) {
    case LICENSES_RECOMMENDATIONS_TYPES.DOWNGRADE: {
      const { numberOfUsers, downgradeToLicense = {} } = recommendation
      headerText = `Downgrade ${numberOfUsers} underutilized ${getAppNameAndLicenseName(appName, recommendation)} ${pluralize('license', numberOfUsers)}`
      insightText = `${getAppNameAndLicenseName(appName, recommendation)} ${pluralizeInsightLicense(numberOfUsers)} underutilized`
      recommendedActionText = `Downgrade to ${startCase(`${downgradeToLicense.name}`)}`
      break
    }
    case LICENSES_RECOMMENDATIONS_TYPES.RECLAIM: {
      const { numberOfUsers } = recommendation
      headerText = `Reclaim ${numberOfUsers} ${getAppNameAndLicenseName(appName, recommendation)} ${pluralize('license', numberOfUsers)} of ${numberOfUsers === 1 ? 'a' : ''} past ${pluralize('user', numberOfUsers)}`
      insightText = `${getAppNameAndLicenseName(appName, recommendation)} ${pluralizeInsightLicense(numberOfUsers)} assigned to past users`
      recommendedActionText = `Reclaim ${pluralize('license', numberOfUsers)}`
      break
    }
    case LICENSES_RECOMMENDATIONS_TYPES.RECLAIM_UNUSED: {
      const { numberOfUsers } = recommendation
      headerText = `Reclaim ${numberOfUsers} unused ${getAppNameAndLicenseName(appName, recommendation)} ${pluralize('license', numberOfUsers)}`
      insightText = `${getAppNameAndLicenseName(appName, recommendation)} ${pluralizeInsightLicense(numberOfUsers)} unused`
      recommendedActionText = `Reclaim ${pluralize('license', numberOfUsers)}`
      break
    }
    default:
      break
  }

  return { headerText, insightText, recommendedActionText }
}

export const generateDrillDownTexts = (recommendation, appName) => {
  let headerText = ''

  if (!recommendation) {
    return { headerText }
  }

  switch (recommendation.type) {
    case LICENSES_RECOMMENDATIONS_TYPES.DOWNGRADE: {
      const { numberOfUsers } = recommendation
      headerText = `Users underutilizing their ${getAppNameAndLicenseName(appName, recommendation)} licenses (${numberOfUsers})`
      break
    }
    case LICENSES_RECOMMENDATIONS_TYPES.RECLAIM_UNUSED: {
      const { numberOfUsers } = recommendation
      headerText = `Users who are not using their ${getAppNameAndLicenseName(appName, recommendation)} license (${numberOfUsers})`
      break
    }
    case LICENSES_RECOMMENDATIONS_TYPES.RECLAIM: {
      const { numberOfUsers } = recommendation
      headerText = `Past users with a ${getAppNameAndLicenseName(appName, recommendation)} license (${numberOfUsers})`
      break
    }
    default:
      break
  }

  return { headerText }
}

export const getActionButtonText = (recommendation) => {
  const { type } = recommendation

  switch (type) {
    case LICENSES_RECOMMENDATIONS_TYPES.DOWNGRADE:
      return 'Downgrade licenses'
    case LICENSES_RECOMMENDATIONS_TYPES.RECLAIM:
    case LICENSES_RECOMMENDATIONS_TYPES.RECLAIM_UNUSED:
      return 'Reclaim licenses'
    default:
      return ''
  }
}

const getAppNameAndLicenseName = (appName = '', recommendation) => {
  const { recommendedLicense, recommendationConfig } = recommendation
  const { name: licenseName = '' } = recommendedLicense
  const { hideLicenseName } = recommendationConfig

  const doesLicenseNameStartsWithAppName = licenseName.split(' ')[0].toLowerCase() === appName.toLowerCase()
  if (appName && licenseName) {
    return (hideLicenseName || appName.toLowerCase() === licenseName.toLowerCase()) ? upperFirst(appName)
      : doesLicenseNameStartsWithAppName ? startCase(licenseName) : `${upperFirst(appName)} ${startCase(licenseName)}`
  } else {
    return ''
  }
}
