import React, { Component } from 'react'
import ToriiSelect from '../select'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import { AppIcon, Spacer } from '@toriihq/design-system'
import { oneLiner } from '../../shared/style/mixins'
import colors from '../../shared/style/colors'
import { fontSize, fontWeight } from '../../shared/style/sizes'
import identity from 'lodash/identity'
import isUndefined from 'lodash/isUndefined'
import omit from 'lodash/omit'
import flatten from 'lodash/flatten'

const HEIGHT = '32px'

const CSS = {
  app: css({
    display: 'flex',
    alignItems: 'center',
    ' > span': css(oneLiner, {
      fontSize: fontSize.small,
      fontWeight: fontWeight.semiBold,
      color: colors.darkText,
      lineHeight: HEIGHT,
      width: 'unset'
    })
  }),
  disabledApp: css({
    cursor: 'not-allowed',
    ' > span': css(oneLiner, {
      color: colors.text,
      lineHeight: HEIGHT
    })
  }),
  badge: css({
    borderRadius: '2px',
    padding: '2px 3px',
    color: colors.white,
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.bold,
    textTransform: 'uppercase',
    margin: '0 4px'
  })
}

const SelectLicense = ({ item }) => {
  const name = item.name === item.app.name || item.name.startsWith(item.app.name) ? item.name : `${item.app.name} - ${item.name}`
  return <div {...css(CSS.app, (item.exists || item.hidden) && CSS.disabledApp)}>
    <Spacer right={'space-100'}>
      <AppIcon appName={item.app.name} appImageUrl={item.app.imageUrl} size='small' />
    </Spacer>
    <span style={{ width: 'unset' }}>{name}</span>
  </div>
}

class SelectLicenses extends Component {
  state = {
    selectedLicense: null
  }

  componentDidMount () {
    const { idOrg, getAppsLicenses } = this.props
    getAppsLicenses({ idOrg })
  }

  onChange = async (selectedLicense) => {
    const { onChange } = this.props
    this.setState({ selectedLicense })
    onChange && onChange(selectedLicense)
  }

  renderLicense = ({ data: item }) => {
    return <SelectLicense item={item} />
  }

  render () {
    const { value, placeholder, appsLicenses, ...restSelectParams } = this.props
    const { selectedLicense } = this.state

    const licenses = flatten(appsLicenses.map(app => ({ ...app, licenses: app.licenses.map(l => ({ ...l, id: l.idLicense, app: omit(app, 'licenses') })) })).map(app => app.licenses))
    return (
      <ToriiSelect
        optionRenderer={this.renderLicense}
        valueRenderer={this.renderLicense}
        valueKey='id'
        labelKey='name'
        placeholder={placeholder}
        maxItemsToShow={10}
        backspaceRemoves={false}
        clearable={false}
        autoBlur
        filterOptions={identity}
        noResultsText='No license found'
        cache={false}
        loadingPlaceholder={'Loading...'}
        {...restSelectParams}
        value={isUndefined(value) ? selectedLicense : value}
        onChange={this.onChange}
        options={licenses}
      />
    )
  }
}

SelectLicenses.propTypes = {
  placeholder: PropTypes.string
}

SelectLicenses.defaultProps = {
  placeholder: 'Select License...'
}

export default SelectLicenses
