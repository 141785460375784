import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontWeight } from '../../shared/style/sizes'
import moment from 'moment'
import Table from '../table'
import { TABLES, TRANSACTION_MAPPING_STATUS, SCOPES, ITEMS_PER_PAGE, DATE_FORMAT } from '../../constants'
import { Tooltip, Avatar, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import isEqual from 'lodash/isEqual'
import ExpensesActivityHeader from './header'
import SelectApps from '../selectApps'
import EnableFor from '../enableFor'
import Analytics from '../../helpers/analytics'
import { hasPropsChanged } from '../../shared/utils'
import { hoverActions } from '../../shared/style/mixins'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getDisplayName } from '../../lenses/users'
import { debounce } from 'lodash'
import { sortStringFromArray } from '@shared/utils'
import Currency from '../currency'

const CSS = {
  content: css({
    minHeight: 'calc(100vh - 280px)'
  }),
  transactionActions: css({
    textAlign: 'right'
  }),
  archivedMark: css({
    opacity: 0.5,
    filter: 'grayscale(1)',
    textDecoration: 'line-through'
  }),
  colorIndication: css({
    display: 'inline-block',
    position: 'relative',
    top: '1px',
    left: '-20px',
    width: '10px',
    height: '10px',
    borderRadius: '10px',
    marginRight: '-10px'
  }),
  footer: css({
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bottom: 0,
    marginTop: '15px',
    padding: '20px 0',
    height: '120px',
    backgroundColor: colors.white,
    borderTop: `1px solid ${colors.border}`
  }),
  footerSummary: css({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    ' > div': {
      marginRight: '60px'
    }
  }),
  number: css({
    color: colors.darkText,
    fontSize: '30px',
    fontWeight: fontWeight.semiBold
  }),
  caption: css({
    color: colors.grey2,
    fontSize: '12px',
    fontWeight: fontWeight.semiBold,
    textTransform: 'uppercase'
  }),
  appCell: css({
    height: '32px',
    display: 'flex',
    alignItems: 'center'
  }),
  appCellSelect: css({
    width: '220px',
    height: '32px'
  }),
  appCellActions: css(hoverActions, {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px'
  })
}

const isArchived = (mappingStatus) => mappingStatus === TRANSACTION_MAPPING_STATUS.ARCHIVED

class ExpensesActivity extends React.Component {
  getColumns = () => {
    return [
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value: description, row: { idApp, extraInfo, mappingStatus } }) => (
          <div {...css(isArchived(mappingStatus) && CSS.archivedMark)}>
            <span {...CSS.colorIndication} style={{ backgroundColor: idApp ? colors.green : colors.grey3 }} />
            <span {...css({ whiteSpace: 'normal' })}>{description}{extraInfo ? ` - ${extraInfo}` : ''}</span>
          </div>
        ),
        maxWidth: 400
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value: amount, row: { mappingStatus } }) => <span className={css(isArchived(mappingStatus) && CSS.archivedMark).toString()}> <Currency value={amount} /> </span>,
        ...Table.numericFieldProps,
        width: 100
      },
      {
        Header: 'Transaction date',
        accessor: 'transactionDate',
        Cell: ({ value: date, row: { mappingStatus } }) => (
          <div {...css(isArchived(mappingStatus) && CSS.archivedMark)}>
            {moment.utc(date).format(DATE_FORMAT)}
          </div>
        ),
        maxWidth: 210
      },
      {
        Header: 'Reported by',
        accessor: 'reportedBy',
        Cell: ({ value: reportedBy }) => {
          const { usersById } = this.props
          if (reportedBy && usersById[reportedBy]) {
            const reporterUserDetails = usersById[reportedBy]
            const { firstName, lastName, photoUrl } = reporterUserDetails
            return (
              <RelativeTeamUserLink idUser={reportedBy}>
                <Tooltip
                  label={`Reported by ${getDisplayName(reporterUserDetails)}`}
                >
                  <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
                </Tooltip>
              </RelativeTeamUserLink>
            )
          }

          return null
        }
      },
      {
        Header: 'Matched App',
        accessor: 'idApp',
        width: 330,
        Cell: ({ value: idApp, row: transaction }) => {
          const isArchivedTransaction = transaction.mappingStatus === TRANSACTION_MAPPING_STATUS.ARCHIVED
          const actionNewStatus = isArchivedTransaction && idApp ? TRANSACTION_MAPPING_STATUS.MAPPED : isArchivedTransaction ? TRANSACTION_MAPPING_STATUS.UNKNOWN : TRANSACTION_MAPPING_STATUS.ARCHIVED
          return <div {...css(CSS.appCell)}>
            <div {...css(CSS.appCellSelect, isArchived(transaction.mappingStatus) && CSS.archivedMark)}>
              <SelectApps
                disabled={isArchivedTransaction}
                placeholder='No match found'
                value={this.props.appsById[idApp] || idApp}
                disableHiddenApps
                onChange={(app, searchValue) => {
                  if (app.value === 'customApp') {
                    this.props.toggleAddApplication({ isAddApplicationOpen: true, customApp: true, app: { name: searchValue }, openFrom: 'Upload expenses' })
                  } else {
                    this.editTransaction(app.id, TRANSACTION_MAPPING_STATUS.MAPPED, transaction)
                  }
                }}
                specialOption={{
                  value: 'customApp',
                  render: (searchValue) => <div style={{ color: colors.blue }}>Add a custom app "{searchValue}"</div>
                }}
              />
            </div>
            <div {...CSS.appCellActions}>
              <Tooltip
                placement='top'
                hide={isArchivedTransaction}
                label='Archive'
              >
                <EnableFor scopes={[SCOPES.EXPENSE_WRITE]}>
                  {isArchivedTransaction
                    ? <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.editTransaction(idApp, actionNewStatus, transaction)} label='Unarchive' />
                    : <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.editTransaction(idApp, actionNewStatus, transaction)} icon='Archive' />}
                </EnableFor>
              </Tooltip>
            </div>
          </div>
        }
      },
      {
        accessor: 'idUpload',
        show: false
      },
      {
        accessor: 'idOrg',
        show: false
      },
      {
        accessor: 'fileName',
        show: false
      },
      {
        accessor: 'idExternalTransaction',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'mappingStatus',
        show: false
      },
      {
        accessor: 'extraInfo',
        show: false
      }
    ]
  }

  state = { sort: this.props.defaultSort }

  componentDidMount () {
    this.fetchInitialData()
  }

  componentDidUpdate (prevProps) {
    const shouldFetch = hasPropsChanged(this.props, prevProps, ['uploadCounter', 'idUpload', 'idOrg'])
    if (shouldFetch) {
      this.fetchInitialData()
    }

    if (!isEqual(prevProps.usersById, this.props.usersById)) {
      const columns = this.getColumns({ usersById: this.props.usersById })
      this.setState({ columns })
    }
  }

  fetchInitialData () {
    const { idOrg, idUpload, getUpload, resetTransactions } = this.props
    if (!idOrg || !idUpload) {
      return
    }

    resetTransactions()
    getUpload({ idOrg, idUpload })
    this.fetchData(true)
  }

  fetchData = debounce((reset = false) => {
    const { transactions } = this.props

    this.getUploadTransactions({ offset: reset ? 0 : transactions.length, reset })
  }, 250)

  getUploadTransactions = ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false }) => {
    const { idOrg, idUpload, getUploadTransactions } = this.props
    const { sort } = this.state

    getUploadTransactions({ idOrg, idUpload, limit, offset, sort: sortStringFromArray(sort), reset, q: this.query })
  }

  onSortedChange = (sort) => {
    this.setState({ sort }, () => this.fetchData(true))
  }

  onSearch = debounce((q) => {
    this.query = q
    this.fetchData(true)
  }, 250)

  editTransaction = async (idApp, mappingStatus, transaction) => {
    const { editTransactionApp } = this.props
    await Analytics.track('Edited transaction app', {
      'Transaction ID': transaction.id,
      'Transaction name': transaction.name,
      'mappingStatus': mappingStatus,
      'idApp': idApp
    })
    await editTransactionApp(idApp, mappingStatus, transaction)
  }

  onNext = () => {
    const { idOrg, history } = this.props
    history.push(`/team/${idOrg}/expenses/allExpenses`)
  }

  renderFooter = () => {
    const { loading, upload } = this.props
    const { mapped, transactions, mappedExpenses } = upload || {}
    return (
      <div {...CSS.footer}>
        <div {...CSS.footerSummary}>
          {!loading && (
            <>
              <div>
                <div {...CSS.number}>{mapped}</div>
                <div {...CSS.caption}>Matched transactions</div>
              </div>
              <div>
                <div {...CSS.number}>{transactions}</div>
                <div {...CSS.caption}>Total transactions</div>
              </div>
              <div>
                <div {...CSS.number}><Currency value={mappedExpenses} /></div>
                <div {...CSS.caption}>Matched expenses</div>
              </div>
            </>
          )}
        </div>
        <div>
          <Button onClick={this.onNext} label='See all expenses' />
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { loading, upload, idOrg, idUpload, usersById } = this.props
    if (loading || !upload) {
      return null
    }

    return <ExpensesActivityHeader
      idOrg={idOrg}
      idUpload={idUpload}
      idUser={upload.idUser}
      fileName={upload.fileName}
      creationTime={upload.creationTime}
      usersById={usersById}
    />
  }

  render () {
    const { loading, transactions, totalTransactions } = this.props

    return (
      <div>
        <div {...CSS.content}>
          <Table
            tableKey={TABLES.expensesUploadActivityTable.key}
            emptyStateMessage='There are no transactions'
            data={transactions}
            fetchData={this.fetchData}
            header={this.renderHeader()}
            columns={this.getColumns()}
            loading={loading}
            manual
            totalCount={totalTransactions}
            searchable
            forceShowSearch
            onSearch={this.onSearch}
            onSortedChangeCB={this.onSortedChange}
            overrideTrStyle={{ paddingLeft: '10px' }}
            overrideTheadStyle={{ paddingLeft: '10px' }}
          />

        </div>
        {this.renderFooter()}
      </div>
    )
  }
}

ExpensesActivity.propTypes = {
  transactions: PropTypes.array,
  defaultSort: PropTypes.array
}

ExpensesActivity.defaultProps = {
  defaultSort: [{ id: 'description', desc: false }]
}

export default ExpensesActivity
