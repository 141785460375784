import analytics from '@root/helpers/analytics'

const Analytics = {
  selectReportYear: (year: number) => {
    const props = {
      year
    }
    analytics.track('Select report-year', props)
  },
  filterTableView: (bucketName: string, filterMethod: string) => {
    const props = {
      bucketName,
      filterMethod
    }
    analytics.track('Filter table-view', props)
  },
  openContract: () => {
    analytics.track('Open Contract')
  },
  hoverChart: (column: string) => {
    const props = {
      column
    }
    analytics.track('Hover chart', props)
  }
}

export default Analytics
