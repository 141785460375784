import moment from 'moment'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  AlertBox,
  AlertBoxType,
  Body2,
  Button,
  ButtonState,
  Caption1,
  Divider,
  ExpandableSection,
  Link,
  Stack,
  Subtitle2
} from '@toriihq/design-system'
import { getWorkflow } from '@actions/workflows'
import EnableFor from '@components/enableFor'
import { getFormattedDate } from '@lenses/utils'
import { SUPPORT_ARTICLES } from '@root/articles'
import { SCOPES } from '@root/constants'
import { usePolling } from '@shared/hooks/usePolling'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { getTestState } from './utils/getTestState'
import { Styles } from './styles'
import { InputValue, TRIGGER_PAYLOAD_TEST_STATE } from './types'

interface Props {
  input: {
    onChange: (data: InputValue) => void
    value: InputValue
    disabled?: boolean
  }
}

export const CustomWebhookPayloadConfig = ({
  input
}: Props): ReactElement => {
  const {
    value,
    disabled,
    onChange
  } = input

  const {
    testStartTime,
    testPayloadReceivedTime,
    payload,
    testError
  } = value ?? {}

  const dispatch = useDispatch()

  const [testState, setTestState] = useState<TRIGGER_PAYLOAD_TEST_STATE>()

  const {
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView
  } = useWorkflowEditorContext()

  const { idOrg, idWorkflow } = useParams()

  useEffect(() => {
    const setTestStateFromValueData = () => {
      const newTestState = getTestState({
        testStartTime,
        testPayloadReceivedTime,
        payload,
        testError
      })
      newTestState !== testState && setTestState(newTestState)
    }

    setTestStateFromValueData()
    const intervalId = setInterval(setTestStateFromValueData, 1000)

    return () => clearInterval(intervalId)
  }, [testStartTime, testPayloadReceivedTime, payload, testError, testState])

  const isInProgress = testState === TRIGGER_PAYLOAD_TEST_STATE.IN_PROGRESS

  const dispatchGetWorkflowTriggerConfiguration = useCallback(() =>
    dispatch(getWorkflow({
      idOrg,
      idWorkflow,
      isAppCatalogPoliciesView,
      isRequestNewAppPoliciesView,
      fields: ['triggerConfiguration']
    })), [dispatch, idOrg, idWorkflow, isAppCatalogPoliciesView, isRequestNewAppPoliciesView])

  usePolling({
    shouldPoll: isInProgress,
    pollingFunction: dispatchGetWorkflowTriggerConfiguration
  })

  const formattedTestResultReceivedTime = testPayloadReceivedTime && getFormattedDate({
    date: testPayloadReceivedTime,
    includeTime: true
  })

  const handleTestTrigger = () => {
    onChange({
      ...value,
      testStartTime: moment().utc().toISOString(),
      testError: null
    })
  }

  const handleCancelTestTrigger = () => {
    onChange({
      ...value,
      testStartTime: null
    })
  }

  const failureData = testError
    ? {
      title: 'An error occurred while testing the trigger.',
      description: testError
    }
    : {
      title: 'No incoming requests received.',
      description: <Link
        target='_blank'
        href={SUPPORT_ARTICLES.CUSTOM_TRIGGER_WORKFLOW}
      >
        Learn how to configure Custom trigger.
      </Link>
    }

  return (
    <Stack
      direction='column'
      gap='space-300'
    >
      <Divider orientation='Vertical' />
      <Stack
        direction='column'
        gap='space-100'
      >
        <Subtitle2>
          Test this trigger
        </Subtitle2>
        <Body2 color='secondary'>
          Torii is listening for incoming requests to confirm the trigger configuration is correct.
          <br />
          The data from this test can be used in actions.
        </Body2>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        gap='space-100'
      >
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <Button
            onClick={handleTestTrigger}
            label='Test trigger'
            buttonState={isInProgress ? ButtonState.loading : ButtonState.neutral}
            disabled={disabled}
          />
        </EnableFor>
        {isInProgress && <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <Button
            onClick={handleCancelTestTrigger}
            type='tertiary'
            label='Cancel'
            disabled={disabled}
          />
        </EnableFor>}
      </Stack>
      {testState === TRIGGER_PAYLOAD_TEST_STATE.SUCCESS && <AlertBox
        type={AlertBoxType.POSITIVE}
        title='Successfully received an incoming request.'
      />}
      {testState === TRIGGER_PAYLOAD_TEST_STATE.FAILURE && <AlertBox
        type={AlertBoxType.NEGATIVE}
        {...failureData}
      />}
      {payload && <Stack
        direction='column'
        gap='space-100'
      >
        <Subtitle2>
          Request results
        </Subtitle2>
        <Body2 color='secondary'>
          Use the data from the test to configure actions in this workflow.
        </Body2>
        <Caption1 color='tertiary'>
          Webhook received at {formattedTestResultReceivedTime} UTC
        </Caption1>
        <Styles.PayloadSectionContainer>
          <ExpandableSection title='Raw JSON'>
            <ReactJson
              src={payload}
              name={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </ExpandableSection>
        </Styles.PayloadSectionContainer>
      </Stack>}
    </Stack>
  )
}
