export const daysOfWeekOptions = [
  { label: 'Every day', value: '*' },
  { label: 'Monday - Friday', value: '1,2,3,4,5' },
  { label: 'Sunday - Thursday', value: '1,2,3,4,7' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
  { label: 'Sunday', value: '7' }
]

export const hourOptions = [
  { label: 'Midnight', value: '0' },
  { label: '1 AM', value: '1' },
  { label: '2 AM', value: '2' },
  { label: '3 AM', value: '3' },
  { label: '4 AM', value: '4' },
  { label: '5 AM', value: '5' },
  { label: '6 AM', value: '6' },
  { label: '7 AM', value: '7' },
  { label: '8 AM', value: '8' },
  { label: '9 AM', value: '9' },
  { label: '10 AM', value: '10' },
  { label: '11 AM', value: '11' },
  { label: '12 PM', value: '12' },
  { label: '1 PM', value: '13' },
  { label: '2 PM', value: '14' },
  { label: '3 PM', value: '15' },
  { label: '4 PM', value: '16' },
  { label: '5 PM', value: '17' },
  { label: '6 PM', value: '18' },
  { label: '7 PM', value: '19' },
  { label: '8 PM', value: '20' },
  { label: '9 PM', value: '21' },
  { label: '10 PM', value: '22' },
  { label: '11 PM', value: '23' }
]

export const utcTimezone = 'UTC'
