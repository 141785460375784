import React from 'react'
import Page from '../../components/page'
import MonthlyExpensesReport from '../../components/monthlyExpensesReport'
import ExtensionRolloutReport from '../../components/extensionRolloutReport'
import SSOAuditReport from '../../components/ssoAuditReport'
import InactiveUsersReport from '../../components/inactiveUsersReport'
import VisibleFor from '@components/visibleFor'
import { SCOPES } from '@root/constants'
import InaccessiblePage from '@pages/inaccessiblePage/inaccessiblePage'

const REPORTS = {
  monthly: {
    title: 'Expense analysis',
    component: <MonthlyExpensesReport />,
    minimalRequiredScope: SCOPES.EXPENSE_READ
  },
  extensionRollout: {
    title: 'Extension status',
    component: <ExtensionRolloutReport />,
    HEADER_HEIGHT: 215
  },
  ssoAudit: {
    title: 'SSO Audit',
    component: <SSOAuditReport />
  },
  inactiveUsers: {
    title: 'Inactive users',
    component: <InactiveUsersReport />
  }
}

class ReportPage extends React.Component {
  render () {
    const { type } = this.props
    const reportType = REPORTS[type]
    const minimalRequiredScope = reportType.minimalRequiredScope
    return (
      <VisibleFor scopes={minimalRequiredScope ? [minimalRequiredScope] : []} fallback={<InaccessiblePage />}>
        <Page title={reportType.title}>
          {reportType.component}
        </Page>
      </VisibleFor>
    )
  }
}

export default ReportPage
