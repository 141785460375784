export enum COMPLIANCE_TYPES {
    SOC2 = 'soc2',
    ISO27001 = 'iso27001',
    GDPR = 'gdpr'
}

export enum COMPLIANCE_TYPE_LABELS {
    gdpr = 'GDPR',
    iso27001 = 'ISO 27001',
    soc2 = 'SOC 2'
}
