const validate = (values, state) => {
  const { user, password, idApp, url } = values
  const { selectServiceConfig } = state

  const errors = {}
  if (!password) {
    errors.password = 'Required'
  }
  if (!user) {
    errors.user = 'Required'
  }
  if (!idApp) {
    errors.idApp = 'Required'
  }

  if (selectServiceConfig && selectServiceConfig.isUrlRequired && !url) {
    errors.url = 'Required'
  }

  if (selectServiceConfig && selectServiceConfig.validations) {
    selectServiceConfig.validations.forEach(validation => {
      const regex = new RegExp(validation.pattern)
      const value = values[validation.fieldId]
      if (value && !regex.test(value)) {
        errors[validation.fieldId] = validation.error
      }
    })
  }
  return errors
}

export default validate
