import React from 'react'
import * as Style from './style'
import { AppIcon, TextBadge, RowWithoutMargin, Spacer, Col } from '@toriihq/design-system'
import OverflowTooltip from '@components/overflowTooltip'
import RelativeTeamLink from '@components/relativeTeamLink'

const removeMarkdownFormatting = (markdownText: string): string => {
  markdownText = markdownText.replace(/^#+\s+(.*)$/gm, '$1')
  markdownText = markdownText.replace(/\*\*(.*?)\*\*/g, '$1')
  markdownText = markdownText.replace(/\*(.*?)\*/g, '$1')
  markdownText = markdownText.replace(/__(.*?)__/g, '$1')
  markdownText = markdownText.replace(/_(.*?)_/g, '$1')
  markdownText = markdownText.replace(/`([^`]+)`/g, '$1')
  markdownText = markdownText.replace(/^\s*[-*+]\s+(.*)$/gm, '$1')
  markdownText = markdownText.replace(/^\s*>\s+(.*)$/gm, '$1')

  return markdownText
}

const Tile = (plugin) => {
  const description = plugin.description.split('.')[0].length < plugin.description.split('\n')[0].length ? plugin.description.split('.')[0] : plugin.description.split('\n')[0]
  const noMarkdownText = removeMarkdownFormatting(description)
  return (
    <RelativeTeamLink to={`/plugin/${plugin.uuid}`}>
      <Style.Tile>
        <Style.Layout>
          <RowWithoutMargin nogutter style={{ height: 42, flexWrap: 'unset', marginBottom: 24 }}>
            <Spacer right={'space-150'}>
              <Col style={{ padding: 0 }} sm={3}>
                <AppIcon appName={plugin.name} appImageUrl={plugin.squareLogoUrl} size='large' />
              </Col>
            </Spacer>
            <Col sm={9} style={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
              <Style.Name nogutter>
                <OverflowTooltip label={plugin.name}>
                  {plugin.name}
                </OverflowTooltip>
              </Style.Name>
              <RowWithoutMargin nogutter>
                <Style.Vendor>Verified by Torii</Style.Vendor>
              </RowWithoutMargin>
            </Col>
          </RowWithoutMargin>
          <RowWithoutMargin nogutter style={{ height: '80px', marginBottom: '10px' }}>
            <Style.Description>{noMarkdownText.concat('.')}</Style.Description>
          </RowWithoutMargin>
          {plugin.isInstalled ? <Style.Badge nogutter>
            <TextBadge color='success' size='Small'>Installed</TextBadge>
          </Style.Badge> : null}
        </Style.Layout>
      </Style.Tile>
    </RelativeTeamLink>
  )
}

export default Tile
