import styled from 'styled-components'
import colors from '@shared/style/colors'

export const Label = styled.div`
  color: ${colors.darkText};
`

export const ContainerButton = styled.button`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s;
  
  [disabled] {
    opacity: 0.5;
  }

  &:hover:not([disabled]) ${Label} {
    color: ${({ theme }) => theme.palette.text.interactive};
  }
`

export const OptionDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Description = styled.div`
  color: ${colors.grey1};
`
