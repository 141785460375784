import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Checkbox, Tooltip } from '@toriihq/design-system'

import texts from '../../../../shared/style/texts'
import colors from '../../../../shared/style/colors'

const CSS = {
  main: css({
    marginBottom: '25px',
    position: 'relative'
  }),
  fieldContainer: css({}),
  label: css(texts.heading, {
    marginRight: '26px',
    marginBottom: '10px'
  }),
  input: css({
    width: '100%',
    borderRadius: '2px'
  }),
  errorMessage: css(texts.body, {
    color: colors.red
  })
}

const CheckboxField = ({ extraInfo, input: { onChange }, label, meta: { touched, error }, checked, disabled, withTooltip }) => {
  return <div {...CSS.main}>
    <div {...CSS.fieldContainer}>
      <div>
        <Tooltip label='This field is defined by Torii and cannot be edited.' hide={!withTooltip}>
          <Checkbox label={label} onChange={onChange} checked={checked} disabled={disabled} />
        </Tooltip>
        {extraInfo}
      </div>
    </div>
    {touched && error && <div {...CSS.errorMessage}>{error}</div>}
  </div>
}

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  extraInfo: PropTypes.element,
  disabled: PropTypes.bool,
  withTooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

CheckboxField.defaultProps = {
  disabled: false,
  withTooltip: false
}

export default CheckboxField
