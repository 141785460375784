import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import UsersList from './usersList'
import debounce from 'lodash/debounce'
import { EMPTY_ARRAY, ITEMS_PER_PAGE } from '@root/constants'
import config from '../../../config'

class UsersMapPopup extends React.Component {
  state = { sort: EMPTY_ARRAY }

  componentDidMount () {
    this.fetchData(true)
  }

  fetchData = debounce((reset = false) => {
    const { users } = this.props

    this.fetchUsers({ offset: reset ? 0 : users.length })
  }, 500, { leading: true, trailing: false })

  sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  exportToCsv = ({ query }) => {
    const { idOrg, country } = this.props
    const { sort } = this.state

    const sortParam = `sort=${encodeURIComponent(sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(','))}`
    const queryParam = `q=${encodeURIComponent(query)}`
    const params = `${country && country.countryCode ? `country=${encodeURIComponent(country.countryCode)}` : ''}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/trends/usersByCountry/csv?${sortParam}&${queryParam}&${params}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  fetchUsers ({ limit = ITEMS_PER_PAGE, offset = 0, props = this.props }) {
    const { getUsersByCountry, idOrg, country } = props
    const { sort } = this.state

    getUsersByCountry({ idOrg, limit, offset, country: country && country.countryCode, sort: this.sortStringFromArray(sort), q: this.query })
  }

  onSearch = debounce((q) => {
    this.query = q
    this.fetchData(true)
  }, 250)

  onSortedChange = (sort) => {
    this.setState({ sort }, () => this.fetchData(true))
  }

  render () {
    const { cancel, isOpen, users, loading, loadingMore, totalUsers, country } = this.props
    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={{ modal: { height: '80%', width: '80%', padding: '0px', display: 'flex', flexDirection: 'column' } }}>
        <ToriiPopup.Header header={`Users from ${(country && country.countryName) || 'all over the world'}`} />
        <ToriiPopup.Content contentArea>
          <UsersList
            exportFunction={this.exportToCsv}
            totalUsers={totalUsers}
            users={users}
            loading={loading}
            loadingMore={loadingMore}
            fetchData={this.fetchData}
            onSearch={this.onSearch}
            scrollObjectId='contentArea'
            onSortedChangeCB={this.onSortedChange}
          />
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }
}

UsersMapPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  loading: PropTypes.bool,
  users: PropTypes.array,
  loadingMore: PropTypes.bool,
  totalUsers: PropTypes.number,
  country: PropTypes.object
}

export default UsersMapPopup
