import React from 'react'
import PropTypes from 'prop-types'
import { AppIcon, Tooltip } from '@toriihq/design-system'
import RelativeTeamLink from '../relativeTeamLink'
import Placeholder from '../placeholder'
import Analytics from '../../helpers/analytics'
import { Wrapper, Label } from './styles'

const onClick = (name) => {
  Analytics.track('Click on app', {
    'Component': 'Similar apps',
    'App name': name
  })
}

const SimilarApp = ({ name, imageUrl, targetUrl, loading, disabled }) => {
  const appIcon = <AppIcon size='large' appName={name} appImageUrl={imageUrl} hoverable disabled={disabled} />

  return (
    <Wrapper>
      <Placeholder loading={loading} type='text' rows={2} style={{ height: '40px', width: '40px' }}>
        <Tooltip label={<Label dangerouslySetInnerHTML={{ __html: name }} />}>
          {disabled
            ? appIcon
            : <RelativeTeamLink to={targetUrl} onClick={() => onClick(name)}>
              {appIcon}
            </RelativeTeamLink>}
        </Tooltip>
      </Placeholder>
    </Wrapper>
  )
}

SimilarApp.propTypes = {
  appName: PropTypes.string,
  last: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}

export default SimilarApp
