import { createParameterSelector } from '@shared/utils'
import { createSelector } from 'reselect'
import { EMPTY_OBJECT, TORII_APP_ID } from '@root/constants'
import { get, identity, keyBy } from 'lodash'
import { getAppsActionsConfig, getTriggersByType, getTriggersOptionsByApp } from './workflows'
import { AppActionsConfig, AppTriggerOptions, TriggerTypeToTriggerOptionsValues } from './types'
import { deprecatedGetAppsByIds } from '../apps'
import { IdWorkflowNode, Workflow } from '@shared/workflows/types'
import { isDefaultAppCatalogPolicy } from '@lenses/workflows'
import { WORKFLOW_TRIGGER_TYPE } from '@shared/types'
import { mapOutputFieldToBranchingEntity } from '@lenses/workflows.t'
import { fieldsValuesToOptions } from '@lenses/utils'

const getWorkflows = createSelector(
  state => get(state, ['workflows', 'workflows'], []),
  workflows => workflows
)

export const getNonDeletedWorkflows = createSelector(
  [getWorkflows],
  (workflows: Workflow[]) => (workflows.filter(workflow => !workflow.isDeleted))
)

export const getWorkflowsById = createSelector(
  [getWorkflows],
  workflows => keyBy(workflows, 'id') || EMPTY_OBJECT
)

const idWorkflowParameterSelector = createParameterSelector(
  ({ idWorkflow }: { idWorkflow: number }) => idWorkflow
)

export const getWorkflow = createSelector(
  [getWorkflowsById, idWorkflowParameterSelector],
  (workflowsById, idWorkflow) => workflowsById[idWorkflow] || EMPTY_OBJECT
)

const getUnavailableWorkflowIds = createSelector(
  state => get(state, ['workflows', 'unavailableWorkflowIds'], []),
  idWorkflows => idWorkflows
)

export const isWorkflowUnavailableSelector = createSelector(
  [getUnavailableWorkflowIds, idWorkflowParameterSelector],
  (unavailableWorkflowIds, idWorkflow) => unavailableWorkflowIds.includes(idWorkflow)
)

export const getAppCatalogPoliciesResourcesSelector = createSelector(
  [state => get(state, ['workflows', 'appCatalogPolicies', 'resources'], {}), deprecatedGetAppsByIds],
  (resources, appsById) => {
    const { users: usersMap = EMPTY_OBJECT, apps: appsMap = EMPTY_OBJECT } = resources
    usersMap['toriiUser'] = { firstName: 'Torii', lastName: 'Team', photoUrl: get(appsById, [TORII_APP_ID, 'imageUrl']) }
    return { usersMap, appsMap }
  }
)

export const getAppCatalogNewAppPoliciesResourcesSelector = createSelector(
  [state => get(state, ['workflows', 'appCatalogRequestNewAppPolicies', 'resources'], {}), deprecatedGetAppsByIds],
  (resources, appsById) => {
    const { users: usersMap = EMPTY_OBJECT, apps: appsMap = EMPTY_OBJECT } = resources
    usersMap['toriiUser'] = { firstName: 'Torii', lastName: 'Team', photoUrl: get(appsById, [TORII_APP_ID, 'imageUrl']) }
    return { usersMap, appsMap }
  }
)

export const isLoadingAppCatalogPoliciesSelector = createSelector(
  state => get(state, ['workflows', 'appCatalogPolicies', 'loading']),
  identity
)

export const isLoadingAppCatalogRequestNewAppPoliciesSelector = createSelector(
  state => get(state, ['workflows', 'appCatalogRequestNewAppPolicies', 'loading']),
  identity
)

export const getAppCatalogRequestNewAppPoliciesSelector = createSelector(
  state => get(state, ['workflows', 'appCatalogRequestNewAppPolicies', 'policies'], []),
  policies => policies
)

export const getAppCatalogPoliciesSelector = createSelector(
  state => get(state, ['workflows', 'appCatalogPolicies', 'policies'], []),
  policies => policies
)

export const getAppCatalogPoliciesByIdSelector = createSelector(
  [getAppCatalogPoliciesSelector],
  policies => keyBy(policies, 'id') || EMPTY_OBJECT
)

export const getAppCatalogRequestNewAppPoliciesByIdSelector = createSelector(
  [getAppCatalogRequestNewAppPoliciesSelector],
  policies => keyBy(policies, 'id') || EMPTY_OBJECT
)

export const getDefaultAppCatalogPolicySelector = createSelector(
  [getAppCatalogPoliciesSelector],
  policies => policies.find(policy => isDefaultAppCatalogPolicy({ workflow: policy }))
)

export const getCustomAppCatalogPolicySelector = createSelector(
  (state, idApp) => {
    const policies = get(state, ['workflows', 'appCatalogPolicies', 'policies'], [])
    return policies.find(policy => get(policy, ['triggerIdApp']) === idApp)
  },
  identity
)

export const getAppCatalogPolicySelector = createSelector(
  [getAppCatalogPoliciesByIdSelector, idWorkflowParameterSelector],
  (policiesById, idWorkflow) => policiesById[idWorkflow] || EMPTY_OBJECT
)

export const getAppCatalogRequestNewAppPolicySelector = createSelector(
  [getAppCatalogRequestNewAppPoliciesByIdSelector, idWorkflowParameterSelector],
  (policiesById, idWorkflow) => policiesById[idWorkflow] || EMPTY_OBJECT
)

const getWorkflowActionParametersSelector = createParameterSelector(
  ({
    idWorkflow,
    idAction,
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView
  }: {
    idWorkflow: number,
    idAction: IdWorkflowNode,
    isAppCatalogPoliciesView: boolean,
    isRequestNewAppPoliciesView: boolean
  }) => ({
    idWorkflow,
    idAction,
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView
  })
)

export const getWorkflowActionNodeSelector = createSelector(
  [
    getWorkflowsById,
    getAppCatalogPoliciesByIdSelector,
    getAppCatalogRequestNewAppPoliciesByIdSelector,
    getWorkflowActionParametersSelector
  ],
  (
    regularWorkflowsById,
    appCatalogPoliciesById,
    appCatalogRequestNewAppPoliciesById,
    { idWorkflow, idAction, isAppCatalogPoliciesView, isRequestNewAppPoliciesView }
  ) => {
    if (!idWorkflow || !idAction) {
      return null
    }

    const getWorkflowsById = () => {
      if (isAppCatalogPoliciesView) {
        return appCatalogPoliciesById
      }
      if (isRequestNewAppPoliciesView) {
        return appCatalogRequestNewAppPoliciesById
      }
      return regularWorkflowsById
    }

    const workflowsById = getWorkflowsById()
    const workflow = workflowsById[idWorkflow]

    return workflow?.actions?.nodes[idAction] ?? null
  }
)

export const isUpdatingWorkflows = createSelector(
  state => get(state, ['workflows', 'updating']),
  identity
)

const idAppParamSelector = createParameterSelector(({ idApp }: { idApp?: number }) => idApp)

export const getAppActionsConfig = createSelector(
  [getAppsActionsConfig, idAppParamSelector],
  (appsActionsConfig?: AppActionsConfig[], idApp?: number) => {
    return idApp && appsActionsConfig?.find(config => config.idApp === idApp)
  }
)

export const getTriggersOptionsValuesByTriggerType = createSelector(
  [getTriggersOptionsByApp],
  (apps: AppTriggerOptions[]): TriggerTypeToTriggerOptionsValues | {} => {
    const options = apps.map(app => app.options).flat()
    return options.reduce((triggerTypeToValueObj, triggerOption) => {
      triggerTypeToValueObj[triggerOption.type] = triggerOption.value
      return triggerTypeToValueObj
    }, {})
  }
)

export const getWorkflowsEditHistoryById = createSelector(
  state => get(state, ['workflows', 'workflowsEditHistory', 'workflowEditHistoriesById'], {}),
  identity
)

export const isLoadingWorkflowsEditHistoryInfo = createSelector(
  state => get(state, ['workflows', 'workflowsEditHistory', 'workflowEditHistoriesById', 'loading'], false),
  identity
)

export const isLoadingWorkflowsEditHistoryLogs = createSelector(
  state => get(state, ['workflows', 'workflowsEditHistory', 'listByIdWorkflow', 'loading'], false),
  identity
)

export const isLoadingMoreWorkflowsEditHistoryLogs = createSelector(
  state => get(state, ['workflows', 'workflowsEditHistory', 'listByIdWorkflow', 'loadingMore'], false),
  identity
)

export const getWorkflowsEditHistoryLogs = createSelector(
  state => get(state, ['workflows', 'workflowsEditHistory', 'listByIdWorkflow'], {}),
  identity
)

const triggerTypeParamSelector = createParameterSelector(({ triggerType }: { triggerType?: WORKFLOW_TRIGGER_TYPE | null }) => triggerType)

export const getTriggerBranchingEntityOptions = createSelector(
  [getTriggersByType, triggerTypeParamSelector],
  (triggersByType: Record<string, any>, triggerType?: WORKFLOW_TRIGGER_TYPE | null) => {
    return triggerType
      ? Object.values(triggersByType[triggerType].outputSchema || {})
        .filter((field: any) => field.enableBranching)
        .map(mapOutputFieldToBranchingEntity) : []
  }
)

export const hasUnsavedChanges = createSelector(
  state => get(state, ['workflows', 'hasUnsavedChanges'], false),
  identity
)

export const getWorkflowTags = createSelector(
  state => get(state, ['workflows', 'workflowTags', 'tags'], []),
  identity
)

export const isLoadingWorkflowTags = createSelector(
  state => get(state, ['workflows', 'workflowTags', 'loading'], false),
  identity
)

export const getWorkflowTagsById = createSelector(
  [getWorkflowTags],
  tags => keyBy(tags, 'id') || EMPTY_OBJECT
)

const getWorkflowsFullAuditLogs = createSelector(
  state => get(state, ['workflows', 'fullAuditLogs'], {}),
  identity
)

type AuditLogsData = {
  resources: Record<string, any>,
  audits: any[],
  total: number
}
export const getWorkflowsFullAuditLogsInfo = createSelector(
  [getWorkflowsFullAuditLogs],
  (auditLogs) => {
    const { resources = {}, audits = [], total } = auditLogs as unknown as AuditLogsData
    const { apps, users } = resources
    return { apps, users, audits, total }
  }
)

export const isLoadingWorkflowsFullAuditLogs = createSelector(
  [getWorkflowsFullAuditLogs],
  (auditLogs) => get(auditLogs, ['loading'], false)
)

export const isLoadingMoreWorkflowsFullAuditLogs = createSelector(
  [getWorkflowsFullAuditLogs],
  (auditLogs) => get(auditLogs, ['loadingMore'], false)
)

export const getWorkflowsFullAuditLogsFieldValues = createSelector(
  [getWorkflowsFullAuditLogs],
  auditLogs => fieldsValuesToOptions(get(auditLogs, ['fieldValues'], {}))
)
