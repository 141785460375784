import config from '../config'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'
import get from 'lodash/get'
import { EMPTY_OBJECT, INTEGRATION_CATEGORY, SYNC_TYPE } from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import { isSupportsUsage } from '@lenses/utils'

const removeUnfilledParametersFromUrl = parameters => {
  return parameters.split('&').filter(string => {
    const [key, value] = string.split('=')
    return `{${key}}` !== value
  }).join('&')
}

export const getTransformedUrl = ({ baseUrl, inputsData = {} }) => {
  const idTestConnectionParam = inputsData.idTestConnection ? `&idTestConnection=${encodeURIComponent(inputsData.idTestConnection)}` : ''
  const idAppsParam = inputsData.idApps ? `&idApps=${encodeURIComponent(inputsData.idApps)}` : ''

  let url = baseUrl
    .replace('{apiBaseUrl}', `${config.apiBaseUrl}/api/auth`)
    .replace('{nextUrl}', encodeURIComponent(window.location.origin + window.location.pathname))
    .concat(idTestConnectionParam)
    .concat(idAppsParam)

  Object.keys(inputsData).forEach(key => {
    if (inputsData[key]) {
      url = url.replace(`{${key}}`, encodeURIComponent(inputsData[key]))
    }
  })

  const [path, params] = url.split('?')
  return path.concat(`?${removeUnfilledParametersFromUrl(params)}`)
}

export const isServiceConnected = (service = {}) => {
  return Boolean(service.isConnected || service.connectedAt)
}

export const getServiceInfo = ({ services, idApp, appName, supportedFeatures = {} }) => {
  const appServices = groupBy(services, 'idApp')[idApp]
  let serviceInfo = appServices

  if (isUndefined(appServices)) {
    return EMPTY_OBJECT
  }

  if (Array.isArray(appServices)) {
    const connectedServices = appServices.filter(service => isServiceConnected(service))
    const servicesWithNativeIntegrations = connectedServices.filter(service => ![SYNC_TYPE.CUSTOM, SYNC_TYPE.MANUAL].includes(get(service, ['syncType'])))
    serviceInfo = servicesWithNativeIntegrations[0] || connectedServices[0] || appServices[0]
  }

  const basicInfo = {
    idApp,
    appName: appName || get(serviceInfo, ['appName']) || get(serviceInfo, ['name']),
    source: get(serviceInfo, ['source']),
    syncType: get(serviceInfo, ['syncType']),
    integrationType: get(serviceInfo, ['integrationType']),
    integrationCategory: get(serviceInfo, ['integrationCategory']),
    supportsUsage: isSupportsUsage({ capabilities: get(serviceInfo, 'capabilities', {}), supportedFeatures }),
    helpCenterLink: get(serviceInfo, ['connectFormConfiguration', 'link', 'url']),
    isConnected: isServiceConnected(serviceInfo),
    neverSynced: !(get(serviceInfo, ['lastSyncTime'])),
    supportsMultipleAccounts: get(serviceInfo, ['supportsMultipleAccounts'])
  }

  return {
    ...basicInfo,
    isNativeUsersIntegration: basicInfo.integrationType !== INTEGRATION_TYPE.CUSTOM &&
                              basicInfo.integrationCategory !== INTEGRATION_CATEGORY.EXPENSE_SOFTWARE,
    isNeverSyncedCustomIntegration: basicInfo.integrationType === INTEGRATION_TYPE.CUSTOM && basicInfo.neverSynced
  }
}
