import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import WorkflowsTemplatesList from '../workflowsTemplatesList'
import WorkflowsTemplatesMenu from '../workflowsTemplatesMenu'

const buttonContainerHeight = 60
const buttonWidth = 190
const spaceBetweenMenuAndLists = 40
const menuTopMargin = 15

const CSS = {
  main: css({
    backgroundColor: colors.white
  }),
  menuContainer: css({
    display: 'inline-block',
    position: 'sticky',
    top: '60px',
    paddingTop: `${menuTopMargin}px`,
    marginRight: `${spaceBetweenMenuAndLists}px`
  }),
  templatesListsContainer: css({
    display: 'inline-block',
    width: `calc(100% - ${spaceBetweenMenuAndLists + buttonWidth}px)`,
    verticalAlign: 'top',
    marginTop: `${buttonContainerHeight + menuTopMargin}px`,
    marginBottom: '50%'
  }),
  workflowsTemplatesList: css({
    marginBottom: '20px'
  })
}

const loadingTemplates = {
  'Employee Onboarding': [{ app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }],
  'Employee Offboarding': [{ app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }],
  'License Utilization': [{ app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }],
  'Shadow-IT Discovery': [{ app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }, { app: [], description: '' }]
}

const WorkflowsTemplates = ({ templatesLists, loading, templateOnClick }) => {
  templatesLists = loading ? loadingTemplates : templatesLists
  return (
    <div {...CSS.main}>
      <div {...CSS.menuContainer}>
        <WorkflowsTemplatesMenu loading={loading} templatesLists={templatesLists} />
      </div>
      <div {...CSS.templatesListsContainer}>
        {Object.entries(templatesLists).map(([category, templateList], index) => <div {...CSS.workflowsTemplatesList} key={index}><WorkflowsTemplatesList loading={loading} header={category} templates={templateList} index={index} templateOnClick={templateOnClick} /></div>)}
      </div>
    </div>
  )
}

WorkflowsTemplates.propTypes = {
  templatesLists: PropTypes.object,
  onClick: PropTypes.func,
  templateOnClick: PropTypes.func,
  loading: PropTypes.bool
}

export default WorkflowsTemplates
