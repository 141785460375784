import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MultipleCheckboxSelectAsync from '@components/multipleCheckboxSelectAsync'

class SelectTags extends Component {
  onChange = async (selectedTags) => {
    const { input: { onChange } } = this.props
    onChange && onChange(selectedTags)
  }

  fetchData = async (searchValue) => {
    const { idOrg, searchAppsTags } = this.props
    const response = await searchAppsTags({ idOrg, q: searchValue || undefined, limit: 100 })
    return { options: response.tags.filter(tag => tag.name).map(tag => ({ value: tag.name, label: tag.name })) }
  }

  render () {
    const { input, placeholder } = this.props
    return (
      <MultipleCheckboxSelectAsync
        loadingPlaceholder={'Loading...'}
        loadOptions={this.fetchData}
        defaultOptions
        valueRenderer={option => option.label}
        isLoading={false}
        selectedValues={input.value || []}
        placeholder={placeholder}
        showValues
        searchable
        cache={false}
        noResultsText='No tag found'
        closeMenuOnSelect={false}
        onChange={this.onChange}
      />
    )
  }
}

SelectTags.propTypes = {
  placeholder: PropTypes.string
}

SelectTags.defaultProps = {
  placeholder: 'Select tags...'
}

export default SelectTags
