import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import colors from '@shared/style/colors'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { LegendContainer, MainContainer, Square } from '@components/waterfallChartBox/chart/styles'

export const barCellsColors = [
  '#00257B',
  '#0054FF',
  '#B0CAFF',
  '#684AFF',
  '#B6A8FF',
  '#E2DDFF',
  '#00768E',
  '#00C3EB',
  '#5EE4FF',
  '#636C7F',
  '#ADB3BF',
  '#D0D3DA',
  '#3BA151'
]

const CustomXAxis = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='middle' fill={colors.grey2} style={{ fontFamily: 'Open Sans' }} fontSize={10}>{payload.value}</text>
    </g>
  )
}

const CustomYAxis = ({ x, y, payload, formatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-16} y={0} dy={0} textAnchor='middle' fill={colors.grey2} style={{ fontFamily: 'Open Sans' }} fontSize={10}>{formatter(payload.value)}</text>
    </g>
  )
}

const renderLegend = (legendMapping) => {
  return (
    <LegendContainer>
      {legendMapping.filter(entry => entry.color !== 'transparent').map((entry, index) => (
        <div key={`item-${index}`}>
          <Square entry={entry} />
          <span>{entry.value}</span>
        </div>
      ))}
    </LegendContainer>
  )
}

class WaterfallBarChart extends React.Component {
  state = { data: [] }

  componentDidMount () {
    const { data } = this.props
    this.setState({ data })
  }

  componentDidUpdate (prevProps) {
    const { data } = this.props
    if (data && !isEqual(data, prevProps.data)) {
      this.setState({ data: data })
    }
  }

  render () {
    const { barsConfig, barSize, showLegend, legendMapping = [], tooltipContent, chartHeight = 180, overrideStyle, showCustomDynamicBarsChart, interval, formatter } = this.props
    const { data } = this.state

    return (
      <MainContainer overrideStyle={overrideStyle}>
        <ResponsiveContainer width='100%' height={chartHeight}>
          <BarChart width={500} data={data} barSize={barSize} reverseStackOrder={showCustomDynamicBarsChart} >
            <CartesianGrid stroke={colors.lightBlue2} vertical={false} />
            <XAxis axisLine={false} tickLine={false} interval={interval} dataKey='name' tick={<CustomXAxis />} />
            <YAxis axisLine={false} tickLine={false} tick={<CustomYAxis formatter={formatter} />} />
            <Tooltip wrapperStyle={{ zIndex: 10 }} position={showCustomDynamicBarsChart ? { y: -150 } : undefined} content={tooltipContent} cursor={{ fill: 'transparent' }} />
            {(showLegend && !showCustomDynamicBarsChart) && <Legend content={() => renderLegend(legendMapping)} wrapperStyle={{ paddingTop: '15px', right: 0 }} />}
            {barsConfig.map((barConfig) => {
              const { dataKey, fill, stackId = 'a', shape, labelList, onClick } = barConfig
              return (
                <Bar dataKey={dataKey} stackId={stackId} fill={fill} shape={shape} onClick={onClick} >
                  {labelList && <LabelList dataKey={labelList.dataKey} position={labelList.position} content={labelList.content} />}
                </Bar>
              )
            })}
          </BarChart>
        </ResponsiveContainer>
      </MainContainer>
    )
  }
}

export const waterfallBarChartType = {
  barSize: PropTypes.number,
  showLegend: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })),
  fill: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    name: PropTypes.string
  })),
  imagesAxis: PropTypes.bool,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCustomDynamicBarsChart: PropTypes.bool,
  customDynamicBarsChartMaxCells: PropTypes.number,
  customDynamicBarsChartDefaultFill: PropTypes.string,
  formatter: PropTypes.func
}

WaterfallBarChart.propTypes = {
  ...waterfallBarChartType
}

WaterfallBarChart.defaultProps = {
  showLegend: true,
  showCustomDynamicBarsChart: false,
  customDynamicBarsChartMaxCells: 4,
  customDynamicBarsChartDefaultFill: colors.navy,
  interval: 0,
  formatter: (value) => value
}

export default WaterfallBarChart
