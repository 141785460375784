import React from 'react'
import Table from '../../table'
import colors from '../../../shared/style/colors'
import { Tooltip } from '@toriihq/design-system'
import { css } from 'glamor'
import texts from '../../../shared/style/texts'
import sumBy from 'lodash/sumBy'
import Input from '../../form/input'
import PropTypes from 'prop-types'
import Currency, { CurrencyFormat } from '@root/components/currency'

const CSS = {
  regular: css(texts.body, {
    color: colors.black
  }),
  main: css({
    width: '100%'
  }),
  tableContainer: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  }),
  tableHeader: css({
    display: 'flex'
  }),
  licenseName: css({
    whiteSpace: 'normal'
  }),
  tableMainHeader: {
    display: 'none'
  },
  tableStyle: {
    minHeight: '107px',
    borderTop: 'none'
  },
  noUserFieldValues: css(texts.heading, {
    textAlign: 'center'
  }),
  footer: css(texts.body, {
    color: colors.darkText,
    marginTop: '15px'
  }),
  totalPercentages: css({
    marginLeft: '130px'
  })
}

const setNoDataProps = () => {
  return {
    className: 'rt-noData',
    style: {
      pointerEvents: 'all',
      position: 'relative',
      top: '30px'
    }
  }
}

class UserFieldPercentagesDivisionTable extends React.Component {
  columns = this.getColumns()

  renderNoUserFieldDisplayNames () {
    return (
      <div {...CSS.noUserFieldValues}>
        No User field names
      </div>
    )
  }

  onAllocatedPercentageChange = (updatedUserFieldDisplayName, e) => {
    const { onChange, userFieldNamesPercentagesDetails } = this.props

    const allocatedPercentage = parseFloat(e.target && e.target.value ? e.target.value : 0)
    const updateduserFieldNamesPercentagesDetails = userFieldNamesPercentagesDetails.map(userFieldNamePercentagesDetails => {
      if (updatedUserFieldDisplayName === userFieldNamePercentagesDetails.userFieldDisplayName) {
        return { ...userFieldNamePercentagesDetails, allocatedPercentage }
      }
      return userFieldNamePercentagesDetails
    })

    onChange(updateduserFieldNamesPercentagesDetails)
  }

  getColumns () {
    return [
      {
        Header: () => {
          const { userField } = this.props
          return userField.label
        },
        id: 'userFieldDisplayName',
        resizable: false,
        Cell: ({ row: { userFieldDisplayName } }) => (
          <div {...CSS.licenseName}>{userFieldDisplayName}</div>
        ),
        width: '100%'
      },
      {
        Header: 'Allocated percentage',
        accessor: 'allocatedPercentage',
        resizable: false,
        Cell: ({ value: allocatedPercentage, row: { userFieldDisplayName } }) => {
          return <Input placeholder='%' value={allocatedPercentage.toString()} suffix='%' onChange={(e) => this.onAllocatedPercentageChange(userFieldDisplayName, e)} type='number' step='0.01' />
        },
        width: 180,
        ...Table.numericFieldProps
      },
      {
        Header: <div {...CSS.tableHeader}>
          <Tooltip label='In last 12 months'>
            <div>Allocated cost</div>
          </Tooltip>
        </div>,
        id: 'allocatedCost',
        resizable: false,
        Cell: ({ row: { allocatedPercentage } }) => {
          const { totalCost } = this.props
          return <div {...CSS.regular}><Currency value={(allocatedPercentage) * totalCost} format={CurrencyFormat.FLOAT} /> </div>
        },
        width: 175,
        ...Table.numericFieldProps
      },
      {
        accessor: 'userFieldDisplayName',
        show: false
      },
      {
        accessor: 'allocatedPercentage',
        show: false
      }
    ]
  }

  render () {
    const { isLoading, userFieldNamesPercentagesDetails } = this.props

    const totalAllocatedPercentage = parseFloat(sumBy(userFieldNamesPercentagesDetails, 'allocatedPercentage').toFixed(2))

    return (
      <div {...CSS.main}>
        <div {...CSS.tableContainer}>
          <Table
            tableHeaderStyle={CSS.tableMainHeader}
            emptyStateMessage={this.renderNoUserFieldDisplayNames()}
            data={userFieldNamesPercentagesDetails}
            columns={this.columns}
            style={CSS.tableStyle}
            loading={isLoading}
            getNoDataProps={setNoDataProps}
          />
        </div>
        {(userFieldNamesPercentagesDetails.length > 0) && <div {...CSS.footer}>
          <span>Total allocated cost:</span>
          <span {...CSS.totalPercentages}>{totalAllocatedPercentage}%</span>
        </div> }
      </div>
    )
  }
}

UserFieldPercentagesDivisionTable.propTypes = {
  userFieldNamesPercentagesDetails: PropTypes.arrayOf(PropTypes.shape({
    userFieldDisplayName: PropTypes.string,
    allocatedPercentage: PropTypes.number
  })),
  isLoading: PropTypes.bool,
  totalCost: PropTypes.number
}
export default UserFieldPercentagesDivisionTable
