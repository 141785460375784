import styled from 'styled-components'

export const BottomSlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing('space-100')};
`

export const AppIconsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing('space-150')};
  align-items: center;
`

export const SelectRemindersWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
