import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import { clearButton } from '../../../shared/style/mixins'

const menuButtonWidth = 190
const menuButtonHeight = 50

const CSS = {
  main: css({
    display: 'inline-block',
    backgroundColor: colors.white,
    width: `${menuButtonWidth}px`
  }),
  menuBorderLine: css({
    width: `${menuButtonWidth}px`,
    height: `1px`,
    backgroundColor: colors.lightBlue2
  }),
  menuButton: css(clearButton, {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    textAlign: 'left',
    paddingLeft: '16px',
    width: `${menuButtonWidth}px`,
    height: `${menuButtonHeight}px`,
    ':hover': {
      backgroundColor: colors.lightBlue3
    }
  }),
  header: css({
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.small,
    color: colors.grey2,
    marginBottom: '17px'
  })
}

const scrollTo = (to, duration) => {
  const element = document.getElementsByClassName('mainWrapper')[0]
  const start = element.scrollTop
  const change = to - start
  const startDate = +new Date()
  const easeInOutQuad = (currentTime, startValue, change, duration) => {
    currentTime /= duration / 2
    if (currentTime < 1) {
      return change / 2 * currentTime * currentTime + startValue
    }
    currentTime--
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + startValue
  }
  const animateScroll = () => {
    const currentDate = +new Date()
    const currentTime = currentDate - startDate
    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
    currentTime < duration ? window.requestAnimationFrame(animateScroll) : element.scrollTop = to
  }
  animateScroll()
}

const WorkflowsTemplatesMenu = ({ templatesLists }) => (
  <div {...CSS.main}>
    <div {...CSS.header}>Templates</div>
    <div {...CSS.menu}>
      <div {...CSS.menuBorderLine} />
      {Object.keys(templatesLists)
        .map((category, index) => <div key={index}>
          <button {...CSS.menuButton} onClick={() => scrollTo(window.document.getElementById(category).offsetTop - 200, 200)} index={index}>{category}</button>
          <div {...CSS.menuBorderLine} />
        </div>)}
    </div>
  </div>
)

WorkflowsTemplatesMenu.propTypes = {
  templatesLists: PropTypes.object
}

export default WorkflowsTemplatesMenu
