import colors from '@shared/style/colors'

type TdStyle = {
  style: Partial<CSSStyleDeclaration>
}

export const getTdStyle = (): TdStyle => {
  return {
    style: {
      border: '0',
      padding: '3px 10px',
      color: colors.black
    }
  }
}
