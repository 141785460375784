import React from 'react'
import { Wrapper } from './styles'
import { AppIcon, Spacer, Tooltip } from '@toriihq/design-system'
import RelativeTeamLink from '@components/relativeTeamLink'
import Analytics from '@helpers/analytics'

const onAppClick = (name, componentName) => {
  Analytics.track('Click on app', {
    'Component': componentName,
    'App name': name
  })
}

export const renderAppTextValue = ({ original = {}, apps }) => {
  if (!apps || apps.length === 0) return ''
  return (original.idApps || []).map(app => (apps[app] || {}).name).sort().join(', ')
}

export const renderAppCell = ({ original = {}, apps = [], numberOfOwnedAppsToDisplay = 3 }) => {
  const currentApps = (original.idApps || [])
  return (
    <Wrapper data-testid='appCell-wrapper'>
      {
        currentApps.slice(0, numberOfOwnedAppsToDisplay).map(ownedApp => {
          const appInfo = apps[ownedApp]
          return appInfo && (
            <Tooltip label={appInfo.name} key={`Tooltip-${appInfo.name}`}>
              <RelativeTeamLink key={appInfo.id} to={`/app/${appInfo.id}`} onClick={() => onAppClick(appInfo.name, 'External Users table')}>
                <Spacer right={'space-050'} top={appInfo.imageUrl ? 'space-050' : 'space-0'}>
                  <AppIcon appImageUrl={appInfo.imageUrl} appName={appInfo.name} />
                </Spacer>
              </RelativeTeamLink>
            </Tooltip>
          )
        })
      }
      {
        currentApps.length > numberOfOwnedAppsToDisplay && <span>+ {currentApps.length - numberOfOwnedAppsToDisplay}</span>
      }
      {
        currentApps.length === 0 && <span>-</span>
      }
    </Wrapper>
  )
}
