import moment from 'moment'
import reduce from 'lodash/reduce'
import range from 'lodash/range'

const initialQuarterMonths = [1, 4, 7, 10]

export const getExpensesByQuarters = (monthlyExpenses) => {
  let quarterIndex = 1

  const isFirstQuarterPartial = (month) => quarterIndex === 1 && !initialQuarterMonths.includes(month)

  const monthToTotal = monthlyExpenses.slice().reverse().reduce((result, monthExpenses) => {
    if (isFirstQuarterPartial(monthExpenses.month)) {
      return result
    }

    const quarter = moment(monthExpenses.date).quarter()
    const quarterKey = `${quarter}_${monthExpenses.year}`

    if (result[quarterKey]) {
      result[quarterKey] = {
        ...result[quarterKey],
        total: result[quarterKey].total + monthExpenses.total
      }
    } else {
      result[quarterKey] = {
        total: monthExpenses.total,
        index: `q${quarterIndex}`
      }
      quarterIndex++
    }
    return result
  }, {})

  return reduce(monthToTotal, (result, quarter) => {
    result[quarter.index] = quarter.total
    return result
  }, {})
}

export const getQuartersNames = (showOnlyThisYear) => {
  const MONTHS_AGO = showOnlyThisYear ? moment().month() : 12
  const quartersInfo = range(0, MONTHS_AGO).reduce((result, monthsAgo) => {
    if (result.names.length === 4) {
      return result
    }
    const date = moment().subtract(monthsAgo, 'month')
    const quarter = date.quarter()
    const quarterKey = `${quarter}_${date.year()}`
    const isNewQuarter = !result.byQuarterAndYear[quarterKey]

    if (isNewQuarter) {
      result.byQuarterAndYear[quarterKey] = true
      result.names.push(`Q${quarter} ${date.year()}`)
    }

    return result
  }, { byQuarterAndYear: {}, names: [] })

  return quartersInfo.names.reverse()
}
