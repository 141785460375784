import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCurrencySymbols } from '@selectors/ui'

const mapStateToProps = state => {
  const currencies = Object.keys(getCurrencySymbols(state) || {})
  return {
    currencies
  }
}

export default withRouter(connect(mapStateToProps)(View))
