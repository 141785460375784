import React from 'react'
import EmailForm from '../emailForm'
import Headers from '../signup/headers'
import { safeRedirect } from '../../shared/utils'

class LoginSAML extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (email) {
    const { getSAMLUrl, nextUrl } = this.props
    try {
      const result = await getSAMLUrl(email, nextUrl)
      const { loginUrl } = result || {}
      safeRedirect(loginUrl)
    } catch (e) {
      return e
    }
  }

  render () {
    const { loginInfo } = this.props

    return (
      <>
        <Headers
          headerText='SAML Sign in'
          center
        />
        <EmailForm
          iconName='User'
          buttonLabel='NEXT'
          loginInfo={loginInfo}
          onSubmit={this.handleSubmit}
          backToLoginText='No SAML?'
          onSuccessMsg='Redirecting...'
          onAuthenticatedMsg='Success. Redirecting...'
        />
      </>
    )
  }
}

export default LoginSAML
