import React from 'react'
import PropTypes from 'prop-types'
import AdvancedFiltersRow from '@components/advancedFilters/advancedFiltersRow'
import { SCOPES } from '@root/constants'
import * as Style from './styles'
import FiltersActions from '@components/filters/filtersActions'
import UserVerticalFilter from '@components/filters/userVerticalFilter'
import VerticalFilterWrapper from '@components/filters/verticalFilterWrapper'
import { removeItemAtIndex, replaceItemAtIndex } from '@lenses/utils'
import AndSeparator from '@components/_shared/andSeparator'

class UserMeetsCriteriaVerticalFilters extends React.Component {
  clearFilters = () => {
    const { onChange } = this.props
    onChange([])
  }

  onChange = (criterion, index) => {
    const { onChange } = this.props
    const criteria = replaceItemAtIndex(this.props.criteria, criterion, index)
    onChange && onChange(criteria)
  }

  onAdd = () => {
    const { onChange } = this.props
    const criterion = { id: Date.now() }
    const criteria = this.props.criteria.concat(criterion)
    onChange && onChange(criteria)
  }

  onRemove = (index) => {
    const { onChange } = this.props
    const criteria = removeItemAtIndex(this.props.criteria, index)
    onChange && onChange(criteria)
  }

  render () {
    const { criteria, optionsKey, optionsValuesPerKey, getTriggerPreview, disabled } = this.props
    const criteriaAmount = criteria.length

    return (
      <>
        {criteria.map((criterion, index) => {
          const shouldRenderAnd = (index + 1) < criteriaAmount
          const onCriterionRemove = () => this.onRemove(index)
          return (
            <Style.FilterWrapper key={index}>
              <VerticalFilterWrapper
                disabled={disabled}
                onRemove={onCriterionRemove}
              >
                <UserVerticalFilter
                  optionsKey={optionsKey}
                  optionsValuesPerKey={optionsValuesPerKey}
                  criterion={criterion}
                  onCriterionChange={(criterion) => this.onChange(criterion, index)}
                  disabled={disabled}
                />
              </VerticalFilterWrapper>
              <AndSeparator shouldRenderAnd={shouldRenderAnd} />
            </Style.FilterWrapper>
          )
        })}
        <FiltersActions
          onAddFilterClick={this.onAdd}
          onPreviewClick={getTriggerPreview}
          onClearFiltersClick={this.clearFilters}
          clearFiltersDisabled={criteria.length === 0}
          editAllowedScopes={[SCOPES.AUTOMATION_WRITE]}
        />
      </>
    )
  }
}

UserMeetsCriteriaVerticalFilters.propTypes = {
  optionsKey: AdvancedFiltersRow.propTypes.optionsKey,
  optionsValuesPerKey: PropTypes.object,
  criteria: PropTypes.arrayOf(AdvancedFiltersRow.propTypes.filter),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getTriggerPreview: PropTypes.func
}

UserMeetsCriteriaVerticalFilters.defaultProps = {
  optionsKey: [],
  optionsValuesPerKey: {},
  criteria: []
}

export default UserMeetsCriteriaVerticalFilters
