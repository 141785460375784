import * as Style from './styles'

type PropsType = {
  shouldRenderAnd: boolean
}

const AndSeparator = (props: PropsType = { shouldRenderAnd: false }) => {
  const { shouldRenderAnd } = props

  return shouldRenderAnd ? <Style.AndSeparator>And</Style.AndSeparator> : null
}

export default AndSeparator
