import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'

export const getUploads = createSelector(
  state => get(state, ['uploads', 'uploads'], []),
  identity
)

export const getUploadsResources = createSelector(
  state => get(state, ['uploads', 'resources'], {}),
  identity
)

export const getParsedUploads = createSelector(
  [getUploads],
  uploads => uploads.filter(upload => upload.transactions > 0)
)

export const isLoadingUploads = createSelector(state => get(state, ['uploads', 'loading'], false),
  identity
)
