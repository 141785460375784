import * as XLSX from 'xlsx'

export const exportExcel = async (filename, data, columnNames) => {
  const worksheet = XLSX.utils.json_to_sheet(data, { header: columnNames })
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, filename)
}

export default exportExcel
