import { BranchNodeModel } from '@shared/workflows/types'
import { BranchesData } from './getBranchActionBranchesData.types'

export const getBranchActionBranchesData = (
  branchNode: BranchNodeModel
): BranchesData => {
  const [branchAction] = branchNode.action.fields
  const { default: defaultBranch, branches } = branchAction.value
  return { defaultBranch, branches }
}
