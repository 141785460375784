import { CAPABILITY_TYPES, TEST_PHASES, TEST_RESULTS } from '@components/testConnection/constants'
import { INTEGRATION_CATEGORY } from '@root/constants'
import moment from 'moment'
import get from 'lodash/get'
import isString from 'lodash/isString'

export const hasTestConnectionInUrl = () => {
  const currentUrl = window.location.href
  return currentUrl.includes('idTestConnection')
}

export const validTestConnectionInProgress = (idOrg, idApp, testConnectionInfo) => {
  if (testConnectionInfo.idOrg !== idOrg || testConnectionInfo.idApp !== idApp) {
    return false
  }
  if (testConnectionInfo.phase !== TEST_PHASES.IN_PROGRESS) {
    return false
  }
  if (moment.utc().isAfter(moment.utc(testConnectionInfo.creationTime).add(3, 'm'))) {
    return false
  }
  return true
}

export const calculateFinalResults = (results) => {
  const testNames = Object.keys(results || {})
  const failedTests = testNames.filter(testName => !results[testName].success)
  if (!failedTests.length) {
    return { result: TEST_RESULTS.SUCCESS }
  }

  const firstMandatoryTestFailed = failedTests.find(failedTest => ['users', 'expenses', 'contracts', 'thirdPartyApps'].includes(failedTest))
  if (firstMandatoryTestFailed) {
    return { result: TEST_RESULTS.FAILED, firstMandatoryTestFailed }
  }
  return { result: TEST_RESULTS.PARTIAL_SUCCESS, failedTests }
}

export const getCapabilities = (serviceCapabilities, integrationCategory, show) => {
  const capabilityList = []
  if (![INTEGRATION_CATEGORY.EXPENSE_SOFTWARE, INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE].includes(integrationCategory)) {
    capabilityList.push(CAPABILITY_TYPES.USERS)
  }
  if (integrationCategory === INTEGRATION_CATEGORY.EXPENSE_SOFTWARE) {
    capabilityList.push(CAPABILITY_TYPES.EXPENSES)
  }
  if (integrationCategory === INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE) {
    capabilityList.push(CAPABILITY_TYPES.CONTRACTS)
  }
  if (get(serviceCapabilities, 'licenses.enabled')) {
    capabilityList.push(CAPABILITY_TYPES.LICENSES)
  }
  if (serviceCapabilities.externalApps) {
    capabilityList.push(CAPABILITY_TYPES.THIRD_PARTY_APPS)
  }
  if (get(serviceCapabilities, 'usage.enabled')) {
    capabilityList.push(CAPABILITY_TYPES.USAGE)
  }
  return capabilityList
}

export const getAuthenticationErrorResults = (serviceCapabilities, integrationCategory, testConnectionInfo) => {
  const capabilities = getCapabilities(serviceCapabilities, integrationCategory)
  const resultsFromDB = isString(testConnectionInfo.results) ? JSON.parse(testConnectionInfo.results) : testConnectionInfo.results

  let mainCapability
  switch (integrationCategory) {
    case INTEGRATION_CATEGORY.EXPENSE_SOFTWARE:
      mainCapability = CAPABILITY_TYPES.EXPENSES
      break
    case INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE:
      mainCapability = CAPABILITY_TYPES.CONTRACTS
      break
    default:
      mainCapability = CAPABILITY_TYPES.USERS
  }

  return capabilities.reduce((results, capability) => {
    if (capability === mainCapability) {
      // Although we no longer show credentials in the UI, we still need to read it from the DB since in this specific scenario this OAuth failure is related to the user's credentials during the authentication handshake (e.g cancelled the request)
      results[capability] = resultsFromDB.credentials
      return results
    }
    results[capability] = { success: false }
    return results
  }, {})
}
