import React from 'react'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { css } from 'glamor'
import numeral from 'numeral'
import moment from 'moment'
import get from 'lodash/get'
import Currency from '../../currencyOld'

const Tooltip = ({ payload, active, hideOnZeroValue = false, formatter = value => value }) => {
  const CSS = {
    main: css({
      padding: '16px',
      border: `1px solid ${colors.border}`,
      backgroundColor: colors.white
    }),
    header: css(texts.subheading, {
      marginBottom: '4px',
      color: colors.grey1,
      textTransform: 'uppercase'
    }),
    details: css(texts.headers.regular),
    noData: css(texts.headers.regular, {
      color: colors.grey1
    }),
    increase: css({
      color: colors.red
    }),
    decrease: css({
      color: colors.green
    }),
    diff: css(texts.subheading, {
      marginLeft: '3px '
    }),
    incompleteMonth: css(texts.body, {
      marginTop: '2px'
    })
  }

  if (!active) {
    return null
  }

  const { date, value, percentage, diff } = get(payload, ['0', 'payload'], {})

  if (hideOnZeroValue && !value) {
    return null
  }

  const now = moment().startOf('month')
  const isIncompleteMonth = now.isSame(moment(date).startOf('month'))

  return (
    <div {...CSS.main}>
      <div {...CSS.header}>{moment.utc(date).format('MMMM YYYY')}</div>
      <div>
        {value === null ? <div {...CSS.noData}>Missing data</div> : <span {...CSS.details}>{formatter(value)}</span>}
        {Boolean(percentage) && percentage !== 0 && <span {...css(CSS.diff, percentage > 0 ? CSS.increase : CSS.decrease)}><Currency value={diff} /> ({numeral(percentage).format('0[.]0%')})</span>}
      </div>
      {isIncompleteMonth && <div {...CSS.incompleteMonth}>Incomplete month</div>}
    </div>
  )
}

export default Tooltip
