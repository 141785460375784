import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
export const getSettings = createSelector(
  state => get(state, ['settings'], {}),
  identity
)

export const getAllPlans = createSelector(
  getSettings,
  settings => get(settings, 'plans', [])
)
