import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { SCOPES } from '../../../constants'
import EnableFor from '../../enableFor'
import Confirmation from '@components/confirmation'

class TeamUserActions extends React.Component {
  state = {
    isDeletePopupOpen: false
  }

  onButtonClick = () => {
    this.setState({ isDeletePopupOpen: true })
  }

  toggleIsDeletePopupOpen = () => {
    this.setState({ isDeletePopupOpen: !this.state.isDeletePopupOpen })
  }

  render () {
    const { me, id, revokeMember, displayName, superAdminRole } = this.props
    const { isDeletePopupOpen } = this.state
    if (me.id === id || !me.idRole === superAdminRole.id) {
      return null
    }

    return (
      <Fragment>
        <Confirmation
          isOpen={isDeletePopupOpen}
          header={`Remove ${displayName} from Torii`}
          text={(
            <div>
              <p>By clicking "Remove", the user will not have access to Torii.</p>
              <p>API Keys created by this user will stay valid. You are recommended to review and rotate API Keys if exist.</p>
            </div>
          )}
          confirmText='Remove'
          declineText='Cancel'
          decline={this.toggleIsDeletePopupOpen}
          confirm={async () => {
            await revokeMember()
            this.toggleIsDeletePopupOpen()
          }}
          close={this.toggleIsDeletePopupOpen}
          mainButtonType={ButtonType.destructive}
        />
        <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]}>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={this.onButtonClick} icon='Trash' />
        </EnableFor>
      </Fragment>
    )
  }
}

TeamUserActions.propTypes = {
  id: PropTypes.number.isRequired,
  idOrg: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  me: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string
  }).isRequired
}

export default TeamUserActions
