import React from 'react'
import WorkflowActions from '@components/workflows/workflowActions'
import { useSelector } from 'react-redux'
import {
  getAppCatalogRequestNewAppPoliciesByIdSelector
} from '@selectors/workflows'
import get from 'lodash/get'
import { WORKFLOW_TYPES } from '@root/constants'

const AppCatalogRequestNewAppPolicyActions = ({
  idOrg,
  idWorkflow,
  name,
  triggerType,
  isValid,
  referrer,
  noBorder = false,
  onDeleteConfirmationOpen,
  pageName,
  shouldShowMenu
}) => {
  const policy = useSelector(getAppCatalogRequestNewAppPoliciesByIdSelector)[idWorkflow]
  const type = get(policy, ['type'], WORKFLOW_TYPES.appCatalog)

  return (
    <WorkflowActions
      idOrg={idOrg}
      idWorkflow={idWorkflow}
      workflowType={type}
      isValid={isValid}
      name={name}
      triggerType={triggerType}
      showDuplicateAction={false}
      showEditTagsAction={false}
      onDeleteConfirmationOpen={onDeleteConfirmationOpen}
      noBorder={noBorder}
      referrer={referrer}
      pageName={pageName}
      shouldShowMenu={shouldShowMenu}
    />
  )
}

export default AppCatalogRequestNewAppPolicyActions
