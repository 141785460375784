import React from 'react'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import WorkflowActionExecutions from '../../components/workflowActionExecutions'
import BackLink from '@components/backLink'
import PropTypes from 'prop-types'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { WORKFLOW_TYPE_TO_ITEM_NAME, WORKFLOW_TYPES } from '@root/constants'

class WorkflowActionExecutionsPage extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    const { idOrg, idWorkflow, idWorkflowExecution, getWorkflow, getWorkflowActionExecutions, getWorkflowsActionsConfig, getContractsFields } = this.props
    getWorkflow({ idOrg, idWorkflow })
    getWorkflowActionExecutions({ idOrg, idWorkflow, idWorkflowExecution })
    getWorkflowsActionsConfig(idOrg)
    getContractsFields({ idOrg })
  }

  render () {
    const { idWorkflow, workflow, actions, loading, actionsByType, appFields, showTriggeredBy, contractsFields, backlinkText, backLinkPathPrefix, itemName } = this.props
    const title = workflow.name && `${workflow.name}${workflow.isDeleted ? ' (deleted)' : ''}`
    const actionsByExecutionOrder = [...actions].reverse()

    return (
      <Page title={title}>
        <div style={{ marginTop: '20px' }}>
          <BackLink navigateTo={`/${backLinkPathPrefix}/${idWorkflow}/executions`} linkText={backlinkText} />
        </div>
        <PageHeader title={title} />
        <WorkflowActionExecutions header={`${itemName.toUpperCase()} ACTIONS`} actions={actionsByExecutionOrder} loading={loading} actionsByType={actionsByType} appFields={appFields} contractsFields={contractsFields} showTriggeredBy={showTriggeredBy} />
      </Page>
    )
  }
}

WorkflowActionExecutionsPage.propTypes = {
  backLinkPathPrefix: PropTypes.string,
  backlinkText: PropTypes.string,
  itemName: PropTypes.string
}

WorkflowActionExecutionsPage.defaultProps = {
  itemName: WORKFLOW_TYPE_TO_ITEM_NAME[WORKFLOW_TYPES.regular],
  backLinkPathPrefix: getWorkflowPathPrefix(),
  backlinkText: 'Back to workflow runs'
}

export default WorkflowActionExecutionsPage
