import colors from '@shared/style/colors'
import { fontWeight } from '@shared/style/sizes'
import styled from 'styled-components'

export const TableHeader = styled.div`
  font-weight: ${fontWeight.normal};
  color: ${colors.grey1};
`

export const ButtonContainer = styled.div`
  min-width: 194px;
`

export const AlertBox = styled.div`
  margin-bottom: 30px;
`
