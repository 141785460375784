import { useEffect, useRef, RefObject } from 'react'

const useScrollToTopOnChange = (dependencies: ReadonlyArray<any>): RefObject<HTMLDivElement> => {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  return divRef
}

export default useScrollToTopOnChange
