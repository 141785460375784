import { css } from 'glamor'
import colors from '@shared/style/colors'
import { ScreenBreakpoints } from '@toriihq/design-system'

export const mobileHeader = css({
  display: 'flex',
  flexDirection: 'row'
})

export const mobileBody = css({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  overscrollBehavior: 'none'
})

export const mobileMain = css({
  display: 'flex',
  flexGrow: 1,
  minHeight: 0,
  width: '100%',
  overflowY: 'auto',
  overflowX: 'none',
  paddingLeft: '20px',
  paddingRight: '20px'
})

export const topMessageBars = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  zIndex: 10
})

export const body = css({
  position: 'absolute',
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'row'
})

export const main = css({
  display: 'flex',
  flex: 1,
  minHeight: 0,
  overflowY: 'auto',
  overflowX: 'none',
  [`@media(min-width: ${ScreenBreakpoints.sm})`]: {
    paddingLeft: '48px',
    paddingRight: '48px'
  },
  [`@media(min-width: ${ScreenBreakpoints.md})`]: {
    paddingLeft: '64px',
    paddingRight: '64px'
  }
})

export const searchBar = css({
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
  backgroundColor: colors.lightBlue3,
  zIndex: 5
})

export const searchBarMobile = css({
  flexGrow: 1
})

export const searchBarShadow = css({
  borderBottom: `1px solid ${colors.border}`
})

export const fullWidth = css({
  width: '100%'
})
