import config from '../config'

const ga4Script = (measurementId) => {
  let e = document.createElement('script')
  e.type = 'text/javascript'
  e.async = !0
  e.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
  let n = document.getElementsByTagName('script')[0]
  n.parentNode!.insertBefore(e, n);

  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).gtag = function gtag (a, b) { (window as any).dataLayer.push(arguments) };
  (window as any).gtag('js', new Date());
  (window as any).gtag('config', measurementId)
}

const track = (event, props = {}) => {
  (window as any).gtag('event', event, props)
}

const { location = {} } = (typeof window !== 'undefined' ? window : {})
const isOnAppUrl = config.appBaseUrl.indexOf((location as any).host) >= 0
const enabled = config.googleAnalytics.enabled && isOnAppUrl

let ga4: {} | null = null
if (enabled) {
  ga4Script(config.googleAnalytics.measurementId)

  ga4 = {
    track
  }
}

export default ga4
