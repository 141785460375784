import React from 'react'
import ToriiSelect from '@components/select'
import { ScheduleFormGroup } from './scheduleFormGroup'

interface Props {
  label: string
  options: Array<{ label: string, value: string }>
  value?: string | null
  onChange: (e: { value: string }) => void
}

export const Select = ({
  label,
  options,
  value,
  onChange
}: Props): JSX.Element => {
  const handleChange = e => {
    e?.value && onChange(e)
  }
  return (
    <ScheduleFormGroup
      label={label}
    >
      <ToriiSelect
        options={options}
        value={value}
        onChange={handleChange}
        clearable={false}
      />
    </ScheduleFormGroup>
  )
}
