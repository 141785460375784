import { compact, groupBy, omit } from 'lodash'
import { OFFBOARDING_TASKS_GROUP_TYPE } from './tabs/pendingTasks/consts'
import type { AppAccountTasks, IdApp, PendingList, PendingTask, PendingTicket, UnmergedPendingList, UnmergedPendingListWithUniqueId, App } from './tabs/pendingTasks/types'

export const groupTasksByAppAccount = (
  tasks: UnmergedPendingList,
  taskType: OFFBOARDING_TASKS_GROUP_TYPE,
  hiddenAppsById: {
    [idApp: IdApp]: App
  }
): {
  appAccountTasks: AppAccountTasks[],
  totalTasks: number
} => {
  const visibleTasks: UnmergedPendingList = tasks.filter(task => !hiddenAppsById[task.idApp])

  const visibleTasksWithUniqueId = visibleTasks.map((task) => {
    const { idAppAccount, idApp } = task
    return {
      ...task,
      uniqueId: `${idAppAccount}-${idApp}`
    }
  })

  const visibleTasksByUniqueId = groupBy(visibleTasksWithUniqueId, 'uniqueId')

  const mergeTasksById = (tasks: UnmergedPendingListWithUniqueId): PendingList => {
    const tasksGroupById = groupBy(tasks, 'id')
    return Object.keys(tasksGroupById).map((idTask) => {
      const tasksBeforeMerge = tasksGroupById[idTask]
      const idUsers = compact(tasksBeforeMerge.map(task => task.idUser))

      const task = {
        ...omit(tasksBeforeMerge[0], 'idUser'),
        idUsers
      } as PendingTask | PendingTicket

      return task
    })
  }

  let totalTasks = 0
  const appAccountTasks = Object.keys(visibleTasksByUniqueId).map((uniqueId) => {
    const appAccountTasks = visibleTasksByUniqueId[uniqueId]
    const firstAccountTask = appAccountTasks[0]
    const { idAppAccount, idApp, appAccountName } = firstAccountTask
    const tasks: AppAccountTasks = {
      idAppAccount,
      idApp,
      appAccountName,
      tasks: mergeTasksById(appAccountTasks),
      taskType,
      uniqueId: `${idAppAccount}-${idApp}`
    }
    totalTasks += tasks.tasks.length
    return tasks
  })

  return { totalTasks, appAccountTasks }
}
