import { ConditionalBranch, BranchBase } from '@shared/workflows/types'

export enum BRANCH_TYPE {
  REGULAR = 'regular',
  DEFAULT_BRANCH = 'default_branch'
}

export interface GetBranchDataResult {
  branch?: ConditionalBranch | BranchBase
  branchLabel?: string
}
