import { ConditionalBranch } from '@shared/workflows/types'
import { EntityOption } from '@components/filters/ifElseBranchFilters/types'
import { getNewBranchFilter } from './getNewBranchFilter'

interface Params {
  branches: ConditionalBranch[]
  branchIndex: number
  entityOptions: EntityOption[]
}

export const addBranchFilter = ({
  branches,
  branchIndex,
  entityOptions
}: Params): ConditionalBranch[] => {
  return branches.map((branch, index) => {
    if (index === branchIndex) {
      const newFilter = getNewBranchFilter({ entityOptions })
      const currentFilters = branch.filters || []
      const updatedBranchFilters = currentFilters.concat(newFilter)

      return { ...branch, filters: updatedBranchFilters }
    }

    return branch
  })
}
