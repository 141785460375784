import React from 'react'
import Page from '@components/page'
import BackLink from '@components/backLink'
import PageHeader from '@components/pageHeader'
import WorkflowsNotifyOnErrorsSettings from '@components/WorkflowsNotifyOnErrorsSettings'
import WorkflowTagsCollapsable from '@components/workflowTagsCollapsable'
import * as Styles from './styles'
import { analytics } from '@shared/services/workflows/analytics'

const WorkflowsSettings = () => {
  const onClickSection = (sectionLabel) => {
    analytics.settingsPage.clickOnSection({ sectionLabel })
  }

  return (
    <Page title={'Workflow Settings'}>
      <div style={{ marginTop: '20px' }}>
        <BackLink navigateTo={'/workflows'} linkText={'Back to workflows'} />
      </div>
      <PageHeader title={'Workflow Settings'} />
      <Styles.WorkflowSettingsCollapsableContainer>
        <WorkflowsNotifyOnErrorsSettings onClick={() => onClickSection('Notification settings')} />
        <WorkflowTagsCollapsable onClick={() => onClickSection('Tags management')} />
      </Styles.WorkflowSettingsCollapsableContainer>
    </Page>
  )
}

export default WorkflowsSettings
