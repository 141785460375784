import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import EditableField from '@components/editableField'
import { Link } from '@toriihq/design-system'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { getFormattedDate } from '@lenses/utils'

const CSS = {
  uploadFileContainer: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    ' .ef-field': {
      borderBottom: '0'
    },
    ' .ef-actions': {
      paddingLeft: '8px'
    },
    marginBottom: '28px'
  }),
  filesContainer: css({
    ' div:nth-of-type(1) .ef-field': {
      borderTop: '0'
    }
  }),
  headerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: `1px solid ${colors.border}`
  }),
  header: css(texts.heading, {
    textTransform: 'uppercase'
  }),
  description: css(texts.subheading, {
    padding: '10px 20px'
  }),
  file: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }),
  fileName: css({
    display: 'flex'
  }),
  field: css({
    ' div': {
      flex: 'auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
}

class ExternalFileBox extends React.Component {
  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  fetchData () {
    const { idOrg, idApp, source, getAppTransactionsBySource } = this.props
    getAppTransactionsBySource({ idOrg, idApp, source })
  }

  render () {
    const { header, description, transactions, source, urlPrefix } = this.props

    const headerComponent = (
      <div {...CSS.headerContainer}>
        <div {...CSS.header}>{header}</div>
      </div>
    )

    const descriptionComponent = description && <div {...CSS.description}>{description}</div>
    const sorted = orderBy(transactions || [], ['transactionDate'], ['desc'])
    const fileFields = sorted
      .filter(transaction => transaction.source === source)
      .map(transactions => {
        return (
          <EditableField
            readonly
            key={transactions.idExternalTransaction}
            label={<div {...CSS.fileName}><Link href={`${urlPrefix}${transactions.idExternalTransaction}`} target='_blank' rel='noopener noreferrer'><Link.Icon name='ExternalLink' />{getFormattedDate({ date: transactions.transactionDate, emptyValueDefaultDisplay: '' })}</Link></div>}
            value={''}
            ignoreEditMode
            showRemoveButton={false}
            emptyValue=''
            overrideStyle={CSS.field}
          />
        )
      })

    if (isEmpty(fileFields)) {
      return null
    }

    return (
      <div {...CSS.uploadFileContainer}>
        {headerComponent}
        {descriptionComponent}
        <div {...CSS.filesContainer}>
          {fileFields}
        </div>
      </div>
    )
  }
}

export default ExternalFileBox
