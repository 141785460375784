import React from 'react'
import { css } from 'glamor'
import { colors } from '@shared/style/colors'
import SelectTree from '../selectTree'
import StackedBarChartBox from '../stackedBarChartBox'
import moment from 'moment'
import uniq from 'lodash/uniq'
import Select from '../select'
import LicenseTooltipContent from '../licensesChart/licenseTooltipContent'
import texts from '@shared/style/texts'
import difference from 'lodash/difference'
import { TIME_PERIOD_OPTIONS, USAGE, USAGE_BOX, USAGE_OPTIONS } from './consts'
import { Tooltip, Icon, Button, ButtonSize, ButtonType, Popover } from '@toriihq/design-system'
import { formatNumber } from '@root/shared/utils'
import emptyImage from '@media/no-results.svg'
import EnableFor from '@components/enableFor'

const CSS = {
  header: css({
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  }),
  filtersContainer: css({
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    marginBottom: '10px',
    gap: '8px'
  }),
  mainDiv: css({
    height: '450px'
  }),
  active: css({
    backgroundColor: colors.lightBlue4
  }),
  inactive: css({
    backgroundColor: colors.warning,
    cursor: 'pointer'
  }),
  unassigned: css({
    backgroundColor: colors.grey2
  }),
  tooltip: css({
    backgroundColor: colors.white,
    border: `1px solid ${colors.border}`,
    boxShadow: '0 9px 12px 0 rgba(0,0,0,0.05)',
    padding: '20px'
  }),
  tooltipContainer: css({
    paddingBottom: '20px',
    ':last-child': {
      paddingBottom: 0
    }
  }),
  tooltipHeader: css(texts.headers.regular, {
    marginBottom: '20px'
  })
}

class LicenseTrendsChart extends React.Component {
  componentDidUpdate (prevProps) {
    const { allLicenseFilterDataIds, selectedLicenseIdsFilter, setLicenseTrendFilterAction } = this.props

    const allLicensesListHasChanged = difference(allLicenseFilterDataIds, prevProps.allLicenseFilterDataIds).length > 0 ||
      difference(prevProps.allLicenseFilterDataIds, allLicenseFilterDataIds).length > 0
    const isAllSelected = difference(allLicenseFilterDataIds, selectedLicenseIdsFilter).length === 0

    if ((allLicensesListHasChanged && isAllSelected) || prevProps.allLicenseFilterDataIds.length === 0) {
      setLicenseTrendFilterAction({ selectedLicenseIdsFilter: allLicenseFilterDataIds, loading: true })
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData = (selectedTimePeriod) => {
    const { idOrg, getLicenseTrendsChart, selectedTimePeriod: previousSelectedTimePeriod } = this.props
    const period = selectedTimePeriod || previousSelectedTimePeriod
    getLicenseTrendsChart({ idOrg, period })
  }

  onFilterChanged = async ({ name, ids, value, callback }) => {
    const { setLicenseTrendFilterAction } = this.props
    const existingSelected = this.props[name]
    let newSelected

    if (value) {
      newSelected = [...existingSelected, ...ids]
    } else {
      newSelected = existingSelected.filter(selectedId => !ids.includes(selectedId))
    }

    await setLicenseTrendFilterAction({ [name]: uniq(newSelected) })
    callback && callback()
  }

  onTimePeriodFilterChange = (selected) => {
    const { setLicenseTrendFilterAction } = this.props
    setLicenseTrendFilterAction({ selectedTimePeriod: selected.value })
    this.fetchData(selected.value)
  }

  getStackedBarChartBoxData = () => {
    const { licenses, selectedLicenseIdsFilter, selectedUsageIdsFilter } = this.props

    const filteredLicenses = licenses
      .filter(license => selectedLicenseIdsFilter.includes(license.idLicense))
      .filter(license => selectedUsageIdsFilter.some(usageId => USAGE[usageId].getValue(license)))

    const allDaysInTimePeriod = licenses.reduce((acc, license) => {
      const { date } = license
      if (!acc[date]) {
        acc[date] = { name: moment(date).format('DD MMM YYYY'), [USAGE_BOX.active.key]: 0, [USAGE_BOX.unassignedAmount.key]: 0, [USAGE_BOX.inactive.key]: 0 }
      }

      return acc
    }, {})

    filteredLicenses.forEach(license => {
      const { date } = license

      allDaysInTimePeriod[date][USAGE_BOX.active.key] += USAGE.active.getValue(license)
      allDaysInTimePeriod[date][USAGE_BOX.unassignedAmount.key] += USAGE.unassignedAmount.getValue(license) || 0
      allDaysInTimePeriod[date][USAGE_BOX.inactive.key] += selectedUsageIdsFilter.reduce((sum, usage) => {
        if (USAGE_BOX.inactive.types.includes(usage)) {
          sum += license[usage]
        }

        return sum
      }, 0)
    })

    const stackedBarCharBoxData = Object.values(allDaysInTimePeriod).reverse()
    const stackedBarCharBoxFill = Object.values(USAGE_BOX).filter(usage => usage.types.some(type => selectedUsageIdsFilter.includes(type)))

    const emptyStateInfo = {
      title: 'Data is not available for the selected period',
      image: <img src={emptyImage} alt='Data is not available for the selected period' />
    }

    return { stackedBarCharBoxData, stackedBarCharBoxFill, emptyStateInfo }
  }

  renderToolTip = ({ payload, label }) => {
    const tooltipColors = {
      active: { color: CSS.active, description: 'Assigned and being used' },
      inactive: { color: CSS.inactive, description: 'Users that are assigned but not being used or with no access to service or a past user' },
      unassignedAmount: { color: CSS.unassigned, description: 'Available licenses not assigned to users' }
    }

    if (!payload) {
      return null
    }

    return (
      <div {...CSS.tooltip}>
        <div {...CSS.tooltipHeader}>{label}</div>
        {
          payload.map(item => (
            <div key={item.dataKey} {...CSS.tooltipContainer}>
              <LicenseTooltipContent
                header={item.name}
                description={tooltipColors[item.dataKey].description}
                amount={formatNumber(item.value)}
                colorCss={tooltipColors[item.dataKey].color}
              />
            </div>
          ))
        }

      </div>
    )
  }

  renderHeader = () => {
    return (
      <div {...CSS.header}>
        LICENSES TREND
        <Tooltip
          placement='top'
          label='Unassigned licenses count might not be available for some applications'
        >
          <Icon name='Info' color='secondary' />
        </Tooltip>
      </div>
    )
  }

  render () {
    const { licenseFilterData, selectedTimePeriod, selectedLicenseIdsFilter, selectedUsageIdsFilter, loading } = this.props

    const { stackedBarCharBoxData, stackedBarCharBoxFill, emptyStateInfo } = this.getStackedBarChartBoxData()
    const showEmptyState = !stackedBarCharBoxData || stackedBarCharBoxData.length === 0

    return (
      <div {...CSS.mainDiv}>
        <div {...CSS.filtersContainer}>
          <Select
            options={TIME_PERIOD_OPTIONS}
            value={selectedTimePeriod}
            onChange={this.onTimePeriodFilterChange}
            clearable={false}
            searchable={false}
            key={`time_period_fields_select`}
            name={`time_period_fields_select`}
            openOnFocus
          />
          <Popover
            position='bottom'
            align='start'
            openOnClick
            content={<SelectTree
              data={licenseFilterData}
              selected={selectedLicenseIdsFilter}
              onChange={(ids, value, callback) => this.onFilterChanged({ name: 'selectedLicenseIdsFilter', ids, value, callback })}
            />}>
            <EnableFor allowForToriiAdmin>
              <Button
                size={ButtonSize.small}
                type={ButtonType.secondary}
                disabled={showEmptyState}
                label={'Filter by license'}
                onClick={() => null}
              />
            </EnableFor>
          </Popover>

          <Popover
            position='bottom'
            align='start'
            openOnClick
            content={<SelectTree
              data={USAGE_OPTIONS}
              selected={selectedUsageIdsFilter}
              onChange={(ids, value, callback) => this.onFilterChanged({ name: 'selectedUsageIdsFilter', ids, value, callback })}
            />}
          >
            <EnableFor allowForToriiAdmin>
              <Button
                size={ButtonSize.small}
                type={ButtonType.secondary}
                disabled={showEmptyState}
                label={'Filter by usage'}
                onClick={() => null}
              />
            </EnableFor>
          </Popover>
        </div>

        <StackedBarChartBox
          data={stackedBarCharBoxData}
          fill={stackedBarCharBoxFill}
          showLegend={false}
          loading={loading}
          header={this.renderHeader()}
          emptyStateInfo={emptyStateInfo}
          showEmptyState={showEmptyState}
          tooltipContent={this.renderToolTip}
          formatter={formatNumber}
        />
      </div>
    )
  }
}

LicenseTrendsChart.propTypes = {}

export default LicenseTrendsChart
