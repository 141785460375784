import styled from 'styled-components'

export namespace Styles {
  export const PluginDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    color: ${({ theme }) => theme.palette.text.tertiary};
  `

  export const PluginDetail = styled.div`
    display: inline-flex;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
  `

  export const PluginDetailLabel = styled.div`
    color: ${({ theme }) => theme.palette.text.tertiary};
    font-weight: 600;
  `

  export const PluginDetailValue = styled.div`
    color: ${({ theme }) => theme.palette.text.primary};
  `
}
