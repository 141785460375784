import React from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { css } from 'glamor'
import Placeholder from '../../placeholder'
import pick from 'lodash/pick'
import { fontSize } from '../../../shared/style/sizes'

const CSS = {
  main: css({
    width: '100%',
    height: '100%',
    minHeight: '197px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row'
  }),
  header: css(texts.subheading, {
    textTransform: 'uppercase',
    color: colors.grey1,
    marginBottom: '5px'
  }),
  legend: css({
    width: '60%',
    minWidth: '150px',
    marginLeft: '-50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }),
  chartContainer: css({
    width: '40%',
    minWidth: '325px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: '5px',
    '> .recharts-responsive-container': {
      maxWidth: '150px'
    }
  }),
  table: css({
    borderCollapse: 'separate',
    borderSpacing: '0 1em',
    padding: '9px 0px'
  }),
  name: css(texts.body, {
    width: '70%',
    color: colors.black,
    fontSize: fontSize.medium
  }),
  value: css(texts.subheading, {
    width: '30%',
    textAlign: 'left',
    fontSize: fontSize.medium,
    color: colors.black
  }),
  square: css({
    display: 'inline-block',
    width: '10px',
    height: '10px',
    marginRight: '8px',
    backgroundColor: 'red',
    borderRadius: '3px'
  })
}

const ToriiPieChart = ({ header, data, loading, hasData, customToolTip, valueDisplayFunction, overrideStyle }) => {
  data = hasData ? data : [{ name: 'None', value: 1, color: '#EBEBEB' }]
  const pieData = data.map(entry => pick(entry, ['name', 'value', 'color', 'minValue', 'count']))
  return (
    <div {...css(CSS.main, overrideStyle)}>
      <div {...CSS.chartContainer}>
        <Placeholder loading={loading} type='round' style={{ margin: 'auto', maxWidth: '150px', maxHeight: '150px' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie data={pieData} dataKey='value' innerRadius={'84%'} outerRadius={'100%'}>
                {data.map((entry) => <Cell key={entry.name} fill={entry.color} />)}
              </Pie>
              {hasData && <Tooltip content={customToolTip} cursor={false} />}
            </PieChart>
          </ResponsiveContainer>
        </Placeholder>
      </div>
      <div {...CSS.legend}>
        {header && <div {...CSS.header}>{header}</div>}
        <Placeholder loading={loading} rows={6} style={{ maxWidth: '100%', margin: 'auto' }}>
          <table {...CSS.table}>
            <tbody>{hasData && data.map((entry) => (
              <tr key={entry.name}>
                {entry.icon ? <td> {entry.icon}</td> : <td><span {...CSS.square} style={{ backgroundColor: entry.color }} /></td>}
                <td {...CSS.name}>
                  <span>{entry.name}</span>
                </td>
                <td {...CSS.value}>{valueDisplayFunction(entry.value)}</td>
              </tr>
            ))}</tbody>
          </table>
        </Placeholder>
      </div>
    </div>
  )
}

ToriiPieChart.propTypes = {
  hasData: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })),
  loading: PropTypes.bool,
  customTooltip: PropTypes.element,
  valueDisplayFunction: PropTypes.func
}

ToriiPieChart.defaultProps = {
  valueDisplayFunction: (value) => value
}

export default ToriiPieChart
