import React from 'react'
import { Button, ButtonProps, Tooltip, Side } from '@toriihq/design-system'
import AccessControl from '@lenses/accessControl'
import { FEATURE_TO_PLAN } from '@shared/features'
import capitalize from 'lodash/capitalize'

interface Props extends ButtonProps {
  feature: string,
  tooltipPlacement: Side
}

const ButtonOfFeature = ({
  feature,
  tooltipPlacement = 'top',
  ...buttonProps
}: Props): JSX.Element => {
  const isFeatureEnabledInPlan = AccessControl.useIsFeatureEnabledInPlan({ feature })
  const tooltip = FEATURE_TO_PLAN[feature]

  return (
    <Tooltip
      placement={tooltipPlacement}
      label={`${capitalize(tooltip)} plan feature`}
      hide={isFeatureEnabledInPlan || !tooltip}
    >
      <Button {...buttonProps} icon={isFeatureEnabledInPlan ? buttonProps.icon : 'Lock'} disabled={buttonProps.disabled || !isFeatureEnabledInPlan} />
    </Tooltip>
  )
}

export default ButtonOfFeature
