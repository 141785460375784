import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`

export const LeftContainer = styled.div`
  display: flex;
  gap: 8px;
`
