import {
  TriggerConfiguration,
  TriggerInputSchemaFields,
  TriggerInputSchemaFieldValue,
  TriggerStepUiConfig
} from '@selectors/workflows/types'
import {
  EMPTY_ACTIONS_MODEL,
  ID_TRIGGER_NODE
} from '@shared/workflows/actions/consts'
import { getNewEmptyAction } from '@shared/workflows/actions/utils/getNewEmptyAction'
import { addActionNode } from '@shared/workflows/actions/utils/addActionNode'
import { AddActionNodeResult } from '@shared/workflows/actions/utils/addActionNode.types'
import { WorkflowActionsModel, Workflow } from '@shared/workflows/types'
import { NewWorkflowTriggerConfigurationField, UpdatedWorkflowInfo } from './types'

export const getNewWorkflowTriggerConfiguration = (trigger: TriggerConfiguration): NewWorkflowTriggerConfigurationField[] => {
  const triggerInputSchema: TriggerInputSchemaFields = trigger.inputSchema || {}
  const triggerInputSchemaValues: TriggerInputSchemaFieldValue[] = Object.values(triggerInputSchema)

  return triggerInputSchemaValues.map(inputSchemaField => ({
    id: inputSchemaField.id,
    value: inputSchemaField.defaultValue
  }))
}

const doesTriggerHaveSetupSteps = (trigger: TriggerConfiguration): boolean => {
  const triggerUiConfigSteps: TriggerStepUiConfig[] | undefined = trigger.uiConfig?.steps
  return Boolean(triggerUiConfigSteps)
}

export const addEmptyActionNodeToRoot = (currentActions: WorkflowActionsModel): AddActionNodeResult => {
  const newAction = getNewEmptyAction()
  return addActionNode({
    actions: currentActions,
    idParentUiNode: ID_TRIGGER_NODE,
    action: newAction
  })
}

export const getUpdatedWorkflowInfo = (workflow: Workflow, trigger: TriggerConfiguration, triggerTypeIdApp: number): UpdatedWorkflowInfo => {
  let idNewNode: string = ID_TRIGGER_NODE

  const updatedWorkflow = {
    ...workflow,
    idApp: triggerTypeIdApp,
    triggerType: trigger.type
  }

  if (!doesTriggerHaveSetupSteps(trigger)) {
    const actions = workflow.actions ?? EMPTY_ACTIONS_MODEL
    const { idNewActionNode, actions: updatedActions } = addEmptyActionNodeToRoot(actions)
    updatedWorkflow.actions = updatedActions
    idNewNode = idNewActionNode
  }

  return { idNewNode, workflow: updatedWorkflow }
}
