import React from 'react'
import { Grid, Row, Col } from '@toriihq/design-system'
import Contracts from '../../../components/contracts'
import { TABLES } from '../../../constants'

class ContractsTab extends React.Component {
  render () {
    const { idApp, loading, idOrg, idContractToOpenPopup } = this.props
    return (
      <Grid>
        <Row>
          <Col>
            <Contracts tableKey={TABLES.appContractsTable.key} idApp={idApp} idOrg={idOrg} idContractToOpenPopup={idContractToOpenPopup} loading={loading} showAddContractButton filterable />
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default ContractsTab
