import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import texts from '../../../shared/style/texts'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize } from '@shared/style/sizes'
import { APP_PERMISSIONS, formFieldTypes, SCOPES } from '@root/constants'
import Select from '../../select'
import SelectionBox from '../../workflows/selectionBox'
import FormGroup from '../../form/formGroup'
import Input from '@components/form/input'
import get from 'lodash/get'
import { AlertBox, AlertBoxType, Spacer } from '@toriihq/design-system'

const CSS = {
  body: css(texts.body),
  instructionsContainer: css({
    ' ol': {
      padding: '14px 14px 0 14px',
      margin: '0',
      lineHeight: '22px'
    }
  }),
  form: css({
    marginTop: '30px'
  }),
  permissionContainer: css({
    color: colors.black
  }),
  popupExplanation: css({
    color: colors.black,
    paddingBottom: '20px',
    fontSize: fontSize.medium
  }),
  oAuthExplanation: css(texts.body, {
    marginTop: '30px'
  }),
  footer: css({
    flexDirection: 'row-reverse',
    width: '100%'
  }),
  connectLink: css({
    marginRight: '10px',
    width: '100%'
  })
}

const defaultReadDescription = 'Allow Torii to access user lists, usage and license data.'
const defaultWriteDescription = 'Allow Torii to access user lists, usage and license data, and manage your users.'

class ConnectDirectIntegration extends React.Component {
  state = { permission: this.props.permission }

  isHidden = (field) => {
    const { formProps } = this.props

    if (!field.showOnFieldSpecificValue) {
      return false
    }

    const dependsOnFieldValue = get(formProps, ['values', field.showOnFieldSpecificValue.name])
    return dependsOnFieldValue !== field.showOnFieldSpecificValue.value
  }

  renderField = (props) => {
    const { input, options, label, meta } = props
    const isDropdownField = input.type === formFieldTypes.dropdown
    return <FormGroup label={label} error={meta.touched && meta.error}>
      { isDropdownField
        ? (
          <Select
            {...input}
            value={input.value}
            onChange={(value) => input.onChange(value ? value.value : null)}
            clearable={false}
            options={options}
          />
        )
        : <Input {...props} {...input} /> }
    </FormGroup>
  }

  render () {
    const { formProps, connectFormConfiguration, serviceName, supportsWritePermission, disableReadOption, showDeleteExplanation, readDescription = defaultReadDescription, writeDescription = defaultWriteDescription, permission, onPermissionChanged, supportedFeatures, showReconnectAlertInfo } = this.props

    const { description, fields = [] } = connectFormConfiguration || {}
    const showOAuthMessage = fields.length === 0
    const permissionFields = [
      {
        name: 'Permissions',
        options: [
          { value: APP_PERMISSIONS.read, label: 'Read', description: readDescription, isDisabled: disableReadOption },
          { value: APP_PERMISSIONS.read_write, label: 'Read and Take action', description: writeDescription }
        ]
      }
    ]

    const { handleSubmit } = formProps
    formProps.pristine = fields.length > 0 ? formProps.pristine : false

    return <div {...CSS.body}>
      {showDeleteExplanation && <div {...CSS.popupExplanation}>In order to remove a user directly, please grant Torii permissions to manage your users.</div>}
      {showReconnectAlertInfo && <Spacer bottom='space-300'><AlertBox type={AlertBoxType.INFORMATIVE} description='Providing connection details which relate to a different account will result in the creation of an additional integration instance.' /></Spacer>}
      {
        supportsWritePermission && <div {...CSS.permissionContainer}>
          <SelectionBox
            header='Choose permissions'
            selectedValue={permission}
            groups={permissionFields}
            onChange={onPermissionChanged}
            display='row'
            hideHeader
            allowedScopes={[SCOPES.INTEGRATIONS_WRITE]}
          />
        </div>
      }

      {showOAuthMessage && <div {...css(supportsWritePermission && CSS.oAuthExplanation)}>{`Click Connect and login to ${serviceName} in order to approve Torii integration.`}</div>}

      <form {...supportsWritePermission ? CSS.form : {}} onSubmit={handleSubmit}>
        { description && <div {...CSS.instructionsContainer}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div> }

        {fields.length > 0
          ? <div>
            {fields.map(field => {
              const options = (field.options || []).filter(option => !option.featureFlag || Boolean(supportedFeatures[option.featureFlag]))
              const isHidden = this.isHidden(field)
              if (isHidden) {
                return null
              }
              return (
                <div className='fs-exclude' key={field.name}>
                  <Field
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    suffix={field.suffix || ''}
                    parse={field.parse || (val => val)}
                    options={options}
                    defaultValue={field.defaultValue ? field.defaultValue : (field.options && field.options.length === 1) ? field.options[0].value : undefined}
                    clearable={field.clearable}
                    type={field.type}
                    component={this.renderField}
                  />
                </div>
              )
            })}
          </div> : null
        }
      </form>
    </div>
  }
}

export const connectFormConfiguration = {
  header: PropTypes.string,
  description: PropTypes.node,
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    suffix: PropTypes.string,
    parse: PropTypes.func,
    isMandatory: PropTypes.bool
  })).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  sendDataAsToken: PropTypes.bool
}

ConnectDirectIntegration.propTypes = {
  connectFormConfiguration: PropTypes.shape(connectFormConfiguration),
  serviceName: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  supportsWritePermission: PropTypes.bool,
  disableReadOption: PropTypes.bool,
  showDeleteExplanation: PropTypes.bool,
  idApp: PropTypes.number.isRequired,
  idOrg: PropTypes.number.isRequired,
  showReconnectAlertInfo: PropTypes.bool,
  onSuccess: PropTypes.func,
  callback: PropTypes.func,
  getSyncStatus: PropTypes.bool
}

ConnectDirectIntegration.defaultProps = {
  permission: APP_PERMISSIONS.read,
  getSyncStatus: true,
  onSuccess: () => {},
  hideConnectByLink: false,
  showReconnectAlertInfo: false,
  onPermissionChanged: () => {}
}

export default ConnectDirectIntegration
