import React from 'react'
import PropTypes from 'prop-types'
import ToriiPieChart from '../pieChartBox/pieChart'
import colors, { categoryColors } from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { css } from 'glamor'
import sumBy from 'lodash/sumBy'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import CustomTooltip from './customTooltip'
import Placeholder from '../placeholder'
import AllExpenseCategoriesPopup from '../allExpenseCategoriesPopup'
import emptyImage from '@media/expenses.svg'
import { SCOPES } from '../../constants'
import Currency from '../currency'
import { Button, ButtonType, ButtonSize, EmptyState } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'
import EnableFor from '@components/enableFor'

const CSS = {
  main: css({
    padding: '20px 0'
  }),
  header: css(texts.heading, {
    color: colors.grey1,
    textTransform: 'uppercase'
  }),
  total: css(texts.headers.xLargeBold, {
    marginRight: '14px 12px 5px 0'
  }),
  categoriesHeader: css(texts.heading, {
    color: colors.grey1,
    marginTop: '16px'
  }),
  emptyState: css({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
}

const ExpensesSummary = ({ expenses, loading, numberOfCategoriesToShow, toggleUploadExpenses, toggleAllExpenseCategories, date }) => {
  const showEmptyState = (!loading && (expenses.total === 0))
  if (showEmptyState) {
    const onClick = () => {
      Analytics.track('Click to upload expenses report', {
        from: 'Empty state expenses page month summary'
      })
      toggleUploadExpenses(true, true)
    }

    return (
      <div {...CSS.emptyState}>
        <EmptyState
          image={<img src={emptyImage} alt='No expenses' />}
          title={`Missing data for ${moment.utc(expenses.date).format('MMMM')}`}
          buttons={[
            <EnableFor scopes={[SCOPES.EXPENSE_WRITE]} allowForToriiAdmin>
              <Button type={ButtonType.primary} onClick={onClick} label='Upload expenses report' />
            </EnableFor>
          ]}
        />
      </div>
    )
  }

  const data = loading ? [] : getDataForChart(expenses, numberOfCategoriesToShow)
  const hasData = data && data.length > 0
  return (
    <div {...CSS.main}>
      <Placeholder loading={loading} type='rect' style={{ height: '55px', width: '150px' }}>
        <div>
          <div {...CSS.header}>{date.format('MMMM YYYY')}</div>
          <div {...CSS.total}><Currency value={expenses.total} format='$0,0' /></div>
        </div>
      </Placeholder>
      <div {...CSS.categoriesHeader}>CATEGORIES <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => toggleAllExpenseCategories(true)} label='See all' /></div>
      <ToriiPieChart data={data} loading={loading} hasData={hasData} customToolTip={<CustomTooltip valueDisplayFunction={(value) => <Currency value={value} />} />} valueDisplayFunction={(value) => <div style={{ whiteSpace: 'nowrap' }}><Currency value={value} /></div>} />
      {!loading && <AllExpenseCategoriesPopup title={moment.utc(expenses.date).format('MMMM YYYY')} categories={getAllCategories(expenses)} total={expenses.total} />}
    </div>
  )
}

const getAllCategories = (expenses = {}) => {
  const unsortedCategories = Object
    .keys(expenses.categories)
    .map(categoryName => ({
      name: categoryName,
      value: expenses.categories[categoryName]
    }))
    .filter(entry => (entry.value !== 0))
  return sortBy(unsortedCategories, category => category.value * -1)
}

const getDataForChart = (expenses, numberOfCategoriesToShow) => {
  const categories = getAllCategories(expenses)
  if (categories.length <= numberOfCategoriesToShow) {
    return categories.map(entry => ({ ...entry, color: categoryColors[entry.name] }))
  }

  const top = categories.slice(0, (numberOfCategoriesToShow - 1))
  const rest = categories.slice((numberOfCategoriesToShow - 1))
  const other = {
    name: 'Other',
    value: sumBy(rest, category => category.value)
  }
  return [...top, other].filter(entry => (entry.value > 0)).map(entry => ({ ...entry, color: categoryColors[entry.name] }))
}

ExpensesSummary.propTypes = {
  expenses: PropTypes.object,
  loading: PropTypes.bool,
  numberOfCategoriesToShow: PropTypes.number
}

ExpensesSummary.defaultProps = {
  numberOfCategoriesToShow: 5
}

export default ExpensesSummary
