import styled from 'styled-components'
import { Col } from '@toriihq/design-system'

export const Layout = styled.div`
  min-width: 320px;
  border: ${({ theme }) => `1px solid ${theme.palette.border.secondary}`}; 
  border-radius: 4px;
  padding: 16px 24px;
`

export const ColCenter = styled(Col)`
  text-align: center;
`

export const ColEnd = styled(Col)`
  text-align: end;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font: ${({ theme }) => theme.typography.font.body02};
  margin-bottom: 16px !important;
`

export const Value = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.component03};
  margin-bottom: 16px !important;

  a {
    color: ${({ theme }) => theme.palette.link.primary};
    font: ${({ theme }) => theme.typography.font.component03};
  }
`

export const Header = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.subtitle01};
`

export const Link = styled.a`
  color: ${({ theme }) => theme.palette.link.primary} !important;
  font: ${({ theme }) => theme.typography.font.component03} !important;
`
