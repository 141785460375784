import React from 'react'
import PropTypes from 'prop-types'
import { Stack, H3, Body1, Grid, Col } from '@toriihq/design-system'
import { Row } from './styles'
import moment from 'moment'
import Page from '@components/page'
import PageHeader from '@components/pageHeader'
import MostUsedApps from '@components/charts/mostUsedApps'
import ActiveUsers from '@components/activeUsers'
import TotalApps from '@components/totalApps'
import ExpenseBox from '@components/expenseBox'
import ExpensesBreakdown from '@components/charts/expensesBreakdown'
import AppsCategory from '@components/charts/appsCategory'
import SecurityAndCompliance from '@components/charts/securityAndCompliance'
import UnderutilizedLicenses from '@components/charts/underutilizedLicenses'
import UsersMap from '@components/charts/usersMap'

const customRowsHeights = {
  first: '140px',
  second: '382px',
  third: '345px',
  forth: '308px'
}

class InsightsPage extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.idOrg !== prevProps.idOrg) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const { idOrg, getInsightsStats, getTransactionsAggsByAppCategory } = this.props
    idOrg && getInsightsStats({ idOrg })
    idOrg && getTransactionsAggsByAppCategory({ idOrg })
  }

  getDisplayAccordingToGeoLocationAndExpensesAccess = (isGeoLocationAllowed, isExpensesVisible) => {
    const showWorldMap = isGeoLocationAllowed ?? true
    if (showWorldMap) {
      return (
        <>
          <Row $rowHeight={customRowsHeights.third}>
            <Col sm={6}>
              <AppsCategory />
            </Col>
            <Col sm={6}>
              <UsersMap />
            </Col>
          </Row>
          <Row $rowHeight={customRowsHeights.forth}>
            <Col sm={isExpensesVisible ? 4 : 12}>
              <SecurityAndCompliance pageName='Insights' showTwoRows={isExpensesVisible} />
            </Col>
            { isExpensesVisible && <Col sm={8}>
              <ExpensesBreakdown />
            </Col> }
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row $rowHeight={customRowsHeights.third}>
            <Col sm={6}>
              <AppsCategory />
            </Col>
            <Col sm={6}>
              <SecurityAndCompliance pageName='Insights' showTwoRows />
            </Col>
          </Row>
          { isExpensesVisible && <Row $rowHeight={customRowsHeights.forth}>
            <Col>
              <ExpensesBreakdown />
            </Col>
          </Row> }
        </>
      )
    }
  }

  render () {
    const { firstName, isExpensesVisible, isGeoLocationAllowed } = this.props
    const todayText = moment().format('dddd, D MMMM YYYY')
    return (
      <Page title='Insights'>
        <PageHeader title={(
          <Stack gap='space-200'>
            <H3>Welcome back, {firstName}</H3>
            <Body1 color='secondary'>{todayText}</Body1>
          </Stack>
        )} />
        <Grid>
          <Row $rowHeight={customRowsHeights.first}>
            <Col sm={isExpensesVisible ? 4 : 6}>
              <ActiveUsers />
            </Col>
            <Col sm={isExpensesVisible ? 4 : 6}>
              <TotalApps />
            </Col>
            { isExpensesVisible && <Col sm={4}>
              <ExpenseBox />
            </Col> }
          </Row>
          <Row $rowHeight={customRowsHeights.second}>
            <Col sm={6}>
              <MostUsedApps />
            </Col>
            <Col sm={6}>
              <UnderutilizedLicenses />
            </Col>
          </Row>
          { this.getDisplayAccordingToGeoLocationAndExpensesAccess(isGeoLocationAllowed, isExpensesVisible) }
        </Grid>
      </Page>
    )
  }
}

InsightsPage.propTypes = {
  idOrg: PropTypes.number,
  firstName: PropTypes.string,
  getInsightsStats: PropTypes.func,
  isExpensesVisible: PropTypes.bool.isRequired,
  isGeoLocationAllowed: PropTypes.number
}

export default InsightsPage
