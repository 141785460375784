import { css } from 'glamor'

export const MatchingStatus = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '4px 0'
})

export const MatchingStatusText = css({
  paddingLeft: '3px'
})

export const LightWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '18px',
  height: '16px'
})

export const GreenLight = css({
  borderRadius: '50%',
  height: '8px',
  width: '8px',
  backgroundColor: '#00DF95'
})
export const RedLight = css({
  borderRadius: '50%',
  height: '8px',
  width: '8px',
  backgroundColor: '#FF3377'
})
export const GreyLight = css({
  borderRadius: '50%',
  height: '8px',
  width: '8px',
  backgroundColor: '#80899C'
})

export const AppIconWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '1px',
  paddingBottom: '2px'
})

export const AppIcon = css({
  border: 0
})

export const DescriptionStyle = css({
  whiteSpace: 'normal'
})
