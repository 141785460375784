import React, { Fragment } from 'react'
import { css } from 'glamor'
import pluralize from 'pluralize'
import { Avatar, Spacer, TextBadge, Popover } from '@toriihq/design-system'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { getDisplayName } from '../../../lenses/users'
import { SCOPES } from '../../../constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import VisibleFor from '@root/components/visibleFor'
import { getFormattedDate } from '@lenses/utils'
import capitalize from 'lodash/capitalize'

const CSS = {
  name: css(texts.headers.medium, {
    color: colors.darkText,
    marginBottom: '20px',
    display: 'flex'
  }),
  removed: css(texts.body, {
    color: colors.red,
    marginBottom: '8px'
  }),
  offboardingStatus: css({
    marginLeft: '20px',
    marginBottom: '10px',
    alignSelf: 'end'
  }),
  email: css(texts.body, {
    color: colors.text
  }),
  moreEmailsText: css({
    color: colors.blue,
    cursor: 'default'
  }),
  emailsPopOver: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    borderRadius: 10
  }),
  emailContainer: css({
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center'
  }),
  fullNameContainer: css({
    display: 'flex',
    alignItems: 'center'
  }),
  nameEmailContainer: css({
    display: 'flex',
    flexDirection: 'column'
  })
}

const UserDetails = ({ user, children }) => {
  const { firstName, lastName, photoUrl, userType } = user
  const isExternalUser = user.isExternal
  const externalUserBadge = <Spacer left='space-100'><TextBadge color='orange' size='Small' key='externalUserBadge'>External</TextBadge></Spacer>
  const userTypeBadge = <Spacer left='space-100'><TextBadge color='success' size='Small' key={userType}>{capitalize(userType)}</TextBadge></Spacer>
  const userStatusBadge = [USER_LIFECYCLE_STATUS.OFFBOARDING, USER_LIFECYCLE_STATUS.OFFBOARDED].includes(user.lifecycleStatus) ? <Spacer left='space-125'><TextBadge color='gray' size='Small' key={user.lifecycleStatus}>{capitalize(user.lifecycleStatus)}</TextBadge></Spacer> : ''
  const fullName = getDisplayName(user)

  return <Fragment>
    <div {...CSS.name}>
      <Spacer right={'space-150'}>
        <Avatar firstName={firstName} lastName={lastName} size='large' imageUrl={photoUrl} />
      </Spacer>
      <div {...CSS.nameEmailContainer}>
        <div {...CSS.fullNameContainer}>
          {fullName}
          {(user.lifecycleStatus !== USER_LIFECYCLE_STATUS.OFFBOARDING ? userStatusBadge : <VisibleFor scopes={[SCOPES.OFFBOARDING_READ]}>
            {userStatusBadge}
          </VisibleFor>)}
        </div>
        <div {...CSS.emailContainer}>
          <div {...CSS.email}>
            {user.email}
          </div>
          {user.additionalEmails?.length > 0 && <Spacer left={'space-050'}>
            <Popover
              position='bottom'
              showArrow
              content={user.additionalEmails.map(email => <div {...CSS.email}>{email}</div>)}
            >
              <div {...CSS.email} {...CSS.moreEmailsText}>{`and ${user.additionalEmails.length} more ${pluralize('email', user.additionalEmails.length, false)}`}</div>
            </Popover>
          </Spacer>}
          {isExternalUser ? externalUserBadge : ''}
          {userType ? userTypeBadge : ''}
        </div>
      </div>

      <div {...CSS.offboardingStatus}>
        {children}
      </div>
    </div>
    {user.identitySourcesDeletionTime && <div {...CSS.removed}>Left the organization on {getFormattedDate({ date: user.identitySourcesDeletionTime })}</div>}
  </Fragment>
}

export default UserDetails
