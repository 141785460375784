import ToriiPopup from '@components/popups/ToriiPopupV2'
import FormGroup from '@components/form/formGroup'
import Input from '@components/form/input'
import React, { useEffect, useState } from 'react'
import { SCOPES } from '@root/constants'

type Props = {
  isOpen: boolean,
  onSubmit: (secret: { name: string; value: string }) => void,
  onClose: () => void,
  isEditMode: boolean,
  name?: string,
  maskedValue?: string,
  nameError?: string | null
}

const CreateOrEditSecretPopup = ({ name: originalName = '', maskedValue = '', nameError: error = null, isOpen, onClose, isEditMode, onSubmit }: Props): JSX.Element => {
  const [name, setName] = useState(originalName)
  const [nameError, setNameError] = useState(error)
  const [value, setValue] = useState('')

  useEffect(() => {
    setNameError(error)
  }, [error])

  const onNameChange = (e) => {
    const nameWithoutSpaces = (e.target.value || '').replace(/ /g, '-')
    setName(nameWithoutSpaces)
    setNameError(null)
  }

  const onValueChange = (e) => {
    setValue(e.target.value)
  }

  const header = isEditMode ? 'Edit secret' : 'Add new secret'
  const mainButtonText = isEditMode ? 'Update' : 'Create'

  return <ToriiPopup isOpen={isOpen} onCloseAction={onClose}>
    <ToriiPopup.Header header={header} />
    <ToriiPopup.Content>
      <FormGroup label='Name:' isRequired error={nameError}>
        <Input
          value={name}
          onChange={onNameChange}
          disabled={isEditMode}
          autoFocus
          spellCheck='false'
        />
      </FormGroup>
      <FormGroup label='Value:' isRequired>
        <Input
          value={value}
          onChange={onValueChange}
          placeholder={maskedValue}
          autoFocus={isEditMode}
          spellCheck='false'
        />
      </FormGroup>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonVisibilityScopes={[SCOPES.SETTINGS_WRITE]}
      showCancelButton
      cancelButtonText='Cancel'
      cancelButtonAction={onClose}
      mainButtonText={mainButtonText}
      isMainButtonDisabled={!name || !value}
      mainButtonAction={() => onSubmit({ name, value })}
    />
  </ToriiPopup>
}

export default CreateOrEditSecretPopup
