import * as React from 'react'
import { Tag, TagSize, TagTheme, Tooltip } from '@toriihq/design-system'
import Placeholder from '../placeholder'
import type { Props } from './types'
import { Wrapper, TagsGroup, Counter, LoadingWrapper } from './styles'
import { getSortedTags } from '@lenses/workflows.t'
import { BaseTag } from '@shared/workflows/types'

const TagsContainer = ({ isLoading, tags, maxTagsToShow }: Props) => {
  const loadingStateComponent = (
    <LoadingWrapper data-testid='tags-container-loading'>
      <Placeholder loading={isLoading} />
    </LoadingWrapper>
  )

  const renderCounter = (remainingTags: BaseTag[]) => {
    if (!remainingTags.length) {
      return null
    }

    const counter = <Counter data-testid='tags-container-counter'>{`+${remainingTags.length}`}</Counter>

    return <Tooltip placement={'top'} label={remainingTags.map(tag => tag.label).join(', ')} description={''} children={counter} maxWidth={200} />
  }

  const renderTags = () => {
    if (!tags.length || !maxTagsToShow) {
      return null
    }

    const sortedTags = getSortedTags(tags)
    const tagsToRender = sortedTags.slice(0, maxTagsToShow)
    const remainingTags = sortedTags.slice(maxTagsToShow)

    return <Wrapper>
      <TagsGroup>
        {
          tagsToRender.map(tag => (
            <Tag
              key={`tag-${tag.id}`}
              children={tag.label}
              color={'grey'}
              size={TagSize.Medium}
              theme={TagTheme.Light}
            />
          ))
        }
      </TagsGroup>
      {renderCounter(remainingTags)}
    </Wrapper>
  }

  return (
    isLoading ? loadingStateComponent : renderTags()
  )
}

export default TagsContainer
