import styled from 'styled-components'
import { Col as col, Grid, RowWithoutMargin, theme } from '@toriihq/design-system'
import { css } from 'glamor'
import { fontSize, fontWeight } from '@root/shared/style/sizes'

export const Popup = {
  modal: {
    width: '48%',
    height: '100%',
    overflow: 'hidden'
  }
}

export const MainArea = css({
  width: '100%',
  height: '90%'
})

export const ExplanationArea = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 90%;
  padding: 0;
`

export const ExplanationTable = styled(Grid)`
  margin-left: 12px !important;
  margin-right: 12px !important;
  padding: 0;
  width: 80%
  overflow: auto;
`

export const ExplanationHeader = styled.div`
  width: 90%;
  margin-top: -5px;
  margin-bottom: 20px;
`

export const InfoIconWrapper = styled.span`
  margin-left: 6px;
  margin-right: 6px;
  position: relative;
  top: 8px;
  margin-top: -10px;
  padding-top: 0;
`

export const UnorderedObjectList = styled.ul`
    list-style-type: none;
    padding-left: 0;
`

export const ListItem = styled.li`
  margin: 5px 0;
`

const Row = styled(RowWithoutMargin)`
  overflow: auto;
`

export const TitleRow = styled(Row)`
    height: 20%;
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.semiBold};
    color: ${theme.palette.text.tertiary}
`

export const ExplanationRow = styled(Row)`
    background-color: ${theme.palette.background.primaryActive};
    height: 56px;
`

export const CoreRow = styled(Row)`
    height: 50%;
`
export const ObjectsRow = styled(Row)`
    height: 50%;
`

export const Col = styled(col)`
  border: 1px solid ${theme.palette.border.primary};
  display: flex;
  align-items: center;
`

export const InnerCol = styled(Col)`
  justify-content: space-around;
  border-left: none;
`
export const ExplanationCol = styled(Col)`
  border-top: none;
  border-bottom: none;
`
