import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isUpdatingUserLifecycle } from '@selectors/org'
import successIcon from '../images/success.svg'
import useTimeout from '@shared/hooks/useTimeout'
import * as Style from './style'
import { UserLifecycleSettingsContext } from '@components/popups/userLifecycleSettingsPopup/UserLifecycleSettingsContext'
import Spinner from '@media/spinner.svg'

export const UserLifecycleComplete = () => {
  const isUpdatingLifecycle = useSelector(isUpdatingUserLifecycle)

  const { preview } = useContext(UserLifecycleSettingsContext)

  const [totalUsers, setTotalUsers] = useState()
  const [workflows, setWorkflows] = useState()
  const [fakeWaitingSpinner, setFakeWaitingSpinner] = useState(true)

  useTimeout(() => {
    setFakeWaitingSpinner(false)
  }, 1000, { persistRenders: true })

  useEffect(() => {
    if (preview) {
      setTotalUsers(preview.pastUsers + preview.currentUsers)
      setWorkflows(preview.workflows)
    }
  }, [preview])

  const showSpinner = isUpdatingLifecycle || fakeWaitingSpinner

  const InfoLine = ({ children, show }) => {
    if (show && !fakeWaitingSpinner) {
      return (
        <div {...Style.PreviewLine}>{children}</div>
      )
    } else {
      return (<></>)
    }
  }

  return (
    <div {...Style.Content}>
      {showSpinner && (
        <div {...Style.Spinner}>
          <img alt='Applying changes...' src={Spinner} width='26px' height='26px' />
          {'Applying changes...'}
        </div>
      )}
      {!showSpinner && (
        <Fragment>
          <div {...Style.Left}>
            <InfoLine show={!isUpdatingLifecycle && preview && totalUsers > 0}>
              User lifecycle of&nbsp;<strong>{`${totalUsers} users`}</strong>&nbsp;was updated
            </InfoLine>
            <InfoLine show={!isUpdatingLifecycle && preview && workflows > 0}>
              Triggers of&nbsp;<strong>{`${workflows} workflows`}</strong>&nbsp;were changed
            </InfoLine>
            <InfoLine show={!isUpdatingLifecycle && workflows === 0}>
              There might be workflows whose triggers changed
            </InfoLine>
          </div>
          <div {...Style.Right}>
            <img src={successIcon} alt={'eyes'} height={Style.SuccessSize} />
          </div>
        </Fragment>
      )}
    </div>
  )
}
