import { GET_CALENDARS } from '@root/constants.t'
import send from '@shared/redux-fetch'
import { GetCalendarsProps } from './types'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getCalendars = ({ idOrg, type, fields = ['id', 'uuid'] }: GetCalendarsProps) => {
  return send(GET_CALENDARS, {
    url: `/api/orgs/${idOrg}/calendars`,
    method: 'GET',
    query: omitBy({
      type: type!,
      fields: fields.join(',')
    }, isUndefined)
  })
}
