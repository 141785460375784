import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import { SCOPES, SHARE_STATUS, TABLES } from '@root/constants'
import { getSelf, getUserScopes } from './me'
import { getNeedsAttentionServices } from './services'
import pluralize from 'pluralize'
import { FEATURES } from '@shared/features'
import { getOrgFeatures } from '@selectors/org'
import { isFeatureInOrgFeatures } from '@lenses/accessControl'

export const getIsSmallScreen = createSelector(
  state => get(state, ['ui', 'isSmallScreen']),
  identity
)

export const getIsUserLifecycleSettingsOpen = createSelector(
  state => get(state, ['ui', 'isUserLifecycleSettingsOpen']),
  identity
)

export const getUserPreferences = createSelector(
  state => get(state, ['ui', 'userPreferences'], {}),
  identity
)

export const getTablesViews = createSelector(
  state => get(state, ['ui', 'tablesViews'], {}),
  identity
)

export const getTablesViewsUsers = createSelector(
  state => get(state, ['ui', 'tablesViews', 'usersById'], {}),
  identity
)

export const getTablesViewsOptions = createSelector(
  [getTablesViews, getSelf, getUserScopes],
  (tables, self, userScopes) => {
    return Object.keys(tables).reduce((acc, tableKey) => {
      const views = tables[tableKey]
      const hasViewsWriteScope = userScopes.includes(SCOPES.PUBLIC_VIEWS_WRITE)
      const viewOptions = Object.values(views).map(view => ({
        ...view,
        value: view.id,
        label: view.viewName,
        actionsDisabled: self.id !== view.idUser && (view.shareStatus === SHARE_STATUS.sharedReadOnly.type || (view.shareStatus === SHARE_STATUS.shared.type && !hasViewsWriteScope))
      }))

      acc[tableKey] = viewOptions
      return acc
    }, {})
  }
)

export const getUserTablesPreferences = createSelector(
  state => get(state, ['ui', 'userPreferences', TABLES.inactiveUsersListPopup.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.inactiveUsersListPopup.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.usersOverlapsPopup.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.usersTable.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.usersTable.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.appUsersTable.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.appUsersTable.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.appActiveUsersListPopup.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.appActiveUsersListPopup.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.deletedUsersTable.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.deletedUsersTable.key, 'columnsConfiguration']),
  state => get(state, ['ui', 'userPreferences', TABLES.externalUsersTable.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.licenseRecommendationsTable.key, 'columnsConfiguration']),
  state => get(state, ['ui', 'userPreferences', TABLES.licenseRecommendationsTable.key, 'filters']),
  state => get(state, ['ui', 'userPreferences', TABLES.allDiscoveredAppsTable.key, 'columnsConfiguration']),
  state => get(state, ['ui', 'userPreferences', TABLES.appDiscoveredAppsTable.key, 'columnsConfiguration']),
  (inactiveUsersPopupColumnsConfiguration, inactiveUsersPopupFilters, usersOverlapsPopupColumnsConfiguration, usersColumnsConfiguration, usersFilters, appUsersColumnsConfiguration, appUsersFilters, appActiveUsersColumnsConfiguration, appActiveUsersFilters, deletedUsersFilters, deletedUsersColumnsConfiguration, externalUsersFilters, licenseRecommendationsColumnsConfiguration, licenseRecommendationsColumnsFilters, allDiscoveredAppsTableColumnsConfiguration, appDiscoveredAppsTableColumnsConfiguration) => ({
    [TABLES.inactiveUsersListPopup.key]: {
      columnsConfiguration: inactiveUsersPopupColumnsConfiguration,
      filters: inactiveUsersPopupFilters
    },
    [TABLES.usersOverlapsPopup.key]: {
      columnsConfiguration: usersOverlapsPopupColumnsConfiguration
    },
    [TABLES.usersTable.key]: {
      columnsConfiguration: usersColumnsConfiguration,
      filters: usersFilters
    },
    [TABLES.appUsersTable.key]: {
      columnsConfiguration: appUsersColumnsConfiguration,
      filters: appUsersFilters

    },
    [TABLES.appActiveUsersListPopup.key]: {
      columnsConfiguration: appActiveUsersColumnsConfiguration,
      filters: appActiveUsersFilters
    },
    [TABLES.deletedUsersTable.key]: {
      columnsConfiguration: deletedUsersColumnsConfiguration,
      filters: deletedUsersFilters
    },
    [TABLES.externalUsersTable.key]: {
      filters: externalUsersFilters
    },
    [TABLES.licenseRecommendationsTable.key]: {
      columnsConfiguration: licenseRecommendationsColumnsConfiguration,
      filters: licenseRecommendationsColumnsFilters
    },
    [TABLES.allDiscoveredAppsTable.key]: {
      columnsConfiguration: allDiscoveredAppsTableColumnsConfiguration
    },
    [TABLES.appDiscoveredAppsTable.key]: {
      columnsConfiguration: appDiscoveredAppsTableColumnsConfiguration
    }
  })
)

export const getAppsV2TablePreferences = createSelector(
  state => get(state, ['ui', 'userPreferences', TABLES.appsV2Table.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.appsV2Table.key, 'filters']),
  (appsTableColumnsConfiguration, appsTableFilters) => ({
    [TABLES.appsV2Table.key]: {
      columnsConfiguration: appsTableColumnsConfiguration,
      filters: appsTableFilters
    }
  })
)

export const getMonthlyReportTablePreferences = createSelector(
  state => get(state, ['ui', 'userPreferences', TABLES.monthlyExpensesReport.key, 'columnsConfiguration'], []),
  state => get(state, ['ui', 'userPreferences', TABLES.monthlyExpensesReport.key, 'filters']),
  (columnsConfiguration, tableFilters) => ({
    [TABLES.monthlyExpensesReport.key]: {
      columnsConfiguration: columnsConfiguration,
      filters: tableFilters
    }
  })
)

export const getNavigationRoutes = createSelector(
  [getSelf, getNeedsAttentionServices, getOrgFeatures],
  (me, needsUserAttentionServices, orgFeatures) => {
    const totalNeedsUserAttentionServicesCount = needsUserAttentionServices.length
    return [
      {
        path: '/insights',
        derivedPaths: ['insights'],
        text: 'Insights',
        icon: 'Insights',
        feature: FEATURES.INSIGHTS.PAGE
      },
      {
        path: '/applications',
        derivedPaths: ['applications', 'app/'],
        text: 'Applications',
        icon: 'Applications',
        feature: FEATURES.APPLICATIONS.PAGE
      },
      {
        path: '/licenses',
        derivedPaths: ['licenses'],
        text: 'Licenses',
        icon: 'Licenses',
        feature: FEATURES.LICENSES.PAGE
      },
      {
        path: '/users',
        derivedPaths: ['users', 'user'],
        text: 'Users',
        icon: 'Users',
        visibilityScopes: [SCOPES.USERS_READ],
        feature: FEATURES.USERS.PAGE
      },
      {
        path: '/expenses',
        derivedPaths: ['expenses'],
        text: 'Expenses',
        icon: 'Expenses',
        visibilityScopes: [SCOPES.EXPENSE_READ],
        feature: FEATURES.EXPENSES.PAGE
      },
      {
        path: '/renewals',
        derivedPaths: ['renewals'],
        text: 'Renewals',
        icon: 'Renewals',
        visibilityScopes: [SCOPES.CONTRACTS_READ],
        feature: FEATURES.RENEWALS.PAGE
      },
      {
        path: '/workflows',
        derivedPaths: ['workflows', 'workflow'],
        text: 'Workflows',
        icon: 'Workflows',
        visibilityScopes: [SCOPES.AUTOMATION_READ],
        feature: FEATURES.WORKFLOWS.PAGE
      },
      {
        path: '/offboarding',
        derivedPaths: ['offboarding'],
        text: 'Offboarding',
        icon: 'Offboarding',
        visibilityScopes: [SCOPES.OFFBOARDING_READ],
        feature: FEATURES.OFFBOARDING.PAGE
      },
      {
        path: '/security',
        derivedPaths: ['security'],
        text: 'Security',
        icon: 'Security',
        feature: FEATURES.SECURITY.PAGE
      },
      {
        path: '/reports',
        derivedPaths: ['reports'],
        text: 'Reports',
        icon: 'Reports',
        feature: FEATURES.REPORTS.PAGE
      },
      {
        path: '/appCatalog',
        derivedPaths: ['appCatalog'],
        text: 'App Catalog',
        icon: 'AppCatalog',
        feature: FEATURES.APP_CATALOG.PAGE
      },
      {
        path: '/services',
        derivedPaths: ['services'],
        text: 'Integrations',
        icon: 'Integrations',
        badge: totalNeedsUserAttentionServicesCount || null,
        badgeTooltipText: totalNeedsUserAttentionServicesCount > 0 ? `${pluralize('integration', totalNeedsUserAttentionServicesCount, true)} ${totalNeedsUserAttentionServicesCount === 1 ? 'needs' : 'need'} attention` : null,
        feature: FEATURES.INTEGRATIONS.PAGE
      },
      {
        path: '/plugins',
        derivedPaths: ['plugins', 'plugin'],
        text: 'Plugins',
        icon: 'Puzzle',
        visibilityScopes: [SCOPES.BASIC_ADMIN],
        feature: FEATURES.PLUGIN_MARKETPLACE.PAGE
      },
      {
        path: '/settings',
        derivedPaths: ['settings'],
        text: 'Settings',
        icon: 'Settings',
        feature: FEATURES.SETTINGS.PAGE
      }
    ].filter(route => !route.hidden)
      .map(route => ({ ...route, isFeatureEnabledInPlan: isFeatureInOrgFeatures({ orgFeatures, feature: route.feature }) }))
  }
)

export const getShowHiddenWorkflows = createSelector(
  state => get(state, ['ui', 'showHiddenWorkflows'], false),
  identity
)

export const getNavigationForceCollapse = createSelector(
  state => get(state, ['ui', 'forceNavigationCollapse'], false),
  identity
)

export const getUnsafeMode = createSelector(
  state => get(state, ['ui', 'unsafeMode'], false),
  identity
)

export const getLicenseEditPopup = createSelector(
  state => get(state, ['ui', 'licenseEditPopup'], false),
  identity
)

export const getAskToUpdateLicenseCostPopup = createSelector(
  state => get(state, ['ui', 'askToUpdateLicenseCostPopup'], false),
  identity
)

export const getShareReportPopup = createSelector(
  state => get(state, ['ui', 'shareReportPopup'], false),
  identity
)

export const getAddApplicationPopup = createSelector(
  state => get(state, ['ui', 'addApplication'], false),
  identity
)

export const isPopUpOpen = createSelector(
  (state, popupName) => state.ui.openPopups.includes(popupName),
  identity
)

export const getHeaderHeight = createSelector(
  state => get(state, ['ui', 'headerHeight']),
  identity
)
export const getIsNavigationVisible = createSelector(
  state => get(state, ['ui', 'isNavigationVisible']),
  identity
)

export const getViewOnlyMode = createSelector(
  state => get(state, ['ui', 'viewOnlyMode'], false),
  identity
)

export const getCurrencySymbols = createSelector(
  state => get(state, ['ui', 'currencies'], {}),
  identity
)

export const getDisplayCurrencyOptions = createSelector(
  state => get(state, ['ui', 'displayCurrencyOptions'], []),
  identity
)

export const getExecuteActionOnUsersPopupUsers = createSelector(
  state => get(state, ['ui', 'configureExecuteActionOnUsersPopup', 'users'], []),
  users => {
    return users.map(user => ({ ...user, id: user.idUser, email: user.originEmail }))
  }
)
