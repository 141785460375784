import { fontSize, fontWeight } from '@root/shared/style/sizes'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.semiBold};
`

export const SubText = styled.div`
  font-size: ${fontSize.small};
  margin-top: 8px;
  display: flex;
  align-items: center;
  height: 32px;
`

export const Placeholder = {
  width: '483px',
  height: '30px',
  marginTop: '30px'
}

export const Text = styled.div`
  display: flex;
  align-content: center;
  gap: ${({ theme }) => theme.spacing('space-050')}; 
`

export const ToggleWrapper = styled.div<{alignToRight: boolean}>`
  margin-left: ${({ theme, alignToRight }) => alignToRight ? 'auto' : theme.spacing('space-100')};
`
