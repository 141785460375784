import { Edge, Node } from 'reactflow'
import { IdWorkflowNode } from '@shared/workflows/types'

export enum WORKFLOW_UI_NODE_TYPE {
  ACTION = 'action',
  BRANCH_ACTION = 'branchAction',
  BRANCH_LABEL = 'branchLabel',
  DEFAULT_BRANCH_LABEL = 'defaultBranchLabel',
  TRIGGER = 'trigger',
  END = 'end'
}

export enum WORKFLOW_UI_EDGE_TYPE {
  DEFAULT_EDGE = 'default_edge',
  EDGE_WITH_ADD_BUTTON = 'edge_with_add_button'
}

export interface TriggerUiNode extends Node<undefined> {
  type: WORKFLOW_UI_NODE_TYPE.TRIGGER
}

export interface WorkflowNodeData {
  idWorkflowNode: IdWorkflowNode
}

export interface ActionUiNode extends Node<WorkflowNodeData> {
  type: WORKFLOW_UI_NODE_TYPE.ACTION
}

export interface BranchActionUiNode extends Node<WorkflowNodeData> {
  type: WORKFLOW_UI_NODE_TYPE.BRANCH_ACTION
}

export interface BranchData {
  idBranchingNode: IdWorkflowNode
  branchIndex: number
}

export type DefaultBranchData = Pick<BranchData, 'idBranchingNode'>

export interface BranchLabelUiNode extends Node<BranchData> {
  type: WORKFLOW_UI_NODE_TYPE.BRANCH_LABEL
}

export interface DefaultBranchLabelUiNode extends Node<DefaultBranchData> {
  type: WORKFLOW_UI_NODE_TYPE.DEFAULT_BRANCH_LABEL
}

export interface EndUiNode extends Node<undefined> {
  type: WORKFLOW_UI_NODE_TYPE.END
}

export type WorkflowUiNode =
  TriggerUiNode | ActionUiNode | BranchActionUiNode |
  BranchLabelUiNode | DefaultBranchLabelUiNode | EndUiNode

export interface ReactflowGraphData {
  nodes: WorkflowUiNode[]
  edges: Edge[]
}

export interface NodeDimensions {
  width: number
  height: number
}
