import React from 'react'
import { Text } from 'recharts'
import colors from '@shared/style/colors'
import PropTypes from 'prop-types'

export const CustomGraphLabel = (props) => {
  const { value, textBreakAll } = props

  const getAttrsOfLabel = (props) => {
    const { viewBox, parentViewBox, offset } = props
    const { x, y, width, height } = viewBox

    const sizeAttrs = parentViewBox ? { width, height } : {}
    const horizontalSign = width >= 0 ? 1 : -1
    const horizontalOffset = horizontalSign * offset

    return {
      x: x + horizontalOffset,
      y: y + height / 2,
      textAnchor: 'middle',
      verticalAnchor: 'middle',
      ...sizeAttrs
    }
  }

  const positionAttrs = getAttrsOfLabel(props)

  return (
    <Text {...props} {...positionAttrs} breakAll={textBreakAll} fill={colors.grey1}>
      {value}
    </Text>
  )
}

CustomGraphLabel.propTypes = {
  value: PropTypes.any.isRequired,
  textBreakAll: PropTypes.bool
}

export default CustomGraphLabel
