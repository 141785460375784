import React from 'react'
import * as Style from '@pages/appsComparison/components/sourcesTooltip/style'
import { css } from 'glamor'
import { getSourceByType } from '@root/sourcesConfig'
import { getSourceName, sortSourcesList } from '@lenses/sources'
import { Icon, Popover } from '@toriihq/design-system'

const SourcesTooltip = ({ appName, sourcesList = [], header }) => {
  const tooltipContent = () => {
    const sortedSourcesList = sortSourcesList({ sourcesList, appName })
    const sourcesWithIcons = sortedSourcesList.map(source => {
      const sourceConfig = getSourceByType(source)
      return (
        <div key={source} {...Style.SourceItem}>
          <img alt={sourceConfig.name} src={sourceConfig.icon.grayscale} {...css(Style.SourceIcon)} />
          <div {...Style.SourceName}>{getSourceName({ sourceConfig, appName })}</div>
        </div>
      )
    })

    return (
      <div {...Style.SourceTooltip}>
        <div {...Style.Header}>{header}</div>
        <div {...Style.SourcesList}>{sourcesWithIcons}</div>
      </div>
    )
  }

  return (
    <Popover
      position='top'
      showArrow
      content={tooltipContent()}
    >
      <Icon name='Info' color='secondary' />
    </Popover>
  )
}

export default SourcesTooltip
