export namespace Styles {
    export const placeholderStyle = {
      maxWidth: '100%',
      width: '787px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '24px auto'
    }
}
