import UserDetails from '@root/components/userDetails'
import React from 'react'
import { Styles } from './style'
import { propsType } from './types'

const PluginInstalledBy = ({ installedBy, installationDate }: propsType) => {
  return (
    <>
      <div>
        <Styles.PluginDetailLabel>Installed by</Styles.PluginDetailLabel>
        <UserDetails {...installedBy} />
      </div>
      <div>
        <Styles.PluginDetailLabel>Installed on</Styles.PluginDetailLabel>
        <Styles.PluginDetailValue>{installationDate}</Styles.PluginDetailValue>
      </div>
    </>
  )
}

export default PluginInstalledBy
