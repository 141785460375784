import View from './view'
import { connect } from 'react-redux'
import { reset } from '../../pages/login/actions'

const mapStateToProps = (state, ownProps) => ({
  ...state.reset
})

const mapDispatchToProps = {
  reset
}
export default connect(mapStateToProps, mapDispatchToProps)(View)
