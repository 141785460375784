import React from 'react'
import PropTypes from 'prop-types'
import TabsPage from '@pages/tabs'
import AppDetailsConfiguration from '@components/appDetailsConfiguration'
import ContractDetailsConfiguration from '@components/contractDetailsConfiguration'
import ApiKeys from '@components/apiKeys'
import HiddenApps from '@components/hiddenApps'
import OrgGeneralSettings from '@components/orgGeneralSettings'
import ManageAccount from '@components/manageAccount'
import CustomApps from '@components/customApps'
import BrowserExtensionSettings from '@components/browserExtensionSettings'
import LabsFeatures from '@components/labsFeatures'
import Roles from '@components/roles'
import SecuritySettings from '@components/securitySettings'
import { SCOPES } from '@root/constants'
import MembersPage from '@pages/members'
import UsersAndEmployeesPage from '@pages/usersAndEmployees'
import OrgSecrets from '@components/orgSecrets'
import EmailSettings from '@components/emailSettings'
import { FEATURES } from '@root/shared/features'
import Subscription from '@components/subscription'

class SettingsPage extends React.Component {
  onlyForToriiAdmins = (canManageAccount, output) => {
    return canManageAccount ? output : null
  }

  render () {
    const { idOrg, canManageAccount } = this.props

    const tabsName = [
      'general',
      'usersAndEmployees',
      'members',
      'roles',
      'appDetails',
      'contractDetails',
      'customApps',
      'hiddenApps',
      'emails',
      'security',
      'secrets',
      'extension',
      'apiAccess',
      'labs',
      'subscription',
      this.onlyForToriiAdmins(canManageAccount, 'accountSettings')
    ].filter(item => Boolean(item))

    const tabsHeader = [
      { header: 'General' },
      { header: 'Users & Employees' },
      { header: 'Members' },
      { header: 'Roles' },
      { header: 'Application Details' },
      { header: 'Contract Details', accessScopes: [SCOPES.CONTRACTS_READ] },
      { header: 'Custom Applications' },
      { header: 'Hidden Applications' },
      { header: 'Emails' },
      { header: 'Security' },
      { header: 'Secrets Vault' },
      { header: 'Browser Extension' },
      { header: 'API Access' },
      { header: 'Labs' },
      { header: 'Subscription' },
      { header: this.onlyForToriiAdmins(canManageAccount, 'Account settings') }
    ].filter(item => Boolean(item.header))

    const tabsContent = [
      <OrgGeneralSettings />,
      <UsersAndEmployeesPage />,
      <MembersPage />,
      <Roles idOrg={idOrg} />,
      <AppDetailsConfiguration />,
      <ContractDetailsConfiguration />,
      <CustomApps idOrg={idOrg} />,
      <HiddenApps idOrg={idOrg} />,
      <EmailSettings />,
      <SecuritySettings />,
      <OrgSecrets />,
      <BrowserExtensionSettings />,
      <ApiKeys />,
      <LabsFeatures />,
      <Subscription />,
      this.onlyForToriiAdmins(canManageAccount, (<ManageAccount />))
    ].filter(item => Boolean(item))

    const tabsFeature = [
      FEATURES.SETTINGS.TABS.GENERAL,
      FEATURES.SETTINGS.TABS.USERS_AND_EMPLOYEES.PAGE,
      FEATURES.SETTINGS.TABS.MEMBERS.PAGE,
      FEATURES.SETTINGS.TABS.ROLES.PAGE,
      FEATURES.SETTINGS.TABS.APPLICATION_DETAILS,
      FEATURES.SETTINGS.TABS.CONTRACT_DETAILS,
      FEATURES.SETTINGS.TABS.CUSTOM_APPS,
      FEATURES.SETTINGS.TABS.HIDDEN_APPS,
      FEATURES.SETTINGS.TABS.EMAILS,
      FEATURES.SETTINGS.TABS.SECURITY,
      FEATURES.SETTINGS.TABS.SECRETS_VAULT,
      FEATURES.SETTINGS.TABS.BROWSER_EXTENSION,
      FEATURES.SETTINGS.TABS.API_ACCESS,
      FEATURES.SETTINGS.TABS.LABS,
      FEATURES.SETTINGS.TABS.GENERAL
    ]

    return (
      <TabsPage
        pageHeader='Settings'
        pageTitle='Settings'
        pageName='Settings'
        path={`/team/${idOrg}/settings`}
        tabsName={tabsName}
        tabsHeader={tabsHeader}
        tabsContent={tabsContent}
        tabsFeature={tabsFeature}
        forceShowTabs
        vertical
      />
    )
  }
}

SettingsPage.propTypes = {
  idOrg: PropTypes.number.isRequired
}

export default SettingsPage
