import colors from '@shared/style/colors'
import { css } from 'glamor'
import texts from '@shared/style/texts'

export const COLOR_SCHEME = [colors.blue, colors.orange, colors.glowGreen, colors.lightBlue4]

export const EmptyStateContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 250
})

export const MissingDataText = css(texts.subheading, {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  borderBottom: `1px solid ${colors.border}`,
  marginBottom: 15
})
