import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'

export const ACTIONS_LOG_CONFIG: Record<ACTIONS_LOG_TYPE, { location: string, presetFilters?: any[] }> = {
  [ACTIONS_LOG_TYPE.ALL]: {
    location: 'Workflows'
  },
  [ACTIONS_LOG_TYPE.ACCESS_REQUESTS_POLICIES]: {
    location: 'Access request policies',
    presetFilters: [{
      key: 'triggerReason',
      op: 'equals',
      value: { value: 'app catalog policy' }
    }]
  },
  [ACTIONS_LOG_TYPE.NEW_APP_REQUEST_POLICIES]: {
    location: 'New access requests',
    presetFilters: [{
      key: 'triggerReason',
      op: 'equals',
      value: { value: 'app catalog policy' }
    }]
  },
  [ACTIONS_LOG_TYPE.OFFBOARDING]: {
    location: 'Offboarding',
    presetFilters: [{
      key: 'triggerReason',
      op: 'contains',
      value: 'user offboarding'
    }]
  },
  [ACTIONS_LOG_TYPE.INACTIVE_LICENSES]: {
    location: 'Licenses',
    presetFilters: [{
      key: 'triggerReason',
      op: 'equals',
      value: { value: 'manual action on inactive license' }
    }]
  },
  [ACTIONS_LOG_TYPE.RECOMMENDATIONS]: {
    location: 'Recommendations',
    presetFilters: [{
      key: 'triggerReason',
      op: 'equals',
      value: { value: 'recommendation' }
    }]
  }
}
