import React, { ReactElement } from 'react'
import Success from '@media/emptyConfiguration.svg'
import { TextWithImageAside, MainTitle, SecondaryTitle, SideImage } from './styles'
import { WORKFLOW_TRIGGER_TYPE } from '@root/shared/types'
import { UsersAndEmployeesLink } from './usersAndEmployeesLink'
import { Workflow } from '@shared/workflows/types'
import { isDefaultAppCatalogPolicy } from '@root/lenses/workflows'

interface Props {
  workflow: Workflow
}

const getTextForUserActionTriggers = (triggerTypeText: string): string => {
  return `Please note that ${triggerTypeText} the selected identity provider or HR management system in the User lifecycle in `
}

const TEXT_COMPONENT: Record<WORKFLOW_TRIGGER_TYPE.USER_JOINS | WORKFLOW_TRIGGER_TYPE.USER_LEFT, ReactElement> = {
  [WORKFLOW_TRIGGER_TYPE.USER_JOINS]: <>{getTextForUserActionTriggers('new user discovery is based on')}<UsersAndEmployeesLink triggerType={WORKFLOW_TRIGGER_TYPE.USER_JOINS} />.</>,
  [WORKFLOW_TRIGGER_TYPE.USER_LEFT]: <>{getTextForUserActionTriggers('past users are defined by the status from')}<UsersAndEmployeesLink triggerType={WORKFLOW_TRIGGER_TYPE.USER_LEFT} />.</>
}

const getSecondaryTitle = (workflow: Workflow) => {
  if (isDefaultAppCatalogPolicy({ workflow })) {
    return workflow.triggerType === WORKFLOW_TRIGGER_TYPE.REQUEST_ACCESS
      ? 'This policy will be used for all apps in the catalog unless you configure app-specific policies.'
      : 'This policy will run whenever a user submits a request for a new app.'
  }

  const { triggerType } = workflow

  return triggerType ? TEXT_COMPONENT[triggerType] : null
}

export const EmptyTriggerStep = ({ workflow }: Props): ReactElement => {
  const secondaryTitle = getSecondaryTitle(workflow)

  return (
    <TextWithImageAside>
      <SideImage alt='Changes applied' src={Success} />
      <div>
        <MainTitle>Trigger set, no need for further definitions!</MainTitle>
        {secondaryTitle ? <SecondaryTitle>{secondaryTitle}</SecondaryTitle> : null}
      </div>
    </TextWithImageAside>
  )
}
