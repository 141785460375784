import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 122px;
  opacity: ${({ isCompleted }) => (isCompleted ? 0.5 : 1)};
`

export const RightSlotWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing('space-100')};
`

export const ProgressBarContainer = styled.div`
  width: 200px;
`
