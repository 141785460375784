import React, { ReactElement } from 'react'
import { BaseEdge, EdgeProps } from 'reactflow'
import { useTheme } from 'styled-components'
import { getEdgeData } from '../utils'

export const DefaultEdge = (props: EdgeProps): ReactElement => {
  const { edgePath } = getEdgeData(props)
  const { palette } = useTheme()

  return <BaseEdge path={edgePath} style={{ stroke: palette.border.primary }} />
}
