import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontSize, fontWeight } from '@root/shared/style/sizes'

export const HeadlineText = css({
  display: 'flex',
  justifyContent: 'space-between'
})
export const ShowingResultStyle = css({
  color: colors.grey2,
  fontSize: fontSize.small,
  fontWeight: fontWeight.semiBold
})

export const WarningBar = css({
  width: '100%',
  backgroundColor: colors.lightYellow,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 20px'
})

export const MarginTop = ({ margin }) => css({
  marginTop: margin
})

export const MarginSides = ({ margin }) => css({
  margin: `0 ${margin}`
})

export const WarningIcon = css({
  paddingRight: '15px',
  color: colors.orange3
})

export const NoTranscationImageWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  paddingRight: '32px'
})
export const NoTransactionsContainer = css({
  display: 'flex',
  justifyContent: 'center',
  transform: 'translateY(40%)',
  color: colors.grey1,
  fontSize: fontSize.large
})

export const NoResultsText = css({
  width: '410px',
  fontSize: fontSize.mediumLarge,
  color: colors.grey2
})

export const NoResultsTerm = ({ maxWidth }) => css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth
})

export const Bold = css({
  fontWeight: fontWeight.bold
})

export const BoldAndItalic = css({
  fontWeight: fontWeight.bold,
  fontStyle: 'italic'
})

export const Flex = css({
  display: 'flex'
})

export const TableWrapper = ({ tableHeight }) => css({
  marginTop: '15px',
  height: tableHeight,
  overflow: 'auto'
})
