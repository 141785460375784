import React from 'react'
import PropTypes from 'prop-types'
import { AppIcon, Spacer } from '@toriihq/design-system'
import ToriiPopup from '../../popups/ToriiPopupV2'
import UsersList from './usersList'
import { Wrapper, Name } from './styles'

const CSS = {
  popup: {
    modal: {
      height: '80%',
      width: '750px',
      display: 'flex',
      flexDirection: 'column'
    }
  }
}

class SSOAuditUsersPopup extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    const idAppChanged = this.props.idApp && (prevProps.idApp !== this.props.idApp)
    const idOrgChanged = this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)
    if (idAppChanged || idOrgChanged) {
      this.fetchData()
    }
  }

  fetchData () {
    const { getSSOAuditUnmanagedUsers, idOrg, idApp, source } = this.props
    if (!idOrg || !idApp) {
      return
    }
    getSSOAuditUnmanagedUsers({ idOrg, idApp, source })
  }

  render () {
    const { cancel, isOpen, app, users, loading } = this.props
    const { name, imageUrl } = app

    return (
      <ToriiPopup
        isOpen={isOpen}
        onCloseAction={cancel}
        styles={CSS.popup}
      >
        <ToriiPopup.Header header={
          <Wrapper>
            <Spacer right={'space-150'}>
              <AppIcon appName={name} appImageUrl={imageUrl} />
            </Spacer>
            <Name>{name}</Name>
          </Wrapper>} />
        <ToriiPopup.Content>
          <UsersList
            users={users}
            app={app}
            loading={loading}
            scrollId='contentArea'
          />
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }
}

SSOAuditUsersPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  loading: PropTypes.bool
}

export default SSOAuditUsersPopup
