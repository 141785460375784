import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'

export const Container = css({
  backgroundColor: colors.white,
  boxShadow: '0px 8px 10px 0px #00000036',
  boxRadius: '2px',
  display: 'flex',
  flexGrow: 1,
  minWidth: '180px',
  flexDirection: 'column'
})

export const Title = css(texts.heading2, {
  padding: '15px 15px 0px',
  color: colors.black
})

export const Label = css(texts.heading2, {
  padding: '0px 15px',
  color: colors.grey2,
  marginBottom: '10px'
})

export const LegendContainer = css({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  borderColor: colors.lightBlue2,
  borderStyle: 'solid',
  borderWidth: '1px 0 0',
  padding: '10px 0px'
})

export const Row = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '25px',
  margin: '0 15px 10px'
})

export const AppInfoContainer = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  marginRight: '3px'
})

export const InfoContainer = css(texts.heading2, {
  color: colors.black,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center'
})
