import React from 'react'
import moment from 'moment'
import { DATE_FORMAT } from '@root/constants'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { type Props, type User } from './types'
import * as Style from './style'
import Markdown from 'markdown-to-jsx'
import isEmpty from 'lodash/isEmpty'
import { Currency, CurrencyFormat } from '@components/currency'
import UsersIconsList from '@components/usersIconsList'
import { WIDGET_FIELD_TYPES } from '../constants'

const FieldValue = ({ type, value }: { type: typeof WIDGET_FIELD_TYPES[keyof typeof WIDGET_FIELD_TYPES], value: string | User | User[] }) => {
  const getValueByType = () => {
    switch (type) {
      case WIDGET_FIELD_TYPES.singleLine:
      case WIDGET_FIELD_TYPES.multiLine:
      case WIDGET_FIELD_TYPES.email:
      case WIDGET_FIELD_TYPES.cardNumber:
      case WIDGET_FIELD_TYPES.number:
        return value
      case WIDGET_FIELD_TYPES.currency: {
        const currency = value as string
        const currencySymbol = currency.slice(0, 1)
        const amount = currency.slice(1)
        return <Currency value={Number(amount)} format={CurrencyFormat.FLOAT} currencySymbol={currencySymbol} />
      }
      case WIDGET_FIELD_TYPES.datePicker: {
        const date = value as string
        return moment(date).format(DATE_FORMAT)
      }
      case WIDGET_FIELD_TYPES.usersDropdown: {
        const user: User = value as User
        return user?.email ? <RelativeTeamUserLink idUser={user.id}>{user.email}</RelativeTeamUserLink> : value
      }
      case WIDGET_FIELD_TYPES.usersDropdownMulti: {
        const users: User[] = value as User[]
        return users.length ? <UsersIconsList users={users} maxUsers={4} /> : value
      }
      default:
        return value || '-'
    }
  }

  return <Style.Value>{getValueByType()}</Style.Value>
}

const KeyValueWidget = ({ header, fields, emptyState }: Props) => {
  const renderEmptyState = () => {
    return <Markdown children={emptyState || ''} options={{
      wrapper: 'article',
      overrides: {
        a: (props) => <Style.Link {...props} target={'_blank'} rel={'noreferrer noopener'} />
      }
    }} />
  }

  return <Style.Layout>
    <Style.Header>{header}</Style.Header>
    <Style.Content>
      {
        isEmpty(fields)
          ? renderEmptyState()
          : fields.map(field => (
            <Style.Row key={field.label}>
              <Style.Label>{field.label}</Style.Label>
              <FieldValue type={field.type} value={field.value} />
            </Style.Row>
          ))
      }
    </Style.Content>
  </Style.Layout>
}

export default KeyValueWidget
