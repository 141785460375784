import React, { useMemo } from 'react'
import { Col, Grid, RowWithoutMargin } from '@toriihq/design-system'
import * as Style from './style'
import { css } from 'glamor'
import Placeholder from '@components/placeholder'
import PropTypes from 'prop-types'

const HorizontalTable = ({ data, rows, loading, overrideStyle, maxDataColumns }) => {
  const tableData = useMemo(() => {
    if (data.length < maxDataColumns) {
      return data.concat({})
    } else {
      return data
    }
  }, [data, maxDataColumns])

  const Loader = <Placeholder loading style={Style.Placeholder}>{' '}</Placeholder>

  return (
    <Grid {...css(overrideStyle)}>
      {rows.map((row, index) => row.show !== false && <RowWithoutMargin {...css(Style.Row, Style.RowOnHover, index !== rows.length - 1 && Style.RowBorder)}>
        <Col key={row.header} sm={2} {...css(Style.Header, Style.Column)}>
          {row.Header}
        </Col>
        {tableData.map((entry, index) =>
          <Col sm={2.5} key={`${row.accessor}_${index}`} {...Style.Column}>
            { loading ? Loader : (row.Cell ? row.Cell({ value: entry[row.accessor], row: entry, index }) : entry[row.accessor])}
          </Col>)}
      </RowWithoutMargin>)}
    </Grid>
  )
}

HorizontalTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  overrideStyle: PropTypes.object,
  maxDataColumns: PropTypes.number
}

HorizontalTable.defaultProps = {
  loading: false,
  maxDataColumns: 4
}

export default HorizontalTable
