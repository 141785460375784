import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontSize, fontWeight } from '@shared/style/sizes'

export const ContentContainer = css({
  display: 'flex'
})

export const StartedText = css({
  fontWeight: fontWeight.semiBold,
  fontSize: fontSize.medium,
  color: colors.black
})

export const LogsMsg = css({
  fontWeight: fontWeight.normal,
  fontSize: fontSize.small,
  lineHeight: '16px',
  color: colors.grey1
})

export const DoneWorkflowTextArea = css({
  alignSelf: 'center',
  marginRight: 'auto',
  lineHeight: '25px'
})
