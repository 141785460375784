import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  padding: 20px 24px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const TitleFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.subtitle01};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const CloseIconButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 20px;
  height: 20px;
`
