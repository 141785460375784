import React, { Fragment } from 'react'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import ReactJson from 'react-json-view'

const CSS = {
  result: css({
    textTransform: 'capitalize'
  }),
  row: css({
    minHeight: '32px',
    alignContent: 'center'
  })
}

class DetailsDisplay extends React.Component {
  state = {
    showDetails: {
      headers: false,
      body: false
    }
  }

  showMore = (key) => {
    this.setState(prevState => ({ showDetails: { ...prevState.showDetails, [key]: true } }))
  }

  showLess = (key) => {
    this.setState(prevState => ({ showDetails: { ...prevState.showDetails, [key]: false } }))
  }

  displayHttpResponse = (responseDetails, showAll, forceShowKeys) => {
    return Object
      .keys(responseDetails)
      .map(key =>
        <div key={key} {...CSS.row}>
          <strong {...CSS.result}>{key}</strong>: {this.mapHttpResponseKeys(key, responseDetails, showAll || forceShowKeys.includes(key))}
        </div>
      )
  }
  formatValue = (value = '') => {
    switch (typeof value) {
      case 'boolean':
        return value.toString()
      case 'object':
        return JSON.stringify(value)
      default:
        return value
    }
  }

  displaydetailsInSeparateLines = (details = {}) => {
    return Object
      .keys(details)
      .map(key =>
        <Fragment key={key}>
          <br />
          <span>
            <strong {...CSS.result}>{key}:</strong> <br />{this.formatValue(details[key])}
          </span>
          <br />
        </Fragment>
      )
  }

  displayValue = (key, value) => {
    const { displayDetailsInSeparateLinesKeys } = this.props
    if (displayDetailsInSeparateLinesKeys.includes(key)) {
      return this.displaydetailsInSeparateLines(value)
    }

    const valueType = typeof value
    return <>{valueType === 'string' ? value : valueType === 'object' ? <ReactJson name={false} displayDataTypes={false} displayObjectSize={false} collapsed src={value} /> : JSON.stringify(value)}<br /></>
  }

  mapHttpResponseKeys = (key, responseDetails, forceShow) => {
    return this.state.showDetails[key] || forceShow
      ? <span>
        {this.displayValue(key, responseDetails[key])}
        {!forceShow && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.showLess(key)} label='Show less' />}
      </span>
      : (<Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.showMore(key)} label='Show more' />)
  }

  render () {
    const { details, showAll, forceShowKeys } = this.props

    return this.displayHttpResponse(details, showAll, forceShowKeys)
  }
}

DetailsDisplay.propTypes = {
  details: PropTypes.object,
  displayDetailsInSeparateLinesKeys: PropTypes.arrayOf(PropTypes.string),
  forceShowKeys: PropTypes.arrayOf(PropTypes.string)
}

DetailsDisplay.defaultProps = {
  details: {},
  displayDetailsInSeparateLinesKeys: [],
  forceShowKeys: ['statusCode']
}

export default DetailsDisplay
