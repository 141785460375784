import styled from 'styled-components'

export const Wrapper = styled.div`
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
`

export const EmptyState = styled.div`
  display: flex; 
  justify-content: center; 
  margin-top: 30px;
`
