import styled from 'styled-components'

export namespace Styles {
  export const PluginCategories = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  `

  export const PluginCategoriesTags = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  `
}
