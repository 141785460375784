import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

const mapStateToProps = () => {
  const query = qs.parse(window.location.search)
  const { emailForQuestions } = query

  return {
    emailForQuestions
  }
}

export default withRouter(connect(mapStateToProps, null)(View))
