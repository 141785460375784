import texts from '@shared/style/texts'
import { css } from 'glamor'

export const Main = css(texts.heading, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media(max-width: 500px)': {
    display: 'none'
  }
})
