import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

export const Name = styled.div`
    font-size: 20px;
    font-weight: 600;
`
