import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'

const CSS = {
  main: css({
    padding: '3px',
    display: 'inline-block',
    position: 'relative',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    transition: 'border-color .2s',
    '.rt-tr:hover &': {
      borderColor: colors.blue
    }
  }),
  noBorder: css({
    border: 'none'
  })
}

const NumberIcon = ({ size, noBorder, number, ...rest }) => {
  const style = css(CSS.main, { height: size }, noBorder && CSS.noBorder)

  return (
    <div {...style} {...rest}>
      +{number}
    </div>
  )
}

NumberIcon.propTypes = {
  size: PropTypes.number.isRequired,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noBorder: PropTypes.bool
}

NumberIcon.defaultProps = {
  size: 60,
  noBorder: false
}

export default NumberIcon
