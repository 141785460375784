import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Spacer } from '@toriihq/design-system'
import { useWorkflowEditorContext } from '../context'
import { useSelectedWorkflow } from '../../hooks/useSelectedWorkflow'
import { getWorkflowsEditHistoryById } from '@selectors/workflows'
import { ToolbarTitle } from './styles'
import { getWorkflowPathPrefix } from '@root/lenses/workflows'
import { getDisplayName } from '@lenses/users'
import { getFormattedDate } from '@lenses/utils'
import { ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE } from '@lenses/workflows.t'
import { capitalize, isEmpty } from 'lodash'
import BackAndForwardNavigator from '@components/BackAndForwardNavigator'

export const WorkflowAuditToolbar = (): ReactElement => {
  const workflow = useSelectedWorkflow()
  const idWorkflow = workflow.id
  const { isPreviewMode, idAudit } = useWorkflowEditorContext()

  const workflowEditHistoryById = useSelector(getWorkflowsEditHistoryById)

  const workflowEditHistoryInfo = (idAudit && workflowEditHistoryById[idAudit]) ?? {}
  const shouldRender = Boolean(isPreviewMode && !isEmpty(workflowEditHistoryInfo))
  const performedBy = workflowEditHistoryInfo?.editedBy && getDisplayName(workflowEditHistoryInfo?.editedBy)
  const date = getFormattedDate({ date: workflowEditHistoryInfo.editTime, includeTime: true })
  const label = ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE[workflowEditHistoryInfo.editType]

  return shouldRender ? <Spacer top={'space-250'}>
    <ToolbarTitle>Viewing workflow edit history</ToolbarTitle>
    <BackAndForwardNavigator
      analyticsEvent='Navigate workflow edit history'
      searchParams='?previewId='
      label={`${capitalize(label)} by ${performedBy} on ${date}`}
      linkPrefix={`/${getWorkflowPathPrefix({ workflowType: workflow.type, triggerType: null })}/${idWorkflow}`}
      backLink={workflowEditHistoryInfo?.previousIdWorkflowEditHistory}
      nextLink={workflowEditHistoryInfo?.nextIdWorkflowEditHistory}
    />
  </Spacer> : <></>
}
