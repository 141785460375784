import { createSelector } from 'reselect'
import identity from 'lodash/identity'

export const getUsersAuditLogs = createSelector(
  state => state.auditLog.userAuditLogs,
  identity
)

export const getUsersAuditLogsResources = createSelector(
  state => state.auditLog.resources,
  identity
)
