import React from 'react'
import PropTypes from 'prop-types'
import { SCOPES } from '../../../constants'
import EnableFor from '../../enableFor'
import Analytics from '@helpers/analytics'
import { Button, ButtonType, ButtonSize, Tooltip } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'

export const getDeleteButtonTooltipText = (isActionDisabled, hasUsers) => {
  let deleteBtnText = 'Delete Role'
  if (!isActionDisabled && hasUsers) {
    deleteBtnText = `Cannot delete a role that's associated with users`
  } else if (isActionDisabled) {
    deleteBtnText = 'This role is defined by Torii and cannot be deleted'
  }
  return deleteBtnText
}

export const getEditButtonTooltipText = (isActionDisabled) => {
  return isActionDisabled ? 'This role is defined by Torii and cannot be edited' : 'Edit permissions'
}
class RoleActions extends React.Component {
  state = {
    isDeletePopupOpen: false
  }

  onDeleteConfirm = async () => {
    const { idRole, name, deleteRole, idOrg } = this.props

    await deleteRole({ idOrg, idRole })

    Analytics.track('Deleted Role', {
      'Role ID': idRole,
      'Role name': name
    })

    this.toggleIsDeletePopup(false)
  }

  toggleIsDeletePopup = (isDeletePopupOpen) => {
    this.setState({ isDeletePopupOpen })
  }

  renderActionButtons = () => {
    const { usersCount, idOrg, idRole, toggleRolePopup } = this.props
    const hasUsers = usersCount > 0
    const isActionDisabled = Boolean(!idOrg)
    return (
      <div>
        {
          this.renderActionButton({
            icon: 'Eye',
            tooltipText: 'View permissions',
            enableForScopes: [SCOPES.MEMBERS_AND_ROLES_READ],
            isReadOnly: true,
            actionFunc: () => toggleRolePopup({ isOpen: true, isReadOnly: true, idRole }),
            isActionDisabled: false
          })
        }
        {
          this.renderActionButton({
            icon: 'Edit',
            tooltipText: getEditButtonTooltipText(isActionDisabled),
            actionFunc: () => toggleRolePopup({ isOpen: true, idRole }),
            isActionDisabled: isActionDisabled
          })
        }
        {
          this.renderActionButton({
            icon: 'Trash',
            tooltipText: getDeleteButtonTooltipText(isActionDisabled, hasUsers),
            actionFunc: () => this.toggleIsDeletePopup(true),
            isActionDisabled: isActionDisabled || hasUsers
          })
        }
      </div>
    )
  }

  renderActionButton = ({ icon, tooltipText, isActionDisabled = false, isReadOnly = false, enableForScopes = [SCOPES.MEMBERS_AND_ROLES_WRITE], actionFunc }) => {
    return (
      <Tooltip
        label={tooltipText}
      >
        <EnableFor scopes={enableForScopes} allowForToriiAdmin>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={isActionDisabled} onClick={actionFunc} icon={icon} />
        </EnableFor>
      </Tooltip>
    )
  }

  render () {
    const { name } = this.props
    const { isDeletePopupOpen } = this.state
    return (
      <div>
        { this.renderActionButtons() }
        <Confirmation
          isOpen={isDeletePopupOpen}
          header={`Delete ${name} role from Torii`}
          confirmText='Delete'
          declineText='Cancel'
          decline={() => this.toggleIsDeletePopup(false)}
          confirm={() => this.onDeleteConfirm()}
          close={() => this.toggleIsDeletePopup(false)}
          mainButtonType={ButtonType.destructive}
        />
      </div>
    )
  }
}

RoleActions.propTypes = {
  idRole: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  usersCount: PropTypes.number.isRequired
}

export default RoleActions
