import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { H3, Body1, Spacer, Button, ButtonType } from '@toriihq/design-system'

const CSS = {
  header: css({
    display: 'flex',
    margin: '30px 0'
  }),
  titleSection: css({
    flex: 1
  }),
  actions: css({
    display: 'flex',
    alignItems: 'center'
  })
}

class ReportHeader extends React.Component {
  render () {
    const { title, subtitle, onExportCSV } = this.props
    return (
      <header {...CSS.header}>
        <section {...CSS.titleSection}>
          <H3>{title}</H3>
          {subtitle && <Spacer top={'space-200'}><Body1 color='secondary'>{subtitle}</Body1></Spacer>}
        </section>
        <section className='hide-on-print' {...CSS.actions}>
          {onExportCSV && <Button icon='Download' type={ButtonType.secondary}
            onClick={() => {
              onExportCSV()
              return new Promise(resolve => setTimeout(resolve, 1000))
            }} label='CSV' />}
        </section>
      </header>
    )
  }
}

ReportHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  exportPdfUrl: PropTypes.string
}

export default ReportHeader
