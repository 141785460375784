import React, { Fragment, useEffect, useState } from 'react'
import * as Style from '../style'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

type ItemKey<T> = { [K in keyof T]: T[K] extends React.Key ? K : never }[keyof T]

export function ShowMore<T> ({
  items = [],
  itemKey,
  unexpandedItemsAmount,
  renderer
}: {
  items: T[],
  itemKey: ItemKey<T>,
  unexpandedItemsAmount: number,
  renderer: (item: T) => JSX.Element,
}) {
  const [isOpened, setIsOpened] = useState(false)

  const [itemsToRender, setItemsToRender] = useState<T[]>([])

  useEffect(() => {
    setIsOpened(false)
  }, [items])

  useEffect(() => {
    if (isOpened) {
      setItemsToRender(items)
    } else {
      setItemsToRender(items.slice(0, unexpandedItemsAmount))
    }
  }, [isOpened, items, unexpandedItemsAmount])

  const shouldDisplayControls = items.length > unexpandedItemsAmount

  const onToggleShowMore = () => {
    setIsOpened(prevIsOpened => !prevIsOpened)
  }

  return (
    <Fragment>
      {itemsToRender.map(item => <Fragment key={item[itemKey] as unknown as React.Key}>{renderer(item)}</Fragment>)}
      {shouldDisplayControls &&
        <Fragment>
          <Style.Line>
            <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onToggleShowMore} label={isOpened ? 'Show less' : `Show more +${items.length - unexpandedItemsAmount}`} />
          </Style.Line>
        </Fragment>}
    </Fragment>
  )
}
