import React, { ReactElement } from 'react'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { getNavigationBackData } from '@pages/workflow_v2/workflowEditor/utils/getNavigationBackData'
import BackLink from '@components/backLink'

export const BackButton = (): ReactElement => {
  const workflow = useSelectedWorkflow()
  const { path, title } = getNavigationBackData(workflow.type, workflow.triggerType)

  return <BackLink linkText={`${title}`} navigateTo={`/${path}`} />
}
