import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { fontSize } from '../../shared/style/sizes'
import CheckButton from '../checkButton'
import colors from '../../shared/style/colors'
import { Button, ButtonType, ButtonSize, Icon } from '@toriihq/design-system'

const maxHeight = 400
const buttonsHeight = 40

const CSS = {
  child: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '35px',
    marginBottom: '5px',
    cursor: 'pointer'
  }),
  parent: css({
    display: 'inline'
  }),
  parentContainer: css({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '32px'
  }),
  checkbox: css({
    display: 'inline-block',
    marginRight: '8px'
  }),
  root: css({
    fontSize: fontSize.small,
    color: colors.darkText,
    maxHeight: `${maxHeight}px`,
    minWidth: '200px'
  }),
  buttons: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '16px'
  }),
  treeContainer: css({
    maxHeight: `${maxHeight - buttonsHeight - 20}px`,
    overflow: 'auto',
    borderBottom: `1px solid ${colors.border}`
  }),
  labelContainer: css({
    position: 'relative',
    left: '20px'
  }),
  onlyThisButton: css({
    opacity: 0,
    padding: '0 0 0 10px !important'
  }),
  showOnlyThisButtonOnHover: css({
    ':hover .onlyThisButton': {
      opacity: 1,
      display: 'inline'
    },
    display: 'flex',
    alignItems: 'center'
  }),
  expanderButton: css({
    all: 'unset',
    ' > *': {
      transition: 'transform .2s'
    }
  }),
  expanded: css({
    ' > *': {
      transform: 'rotate(90deg)'
    }
  })
}

class SelectTree extends React.Component {
  constructor (props) {
    super(props)

    const { data } = props
    const collapsed = data.reduce((acc, parent) => {
      acc[parent.id] = true
      return acc
    }, {})

    this.state = {
      collapsed
    }
  }

  onChildChange = (id, value) => {
    const { onChange } = this.props
    onChange([id], value)
  }

  onParentChange = (parent, value) => {
    const { onChange } = this.props
    const ids = parent.children.map(child => child.id)
    onChange(ids, value)
  }

  toggleAll = (enabled) => {
    const { data, onChange } = this.props
    const ids = data.reduce((ids, parent) => {
      parent.children.forEach(child => ids.push(child.id))
      return ids
    }, [])

    onChange(ids, enabled)
  }

  toggleCollapse = (id) => {
    const { collapsed } = this.state
    collapsed[id] = !collapsed[id]

    this.setState({ collapsed })
  }

  chooseOnly = (parent) => {
    const { data, onChange } = this.props
    const ids = data.reduce((ids, parent) => {
      parent.children.forEach(child => ids.push(child.id))
      return ids
    }, [])

    onChange(ids, false, () => onChange(parent.children.map(c => c.id), true))
  }

  renderParent = (parent) => {
    const { selected } = this.props

    const shouldShowChevron = parent.children.length > 1
    const isParentSelected = parent.children.every(child => selected.includes(child.id))
    const isIndeterminate = !isParentSelected && parent.children.some(child => selected.includes(child.id))
    return (
      <div {...CSS.parentContainer}>
        {shouldShowChevron && <button {...css(CSS.expanderButton, this.shouldShowChildren(parent) && CSS.expanded)} onClick={() => this.toggleCollapse(parent.id)}><Icon name='ChevronRight' /></button>}
        <div {...css(CSS.showOnlyThisButtonOnHover, !shouldShowChevron && CSS.labelContainer)}>
          <div {...CSS.checkbox}><CheckButton id={parent.id} isChecked={isParentSelected} indeterminate={isIndeterminate} onChange={(id, value) => this.onParentChange(parent, value)} /></div>
          <div {...CSS.parent} onClick={() => this.onParentChange(parent, !isParentSelected)}>{parent.label} </div>
          <div {...CSS.onlyThisButton} className='onlyThisButton'><Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.chooseOnly(parent)} label='Only this' /></div>
        </div>
      </div>
    )
  }

  renderChild = (child) => {
    const { selected } = this.props
    const isChildSelected = selected.includes(child.id)

    return (
      <div key={child.id} {...CSS.child} >
        <div {...CSS.checkbox}><CheckButton id={child.id} isChecked={isChildSelected} onChange={this.onChildChange} /></div>
        <div onClick={() => this.onChildChange(child.id, !isChildSelected)}>{child.label}</div>
      </div>
    )
  }

  shouldShowChildren = (parent) => {
    const { collapsed } = this.state

    return !collapsed[parent.id] && parent.children.length > 1
  }

  render () {
    const { data } = this.props
    const sortData = (data) => data.sort((a, b) => a.label.localeCompare(b.label))

    return <div {...CSS.root}>
      <div {...CSS.treeContainer}>
        <div>
          {
            sortData(data).map(parent => (
              <div key={parent.id} >
                {this.renderParent(parent)}
                {this.shouldShowChildren(parent) && sortData(parent.children).map(this.renderChild)}
              </div>
            ))
          }
        </div>
      </div>
      <div {...CSS.buttons}>
        <Button type={ButtonType.secondary} onClick={() => this.toggleAll(true)} label='Select all' />
        <Button type={ButtonType.secondary} onClick={() => this.toggleAll(false)} label='Hide all' />
      </div>
    </div>
  }
}

SelectTree.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    }))
  })),
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onChange: PropTypes.func
}

export default SelectTree
