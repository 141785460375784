import send from '@shared/redux-fetch'
import {
  GET_APP_USER_LICENSES_FIELD_VALUES,
  GET_APP_LICENSE_RECOMMENDATION_USERS,
  GET_RECOMMENDATION,
  GET_RECOMMENDATIONS,
  GET_RECOMMENDATION_APPS_ACCOUNTS,
  DEFAULT_API_PAGE_LIMIT
} from '@root/constants'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getAppUserLicenseFieldValues = ({ idOrg, idApp, fields }) => {
  return send(GET_APP_USER_LICENSES_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/recommendations/fieldValues/${idApp}`,
    query: omitBy({
      qFields: fields
    }, isUndefined),
    meta: {
      idApp
    }
  })
}

export const getAppLicenseRecommendationUserList = ({ idOrg, idApp, idRecommendation, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, fields, reset, filters = [] }) => {
  return send(GET_APP_LICENSE_RECOMMENDATION_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/recommendations/${idRecommendation}/users`,
    query: omitBy({
      limit,
      offset,
      q,
      sort,
      fields,
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      idApp,
      reset
    }
  })
}

export const getRecommendations = ({ idOrg, idApp, idAppAccount, includeAppsInPreview }) => {
  return send(GET_RECOMMENDATIONS, {
    url: `/api/orgs/${idOrg}/recommendations`,
    query: omitBy({
      idAppAccount,
      idApp,
      includeAppsInPreview
    }, isUndefined)
  })
}

export const getRecommendation = ({ idOrg, idRecommendation, includeAppsInPreview }) => {
  return send(GET_RECOMMENDATION, {
    url: `/api/orgs/${idOrg}/recommendations/${idRecommendation}`,
    query: omitBy({
      includeAppsInPreview
    }, isUndefined)
  })
}

export const getRecommendationsAppsList = ({ idOrg, includeAppsInPreview }) => {
  return send(GET_RECOMMENDATION_APPS_ACCOUNTS, {
    url: `/api/orgs/${idOrg}/recommendations/apps`,
    query: omitBy({
      includeAppsInPreview
    }, isUndefined)
  })
}
