import type { IdWorkflowUiNode } from '../types'
import {
  IdWorkflowNode,
  WorkflowNodeModel,
  WorkflowNodes
} from '@shared/workflows/types'

interface ParentNodeInfo {
  idParentNode: IdWorkflowNode
  parentNode: WorkflowNodeModel
  branchPositionInParent?: number
}

const DEFAULT_BRANCH_POSITION = -1
const DEFAULT_BRANCH_ID = 'default'

export const extractParentActionNodeInfo = (
  { idParentUiNode, nodes }: { idParentUiNode : IdWorkflowUiNode, nodes: WorkflowNodes }): ParentNodeInfo => {
  const [idParentNode, branchPosition] = idParentUiNode.split('-')
  const result: ParentNodeInfo = {
    idParentNode,
    parentNode: nodes[idParentNode]!
  }

  if (branchPosition) {
    result.branchPositionInParent = branchPosition === DEFAULT_BRANCH_ID
      ? DEFAULT_BRANCH_POSITION
      : Number(branchPosition)
  }

  return result
}

export const isDefaultBranchInParent = (branchPositionInParent: number) => {
  return branchPositionInParent === DEFAULT_BRANCH_POSITION
}

export const generateDefaultBranchLabelId = ({ idBranchingNode }: { idBranchingNode: IdWorkflowNode }): IdWorkflowUiNode => (
  `${idBranchingNode}-${DEFAULT_BRANCH_ID}`
)

export const generatePositionedBranchLabelId = ({ idBranchingNode, branchIndex }: { idBranchingNode: IdWorkflowNode, branchIndex: number }): IdWorkflowUiNode => (
  `${idBranchingNode}-${branchIndex}`
)
