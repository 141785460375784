import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../shared/style/colors'
import { Tooltip } from '@toriihq/design-system'
import findLastIndex from 'lodash/findLastIndex'
import pluralize from 'pluralize'
import { fontWeight } from '../../shared/style/sizes'
import { css } from 'glamor'
import { getFormattedDate } from '@lenses/utils'

const CSS = {
  tooltip: css({
    textAlign: 'center',
    maxWidth: '320px'
  }),
  date: css({
    fontWeight: fontWeight.semiBold
  })
}
const findStep = (steps = [], score = 0) => {
  return findLastIndex(steps, stepValue => (score > stepValue)) + 1
}

const ScoreBars = ({ score, steps, legend, width, initialHeight, diffHeight, margin, color, tooltipText, lastVisitTime, showLastVisit }) => {
  let tooltipHtml
  if (legend) {
    tooltipHtml = legend[findStep(steps, score)]
  } else {
    const daysInUse = score ? `Used ${score} ${pluralize('day', score)} ${tooltipText}` : 'No usage'
    const daysInUseTooltipText = `${daysInUse} during the last 30 days`
    const lastUsedTimeTooltipText = lastVisitTime && showLastVisit ? <span>Last used: <span {...CSS.date}>{getFormattedDate({ date: lastVisitTime })}</span></span> : ''
    tooltipHtml = <div {...CSS.tooltip}>{daysInUseTooltipText}<br />{lastUsedTimeTooltipText}</div>
  }

  return <Tooltip
    label={tooltipHtml}
  >
    <span>
      {steps.map((stepValue, i) => (
        <div
          style={{
            display: 'inline-block',
            marginRight: `${margin}px`,
            background: score > stepValue ? color : colors.grey3,
            width: `${width}px`,
            height: `${initialHeight + i * diffHeight}px`
          }}
          key={i}
        />
      ))}
    </span>
  </Tooltip>
}

ScoreBars.propTypes = {
  score: PropTypes.number,
  steps: PropTypes.array,
  width: PropTypes.number,
  initialHeight: PropTypes.number,
  diffHeight: PropTypes.number,
  margin: PropTypes.number,
  color: PropTypes.string,
  legend: PropTypes.array,
  tooltipText: PropTypes.string,
  showLastVisit: PropTypes.bool
}

ScoreBars.defaultProps = {
  steps: [0, 4, 9],
  width: 4,
  initialHeight: 6,
  diffHeight: 2,
  margin: 2,
  color: colors.lightBlue4,
  tooltipText: '',
  showLastVisit: true
}

export default ScoreBars
