import styled from 'styled-components'

export const Filters = styled.div`
  align-self: flex-start;
  height: 600px;
  border-right: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
`

export const Header = styled.div`
  margin-bottom: 20px;
`
export const Title = styled.div``
