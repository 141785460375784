import omit from 'lodash/omit'
import {
  IdWorkflowNode,
  WORKFLOW_NODE_TYPE,
  WorkflowActionsModel
} from '@shared/workflows/types'
import { updateNextNodeInWorkflowActions } from './updateNextNodeInWorkflowActions'

export const deleteActionNode = (
  idNode: IdWorkflowNode,
  actions: WorkflowActionsModel
): WorkflowActionsModel => {
  const { nodes } = actions
  const nodeToDelete = nodes[idNode]

  if (nodeToDelete.type !== WORKFLOW_NODE_TYPE.ACTION) {
    throw new Error('deleteActionNode supports only `action` nodes')
  }

  const updatedWorkflowActions = updateNextNodeInWorkflowActions({
    idCurrentNextNode: idNode,
    idNewNextNode: nodeToDelete.next,
    workflowActions: actions
  })

  const newNodes = omit(updatedWorkflowActions.nodes, idNode)

  return {
    ...updatedWorkflowActions,
    nodes: newNodes
  }
}
