import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const Img = styled.img`
  flex: 0 0 120px;
  object-fit: contain;
`
