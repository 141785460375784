import React from 'react'
import PropTypes from 'prop-types'
import Services from '../../components/services'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import TableSearch from '../../components/table/tableSearch'
import Analytics from '../../helpers/analytics'
import debounce from 'lodash/debounce'
import { Button, ButtonType, ButtonSize, Stack } from '@toriihq/design-system'

class ServicesPage extends React.Component {
  state = {
    search: null
  }

  onSearch = (e) => {
    const { value } = e.currentTarget
    this.setState({
      search: value
    })
    this.onSearchStopped()
  }

  onSearchStopped = debounce(() => {
    const { search } = this.state
    if (!search) {
      return
    }

    Analytics.track('Search Table', {
      'Table': 'Services',
      'Term': search
    })
  }, 1000)

  onIntegrationCapabilitiesClick () {
    const { idOrg, history } = this.props
    history.push(`/team/${idOrg}/services/capabilities`)

    Analytics.track('Click to go to integration capabilities table')
  }

  render () {
    const { search } = this.state

    const contentRight = (
      <Stack direction='row' gap='space-200' alignItems='center'>
        <Button onClick={() => this.onIntegrationCapabilitiesClick()} label='Integration Capabilities' type={ButtonType.secondary} size={ButtonSize.small} />
        <TableSearch onSearch={this.onSearch} />
      </Stack>
    )

    return (
      <Page title='Integrations'>
        <PageHeader
          title='Integrations'
          contentRight={contentRight}
        />
        <Services search={search} />
      </Page>
    )
  }
}

ServicesPage.propTypes = {
  isSmallScreen: PropTypes.bool
}

export default ServicesPage
