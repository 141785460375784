import { BRANCH_FILTER_ENTITY, BranchFilter } from '@shared/workflows/types'

export const changeBranchEntity = (branchFilter: BranchFilter, newEntity: BRANCH_FILTER_ENTITY): BranchFilter => {
  const MAP_ENTITY_TO_FILTER_RESET = {
    [BRANCH_FILTER_ENTITY.APP]: { id: Date.now() },
    [BRANCH_FILTER_ENTITY.CONTRACT]: { id: Date.now() },
    [BRANCH_FILTER_ENTITY.USER]: { filters: { id: Date.now() } }
  }

  const updatedBranchFilter = {
    ...branchFilter,
    entity: newEntity,
    filter: MAP_ENTITY_TO_FILTER_RESET[newEntity],
    isValid: true,
    errorMsg: ''
  }
  return updatedBranchFilter as BranchFilter
}
