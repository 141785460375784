import { WorkflowActionModel } from '@shared/workflows/types'

const NEW_EMPTY_ACTION = {
  isValid: false,
  fields: [],
  type: null,
  errorMsg: ''
}

const generateIdAction = () => new Date().getTime()

export const getNewEmptyAction = (): WorkflowActionModel => ({
  id: generateIdAction(),
  ...NEW_EMPTY_ACTION
})
