import React from 'react'
import UserDetails from '../userDetails'
import { getDisplayName } from '../../lenses/users'
import RelativeTeamLink from '../relativeTeamLink'
import { Link } from '@toriihq/design-system'

export const getNameColumn = (style = {}) => ({
  Header: 'Name',
  id: 'fullName',
  accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
  minWidth: 186,
  maxWidth: 350,
  Cell: ({ row: { firstName, lastName, idUser, id, email, isExternal, photoUrl } }) => (
    <UserDetails
      firstName={firstName}
      lastName={lastName}
      email={email}
      isExternal={isExternal}
      idUser={idUser || id}
      showExternalUserBadge={false}
      photoUrl={photoUrl}
    />
  ),
  textValue: ({ firstName, lastName, email }) => `${getDisplayName({ firstName, lastName, email, emptyValue: '' })}`,
  style
})

export const getStartedByColumns = () => ([
  {
    Header: 'Started By',
    accessor: 'offboardingStartedByUser',
    Cell: ({ row }) => {
      if (row.offboardingStartedByUser) {
        return <UserDetails linkToUserPage idUser={row.offboardingStartedByUser.id} {...row.offboardingStartedByUser} />
      }

      if (row.offboardingStartedByWorkflowAction) {
        return (
          <RelativeTeamLink
            nav
            to={`/workflow/${row.offboardingStartedByWorkflowAction.idWorkflow}/executions/${row.offboardingStartedByWorkflowAction.idWorkflowExecution}`}>
            <Link>Workflow-{row.offboardingStartedByWorkflowAction.idWorkflow}</Link>
          </RelativeTeamLink>
        )
      }

      return '-'
    },
    minWidth: 186,
    sortable: true
  },
  {
    accessor: 'offboardingStartedByWorkflowAction',
    show: false
  }
])
