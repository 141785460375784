import { FixedBranchesFieldValue, ActionWithFixedBranchesNodeModel, BranchBase } from '@shared/workflows/types'
import { WORKFLOW_FIELD_TYPE } from '@shared/workflows/actions/types'

interface BranchesData {
  branches: BranchBase[]
  defaultBranch: BranchBase
}

export const getFixedBranchesData = (
  actionWithFixedBranchesNodeModel: ActionWithFixedBranchesNodeModel
): BranchesData => {
  const fixedBranches = actionWithFixedBranchesNodeModel.action.fields
    .find(field => field.id === WORKFLOW_FIELD_TYPE.FIXED_BRANCHES)!.value as FixedBranchesFieldValue

  const {
    default: defaultBranch,
    branches
  } = fixedBranches

  return {
    defaultBranch,
    branches
  }
}
