import styled from 'styled-components'

export const Layout = styled.div`
  min-width: 320px;
  display: grid;
  grid-template-areas: 'header' 'content';
  grid-template-rows: auto 1fr;
  row-gap: 16px;
  border: ${({ theme }) => `1px solid ${theme.palette.border.secondary}`}; 
  border-radius: 4px;
  padding: 16px 24px;
`

export const Content = styled.div`
  grid-area: content;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  
  :last-child {
    margin-bottom: 0;
  }
`

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font: ${({ theme }) => theme.typography.font.body02};
`

export const Value = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.component03};
  
  a {
    color: ${({ theme }) => theme.palette.link.primary};
    font: ${({ theme }) => theme.typography.font.component03};
  }
`

export const Header = styled.div`
  grid-area: header;
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.subtitle01};
`

export const Link = styled.a`
  color: ${({ theme }) => theme.palette.link.primary} !important;
  font: ${({ theme }) => theme.typography.font.component03} !important;
`
