import View from './view'
import { connect } from 'react-redux'
import { getUsedApps, isAppsLoading } from '@selectors/apps'
import { getAppsOfUserES, isLoadingAppUsers } from '@selectors/appUsers'
import { SCOPES } from '../../constants'
import { APP_USER_STATUS } from '@shared/types'
import some from 'lodash/some'
import has from 'lodash/has'
import uniqBy from 'lodash/uniqBy'
import intersection from 'lodash/intersection'
import AccessControl from '@lenses/accessControl'
import { getLazyLoadedUsers } from '@selectors/users'

const mapStateToProps = (state, ownProps) => {
  const { idUser } = ownProps

  const loading = isAppsLoading(state) || isLoadingAppUsers(state)
  const apps = (getAppsOfUserES(state)[idUser] || []).filter(appUser => appUser.status === APP_USER_STATUS.ACTIVE)
  const allApps = getUsedApps(state) || []
  const userFromEs = getLazyLoadedUsers(state)[idUser]
  const allIdUsers = userFromEs?.allIdUsers
  const annualCostConverted = userFromEs?.annualCostConverted
  const additionalOwnedApps = allApps.filter(app => (allIdUsers?.includes(app.owner) || allIdUsers?.includes(parseInt(app.primaryOwner)) || intersection(app.appOwners?.map(s => s && parseInt(s)) || [], allIdUsers ?? []).length > 0) && !apps.find(appUser => appUser.idApp === app.id))
  const totalApps = apps.length + additionalOwnedApps.length
  const totalOwner = uniqBy(apps.filter(app => allIdUsers?.includes(app.owner)), 'idApp').length + additionalOwnedApps.length
  const showCostData = AccessControl.isAccessibleWithState({ scopes: [SCOPES.EXPENSE_READ], state }) && Boolean(annualCostConverted || some(apps, app => has(app, 'annualCostConverted')))

  return {
    totalApps,
    totalOwner,
    annualCostConverted,
    showCostData,
    loading: loading
  }
}

export default connect(mapStateToProps)(View)
