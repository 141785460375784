import { css } from 'glamor'
import colors from '@shared/style/colors'
import { theme } from '@toriihq/design-system'

export const CSS = {
  criteria: css({
    borderRadius: '4px',
    border: `1px solid ${colors.border}`,
    padding: '15px'
  }),
  spanError: css({
    color: theme.palette.text.error
  }),
  divWrapSelectWithError: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  outer: css({
    ' .Select-option:first-child': {
      borderBottom: `1px solid ${colors.lightBlue2}`
    }
  })
}
