import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import Headers from '../signup/headers'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@toriihq/design-system'

const LoginSAMLFailure = () : ReactElement => {
  const errorMessage = new URLSearchParams(window.location.search).get('error')
  return (
    <div>
      <Helmet>
        <title>SAML Login could not be completed</title>
      </Helmet>
      <Headers
        headerText='Login Failed'
        subHeaderText={
          <div>
            {errorMessage && (
              <>
                <p>The SAML login attempt was unsuccessful, please <RouterLink to='/'><Link>try again</Link></RouterLink> or contact your IT team.</p>
                <p>The error is: "{errorMessage}"</p>
              </>
            )}
            {!errorMessage && (
              <>
                <p>You do not have a user on Torii or the SAML login was unsuccessful.</p>
                <p><RouterLink to='/login/signup'><Link>Sign up</Link></RouterLink> to the application catalog or contact your IT team and request access to Torii.</p>
              </>
            )}
          </div>
        }
        center={false}
      />
    </div>
  )
}

export default LoginSAMLFailure
