import { Icon, Tooltip } from '@toriihq/design-system'
import React from 'react'

const optionRenderers = {
  channels: option => {
    return <div
      key={option.value}
      style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
    >
      {option.isPrivate
        ? <Tooltip label='Private channel'><Icon name='Lock' color='secondary' /></Tooltip>
        : <Icon name='Hash' color='secondary' />
      }
      <span>{option.label}</span>
    </div>
  }
}

export default optionRenderers
