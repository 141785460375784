import React from 'react'
import texts from '../../shared/style/texts'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import EnableFor from '../enableFor'
import { Button, ButtonType } from '@toriihq/design-system'
import * as Styles from './styles'

const CSS = {
  main: css(texts.headers.large, {
    padding: '30px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 768px)': {
      alignItems: 'stretch',
      flexDirection: 'column'
    }
  }),
  left: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '@media(max-width: 500px)': {
      display: 'block'
    }
  }),
  right: css({
    display: 'flex',
    alignSelf: 'flex-start'
  }),
  title: css({
    '@media(max-width: 500px)': {
      marginRight: 0,
      marginBottom: '15px'
    }
  }),
  fullWidthTitle: css({
    flex: 1
  }),
  topContent: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }),
  subTitle: css(texts.subTitle),
  secondary: css({
    padding: '0'
  })
}

const PageHeader = ({ title, contentBottom, contentTop, contentBelow, content, contentRight, menuButtonText, menuButtonOnClick, isMenuButtonDisabled, menuButtonOutline, allowedScopes = [], allowForToriiAdmin = false, iconName, isSecondary = false, overrideStyle }) => {
  const buttonIconProp = iconName ? { icon: iconName } : {}

  return <>
    <div data-component='PageHeader' {...css(CSS.main, isSecondary && CSS.secondary)} {...overrideStyle}>
      <div {...CSS.left}>
        <div {...CSS.topContent}>
          {contentTop && <div>{contentTop}</div>}
          {isSecondary
            ? <div {...css(CSS.subTitle, !content && CSS.fullWidthTitle)}>{title}</div>
            : <div {...css(CSS.title, !content && CSS.fullWidthTitle)}><Styles.Title>{title}</Styles.Title></div>
          }
          {contentBottom && <div>{contentBottom}</div>}
        </div>
        {content && <div>{content}</div>}
      </div>
      <div {...CSS.right}>
        {contentRight}
        {menuButtonText && (
          <EnableFor scopes={allowedScopes} allowForToriiAdmin={allowForToriiAdmin}>
            <Button {...buttonIconProp} type={menuButtonOutline ? ButtonType.secondary : ButtonType.primary} onClick={menuButtonOnClick} disabled={isMenuButtonDisabled} label={menuButtonText} />
          </EnableFor>
        )}
      </div>
    </div>
    {contentBelow && <>{contentBelow}</>}
  </>
}

PageHeader.propTypes = {
  title: PropTypes.node,
  menuButtonText: PropTypes.node,
  menuButtonOnClick: PropTypes.func,
  isMenuButtonDisabled: PropTypes.bool,
  menuButtonOutline: PropTypes.bool,
  content: PropTypes.node,
  contentRight: PropTypes.node,
  contentTop: PropTypes.node,
  contentBottom: PropTypes.node,
  contentBelow: PropTypes.node,
  iconName: PropTypes.string,
  isSecondary: PropTypes.bool,
  allowedScopes: PropTypes.arrayOf(PropTypes.string),
  allowForToriiAdmin: PropTypes.bool,
  overrideStyle: PropTypes.object
}

PageHeader.defaultProps = {
  isMenuButtonDisabled: false,
  menuButtonOutline: true,
  iconName: null,
  isSecondary: false
}

export default PageHeader
