export const parseCron = (
  cron: string
): { hour: string, dayOfWeek: string } => {
  const cronParts = cron.split(' ')
  if (cronParts.length !== 5) {
    throw new Error(`Invalid cron: ${cron}`)
  }
  return {
    hour: cronParts[1],
    dayOfWeek: cronParts[4]
  }
}

export const toCron = (
  { minute, hour, dayOfWeek }:
  { minute: string, hour: string; dayOfWeek: string }
): string => {
  return `${minute} ${hour} * * ${dayOfWeek}`
}
