import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
  const idApp = parseInt(ownProps.match.params.idApp, 10)

  return {
    idApp
  }
}

export default withRouter(connect(mapStateToProps)(View))
