import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import defer from 'lodash/defer'
import { Tooltip } from '@toriihq/design-system'

const CSS = {
  bar: css({
    position: 'relative',
    display: 'flex',
    verticalAlign: 'middle',
    overflow: 'hidden',
    borderRadius: '2px',
    margin: '10px 0 10px 5px',
    width: '100%',
    transition: 'width .3s ease-in-out, min-width .3s ease-in-out',

    '& > span': {
      width: '100%'
    }
  }),
  progress: css({
    width: '100%'
  })
}

class AnimatedBar extends React.Component {
  state = { rendered: false }

  componentDidMount () {
    defer(this.initialAnimation)
  }

  initialAnimation = () => {
    this.setState({ rendered: true })
  }

  render () {
    const { rendered } = this.state
    const { percentageWidth, minBarWidth, barColor, barHeight, amount, totalAmount, tooltipHtml } = this.props
    const calculatedBarWidth = (amount / totalAmount) * percentageWidth
    const heightCSS = { height: `${barHeight}px` }
    const colorCSS = { backgroundColor: barColor }
    const bar = <div {...css(CSS.progress, colorCSS, heightCSS)} />
    const barComponent =
      <Tooltip
        label={tooltipHtml}
        hide={!tooltipHtml}
      >
        {bar}
      </Tooltip>

    return (
      <div {...css(CSS.bar)} style={{ width: `${rendered ? calculatedBarWidth : 0}%`, minWidth: rendered ? `${minBarWidth}px` : 0 }}>
        {barComponent}
      </div>
    )
  }
}

AnimatedBar.propTypes = {
  amount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  percentageWidth: PropTypes.number.isRequired,
  minBarWidth: PropTypes.number,
  barHeight: PropTypes.number,
  tooltipHtml: PropTypes.node,
  barColor: PropTypes.string
}

AnimatedBar.defaultProps = {
  percentageWidth: 100,
  minBarWidth: 50,
  barColor: colors.lightBlue4,
  barHeight: 16
}

export default AnimatedBar
