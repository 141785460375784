import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import paperAirplaneImage from './images/paperAirplane.svg'
import UsersIconsList from '../../usersIconsList'

const CSS = {
  popup: {
    modal: { width: '473px' }
  },
  contentArea: css({
    width: '100%'
  }),
  container: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }),
  leftSide: css({
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column'
  }),
  rightSide: css({
    maxWidth: '64px',
    display: 'flex',
    flex: 1,
    flexShrink: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }),
  employeesCount: css(texts.body, {
    lineHeight: '18px',
    color: colors.black
  }),
  employeeList: css({
    width: '370px',
    margin: '10px 0px -5px -80px'
  })
}

class SentEmailsPopup extends React.Component {
  viewLog = () => {
    const { history, idWorkflow, idOrg, close } = this.props
    history.push(`/team/${idOrg}/workflow/${idWorkflow}/executions`)
    close()
  }

  render () {
    const { cancel, isOpen, usersCount, sampleUsers } = this.props

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={CSS.popup}>
        <ToriiPopup.Header header={'Emails Are On Their Way'} />
        <ToriiPopup.Content contentAreaStyle={CSS.contentArea}>
          <div {...CSS.container}>
            <div {...CSS.leftSide}>
              <div {...CSS.employeesCount}>Emails sent to {pluralize('employee', usersCount, true)}</div>
              <div {...CSS.employeeList}><UsersIconsList center users={sampleUsers} maxUsers={5} totalUsersCount={usersCount} /></div>
            </div>
            <div {...CSS.rightSide}>
              <img width={'64px'} height={'55px'} alt='Emails Are On Their Way' src={paperAirplaneImage} />
            </div>
          </div>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          cancelButtonText={'Cancel'}
          mainButtonText={'View log'}
          mainButtonAction={this.viewLog}
        />
      </ToriiPopup>
    )
  }
}

SentEmailsPopup.propTypes = {
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  idOrg: PropTypes.number.isRequired,
  usersCount: PropTypes.number,
  sampleUsers: PropTypes.array
}

export default SentEmailsPopup
