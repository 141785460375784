import { useSelector } from 'react-redux'
import { getSupportedFeatures } from '@selectors/org'

export const isFeatureEnabled = (state, feature) => {
  const features = getSupportedFeatures(state)
  return features[feature]
}

export const useSupportedFeatures = (feature) => {
  const features = useSelector(getSupportedFeatures)
  return features[feature]
}
