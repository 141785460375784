const mergePropsWithHandlers = mapPropsToHandlers => (stateProps, dispatchProps, ownProps) => {
  const props = {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }

  const handlers = (mapPropsToHandlers && mapPropsToHandlers(props)) || {}

  return {
    ...stateProps,
    ...dispatchProps,
    ...handlers,
    ...ownProps
  }
}

export default mergePropsWithHandlers
