import View from './view'
import { connect } from 'react-redux'
import { getShowHiddenWorkflows, getUnsafeMode } from '@selectors/ui'
import { toggleShowHiddenWorkflows, toggleUnsafeMode } from '@actions/'
import { isToriiAdmin } from '@selectors/me'
import { getOrgs, getToriiAdminScopesById } from '@selectors/orgs'
import { EMPTY_ARRAY } from '@root/constants'

const mapStateToProps = (state) => {
  const showActions = isToriiAdmin(state)
  const unsafeMode = getUnsafeMode(state)
  const idAudit = parseInt(new URLSearchParams(window.location.search).get('previewId'))

  const isWorkflowPage = window.location.pathname.includes('workflow')
  const showHiddenWorkflows = getShowHiddenWorkflows(state)

  const allOrgs = getOrgs(state)
  const toriiAdminScopesById = getToriiAdminScopesById(state)
  const idToriiAdminScopes = allOrgs.find(org => org.id === state.org.id)?.idToriiAdminScopes ?? EMPTY_ARRAY
  const toriiAdminScopes = idToriiAdminScopes.map(id => toriiAdminScopesById[id].systemKey)

  return {
    isSmallScreen: state.ui.isSmallScreen,
    companyName: state.org.companyName,
    unsafeMode,
    isWorkflowPage,
    showHiddenWorkflows,
    showActions,
    isPreviewMode: Boolean(idAudit),
    toriiAdminScopes
  }
}

const mapDispatchToProps = {
  toggleShowHiddenWorkflows,
  toggleUnsafeMode
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
