import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Table from '../table'
import { TABLES, SCOPES, LICENSES_CUSTOM_SELECT_OPTIONS } from '../../constants'
import Analytics from '../../helpers/analytics'
import colors from '../../shared/style/colors'
import emptyImage from './images/licenses.svg'
import { theme, Button, ButtonType, EmptyState } from '@toriihq/design-system'
import InactiveUsersPopup from '../popups/inactiveUsersPopup'
import EnableFor from '@components/enableFor'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'
import { getColumns } from './columns'

class Licenses extends React.Component {
  state = { isOpen: false, selectedIdApp: null, selectedIdLicense: null, selectedColumn: null, expanded: {}, displayBenchmark: false }

  constructor (props) {
    super(props)
    this.getAllColumns()
  }

  componentDidMount () {
    const { getLicensesTypes, deprecatedGetApps, getSupportedFeatures, idOrg, displayBenchmark } = this.props
    deprecatedGetApps({ idOrg })
    getLicensesTypes({ idOrg })
    getSupportedFeatures({ idOrg })
    this.setState({ displayBenchmark: displayBenchmark }, () => this.getAllColumns())
  }

  componentDidUpdate (prevProps) {
    if (this.props.displayBenchmark !== prevProps.displayBenchmark) {
      this.setState({ displayBenchmark: this.props.displayBenchmark }, () => this.getAllColumns())
    }
  }

  getAllColumns () {
    const { idOrg, hasLicensesCostAndChargebackAccess } = this.props
    const { displayBenchmark } = this.state
    this.appsColumns = getColumns({ idOrg, isApps: true, hasLicensesCostAndChargebackAccess, displayBenchmark, togglePopup: this.togglePopup, toggleEditLicensePopup: this.toggleEditLicensePopup })
    this.licensesColumns = getColumns({ idOrg, isApps: false, hasLicensesCostAndChargebackAccess, displayBenchmark, togglePopup: this.togglePopup, toggleEditLicensePopup: this.toggleEditLicensePopup })
  }

  updateLicensePrice = (idLicense, pricePerUser, idApp) => {
    const { idOrg, updateLicense } = this.props

    updateLicense({ idOrg, idLicense, pricePerUser, idApp })
  }

  renderNoServices = () => {
    const onClick = () => {
      Analytics.track('Click to connect service providers', {
        from: 'From licenses page'
      })
      this.onConnectClick()
    }
    return <EmptyState
      image={<img src={emptyImage} alt='No licenses' />}
      title='Manage your licenses'
      buttons={[
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} allowForToriiAdmin>
          <Button type={ButtonType.primary} onClick={onClick} label='Connect service providers' />
        </EnableFor>
      ]}
    />
  }

  onConnectClick = () => {
    const { idOrg, history } = this.props
    history.push(`/team/${idOrg}/services/all`)

    Analytics.track('Clicked to go to services', {
      from: 'Empty state licenses list'
    })
  }

  togglePopup = ({ selectedIdApp = this.state.selectedIdApp, selectedAppName = this.state.selectedAppName, selectedIdLicense = this.state.selectedIdLicense, savingsConverted, inactiveAmount }) => {
    const nextPopUpState = !this.state.isOpen
    this.setState({ isOpen: nextPopUpState, selectedIdApp, selectedAppName, selectedIdLicense: selectedIdLicense || undefined, savingsConverted, inactiveAmount })
    Analytics.track(`Clicked ${nextPopUpState ? 'show' : 'hide'} application wasted users`, {
      'App name': selectedAppName,
      'App id': selectedIdApp
    })
  }

  closePopup = () => {
    const nextPopUpState = !this.state.isOpen
    this.setState({ isOpen: nextPopUpState, selectedIdLicense: undefined })
    Analytics.track(`Clicked ${nextPopUpState ? 'show' : 'hide'} application wasted users`, {
      'App name': this.selectedAppName,
      'App id': this.state.selectedIdApp
    })
  }

  toggleEditLicensePopup = ({ selectedIdApp = this.state.selectedIdApp, selectedIdLicense = this.state.selectedIdLicense, selectedAppName = this.state.selectedAppName, selectedColumn = this.state.selectedColumn } = { selectedIdApp: null, selectedIdLicense: null, selectedAppName: null, selectedColumn: null }) => {
    const { licenses, toggleLicenseEditPopupDispatch } = this.props
    const selectedLicensesToEdit = licenses.find(license => license.idApp === selectedIdApp)?.licenseTypes

    const nextPopUpState = true
    toggleLicenseEditPopupDispatch({ isOpen: nextPopUpState, idApp: selectedIdApp, idLicense: selectedIdLicense, appName: selectedAppName, licenses: selectedLicensesToEdit, selectedColumn })
  }

  onColumnResize = (newResized) => {
    const { id, value } = newResized[0]
    this.setState({ [id]: value })
  }

  setSubTableTrGroupStyle = () => {
    return {
      className: css({
        '&:last-child': {
          borderBottom: 'none !important'
        }
      }).toString(),
      style: {
        borderBottom: `1px solid ${colors.grey3}`
      }
    }
  }

  expandRow (rowInfo) {
    const { row } = rowInfo
    const expanded = { ...this.state.expanded }
    expanded[row._viewIndex] = !this.state.expanded[row._viewIndex]
    this.setState({ expanded })
  }

  render () {
    const { licenses, view, loading } = this.props
    const { isOpen } = this.state
    const PADDING = 5
    const TheadComponent = () => null

    const columns = this.licensesColumns
      .map(column => {
        const id = column.id
        const hasWidth = this.state[id]
        return {
          ...column,
          ...(hasWidth ? { width: this.state[id] } : {})
        }
      })
    columns.unshift({
      width: 35
    })

    const setTdStyle = () => {
      return {
        style: {
          border: 0,
          padding: `10px ${PADDING}px`,
          color: colors.black
        }
      }
    }

    const injectTheadThSortOrder = (table, _, column) => {
      const sortOrder = table.sorted.find(sorted => sorted.id === column.id)
      return {
        style: {
          textAlign: 'left',
          display: 'flex',
          border: 0,
          padding: `14px ${PADDING}px`,
          color: sortOrder && colors.darkText,
          boxShadow: 'none',
          alignItems: 'center',
          ...column.style
        },
        sortOrder: sortOrder && (sortOrder.desc ? 'desc' : 'asc')
      }
    }

    return (
      <Fragment>
        <Table
          draggable={false}
          collapseOnDataChange={false}
          tableKey={TABLES.licensesTable.key}
          data={licenses}
          columns={this.appsColumns}
          emptyStateMessage={this.renderNoServices()}
          loading={loading}
          overrideTrStyle={{ minHeight: '60px', cursor: 'pointer' }}
          onResizedChange={this.onColumnResize}
          getTdProps={setTdStyle}
          getTheadThProps={injectTheadThSortOrder}
          tableHeaderStyle={{ padding: 0 }}
          expanded={this.state.expanded}
          onTrClick={(e) => this.expandRow(e)}
          customSelect
          customSelectOptions={Object.values(LICENSES_CUSTOM_SELECT_OPTIONS)}
          customSelectSelectedValue={view}
          customSelectOnChange={() => undefined}
          extraHeaderComponent={<ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.INACTIVE_LICENSES} />}
          SubComponent={({ row }) =>
            row.licenseTypes &&
            (<Table
              draggable={false}
              tableHeaderStyle={{ display: 'none' }}
              TheadComponent={TheadComponent}
              getTdProps={setTdStyle}
              getTheadThProps={injectTheadThSortOrder}
              getTrGroupProps={this.setSubTableTrGroupStyle}
              data={row.licenseTypes}
              columns={columns}
              loading={false}
              style={{ minHeight: '60px', backgroundColor: theme.palette.background.primaryActive }}
            />)
          }
        />
        {isOpen && <InactiveUsersPopup isOpen={isOpen} idApp={this.state.selectedIdApp} idLicense={this.state.selectedIdLicense} savingsConverted={this.state.savingsConverted} cancel={this.closePopup} loading={loading} inactiveAmount={this.state.inactiveAmount} />}
      </Fragment>
    )
  }
}

Licenses.propTypes = {
  licenses: PropTypes.arrayOf(PropTypes.shape({
    idApp: PropTypes.number,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    category: PropTypes.string,
    expenses: PropTypes.number,
    savings: PropTypes.number,
    totalAmount: PropTypes.number,
    activeAmount: PropTypes.number,
    inactiveAmount: PropTypes.number,
    unassignedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inactiveReasons: PropTypes.shape({
      noAccess: PropTypes.number,
      leftOrg: PropTypes.number,
      noUsage: PropTypes.number
    }),
    licenseTypes: PropTypes.arrayOf(PropTypes.shape({
      idApp: PropTypes.number,
      licenseName: PropTypes.string,
      expenses: PropTypes.number,
      totalAmount: PropTypes.number,
      activeAmount: PropTypes.number,
      inactiveAmount: PropTypes.number,
      unassignedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      inactiveReasons: PropTypes.shape({
        noAccess: PropTypes.number,
        leftOrg: PropTypes.number,
        noUsage: PropTypes.number
      })
    }))
  })),
  loading: PropTypes.bool.isRequired
}

export default Licenses
