import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { fontSize } from '../../shared/style/sizes'
import UserDetails from '../userDetails'
import debouncePromise from 'debounce-promise'
import { ToriiSelectAsync } from '../select'
import isUndefined from 'lodash/isUndefined'
import { getDisplayName } from '../../lenses/users'

const CSS = {
  option: css({
    display: 'flex',
    alignItems: 'center',
    lineHeight: fontSize.small,
    height: '50px'
  })
}

const MAX_RESULTS_AMOUNT_OF_ONE_TYPE = 5

class FormFieldSelectUser extends React.Component {
  state = { searchValue: null }

  renderOption ({ data: user }) {
    const renderedOption = <UserDetails
      overrideStyle={{ padding: 0, width: '100%' }}
      key={user.id}
      firstName={user.firstName}
      lastName={user.lastName}
      email={user.email}
      idUser={user.id}
      showExternalUserBadge={false}
      photoUrl={user.photoUrl}
      linkToUserPage={false}
    />
    const optionStyle = css(
      CSS.option
    )

    return <div key={user.value} {...optionStyle}>{renderedOption}</div>
  }

  onSearch = (searchValue) => {
    this.setState({ searchValue })
    return searchValue
  }

  fetchData = debouncePromise(async (searchValue) => {
    const { idOrg, searchUserRestricted, idActionExe, id, secret } = this.props
    const response = await searchUserRestricted({ idOrg, q: searchValue, limit: MAX_RESULTS_AMOUNT_OF_ONE_TYPE, idActionExe, id, secret })
    const mappedUserOptions = response.users.map(user => ({ ...user, value: user.id, label: [user.firstName, user.lastName, user.email].join(' '), optionType: 'user', pathTemplate: `/team/{idOrg}/user/${user.id}` }))
    return { options: mappedUserOptions }
  }, 500)

  getOptions = async (searchValue) => {
    if (!searchValue) {
      return { options: [] }
    }

    return this.fetchData(searchValue)
  }

  render () {
    const { onChange, value, multi } = this.props
    const { searchValue } = this.state

    return <ToriiSelectAsync
      ref={select => { this.select = select }}
      optionRenderer={this.renderOption}
      valueRenderer={props => getDisplayName(props.data)}
      selectWidth='100%'
      onChange={onChange}
      clearable
      onInputChange={this.onSearch}
      noResultsText='No users found'
      loadOptions={this.getOptions}
      cache={false}
      loadingPlaceholder={'Loading...'}
      value={isUndefined(value) ? searchValue : value}
      placeholder='Type to search a user...'
      multi={multi}
    />
  }
}

FormFieldSelectUser.propTypes = {
  options: PropTypes.array
}

FormFieldSelectUser.defaultProps = {
  options: []
}

export default FormFieldSelectUser
