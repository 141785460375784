import Analytics from '@helpers/analytics'
import { css } from 'glamor'
import { Icon } from '@toriihq/design-system'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TrialCountdown from '@components/trialCountdown'
import PropTypes from 'prop-types'
import NavigationMenu from '@components/navigation/NavigationMenu'
import { useWindowDimensions } from '@shared/hooks'
import * as CSS from './styles'
import { useLocation } from 'react-router-dom'
import LogoWithTextColor from '@media/logo_with_text_color.svg'

const NavigationWithOverlay = React.forwardRef((props, ref) => {
  const { isWhiteLabel, companyName, logoUrl, isTrial, headerSpace, isOnlyAppOwner } = props

  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [canHideOverlay, setCanHideOverlay] = useState(true)
  const [isScrolling, setIsScrolling] = useState(false)

  const bodyRef = useRef()
  const navPanelAnimation = useRef(null)
  const { width: windowWidth } = useWindowDimensions()

  const runAnimation = useCallback((keyframes) => {
    if (navPanelAnimation.current && navPanelAnimation.current.animate) {
      const animationObj = navPanelAnimation.current.animate(keyframes, { duration: 150, iterations: 1 })
      animationObj.finished.then(() => {
        if (!isOpen) {
          setCanHideOverlay(true)
        }
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setCanHideOverlay(false)
      runAnimation([{ left: `-${windowWidth * 0.8}px`, right: '0px' }, { left: '0px', right: `${windowWidth * 0.8}px` }])
    } else {
      runAnimation([{ left: '0px', right: `${windowWidth * 0.8}px` }, { left: `-${windowWidth * 0.8}px`, right: '0px' }])
    }
  }, [isOpen, runAnimation, windowWidth])

  useEffect(() => {
    setIsOpen(false)
    setCanHideOverlay(true)
  }, [location])

  const getLogo = () => {
    return isWhiteLabel ? logoUrl : LogoWithTextColor
  }

  const onLogoClick = () => {
    setIsOpen(!isOpen)
    Analytics.track('Click on hamburger icon', {
      Action: isOpen ? 'Collapse' : 'Expend'
    })
  }

  const onMenuScroll = () => {
    const scrollTop = bodyRef.current.scrollTop
    setIsScrolling(scrollTop > 0)
  }

  const Hamburger = () => {
    return (
      <div {...CSS.Header} onClick={onLogoClick}>
        <Icon name='Menu' />
      </div>
    )
  }

  const FullLogo = () => {
    return (
      <div {...(isScrolling ? css(CSS.Header, CSS.HeaderShadow) : CSS.Header)} onClick={onLogoClick}>
        <Icon name='Menu' />
        <div {...css(CSS.Logo, isWhiteLabel && CSS.WhiteLabel)}>
          <img alt={isWhiteLabel ? companyName : 'Torii Logo'} {...CSS.Image} src={getLogo()} />
        </div>
      </div>
    )
  }

  return (
    <>
      {!isOnlyAppOwner && <Hamburger />}
      <div
        ref={ref}
        {...css(CSS.Panel, {
          top: headerSpace,
          height: `calc(100% - ${headerSpace}px)`,
          display: canHideOverlay ? 'none' : 'flex'
        })}>
        <div {...CSS.NavPanelContainer} ref={navPanelAnimation}>
          <div {...css(CSS.NavPanel, { width: `${windowWidth * 0.8}px` })}>
            <FullLogo />
            {isOpen && (
              <section ref={bodyRef} {...CSS.Body} onScroll={onMenuScroll}>
                <NavigationMenu isOpen={isOpen} />
                {isTrial && (
                  <div {...CSS.Footer}>
                    <TrialCountdown isOpen={isOpen} />
                  </div>
                )}
              </section>
            )}
          </div>
        </div>
      </div>
    </>
  )
})

NavigationWithOverlay.propTypes = {
  headerSpace: PropTypes.number,
  isWhiteLabel: PropTypes.bool,
  companyName: PropTypes.string,
  logoUrl: PropTypes.string,
  isTrial: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object)
}

export default NavigationWithOverlay
