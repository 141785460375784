import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import EnableFor from '../../enableFor'
import { simpleEmailRegex } from '@root/constants'
import MultipleCheckboxSelectAsync from '../../multipleCheckboxSelectAsync'
import UserDetails from '../../userDetails'
import colors from '@shared/style/colors'
import { getDisplayName } from '@lenses/users'
import identity from 'lodash/identity'
import debouncePromise from 'debounce-promise'
import isString from 'lodash/isString'
import partition from 'lodash/partition'
import CreateUserPopup from '@components/popups/createUserPopup'
import texts from '@shared/style/texts'
import CustomOptionDetails from '@components/customOptionDetails'
import isEmpty from 'lodash/isEmpty'

const CSS = {
  container: css({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '8px'
  }),
  selectContainer: css({
    flex: 1,
    minHeight: '32px',
    display: 'flex'
  }),
  outer: css({
    ' .Select-option': {
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  addNewUser: css(texts.body, {
    color: colors.blue
  })
}

class MultipleUsers extends React.Component {
  state = {
    key: (this.props.value || 'key').toString(),
    selectedUsers: []
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.value && this.props.value) {
      this.initialGetOptions = true
      this.setState({ key: this.props.value.toString() })
    }
  }

  onClickCustomEmailOption = (input) => {
    const { creatable, toggleAddUser, field } = this.props
    creatable && toggleAddUser(true, input, field.id)
  }

  onChangeTo = (selectedUsers) => {
    const { onChange } = this.props

    const selectedOption = selectedUsers.length && selectedUsers[selectedUsers.length - 1]
    if (selectedOption.customEmail) {
      return this.onClickCustomEmailOption(selectedOption.value)
    }

    const idUsers = selectedUsers.map((user) => isString(user.value) ? user.value : user.id)
    this.setState({ selectedUsers })

    onChange(idUsers)
  }

  renderOption = (option) => {
    const isSpecialOption = isString(option.value)
    const isCustomEmail = option.customEmail

    return (
      <div key={option.value || option.id}>
        <div>
          {isCustomEmail ? <div {...CSS.addNewUser}>+ Add new user with email <strong>{option.value}</strong></div>
            : isSpecialOption
              ? <CustomOptionDetails {...option} />
              : <UserDetails {...option} showPastUserBadge id={null} />
          }
        </div>
      </div>
    )
  }

  valueRenderer = (option) => {
    return option.label || getDisplayName(option)
  }

  fetchData = async ({ searchValue, idUsers }) => {
    const { idOrg, searchUsers } = this.props
    const isUsersAlreadySelected = !isEmpty(idUsers)
    const response = await searchUsers({ idOrg, idUsers, q: searchValue, limit: 10, includeChildren: isUsersAlreadySelected })

    return response.users.map(user => ({ ...user, label: getDisplayName(user) }))
  }

  debouncedFetchData = debouncePromise(this.fetchData, 500)

  initialGetOptions = true
  getOptions = async (searchValue) => {
    const { specialUsers, value, creatable } = this.props

    if (!searchValue) {
      let initialRandomUsers = []
      let selectedUsers = []
      if (this.initialGetOptions) {
        this.initialGetOptions = false
        const [specialIdUsers, idUsers] = partition(value, user => isString(user))

        if (idUsers && idUsers.length) {
          const users = await this.fetchData({ searchValue, idUsers })
          selectedUsers = selectedUsers.concat(users)
        }

        if (specialIdUsers && specialIdUsers.length) {
          const selectedSpecialUsers = specialIdUsers.map(id => specialUsers[id])
          selectedUsers = selectedUsers.concat(selectedSpecialUsers)
        }

        this.setState({ selectedUsers })
      }

      initialRandomUsers = await this.fetchData({})

      return { options: Object.values(specialUsers).concat(initialRandomUsers) }
    }

    const specialUsersFiltered = Object.values(specialUsers).filter(user => {
      return user.label && user.label.toUpperCase().includes(searchValue.toUpperCase())
    })

    const options = await this.debouncedFetchData({ searchValue })

    if (creatable && simpleEmailRegex.test(searchValue)) {
      return { options: [...specialUsersFiltered, ...options, { id: searchValue, label: searchValue, customEmail: true, noCheckbox: true }].map(o => ({ ...o, value: o.value || o.id, label: o.label || o.name })) }
    }

    return { options: [...specialUsersFiltered, ...options].map(o => ({ ...o, value: o.value || o.id, label: o.label || o.name })) }
  }

  render () {
    const { text, overrideStyle, loading, selectClassName, allowedScopes = [], disabled = false, specialUsers, placeholder, noResultsText, actionType, field, addUser: { isOpen, fieldId: addUserFieldId }, cache, borderless } = this.props
    const { selectedUsers, key } = this.state
    const selectProps = {
      selectClassName,
      selectOuterClassName: CSS.outer.toString(),
      renderOption: this.renderOption,
      onChange: this.onChangeTo,
      multi: true,
      showValues: true,
      placeholder,
      searchable: true,
      isLoading: loading,
      disabled,
      filterOptions: identity,
      noResultsText,
      defaultOptions: true,
      loadOptions: this.getOptions,
      value: selectedUsers,
      valueRenderer: this.valueRenderer,
      valueKey: 'id',
      labelKey: 'name',
      specialUsers,
      cache,
      borderless,
      selectWidth: '100%'
    }

    return (
      <div {...css(CSS.container, overrideStyle)} key={key}>
        {text && <div>{text}</div>}
        <div {...CSS.selectContainer}>
          <EnableFor scopes={allowedScopes}>
            <MultipleCheckboxSelectAsync {...selectProps} />
          </EnableFor>
        </div>
        {isOpen && field && addUserFieldId === field.id
          ? <CreateUserPopup onSuccess={this.onChangeTo} selectedUsers={selectedUsers} actionType={actionType} /> : null}
      </div>
    )
  }
}

MultipleUsers.propTypes = {
  overrideStyle: PropTypes.object,
  value: PropTypes.node,
  onChange: PropTypes.func,
  text: PropTypes.node,
  actionType: PropTypes.string,
  triggerType: PropTypes.string,
  includeCustomUserFields: PropTypes.bool,
  includeCustomEmailFields: PropTypes.bool,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  creatable: PropTypes.bool,
  cache: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

MultipleUsers.defaultProps = {
  specialUsers: {},
  placeholder: 'Select user...',
  noResultsText: 'No users found',
  creatable: false,
  cache: undefined
}

export default MultipleUsers
