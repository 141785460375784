import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { Field } from 'react-final-form'
import Validator from 'validatorjs'
import SelectGroup from './selectGroup'
import InputField from './input'
import FormQuestion from './formQuestion'
import CheckboxField from './checkbox'

const validate = (values) => {
  const errors = {}
  const rules = {
    fieldName: 'required|max:100'
  }

  const messages = {
    'required.fieldName': 'This field is mandatory',
    'max.fieldName': 'Try something a bit shorter (max. 100)'
  }

  const validator = new Validator(values, rules, messages)
  validator.passes()
  const allErrors = validator.errors.get('fieldName')
  if (allErrors) {
    errors.fieldName = allErrors[0]
  }
  return errors
}

class InputPopup extends React.Component {
  submitForm = async (values) => {
    const { submitButton, cancelButton, autoHideOnSubmit } = this.props
    if (!values.checkbox || !values.formQuestion) {
      values.formQuestion = null
    }
    await submitButton.onClick(values)
    if (autoHideOnSubmit) {
      setTimeout(() => cancelButton.onClick(false), 1000)
    }
  }

  render () {
    const { cancelButton, submitButton, header, subHeader, isOpen, input: { label, fieldName, formQuestion, idGroup }, editMode, isPredefined, groupsForSelectGroup, showCheckBox = true, showGroupDropdown = false } = this.props

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancelButton.onClick}>
        <ToriiPopup.Header header={header} subHeader={subHeader} />
        <ToriiPopup.Form
          onSubmit={this.submitForm}
          initialValues={{ fieldName, idGroup, formQuestion, checkbox: Boolean(formQuestion) }}
          validate={validate}
          render={(formProps) => {
            const { handleSubmit, form } = formProps
            const { checkbox } = form.getState().values
            const disabled = Boolean(isPredefined)

            return (
              <form onSubmit={handleSubmit}>
                <Fragment>
                  {showGroupDropdown && <Field label={label} name='idGroup' options={groupsForSelectGroup} component={SelectGroup} autoFocus={!editMode} />}
                  <Field label={label} name='fieldName' component={InputField} autoFocus={!editMode} disabled={disabled} withTooltip={isPredefined} />
                </Fragment>
                {showCheckBox && (
                  <Fragment>
                    <Field name='checkbox' type='checkbox' component={CheckboxField} checked={checkbox} label='Customize how this field will appear in forms' disabled={disabled} withTooltip={isPredefined} />
                    <Field name='formQuestion' component={FormQuestion} show={checkbox} />
                  </Fragment>
                )}
              </form>
            )
          }}
          renderFooter={(formProps) => (
            <ToriiPopup.Footer
              cancelButtonText={cancelButton.label}
              mainButtonText={submitButton.label}
              isMainSubmit
              formProps={formProps}
            />
          )}
        />
      </ToriiPopup>
    )
  }
}

InputPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancelButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  submitButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.string
  }).isRequired,
  header: PropTypes.node,
  subHeader: PropTypes.node,
  autoHideOnSubmit: PropTypes.bool
}

InputPopup.defaultProps = {
  editMode: false,
  autoHideOnSubmit: true,
  checkbox: false
}

export default InputPopup
