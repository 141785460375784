import React from 'react'
import TabsPage from '@root/pages/tabs'
import PluginModel from '@pages/pluginMarketplace/types'
import PluginDescription from './pluginDescription'
import PluginWidgets from './pluginWidgets'
import { formFieldTypes, formFieldTypeToName } from '@root/constants'

const PluginTabs = ({ plugin, idOrg }: { plugin: PluginModel, idOrg: number }) => {
  const tabsName = ['description', 'widgets']
  const tabsHeader: { header: string, counter?: number }[] = [
    { header: 'Description' }
  ]
  const tabsContent = [
    <PluginDescription plugin={plugin} />
  ]

  if (plugin.widgets.length) {
    tabsHeader.push({ header: 'Widgets', counter: plugin.widgets.length })
    tabsContent.push(<PluginWidgets widgets={plugin.widgets.map(widget => ({
      ...widget,
      fields: widget.fields.map(field => ({ ...field, type: formFieldTypes.singleLine, value: formFieldTypeToName[field.type] })) }))}
    />)
  }
  return (
    <TabsPage
      pageHeader={''}
      pageTitle={'Plugin'}
      pageName={'Plugin'}
      path={`/team/${idOrg}/plugin/${plugin.uuid}`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      isSecondary
    />
  )
}

export default PluginTabs
