import React from 'react'
import PropTypes from 'prop-types'
import { Mention, MentionsInput } from 'react-mentions'
import colors from '../../../shared/style/colors'
import { fontWeight } from '../../../shared/style/sizes'
import { theme } from '@toriihq/design-system'

const style = {
  control: {
    font: theme.typography.font.body02,
    color: theme.palette.text.primary
  },
  highlighter: {
    overflow: 'hidden'
  },
  input: {
    margin: 0
  },
  '&singleLine': {
    control: {
      display: 'block',
      textTransform: 'none',
      minWidth: '200px',
      width: '100%'
    },
    highlighter: {
      padding: '0 5px'
    },
    input: {
      padding: '0 5px',
      outline: 0,
      border: 0,
      width: '100%'
    }
  },
  '&multiLine': {
    control: {
      textTransform: 'none'
    },
    highlighter: {
      padding: 0
    },
    input: {
      padding: 0,
      minHeight: 120,
      outline: 0,
      border: 0
    }
  },
  suggestions: {
    list: {
      backgroundColor: colors.white,
      border: `1px solid ${colors.border}`,
      borderRadius: '4px',
      fontSize: 12,
      maxHeight: '192px',
      overflow: 'scroll'
    },
    item: {
      fontWeight: fontWeight.normal,
      color: colors.grey1,
      lineHeight: '18px',
      padding: '5px 15px',
      borderBottom: `1px solid ${colors.border}`,
      borderRadius: '4px',
      '&focused': {
        backgroundColor: colors.background,
        color: colors.blue
      }
    }
  }
}

const itemStyle = {
  backgroundColor: colors.lightBlue2
}

// For "markup" and "regex" see documentation of react-mentions
// https://github.com/signavio/react-mentions
const markup = '{__id__}'
const regex = new RegExp('{([A-Za-z0-9](?:[^}]*?[A-Za-z0-9])?)}', 'g')

const MentionsTextarea = ({ className, value, mentions, onChange, onBlur, trigger, overrideStyle, disabled, ...rest }) => (
  <MentionsInput
    className={className}
    disabled={disabled}
    markup={markup}
    regex={regex}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    displayTransform={(id, display) => {
      return '[' + (mentions.find(item => item.id === id) || { display: 'invalid placeholder' }).display + ']'
    }}
    onPaste={(e) => {
      e.persist()
      setTimeout(() => {
        let v = e.target.value
        mentions.forEach(m => {
          v = v.replace(new RegExp(`\\[${m.display}\\]`, 'g'), `{${m.id}}`)
        })
        onChange({ target: { value: v } })
      })
    }}
    style={{ ...style, ...overrideStyle }}
    {...rest}
  >
    <Mention
      trigger={trigger}
      data={mentions}
      style={itemStyle}
      disabled={disabled}
    />
  </MentionsInput>
)

MentionsTextarea.propTypes = {
  value: PropTypes.any,
  mentions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.any,
    display: PropTypes.string
  })),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  trigger: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

MentionsTextarea.defaultProps = {
  trigger: '@',
  singleLine: false,
  mentions: [],
  disabled: false,
  className: ''
}

export default MentionsTextarea
