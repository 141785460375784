import envConfig from '@envConfig'
import { type Config } from './config/types'
import Cookie from 'cookie'

const setCookie = (name, value, days) => {
  const d = new Date()
  d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

const config: Config = envConfig
const params = new URLSearchParams(window.location.search)
let apiBaseUrlFromUrl = params.get('API_BASE_URL')
if (apiBaseUrlFromUrl) {
  if (apiBaseUrlFromUrl.endsWith('/')) {
    apiBaseUrlFromUrl = apiBaseUrlFromUrl.slice(0, -1)
  }
  setCookie('API_BASE_URL', apiBaseUrlFromUrl, 1)
}

let themeFromUrl = params.get('THEME')
if (themeFromUrl === 'light' || themeFromUrl === 'dark') {
  console.log(`Theme is set via "THEME" query param to "${themeFromUrl}".`)
  config.theme = themeFromUrl
}

const cookies = document ? Cookie.parse(document.cookie || '') : {}

if (cookies.API_BASE_URL) {
  console.log(`Preview API base URL is set via "API_BASE_URL" to "${cookies.API_BASE_URL}".`)
  config.apiBaseUrl = cookies.API_BASE_URL
  config.proxyApiRequests = false
}

export default config
