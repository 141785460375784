import { theme } from '@toriihq/design-system'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 392px;
  display: grid;
  gap: ${({ theme }) => theme.spacing('space-200')};
`

export const Header = styled.div``

export const BreakdownWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing('space-100')};
`

export const Line = styled.div`
  color: ${theme.palette.text.tertiary};
`

export const KeyValueLine = styled.div`
  display: flex;
`

export const Key = styled.div`
  flex-grow: 1;
`

export const Value = styled.div``

export const Title = styled(Line)`
  font: ${theme.typography.font.body01};
  font-weight: ${theme.typography.fontWeight.semiBold};
  color: ${theme.palette.text.primary};
`

export const KeyValueTitle = styled(Title)`
  display: flex;
`

export const FlexContainer = styled.div`
  display: flex;
`

export const Spacer = styled.div`
  margin: 8px 0;
`
