import React, { Component } from 'react'
import { ToriiSelectAsync } from '../select'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import colors from '../../shared/style/colors'
import isUndefined from 'lodash/isUndefined'
import debouncePromise from 'debounce-promise'
import { AppIcon, Spacer, TextBadge } from '@toriihq/design-system'
import { Name, DisabledApp } from './styles'
import { DEFAULT_SEARCH_APPS_LIMIT } from '@root/constants'
import OverflowTooltip from '../overflowTooltip'

const CSS = {
  app: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  }),
  appIconAndName: css({
    display: 'inline-flex',
    alignItems: 'center',
    flex: 1
  }),
  disabledApp: css({
    cursor: 'not-allowed'
  }),
  arrowIcon: css({
    height: '6px',
    width: '8px',
    transform: 'none',
    fontSize: '9px',
    color: colors.grey2
  })
}

const SelectApp = ({ item, hideBadge, shouldOverflow }) => {
  const displayBadge = (item.badge || item.exists || item.hidden) && !hideBadge
  const badge = displayBadge ? item.badge || <TextBadge color='blue' size='Small'>{ (item.exists ? 'Already detected' : item.hidden ? 'Hidden' : null)}</TextBadge> : null

  return <div {...css(CSS.app, (item.exists || item.hidden) && CSS.disabledApp)}>
    <div {...CSS.appIconAndName}>
      <Spacer right={'space-100'}>
        {(item.exists || item.hidden)
          ? <DisabledApp appImageUrl={item.imageUrl}>
            <AppIcon appName={item.name} appImageUrl={item.imageUrl} size='small' />
          </DisabledApp>
          : <AppIcon appName={item.name} appImageUrl={item.imageUrl} size='small' />
        }
      </Spacer>
      {shouldOverflow ? <div style={{ width: 'calc(100% - 32px)' }}>
        <OverflowTooltip label={item.name}>
          {item.name}
        </OverflowTooltip>
      </div> : <Name>{item.name}</Name>}
    </div>
    {badge}
  </div>
}

class SelectApps extends Component {
  state = {
    selectedApp: null
  }

  onChange = async (selectedApp) => {
    const { onChange } = this.props
    const { inputValue } = this.state
    this.setState({ selectedApp })
    onChange && onChange(selectedApp, inputValue)
  }

  fetchData = debouncePromise(async (searchValue) => {
    const { idOrg, searchApps, usedAppsById, disableExistsApps, disableHiddenApps, specialOption, includeAllApps, transformApps, limit = DEFAULT_SEARCH_APPS_LIMIT, hasApplicationsTakeActionScope } = this.props
    const response = await searchApps({ idOrg, q: searchValue, limit, includeAllApps })
    const isCustomAppOption = specialOption?.value === 'customApp'
    const canRenderSpecialOption = specialOption && ((isCustomAppOption && hasApplicationsTakeActionScope) || !isCustomAppOption)

    if (canRenderSpecialOption) {
      specialOption.isSpecial = true
    }

    const options = transformApps ? transformApps(response.apps) : response.apps.map(app => {
      const exists = disableExistsApps && !!usedAppsById[app.id]
      const hidden = disableHiddenApps && !!app.isHidden
      return { ...app, exists, hidden, disabled: exists || hidden }
    }).concat(canRenderSpecialOption ? specialOption : [])
    return { options }
  }, 500)

  getOptions = async (searchValue) => {
    const { initialOptions } = this.props
    if (!searchValue) {
      return initialOptions ? { options: initialOptions } : { options: [] }
    }

    return this.fetchData(searchValue)
  }

  renderApp = (item, hideBadge, shouldOverflow) => {
    if (item.isSpecial && item.render) {
      const { inputValue } = this.state
      return item.render(inputValue)
    }
    return <SelectApp item={item} hideBadge={hideBadge} shouldOverflow={shouldOverflow} />
  }

  onInputChange = (value) => {
    value && this.setState({ inputValue: value })
    return value
  }

  render () {
    const { value, placeholder, filterOptions, ...restSelectParams } = this.props
    const { selectedApp } = this.state

    return (
      <ToriiSelectAsync
        ref={select => { this.select = select }}
        optionRenderer={({ data: item }) => this.renderApp(item, false, false)}
        valueRenderer={({ data: item }) => this.renderApp(item, true, true)}
        valueKey='id'
        labelKey='name'
        placeholder={placeholder}
        maxItemsToShow={10}
        backspaceRemoves={false}
        clearable={false}
        autoBlur
        noResultsText='No apps found'
        loadOptions={this.getOptions}
        cache={false}
        loadingPlaceholder={'Loading...'}
        {...restSelectParams}
        onInputChange={this.onInputChange}
        value={isUndefined(value) ? selectedApp : value}
        onChange={this.onChange}
        isOptionDisabled={option => option.disabled}
      />
    )
  }
}

SelectApps.propTypes = {
  placeholder: PropTypes.string,
  includeAllApps: PropTypes.bool
}

SelectApps.defaultProps = {
  placeholder: 'Select App...',
  includeAllApps: true
}

export default SelectApps
