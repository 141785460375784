import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { APP_PERMISSIONS, SCOPES, SYNC_TYPE } from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import { FORM_ERROR } from 'final-form'
import { getTransformedUrl } from '@shared/services'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import ConnectDirectIntegration from '@components/service/connectDirectIntegration'
import validate from '@components/service/connectDirectIntegration/validation'
import ConnectLink from '@components/service/connectLink'
import Analytics from '@helpers/analytics'
import TestConnectionAnalytics from '@components/testConnection/analytics'
import get from 'lodash/get'
import omit from 'lodash/omit'
import TestConnection from '@components/testConnection'
import { CONNECT_STAGES, TEST_PHASES, TEST_RESULTS } from '@components/testConnection/constants'
import moment from 'moment'
import { calculateFinalResults, getAuthenticationErrorResults } from '@components/testConnection/utils'
import BackLink from '@components/backLink'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const connectionInterface = 'Connect in app'
const CSS = {
  connectLink: css({
    marginRight: '10px',
    width: '100%'
  }),
  footer: css({
    flexDirection: 'row-reverse',
    width: '100%'
  })
}

const DELAY = 2000

class ConnectDirectIntegrationPopup extends React.Component {
  state = {
    permission: this.props.permission,
    stage: this.props.validTestInProgress ? CONNECT_STAGES.IN_PROGRESS : CONNECT_STAGES.CONNECT_FORM,
    afterRedirect: false,
    idTestConnection: null,
    inputsData: null,
    results: null,
    internalError: false,
    idApps: this.props.idApps
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  async componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData()
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ permission: this.props.permission })
      this.props.isOpen && TestConnectionAnalytics.toggleIntegrationConnection(true, this.props.serviceName, this.props.idApp, this.props.hasTestConnection ? 'Test before connect' : 'Connect', connectionInterface)
    }

    if (this.props.validTestInProgress && this.props.validTestInProgress !== prevProps.validTestInProgress) {
      await this.handleTestConnectionForOAuth()
    }
  }

  handleTestConnectionForOAuth = async () => {
    const { getTestConnectionInfo, updateTestConnectionEntry, authenticationError, idTestConnection, idOrg, serviceConfig, idApp } = this.props
    const { capabilities, integrationCategory } = serviceConfig
    this.setState({ afterRedirect: true })
    if (!authenticationError) {
      await this.onTest()
    } else {
      this.setState({ stage: CONNECT_STAGES.IN_PROGRESS })
      const testConnectionInfo = await getTestConnectionInfo({ idTestConnection, idOrg, idApp })
      const results = getAuthenticationErrorResults(capabilities, integrationCategory, testConnectionInfo)
      await updateTestConnectionEntry({ idTestConnection, idOrg, data: { phase: TEST_PHASES.RESULTS, testEndTime: moment.utc().toISOString() } })
      setTimeout(async () => {
        this.setState(({ stage: CONNECT_STAGES.RESULTS, results }))
      }, DELAY)
    }
  }

  fetchData = () => {
    const { idOrg, getServicesSyncData, getServicesConfig, getSyncData } = this.props
    getSyncData && getServicesSyncData({ idOrg })
    getSyncData && getServicesConfig({ idOrg })
  }

  onPermissionChanged = ({ value }) => {
    this.setState({ permission: value })
  }

  onSubmit = async (inputsData) => {
    const { connectFormConfiguration = {}, integrationType, createTestConnectionEntry, idApp, idOrg, hasTestConnection } = this.props

    const isHidden = (key) => {
      const field = connectFormConfiguration.fields.find(field => field.name === key)
      if (!field.showOnFieldSpecificValue) {
        return false
      }
      const dependsOnFieldValue = get(inputsData, field.showOnFieldSpecificValue.name)
      return dependsOnFieldValue !== field.showOnFieldSpecificValue.value
    }

    const hiddenInputsDataKeys = Object.keys(inputsData).filter(isHidden)
    inputsData = omit(inputsData, hiddenInputsDataKeys)
    if (hasTestConnection) {
      const { id: idTestConnection } = await createTestConnectionEntry({ data: { phase: TEST_PHASES.IN_PROGRESS, integrationType: integrationType, idApp }, idOrg })
      this.setState({ idTestConnection: parseInt(idTestConnection) })
    } else {
      await this.onConnect(inputsData)
      return
    }
    const oauthConnectionWithNoTest = (integrationType === INTEGRATION_TYPE.OAUTH || integrationType === INTEGRATION_TYPE.CONFIGURED_OAUTH) && !this.state.afterRedirect
    if (oauthConnectionWithNoTest) {
      this.redirectWindow(inputsData)
    } else {
      await this.onTest(inputsData)
    }
  }

  onTest = async (inputsData) => {
    let appToken
    let results
    const { serviceName, testConnection, idTestConnection: idTestConnectionFromUrl, connectFormConfiguration = {}, integrationType, idApp, idOrg } = this.props
    const idTestConnection = this.state.idTestConnection || idTestConnectionFromUrl
    TestConnectionAnalytics.clickTestIntegration(serviceName, idApp, connectionInterface)
    this.setState({ stage: CONNECT_STAGES.IN_PROGRESS, inputsData })

    try {
      if (INTEGRATION_TYPE.TOKENS === integrationType) {
        appToken = connectFormConfiguration.sendDataAsToken ? { token: JSON.stringify(inputsData) } : inputsData
      }

      results = await testConnection({ idApp, idOrg, appToken, idTestConnection })
      setTimeout(async () => {
        this.setState(({ stage: CONNECT_STAGES.RESULTS, results, appToken }))
      }, DELAY)
    } catch (e) {
      this.setState({ internalError: true })
    }
  }

  onConnect = async (inputsData) => {
    const {
      getSyncStatus,
      createSourceToken,
      callback,
      close,
      source,
      integrationType,
      connectFormConfiguration = {},
      getServicesSyncData,
      idOrg,
      idApp,
      serviceName,
      isReconnect
    } = this.props

    if (isReconnect) {
      Analytics.track(`Click on reconnect button`, {
        'App name': serviceName
      })
    }

    if ([INTEGRATION_TYPE.CONFIGURED_OAUTH, INTEGRATION_TYPE.OAUTH].includes(integrationType)) {
      this.redirectWindow(inputsData)
    } else {
      const onConnectSuccess = () => {
        const SUCCESS_DELAY = 1000
        setTimeout(async () => {
          getSyncStatus && await getServicesSyncData({ idOrg })
          close()
          callback && callback()
        },
        SUCCESS_DELAY)
      }
      const formData = connectFormConfiguration.sendDataAsToken ? { token: JSON.stringify(inputsData) } : inputsData
      return createSourceToken({ idApp, source, formData: { ...formData, permission: this.state.permission } })
        .then(onConnectSuccess).catch(e => {
          window.Sentry && window.Sentry.captureException(e)
          return { [FORM_ERROR]: '* Please check your entries and try again' }
        })
    }
  }

  onConnectForTestConnection = async () => {
    const { inputsData } = this.state
    const { close, getSyncStatus, createSourceTokenAfterTestConnection, callback, source, integrationType, connectFormConfiguration = {}, getServicesSyncData, idOrg, idApp, serviceName, isReconnect, idTestConnection: idTestConnectionFromUrl } = this.props
    const idTestConnection = this.state.idTestConnection || idTestConnectionFromUrl
    let appToken
    let permission

    const finalResults = calculateFinalResults(this.state.results)
    TestConnectionAnalytics.clickOnConnectAfterTestIntegration(finalResults, serviceName, idApp, connectionInterface)
    isReconnect && Analytics.track(`Click on reconnect button`, { 'App name': serviceName })

    if (INTEGRATION_TYPE.TOKENS === integrationType) {
      appToken = connectFormConfiguration.sendDataAsToken ? { token: JSON.stringify(inputsData) } : inputsData
      permission = this.state.permission
    }

    await createSourceTokenAfterTestConnection({ idTestConnection, idApp, source, formData: { permission, ...appToken } })

    getSyncStatus && await getServicesSyncData({ idOrg })
    this.resetState()
    close && close()
    callback && callback()
  }

  redirectWindow = (inputsData) => {
    const { source, connectUrl = {}, idApps } = this.props
    window.location.href = getTransformedUrl({ baseUrl: connectUrl, source, inputsData: { ...inputsData, permission: this.state.permission, idTestConnection: this.state.idTestConnection, idApps } })
  }

  onBackClick = () => {
    const { serviceName, idApp } = this.props
    const finalResults = calculateFinalResults(this.state.results)
    TestConnectionAnalytics.backClick(finalResults, serviceName, idApp, connectionInterface)
    this.setState({
      stage: CONNECT_STAGES.CONNECT_FORM,
      results: null,
      afterRedirect: false,
      idTestConnection: null,
      internalError: false
    })
  }

  onLinkClick = async (e) => {
    e.preventDefault()

    const { serviceName, connectFormConfiguration, isReconnect } = this.props
    const { header, link } = connectFormConfiguration || {}

    window.open(link.url, '_blank', 'noopener,noreferrer')
    Analytics.track(`Click on how-to-set-up link`, {
      'Dialog name': header || `${isReconnect ? 'Reconnect' : 'Connect'} ${serviceName}`,
      'Link': link.url,
      'App name': serviceName
    })
  }

  resetState = () => {
    this.setState({
      stage: CONNECT_STAGES.CONNECT_FORM,
      results: null,
      inputsData: null,
      afterRedirect: false,
      idTestConnection: null,
      internalError: false
    })
  }

  onClose () {
    const { close, serviceName, idApp, hasTestConnection } = this.props
    TestConnectionAnalytics.toggleIntegrationConnection(false, this.props.serviceName, this.props.idApp, hasTestConnection ? 'Test before connect' : 'Connect', connectionInterface)
    const finalResults = calculateFinalResults(this.state.results)
    if (this.state.stage === CONNECT_STAGES.RESULTS) {
      TestConnectionAnalytics.closeAfterSeeingTestResults(finalResults, serviceName, idApp, connectionInterface)
    }
    close && close()
    this.resetState()
  }

  connectFormPopup () {
    const { connectFormConfiguration, serviceName, hideConnectByLink, idApp, supportsWritePermission, isReconnect, showReconnectAlertInfo, hasTestConnection } = this.props
    const { link, fields = [] } = connectFormConfiguration || {}
    const { permission } = this.state
    const subHeader = link && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.onLinkClick} label={link.text} />
    return (
      <>
        <ToriiPopup.Header header={(`${isReconnect ? 'Reconnect' : 'Connect'} ${serviceName}`)} subHeader={subHeader} />
        <ToriiPopup.Form
          onSubmit={this.onSubmit}
          initialValues={this.state.inputsData}
          validate={(values) => validate(values, this.props)}
          render={(formProps) => {
            return <ConnectDirectIntegration
              formProps={formProps}
              onPermissionChanged={this.onPermissionChanged}
              {...this.props}
              permission={permission}
              showReconnectAlertInfo={showReconnectAlertInfo}
            />
          }}
          renderFooter={(formProps) => {
            formProps.pristine = fields.every(field => !field.isMandatory) || this.state.inputsData ? false : formProps.pristine
            return (
              <ToriiPopup.CustomFooter>
                <ToriiPopup.Footer.Buttons overrideStyle={CSS.footer}>
                  <ToriiPopup.Footer.SubmitButton formProps={formProps} recoveryTime={1000} scopes={[SCOPES.INTEGRATIONS_WRITE]} label={hasTestConnection ? 'Continue' : isReconnect ? 'Reconnect' : 'Connect'} />
                  {!hideConnectByLink && <ConnectLink overrideStyle={CSS.connectLink} idApp={idApp} serviceName={serviceName} permission={this.state.permission} syncType={SYNC_TYPE.API} showPermission={supportsWritePermission} />}
                </ToriiPopup.Footer.Buttons>
              </ToriiPopup.CustomFooter>
            )
          }}
        />
      </>
    )
  }

  inProgressPopup () {
    const { isReconnect, serviceName, capabilityList } = this.props

    return (
      <TestConnection>
        <ToriiPopup.Header header={`Test ${serviceName} Connection`} />
        {!this.state.internalError ? <TestConnection.InProgressBanner /> : <TestConnection.InternalErrorBanner />}
        <TestConnection.UnprocessedContent capabilityList={capabilityList} />
        <ToriiPopup.CustomFooter>
          <ToriiPopup.Footer.Buttons>
            <Button disabled size={ButtonSize.medium} label={isReconnect ? 'Reconnect' : 'Connect'} />
          </ToriiPopup.Footer.Buttons>
        </ToriiPopup.CustomFooter>
      </TestConnection>
    )
  }

  resultsPopup () {
    const { isReconnect, serviceName, capabilityList } = this.props
    const finalResults = calculateFinalResults(this.state.results)
    return (
      <TestConnection>
        <ToriiPopup.Header header={`Test ${serviceName} Connection`} />
        <TestConnection.TestResultsBanner finalResults={finalResults} />
        <TestConnection.TestResultsContent finalResults={finalResults} results={this.state.results} capabilityList={capabilityList} />
        <ToriiPopup.CustomFooter>
          <BackLink onClick={this.onBackClick}>Back</BackLink>
          <Button size={ButtonSize.medium} disabled={finalResults.result === TEST_RESULTS.FAILED} onClick={this.onConnectForTestConnection} label={isReconnect ? 'Reconnect' : 'Connect'} />
        </ToriiPopup.CustomFooter>
      </TestConnection>
    )
  }

  getPopupContent = () => {
    const { stage } = this.state
    switch (stage) {
      case CONNECT_STAGES.CONNECT_FORM:
        return this.connectFormPopup()
      case CONNECT_STAGES.IN_PROGRESS:
        return this.inProgressPopup()
      case CONNECT_STAGES.RESULTS:
        return this.resultsPopup()
      default:
        return null
    }
  }

  render () {
    const { isOpen, idApp } = this.props
    return (
      <ToriiPopup isOpen={Boolean(isOpen && idApp)} onCloseAction={() => this.onClose()} styles={{ modal: { width: '645px' } }}>
        {this.getPopupContent()}
      </ToriiPopup>
    )
  }
}

export const connectFormConfiguration = {
  header: PropTypes.string,
  description: PropTypes.node,
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    suffix: PropTypes.string,
    parse: PropTypes.func,
    isMandatory: PropTypes.bool
  })).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  sendDataAsToken: PropTypes.bool
}

ConnectDirectIntegrationPopup.propTypes = {
  connectFormConfiguration: PropTypes.shape(connectFormConfiguration),
  serviceName: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  supportsWritePermission: PropTypes.bool,
  disableReadOption: PropTypes.bool,
  showDeleteExplanation: PropTypes.bool,
  idApp: PropTypes.number.isRequired,
  idOrg: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  callback: PropTypes.func,
  getSyncStatus: PropTypes.bool,
  hideConnectByLink: PropTypes.bool,
  getSyncData: PropTypes.bool
}

ConnectDirectIntegrationPopup.defaultProps = {
  permission: APP_PERMISSIONS.read,
  getSyncStatus: true,
  onSuccess: () => {},
  hideConnectByLink: false,
  getSyncData: true
}

export default ConnectDirectIntegrationPopup
