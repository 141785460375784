import { MergeUsersRule as RuleType } from '@components/mergeUsersRules/types'
import get from 'lodash/get'
import { Styles } from '@components/mergeUsersRules/mergeUsersRule/style'
import AppDetails from '@components/appDetails'
import ButtonActions from '@components/_shared/buttonActions'
import { SCOPES } from '@root/constants'
import React, { ReactElement } from 'react'
import { SOURCE_TYPES_BY_ID_APP } from '@root/sourcesConfig'

type Props = {
  rule: RuleType
  onEditRule: () => void
  onDeleteRule: () => void
}

export const MergeUsersRule = ({ rule, onEditRule, onDeleteRule }: Props): ReactElement => {
  const idApp = get(rule, ['config', 'idApp'])
  const { name: appName, mergeUsersFieldName: fieldName } = SOURCE_TYPES_BY_ID_APP[idApp]

  return <Styles.RuleApp>
    <Styles.RuleAppDetails>
      <AppDetails
        id={idApp}
        name={`${appName} "${fieldName}" fields`}
        category={''}
        overrideStyle={{ width: '350px' }}
      />
    </Styles.RuleAppDetails>
    <Styles.ActionButtons>
      <ButtonActions
        iconColor='secondary'
        showDeleteAction
        showEditAction
        onEditClick={onEditRule}
        onDeleteClick={onDeleteRule}
        enableForScopes={[SCOPES.SETTINGS_WRITE]}
      />
    </Styles.ActionButtons>
  </Styles.RuleApp>
}
