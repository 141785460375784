import React, { Fragment } from 'react'
import EditableUploadFileBox from './editableUploadFileBox'

class UploadAppFiles extends React.Component {
  render () {
    const { deleteAppDetailsFieldValue, disabled, details, idOrg, idApp, readonly, loading } = this.props

    if (!details && !loading) {
      return null
    }

    return (
      <Fragment>
        {details.map(detail => {
          const onRemove = ({ idField, idFile }) => deleteAppDetailsFieldValue({ idOrg, idApp, idField, id: idFile })
          return <EditableUploadFileBox key={detail.systemKey} loading={loading} uploadDetails={detail} idApp={idApp} idOrg={idOrg} readonly={readonly} disabled={disabled} onRemove={onRemove} />
        })}
      </Fragment>
    )
  }
}

export default UploadAppFiles
