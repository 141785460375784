import UserDetails from '@components/userDetails'
import { getDisplayName } from '@lenses/users'
import { AppWrapper } from '@pages/appsComparison/components/usersOverlaps/components/styles'
import { AppIcon, Icon, Spacer, Tooltip } from '@toriihq/design-system'
import UsageIcon from '@components/usageIcon'
import { DATE_FORMAT, scoreSpecialValues } from '@root/constants'
import moment from 'moment/moment'
import colors from '@shared/style/colors'
import React from 'react'
import getColumnByFieldType from '@components/table/columnFieldTypes'

export const getColumns = ({ onClose, apps, dynamicColumnsInfo, usersById }) => [
  {
    Header: 'Users',
    id: 'firstName',
    accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
    minWidth: 200,
    maxWidth: 350,
    Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl } }) => (
      <UserDetails
        onSelect={onClose}
        firstName={firstName}
        lastName={lastName}
        email={email}
        isExternal={isExternal}
        idUser={idUser}
        showExternalUserBadge={false}
        photoUrl={photoUrl}
      />
    ),
    textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email })
  },
  {
    accessor: 'firstName',
    show: false
  },
  {
    accessor: 'lastName',
    show: false
  },
  {
    accessor: 'idUser',
    show: false
  },
  {
    accessor: 'email',
    show: false
  },
  {
    accessor: 'isExternal',
    show: false
  },
  {
    accessor: 'photoUrl',
    show: false
  }
]
  .concat(apps.map((app, index) => (
    {
      Header: () => (
        <AppWrapper>
          <Spacer right={'space-150'}>
            <AppIcon appName={app.name} appImageUrl={app.imageUrl} />
          </Spacer>
          <span>{app.name}</span>
        </AppWrapper>
      ),
      columns: [
        {
          Header: 'Has license',
          id: `licenses_${app.id}`,
          accessor: `licenses_${app.id}`,
          width: apps.length > 2 ? 95 : undefined,
          Cell: ({ value }) => {
            if (!value) {
              return '-'
            }
            const licenses = value.split(',')
            return licenses.map(license => {
              const [id, name] = license.split(':')
              return <Tooltip
                key={id}
                placement='top'
                label={name}
              >
                <Icon name='CheckCircleFill' color='interactive' />
              </Tooltip>
            })
          }
        },
        {
          Header: 'Usage',
          id: `score_${app.id}`,
          accessor: `score_${app.id}`,
          width: apps.length > 2 ? 70 : undefined,
          style: { display: 'flex', alignItems: 'center ' },
          Cell: ({ value: score, row }) => <UsageIcon
            isUserUsage score={row[`lastVisitTime_${app.id}`] ? score : scoreSpecialValues.notCollectingUsage}
            lastVisitTime={row[`lastVisitTime_${app.id}`]}
          />
        },
        {
          Header: 'Last used date',
          id: `lastVisitTime_${app.id}`,
          accessor: `lastVisitTime_${app.id}`,
          width: apps.length > 2 ? 140 : 160,
          Cell: ({ value }) => value ? moment(value).format(DATE_FORMAT) : '-',
          getProps: () => {
            return {
              style: {
                padding: '25px 20px',
                borderRight: `1px solid ${colors.border}`
              }
            }
          }
        }
      ],
      id: `app${index + 1}`
    }
  )))
  .concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, usersById, fieldIdentifier: 'key' }))
