import {
  NodeDimensions,
  WORKFLOW_UI_NODE_TYPE
} from '../types'
import {
  BRANCH_LABEL_DIMENSIONS,
  DEFAULT_NODE_DIMENSIONS,
  END_NODE_DIMENSIONS
} from '../consts'

export const getNodeDimensions = (nodeType: WORKFLOW_UI_NODE_TYPE): NodeDimensions => {
  const nodeDimensions = {
    [WORKFLOW_UI_NODE_TYPE.END]: END_NODE_DIMENSIONS,
    [WORKFLOW_UI_NODE_TYPE.BRANCH_LABEL]: BRANCH_LABEL_DIMENSIONS,
    [WORKFLOW_UI_NODE_TYPE.DEFAULT_BRANCH_LABEL]: BRANCH_LABEL_DIMENSIONS
  }

  return nodeDimensions[nodeType] ?? DEFAULT_NODE_DIMENSIONS
}
