import { Styles } from './style'
import React, { ReactElement } from 'react'
import SelectionBox from '@components/workflows/selectionBox'
import { SharedProps, SharedState } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import { Col, Tooltip } from '@toriihq/design-system'
import { APP_RULES_BY_ID_APP } from './constants'
import { getRule } from './utils'

type Props = {
    onSelectionChange: ({ selectedRuleIdApp, selectedRuleUserFieldKey }) => void
    sharedState: SharedState
    sharedProps: SharedProps
}

export const RuleSelector = ({ onSelectionChange, sharedState, sharedProps }: Props): ReactElement => {
  const { selectedRuleIdApp, availableRules } = sharedState

  const onChange = ({ value }) => {
    onSelectionChange({
      selectedRuleIdApp: value,
      selectedRuleUserFieldKey: APP_RULES_BY_ID_APP[value].mergeUsersFieldKey
    })
  }

  return (
    <Styles.RulesRow>
      {availableRules?.map(appRule => {
        const { isEnabled } = appRule
        return <Col key={appRule.idApp}>
          <Tooltip label={'This account is not connected.\nConnect at integrations page'} hide={isEnabled} fullWidth>
            <SelectionBox
              groups={getRule({ appRule, isEnabled })}
              hideHeader
              selectedValue={selectedRuleIdApp}
              onChange={onChange}
              overrideStyle={{ margin: 0 }}
            />
          </Tooltip>
        </Col>
      })}
    </Styles.RulesRow>
  )
}
