import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  EMPTY_ARRAY,
  WORKFLOW_STEP_TYPES,
  WORKFLOW_VALIDATION_TYPES
} from '@root/constants'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import {
  getFlattenActionsConfig,
  getFieldsDefaultValues,
  getWorkflowActionDynamicFieldsOption,
  getActionsConfigByType
} from '@selectors/workflows'
import { getStepsAndFieldsWithDefaultValues } from '@shared/workflows/getStepsAndFieldsWithDefaultValues'

const mapStateToProps = (state, ownProps) => {
  const { action, triggerType } = ownProps
  const { idOrg } = ownProps.match.params

  const actionsConfig = getFlattenActionsConfig(state)
  const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === action.type)
  const fieldsDefaultValues = getFieldsDefaultValues(state)
  const actionsConfigByType = getActionsConfigByType(state)

  let fields = []

  const dynamicFieldsOptionsByActionId = getWorkflowActionDynamicFieldsOption(state)
  const dynamicFieldsOptions = dynamicFieldsOptionsByActionId[action.id] || EMPTY_ARRAY
  const isLoaded = !isEmpty(action) && action.type && actionsConfig.length > 0

  const { fields: actionFieldsDefaultValues } = isLoaded ? getStepsAndFieldsWithDefaultValues(triggerType, actionConfig, fieldsDefaultValues, actionsConfigByType) : {}
  if (actionFieldsDefaultValues) {
    const actionFieldsByType = keyBy(action.fields || [], 'id')
    fields = actionFieldsDefaultValues.map(fieldConfig => {
      const actionField = actionFieldsByType[fieldConfig.id]
      const isRequired = fieldConfig.validations.includes(WORKFLOW_VALIDATION_TYPES.REQUIRED)
      const dynamicOptions = dynamicFieldsOptions.find(f => f.id === fieldConfig.id)
      const options = fieldConfig.options || (dynamicOptions || {}).options
      const loading = dynamicFieldsOptionsByActionId.loading
      return { ...fieldConfig, ...actionField, isRequired, options, loading }
    })
  }

  return {
    idOrg,
    actionConfig,
    action,
    fields,
    componentType: WORKFLOW_STEP_TYPES.STEP_SETUP
  }
}

export default withRouter(connect(mapStateToProps)(View))
