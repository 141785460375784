import React from 'react'
import TextControl from '../../form/text-control'
import Headers from '../headers/index'
import { withRouter } from 'react-router-dom'
import { css } from 'glamor'
import { Field } from 'react-final-form'

const CSS = {
  form: css({
    ' .form-group': {
      marginBottom: '15px'
    }
  })
}

const validatePassword = (password) => {
  const MIN_LENGTH = 8
  const MAX_LENGTH = 50
  if (!password) {
    return 'Please choose a password'
  }

  if (password.toString().length < MIN_LENGTH) {
    return `Try something a bit longer (at least ${MIN_LENGTH} characters)`
  }

  if (password.toString().length > MAX_LENGTH) {
    return `Try something a bit shorter (up to ${MAX_LENGTH} characters)`
  }
}

class SetPasswordSignupStep extends React.Component {
  input = {}

  componentDidMount () {
    if (this.input.password) {
      this.input.password.focus()
    }
  }

  renderField = (props) => {
    const { meta, input, ...rest } = props
    return (
      <TextControl
        ref={(c) => (this.input[input.name] = c)}
        hasError={meta.touched && Boolean(meta.error)}
        error={meta.error}
        iconName='Lock'
        {...input}
        {...rest}
      />
    )
  }

  render () {
    const { isSAML } = this.props

    return (
      <div>
        {isSAML && (
          <Headers
            headerText='Complete Sign Up'
            subHeaderText={`You will be able to connect using your company's Single Sign On solution`}
          />
        )}

        {!isSAML && (
          <Headers
            headerText='Please choose your password'
            subHeaderText='Try something with at least 8 characters'
          />
        )}

        <div {...CSS.form}>
          <fieldset>
            {!isSAML && (
              <Field
                name='password'
                type='password'
                placeholder='Choose Password'
                component={this.renderField}
                validate={validatePassword}
              />
            )}
          </fieldset>
        </div>
      </div>
    )
  }
}

export default withRouter(SetPasswordSignupStep)
