import React, { ReactElement } from 'react'
import { Position } from 'reactflow'
import { TransparentHandle } from '../../transparentHandle'
import { Container, Label } from './styles'
import { BRANCH_TYPE } from './types'

interface Props {
  label: string
  branchType: BRANCH_TYPE
}

export const BranchLabelContent = ({ branchType, label }: Props): ReactElement => {
  return (
    <Container branchType={branchType}>
      <TransparentHandle type='target' position={Position.Top} />
      <Label title={label}>{label}</Label>
      <TransparentHandle type='source' position={Position.Bottom} />
    </Container>
  )
}
