import React from 'react'
import Table from '../../../table/index'
import { TABLES, SCOPES, MANUAL_WORKFLOW_EXECUTION_FLOW } from '@root/constants'
import colors from '../../../../shared/style/colors'
import { hoverActions } from '@shared/style/mixins'
import { getDisplayName } from '@lenses/users'
import UserDetails from '../../../userDetails'
import isEqual from 'lodash/isEqual'
import getColumnByFieldType from '../../../table/columnFieldTypes'
import { Tooltip, ButtonType } from '@toriihq/design-system'
import EnableFor from '../../../enableFor'
import { css } from 'glamor'
import * as Style from './style'
import { getSourceByType } from '@root/sourcesConfig'
import get from 'lodash/get'
import OriginalAndConvertedCurrencies from '@root/components/originalAndConvertedCurrencies'
import { CURRENCY_BADGE } from '@root/components/table/editableTableColumn/types/currency'
import { getFormattedDate } from '@lenses/utils'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { FEATURES } from '@root/shared/features'
import ButtonOfFeature from '@components/buttonOfFeature'

const CSS = {
  badge: css(CURRENCY_BADGE, {
    lineHeight: '14px',
    height: '17px'
  })
}
const getNameColumn = () => ({
  Header: 'Assigned to',
  id: 'fullName',
  accessor: ({ firstName, lastName, email }) => {
    const array = [firstName, lastName, email]
    return array.join(' ').trim().toLowerCase()
  },
  minWidth: 220,
  maxWidth: 250,
  Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl } }) => {
    return (
      <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin>
        <UserDetails
          firstName={firstName}
          lastName={lastName}
          email={email}
          isExternal={isExternal}
          idUser={idUser}
          photoUrl={photoUrl}
        />
      </EnableFor>
    )
  },
  textValue: ({ user: { firstName, lastName, email } }) => getDisplayName({ firstName, lastName, email })
})

class UsersList extends React.Component {
  constructor (props) {
    super(props)
    this.columns = this.getColumns()
  }

  componentDidMount () {
    const { getServicesConfig, idOrg } = this.props
    getServicesConfig({ idOrg })
  }

  componentDidUpdate (prevProps) {
    const { tableInfo: { dynamicColumnsInfo, columnsConfiguration } } = this.props
    const columnsConfigurationWasChanged = (
      !isEqual(prevProps.tableInfo.columnsConfiguration, columnsConfiguration) ||
      !isEqual(prevProps.tableInfo.dynamicColumnsInfo, dynamicColumnsInfo)
    )

    if (columnsConfigurationWasChanged) {
      this.columns = this.getColumns()
    }
  }

  getColumns = () => {
    const { tableInfo: { dynamicColumnsInfo }, idApp, idOrg } = this.props

    return [
      {
        Header: 'License',
        id: 'licenseName',
        accessor: 'license'
      },
      {
        Header: 'Account',
        accessor: 'appAccountName',
        Cell: ({ value: appAccountName }) => appAccountName || '-'
      },
      getNameColumn(),
      {
        Header: 'Email',
        id: 'originEmail',
        accessor: 'originEmail'
      },
      {
        accessor: 'email',
        show: false,
        textHeader: 'Email'
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        minWidth: 120,
        showFunc: () => Boolean(this.props.tableInfo.columnsConfiguration.reason)
      },
      {
        Header: <div {...Style.flex}>
          {this.columnHeaderToolTip('Last used', 'lastUsageTime', this.props.idApp)}
        </div>,
        accessor: 'lastUsageTime',
        Cell: ({ value: lastUsageTime }) => {
          return getFormattedDate({ date: lastUsageTime })
        },
        sortMethod: Table.sortMethods.date,
        minWidth: 160,
        showFunc: () => Boolean(this.props.tableInfo.columnsConfiguration.lastUsageTime)
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'idUser',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      },
      {
        accessor: 'pricePerUserConverted',
        show: false
      },
      {
        accessor: 'currency',
        show: false
      },
      {
        Header: 'Annual cost per license',
        accessor: 'pricePerUser',
        show: this.props.hasLicensesCostAndChargebackAccess && Boolean(this.props.tableInfo.columnsConfiguration.pricePerUser),
        resizable: false,
        Cell: ({ row }) => {
          const { pricePerUser, pricePerUserConverted, currency } = row
          return <div>
            { pricePerUser === 0 && <span {...CSS.badge}>FREE</span> }
            { pricePerUser === null && <div {...CSS.badge}>UNKNOWN</div> }
            { pricePerUser > 0 && <OriginalAndConvertedCurrencies value={pricePerUser} convertedValue={pricePerUserConverted} currency={currency} /> }
          </div>
        },
        width: 180,
        sortable: false,
        ...Table.numericFieldProps
      }
    ]
      .concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
      .concat([
        {
          Header: '',
          id: 'actions',
          sortable: false,
          width: 120,
          className: hoverActions.toString(),
          Cell: ({ row: userInfo, original: { originIdUser } }) => {
            userInfo = {
              ...userInfo,
              email: userInfo.originEmail,
              idUser: originIdUser,
              originIdUser
            }

            return (
              <EnableFor scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_WRITE, idOrg, idApp)]} allowForToriiAdmin>
                <ButtonOfFeature feature={FEATURES.WORKFLOWS.PAGE} type={ButtonType.secondary} onClick={() => this.toggleAction({ userInfo })} label='Take action' />
              </EnableFor>
            )
          },
          showFunc: () => Boolean(this.props.isSupportsWritePermission)
        }
      ])
  }

  toggleAction = ({ userInfo }) => {
    const { idApp, toggleConfigureExecuteActionOnUsers } = this.props
    const usersInfo = [userInfo]
    return toggleConfigureExecuteActionOnUsers({ isOpen: true, idApp, users: usersInfo, onConfig: () => {}, executionFlow: MANUAL_WORKFLOW_EXECUTION_FLOW.inactiveUsers, flowType: 'Inactive users' })
  }

  columnHeaderToolTipInfo = {
    'lastUsageTime': {
      [getSourceByType('salesforce').idApp]: 'Salesforce updates last used date with 2 days lag',
      [getSourceByType('office365').idApp]: 'Microsoft 365 updates last used date with 2 days lag'
    }
  }

  columnHeaderToolTip = (title, columnName, idApp) => {
    const info = get(this.columnHeaderToolTipInfo, [columnName, idApp])

    if (!info) {
      return title
    }

    return (
      <Tooltip label={info}>{title}</Tooltip>
    )
  }

  render () {
    const { licenses, loading, loadingMore, configurableColumnsOptions, onSearch, onSortedChangeCB, fetchData, totalCount, totalUsers, exportFunction, scrollObjectId = 'main' } = this.props
    const getHeader = props => `INACTIVE LICENSES (${totalUsers || props.data.length})`

    return (
      <Table
        tableKey={TABLES.inactiveUsersListPopup.key}
        data={licenses}
        columns={this.columns}
        header={getHeader}
        loading={loading}
        loadingMore={loadingMore}
        exportable
        exportFunction={exportFunction}
        searchable
        getTdProps={setTdStyle}
        configurableColumns
        configurableColumnsOptions={configurableColumnsOptions}
        forceShowSearch
        manual
        scrollObjectId={scrollObjectId}
        onSearch={onSearch}
        fetchData={fetchData}
        onSortedChangeCB={onSortedChangeCB}
        totalCount={totalCount}
      />
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

export default UsersList
