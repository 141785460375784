import { colors } from '../../shared/style/colors'

export const TIME_PERIODS = {
  'last_7_days': { type: 'last_7_days', label: 'Last 7 days', value: 7, unit: 'days' },
  'last_14_days': { type: 'last_14_days', label: 'Last 14 days', value: 14, unit: 'days' },
  'last_30_days': { type: 'last_30_days', label: 'Last 30 days', value: 30, unit: 'days' },
  'last_6_months': { type: 'last_6_months', label: 'Last 6 months', value: 6, unit: 'months' },
  'last_12_months': { type: 'last_12_months', label: 'Last 12 months', value: 12, unit: 'months' }
}

export const TIME_PERIOD_OPTIONS = Object.values(TIME_PERIODS).map(period => ({
  value: period.type,
  label: period.label
}))

export const USAGE = {
  active: { label: 'Active', id: 'active', getValue: license => license.assignedAmount - license.inactiveAmount },
  unassignedAmount: { label: 'Unassigned', id: 'unassignedAmount', getValue: license => license.unassignedAmount },
  leftOrg: { label: 'Left the org', id: 'leftOrg', getValue: license => license.leftOrg },
  noUsage: { label: 'No Usage', id: 'noUsage', getValue: license => license.noUsage },
  noAccess: { label: 'No access to account', id: 'noAccess', getValue: license => license.noAccess }
}

export const USAGE_OPTIONS = [
  {
    id: 'activeAmount',
    label: 'Active',
    children: [USAGE.active]
  },
  {
    id: 'unassgined',
    label: 'Unassigned',
    children: [USAGE.unassignedAmount]
  },
  {
    id: 'inactive',
    label: 'Inactive',
    children: [USAGE.leftOrg, USAGE.noUsage, USAGE.noAccess]
  }
]

export const USAGE_BOX = {
  active: { name: 'Active', key: 'active', color: colors.lightBlue4, types: [USAGE.active.id] },
  inactive: { name: 'Inactive', key: 'inactive', color: colors.orange2, types: [USAGE.leftOrg.id, USAGE.noUsage.id, USAGE.noAccess.id] },
  unassignedAmount: { name: 'Unassigned', key: 'unassignedAmount', color: colors.grey2, types: [USAGE.unassignedAmount.id] }
}
