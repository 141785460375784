import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

/**
 * This hook is an extension of the useEffect hook,
 * that works the same but will start working only after the component mounts
 */
const useEffectAfterMount = (effect: EffectCallback, deps: DependencyList = []) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      return effect?.()
    } else {
      didMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}

export default useEffectAfterMount
