import React from 'react'
import { Grid, Row, Col } from '@toriihq/design-system'
import AppUsers from '../../../components/appUsers'

const Users = ({ idApp, idOrg }) => (
  <Grid>
    <Row>
      <Col>
        <AppUsers idApp={idApp} idOrg={idOrg} />
      </Col>
    </Row>
  </Grid>
)

export default Users
