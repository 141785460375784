import styled from 'styled-components'

export namespace Styles {
    export const TextArea = styled.div`
      display: flex;
      padding: 0 24px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    `

    export const TextHeader = styled.div`
      font: ${({ theme }) => theme.typography.font.subtitle02};
    `

    export const TextList = styled.ul`
        padding-left: 20px;
        margin: 0;
    `

    export const Container = styled.div`
    `

    export const RuleSelectorContainer = styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px;
      gap: 16px;
    `
}
