import React from 'react'
import PropTypes from 'prop-types'
import { WORKFLOW_EXECUTION_ERROR_TYPES } from '../../../constants'
import { Link, Tooltip } from '@toriihq/design-system'
import DetailsDisplay from '@components/workflows/detailsDisplay'
import config from '@root/config'

export const getErrorMessageByType = ({ errorType, appName, showTooltip, errorDetails, idOrg }) => {
  let message
  switch (errorType) {
    case WORKFLOW_EXECUTION_ERROR_TYPES.noWritePermissions:
      message = 'Insufficient permissions. The user that integrated the app does not have the permission to perform this action'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.disabledService:
      message = <>Integration currently fails. See more details in the <Link href={`${config.appBaseUrl}/team/${idOrg}/services`} target='_blank'>Integrations page</Link>.</>
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.serviceNotConnected:
      message = <>Integration is not connected. Go to the <Link href={`${config.appBaseUrl}/team/${idOrg}/services`} target='_blank'>Integrations page</Link> to connect the integration.</>
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.notSupportedInPlan:
      message = `Torii is not allowed to manage your users. Consider upgrading your ${appName} plan to allow the requested level of access`
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.cannotDeactivateDefaultWorkflowUser:
      message = `Torii can't delete ${appName} default workflow user. To delete this user change the default workflow user in ${appName} dashboard`
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.userNotFound:
      message = 'User not found'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.userNotFoundInAccount:
      message = 'User not found in the selected account'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.userNotToriiUser:
      message = 'Selected user is not a Torii user'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.managerNotFoundInAccount:
      message = 'Manager not found in the selected account'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.groupNotFound:
      message = 'Group not found'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.memberAlreadyExists:
      message = 'Member already exists in group'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.noArchivedLicenses:
      message = 'Insufficient licenses for one or more products that are set to automatically assign licenses to archived users'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.invalidOrgUnit:
      message = 'Invalid Input: Org unit'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.invalidUserGroup:
      message = 'Invalid Input: User Group'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.invalidWebhookUrl:
      message = 'Invalid webhook URL'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.usernameAlreadyInUse:
      message = 'Username already in use'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.adminPermissionsAreRequired:
      message = 'Admin permissions are required in order to perform the action'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.transferFailed:
      message = `Transfer failed on ${appName} end, please check with ${appName} suppport${errorDetails && errorDetails.transferId ? ` (TransferId: ${errorDetails.transferId})` : ''}`
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.sourceUserNotFound:
      message = 'Source user not found'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.destinationUserNotFound:
      message = 'Destination user not found'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.memberNotFound:
      message = 'Member not found'
      break
    case WORKFLOW_EXECUTION_ERROR_TYPES.customHttpRequestFailed:
      message = <DetailsDisplay details={errorDetails} />
      break
    default:
      message = errorType
  }

  return showTooltip
    ? (
      <Tooltip
        label={message}>
        <span>{message}</span>
      </Tooltip>
    )
    : <span>{message}</span>
}

class ErrorMessage extends React.Component {
  getErrorMessage = () => {
    const { errorType, appName, showTooltip, errorDetails, match } = this.props
    const idOrg = parseInt(match.params.idOrg, 10)
    const message = getErrorMessageByType({ errorType, appName, showTooltip, errorDetails, idOrg })
    return showTooltip
      ? (
        <Tooltip
          label={message}>
          <span>{message}</span>
        </Tooltip>
      )
      : <span>{message}</span>
  }
  render () {
    return this.getErrorMessage()
  }
}

ErrorMessage.propTypes = {
  errorType: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool
}

ErrorMessage.defaultProps = {
  showTooltip: true
}

export default ErrorMessage
