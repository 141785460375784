import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import PropTypes from 'prop-types'
import RelativeTeamLink from '../relativeTeamLink'

const Breadcrumbs = ({ items, currentPageName, overrideStyle = {}, divider }) => {
  const CSS = {
    main: css(texts.body, overrideStyle),
    currentCrumb: css({
      color: colors.grey2
    }),
    previousCrumb: css({
      ':hover': {
        color: colors.blue
      }
    })
  }

  const shouldRemoveLastDivider = (stepIndex) => !currentPageName && stepIndex === (items.length - 1)

  return (
    <div {...CSS.main}>
      {items.map((item, index) => (
        <span key={item.name} {...CSS.previousCrumb}>
          <RelativeTeamLink to={item.path}>{item.name}</RelativeTeamLink>
          {shouldRemoveLastDivider(index) ? '' : ` ${divider} `}
        </span>
      ))}
      {currentPageName && <span {...CSS.currentCrumb}>{currentPageName}</span>}
    </div>
  )
}

Breadcrumbs.propTypes = {
  currentPageName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  overrideStyle: PropTypes.object,
  divider: PropTypes.string.isRequired
}

Breadcrumbs.defaultProps = {
  divider: '/'
}

export default Breadcrumbs
