import React from 'react'
import { css } from 'glamor'
import { AppAttributesDetails } from 'torii-components'
import colors from '@shared/style/colors'
import SelectDraggableOptions from '@components/selectDraggableOptions'
import { SCOPES } from '@root/constants'
import { Avatar, Col, Grid, Row } from '@toriihq/design-system'

const CSS = {
  previewContainer: css({
    minHeight: '578px',
    border: `1px solid ${colors.lightBlue2}`,
    padding: '24px 21px'
  }),
  previewFields: {
    marginTop: '30px'
  }
}

const SelectAppFields = (props) => {
  const { onChange, value = [], options = [] } = props

  const optionRenderer = (option) => (<div>{option.label}</div>)

  const fields = value.map(v => options.find(o => o.systemKey === v)).filter(Boolean)

  return (
    <Grid>
      <Row>
        <Col sm={7}>
          <SelectDraggableOptions
            optionRenderer={optionRenderer}
            onChange={onChange}
            value={value}
            options={options}
            placeholderText={'+ Add field'}
            allowedScopes={[SCOPES.APPLICATION_CATALOG_WRITE]}
          />
        </Col>
        <Col sm={5}>
          <div {...CSS.previewContainer}>
            <AppAttributesDetails>
              <AppAttributesDetails.Header />
              <AppAttributesDetails.Fields fields={fields} avatar={Avatar} style={CSS.previewFields} />
            </AppAttributesDetails>
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

export default SelectAppFields
