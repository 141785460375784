import { css } from 'glamor'
import colors from '@shared/style/colors'

export const revokeAccessInfoList = css({
  padding: '10px 14px',
  margin: '0px 14px'
})

export const provideAccessInfoList = css({
  padding: '0px 24px'
})

export const content = css({
  padding: '24px'
})

export const link = css({
  display: 'flex',
  height: '20px',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: `${colors.blue} !important`,
  '&:active, &:hover, &:focus': {
    color: colors.lightBlue1
  },
  cursor: 'pointer'
})

export const emailItem = css({
  display: 'flex',
  flexWrap: 'wrap'
})
