import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ButtonSize, ButtonType, Button, Tooltip } from '@toriihq/design-system'
import throttle from 'lodash/throttle'
import Analytics from '../../../helpers/analytics'
import pluralize from 'pluralize'
import EnableFor from '../../enableFor'
import Confirmation from '@components/confirmation'

class FieldActions extends React.Component {
  constructor (props) {
    super(props)

    this.state = { isEditOpen: false, isDeleteOpen: false }
    this.toggleDeleteFieldState = this.toggleDeleteFieldState.bind(this)
  }

  toggleDeleteFieldState (isClosedByUser = true) {
    const { id, name, itemName } = this.props
    const isDeleteOpen = !this.state.isDeleteOpen

    if (isDeleteOpen) {
      Analytics.track(`Clicked to open popup: delete ${itemName} field`, {
        'Field ID': id,
        'Field name': name
      })
    } else if (isClosedByUser) {
      Analytics.track(`Closed popup: delete ${itemName} field`, {
        'Field ID': id,
        'Field name': name
      })
    }
    this.setState({ isDeleteOpen })
  }

  deleteField = () => {
    const { id, idOrg, deleteDetailsField } = this.props
    deleteDetailsField({ idOrg, idField: id })
  }
  render () {
    const { isDeleteOpen } = this.state
    const { id, name, isPredefined, isPluginGroup, isReadOnlyGroup, numItemsUsed, toggleUpdateDetailsField, itemName, allowedScopes = [] } = this.props
    const isActionsDisabled = isPredefined || isPluginGroup || isReadOnlyGroup

    const deleteFieldConfirmation = <Confirmation
      isOpen={isDeleteOpen}
      header={`Delete '${name}' field?`}
      text={`This field is currently used in ${pluralize(itemName, numItemsUsed, true)}. By clicking "Delete", all data will be lost.`}
      confirmText='Delete'
      declineText='Cancel'
      decline={this.toggleDeleteFieldState}
      confirm={throttle(this.deleteField, 2000, { trailing: false })}
      close={this.toggleDeleteFieldState}
      mainButtonType={ButtonType.destructive}
    />

    const showTooltip = isPluginGroup || isReadOnlyGroup

    return (
      <Fragment>
        {(isDeleteOpen && deleteFieldConfirmation)}
        <Tooltip
          position='top'
          hide={!showTooltip}
          maxWidth={230}
          label={isPluginGroup ? 'This field was created automatically and cannot be edited.' : 'This field is defined by Torii and cannot be edited.'}
        >
          <EnableFor scopes={allowedScopes}>
            <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={isPluginGroup || isReadOnlyGroup} onClick={() => toggleUpdateDetailsField({ isOpen: true, idField: id, name })} icon='Edit' />
          </EnableFor>
        </Tooltip>
        <Tooltip
          position='top'
          hide={!isActionsDisabled}
          maxWidth={230}
          label={isPluginGroup ? 'This field was created automatically and cannot be deleted.' : 'This field is defined by Torii and cannot be deleted.'}
        >
          <EnableFor scopes={allowedScopes}>
            <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={isActionsDisabled} onClick={this.toggleDeleteFieldState} icon='Trash' />
          </EnableFor>
        </Tooltip>
      </Fragment>
    )
  }
}

FieldActions.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isPredefined: PropTypes.bool.isRequired
}

export default FieldActions
