import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import Placeholder from '../placeholder'
import numeral from 'numeral'
import texts from '../../shared/style/texts'
import Currency from '../currency'

const CSS = {
  container: css({
    display: 'flex',
    paddingRight: '30px'
  }),
  divider: css({
    borderTop: `1px solid ${colors.border}`,
    margin: '15px 0 11px 0'
  }),
  subHeader: css({
    fontSize: '25px',
    lineHeight: '40px',
    marginBottom: 0
  }),
  section: css({
    paddingLeft: '20px',
    paddingRight: '30px',
    height: '60px',
    ':first-child': {
      paddingLeft: 0
    },
    ':not(:last-child)': {
      borderRight: `1px solid ${colors.border}`
    },
    ':last-child': {
      paddingRight: 0
    }
  }),
  sectionTitle: css(texts.subheading, {
    textTransform: 'uppercase',
    color: colors.text,
    marginBottom: '5px'
  }),
  number: css(texts.headers.large)
}

const TotalApps = ({ loading, totalApps, totalOwner, annualCostConverted, showCostData }) => {
  const totalAppsValue = numeral(totalApps || 0).format('0,0')
  const totalOwnerValue = numeral(totalOwner || 0).format('0,0')

  return (
    <div {...CSS.container} >
      <Placeholder loading={loading} type='rect' style={{ height: '55px' }}>
        {showCostData && <div {...CSS.section}>
          <div {...CSS.sectionTitle}>Annual cost</div>
          <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '160px', maxWidth: '160px' }}>
            <div {...CSS.number}><Currency value={annualCostConverted} /></div>
          </Placeholder>
        </div>}
        <div {...CSS.section}>
          <div {...CSS.sectionTitle}>Applications</div>
          <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '160px', maxWidth: '160px' }}>
            <div {...CSS.number}>{totalAppsValue}</div>
          </Placeholder>
        </div>
        <div {...CSS.section}>
          <div {...CSS.sectionTitle}>Assigned as owner</div>
          <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '160px', maxWidth: '160px' }}>
            <div {...CSS.number}>{totalOwnerValue}</div>
          </Placeholder>
        </div>
      </Placeholder>
    </div>
  )
}

TotalApps.propTypes = {
  totalApps: PropTypes.number,
  loading: PropTypes.bool
}

export default TotalApps
