import React from 'react'
import { connect } from 'react-redux'
import Signup from '../../components/signup'
import BasicInformationSignupStep from '../../components/signup/basicInformationSignupStep'
import SetPasswordSignupStep from '../../components/signup/setPasswordSignupStep'
import { getEmailFromValidationKey } from './actions'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import SignupWizard from '../../components/signup/signupWizard'
import config from '../../config'
import colors from '../../shared/style/colors'
import { fontSize, fontWeight } from '../../shared/style/sizes'
import { css } from 'glamor'
import { Link } from '@toriihq/design-system'

const CSS = {
  tos: css({
    textAlign: 'left',
    marginTop: '30px',
    color: colors.grey2,
    fontSize: fontSize.small,
    fontWeight: fontWeight.normal
  })
}

const marginStyle = { margin: '20% auto' }

class SignupPage extends React.Component {
  state = { stepsComponents: [] }

  componentDidMount () {
    const { getEmailFromValidationKey, history } = this.props
    const { id, key } = this.props.match.params
    getEmailFromValidationKey(id, key)
      .then(this.setSteps)
      .catch(e =>
        history.replace(`/signup/validate/${id}/${key}`)
      )
  }

  setSteps = () => {
    const { webBaseUrl } = config

    const stepsComponents = [
      { component: BasicInformationSignupStep, options: { style: marginStyle } },
      { component: SetPasswordSignupStep,
        options: {
          style: marginStyle,
          shouldSubmit: true,
          message: () => <div {...CSS.tos}>
            <p>
              By signing up you agree to our {' '}
              <Link href={`${webBaseUrl}/terms`} target='_blank'>Terms of Use</Link>
              {' '} and {' '}
              <Link href={`${webBaseUrl}/privacy`} target='_blank'>Privacy Policy</Link>.
            </p>
          </div>
        }
      }
    ]

    this.setState({ stepsComponents })
  }

  render () {
    const { onSubmit, initialValues, isEmailValid, idRole } = this.props
    return (
      <div>
        <Signup overrideCss={marginStyle}>
          {isEmailValid && Boolean(this.state.stepsComponents.length) && <SignupWizard
            idRole={idRole}
            initialValues={initialValues}
            onSubmit={onSubmit}
            steps={this.state.stepsComponents}
          />}
        </Signup>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { validation, org, trialEndTime } = state.emailValidation
  const query = qs.parse(window.location.search)
  const next = query.next ? `?next=${encodeURIComponent(query.next)}` : ''

  return {
    isEmailValid: Boolean(validation),
    next,
    trialEndTime,
    initialValues: {
      companyName: org && org.companyName
    }
  }
}

const mapDispatchToProps = {
  getEmailFromValidationKey
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupPage))
