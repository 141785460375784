import { analytics } from './analytics'

export const handleExitDrawerAnalytics = ({
  idNode,
  triggerType,
  actionName,
  isCloseButton = false
}: {
    idNode: string
    triggerType: string,
    actionName?: string,
    isCloseButton?: boolean
  }) => {
  const buttonLocation = isCloseButton ? 'footer (Close)' : 'Top right corner (X)'
  if (idNode === 'trigger') {
    analytics.trigger.closePanel({
      triggerType,
      buttonLocation
    })
  } else {
    analytics.action.closePanel({
      triggerType,
      actionName: actionName!,
      buttonLocation
    })
  }
}
