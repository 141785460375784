import styled, { css } from 'styled-components'

export const DisabledApp = styled.div`
    cursor: not-allowed;
    ${({ appImageUrl }) =>
    appImageUrl && css` filter: grayscale(100%);`
}
    background-color: #E4EAF2;
`

export const Name = styled.span`
    width: unset;
`
