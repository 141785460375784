import { NODE_STATUS_TYPE } from '../workflowNodeContent/types'
import capitalize from 'lodash/capitalize'
import {
  GetActionStatusParams,
  GetActionStatusResult
} from './types'

export const getNodeStatus = ({
  isNew,
  isValid,
  isSelected,
  nodeType,
  customConfigurationText
}: GetActionStatusParams
): GetActionStatusResult => {
  if (isNew) {
    if (isSelected) {
      return {
        text: `Configuring ${nodeType}...`,
        statusType: NODE_STATUS_TYPE.REGULAR
      }
    }
    return {
      text: 'Click to configure',
      statusType: NODE_STATUS_TYPE.CALL_TO_ACTION
    }
  }
  if (!isValid) {
    return {
      text: `${capitalize(nodeType)} needs attention`,
      statusType: NODE_STATUS_TYPE.ERROR
    }
  }
  return {
    text: customConfigurationText || `${capitalize(nodeType)} configured`,
    statusType: NODE_STATUS_TYPE.REGULAR
  }
}
