import styled from 'styled-components'
import { ButtonType, ButtonSize } from '@toriihq/design-system'

export const Layout = styled.div<{ hideContinueOnErrorToggle: boolean }>`
  display: flex;
  padding: 24px;
  justify-content: ${({ hideContinueOnErrorToggle }) => hideContinueOnErrorToggle ? 'flex-end' : 'space-between'};
  align-items: center;
  align-self: stretch;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const ContinueOnErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ContinueOnErrorText = styled.span`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const getButtonStyleProps = (isLastStep) => isLastStep
  ? { label: 'Close', type: ButtonType.secondary, size: ButtonSize.medium }
  : { label: 'Next', type: ButtonType.primary, size: ButtonSize.medium }
