import PropTypes from 'prop-types'
import React from 'react'
import TextControl from '../form/text-control'
import Alert, { ALERT_TYPE } from '../alert/index'
import Headers from '../signup/headers/index'
import qs from 'query-string'
import { Button, Link } from '@toriihq/design-system'

class ResetPage extends React.Component {
  constructor (props) {
    super(props)

    this.input = {}
  }

  componentDidMount () {
    if (this.input.password) {
      this.input.password.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    const { reset, params } = this.props

    reset({
      id: params.id,
      key: params.key,
      password: this.input.password.value()
    })
  }

  render () {
    const { success, error, hasError, help, loading } = this.props
    const alerts = []

    if (success) {
      const query = qs.parse(window.location.search)
      const redirectPath = query.next || '/team'
      alerts.push(
        <div key='success'>
          <Alert type={ALERT_TYPE.SUCCESS}>
            Your password has been reset
          </Alert>
          <Link href={redirectPath}>Continue</Link>
        </div>
      )
    }

    if (error) {
      alerts.push(
        <Alert key='error' type={ALERT_TYPE.ERROR}>
          {error}
        </Alert>
      )
    }

    let formElements

    if (!success) {
      formElements = (
        <fieldset>
          <TextControl
            ref={(c) => (this.input.password = c)}
            name='password'
            type='password'
            placeholder='New password'
            hasError={hasError.password}
            error={help.password}
            disabled={loading}
            iconName='Lock'
          />
          <div className='text-right'>
            <Button
              label='Set Password'
            />
          </div>
        </fieldset>
      )
    }

    return (
      <section>
        <Headers
          headerText='Reset your password'
          subHeaderText='Enter your new password '
        />

        <form onSubmit={this.handleSubmit.bind(this)}>
          {alerts}
          {formElements}
        </form>
      </section>
    )
  }
}

ResetPage.propTypes = {
  params: PropTypes.object
}

export default ResetPage
