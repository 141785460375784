import moment from 'moment'

interface Props {
  durationUnit: 'days' | 'hours' | 'minutes'
  continueExecutionAt: string
}

export const getResumingInText = ({
  durationUnit,
  continueExecutionAt
}: Props): string => {
  const now = moment().utc()
  const resumeAt = moment(continueExecutionAt)

  const diffMinutes = resumeAt.diff(now, 'minutes', true)
  const diffHours = resumeAt.diff(now, 'hours', true)
  const diffDays = resumeAt.diff(now, 'days', true)

  if (diffMinutes < 1) {
    return '1 minute'
  }

  if (durationUnit === 'minutes' || diffHours < 1) {
    const roundedMinutes = Math.ceil(diffMinutes)
    return `${roundedMinutes} minutes`
  }

  if (durationUnit === 'hours' || diffDays < 1) {
    const roundedHours = Math.ceil(diffHours)
    return `${roundedHours} hours`
  }

  const roundedDays = Math.ceil(diffDays)
  return `${roundedDays} days`
}
