import styled from 'styled-components'

export const TabText = styled.span<{ isSelected: boolean }>`
  font: ${({ theme }) => theme.typography.font.component03};
  color: ${({ theme, isSelected }) => isSelected ? theme.palette.link.primary : theme.palette.text.secondary};
  text-align: center;
  transition: color .2s;
  
  &:hover {
    color: ${({ theme }) => theme.palette.link.primary};
  }
`
