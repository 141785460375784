import { GET_PENDING_OFFBOARDING_TASKS, GET_PENDING_OFFBOARDING_TICKETS } from '@root/constants.t'
import send from '@shared/redux-fetch'
import { GetPendingTasksProps, GetPendingTicketsProps } from './types'

export const getPendingOffboardingTasks = ({ idOrg }: GetPendingTasksProps) => {
  return send(GET_PENDING_OFFBOARDING_TASKS, {
    url: `/api/orgs/${idOrg}/offboarding/tasks`,
    method: 'GET'
  })
}

export const getPendingOffboardingTickets = ({ idOrg }: GetPendingTicketsProps) => {
  return send(GET_PENDING_OFFBOARDING_TICKETS, {
    url: `/api/orgs/${idOrg}/offboarding/tickets`,
    method: 'GET'
  })
}
