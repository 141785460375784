import React, { ReactElement } from 'react'
import Page from '@components/page'
import PageHeader from '@components/pageHeader'
import Audits from '@pages/workflowsExecutionsAudit/components/Audits'
import BackLink from '@components/backLink'

const WorkflowsExecutionsAudit = (): ReactElement => {
  return (
    <Page title='Actions log'>
      <div style={{ marginTop: '20px' }}>
        <BackLink navigateTo={`/workflows`} linkText={'Back to workflows'} />
      </div>
      <PageHeader title='Actions log' />
      <Audits />
    </Page>
  )
}

export default WorkflowsExecutionsAudit
