import React from 'react'
import PropTypes from 'prop-types'
import * as Style from './style'
import AppInfo from '@pages/appsComparison/components/chartWidget/appInfo'

const CustomGraphTooltip = (props) => {
  const { active, payload: appList, label, title, renderTooltipInfo = (_item) => {}, component = '' } = props

  if (!active || !appList || !appList.length) {
    return null
  }
  return (
    <div {...Style.Container}>
      <div {...Style.Title}>{title}</div>
      <div {...Style.Label}>{label}</div>
      <div {...Style.LegendContainer}>
        {appList.map((item, index) => {
          const data = item.payload
          return (
            <div key={`tooltip-${index}-${data.idApp}`} {...Style.Row}>
              <div {...Style.AppInfoContainer}>
                <AppInfo
                  idApp={data.idApp}
                  color={data.color}
                  name={data.name}
                  imageUrl={data.imageUrl}
                  component={component}
                  isBig={false}
                  enableOverflow={false}
                />
              </div>
              <div {...Style.InfoContainer}>{renderTooltipInfo(data)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

CustomGraphTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  label: PropTypes.string,
  title: PropTypes.string,
  renderTooltipInfo: PropTypes.func,
  component: PropTypes.string
}

export default CustomGraphTooltip
