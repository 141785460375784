import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'

export const PreviewLine = css(texts.subheading, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  color: colors.black,
  paddingBottom: '10px'
})

export const Spinner = css(texts.body, {
  color: colors.grey1,
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '10px'
})

export const Content = css({
  display: 'flex',
  minHeight: '60px',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
})

export const Left = css({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start'
})

export const SuccessSize = '72px'

export const Right = css({
  maxWidth: '64px',
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end'
})
