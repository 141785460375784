import React from 'react'
import PropTypes from 'prop-types'
import ConnectToriiBot from '../../service/connectToriiBot'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import validate from '@components/service/connectToriiBot/validation'
import { APP_PERMISSIONS, SCOPES, SYNC_TYPE } from '@root/constants'
import ConnectLink from '@components/service/connectLink'
import { getSourceByType } from '@root/sourcesConfig'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { FORM_ERROR } from 'final-form'
import Analytics from '@helpers/analytics'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const CSS = {
  connectLink: css({
    marginRight: '10px',
    width: '100%'
  }),
  footer: css({
    flexDirection: 'row-reverse',
    width: '100%'
  })
}

const formError = { [FORM_ERROR]: 'We could not connect the service, please try again' }

class ConnectToriiBotPopup extends React.Component {
  state = { selectServiceConfig: {}, selection: {} }

  componentDidMount () {
    this.updateState(this.props)
  }

  componentDidUpdate (prevProps) {
    if (this.props.servicesConfig !== prevProps.servicesConfig || this.props.initialValues.idApp !== prevProps.initialValues.idApp) {
      this.updateState(this.props)
    }
  }

  updateState = () => {
    const { servicesConfig, initialValues } = this.props
    const selectServiceConfig = servicesConfig.find(service => service.idApp === initialValues.idApp) || {}
    const selection = selectServiceConfig.idApp ? { value: selectServiceConfig.idApp, label: selectServiceConfig.name } : {}

    this.setState({ selectServiceConfig, selection })
  }

  onSubmit = (form) => {
    const { onConnect, reset, getServicesSyncData, idOrg, getSyncStatus, close, connectService } = this.props
    const { isReconnect } = connectService
    const { selection } = this.state
    const { user, password, url } = form
    const token = JSON.stringify({ user, password, url })

    if (isReconnect) {
      Analytics.track(`Click on reconnect button`, {
        'App name': selection.label
      })
    }

    return Promise.resolve(onConnect && onConnect({ idApp: connectService.idApp, source: getSourceByType('toriiBot').id, formData: { token, type: connectService.idApp } }))
      .then(res => {
        const error = get(res, 'response.error')
        if (error) {
          return formError
        }
        getSyncStatus && getServicesSyncData({ idOrg })
        setTimeout(reset, 1000)

        const SUCCESS_DELAY = 1000
        setTimeout(close, SUCCESS_DELAY)
      })
      .catch(e => formError)
  }

  onSelectedAppChange = ({ selection }) => {
    const { servicesConfig } = this.props
    const selectServiceConfig = servicesConfig.find(service => service.idApp === selection.value) || {}

    this.setState({ selectServiceConfig, selection })
  }

  onLinkClick = async (e) => {
    e.preventDefault()

    const { connectService: { isReconnect }, initialValues } = this.props
    const { selection, selectServiceConfig } = this.state
    const { connectFormConfiguration: { link } } = selectServiceConfig
    const header = this.getHeaderText({ initialValues, isReconnect, selection })

    window.open(link.url, '_blank', 'noopener,noreferrer')
    Analytics.track(`Click on how-to-set-up link`, {
      'Dialog name': header,
      'Link': link.url,
      'App name': selection.label
    })
  }

  render () {
    const { cancel, connectService, initialValues } = this.props
    const { isReconnect, showReconnectAlertInfo } = connectService

    const { selection, selectServiceConfig } = this.state
    const showConnectLink = !isEmpty(selection) || initialValues.idApp
    const { connectFormConfiguration: { link = {} } = {} } = selectServiceConfig

    const header = this.getHeaderText({ initialValues, isReconnect, selection })
    const { url, text } = link
    const subHeader = (url && text) && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.onLinkClick} href={url} label={text} />

    return <ToriiPopup
      isOpen={connectService.isOpen}
      onCloseAction={cancel}
    >
      <ToriiPopup.Header header={header} subHeader={subHeader} />
      <ToriiPopup.Form
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        validate={(values) => validate(values, this.state)}
        render={(formProps) => {
          return <ConnectToriiBot
            idApp={connectService.idApp}
            appName={connectService.appName}
            formProps={formProps}
            selectServiceConfig={selectServiceConfig}
            selection={selection}
            onSelectedAppChange={this.onSelectedAppChange}
            showReconnectAlertInfo={showReconnectAlertInfo}
          />
        }}
        renderFooter={(formProps) => {
          return <ToriiPopup.Footer
            showCancelButton={false}
            mainButtonText={isReconnect ? 'Reconnect' : 'Connect'}
            isMainSubmit
            recoveryTime={1000}
            formProps={formProps}
            scopes={[SCOPES.INTEGRATIONS_WRITE]}
            buttonsOverrideStyle={CSS.footer}
          >
            {showConnectLink && <ConnectLink idApp={selection.value} serviceName={selection.label} permission={APP_PERMISSIONS.read} syncType={SYNC_TYPE.TORII_BOT} overrideStyle={CSS.connectLink} />}
          </ToriiPopup.Footer>
        }}
      />
    </ToriiPopup>
  }

  getHeaderText ({ initialValues, isReconnect, selection }) {
    return initialValues.idApp ? `${isReconnect ? 'Reconnect' : 'Connect'} ${selection.label} to Torii` : 'Connect new integration'
  }
}

ConnectToriiBotPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default ConnectToriiBotPopup
