import { combineReducers } from 'redux'
import emailValidationReducer from '@reducers/emailValidation'
import signupReducer from '@reducers/signup'
import loginReducer from '@reducers/login'
import logoutReducer from '@reducers/logout'
import resetReducer from '@reducers/reset'
import forgotReducer from '@reducers/forgot'
import uiReducer from '@reducers/ui'
import meReducer from '@reducers/me'
import orgReducer from '@reducers/org'
import orgsReducer from '@reducers/orgs'
import updatesReducer from '@reducers/updates'
import similarAppsReducer from '@reducers/similarApps'
import appReducer from '@reducers/app'
import appsReducer from '@reducers/apps'
import usersReducer from '@reducers/users'
import userDetailsReducer from '@reducers/userDetails'
import automationsReducer from '@reducers/automations'
import appDetailsReducer from '@reducers/appDetails'
import servicesReducer from '@reducers/services'
import userSettingsReducer from '@reducers/userSettings'
import transactionsReducer from '@reducers/transactions'
import trendsReducer from '@reducers/trends'
import auditLogReducer from '@reducers/auditLog'
import instantAccessReducer from '@reducers/instantAccess'
import apiKeysReducer from '@reducers/apiKeys'
import licensesReducer from '@reducers/licenses'
import appUsersReducer from '@reducers/appUsers'
import workflowsReducer from '@reducers/workflows'
import reportsReducer from '@reducers/reports'
import hiddenAppsReducer from '@reducers/hiddenApps'
import customAppsReducer from '@reducers/customApps'
import offboardingReducer from '@reducers/offboarding'
import uploadsReducer from '@reducers/uploads'
import statsReducer from '@reducers/stats'
import productUpdatesReducer from '@reducers/productUpdates'
import contractsReducer from '@reducers/contracts'
import chargebackReducer from '@reducers/chargeback'
import rolesReducer from '@reducers/roles'
import samlConfigurationReducer from '@reducers/samlConfiguration'
import recommendationsReducer from '@reducers/recommendations'
import orgSecretsReducer from '@reducers/orgSecrets'
import settingsReducer from '@reducers/settings'
import discoveredAppsReducer from '@reducers/discoveredApps'
import calendarsReducer from '@reducers/calendars'

const rootReducer = combineReducers({
  apiKeys: apiKeysReducer,
  app: appReducer,
  appDetails: appDetailsReducer,
  apps: appsReducer,
  appUsers: appUsersReducer,
  auditLog: auditLogReducer,
  automations: automationsReducer,
  calendars: calendarsReducer,
  contracts: contractsReducer,
  chargeback: chargebackReducer,
  emailValidation: emailValidationReducer,
  forgot: forgotReducer,
  hiddenApps: hiddenAppsReducer,
  customApps: customAppsReducer,
  instantAccess: instantAccessReducer,
  licenses: licensesReducer,
  logout: logoutReducer,
  login: loginReducer,
  me: meReducer,
  offboarding: offboardingReducer,
  org: orgReducer,
  orgs: orgsReducer,
  orgSecrets: orgSecretsReducer,
  productUpdates: productUpdatesReducer,
  recommendations: recommendationsReducer,
  reports: reportsReducer,
  reset: resetReducer,
  roles: rolesReducer,
  services: servicesReducer,
  signup: signupReducer,
  similarApps: similarAppsReducer,
  stats: statsReducer,
  transactions: transactionsReducer,
  discoveredApps: discoveredAppsReducer,
  trends: trendsReducer,
  ui: uiReducer,
  updates: updatesReducer,
  uploads: uploadsReducer,
  users: usersReducer,
  userDetails: userDetailsReducer,
  userSettings: userSettingsReducer,
  workflows: workflowsReducer,
  samlConfiguration: samlConfigurationReducer,
  settings: settingsReducer
})

export default rootReducer
