import send from '@shared/redux-fetch'
import {
  CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION,
  CREATE_TEST_CONNECTION_ENTRY,
  GET_TEST_CONNECTION_INFO,
  TEST_CONNECTION,
  UPDATE_TEST_CONNECTION_ENTRY
} from '@root/constants'

export const testConnection = ({ idApp, idOrg, appToken, idTestConnection }) => {
  let body = { appToken, idApp }

  return send(TEST_CONNECTION, {
    url: `/api/orgs/${idOrg}/testConnection/${idTestConnection}/execute`,
    method: 'PUT',
    body
  })
}

export const getTestConnectionInfo = ({ idTestConnection, idOrg, idApp }) => {
  return send(GET_TEST_CONNECTION_INFO, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/testConnection/${idTestConnection}`,
    method: 'GET'
  })
}

export const createTestConnectionEntry = ({ data, idOrg }) => {
  return send(CREATE_TEST_CONNECTION_ENTRY, {
    url: `/api/orgs/${idOrg}/testConnection`,
    method: 'POST',
    body: data
  })
}

export const updateTestConnectionEntry = ({ data, idOrg, idTestConnection }) => {
  return send(UPDATE_TEST_CONNECTION_ENTRY, {
    url: `/api/orgs/${idOrg}/testConnection/${idTestConnection}`,
    method: 'PUT',
    body: { data }
  })
}

export const createSourceTokenAfterTestConnection = ({ source, idApp, formData, idTestConnection }) => {
  const body = { ...formData, source, idApp, idTestConnection }
  return send(CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION, {
    url: '/api/auth/testConnection/token',
    method: 'POST',
    body,
    meta: {
      source,
      permission: formData.permissions
    }
  })
}
