import React from 'react'
import PropTypes from 'prop-types'
import UploadAppDetailsAttachments from '@components/uploadAppDetailsAttachments'
import ToriiPopup from '../ToriiPopupV2'

const UploadAppDetailsAttachmentsPopup = ({ cancel, isOpen, close, idApp, idField, ...rest }) => {
  return (<ToriiPopup
    isOpen={isOpen}
    onCloseAction={cancel}
  >
    <ToriiPopup.Header header={'Upload file'} subHeader={<div>Upload your PDF, Word, Excel or image files here</div>} />
    <ToriiPopup.Content>
      <UploadAppDetailsAttachments idApp={idApp} idField={idField} isInsidePopup {...rest} />
    </ToriiPopup.Content>
  </ToriiPopup>)
}

UploadAppDetailsAttachmentsPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default UploadAppDetailsAttachmentsPopup
