import React from 'react'
import UploadFileDropzone from './uploadFileDropzone'
import PropTypes from 'prop-types'

class UploadAppDetailsAttachments extends React.Component {
  render () {
    const { idOrg, idApp, idField, uploadAppAttachment, isInsidePopup, toggleUploadAppDetailsAttachments } = this.props
    const onUpload = ({ idUpload }) => {
      uploadAppAttachment({ idOrg, idApp, idField, idUpload })
      toggleUploadAppDetailsAttachments(false, false)
    }
    return (
      <UploadFileDropzone
        onPostUpload={onUpload}
        idApp={idApp}
        idField={idField}
        isInsidePopup={isInsidePopup}
        showNotification
      />
    )
  }
}

UploadAppDetailsAttachments.propTypes = {
  idApp: PropTypes.number,
  idField: PropTypes.number,
  isInsidePopup: PropTypes.bool,
  isFinished: PropTypes.bool,
  handleFinished: PropTypes.func
}

export default UploadAppDetailsAttachments
