import React from 'react'
import PropTypes from 'prop-types'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import RelativeTeamLink from '@components/relativeTeamLink'

const RelativeTeamUserLink = (props) => {
  const { idUser, children, isSupportTeamUser, ...rest } = props

  return (
    <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin>
      {isSupportTeamUser ? <span>{children}</span> : <RelativeTeamLink to={`/user/${idUser}`} {...rest}>
        {children}
      </RelativeTeamLink>}
    </EnableFor>
  )
}

RelativeTeamUserLink.propTypes = {
  isSupportTeamUser: PropTypes.bool,
  idUser: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}

RelativeTeamUserLink.defaultProps = {
  isSupportTeamUser: false
}

export default RelativeTeamUserLink
