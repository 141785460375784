import styled, { css } from 'styled-components'
import { BRANCH_LABEL_DIMENSIONS } from '../../../consts'
import { BRANCH_TYPE } from './types'

const { height, width } = BRANCH_LABEL_DIMENSIONS

const getColorStyles = (branchType: BRANCH_TYPE) => {
  const typeToColorMap = {
    [BRANCH_TYPE.REGULAR]: css`
      color: ${({ theme }) => theme.palette.text.success};
      background-color: ${({ theme }) => theme.palette.background.successSubtleHover}
    `,
    [BRANCH_TYPE.DEFAULT_BRANCH]: css`
      color: ${({ theme }) => theme.palette.background.inverse};
      background-color: ${({ theme }) => theme.palette.background.errorSubtleHover}
    `
  }
  return typeToColorMap[branchType]
}

export const Container = styled.div<{ branchType: BRANCH_TYPE }>`
  height: ${height}px;
  width: ${width}px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: grab;
  border-radius: ${({ theme }) => theme.corner('radius-075')};
  font: ${({ theme }) => theme.typography.font.subtitle01};
  ${({ branchType }) => getColorStyles(branchType)}
`

export const Label = styled.div`
  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
