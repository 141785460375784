import styled from 'styled-components'
import { WORKFLOW_NODE_CLASS_NAME } from '@pages/workflow_v2/workflowEditor/workflowGraph/consts'
import { NODE_STATUS_TYPE } from './types'

const subtitleHeight = '20px'
const textContainerGap = '4px'
const transitionDuration = '0.2s'
const statusErrorClassName = `status-${NODE_STATUS_TYPE.ERROR}`
const statusCallToActionClassName = `status-${NODE_STATUS_TYPE.CALL_TO_ACTION}`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  gap: 16px;
`

export const IconContainer = styled.div`
  flex: 0 0 48px;
`

export const Title = styled.div<{ transform?: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.component02};
  transition: transform ${transitionDuration};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ transform }) => transform && `
    transform: translateY(calc((${subtitleHeight} + ${textContainerGap}) / 2));
  `}
`

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font: ${({ theme }) => theme.typography.font.body02};
  height: ${subtitleHeight};
  opacity: 0;
  transition: opacity ${transitionDuration};

  color: ${({ theme }) => theme.palette.text.tertiary};

  .${statusErrorClassName} > & {
    color: ${({ theme }) => theme.palette.text.error};
  }

  .${statusCallToActionClassName} > & {
    color: ${({ theme }) => theme.palette.link.primary};
  }
`

export const TextContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s;
  gap: ${textContainerGap};

  &.${statusErrorClassName},
  &.${statusCallToActionClassName},
  .${WORKFLOW_NODE_CLASS_NAME}:is(:hover, .selected) & {
    ${Title} {
      transform: translateY(0);
    }

    ${Subtitle} {
      opacity: 1;
    }
  }
`
