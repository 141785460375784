import React from 'react'
import UserDetails from '../userDetails'
import { getDisplayName } from '@lenses/users'
import { USERS_TABLE_COLUMNS } from '@root/tableColumnsMappingConst'

export const getNameColumn = ({ showExternalUserBadge = false } = {}) => ({
  Header: 'Name',
  id: 'name',
  accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
  minWidth: 220,
  maxWidth: 350,
  Cell: ({ row: { firstName, lastName, idUser, id, email, isExternal, photoUrl, lifecycleStatus } }) => (
    <UserDetails
      firstName={firstName}
      lastName={lastName}
      email={email}
      isExternal={isExternal}
      idUser={idUser || id}
      showExternalUserBadge={showExternalUserBadge}
      photoUrl={photoUrl}
      lifecycleStatus={lifecycleStatus}
    />
  ),
  textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email })
})

export const filterOutNotAccessableColumns = ({ columns, columnsRestrictions }) => {
  return columns.filter(col => {
    if (!columnsRestrictions.hasLicenseCostAndChargebackAccess) {
      const columnsThatRequireLicenseCostAndChargbackAccess = [USERS_TABLE_COLUMNS.ANNUAL_COST_CONVERTED]
      return !columnsThatRequireLicenseCostAndChargbackAccess.includes(col.value)
    }
    return true
  })
}
