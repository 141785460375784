import React from 'react'
import { css } from 'glamor'
import config from '../../config'
import moment from 'moment'
import Analytics from '../../helpers/analytics'
import { colors } from '../../shared/style/colors'
import unexpectedError from '@media/unexpectedError.svg'
import { Icon, H4, Body1, Button, ButtonType, Caption1, Link } from '@toriihq/design-system'
import SubscribeButton from '@components/subscription/subscribeButton'
import * as Style from './style'
import { PLANS } from '@shared/features'

const CSS = {
  main: css({
    marginTop: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    gap: '30px',
    [`@media(max-width: 1024px)`]: {
      flexDirection: 'column'
    }
  }),
  content: css({
    marginTop: '230px',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    [`@media(max-width: 1024px)`]: {
      marginTop: '50px'
    }
  }),
  logout: css({
    position: 'absolute',
    right: '60px'
  }),
  link: css({
    color: colors.blue
  })
}

class NoPermissionsPage extends React.Component {
  componentDidMount () {
    const { me, paidPlanExpired, getOrg } = this.props
    const { isDisabled, trialEndTime } = me.org || {}
    const isTrialEnded = !isDisabled && moment() > moment(trialEndTime)

    if (me.idOrg) {
      getOrg({ idOrg: me.idOrg })
    }

    const analyticsMessage = isTrialEnded ? 'User with a trial ended account visited Torii' : 'User reached "no permissions" page'
    Analytics.track(analyticsMessage, {
      isDisabled: Boolean(isDisabled),
      isTrialEnded,
      paidPlanExpired,
      email: me.email,
      firstName: me.firstName,
      lastName: me.lastName
    })
  }

  contactSupport () {
    const newWindow = window.open('https://support.toriihq.com/hc/en-us/requests/new', '_blank')
    newWindow.opener = null
  }

  renderContent () {
    const { me: { org = {} }, paidPlanExpired } = this.props
    const { isCatalogEnabledForMembers, isDisabled, companyName, trialEndTime, plan } = org
    const isTrialEnded = moment() > moment(trialEndTime)
    const contactEmail = 'contactus@toriihq.com'

    const title = isTrialEnded ? 'Your free trial has ended' : 'Your subscription has ended'

    if (isDisabled || isTrialEnded || paidPlanExpired) {
      return (
        <Style.ContentWrapper>
          <img alt='torii' src={unexpectedError} height='150px' width='336px' />
          <Style.TextWrapper>
            <Style.FlexText>
              <div>
                <H4>{title}</H4>
                { plan.type === PLANS.BASIC && <Body1>Subscribe to continue using Torii</Body1> }
                { plan.type === PLANS.BASIC && <Style.ButtonsWrapper>
                  { !isDisabled && <SubscribeButton /> }
                  <Button type={ButtonType.secondary} onClick={this.contactSupport} label={'Contact Support'} />
                </Style.ButtonsWrapper> }
                <Style.ContactSupportWrapper>
                  { plan.type !== PLANS.BASIC && <Body1>For further questions, reach out to us at <br /> <a {...CSS.link} href={contactEmail}>{contactEmail}</a></Body1> }
                </Style.ContactSupportWrapper>
              </div>
              <Style.Disclaimer>
                <Caption1>Your account and all associated data will be automatically deleted in a few days if you do not subscribe</Caption1>
              </Style.Disclaimer>
            </Style.FlexText>
          </Style.TextWrapper>
        </Style.ContentWrapper>
      )
    }

    return (
      <>
        <Body1>It seems that you do not have the required permissions to access Torii. Please contact your IT team.</Body1>
        {Boolean(isCatalogEnabledForMembers) && <Body1>Looking for <Link target='_blank' href={config.catalogBaseUrl}>{`${companyName}'s Application Catalog`}</Link>?</Body1>}
      </>
    )
  }

  render () {
    return (
      <div {...CSS.main}>
        <div>
          <a href='/login/logout' {...CSS.logout}>
            <Icon name='Logout' color='primary' /> <span>Log out</span>
          </a>
        </div>
        <div {...CSS.content}>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

export default NoPermissionsPage
