import React from 'react'
import { css } from 'glamor'
import Input from '../form/input'
import Select from '../select'
import get from 'lodash/get'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

const CSS = {
  container: css({
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    width: 160,
    gap: 32
  })
}

export const types = {
  ago: 'ago',
  from: 'from'
}

const optionsTypes = [{ label: 'days ago', value: types.ago }, { label: 'days from now', value: types.from }]

class RelativeDaySelect extends React.Component {
  onChange = (changedValue) => {
    const { value, onChange } = this.props
    onChange({ ...value, ...changedValue })
  }

  render () {
    const { value, overrideStyle, disabled } = this.props

    return (
      <div {...css(CSS.container, overrideStyle)}>
        <Input
          type='number'
          defaultValue={get(value, 'number')}
          onBlur={e => this.onChange({ number: get(e, ['target', 'value']) })}
          disabled={disabled}
          min={0}
        />
        <Select
          value={get(value, 'type')}
          options={optionsTypes}
          onChange={value => this.onChange({ type: value })}
          clearable={false}
          disabled={disabled}
        />
      </div>
    )
  }
}

RelativeDaySelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    number: PropTypes.number,
    type: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf(optionsTypes.map(type => type.value))
    })
  })
}

RelativeDaySelect.defaultProps = {
  onChange: noop
}

export default RelativeDaySelect
