export const USERS_IMPORT_PARSING_ERRORS = {
  NO_VALID_USERS: 'NO_VALID_USERS',
  LARGE_FILE: 'LARGE_FILE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NO_HEADERS: 'NO_HEADERS'
}

export const UPLOAD_ERROR_TEXTS = {
  [USERS_IMPORT_PARSING_ERRORS.NO_VALID_USERS]: 'Unable to process user list. No user email found.',
  [USERS_IMPORT_PARSING_ERRORS.LARGE_FILE]: 'The file contains more than 5000 rows.',
  [USERS_IMPORT_PARSING_ERRORS.UNKNOWN_ERROR]: 'Upload failed.',
  [USERS_IMPORT_PARSING_ERRORS.NO_HEADERS]: 'Unable to process user list. No headers found in the file.'
}

export const PARSING_STATUS = {
  parsingInProgress: 'parsing_in_progress',
  parsingSucceeded: 'parsing_succeeded',
  parsingFailed: 'parsing_failed'
}
