import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import InputField from '@components/popups/inputPopup/input'
import { simpleEmailRegex } from '@root/constants'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2'

const CSS = {
  form: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '-5px 0px -28px 0px'
  })
}

class CreateUser extends React.Component {
  componentDidMount () {
    const { email } = this.props

    this.context.submitAction.current = this.onSubmit
    this.context.setInitialValues({
      email,
      firstName: '',
      lastName: ''
    })
  }

  onSubmit = async (values) => {
    const { createUserReporting, idOrg, onSuccess, actionType, creator } = this.props
    const firstName = values.firstName || null
    const lastName = values.lastName || null

    const result = await createUserReporting({ idOrg, actionType, creator, email: values.email, firstName, lastName })

    onSuccess(result)
  }

  fetchData = async (searchValue) => {
    const { idOrg, searchUsersByEmail } = this.props
    const response = await searchUsersByEmail({ idOrg: idOrg, email: searchValue, limit: 1, searchByCanonicalEmail: true })
    return response.users
  }

  validateEmail = async value => {
    if (!value) {
      return 'Required'
    }
    if (!simpleEmailRegex.test(value)) {
      return 'The email address is incorrect'
    }

    const users = await this.fetchData(value)
    if (users.length) {
      return 'A user with this email already exists'
    }
  }

  render () {
    const { handleSubmit } = this.props
    return (
      <form {...CSS.form} onSubmit={handleSubmit} >
        <Field
          name='email'
          type='email'
          label='Email:'
          component={InputField}
          isRequired
          maxLength={50}
          validate={this.validateEmail}
        />
        <Field
          name='firstName'
          label='First Name:'
          component={InputField}
          maxLength={50}
          autoFocus={false}
          validateFields={[]}
        />
        <Field
          name='lastName'
          label='Last Name:'
          component={InputField}
          maxLength={50}
          autoFocus={false}
          validateFields={[]}
        />
      </form>
    )
  }
}

CreateUser.contextType = ToriiPopupContext

CreateUser.propTypes = {
  idOrg: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  createUserReporting: PropTypes.func.isRequired,
  toggleAddUser: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  searchUsersByEmail: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array,
  actionType: PropTypes.string,
  creator: PropTypes.string
}

CreateUser.defaultProps = {
  selectedUsers: []
}
export default CreateUser
