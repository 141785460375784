import React from 'react'
import BarsChartBox from '../barsChartBox'
import colors from '../../shared/style/colors'
import { CONTENT_TOOLTIP_TYPES } from '@components/barsChartBox/chart/view'
import { formatNumber, safeWindowOpen } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import NotEnoughUsage from '@media/people.svg'
import { Button, ButtonType, Link } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'
import { useHistory } from 'react-router-dom'

const getEmptyStateInfoNoUsage = (appName) => {
  const onClick = () => {
    Analytics.track('Click on learn how active users data is synced with Torii', {
      from: 'Empty state - active users trend'
    })
    safeWindowOpen('https://support.toriihq.com/hc/en-us/articles/5129309596955')
  }
  return {
    image: <img src={NotEnoughUsage} alt='No active users data' />,
    description: <div>Torii does not have data about user activity.<br />Make sure {appName} is connected to your SSO
      provider and browser extension is fully deployed.</div>,
    link: <Link onClick={onClick}>Read how to enrich user activity data</Link>
  }
}

const getEmptyStateInfoNoUsageInLast12Months = (service = {}, appName) => {
  const { supportsUsage, helpCenterLink } = service
  const isServiceSupportsUsage = !isEmpty(service) && supportsUsage

  const baseText = `Torii did not detect any user activity in last 12 months.`
  const subHeaderText = `${baseText} ${isServiceSupportsUsage ? `Make sure you are syncing usage from ${appName} API.` : `Make sure  ${appName} is connected to your to SSO provider and browser extension is fully deployed.`}`

  const linkText = isServiceSupportsUsage ? `Read more in Torii help center` : `Read how to enrich user activity data`
  const link = isServiceSupportsUsage ? helpCenterLink : 'https://support.toriihq.com/hc/en-us/articles/5129309596955'

  return {
    image: <img src={NotEnoughUsage} alt='No active users data in last 12 months' />,
    description: subHeaderText,
    link: <Link href={link} target='_blank'>{linkText}</Link>
  }
}

const getEmptyStateInfoOnlyExtensionLowUsage = (idOrg, history) => {
  const onClickButton = () => {
    Analytics.track('Click on view extension report link', {
      from: 'Empty state - User activity (app page chart)'
    })
    history.push(`/team/${idOrg}/reports/extensionRollout`)
  }

  const onClickLink = () => {
    Analytics.track('Click on Read more in Torii help center', {
      from: 'Empty state - User activity (app page chart)'
    })
    safeWindowOpen('https://support.toriihq.com/hc/en-us/articles/5129309596955')
  }
  return {
    image: <img src={NotEnoughUsage} alt='Not enough data to display in user activity trend chart' />,
    description: 'Torii does not have enough data to display user activity trend. The app has activity only from browser extension that is not fully deployed.',
    buttons: [<Button type={ButtonType.secondary} onClick={onClickButton} label='View extension status report' />],
    link: <Link onClick={onClickLink}>Read how to enrich app usage data</Link>
  }
}

const ActiveUsersTrendChartBox = ({ idOrg, data, isInUse, loading, appName, service, overrideStyle, sources, isSmallScreen, disabledSource, isOnlyExtensionLowUsage, noActiveUsersInLast12Months, showEmptyState }) => {
  const history = useHistory()
  if (!isInUse && !loading) {
    return null
  }

  const emptyStateInfo = isOnlyExtensionLowUsage
    ? getEmptyStateInfoOnlyExtensionLowUsage(idOrg, history)
    : noActiveUsersInLast12Months
      ? getEmptyStateInfoNoUsageInLast12Months(service, appName)
      : getEmptyStateInfoNoUsage(appName)

  return (
    <div {...overrideStyle}>
      <BarsChartBox
        loading={loading}
        header='User activity'
        description={`Users that logged in or used the app at least once`}
        data={data}
        sources={sources}
        emptyStateInfo={emptyStateInfo}
        showEmptyState={showEmptyState}
        appName={appName}
        fill={colors.navy}
        isSmallScreen={isSmallScreen}
        disabledSource={disabledSource}
        tooltipType={CONTENT_TOOLTIP_TYPES.KEY_VALUE}
        formatter={(value) => formatNumber(value)}
        keyValueFormatter={(value) => formatNumber(value)}
      />
    </div>
  )
}

export default ActiveUsersTrendChartBox
