import React from 'react'
import { Styles } from './style'
import { getFormattedDate } from '@root/lenses/utils'
import PluginModel, { Publicity } from '@pages/pluginMarketplace/types'
import PluginCategories from './PluginCategories'
import PluginDetail from './pluginDetail'
import PluginInstalledBy from './pluginInstalledBy'

const PluginDetails = ({ plugin }: { plugin: PluginModel }) => {
  return (
    <Styles.PluginAside>
      {plugin.rectLogoUrl ? (
        <Styles.PluginLogo alt={plugin.name} src={plugin.rectLogoUrl} title={plugin.name} />
      ) : (
        <Styles.PluginLogoFallback>
          <Styles.InnerText>{plugin.name.slice(0, 2)}</Styles.InnerText>
        </Styles.PluginLogoFallback>
      )
      }
      <Styles.PluginDetails>
        <PluginDetail label={'Version'} value={plugin.version} />
        <PluginDetail label={'Availability'} value={plugin.isPublic ? Publicity.Public : Publicity.Private} />
        {
          (plugin.isInstalled)
            ? <PluginInstalledBy installedBy={plugin.installedBy} installationDate={getFormattedDate({ date: plugin.installationDate })} />
            : null
        }
        <PluginCategories categories={plugin.categories} />
      </Styles.PluginDetails>
    </Styles.PluginAside>
  )
}

export default PluginDetails
