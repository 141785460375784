import React, { PropsWithChildren } from 'react'
import DeleteResources from '@media/delete_resources.svg'
import { Container, ContentContainer, Img } from './styles'

const PopupContent = ({ children }: PropsWithChildren<{}>): JSX.Element => (
  <Container>
    <ContentContainer>{children}</ContentContainer>
    <Img src={DeleteResources} />
  </Container>
)

export default PopupContent
