import styled from 'styled-components'

export const FlexCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const VerticalRule = styled.div`
  width: 1px;
  height: 20px;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.palette.border.primary};
`

export const Heading = styled.span``

export const Text = styled.div``

export const Link = styled(Text)`
  color: ${({ theme }) => theme.palette.text.interactive};
`
