import { createSelector } from 'reselect'
import { identity, get } from 'lodash'
import { getPendingChangesPreviewData } from '@components/popups/discoveredAppsPreviewPopup/utils'

export const getDiscoveredApps = createSelector(
  state => state.discoveredApps.discoveredApps,
  identity
)

export const getPendingChanges = createSelector(
  state => state.discoveredApps.pendingChanges,
  identity
)

export const getDiscoveredAppsWithPendingChanges = createSelector(
  [getDiscoveredApps, getPendingChanges],
  (apps, pendingChanges) => {
    return apps.map(app => ({
      ...app,
      ...pendingChanges[app.id]
    }))
  }
)

export const getDiscoveredAppsResources = createSelector(
  state => get(state, ['discoveredApps', 'resources'], {}),
  identity
)

export const getModifyDiscoveredAppsResources = createSelector(
  state => get(state, ['discoveredApps', 'pendingChangesResources'], {}),
  identity
)

export const getDiscoveredAppsResourcesWithPendingChangesResources = createSelector(
  [getDiscoveredAppsResources, getModifyDiscoveredAppsResources],
  (resources, newResources) => {
    return { ...resources.apps, ...newResources }
  }
)

export const getTotalDiscoveredApps = createSelector(
  state => get(state, ['discoveredApps', 'totalDiscoveredApps'], 0),
  identity
)

export const getIsLoadingDiscoveredApps = createSelector(
  state => state.discoveredApps.loading,
  identity
)

export const getIsLoadingMoreDiscoveredApps = createSelector(
  state => state.discoveredApps.loadingMore,
  identity
)

export const getPendingChangesPreview = createSelector(
  state => state.discoveredApps.pendingChangesPreview.previewData || [],
  (rawPreviewData) => getPendingChangesPreviewData(rawPreviewData)
)
