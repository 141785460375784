import { USER_FIELDS_TYPES } from '@root/constants'

type IdUser = number
export interface User {
  id: IdUser
  firstName: string
  lastName: string
  value: IdUser
}

export type UserDetailValue = string | User | null

export type UserFieldType = typeof USER_FIELDS_TYPES[keyof typeof USER_FIELDS_TYPES];

export enum FIELD_KEY {
  previousValue = 'previousValue',
  newValue = 'newValue'
}
export interface ChangedUserDetails {
  idAppAccount: number
  idApp: number
  userField: {
    key: string
    type: UserFieldType
    label: string
  }
  previousValue: UserDetailValue
  newValue: UserDetailValue
}

interface FieldConfiguration {
  id: string
  value: any
  isValid: boolean
  error: string
}

export interface TriggerConfiguration {
  id: string
  value: FieldConfiguration[]
}
