import React from 'react'
import AppDetails from '../appDetails'
import { getDisplayName } from '@lenses/users'
import Table from '../table'
import get from 'lodash/get'
import getColumnByFieldType, { DatePickerCell } from '../table/columnFieldTypes'
import SelectContractStatus from '../selectContractStatus'
import AssignContractUserButton from '../assignContractUserButton'
import { css } from 'glamor'
import { colors } from '@shared/style/colors'
import { formFieldTypes, SCOPES } from '@root/constants'
import { fontWeight } from '@shared/style/sizes'
import capitalize from 'lodash/capitalize'
import { Tooltip, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import EnableFor from '../enableFor'
import { hoverActions } from '@root/shared/style/mixins'
import { setDuplicatedContract } from '@lenses/contracts'
import { getContractCurrencyValue, getContractCurrencyConvertedValue, getContractCurrencyCode } from '@components/contracts/utils'
import isNumber from 'lodash/isNumber'
import OriginalAndConvertedCurrencies from '@components/originalAndConvertedCurrencies'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import UsersIconsList from '../usersIconsList/view'
import { compact } from 'lodash'
import ContractImg from '@media/contract.svg'
import UserDetails from '../userDetails/view'
import moment from 'moment'

const insideTableRowsClass = '.rt-tr:hover &'

const CSS = {
  nameCellContainer: css({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    gap: '4px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    fontWeight: fontWeight.semiBold,
    outline: 'none',
    [insideTableRowsClass]: {
      color: colors.blue
    }
  }),
  contractActions: hoverActions
}

export const ContractNameCell = ({ name, idContract, onEditContract }) => {
  return <button type='button' {...CSS.nameCellContainer} onClick={() => onEditContract(idContract)}>
    <img alt='contract' src={ContractImg} width={24} height={24} /> {name}
  </button>
}

const AppDetailsCell = ({ value: idApp }, appsById) => {
  const app = appsById[idApp] || {}
  return <AppDetails
    id={app.id}
    name={app.name}
    category={app.category}
    imageUrl={app.imageUrl}
    component='Contracts list'
  />
}
const SelectStatusCell = ({ status, idContract, contractStatusOptions, idApp }) => {
  const isLoading = contractStatusOptions.length === 0
  return <SelectContractStatus options={contractStatusOptions} idApp={idApp} idContract={idContract} isLoading={isLoading} selectedValue={status} />
}
const UserCell = ({ value: idUser, row: { id, idApp, idOrg } }, systemKey, usersById, label) => {
  const user = idUser ? usersById[idUser] || {} : null
  return <AssignContractUserButton systemKey={systemKey} idContract={id} idApp={idApp} user={user} label={label} idOrg={idOrg} />
}
const MultiUserCell = ({ value, usersById }) => {
  const users = compact(value?.map(idUser => usersById[idUser]) ?? [])
  return users?.length ? <UsersIconsList users={users} maxUsers={4} /> : null
}

const duplicateActionButton = ({ onDuplicateContract, contract, contractsFields, idOrg, idApp }) => {
  return <Tooltip label='Duplicate'>
    <EnableFor scopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}>
      <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={() => {
        const details = setDuplicatedContract(contract, contractsFields)
        onDuplicateContract(details)
      }} icon='Copy' />
    </EnableFor>
  </Tooltip>
}

export const deleteActionButton = ({ onDeleteContract, idContract, contractName, idOrg, idApp = null }) => {
  const onClickEvent = (event) => {
    event.stopPropagation()
    onDeleteContract({ idContract, contractName, buttonLabel: 'Delete' })
  }
  return <Tooltip label='Delete'>
    <EnableFor scopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}>
      <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={onClickEvent} icon='Trash' />
    </EnableFor>
  </Tooltip>
}

const getSpecialColumns = ({ columns, contractStatusOptions, contractStatusOptionsOrder, columnsConfiguration, preDefinedColumnsMapping, appsById, usersById, onEditContract, onDuplicateContract, contractsFields, onDeleteContract, idOrg, idApp }) => {
  return [
    {
      id: 'actions',
      Header: '',
      sortable: false,
      resizable: false,
      fixed: 'right',
      Cell: ({ row: { id: idContract, name, _original } }) => {
        return <div {...CSS.contractActions}>
          {duplicateActionButton({ onDuplicateContract, contract: _original, contractsFields, idOrg, idApp })}
          {deleteActionButton({ onDeleteContract, idContract, contractName: name, idOrg, idApp })}
        </div>
      },
      width: 100,
      show: true,
      hideFromCSV: true
    },
    {
      Header: 'Contract Id',
      accessor: 'id',
      forceCSV: true,
      show: Boolean(columnsConfiguration.id)
    },
    {
      Header: get(columns, ['name', 'name'], ''),
      accessor: 'name',
      minWidth: 200,
      maxWidth: 300,
      style: {
        paddingLeft: '5px'
      },
      show: Boolean(columnsConfiguration.name),
      Cell: ({ value: name, row: { id: idContract } }) => ContractNameCell({ name, idContract, onEditContract })
    },
    {
      Header: preDefinedColumnsMapping.idApp,
      accessor: 'idApp',
      minWidth: 200,
      maxWidth: 300,
      textHeader: 'Application Name',
      Cell: (props) => AppDetailsCell(props, appsById),
      show: Boolean(columnsConfiguration.idApp),
      style: {
        paddingLeft: '5px'
      },
      textValue: ({ idApp }) => (appsById[idApp] || {}).name,
      sortMethod: (a, b) => {
        const appA = (appsById[a] || {}).name || ''
        const appB = (appsById[b] || {}).name || ''
        return appA.localeCompare(appB)
      }
    },
    {
      Header: 'Contract Source',
      accessor: 'source',
      minWidth: 200,
      maxWidth: 300,
      show: Boolean(columnsConfiguration.source),
      textValue: ({ value }) => capitalize((value || '')),
      Cell: ({ value: source }) => capitalize(source)
    },
    {
      Header: get(columns, ['status', 'name'], ''),
      accessor: 'status',
      textValue: ({ status }) => ((contractStatusOptions || []).find(option => status === option.value) || {}).label,
      Cell: ({ value: status, row: { id: idContract, idApp } }) => SelectStatusCell({ status, idContract, contractStatusOptions, idApp }),
      sortMethod: (a, b) => (contractStatusOptionsOrder[a] || 0) - (contractStatusOptionsOrder[b] || 0),
      width: 210,
      show: Boolean(columnsConfiguration.status)
    },
    {
      Header: get(columns, ['owner', 'name'], ''),
      accessor: 'owner',
      textValue: ({ value: idOwner }) => idOwner ? `${getDisplayName(usersById[idOwner] || {})} <${usersById[idOwner].email}>` : '',
      width: 135,
      Cell: params => UserCell(params, 'owner', usersById, get(columns, ['owner', 'name'], '')),
      sortMethod: (a, b) => Table.sortMethods.user(usersById, a, b),
      show: Boolean(columnsConfiguration.owner)
    },
    {
      Header: 'Creation Date',
      id: 'creationTime',
      accessor: (row) => row.creationTime ? moment.utc(row.creationTime).toDate().getTime() : null,
      minWidth: 200,
      maxWidth: 300,
      show: Boolean(columnsConfiguration.creationTime),
      textValue: ({ value }) => capitalize((value || '')),
      Cell: ({ value }) => DatePickerCell({ value })
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      minWidth: 200,
      maxWidth: 300,
      show: Boolean(columnsConfiguration.createdBy),
      textValue: ({ value }) => capitalize((value || '')),
      Cell: ({ value: idUser }) => {
        if (idUser) {
          const user = usersById[idUser]
          return user && <UserDetails
            idUser={idUser}
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            photoUrl={user.photoUrl}
          />
        } else {
          return null
        }
      }
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'idOrg',
      show: false
    }
  ]
}

const customFieldPropsByType = {
  [formFieldTypes.usersDropdown]: ({ field, usersById }) => ({
    Cell: params => UserCell(params, field.key, usersById, field.name)
  }),
  [formFieldTypes.usersDropdownMulti]: ({ usersById }) => ({
    Cell: params => MultiUserCell({ usersById, value: params.value })
  }),
  [formFieldTypes.currency]: ({ field, identifier }) => ({
    Cell: params => {
      const currencyField = params.original[field.key]
      const value = getContractCurrencyValue(currencyField)
      const convertedValue = getContractCurrencyConvertedValue(currencyField)
      const contractCurrencyCode = getContractCurrencyCode(currencyField)
      return <OriginalAndConvertedCurrencies value={value} convertedValue={convertedValue} currency={contractCurrencyCode} />
    },
    accessor: (row) => {
      const value = getContractCurrencyValue(row[identifier])
      const valueNumber = isNumber(value) ? value : parseInt(value)
      return !valueNumber || isNaN(value) ? null : value / 100
    }
  }),
  [formFieldTypes.number]: ({ field }) => ({
    id: field.key,
    accessor: (row) => {
      const value = parseInt(row[field.key])
      return isNaN(value) ? null : value
    }
  })
}

const getColumns = ({ specialColumnsInfo, preDefinedColumnsMapping, usersById, appsById, contractsById, dynamicColumnsInfo, columnsConfiguration, contractStatusOptions, contractStatusOptionsOrder, onEditContract, name: tableName, onDuplicateContract, contractsFields, onDeleteContract, idOrg, idApp }) => {
  return getSpecialColumns({ columns: specialColumnsInfo, preDefinedColumnsMapping, contractStatusOptions, contractStatusOptionsOrder, columnsConfiguration, appsById, usersById, onEditContract, onDuplicateContract, contractsFields, onDeleteContract, idOrg, idApp })
    .concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, usersById, tableName, customFieldPropsByType, fieldIdentifier: 'key', onEdit: onEditContract, contractsById }))
}

export default getColumns
