import { NO_APP_ACCOUNT_ID } from '../constants'

export const getAppAccountName = ({ appAccountName, idAppAccount, numberOfAppAccounts }) => {
  const accountName = appAccountName || (idAppAccount === NO_APP_ACCOUNT_ID && numberOfAppAccounts > 1 ? 'Unmanaged account' : null)
  return accountName
}

export const getAppWithAppAccountName = ({ name, appAccountName, idAppAccount, numberOfAppAccounts }) => {
  const accountName = getAppAccountName({ appAccountName, idAppAccount, numberOfAppAccounts })
  return `${name} ${accountName ? ` - ${accountName}` : ''}`
}
