import styled from 'styled-components'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { css } from 'glamor'
import { theme } from '@toriihq/design-system'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'
const ROW_HEIGHT = '30px'

export const Main = css({
  height: '100%',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px'
})

export const Header = css({
  minHeight: '50px',
  padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
  borderBottom: `1px solid ${colors.border}`
})

export const Container = css({
  display: 'flex',
  width: '100%',
  height: `calc(100% - ${HEADER_HEIGHT})`
})

export const Separator = css({
  borderRight: `1px solid ${colors.border}`
})

export const Indexing = css({
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: colors.grey2,
  width: '40px'
})

export const Chart = css({
  paddingLeft: BOX_PADDING,
  alignSelf: 'center',
  width: '100%'
})

export const Index = css(texts.body, {
  height: ROW_HEIGHT,
  lineHeight: ROW_HEIGHT
})

export const ActiveUsers = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${colors.black};
  width: 50px;
`

export const AppRow = styled.div`
  height: ${ROW_HEIGHT};
  display: flex;
  align-items: center;
`
