import styled, { css } from 'styled-components'

export const Container = styled.div<{ fillContainerHeight?: boolean }>`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.primary};

  ${props => props.fillContainerHeight ? css`
    height: 100%;
    display: flex;
    flex-direction: column;
  ` : css`
    padding-bottom: 16px;
  `}
`

export const Content = styled.div<{ fillContainerHeight?: boolean }>`
  flex: ${props => props.fillContainerHeight ? '1' : null};
`
