import { USAGE_LEVEL } from '@root/constants'
import { getFilterOptionsValuesPerKey, getUniqueValues, getUniqueValuesWithAccessor, opsWithNoValue } from '@lenses/filters'
import { getSourceByType } from '@root/sourcesConfig'
import AccessControl from '@lenses/accessControl'

export const getFilterOptionsValues = ({ apps, stateOptions, tableInfo, usersById }) => {
  const usageValues = Object.values(USAGE_LEVEL).map((value) => ({ label: value, value }))
  const sourceValues = getUniqueValues(apps, 'sources').map(({ value }) => ({ label: getSourceByType(value).name, value }))

  return {
    ...getFilterOptionsValuesPerKey(apps, tableInfo, usersById),
    filterableState: stateOptions,
    state: stateOptions,
    usage: usageValues,
    sources: sourceValues,
    tags: getUniqueValuesWithAccessor(apps, ({ tags }) => tags && tags.map((tag) => ({ label: tag.name, value: tag.name })))
  }
}

export const getValidFilters = (filters) => (filters.filter(f => f.key && (f.value || f.value === 0 || (f.op && opsWithNoValue[f.op.value]))))

export const clearEmptyFilters = (onFiltersChange, filters) => {
  const validFilters = getValidFilters(filters)
  if (filters.length !== validFilters.length) {
    onFiltersChange(validFilters)
  }
}

export const removeRestrictedFilterOptions = (filterOptions, state) => {
  return filterOptions.filter(option => !option.scopes || (option.scopes && AccessControl.isAccessibleWithState({ scopes: option.scopes, state })))
}

export const removeFiltersWithNoOptions = (filterOptions, filters) => {
  const filterOptionKeys = filterOptions?.map(option => option.value)
  return filters?.filter(filter => filterOptionKeys?.includes(filter.key?.value) || filterOptionKeys?.includes(filter.key))
}

export const removeColumnOptionsByFilterOptions = (filterOptions, columnOptions) => {
  const filterOptionKeys = filterOptions?.map(option => option.value)
  return columnOptions?.filter(column => filterOptionKeys?.includes(column.value))
}
