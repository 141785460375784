import React from 'react'
import Table from '../../../table/index'
import colors from '../../../../shared/style/colors'
import { getDisplayName } from '../../../../lenses/users'
import UserDetails from '../../../userDetails'
import SourceIcon from '../../../sourceIcon'
import { getSourceByType } from '../../../../sourcesConfig'
import { TABLES } from '../../../../constants'
import { getFormattedDate } from '@lenses/utils'

const getNameColumn = () => ({
  Header: 'User',
  id: 'fullName',
  accessor: ({ firstName, lastName, email }) => {
    const array = [firstName, lastName, email]
    return array.join(' ').trim().toLowerCase()
  },
  minWidth: 220,
  maxWidth: 250,
  Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl } }) => {
    return <UserDetails
      firstName={firstName}
      lastName={lastName}
      email={email}
      isExternal={isExternal}
      idUser={idUser}
      photoUrl={photoUrl}
    />
  },
  textValue: ({ user: { firstName, lastName, email } }) => getDisplayName({ firstName, lastName, email })
})

const columns =
  [
    getNameColumn(),
    {
      accessor: 'email',
      show: false,
      textHeader: 'Email'
    },
    {
      Header: 'Country',
      show: true,
      accessor: 'countryName'
    },
    {
      Header: 'Last seen',
      accessor: 'usageDate',
      Cell: ({ value: usageDate }) => {
        return getFormattedDate({ date: usageDate })
      },
      sortMethod: Table.sortMethods.date,
      minWidth: 80,
      show: true
    },
    {
      Header: 'Source',
      show: true,
      accessor: 'source',
      Cell: ({ value: source }) => (
        <div>
          <SourceIcon
            key={source}
            sourceType={source}
            tooltipText={getSourceByType(source).name}
          />
        </div>
      )
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    }
  ]

class UsersList extends React.Component {
  render () {
    const { users, loading, loadingMore, onSearch, onSortedChangeCB, fetchData, totalUsers, exportFunction, scrollObjectId = 'main' } = this.props
    const getHeader = `USERS (${totalUsers || users.length})`

    return (
      <Table
        tableKey={TABLES.mapUsersTable.key}
        data={users}
        columns={columns}
        header={getHeader}
        loading={loading}
        loadingMore={loadingMore}
        getTdProps={setTdStyle}
        forceShowSearch
        manual
        scrollObjectId={scrollObjectId}
        searchable
        onSearch={onSearch}
        fetchData={fetchData}
        exportable
        exportFunction={exportFunction}
        onSortedChangeCB={onSortedChangeCB}
        totalCount={totalUsers}
        pageSize={25}
      />
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

export default UsersList
