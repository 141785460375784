import { createSelector } from 'reselect'
import identity from 'lodash/identity'

export const getAppChargebackConfig = createSelector(
  state => state.chargeback.appChargebackConfig,
  identity
)

export const isLoadingChargebackConfig = createSelector(
  state => state.chargeback.appChargebackConfig.loading,
  identity
)
