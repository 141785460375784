const identify = (props) => {
  window.Sentry && window.Sentry.setUser(props)
}

let sentry = null
if (window.Sentry) {
  sentry = {
    identify
  }
}

export default sentry
