import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import pluralize from 'pluralize'
import { ITEMS_PER_PAGE, TABLES } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { Spacer, Link } from '@toriihq/design-system'
import NotConfigured from '../../../components/offboarding/notConfigured'
import Configured from '../../../components/offboarding/configured'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import UsersIconsList from '../../usersIconsList'
import RelativeTeamLink from '@components/relativeTeamLink'

const SIDE_PADDING = '40px'
const CSS = {
  popup: css({
    width: '700px'
  }),
  container: css({
    padding: `30px ${SIDE_PADDING}`
  }),
  actions: css({
    borderTop: `1px solid ${colors.border}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `30px ${SIDE_PADDING}`,
    marginTop: '10px'
  }),
  errorMessage: css({
    color: colors.error,
    lineHeight: '28px'
  }),
  subHeader: css(texts.body, {
    lineHeight: '7px',
    color: colors.black
  }),
  expandableSectionsWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '20px'
  })
}

class OffboardingPreviewPopup extends Component {
  state = { errorMessage: '' }

  componentDidMount () {
    const { getOffboardingAppsOfUsers, idOrg, getServicesSyncData, getServicesConfig, users, idUsers, getOffboardingApps, getDefaultOffboardingWorkflow } = this.props

    if (idOrg && users.length > 0) {
      getOffboardingApps({ idOrg })
      getOffboardingAppsOfUsers({ idOrg, idUsers })
      getServicesSyncData({ idOrg })
      getServicesConfig({ idOrg })
      getDefaultOffboardingWorkflow({ idOrg })
    }
  }

  refreshAllOffboardingData = async () => {
    const { idOrg, getOffboardingTodoUsers, getOffboardingInProgressUsers, getOffboardingDoneUsers, getOffboardingTodoUsersCount, getOffboardingInProgressUsersCount, getOffboardingDoneUsersCount } = this.props
    getOffboardingTodoUsersCount({ idOrg })
    getOffboardingInProgressUsersCount({ idOrg })
    getOffboardingDoneUsersCount({ idOrg })
    getOffboardingTodoUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true })
    getOffboardingInProgressUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true })
    getOffboardingDoneUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true })
  }

  startOffboarding = async () => {
    const { users, idUsers, idOrg, updateUsersLifecycleStatus, close, toggleOffboardingStartedPopup, getOffboardingStatusOfUser } = this.props

    try {
      const response = await updateUsersLifecycleStatus({ idOrg, idUsers, lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDING })
      if (response.success) {
        await this.refreshAllOffboardingData()

        if (idUsers.length === 1) {
          await getOffboardingStatusOfUser({ idOrg, idUser: idUsers[0] })
        }

        toggleOffboardingStartedPopup({ isOpen: true, users })
        close()
      } else {
        throw new Error('Error occurred while starting offboarding')
      }
    } catch (e) {
      this.setState({ errorMessage: 'Error occurred while starting offboarding. Please try again.' })
      throw e
    }
  }

  getText () {
    const { isDefaultOffboardingWorkflowIsActive, cancel } = this.props

    return isDefaultOffboardingWorkflowIsActive ? <span>"Ignore non-configured apps during offboarding" setting is turned on. The offboarding from the apps below will be skipped. To change this setting,
      <RelativeTeamLink to={`/offboarding/settings`} onClick={cancel}> <Link>click here</Link></RelativeTeamLink>.</span> : null
  }

  render () {
    const { isOpen, cancel, users, idUsers, idOrg, needsAttentionApps, automaticApps, ignoredApps, delegateApps, getOffboardingAppsOfUsers, loading, isDefaultOffboardingWorkflowIsActive } = this.props
    const { errorMessage } = this.state

    const refreshData = () => getOffboardingAppsOfUsers({ idOrg, idUsers })

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width='700px'>
        <ToriiPopup.Header header={`Start offboarding for ${pluralize('employee', users.length, true)}`} />
        <ToriiPopup.Content>
          {errorMessage && <div {...CSS.errorMessage}>{errorMessage}</div>}
          <div {...CSS.subHeader}>Review and configure an offboarding method for each application</div>
          <Spacer top={'space-150'}><UsersIconsList users={users} maxUsers={5} /></Spacer>
          <div {...CSS.expandableSectionsWrapper}>
            {Boolean(loading || needsAttentionApps.length) && (
              <NotConfigured
                forceLoading={loading}
                tableWrapperId='notConfiguredTableWrapper'
                apps={needsAttentionApps}
                idOrg={idOrg}
                header='Need to configure'
                headerHeight={isDefaultOffboardingWorkflowIsActive ? 200 : 120}
                description={`You'll also be able to configure this later`}
                tableKey={TABLES.needsAttentionAppsTable.key}
                showButtonsOnlyOnHover={false}
                refreshDataFunc={refreshData}
                alertBoxContent={this.getText()}
                maxAppIcons={12}
              />
            )}
            {Boolean(loading || automaticApps.length) && (
              <Configured
                forceLoading={loading}
                tableWrapperId='automaticConfiguredTableWrapper'
                idOrg={idOrg}
                idUsers={idUsers}
                header='Automatic offboarding'
                headerHeight={100}
                tableKey={TABLES.automaticOffboardingTable.key}
                apps={automaticApps}
                refreshDataFunc={refreshData}
                maxAppIcons={12}
              />
            )}
            {Boolean(loading || delegateApps.length) && (
              <Configured
                forceLoading={loading}
                tableWrapperId='delegateConfiguredTableWrapper'
                idOrg={idOrg}
                idUsers={idUsers}
                header='Delegate offboarding'
                headerHeight={100}
                tableKey={TABLES.delegateOffboardingTable.key}
                apps={delegateApps}
                refreshDataFunc={refreshData}
                maxAppIcons={12}
              />
            )}
            {Boolean(loading || ignoredApps.length) && (
              <Configured
                forceLoading={loading}
                tableWrapperId='ignoredConfiguredTableWrapper'
                idOrg={idOrg}
                idUsers={idUsers}
                header='Ignored offboarding'
                headerHeight={100}
                tableKey={TABLES.ignoredOffboardingTable.key}
                apps={ignoredApps}
                refreshDataFunc={refreshData}
                maxAppIcons={12}
              />
            )}
          </div>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          cancelButtonText='Cancel'
          mainButtonText='Start'
          mainButtonAction={this.startOffboarding}
        />
      </ToriiPopup>
    )
  }
}

export default OffboardingPreviewPopup
