
import { getFixedBranchesData } from './getFixedBranchesData'
import { getBranchActionBranchesData } from './getBranchActionBranchesData'
import { ActionWithFixedBranchesNodeModel, BranchNodeModel, WORKFLOW_NODE_TYPE } from '@shared/workflows/types'

type NodeModel = BranchNodeModel | ActionWithFixedBranchesNodeModel

export const getBranchesData = (node: NodeModel) => {
  if (node.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
    return getBranchActionBranchesData(node as BranchNodeModel)
  } else if (node.type === WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES) {
    return getFixedBranchesData(node as ActionWithFixedBranchesNodeModel)
  } else {
    throw new Error(`Unsupported node type`)
  }
}
