import omit from 'lodash/omit'
import {
  IdWorkflowNode,
  WorkflowActionsModel
} from '@shared/workflows/types'
import { getNodeDescendants } from '@shared/workflows/actions/utils/getNodeDescendants'
import { updateNextNodeInWorkflowActions } from './updateNextNodeInWorkflowActions'

export const deleteActionWithBranches = (
  idNode: IdWorkflowNode,
  actions: WorkflowActionsModel
): WorkflowActionsModel => {
  const idNodesToRemove = getNodeDescendants(idNode, actions.nodes)

  const updatedWorkflowActions = updateNextNodeInWorkflowActions({
    idCurrentNextNode: idNode,
    idNewNextNode: null,
    workflowActions: actions
  })

  const newNodes = omit(updatedWorkflowActions.nodes, idNodesToRemove)

  return {
    ...updatedWorkflowActions,
    nodes: newNodes
  }
}
