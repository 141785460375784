import React from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import colors from '../../shared/style/colors'

const Placeholder = ({ children, loading, style, className, type, rows, widths = [100] }) => (
  <ReactPlaceholder
    ready={!loading}
    type={type}
    showLoadingAnimation
    color={colors.grey3}
    rows={rows}
    className={className}
    widths={widths}
    style={{
      fontSize: '15px',
      maxWidth: '50%',
      ...style
    }}>
    {children}
  </ReactPlaceholder>
)

Placeholder.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'media', 'textRow', 'rect', 'round']),
  rows: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
}

Placeholder.defaultProps = {
  loading: false,
  type: 'text',
  style: {},
  className: '',
  rows: 1
}

export default Placeholder
