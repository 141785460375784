import View from './view'
import { connect } from 'react-redux'
import { trialSignup } from '@pages/login/actions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => ({
  loginInfo: state.login.TRIAL_SIGNUP
})

const mapDispatchToProps = {
  trialSignup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
