import View from './view'
import { connect } from 'react-redux'
import flatten from 'lodash/flatten'
import {
  sortTable,
  filterTable,
  toggleFilterTable,
  getTableViews,
  tableCustomSelectChange,
  configureTableColumns
} from '@actions/'
import { getUserPreferences, getIsSmallScreen } from '@selectors/ui'
import { EMPTY_ARRAY, EMPTY_OBJECT, SHARE_STATUS, SCOPES } from '@root/constants'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import { getTableFiltersURLQueryParam, getSecondaryCustomSelectSelectedValueFromURL, getValue } from '@shared/utils'
import { getSelf, getUserScopes } from '@selectors/me'
import { isArray } from 'lodash'
import { isFeatureEnabled } from '@lenses/features'

const getDefaultSort = ({ prefSort, propSort }) => {
  const prefSortExist = prefSort && isArray(prefSort) && prefSort.length
  const safePropSort = (isArray(propSort) && propSort) || EMPTY_ARRAY

  return prefSortExist ? prefSort : safePropSort
}

const mapStateToProps = (state, ownProps) => {
  const { tableKey, configurableColumnsOptions, filtersOptions, columns, supportViews, loading, filterable, overrideTrStyleRule, highlightColor } = ownProps
  const me = getSelf(state)
  const userPreferences = getUserPreferences(state)
  const userTablePreferences = (tableKey && userPreferences[tableKey]) || EMPTY_OBJECT

  const URLFilters = getTableFiltersURLQueryParam()
  const supportURLFilters = filterable && URLFilters.length && !supportViews
  const filters = supportURLFilters ? URLFilters : get(userTablePreferences, ['filters'], EMPTY_ARRAY)

  const { isFiltersOpen, defaultCustomSelectOption } = userTablePreferences
  const isSmallScreen = getIsSmallScreen(state)
  const defaultSort = getDefaultSort({ prefSort: userTablePreferences.defaultSort, propSort: ownProps.defaultSort })

  const unwrappedColumns = ownProps.groupedHeaders ? flatten(columns.map(groupColumn => groupColumn.columns || groupColumn)) : get(columns, '[0].columns', columns)
  const allColumnsByColumnKey = keyBy(configurableColumnsOptions || EMPTY_ARRAY, 'value')
  const configuredColumnsByAccessor = keyBy(unwrappedColumns || EMPTY_ARRAY, (column) => column.id || column.accessor)
  const allFiltersByKey = keyBy(filtersOptions || EMPTY_ARRAY, 'value')

  const supportedFilters = filters.filter(filter => !filter.key || Boolean(allFiltersByKey[getValue(filter.key)]))
  const supportedSort = defaultSort.filter(sort => Boolean(allColumnsByColumnKey[sort.id] || configuredColumnsByAccessor[sort.id]))

  const customSelectProps = {}
  if (ownProps.customSelect && !ownProps.customSelectSelectedValue && defaultCustomSelectOption) {
    ownProps.customSelectOnChange(defaultCustomSelectOption)
    customSelectProps.customSelectOptions = ownProps.customSelectOptions || [defaultCustomSelectOption]
  }

  if (ownProps.secondaryCustomSelect && ownProps.secondaryCustomSelectSelectedValue && ownProps.secondaryCustomSelectOnChange) {
    const URLSecondaryCustomSelectSelectedValue = getSecondaryCustomSelectSelectedValueFromURL()
    if (URLSecondaryCustomSelectSelectedValue && URLSecondaryCustomSelectSelectedValue.value !== ownProps.secondaryCustomSelectSelectedValue.value) {
      ownProps.secondaryCustomSelectOnChange(URLSecondaryCustomSelectSelectedValue)
    }
  }

  const userScopes = getUserScopes(state)
  const hasViewsWriteScope = userScopes.includes(SCOPES.PUBLIC_VIEWS_WRITE)

  const isReadOnlyView = !loading && supportViews && me.id !== userTablePreferences.idUser && (userTablePreferences.shareStatus === SHARE_STATUS.sharedReadOnly.type || (userTablePreferences.shareStatus === SHARE_STATUS.shared.type && !hasViewsWriteScope))
  const readOnlyTooltip = isReadOnlyView ? 'Editing this shared view is disabled' : ''

  return {
    isSmallScreen,
    origDefaultSort: defaultSort,
    defaultSort: supportedSort,
    filters: supportedFilters,
    hasInvalidFilters: filters.length > supportedFilters.length,
    isFiltersOpen,
    userTablePreferences,
    isReadOnlyView,
    readOnlyTooltip,
    overrideTrStyleRule,
    highlightColor,
    isScheduledReportEnabled: isFeatureEnabled(state, 'scheduledReports'),
    ...customSelectProps
  }
}

const mapDispatchToProps = {
  sortTable,
  filterTable,
  toggleFilterTable,
  getTableViews,
  tableCustomSelectChange,
  configureTableColumns
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
