import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import DetailsDisplay from '@components/workflows/detailsDisplay'
import { css } from 'glamor'

const CSS = {
  container: css({
    margin: 'auto',
    maxHeight: '90%'
  })
}

const CustomActionResponsePopup = ({ cancel, isOpen, action = {} }) => {
  return <ToriiPopup
    isOpen={isOpen}
    onCloseAction={cancel}>
    <ToriiPopup.Header header={'Error details'} subHeader={`${action.runtimeInfo.method.label} request to ${action.runtimeInfo.url}`} />

    <ToriiPopup.Content>
      <div {...CSS.container}>
        <div>
          <DetailsDisplay details={action.details} showAll />
        </div>
      </div>
    </ToriiPopup.Content>

    <ToriiPopup.Footer showMainButton={false} cancelButtonText={'Close'} />

  </ToriiPopup>
}

CustomActionResponsePopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  action: PropTypes.object
}

export default CustomActionResponsePopup
