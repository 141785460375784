import React, { useState, useEffect } from 'react'
import numeral from 'numeral'
import Input from '@components/form/input'

export const CurrencyInput = ({ onChange, value }) => {
  const [displayValue, setDisplayValue] = useState<number | undefined>()

  useEffect(() => {
    onChange(displayValue === undefined ? displayValue : displayValue * 100)
  }, [displayValue, onChange])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue === '') {
      setDisplayValue(undefined)
      return
    }

    const isSafe = Number.isSafeInteger(Math.floor(parseFloat(inputValue)))
    if (!isSafe) {
      return
    }

    const isFloat = inputValue.includes('.')
    const valueToDisplay = isFloat ? parseFloat(numeral(inputValue).format('0.00000000000')) : parseInt(inputValue)
    setDisplayValue(valueToDisplay)
  }

  const handleBlur = () => {
    const valueWit100 = value
    const valueToDisplay = valueWit100 && !isNaN(valueWit100 / 100) ? parseFloat(numeral(valueWit100 / 100).format('0.[00]')) : null
    valueToDisplay && setDisplayValue(valueToDisplay)
  }

  return (
    <Input
      type='number'
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
