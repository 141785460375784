import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from '../../../form/formGroup'
import Input from '../../../form/input'
import withTooltipHOC from '../../../../shared/HOCs/withTooltip'

const InputComponent = ({ input, disabled, isRequired, autoFocus, maxLength }) => <Input
  {...input}
  disabled={disabled}
  required={isRequired}
  autoFocus={autoFocus}
  maxLength={maxLength}
/>

const InputField = ({ autoFocus, extraInfo, input, label, meta: { touched, error }, disabled, errorAfterTouchOnly, withTooltip, isRequired, maxLength }) => {
  const TooltipInput = withTooltip ? withTooltipHOC(InputComponent, { label: 'This field is defined by Torii and cannot be edited.', fullWidth: true }) : InputComponent
  return (
    <FormGroup label={label} error={(touched || !errorAfterTouchOnly) && error} disabled={disabled} isRequired={isRequired}>
      <TooltipInput input={input} autoFocus={autoFocus} disabled={disabled} isRequired={isRequired} maxLength={maxLength} />
      {extraInfo}
    </FormGroup>
  )
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  extraInfo: PropTypes.element,
  disabled: PropTypes.bool,
  errorAfterTouchOnly: PropTypes.bool,
  withTooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

InputField.defaultProps = {
  autoFocus: true,
  disabled: false,
  errorAfterTouchOnly: true,
  withTooltip: false
}
export default InputField
