import { useEffect, useRef } from 'react'
import { Dimensions, useReactFlow, useStoreApi } from 'reactflow'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import pick from 'lodash/pick'
import { alignToTriggerNode, centerToSelectedNode } from './utils'

export const useWorkflowGraphPositioning = () => {
  const reactflowStore = useStoreApi()
  const {
    getNode,
    getZoom,
    setCenter,
    setViewport,
    viewportInitialized
  } = useReactFlow()
  const { idSelectedNode } = useWorkflowNavigation()
  const isInitializedRef = useRef(false)

  const currentZoom = getZoom()
  const reactflowState = reactflowStore.getState()
  const reactflowDimensions: Dimensions = pick(reactflowState, ['width', 'height'])
  const reactflowContainerWidth = reactflowDimensions.width

  const initGraphPositioning = () => {
    if (isInitializedRef.current || !viewportInitialized) {
      return
    }

    const selectedNode = idSelectedNode && getNode(idSelectedNode)
    if (selectedNode) {
      centerToSelectedNode({
        selectedNode,
        setCenter,
        zoom: currentZoom
      })
    } else {
      alignToTriggerNode({
        getNode,
        reactflowContainerWidth,
        zoom: currentZoom,
        setViewport
      })
    }
    isInitializedRef.current = true
  }

  useEffect(
    initGraphPositioning,
    [currentZoom, getNode, idSelectedNode, viewportInitialized, reactflowContainerWidth, setCenter, setViewport]
  )
}
