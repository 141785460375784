import styled from 'styled-components'

import colors from '../../../shared/style/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Name = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.black};
  `

export const Expenses = styled.span`
  font: ${({ theme }) => theme.typography.font.component03};
  color: ${colors.grey2};
    
`
