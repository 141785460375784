import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontWeight } from '../../shared/style/sizes'
import WhiteToriiLogo from '@media/white_torii_logo.svg'
import DashboardSignIn from '@media/dashboard_signin.svg'
import CatalogSignIn from '@media/catalog_signin.svg'
import PoweredBy from '@media/powered_by.svg'

const SMALL_SCREEN_SIZE = '800px'

const CSS = {
  main: css({
    display: 'flex',
    justifyContent: 'space-around',
    verticalAlign: 'middle',
    height: '100vh'
  }),
  company: css({
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: fontWeight.bold,
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      display: 'none'
    }
  }),
  dashboardCompany: css({
    padding: '0 54px',
    textAlign: 'center',
    backgroundColor: colors.blue,
    fontSize: '30px',
    color: colors.white
  }),
  catalogCompany: css({
    backgroundColor: colors.lightBlue3,
    fontSize: '35px',
    color: colors.black
  }),
  image: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }),
  actionContainer: css({
    width: '50%',
    position: 'absolute',
    right: 0,
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      width: '100%'
    }
  }),
  action: css({
    margin: '0 auto',
    maxWidth: '500px',
    padding: '40px',
    [`@media(max-width: ${SMALL_SCREEN_SIZE})`]: {
      margin: '0 auto',
      padding: '20px'
    }
  })
}

const Signup = ({ isFromCatalog, overrideCss, children }) => {
  const dashboard = <div {...css(CSS.company, CSS.dashboardCompany)}>
    <img style={{ zIndex: 3, margin: '15% 0 20px 0', height: '31px' }} alt='Torii logo' src={WhiteToriiLogo} />
    <div style={{ zIndex: 3 }}>Discover, Optimize, and automate your SaaS</div>
    <div style={{ zIndex: 3 }}>with the SaaS Management Platform Built for IT</div>
    <div {...CSS.image}><img alt='Torii' src={DashboardSignIn} width='100%' /></div>
  </div>

  const catalog = <div {...css(CSS.company, CSS.catalogCompany)}>
    <div style={{ zIndex: 3, margin: '10% 0 0 50px' }}>Application Catalog</div>
    <div {...CSS.image}><img alt='Application catalog' src={CatalogSignIn} width='100%' /></div>
    <img style={{ zIndex: 3, position: 'absolute', bottom: '46px', left: '56px' }} alt='Torii logo' src={PoweredBy} />
  </div>

  return (
    <div {...CSS.main}>
      {!isFromCatalog && dashboard}
      {isFromCatalog && catalog}
      <div {...CSS.actionContainer}>
        <div {...css(CSS.action, overrideCss)}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Signup
