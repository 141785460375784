import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { fontWeight } from '../../../shared/style/sizes'
import pluralize from 'pluralize'
import Currency, { CurrencyFormat } from '@components/currency'

const totalHoverStyle = {
  transform: 'translateY(0)'
}
const infoHoverStyle = {
  opacity: 1
}

const CSS = {
  container: css(texts.subheading, {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    textAlign: 'right',
    flexDirection: 'column'
  })
}

const LicenseCell = ({ totalUserFieldCost, count, costPerLicense, overrideStyle, hoverClass, overrideTotalStyle, totalColumn, costPerUser, isBottomUp, percentage, total }) => {
  const isCostPerUser = costPerUser !== undefined
  const isCostPerLicense = costPerLicense !== undefined

  const hoverStyle = `.${hoverClass}:hover &`
  CSS.total = css({
    color: colors.black,
    textTransform: 'uppercase',
    fontWeight: fontWeight.normal,
    flex: 1,
    transition: 'color .2s, transform.2s',
    transform: `translateY(${totalColumn && !isCostPerUser ? 10 : 20}px)`,
    marginBottom: '3px',
    [hoverStyle]: totalHoverStyle
  })

  CSS.info = css({
    marginBottom: '3px',
    flex: 1,
    fontWeight: fontWeight.normal,
    opacity: 0,
    transition: 'opacity .2s',
    [hoverStyle]: infoHoverStyle
  })

  const itemName = isCostPerUser ? 'user' : 'license'

  return <div className='headerContainer' {...css(CSS.container, overrideStyle)}>
    {isBottomUp && <>
      <div {...css(CSS.total, overrideTotalStyle)}><Currency value={totalUserFieldCost} format={CurrencyFormat.FLOAT} shouldRoundValue /></div>
      <div {...CSS.info}>{count} {pluralize(itemName, count)}</div>
      {(isCostPerUser || isCostPerLicense) && <div {...CSS.info}><Currency value={isCostPerUser ? costPerUser : costPerLicense} format={CurrencyFormat.FLOAT} shouldRoundValue />/{itemName}</div>}
    </>}

    {!isBottomUp && <>
      <div {...css(CSS.total, overrideTotalStyle)}><Currency value={totalUserFieldCost} format={CurrencyFormat.FLOAT} shouldRoundValue /></div>
      <div {...CSS.info}>{percentage || 0}% x <Currency value={total} format={CurrencyFormat.FLOAT} shouldRoundValue /></div>
    </>}
  </div>
}

LicenseCell.propTypes = {
  totalCost: PropTypes.number,
  count: PropTypes.number,
  costPerLicense: PropTypes.number
}

LicenseCell.defaultProps = {
  hoverClass: 'headerContainer'
}

export default LicenseCell
