import {
  ActionFieldOption,
  AppWithActionOptions,
  GetSelectActionConfirmationDataParams,
  SelectActionConfirmationData
} from './types'
import { closedSelectActionConfirmationData } from '@pages/workflow_v2/actionConfiguration/actionTypeSelection/consts'
import { isBranchActionType } from '../utils/isBranchActionType'
import { WORKFLOW_ACTION_TYPE, WORKFLOW_NODE_TYPE } from '@shared/workflows/types'

export const sortByIsToriiActionThenByName = (a: AppWithActionOptions, b: AppWithActionOptions) => {
  if (a.idApp === 1) {
    return -1
  } else if (b.idApp === 1) {
    return 1
  }

  return a.name.localeCompare(b.name)
}

export const sortByName = (a: ActionFieldOption, b: ActionFieldOption) => {
  return a.label.localeCompare(b.label)
}

export const getSelectActionConfirmationData = ({
  selectedOption,
  isActionTypeAlreadySelected,
  currentActionNode
}: GetSelectActionConfirmationDataParams): SelectActionConfirmationData => {
  if (isActionTypeAlreadySelected) {
    return {
      isOpen: true,
      header: 'Replace action?',
      text: 'By selecting a different action, your existing action configuration will be overridden. This cannot be undone. The logs will not be affected.',
      confirmText: 'Replace'
    }
  }

  const {
    type,
    branchesConfiguration
  } = selectedOption

  const isBranchAction = isBranchActionType(type)
  const isActionHasBranches = Boolean(branchesConfiguration)
  const shouldDisplayBranchConfirmation = Boolean(
    currentActionNode.type === WORKFLOW_NODE_TYPE.ACTION && currentActionNode.next
  )

  if (shouldDisplayBranchConfirmation) {
    if (isBranchAction) {
      return {
        isOpen: true,
        text: 'You\'re creating a branch in the middle of an existing series of actions. All actions will be moved under the new first branch.',
        confirmText: 'Add If/else branch'
      }
    } else if (isActionHasBranches) {
      const firstBranchLabel = branchesConfiguration?.branches[0].label
      return {
        isOpen: true,
        text: `You're adding an action with branches in the middle of an existing series of actions. All actions will be moved under the "${firstBranchLabel}" branch.`,
        confirmText: `Add action`
      }
    }
  }

  return closedSelectActionConfirmationData
}

export const shouldDisableActionSelection = (actionType: string, isTriggerSupportBranching: boolean) => {
  if (actionType === WORKFLOW_ACTION_TYPE.IF_ELSE_BRANCH) {
    return !isTriggerSupportBranching
  }

  return false
}
