import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import range from 'lodash/range'
import { Spacer, AppIcon, Button, ButtonType, AlertBox, AlertBoxType, EmptyState } from '@toriihq/design-system'
import RelativeTeamLink from '../relativeTeamLink'
import { hoverActions } from '../../shared/style/mixins'
import HoverableHeader from '../hoverableHeader'
import { exportCSV } from '../../helpers/exportCSV'
import TableCSVButton from '../table/tableCSVButton'
import emptyImage from '../appChargeback/images/calculator.svg'
import moment from 'moment'
import Placeholder from '../placeholder'
import UserFieldTable from './userFieldTable'
import EnableFor from '../enableFor'
import { CHARGEBACK_ALLOCATION_COST, CHARGEBACK_ALLOCATION_STRATEGY, SCOPES } from '../../constants'
import { pluralizeWithFormat } from '@root/shared/utils'
import * as Style from './styles'
import Currency from '../currency'

const CSS = {
  tableHeaderStyle: css({
    padding: 0
  }),
  subHeaderStyle: css({
    color: colors.black,
    fontSize: 12,
    fontWeight: 'normal'
  }),
  headerStyle: css({
    textTransform: 'unset',
    color: colors.black,
    fontSize: 14,
    fontWeight: 600
  }),
  show: css({
    display: 'block'
  }),
  showOnHover: css({
    display: 'none',
    ':hover': {
      display: 'block'
    }
  }),
  csvWrapper: css({
    width: '100%',
    textAlign: 'right',
    marginBottom: '20px'
  })
}

const costHeaderStyle = { justifyContent: 'flex-end' }
const costStyle = { textAlign: 'right', height: 'fit-content', alignSelf: 'center', paddingRight: 50 }

class ChargeBackReportTable extends React.Component {
  state = { displayBanner: false }

  componentDidMount () {
    this.fetchData()
  }

  fetchData = () => {
    const { idOrg, getChargebackReport } = this.props
    getChargebackReport({ idOrg })
  }

  openConfigureAppChargeback = (idApp) => {
    const { toggleConfigureAppChargeback } = this.props
    const callback = () => this.setState({ displayBanner: true })
    toggleConfigureAppChargeback({ isConfigureAppChargebackOpen: true, idApp, callback })
  }

  getColumns = ({ config, isOpen, userFieldData }) => {
    return [
      {
        Header: <HoverableHeader hoverClass='rt-tr' overrideHeaderStyle={CSS.headerStyle} overrideSubHeaderStyle={CSS.subHeaderStyle} header={config.userFieldDisplayName} subHeader={config.allocateCostByUserFieldLabel} forceHoverStyle={isOpen} />,
        accessor: 'appName',
        Cell: ({ value: appName, row: { idApp, appImageUrl, licenseDisplayName } }) => {
          return (
            <Style.Wrapper>
              <RelativeTeamLink to={`/app/${idApp}`} >
                <Spacer right={'space-150'}>
                  <AppIcon appName={appName} appImageUrl={appImageUrl} />
                </Spacer>
              </RelativeTeamLink>{appName}{licenseDisplayName ? ` - ${licenseDisplayName}` : ''}
            </Style.Wrapper>
          )
        },
        sortable: false,
        resizable: false,
        width: 450
      },
      {
        Header: <div {...CSS.headerStyle}><Currency value={sumBy(userFieldData, ({ userFieldTotalCost, licenseTotalCost, allocationCost }) => allocationCost === 'license' ? licenseTotalCost : userFieldTotalCost)} /></div>,
        accessor: 'userFieldTotalCost',
        Cell: ({ value, row: { licenseTotalCost, allocationCost } }) => {
          const cost = allocationCost === 'license' ? licenseTotalCost : value
          if (cost === null) {
            return '-'
          }
          return <Currency value={cost} />
        },
        sortable: false,
        resizable: false,
        headerStyle: costHeaderStyle,
        width: 250,
        style: costStyle
      },
      {
        id: 'details',
        Cell: ({ row: { allocationCost, percentage, licenseCost, costPerUser, licenseCount, userFieldTotalCount, totalCost } }) => {
          if (allocationCost === 'license') {
            return licenseCost === null ? '-' : <div>{pluralizeWithFormat('license', licenseCount, true)} X <Currency value={licenseCost} /></div>
          }
          if (allocationCost === 'user') {
            return costPerUser === null ? '-' : <div>{pluralizeWithFormat('user', userFieldTotalCount, true)} X <Currency value={costPerUser} /></div>
          }
          return percentage === null ? '-' : <div>{percentage}% of <Currency value={totalCost} /></div>
        },
        sortable: false,
        resizable: false,
        style: { paddingLeft: 50 }
      },
      {
        sortable: false,
        resizable: false,
        id: 'reconfigure',
        accessor: 'idApp',
        minWidth: 150,
        Cell: ({ value }) => {
          return <EnableFor scopes={[SCOPES.LICENSE_AND_CHARGEBACK_WRITE]}>
            <div {...hoverActions}>
              <Button type={ButtonType.secondary} onClick={() => this.openConfigureAppChargeback(value)} label='Reconfigure' />
            </div>
          </EnableFor>
        },
        hideFromCSV: true
      },
      {
        accessor: 'appImageUrl',
        show: false
      },
      {
        accessor: 'idApp',
        show: false
      },
      {
        accessor: 'licenseDisplayName',
        show: false
      },
      {
        accessor: 'licenseTotalCost',
        show: false
      },
      {
        accessor: 'allocationCost',
        show: false
      },
      {
        accessor: 'costPerUser',
        show: false
      },
      {
        accessor: 'licenseCost',
        show: false
      },
      {
        accessor: 'licenseCount',
        show: false
      },
      {
        accessor: 'percentage',
        show: false
      },
      {
        accessor: 'totalCost',
        show: false
      },
      {
        accessor: 'userFieldTotalCount',
        show: false
      }
    ]
  }

  exportToCsv = async () => {
    const { data, currency } = this.props

    const getAllocationStrategy = (item) => {
      const isBottomUp = item.allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.bottomUp
      const prefix = isBottomUp ? 'Bottom up - cost per ' : 'Top down'
      const suffix = isBottomUp ? item.allocationCost === CHARGEBACK_ALLOCATION_COST.license ? 'license' : 'user' : ''
      return `${prefix}${suffix}`
    }

    const getAmount = ({ allocationCost, percentage, licenseCount, userFieldTotalCount }) => {
      return allocationCost === CHARGEBACK_ALLOCATION_COST.license ? licenseCount : allocationCost === CHARGEBACK_ALLOCATION_COST.user ? userFieldTotalCount : percentage || undefined
    }
    const getTotalCost = item => {
      const cost = item.allocationCost === CHARGEBACK_ALLOCATION_COST.license ? item.licenseTotalCost : item.allocationCost === CHARGEBACK_ALLOCATION_COST.user ? item.userFieldTotalCost : item.percentage || undefined
      return cost ? item.percentage ? (item.totalCost / 100) * (cost / 100) : cost / 100 : undefined
    }

    const getCostPerUnit = ({ allocationCost, percentage, licenseCost, costPerUser, totalCost }) => {
      if (allocationCost === CHARGEBACK_ALLOCATION_COST.license) {
        return licenseCost ? licenseCost / 100 : undefined
      }
      if (allocationCost === CHARGEBACK_ALLOCATION_COST.user) {
        return costPerUser ? costPerUser / 100 : undefined
      }
      return percentage ? totalCost / 100 : undefined
    }

    const columnNames = ['Cost allocation', 'Cost sub-allocation', 'App name - license name', 'Allocation strategy', 'Amount', `Cost per unit (${currency})`, `Total cost (${currency})`]
    const dataForCSV = data.map(item => {
      return {
        'Cost allocation': item.allocateCostByUserFieldLabel,
        'Cost sub-allocation': item.userFieldDisplayName,
        'App name - license name': `${item.appName}${item.licenseDisplayName ? ` - ${item.licenseDisplayName}` : ''}`,
        'Allocation strategy': getAllocationStrategy(item),
        'Amount': getAmount(item),
        [`Cost per unit (${currency})`]: getCostPerUnit(item),
        [`Total cost (${currency})`]: getTotalCost(item)
      }
    })

    exportCSV(`chargeback_${moment().format('DD_MMMM_YYYY')}.csv`, dataForCSV, columnNames)
  }

  renderEmptyState = () => {
    return <Style.EmptyState>
      <EmptyState
        image={<img src={emptyImage} alt='Chargeback is not configured for this application' />}
        description={<span>You don't have any application chargebacks configured.<br />Application chargeback is configured from the Application → Chargeback tab</span>}
      />
    </Style.EmptyState>
  }

  render () {
    const { data, loading } = this.props
    const { displayBanner } = this.state
    const dataByUserByUserField = groupBy(data, item => `${item.allocateCostByUserFieldKey}_${item.userFieldDisplayName}`)
    const emptyState = data.length === 0

    return <div>
      {displayBanner && <AlertBox type={AlertBoxType.INFORMATIVE} description='Chargeback report data might take a minute to update' />}
      {!loading && (emptyState ? this.renderEmptyState() : <div {...CSS.csvWrapper}>
        <TableCSVButton onClick={this.exportToCsv} disabled={!data || data.length === 0}>CSV</TableCSVButton>
      </div>)}
      {loading && <div style={{ marginTop: 50 }}>
        {range(8).map(() => {
          return <Placeholder loading={loading} type='rect' rows={1} style={{ width: '100%', height: 66, maxWidth: '100%', margin: 10 }} />
        })}
      </div>}
      {map(dataByUserByUserField, (userFieldData, key) => {
        const config = userFieldData[0] || {}

        return <UserFieldTable
          key={key}
          id={key}
          userFieldData={userFieldData}
          config={config}
          getColumns={this.getColumns}
        />
      })}
    </div>
  }
}

export default ChargeBackReportTable
