import { css } from 'glamor'
import { fontWeight } from '@shared/style/sizes'

export const TableTitle = css({
  fontWeight: fontWeight.semiBold,
  marginBottom: 10
})

export const PreviewTableTheadTh = {
  style: {
    height: 0,
    padding: 0
  }
}

export const PreviewTableHeader = css({
  padding: 0
})

export const defaultColumns = [{}]
