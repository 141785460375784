import { get, isNil, negate } from 'lodash'
import { ReactNode } from 'react'

export const extractPlaceholderValue = (
  { placeholder, data }: { placeholder: string, data: Record<string, ReactNode> }
): ReactNode => {
  const placeholderWithoutBraces = placeholder
    .split('{').join('')
    .split('}').join('')

  const placeholderParts = placeholderWithoutBraces
    .split('??')

  const value = placeholderParts
    .map(placeholderPart => get(data, placeholderPart.trim()))
    .find(negate(isNil))

  return value ?? ''
}
