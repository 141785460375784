import React from 'react'
import { Icon, Tooltip } from '@toriihq/design-system'

const CoreApp = () => {
  return (
    <Tooltip placement='top' label='Core app - Used more than 15 days in the last 30 days'>
      <Icon name='SuperUsageFilled' color='emphasis' />
    </Tooltip>
  )
}

export default CoreApp
