import { isCustomAppCatalogPolicy } from '@lenses/workflows'
import analytics from '@helpers/analytics'

const Analytics = {
  onNavToExecutionLog: ({ workflow, isAppCatalogPoliciesView, pageName }): void => {
    const { triggerIdApp, type: workflowType, triggerType } = workflow

    const analyticsObject = isAppCatalogPoliciesView ? 'policy-log' : 'workflow-runs'
    const isCustomPolicy = isCustomAppCatalogPolicy({ workflow: { triggerIdApp, type: workflowType } })
    const additionalAnalyticsProps = isAppCatalogPoliciesView
      ? { 'Policy type': isCustomPolicy ? 'Custom' : 'Default' }
      : { 'Trigger name': triggerType }

    analytics.track(`Navigate to ${analyticsObject}`, {
      'Page name': pageName,
      ...additionalAnalyticsProps
    })
  }
}

export default Analytics
