import View from './view'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import {
  getActionsConfigByType,
  getTriggersByType,
  isLoadingWorkflows,
  getWorkflow,
  getAppCatalogPoliciesByIdSelector,
  getAppCatalogRequestNewAppPoliciesByIdSelector,
  isLoadingAppCatalogPoliciesSelector,
  isLoadingAppCatalogRequestNewAppPoliciesSelector,
  isLoadingWorkflowsEditHistoryInfo,
  getWorkflowsEditHistoryById
} from '@selectors/workflows'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import { EMPTY_OBJECT } from '@root/constants'
import { WorkflowEditorProvider } from '@pages/workflow_v2/workflowEditor/context'

const mapStateToProps = (state, ownProps) => {
  const { isAppCatalogPoliciesView = false, isRequestNewAppPoliciesView = false, idAudit } = ownProps
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idWorkflow = parseInt(ownProps.match.params.idWorkflow, 10)
  const isPreviewMode = Boolean(idAudit)

  let workflow = (
    isAppCatalogPoliciesView ? getAppCatalogPoliciesByIdSelector(state)[idWorkflow]
      : isRequestNewAppPoliciesView ? getAppCatalogRequestNewAppPoliciesByIdSelector(state)[idWorkflow]
        : getWorkflow(state, { idWorkflow })
  ) || EMPTY_OBJECT

  let loadingPreviewData
  if (isPreviewMode) {
    const workflowAudit = getWorkflowsEditHistoryById(state)[idAudit]
    loadingPreviewData = isLoadingWorkflowsEditHistoryInfo(state) && isEmpty(workflowAudit)

    Object.assign(workflow, omit(workflowAudit, ['id']))
  }

  const triggersByType = getTriggersByType(state)
  const actionsByType = getActionsConfigByType(state)

  const loading =
    (isAppCatalogPoliciesView ? isLoadingAppCatalogPoliciesSelector(state)
      : isRequestNewAppPoliciesView ? isLoadingAppCatalogRequestNewAppPoliciesSelector(state)
        : isLoadingWorkflows(state)) || !workflow.actions ||
    Object.keys(triggersByType).length === 0 ||
    Object.keys(actionsByType).length === 0 ||
    loadingPreviewData

  return {
    idOrg,
    idWorkflow,
    workflow,
    loading,
    idAudit
  }
}

const WorkflowV2Page = withRouter(connect(mapStateToProps)(View))

const WorkflowV2PageWithProvider = (props) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const previewId = queryParams.get('previewId')
  const idAudit = previewId ? parseInt(previewId) : null
  const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = props
  return <WorkflowEditorProvider isAppCatalogPoliciesView={isAppCatalogPoliciesView} isRequestNewAppPoliciesView={isRequestNewAppPoliciesView} idAudit={idAudit}>
    <WorkflowV2Page {...props} idAudit={idAudit} />
  </WorkflowEditorProvider>
}

export default WorkflowV2PageWithProvider
