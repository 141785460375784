import React from 'react'
import BillingDetails from '../../../components/billingDetails'
import { Grid, Row, Col } from '@toriihq/design-system'
import UploadAppFiles from '../../../components/uploadAppFiles'
import ExternalFileBox from '../../../components/uploadAppFiles/externalFileBox'
import EnableFor from '../../../components/enableFor'
import { SCOPES } from '@root/constants'
import VisibleFor from '@components/visibleFor'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'

class Info extends React.Component {
  componentDidMount () {
    const { idOrg, idApp, getAppDetailsFields } = this.props
    if (idOrg && idApp) {
      getAppDetailsFields({ idOrg, idApp })
    }
  }

  render () {
    const { idOrg, idApp, isInUse, loading, expensesSources } = this.props

    if (!isInUse && !loading) {
      return null
    }

    const hasBillCom = Boolean((expensesSources || []).some(source => source === 'billcom'))

    return (
      <Grid>
        <Row>
          <Col sm={8}>
            <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.APPLICATIONS_WRITE, idOrg, idApp)]}>
              <BillingDetails idApp={idApp} loading={loading} />
            </EnableFor>
          </Col>
          <VisibleFor scopes={[SCOPES.EXPENSE_READ, getScopeByIdOrgAndIdApp(SCOPES.EXPENSE_READ, idOrg, idApp)]}>
            <Col sm={4}>
              <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.APPLICATIONS_WRITE, idOrg, idApp)]}>
                <UploadAppFiles idApp={idApp} loading={loading} />
              </EnableFor>
              {hasBillCom && <ExternalFileBox
                idApp={idApp}
                source='billcom'
                header='Bill.com'
                description='Showing bills from Bill.com'
                urlPrefix='https://app.bill.com/neo/bills/'
              />}
            </Col>
          </VisibleFor>
        </Row>
      </Grid>
    )
  }
}

export default Info
