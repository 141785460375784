import Input from '@components/form/input'
import { USER_FIELDS_TYPES } from '@root/constants'
import SelectUsers from '@components/selectUsers'
import React, { ReactElement } from 'react'
import FormGroup from '@components/form/formGroup'
import { ChangedUserDetails, UserDetailValue, FIELD_KEY, TriggerConfiguration, UserFieldType, User } from './types'
import DatePicker from '@components/datePicker'
import Placeholder from '@components/placeholder'
import { useSelector } from 'react-redux'
import { isLoadingWorkflows } from '@selectors/workflows'

interface Props {
  triggerConfiguration: [TriggerConfiguration]
  onChange: (value: ChangedUserDetails) => void
  value: ChangedUserDetails
}
export const UserDetailChange = ({ triggerConfiguration, onChange, value }: Props): ReactElement => {
  const loadingWorkflows = useSelector(isLoadingWorkflows)
  const isLoading = !triggerConfiguration && loadingWorkflows

  if (isLoading) {
    return <Placeholder loading rows={1} />
  }

  const triggerConfigurationValues = triggerConfiguration[0].value
  const userField = triggerConfigurationValues.find(field => field.id === 'userField')
  const userFieldType = userField!.value.value.type
  const userFieldLabel = userField!.value.label
  const appField = triggerConfigurationValues.find(field => field.id === 'app')
  const appName = appField!.value.name
  const idApp = appField!.value.id
  const accountField = triggerConfigurationValues.find(field => field.id === 'account')
  const idAppAccount = accountField!.value.id
  const userFieldKey = userField!.value.value.key

  const handleChange = ({
    previousValue = value.previousValue,
    newValue = value.newValue
  } : {
    previousValue?: UserDetailValue
    newValue?: UserDetailValue
  }) => {
    const userField = {
      label: userFieldLabel,
      key: userFieldKey,
      type: userFieldType
    }
    const isValid = Boolean(previousValue && newValue)
    const updatedValue = { isValid, previousValue, newValue, userField, idAppAccount, idApp }

    onChange(updatedValue)
  }

  const handleUserChange = (user: User, fieldKey: FIELD_KEY) => {
    handleChange({ [fieldKey]: user })
  }

  const handleDateChange = (date: string | undefined, fieldKey: FIELD_KEY) => {
    handleChange({ [fieldKey]: date || null })
  }

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>, fieldKey: FIELD_KEY) => {
    const value: string = e.target.value
    handleChange({ [fieldKey]: value })
  }

  const renderInputByUserFieldType = ({
    userFieldType,
    fieldKey
  }: {
    userFieldType: UserFieldType,
    fieldKey: FIELD_KEY
  }) => {
    switch (userFieldType) {
      case USER_FIELDS_TYPES.user:
        return <SelectUsers onChange={user => handleUserChange(user, fieldKey)} />
      case USER_FIELDS_TYPES.datePicker:
        return <DatePicker
          onDayChange={(date) => handleDateChange(date, fieldKey)}
          disabled={false}
        />
      default:
        return <Input onChange={e => handleInputValueChange(e, fieldKey)} />
    }
  }

  return <>
    <FormGroup label='An app where the change happened'>
      <Input disabled value={appName} />
    </FormGroup>
    <FormGroup label='An attribute that changed'>
      <Input disabled value={userFieldLabel} />
    </FormGroup>
    <FormGroup label='Changed from'>
      {renderInputByUserFieldType({ userFieldType, fieldKey: FIELD_KEY.previousValue })}
    </FormGroup>
    <FormGroup label='Changed to'>
      {renderInputByUserFieldType({ userFieldType, fieldKey: FIELD_KEY.newValue })}
    </FormGroup>
  </>
}
