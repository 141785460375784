import get from 'lodash/get'
import { createSelector } from 'reselect'
import { keyBy } from 'lodash'

export const getRecommendations = createSelector(
  state => get(state, ['recommendations']) || { recommendations: [], loadingRecommendations: false, loadingAppsList: false, appsList: [] },
  recommendations => {
    if (recommendations.appsList?.length > 0) {
      const appListById = keyBy(recommendations.appsList, 'idApp')
      recommendations.recommendations.sort((a, b) => appListById[a.idApp]?.appName.localeCompare(appListById[b.idApp]?.appName))
    }
    return recommendations
  }
)
