import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontWeight } from '@shared/style/sizes'

export const CollapsedContainer = css({
  flexDirection: 'column',
  color: colors.black,
  display: 'flex',
  alignItems: 'center',
  marginLeft: '17px',
  marginRight: '20px'
})

export const MainContainer = css({
  flexDirection: 'column',
  color: colors.black,
  display: 'flex',
  flex: 1,
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: fontWeight.semiBold
})

export const TrailContainer = css({
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  marginLeft: '25px'
})

export const ProgressContainer = css({
  flexDirection: 'column',
  display: 'flex',
  flexGrow: 1,
  marginLeft: '10px',
  marginRight: '40px'
})

export const IceCream = css({
  width: '20px',
  height: '23px'
})
