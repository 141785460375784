import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import WorkflowTemplateBox from '../workflowTemplateBox'
import { fontSize, fontWeight } from '../../../shared/style/sizes'

const CSS = {
  main: css({
    backgroundColor: colors.white
  }),
  workflowTemplateBox: css({
    display: 'inline-block',
    marginBottom: '19px',
    marginRight: '19px'
  }),
  header: css({
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.small,
    color: colors.grey2,
    marginBottom: '17px'
  })
}

const WorkflowsTemplatesList = ({ header, loading, templates = [], templateOnClick }) => (
  <div id={header} {...CSS.main}>
    <div {...CSS.header}>{header}</div>
    <div>{templates.map((template, index) => <div {...CSS.workflowTemplateBox} key={index}><WorkflowTemplateBox loading={loading} apps={template.apps} text={template.description} onClick={() => templateOnClick(template.id)} /></div>)}
    </div>
  </div>
)

WorkflowsTemplatesList.propTypes = {
  templates: PropTypes.array,
  templateOnClick: PropTypes.func,
  loading: PropTypes.bool
}

export default WorkflowsTemplatesList
