import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize } from '@shared/style/sizes'
import { Field } from 'react-final-form'
import FormGroup from '../../form/formGroup'
import Input from '@components/form/input'
import { AlertBox, AlertBoxType, Spacer } from '@toriihq/design-system'

const CSS = {
  fields: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  errorText: css({
    color: colors.error,
    fontSize: fontSize.small
  })
}

class ConnectToriiBot extends React.Component {
  textFieldComponent = (props) => {
    const { meta: { submitSucceeded, submitting, error, touched }, isRequired } = props

    return (
      <FormGroup label={props.label} error={touched ? error : null} isRequired={isRequired}>
        <Input
          disabled={submitting || submitSucceeded}
          error={touched && error}
          autoComplete='new-password'
          {...props.input}
        />
      </FormGroup>
    )
  }

  render () {
    const { formProps, selectServiceConfig, showReconnectAlertInfo } = this.props
    const { handleSubmit, submitError } = formProps

    return <form onSubmit={handleSubmit}>
      {showReconnectAlertInfo && <Spacer bottom='space-300'><AlertBox type={AlertBoxType.INFORMATIVE} description='Providing connection details which relate to a different account will result in the creation of an additional integration instance.' /></Spacer>}
      <div {...CSS.fields}>
        <Field
          className='fs-exclude'
          name='user'
          label='User name'
          isRequired
          component={this.textFieldComponent}
        />
        <Field
          className='fs-exclude'
          name='password'
          label='Password'
          type='password'
          isRequired
          component={this.textFieldComponent}
        />
        <Field
          className='fs-exclude'
          name='url'
          label='SSO/Login URL'
          placeholder='https://yourcompany.application.com'
          isRequired={Boolean(selectServiceConfig && selectServiceConfig.isUrlRequired)}
          component={this.textFieldComponent}
        />
        <div
          {...CSS.errorText}
          style={{ visibility: submitError ? 'visible' : 'hidden' }}>
          {submitError || 'no error'}
        </div>
      </div>
    </form>
  }
}

ConnectToriiBot.defaultProps = {
  showReconnectAlertInfo: false,
  onSelectedAppChange: () => {}
}
export default ConnectToriiBot
