import React from 'react'
import PropTypes from 'prop-types'
import { Toggle } from '@toriihq/design-system'

const ToriiToggle = ({ onToggle, id, ...rest }) => (
  <Toggle
    onChange={(e) => {
      onToggle && onToggle(e.target.checked, id)
    }}
    {...rest}
  />
)

ToriiToggle.propTypes = {
  onToggle: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default ToriiToggle
