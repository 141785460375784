import View from './view'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  const { validation, org } = state.emailValidation
  const passedEmailValidation = Boolean(validation)
  return {
    ...state.signup,
    passedEmailValidation,
    org
  }
}

export default connect(mapStateToProps)(View)
