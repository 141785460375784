import React from 'react'
import WorkflowActions from '@components/workflows/workflowActions'
import { useSelector } from 'react-redux'
import {
  getAppCatalogPoliciesByIdSelector,
  getAppCatalogPoliciesResourcesSelector
} from '@selectors/workflows'
import { isCustomAppCatalogPolicy } from '@lenses/workflows'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { EMPTY_OBJECT, WORKFLOW_TYPES } from '@root/constants'

const AppCatalogPolicyActions = ({
  idOrg,
  idWorkflow,
  name,
  triggerType,
  isValid,
  referrer,
  noBorder = false,
  onDeleteConfirmationOpen,
  pageName,
  shouldShowMenu,
  showDuplicateAction
}) => {
  const policy = useSelector(getAppCatalogPoliciesByIdSelector)[idWorkflow]
  const { appsMap } = useSelector(getAppCatalogPoliciesResourcesSelector)
  const type = get(policy, ['type'], WORKFLOW_TYPES.appCatalog)
  const policyIdApp = get(policy, ['triggerIdApp'])
  const policyApp = appsMap[policyIdApp]
  const isCustomPolicy = isCustomAppCatalogPolicy({ workflow: policy })

  const manualRunPolicyFormInitialValues = isCustomPolicy && !isEmpty(policyApp) ? { triggerApp: policyApp } : EMPTY_OBJECT

  return (
    <WorkflowActions
      idOrg={idOrg}
      idWorkflow={idWorkflow}
      workflowType={type}
      isValid={isValid}
      name={name}
      triggerType={triggerType}
      disableDeleteActionReason={!isCustomPolicy && 'Default policy cannot be deleted'}
      showDuplicateAction={showDuplicateAction}
      showEditTagsAction={false}
      onDeleteConfirmationOpen={onDeleteConfirmationOpen}
      noBorder={noBorder}
      referrer={referrer}
      manualRunFormInitialValues={manualRunPolicyFormInitialValues}
      isAppCatalogPoliciesView
      pageName={pageName}
      shouldShowMenu={shouldShowMenu}
    />
  )
}

export default AppCatalogPolicyActions
