import React, { useCallback, useMemo, useState } from 'react'

export const UserLifecycleSettingsContext = React.createContext({ isPreviewEnable: false, selectedLifecycleApps: [], preview: {}, updateLifecycleSettings: () => {} })

export const UserLifecycleSettingsProvider = ({ children, userLifecycleConfig }) => {
  const [selectedLifecycleApps, setSelectedLifecycleApps] = useState(userLifecycleConfig)
  const [preview, setPreview] = useState()

  const isPreviewEnable = useMemo(() => selectedLifecycleApps && selectedLifecycleApps.length > 0, [selectedLifecycleApps])

  const updateLifecycleSettings = useCallback(({ lifecycleApps, preview }) => {
    lifecycleApps && setSelectedLifecycleApps(lifecycleApps)
    preview && setPreview(preview)
  }, [])

  return (
    <UserLifecycleSettingsContext.Provider value={{ isPreviewEnable, selectedLifecycleApps, preview, updateLifecycleSettings }}>
      {children}
    </UserLifecycleSettingsContext.Provider>
  )
}
