import React from 'react'
import RemindersSettings from '@components/offboarding/remindersSettings'
import DefaultOffboardingToggle from '@components/offboarding/defaultOffboardingToggle'
import { Title, Container, Wrapper } from './style'

const AdvancedSettings = () => {
  return (
    <Container>
      <Title>Advanced</Title>
      <Wrapper>
        <RemindersSettings />
        <DefaultOffboardingToggle />
      </Wrapper>
    </Container>
  )
}

export default AdvancedSettings
