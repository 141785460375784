import React from 'react'
import { Helmet } from 'react-helmet'
import * as Style from './style'
import NotFoundImg from '@media/404.svg'

const NotFound = () => (
  <div className='NOTFOUND' {...Style.Page}>
    <Helmet>
      <title>404 - Page not found</title>
    </Helmet>
    <div {...Style.Main}>
      <div {...Style.Card}>
        <img alt='toro' src={NotFoundImg} width='110' />
        <div {...Style.Header}>{'404 Not Found'}</div>
        <div {...Style.Info}>
          {'The page could not be found'}
        </div>
      </div>
    </div>
  </div>
)

export default NotFound
