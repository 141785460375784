import moment from 'moment'
import { TRIGGER_PAYLOAD_TEST_STATE } from '../types'
import { TEST_TIMEOUT_MINUTES } from '../consts'

export const getTestState = ({
  testStartTime,
  testPayloadReceivedTime,
  payload,
  testError
}: {
  testStartTime: string | null
  testPayloadReceivedTime: string | null
  payload: any
  testError: string | null
}): TRIGGER_PAYLOAD_TEST_STATE => {
  if (!testStartTime) {
    return TRIGGER_PAYLOAD_TEST_STATE.READY_TO_TEST
  }

  if (testError) {
    return TRIGGER_PAYLOAD_TEST_STATE.FAILURE
  }

  if (payload && testPayloadReceivedTime &&
      moment(testPayloadReceivedTime).utc().isAfter(testStartTime)) {
    return TRIGGER_PAYLOAD_TEST_STATE.SUCCESS
  }

  const now = moment().utc()

  const testEndTime = moment(testStartTime)
    .utc()
    .add(TEST_TIMEOUT_MINUTES, 'minutes')

  const isTestTimePassed = now.isAfter(testEndTime)

  if (!isTestTimePassed) {
    return TRIGGER_PAYLOAD_TEST_STATE.IN_PROGRESS
  }

  return TRIGGER_PAYLOAD_TEST_STATE.FAILURE
}
