import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AssignAppOwnersPopup, { Props as AssignAppOwnersPopupProps } from '@components/popups/assignAppOwners'
import { Styles } from './style'
import { AddButton, Tooltip } from '@toriihq/design-system'
import { User } from '../popups/assignAppOwners/types'
import LinkableAvatar from '../_shared/linkableAvatar'
import EnableFor from '../enableFor'
import { SCOPES } from '@root/constants'

type Props =
  & Pick<AssignAppOwnersPopupProps, 'idApp' | 'onSubmitSuccess'>
  & {
    existingPrimaryOwner?: User;
    addButtonTooltipLabel?: string;
  }

export default function AssignAppOwnersButton (props: Props) {
  const { idApp, addButtonTooltipLabel, onSubmitSuccess, existingPrimaryOwner } = props

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const addButton = <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}>
    <AddButton onClick={() => setIsPopupOpen(true)} />
  </EnableFor>
  const wrappedAddButton = addButtonTooltipLabel
    ? <Tooltip label={addButtonTooltipLabel}>
      {addButton}
    </Tooltip>
    : addButton

  return <Styles.AssignAppOwnersButton>
    {existingPrimaryOwner ? <LinkableAvatar
      user={existingPrimaryOwner}
      displayEmail
      displayIsDeletedUser
    /> : wrappedAddButton}

    {isPopupOpen && <AssignAppOwnersPopup
      idApp={idApp}
      isOpen={isPopupOpen}
      onCancel={() => setIsPopupOpen(false)}
      onSubmitSuccess={(...args) => {
        setIsPopupOpen(false)
        onSubmitSuccess(...args)
      }}
    />}
  </Styles.AssignAppOwnersButton>
}

AssignAppOwnersButton.propTypes = {
  idApp: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired
}
