import View from './view'
import { connect } from 'react-redux'
import { signup } from '../../../pages/login/actions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state) => ({
  loginInfo: state.login.SIGNUP
})

const mapDispatchToProps = {
  catalogSignup: signup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
