import React from 'react'
import AppChargeback from '../../../components/appChargeback'

class ChargebackTab extends React.Component {
  render () {
    const { idApp } = this.props
    return <AppChargeback idApp={idApp} />
  }
}

export default ChargebackTab
