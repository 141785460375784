import React from 'react'
import { css } from 'glamor'
import texts from '../../../shared/style/texts'
import RelativeTeamLink from '../../relativeTeamLink'
import { Tooltip, Link } from '@toriihq/design-system'
import get from 'lodash/get'
import pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'
import Analytics from '../../../helpers/analytics'
import { WORKFLOW_ACTION_TYPES } from '../../../constants'
import { fontSize } from '../../../shared/style/sizes'

const CSS = {
  headerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.small
  }),
  header: css(texts.headers.small, {
    textTransform: 'uppercase'
  })
}

class SendFormPreviewButton extends React.Component {
  navigateToPreview = () => {
    const previewData = this.buildPreviewData()
    window.localStorage?.setItem('formPreviewData', JSON.stringify(previewData))
    Analytics.track('Clicked to see form preview')
  }

  buildPreviewData () {
    const { action, apps, contracts, contractDetails, appDetails } = this.props

    const formIntro = action.fields.find(field => field.id === 'intro').value
    const idFields = action.fields.find(field => field.id === 'fields').value || []
    const appDetailsMap = keyBy(appDetails, 'idField')
    const contractDetailsMap = keyBy(contractDetails, 'id')
    const formFields = idFields.map(idField => action.type === WORKFLOW_ACTION_TYPES.SEND_CONTRACT_FORM ? contractDetailsMap[idField] : appDetailsMap[idField])

    const app = pick(apps[0], ['name', 'idApp', 'imageUrl'], {})
    const contract = pick(contracts[0], ['name', 'id'], {})
    return {
      emailSetup: {
        from: {
          id: 1,
          firstName: 'Tony',
          lastName: 'Stark',
          email: 'tony@stark.com'
        },
        to: {
          id: 2,
          firstName: 'Peter',
          lastName: 'Parker',
          email: 'peter@parker.com'
        }
      },
      formIntro,
      formFields,
      app,
      contract,
      actionType: action.type
    }
  }

  render () {
    const { idOrg } = this.props
    const previewData = this.buildPreviewData()
    const previewUrl = '/formPreview'
    const previewTooltiphtml = <div>See form preview, as it would look like if: <br /> Detected application: {get(previewData, ['app', 'name'], '')}<br />Recipient: {get(previewData, 'emailSetup.to.firstName', '')} <br /> Sender: {get(previewData, 'emailSetup.from.firstName', '')}</div>

    return (
      <div {...CSS.headerContainer}>
        <Tooltip label={previewTooltiphtml}>
          <RelativeTeamLink target='_blank' to={previewUrl} idOrg={idOrg} onClick={this.navigateToPreview}><Link>Sample form preview</Link></RelativeTeamLink>
        </Tooltip>
      </div>
    )
  }
}

export default SendFormPreviewButton
