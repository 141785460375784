import { TriggerStepConfiguration } from '@shared/workflows/types'

export const clearTriggerConfigurationFields = (
  triggerConfiguration: TriggerStepConfiguration[],
  idFields: string[]
): TriggerStepConfiguration[] => {
  return triggerConfiguration.map(field => {
    if (idFields.includes(field.id)) {
      return { ...field, value: null }
    }

    return field
  })
}
