import React, { ReactElement } from 'react'
import ToriiSelect from '@components/select'
import Input from '@components/form/input'
import FormGroup from '@components/form/formGroup'
import { formFieldTypes } from '@root/constants'
import { CSS, DateNewValue } from './styles'

interface Props {
  field: any
  disabled: boolean,
  value: { dateField?: any, sign?: number, number?: number, unit?: string }
  onValueChange: (value: any, locally?: boolean) => void
}

export const UpdateDateField = ({
  disabled,
  field,
  value = {},
  onValueChange
}: Props): ReactElement => {
  const className = `${CSS.dateFields}`

  return <FormGroup label='New Value' isRequired>
    <DateNewValue>
      <ToriiSelect
        className={className}
        options={[{ name: 'Triggered date', systemKey: 'trigger.date' }].concat((field.options || []).filter(f => f.type === formFieldTypes.datePicker))}
        placeholder='Select field'
        valueKey='systemKey'
        labelKey='name'
        onChange={(e) => onValueChange({ ...value, dateField: e })}
        clearable={false}
        value={value.dateField}
        disabled={disabled}
      />
      <ToriiSelect
        className={className}
        options={[{ label: 'Plus', value: 1 }, { label: 'Minus', value: -1 }]}
        value={value.sign}
        onChange={(e) => onValueChange({ ...value, sign: e })}
        disabled={disabled}
      />
      <Input
        className={`${CSS.dateNumber.toString()} ${className}`}
        type='number'
        value={value.number}
        onChange={(e) => onValueChange({ ...value, number: e.target.value }, true)}
        onBlur={(e) => onValueChange({ ...value, number: e.target.value })}
        disabled={disabled}
      />
      <ToriiSelect
        className={className}
        options={[{ label: 'Days', value: 'days' }, { label: 'Months', value: 'months' }, { label: 'Years', value: 'years' }]}
        value={value.unit}
        onChange={(e) => onValueChange({ ...value, unit: e })}
        disabled={disabled}
      />
    </DateNewValue>
  </FormGroup>
}
