import React, { ReactElement } from 'react'
import { EdgeLabelRenderer, EdgeProps } from 'reactflow'
import { ButtonContainer } from './styles'
import { AddActionButton } from './addActionButton'
import { ActionIfThenLabelRenderer } from './actionIfThenLabelRenderer'
import { DefaultEdge } from '../defaultEdge'
import { getEdgeData } from '../utils'

export const EdgeWithAddButton = (props: EdgeProps): ReactElement => {
  const { labelX, labelY } = getEdgeData(props)
  const { source: idParentNode } = props

  return (<>
    <DefaultEdge {...props} />
    <EdgeLabelRenderer>
      <ButtonContainer
        labelX={labelX}
        labelY={labelY}
        className='nodrag nopan'
      >
        <ActionIfThenLabelRenderer idParentNode={idParentNode} />
        <AddActionButton idParentNode={idParentNode} />
      </ButtonContainer>
    </EdgeLabelRenderer>
  </>)
}
