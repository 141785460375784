import { NodeDimensions } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'

export const DEFAULT_NODE_DIMENSIONS: NodeDimensions = {
  width: 418,
  height: 82
}

export const END_NODE_DIMENSIONS: NodeDimensions = {
  width: 55,
  height: 25
}

export const BRANCH_LABEL_DIMENSIONS: NodeDimensions = {
  width: 224,
  height: 40
}

export const WORKFLOW_NODE_CLASS_NAME = 'workflow-node'
