import styled from 'styled-components'
import { css } from 'glamor'
import colors from '@shared/style/colors'

export const CSS = {
  previewButtonContainer: css({
    marginTop: '34px'
  }),
  divWrapSelectWithError: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  spanError: css({
    color: colors.error
  })
}

export const TriggerConfiguration = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TriggerHeader = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle01};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const ScheduleHeader = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  font: ${({ theme }) => theme.typography.font.subtitle01};
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
`

export const IconWrapper = styled.div`
  padding-top: 8px;
`

export const AlertBoxWrapper = styled.div`
 margin: 20px 0;
`
