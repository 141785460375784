import React from 'react'
import { css } from 'glamor'
import Placeholder from '../../components/placeholder'
import BasicTopBar from '../../components/basicTopBar'
import { Body1, H3 } from '@toriihq/design-system'
import NotFoundImg from '@media/404.svg'
import CheckmarkImg from '@media/checkmark.svg'

const CSS = {
  textAndIconBox: css({
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '16px'
  })
}

class WorkflowRequestApprovalPage extends React.Component {
  componentDidMount () {
    const { submitWorkflowApprovalRequest, idActionExe, idOrg, approved, token } = this.props
    submitWorkflowApprovalRequest({ idOrg, idActionExe, token, approved })
  }

  renderTextAndIcon (element, header, description) {
    return <div {...CSS.textAndIconBox}>
      {element}
      <H3>{header}</H3>
      {description && <Body1>{description}</Body1>}
    </div>
  }

  renderAuthError () {
    return this.renderTextAndIcon(
      <img alt='Not found' src={NotFoundImg} width='110' />,
      'You don\'t have the required permissions')
  }

  renderThanksMessage (approved, approveText, declineText) {
    const approve = approveText || 'Your answer is Approve'
    const decline = declineText || 'Your answer is Decline'
    return this.renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      'Thanks!',
      approved ? approve : decline)
  }

  renderDoneMessage () {
    return this.renderTextAndIcon(
      <img alt='Success' src={CheckmarkImg} width='110' />,
      'This request was already submitted, thanks!')
  }

  render () {
    const { isAuthError, submitSuccess, alreadyCompleted, loading, approved, approveText, declineText } = this.props
    const showThanksMessage = !isAuthError && submitSuccess
    const showDoneMessage = !isAuthError && alreadyCompleted

    return (
      <>
        <BasicTopBar />
        <Placeholder loading={loading} type='text' rows={6} style={{ margin: '0 auto', paddingTop: '50px' }}>
          {isAuthError && this.renderAuthError()}
          {showThanksMessage && this.renderThanksMessage(approved, approveText, declineText)}
          {showDoneMessage && this.renderDoneMessage()}
        </Placeholder>
      </>
    )
  }
}

export default WorkflowRequestApprovalPage
