import { UpdateNextNodeParams } from './updateNextNodeInWorkflowActions.types'
import { ActionWithFixedBranchesNodeModel, BranchNodeModel, WorkflowActionsModel } from '@shared/workflows/types'
import { dfsTraverseActions } from '@shared/workflows/actions/utils/dfsTraverseActions'
import cloneDeep from 'lodash/cloneDeep'
import { getBranchesData } from '@shared/workflows/actions/utils/getBranchesData'

const handleActionWithBranches = ({
  idNode,
  node,
  idCurrentNextNode,
  idNewNextNode,
  result
}: {
  idNode: string
  node: BranchNodeModel | ActionWithFixedBranchesNodeModel
  idCurrentNextNode: string
  idNewNextNode: string | null
  result: WorkflowActionsModel
}): void => {
  const { branches, defaultBranch } = getBranchesData(node)
  let clonedActionWithBranchNode: BranchNodeModel | ActionWithFixedBranchesNodeModel | undefined

  if (defaultBranch.next === idCurrentNextNode) {
    clonedActionWithBranchNode = cloneDeep(node)
    const { defaultBranch: resultDefaultBranch } = getBranchesData(clonedActionWithBranchNode)
    resultDefaultBranch.next = idNewNextNode
  } else {
    branches.forEach((branch, index) => {
      if (branch.next === idCurrentNextNode) {
        clonedActionWithBranchNode = cloneDeep(node)
        const { branches: resultBranches } = getBranchesData(clonedActionWithBranchNode)
        const resultBranch = resultBranches[index]
        resultBranch.next = idNewNextNode
      }
    })
  }

  if (clonedActionWithBranchNode) {
    result.nodes[idNode] = clonedActionWithBranchNode
  }
}

export const updateNextNodeInWorkflowActions = ({
  idCurrentNextNode,
  idNewNextNode,
  workflowActions
}: UpdateNextNodeParams): WorkflowActionsModel => {
  const { nodes, idRootNode } = workflowActions
  const result: WorkflowActionsModel = {
    idRootNode,
    nodes: { ...nodes }
  }

  if (idRootNode === null) {
    return result
  }

  if (idRootNode === idCurrentNextNode) {
    return {
      ...result,
      idRootNode: idNewNextNode
    }
  }

  dfsTraverseActions({
    idCurrentNode: idRootNode,
    nodes,
    handleAction: ({ idNode, actionNode }) => {
      if (actionNode.next === idCurrentNextNode) {
        result.nodes[idNode] = {
          ...actionNode,
          next: idNewNextNode
        }
      }
    },
    handleBranchAction: ({ idNode, branchNode }) => {
      handleActionWithBranches({
        idNode,
        node: branchNode,
        idCurrentNextNode,
        idNewNextNode,
        result
      })
    },
    handleActionWithFixedBranches: ({ idNode, actionNode }) => {
      handleActionWithBranches({
        idNode,
        node: actionNode,
        idCurrentNextNode,
        idNewNextNode,
        result
      })
    }
  })

  return result
}
