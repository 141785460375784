import { getNodeDimensions } from '../../utils/getNodeDimensions'
import { WORKFLOW_UI_NODE_TYPE } from '../../types'
import { AlignToTriggerNodeParams, CenterToSelectedNodeParams } from './types'
import { PADDING_TOP } from './consts'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'

export const centerToSelectedNode = ({
  selectedNode,
  setCenter,
  zoom
}: CenterToSelectedNodeParams) => {
  const { position, type } = selectedNode
  const { height, width } = getNodeDimensions(type as WORKFLOW_UI_NODE_TYPE)

  const calculateCenter = (start: number, length: number) => start + (length / 2)

  setCenter(
    calculateCenter(position.x, width),
    calculateCenter(position.y, height),
    { zoom, duration: 0 }
  )
}

export const alignToTriggerNode = ({
  getNode,
  reactflowContainerWidth,
  setViewport,
  zoom
}: AlignToTriggerNodeParams) => {
  const triggerNode = getNode(ID_TRIGGER_NODE)!
  const { width: nodeWidth } = getNodeDimensions(triggerNode.type as WORKFLOW_UI_NODE_TYPE)
  const x =
    // get the center point of the reactflow container:
    (reactflowContainerWidth / 2) -
    // in case the trigger node is not on `0` subtract the distance from the center point to the trigger node position:
    (triggerNode.position.x * zoom) -
    // subtract half of the trigger node width to get the center of the trigger node:
    (nodeWidth / 2 * zoom)
  const y = (PADDING_TOP - triggerNode.position.y)
  setViewport({ x, y, zoom })
}
