import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import RelativeTeamLink from '../relativeTeamLink'
import Analytics from '@helpers/analytics'
import ExtensionRolloutStatus from '@media/extensionRolloutStatus.svg'
import ThirdPartyReport from '@media/3rdPartyReport.svg'
import MonthlyReport from '@media/monthlyReport.svg'
import { ReportsTypes } from '@components/reportBox/constants'
import { Button, ButtonType, ButtonSize, Icon, Tooltip } from '@toriihq/design-system'

const CSS = {
  main: css({
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderRadius: '4px',
    width: '250px',
    height: '290px',
    padding: '24px 23px 40px 26px',
    overflow: 'hidden',
    ':hover': {
      boxShadow: '0 7px 15px 0 rgba(0,0,0,0.24)'
    },
    ' .info': css({
      transition: 'transform .2s'
    }),
    ':hover .info': css({
      transform: 'translateY(-47px)'
    }),
    ':hover .bottomViewButton a': {
      transform: 'translateY(-47px)',
      opacity: '1'
    },
    ':hover .bottomDownloadButton': {
      transform: 'translateY(-47px)',
      opacity: '1'
    }
  }),
  name: css(texts.headers.regular, {
    color: colors.white,
    marginBottom: '12px'
  }),
  description: css(texts.subheading, {
    color: colors.white
  }),
  viewAndDownload: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
    cursor: 'inherit'
  }),
  bottomBoxButton: css(texts.subheading, {
    transition: 'opacity .2s, transform .2s',
    opacity: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '47px',
    backgroundColor: colors.white,
    borderRadius: '0 0 4px 4px',
    position: 'absolute',
    bottom: '-47px',
    left: 0,
    right: 0,
    color: `${colors.blue} !important`,
    cursor: 'pointer'
  }),
  iconLock: css({
    marginBottom: '64px'
  })
}

const getBackgroudImg = (imgName = '') => {
  switch (imgName) {
    case ReportsTypes.Monthly: {
      return MonthlyReport
    }
    case ReportsTypes.ExtensionRolloutStatus: {
      return ExtensionRolloutStatus
    }
    case ReportsTypes.ThirdParty: {
      return ThirdPartyReport
    }
    default: {
      return ThirdPartyReport
    }
  }
}

const generateCSS = (color = '', imgName = '') => {
  const generatedCSS = Object.assign({}, CSS)
  generatedCSS.main = css(generatedCSS.main, {
    backgroundImage: `url(${getBackgroudImg(imgName)})`,
    backgroundColor: color
  })

  return generatedCSS
}

class ReportBox extends React.Component {
  constructor (props) {
    super(props)

    const { color, imgName } = props
    this.CSS = generateCSS(color, imgName)
  }

  onClick = () => {
    const { name } = this.props

    Analytics.track('Click on Report card', {
      'Report name': name
    })
  }

  render () {
    const { name, description, reportName, onCsvDownload, downloadEnabled, viewEnabled, shouldLockReport = false } = this.props

    const CSS = this.CSS

    return (
      <div {...CSS.main}>
        {shouldLockReport && <div {...CSS.iconLock}><Icon name='Lock' color='onColor' /></div>}
        <div className='info' data-testid='report-box-info'>
          <div {...CSS.name}>{name}</div>
          <div {...CSS.description}>{description}</div>
        </div>
        {(!viewEnabled && downloadEnabled) && <div className='bottomDownloadButton' {...css(CSS.bottomBoxButton)}>
          <Tooltip hide={!shouldLockReport} label={'Enterprise plan feature'}><Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => onCsvDownload()} icon={shouldLockReport ? 'Lock' : 'Download'} label='CSV' disabled={shouldLockReport} /></Tooltip>
        </div>}
        {viewEnabled && !downloadEnabled && <div className='bottomViewButton'><RelativeTeamLink onClick={this.onClick} className={CSS.bottomBoxButton.toString()} to={`/reports/${reportName}`}>VIEW</RelativeTeamLink></div>}
        {(viewEnabled && downloadEnabled) && <div className='bottomDownloadButton' {...css(CSS.bottomBoxButton, CSS.viewAndDownload)}>
          <RelativeTeamLink onClick={this.onClick} to={`/reports/${reportName}`}>VIEW</RelativeTeamLink>
          <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => onCsvDownload()} icon='Download' label='CSV' />
        </div>}
      </div>
    )
  }
}

ReportBox.propTypes = {
  imgName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  viewEnabled: PropTypes.bool.isRequired
}

ReportBox.defaultProps = {
  downloadEnabled: true,
  viewEnabled: true
}
export default ReportBox
