import { css } from 'glamor'

export const oneLiner = css({
  display: 'block',
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
})

export const clearButton = css({
  outline: '0',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer'
})

export const isDisabledCss = css({
  cursor: 'not-allowed !important',
  backgrondColor: 'rgba(0,0,0,0.5)'
})

export const align = {
  left: css({ textAlign: 'left' }),
  right: css({ textAlign: 'right' })
}

export const insideTableRowsClass = '.rt-tr:hover &'
export const insideTableCellsClass = '&.rt-td'
export const hoverActions = css({
  opacity: 0,
  transition: 'opacity .2s',
  [insideTableCellsClass]: {
    textOverflow: 'unset !important'
  },
  [insideTableRowsClass]: {
    opacity: 1
  }
})
