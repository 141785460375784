import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { getCurrencySymbol } from '@selectors/org'

export enum CurrencyOldFormat {
  FLOAT = '$0,0[.]00',
  DEFAULT = '$0,'
}
export interface CurrencyOldProps {
    value: number
    valueInCents?: boolean
    format?: CurrencyOldFormat
    currencySymbol?: string,
    toUpperCase?: boolean
}
export const CurrencyOld = (props: CurrencyOldProps): JSX.Element => {
  const { value,
    valueInCents = false,
    format = CurrencyOldFormat.DEFAULT,
    currencySymbol = '',
    toUpperCase = false } = props

  const orgDefaultCurrencySymbol = useSelector(getCurrencySymbol)
  const valueInMajorUnit = valueInCents ? value / 100 : value

  const currency = numeral(valueInMajorUnit)
    .format(format)
    .replace('$', currencySymbol || orgDefaultCurrencySymbol)
  return toUpperCase ? currency.toUpperCase() : currency
}
export default CurrencyOld
