import { getWorkflowPathSuffix } from './utils'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { IdWorkflowNodeStep } from '@pages/workflow_v2/types'
import { IdWorkflowUiNode } from '@shared/workflows/actions/types'
import { WorkflowNavigationUtils } from './types'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { WORKFLOW_TYPES, WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import { useWorkflowEditorContext } from '../../workflowEditor/context'

export const useWorkflowNavigation = (): WorkflowNavigationUtils => {
  const { idOrg, idWorkflow, idSelectedNode, stepIndex } = useParams()
  const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = useWorkflowEditorContext()
  const history = useHistory()
  const location = useLocation()

  const teamPath = `/team/${idOrg}`
  const workflowType = isAppCatalogPoliciesView || isRequestNewAppPoliciesView ? WORKFLOW_TYPES.appCatalog : WORKFLOW_TYPES.regular
  const triggerType = isAppCatalogPoliciesView ? WORKFLOW_TRIGGER_TYPES.REQUEST_ACCESS : isRequestNewAppPoliciesView ? WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP : null
  const workflowPathPrefix = getWorkflowPathPrefix({ workflowType, triggerType })
  const workflowPathSuffix = getWorkflowPathSuffix(location.search)
  const workflowPath = `/${workflowPathPrefix}/${idWorkflow}`
  const basePath = `${teamPath}${workflowPath}`

  const selectNode = (idNode: IdWorkflowUiNode) => {
    history.push(`${basePath}/${idNode}${workflowPathSuffix}`)
  }

  const selectNodeStep = (idNodeStep: IdWorkflowNodeStep = '0') => {
    history.replace(`${basePath}/${idSelectedNode}/${idNodeStep}${workflowPathSuffix}`)
  }

  const unselectNode = () => {
    history.push(`${basePath}${workflowPathSuffix}`)
  }

  const goToNextStep = () => {
    const nextStepIndex = Number(stepIndex) + 1
    selectNodeStep(`${nextStepIndex}`)
  }

  return {
    idSelectedNode,
    idSelectedNodeStep: stepIndex,
    selectNode,
    unselectNode,
    selectNodeStep,
    goToNextStep
  }
}
