import analytics from '@root/helpers/analytics'

const Analytics = {
  onFilterChangeAnalytics: (fromPage, appName, columnName) => {
    const props = {
      'Page name': fromPage,
      'Column name': columnName
    }
    if (appName) {
      props['App name'] = appName
    }
    analytics.track('Filter recommendations-tab', props)
  },
  onLearnMoreClickAnalytics: (fromPage, appName) => {
    const props = {
      'Page name': fromPage
    }
    if (appName) {
      props['Application name'] = appName
    }

    analytics.track('Click on learn-more-recommendations', props)
  }
}

export default Analytics
