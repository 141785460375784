import React, { Fragment } from 'react'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import SourceIcon from '../../sourceIcon'
import { getSourceByType } from '../../../sourcesConfig'
import { Tooltip, Spacer } from '@toriihq/design-system'
import Placeholder from '../../placeholder'
import { useWindowDimensions } from '@shared/hooks'
import * as Style from './style'
import { getSourceName, sortSourcesList } from '@lenses/sources'
import isEmpty from 'lodash/isEmpty'

const SourcesLine = ({ appName, name, sourcesList, emptyListMessage, loading, overrideStyle, isSmallScreen, disabledSource }) => {
  const { width: windowWidth } = useWindowDimensions()
  const { sourceToDisable, tooltipProps } = disabledSource

  const getIconsAmount = () => {
    if (isSmallScreen) {
      return windowWidth >= Style.SMALL_SCREEN_WIDTH ? 3 : (windowWidth >= Style.XS_SMALL_SCREEN_WIDTH ? 2 : 1)
    }
    return windowWidth >= Style.LARGE_SCREEN_WIDTH ? 3 : (windowWidth >= Style.MEDIUM_SCREEN_WIDTH ? 2 : 1)
  }
  const showSourceName = () => (isSmallScreen && windowWidth >= Style.SMALL_SCREEN_BASE_WIDTH) || (!isSmallScreen && windowWidth >= Style.BASE_SCREEN_WIDTH)

  const hasSources = sourcesList && sourcesList.length
  const sortedSourcesList = sortSourcesList({ sourcesList, appName })
  const iconsAmount = getIconsAmount()
  const sourcesIcons = sortedSourcesList.slice(0, iconsAmount).map(source => {
    const sourceConfig = getSourceByType(source)
    const sourceName = showSourceName() ? getSourceName({ sourceConfig, appName }) : ''
    const isSourceDisabled = !isEmpty(disabledSource) && source === sourceToDisable
    const sourceItem = getSourceItem({ source, isSourceDisabled, sourceName, tooltipProps })

    return (
      <div key={source} {...css(Style.SourceItem, isSourceDisabled && Style.Disabled)}>
        {sourceItem}
      </div>
    )
  })
  const extraSources = sourcesList && sortedSourcesList.slice(iconsAmount, sourcesList.length).map(source => {
    const sourceConfig = getSourceByType(source)
    return getSourceName({ sourceConfig, appName })
  })
  const sourcesTooltip = extraSources?.length > 0 ? <Spacer left={'space-100'}><Tooltip placement='top' label={extraSources.join(', ')}><span {...Style.Sources}>+{extraSources.length}</span></Tooltip></Spacer> : ''

  return (
    <div {...Style.SourceLine}>
      <Placeholder loading={loading} rows={1} style={{ maxWidth: '70%' }}>
        <Fragment>
          <div {...css(Style.SourceName, overrideStyle)}>{name}</div>
          <div {...Style.SourcesList}>
            {hasSources ? <Fragment>{sourcesIcons}{sourcesTooltip}</Fragment> : showEmptySourceList(emptyListMessage)}
          </div>
        </Fragment>
      </Placeholder>
    </div>
  )
}

const getSourceItem = ({ source, isSourceDisabled, sourceName, tooltipProps }) => {
  const showDisabledSourceTooltip = !isSourceDisabled

  return (
    <Tooltip
      position='top'
      {...tooltipProps}
      hide={showDisabledSourceTooltip}
    >
      <SourceIcon imgStyle={css(Style.SourceIcon, isSourceDisabled && Style.Disabled)} sourceType={source} tooltipText={sourceName} disableTooltip={isSourceDisabled} />
      {sourceName}
    </Tooltip>
  )
}

const showEmptySourceList = (emptyListMessage) => (
  <Tooltip placement='top' label={emptyListMessage}>
    —
  </Tooltip>
)

SourcesLine.propTypes = {
  name: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  sourcesList: PropTypes.array,
  emptyListMessage: PropTypes.node.isRequired,
  isSmallScreen: PropTypes.bool,
  disabledSource: PropTypes.object
}

SourcesLine.defaultProps = {
  sourcesList: [],
  emptyListMessage: '-',
  isSmallScreen: false,
  disabledSource: {}
}

export default SourcesLine
