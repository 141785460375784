import { createSelector } from 'reselect'
import identity from 'lodash/identity'

export const isLoadingWorkflowForm = createSelector(
  state => state.instantAccess.loadingWorkflowForm,
  identity
)

export const isWorkflowFormAuthError = createSelector(
  state => state.instantAccess.workflowForm.authError,
  authError => Boolean(authError)
)

export const isWorkflowFormSubmitSuccess = createSelector(
  state => state.instantAccess.workflowForm.submitSuccess,
  identity
)

export const getWorkflowFormInfo = createSelector(
  state => state.instantAccess.workflowForm,
  identity
)

export const getWorkflowRequestApprovalInfo = createSelector(
  state => state.instantAccess.workflowRequestApproval,
  identity
)

export const isLoadingConnectIntegration = createSelector(
  state => state.instantAccess.loadingConnectIntegration,
  identity
)

export const getConnectIntegrationInfo = createSelector(
  state => state.instantAccess.connectIntegration,
  identity
)

export const isLoadingUserTasks = createSelector(
  state => state.instantAccess.userTasks.loading,
  identity
)

export const isUserTasksAuthError = createSelector(
  state => state.instantAccess.userTasks.authError,
  authError => Boolean(authError)
)

export const isUserTasksUpdateError = createSelector(
  state => state.instantAccess.userTasks.updateError,
  updateError => Boolean(updateError)
)

export const getUserTasks = createSelector(
  state => state.instantAccess.userTasks,
  identity
)

export const isShowOffboardingMessage = createSelector(
  state => state.instantAccess.userTasks.isOpen,
  identity
)

export const getOffboardingMessage = createSelector(
  state => state.instantAccess.userTasks.message,
  identity
)
