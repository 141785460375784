import React from 'react'
import { connect } from 'react-redux'
import { getEmailFromValidationKey, sendRequest } from '../signup/actions'
import { withRouter, Link } from 'react-router-dom'
import MessagePage from '../../components/signup/messagePage'
import { USER_STATUS } from '../../constants'
import Analytics from '../../helpers/analytics'
import { isCatalog, isExtensionLogin, isWebapp } from '../../lenses/utils'
import { Button, ButtonSize } from '@toriihq/design-system'

const mapStateToProps = (state) => {
  return {
    ...state.emailValidation
  }
}

const mapDispatchToProps = {
  getEmailFromValidationKey,
  sendRequest
}

class Validation extends React.Component {
  componentDidMount () {
    const { getEmailFromValidationKey, history } = this.props
    const { id, key } = this.props.match.params
    getEmailFromValidationKey(id, key).then(response => {
      const { email, org, error, user, next, utmParams } = response

      const urlQueryParams = new URLSearchParams(utmParams)
      next && urlQueryParams.set('next', next)
      const queryParams = urlQueryParams.toString() ? `?${urlQueryParams.toString()}` : ''

      if (email && !error && !this.isActiveUser(user)) {
        const isFromCatalog = isCatalog(next)
        const isFromWebapp = isWebapp(next)
        const isFromToExtension = isExtensionLogin(next)

        if (!org) {
          history.replace(`/signup/1/${id}/${key}${queryParams}`)
        } else if (isFromCatalog || isFromWebapp) {
          const step = user && user.firstName && user.lastName ? 2 : 1
          history.replace(`/signup/${step}/${id}/${key}${queryParams}`)
        } else if (isFromToExtension) {
          this.signup({ id, key, email }).then(() => {
            history.replace(`/team/extensionLogin`)
          })
        } else {
          history.replace(`/signup/1/${id}/${key}${queryParams}`)
        }
      }
    })
  }

  signup = async ({ id, key, email }) => {
    const { sendRequest } = this.props
    Analytics.track('Signup from extension flow', {
      Email: email
    })
    const response = await sendRequest({
      id,
      key
    })

    if (response.id) {
      const me = {
        ...response,
        creationTime: Date.now()
      }
      Analytics.alias(me.id)
      Analytics.identify(me)
      Analytics.track('Signed up')
    }
  }

  isActiveUser (user) {
    return user && user.status === USER_STATUS.ACTIVE
  }

  render () {
    const { error, user } = this.props

    return (
      <div>
        {error && (
          <MessagePage
            headerText='We could not validate your email'>
            The sign-up link is invalid or expired. Please reach out to your contact at Torii.
          </MessagePage>
        )}

        {this.isActiveUser(user) && (
          <MessagePage headerText={`Hi ${user.firstName}`}>
            <p>It seems like you've already signed up to Torii in the past,
              that's awesome.</p>
            <p><Link to='/login'><Button size={ButtonSize.medium} label='Continue to Login' /></Link>
            </p>
          </MessagePage>
        )}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Validation))
