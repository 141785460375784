import styled from 'styled-components'
import { link } from '@shared/style/textsStyled'

export namespace Styles {
    export const TextArea = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
    `

    export const TextListHeader = styled.div`
      font: ${({ theme }) => theme.typography.font.subtitle02};
    `

    export const TextList = styled.div`
    `

    export const Container = styled.div`
      display: flex;
      padding: 0px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    `

    export const Link = styled.a`
      ${link}
    `
}
