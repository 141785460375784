import React from 'react'
import Analytics from './analytics'
import defer from 'lodash/defer'

const withTracker = (Component) => {
  return class extends React.Component {
    componentDidUpdate (prevProps) {
      const { pathname } = this.props.location
      if (this.props.location !== prevProps.location) {
        this.trackPage(pathname)
      }
    }

    componentDidMount () {
      const { pathname } = this.props.location
      this.trackPage(pathname)
    }

    trackPage (pathname) {
      this.waitForTitleChange(() => {
        Analytics.pageView(pathname)
      })
    }

    waitForTitleChange (func) {
      defer(func)
    }

    render () {
      return <Component {...this.props} />
    }
  }
}

export default withTracker
