import React, { ReactElement } from 'react'
import { NavigationToWorkflowExecutions } from './styles'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import NavigationToWorkflowExecutionLog from '@components/navigationToWorkflowExecutionLog'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { capitalize } from 'lodash'

export const NavToExecutionLog = (): ReactElement => {
  const workflow = useSelectedWorkflow()
  const { id: idWorkflow, type: workflowType, triggerType } = workflow || {}
  const { isAppCatalogPoliciesView } = useWorkflowEditorContext()

  if (!workflow.executions) {
    return <></>
  }

  const entity = isAppCatalogPoliciesView ? 'policy' : 'workflow'
  const pathPrefix = getWorkflowPathPrefix({ workflowType, triggerType })

  const navLogComponent = (
    <NavigationToWorkflowExecutionLog
      navTo={`/${pathPrefix}/${idWorkflow}/executions`}
      workflow={workflow}
      isAppCatalogPoliciesView={isAppCatalogPoliciesView}
      title={`View ${entity} runs (${workflow.executions})`}
      pageName={`${capitalize(entity)} configuration`}
    />
  )

  const componentToRender = (
    <NavigationToWorkflowExecutions>{navLogComponent}</NavigationToWorkflowExecutions>
  )
  return componentToRender
}
