import React, { Fragment, useContext, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Checkbox, Button, ButtonSize, ButtonType } from '@toriihq/design-system'
import * as Style from './style'
import { css } from 'glamor'
import BackLink from '@components/backLink'
import { ToriiPopupContext, ToriiPopupProvider } from '@components/popups/ToriiPopupV2/popupContext'
import RenderModals from '@components/popups/ToriiPopupV2/renderModals'
import EnableForIfScopes from '@components/popups/ToriiPopupV2/enableForIfScopes'
import VisibleFor from '@components/visibleFor'
import SubmitButton from '@components/submitButton'

const getClientHeight = (areaId) => {
  const popupAreas = document.querySelectorAll(`#${areaId}`)
  const popupAreasArr = Array.from(popupAreas)

  return popupAreasArr && popupAreasArr.length >= 1 ? popupAreasArr[popupAreasArr.length - 1] : { clientHeight: 0 }
}

const getHeaderAndFooterHeight = () => {
  const popupHeaderArea = getClientHeight('popupHeaderArea')
  const popupFooterArea = getClientHeight('popupFooterArea')

  return popupHeaderArea.clientHeight + popupFooterArea.clientHeight
}

const ToriiPopup = (props) => {
  const {
    children,
    closeOnOverlayClick = true,
    modalOverrideStyle,
    styles,
    isOpen,
    confirmClose = false,
    width,
    height,
    onCloseAction = () => {},
    showCloseIcon
  } = props

  return (
    <ToriiPopupProvider confirmClose={confirmClose} onCloseAction={onCloseAction}>
      <RenderModals children={children} showCloseIcon={showCloseIcon} closeOnOverlayClick={closeOnOverlayClick} modalOverrideStyle={modalOverrideStyle} styles={styles} isOpen={isOpen} onCloseAction={onCloseAction} width={width} height={height} />
    </ToriiPopupProvider>
  )
}

const ToriiPopupHeader = (props) => {
  const { header, subHeader, children, overrideStyle } = props

  return (
    <div {...css(Style.HeaderContainer, overrideStyle)} id='popupHeaderArea'>
      {header &&
        <div {...Style.Header}>
          {header}
        </div>
      }
      {subHeader &&
        <div {...Style.SubHeader}>
          {subHeader}
        </div>
      }
      {children &&
        <div {...Style.CustomHeader}>
          {children}
        </div>
      }
    </div>
  )
}
ToriiPopup.Header = ToriiPopupHeader

const ToriiPopupContent = (props) => {
  const { children, contentAreaStyle } = props

  const [headerAndFooterHeight, setHeaderAndFooterHeight] = useState(getHeaderAndFooterHeight())

  useLayoutEffect(() => {
    setHeaderAndFooterHeight(getHeaderAndFooterHeight())
  }, [])

  return (
    <section {...css(Style.ContentArea(headerAndFooterHeight), contentAreaStyle)} id='contentArea'>
      {children}
    </section>
  )
}
ToriiPopup.Content = ToriiPopupContent

const ToriiPopupForm = (props) => {
  const { onSubmit, validate, render, renderFooter, contentAreaStyle, mutators, initialValues, validateOnBlur } = props

  const { submitAction, initialValues: initialValuesFromContext } = useContext(ToriiPopupContext)

  return (
    <Form
      onSubmit={onSubmit || submitAction.current}
      validate={validate}
      validateOnBlur={validateOnBlur}
      mutators={mutators}
      initialValues={initialValues || initialValuesFromContext}
      render={(formProps) =>
        <Fragment>
          <section {...css(Style.FormArea(getHeaderAndFooterHeight()), contentAreaStyle)} id='contentArea'>
            {render(formProps)}
          </section>
          {renderFooter && renderFooter(formProps)}
        </Fragment>
      }
    />
  )
}
ToriiPopup.Form = ToriiPopupForm

const ToriiPopupFooter = (props) => {
  const {
    showPageIndex = false,
    currentPage,
    numberOfPages,
    showBackButton = false,
    backButtonAction = () => {},
    backButtonText = 'Back',
    isBackButtonDisabled = false,
    showCancelButton = true,
    cancelButtonText,
    cancelButtonAction,
    showMainButton = true,
    scopes,
    mainButtonText,
    mainButtonAction,
    mainButtonIcon,
    isMainButtonDisabled,
    isMainSubmit = false,
    recoveryTime = 0,
    formProps = {},
    overrideStyle,
    buttonsOverrideStyle,
    mainButtonType = ButtonType.primary,
    showCheckbox,
    checkboxLabel,
    isCheckboxDisabled,
    children,
    mainButtonVisibilityScopes,
    turnOverOutlineButtonWithLinkButton = false,
    allowPristine
  } = props

  const { onClose, scopes: scopesFromContext, mainButtonAction: mainButtonActionFromContext } = useContext(ToriiPopupContext)
  const [checked, setChecked] = useState(true)

  if (!(showPageIndex || showBackButton || showCancelButton || showMainButton || children)) {
    return <footer {...css(Style.EmptyFooter)} id='popupFooterArea' />
  }

  const mainButtonActionMethod = () => {
    if (mainButtonAction) {
      return mainButtonAction(checked)
    }
    if (mainButtonActionFromContext.current) {
      return mainButtonActionFromContext.current()
    }
  }

  const [outlineButtonText, outlineButtonAction, outlineButtonDisabled, linkButtonText, linkButtonAction, linkButtonDisabled] =
  turnOverOutlineButtonWithLinkButton ? [backButtonText, backButtonAction, (currentPage === 1 || isBackButtonDisabled), cancelButtonText, (cancelButtonAction || onClose), false]
    : [cancelButtonText, (cancelButtonAction || onClose), false, backButtonText, backButtonAction, (currentPage === 1 || isBackButtonDisabled)]

  return (
    <footer {...css(Style.Footer, overrideStyle)} id='popupFooterArea'>
      {showBackButton &&
        <div {...Style.BackButtonContainer}>
          <BackLink onClick={linkButtonAction} linkText={linkButtonText} overrideStyle={Style.BackButton} showBackIcon={!turnOverOutlineButtonWithLinkButton} disabled={linkButtonDisabled} />
          {showPageIndex && <div {...Style.PageIndex}>{`${currentPage} of ${numberOfPages}`}</div>}
        </div>
      }
      {showCheckbox &&
        <div {...Style.FooterCheckboxContainer}>
          <Checkbox label={checkboxLabel} disabled={isCheckboxDisabled} onChange={e => setChecked(e.target.checked)} checked={checked} />
        </div>
      }
      <ToriiPopup.Footer.Buttons overrideStyle={buttonsOverrideStyle}>
        {showCancelButton && (<Button onClick={outlineButtonAction} disabled={outlineButtonDisabled} type={ButtonType.secondary} size={ButtonSize.medium} label={outlineButtonText} />)}
        {showMainButton &&
          <VisibleFor scopes={mainButtonVisibilityScopes}>
            <EnableForIfScopes scopes={scopes || scopesFromContext}>
              {isMainSubmit ? (
                <SubmitButton
                  onClick={formProps.handleSubmit}
                  size={ButtonSize.medium}
                  recoveryTime={recoveryTime}
                  form={formProps}
                  label={mainButtonText}
                  icon={mainButtonIcon}
                  allowPristine={allowPristine}
                />
              )
                : (
                  <Button
                    dataTestId='popupMainButton'
                    onClick={() => mainButtonActionMethod()}
                    size={ButtonSize.medium}
                    disabled={isMainButtonDisabled}
                    type={mainButtonType}
                    label={mainButtonText}
                    icon={mainButtonIcon}
                  />
                )
              }
            </EnableForIfScopes>
          </VisibleFor>
        }
        {children}
      </ToriiPopup.Footer.Buttons>
    </footer>
  )
}
ToriiPopup.Footer = ToriiPopupFooter

const ToriiPopupFooterButtons = ({ children, overrideStyle }) => (
  <div {...css(Style.Buttons, overrideStyle)}>
    {children}
  </div>
)
ToriiPopup.Footer.Buttons = ToriiPopupFooterButtons

const ToriiPopupCustomFooter = (props) => {
  return (
    <footer {...css(Style.Footer, props.overrideStyle)} id='popupFooterArea'>
      {props.children}
    </footer>
  )
}
ToriiPopup.CustomFooter = ToriiPopupCustomFooter

const ToriiPopupFooterBackButton = (props) => {
  return (
    <div {...Style.BackButtonContainer}>
      <BackLink onClick={props.backButtonAction} overrideStyle={Style.BackButton} disabled={props.currentPage === 1 || props.isBackButtonDisabled} />
      {props.showPageIndex && <div {...Style.PageIndex}>{`${props.currentPage} of ${props.numberOfPages}`}</div>}
    </div>
  )
}
ToriiPopup.Footer.BackButton = ToriiPopupFooterBackButton

const ToriiPopupFooterCancelButton = ({ label }) => {
  const { onClose } = useContext(ToriiPopupContext)
  return <Button onClick={onClose} type={ButtonType.secondary} size={ButtonSize.medium} label={label} />
}
ToriiPopup.Footer.CancelButton = ToriiPopupFooterCancelButton

const ToriiPopupFooterButton = (props) => {
  const { scopes: scopesFromContext, mainButtonAction: mainButtonActionFromContext } = useContext(ToriiPopupContext)

  const mainButtonActionMethod = () => {
    if (props.mainButtonAction) {
      return props.mainButtonAction()
    }
    if (mainButtonActionFromContext.current) {
      return mainButtonActionFromContext.current()
    }
  }
  const mainButtonType = props.mainButtonType || ButtonType.primary
  return (
    <EnableForIfScopes scopes={props.scopes || scopesFromContext}>
      <Button
        onClick={() => mainButtonActionMethod()}
        size={ButtonSize.medium}
        disabled={props.isMainButtonDisabled}
        type={mainButtonType}
        label={props.mainButtonText}
      />
    </EnableForIfScopes>
  )
}
ToriiPopup.Footer.Button = ToriiPopupFooterButton

const ToriiPopupFooterSubmitButton = (props) => {
  const { scopes: scopesFromContext } = useContext(ToriiPopupContext)
  return (
    <EnableForIfScopes scopes={props.scopes || scopesFromContext}>
      <SubmitButton
        onClick={props.formProps.handleSubmit}
        size={ButtonSize.medium}
        recoveryTime={props.recoveryTime}
        form={props.formProps}
        label={props.label}
      />
    </EnableForIfScopes>
  )
}
ToriiPopup.Footer.SubmitButton = ToriiPopupFooterSubmitButton

ToriiPopup.propTypes = {
  closeOnOverlayClick: PropTypes.bool,
  modalOverrideStyle: PropTypes.object,
  styles: PropTypes.object,
  width: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCloseAction: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  confirmClose: PropTypes.bool,
  children: PropTypes.node
}

ToriiPopup.Header.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node
}

ToriiPopup.Content.propTypes = {
  contentAreaStyle: PropTypes.object
}

ToriiPopup.Form.prototypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  render: PropTypes.func,
  mutators: PropTypes.object,
  initialValues: PropTypes.object
}

ToriiPopup.Footer.propTypes = {
  showPageIndex: PropTypes.bool,
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  showBackButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  isBackButtonDisabled: PropTypes.bool,
  backButtonAction: PropTypes.func,
  showCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  showMainButton: PropTypes.bool,
  scopes: PropTypes.arrayOf(PropTypes.string),
  mainButtonText: PropTypes.string,
  mainButtonAction: PropTypes.func,
  mainButtonIcon: PropTypes.string,
  isMainButtonDisabled: PropTypes.bool,
  isMainSubmit: PropTypes.bool,
  recoveryTime: PropTypes.number,
  formProps: PropTypes.object,
  overrideStyle: PropTypes.object,
  mainButtonType: PropTypes.string,
  buttonsOverrideStyle: PropTypes.object,
  showCheckbox: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
  checkboxLabel: PropTypes.string,
  mainButtonVisibilityScopes: PropTypes.arrayOf(PropTypes.string),
  turnOverOutlineButtonWithLinkButton: PropTypes.bool,
  allowPristine: PropTypes.bool
}

export { ToriiPopupContext } from './popupContext'

export default ToriiPopup
