import { ConditionalBranch } from '@shared/workflows/types'
import { removeItemAtIndex } from '@lenses/utils'

export const removeBranchFilter = (branches: ConditionalBranch[], branchIndex: number, branchFilterIndex: number): ConditionalBranch[] => {
  return branches.map((branch, index) => {
    if (index === branchIndex) {
      const updatedBranchFilters = removeItemAtIndex(branch.filters, branchFilterIndex)
      return { ...branch, filters: updatedBranchFilters }
    }

    return branch
  })
}
