import colors from '@shared/style/colors'
import { css } from 'glamor'
import { fontSize, fontWeight } from '@shared/style/sizes'

export const Main = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '0 0 50px 0'
})

export const RiskLevel = css({
  display: 'flex',
  alignItems: 'center'
})

export const RiskLevelNoActiveUsersText = css({
  color: colors.grey1
})

export const ContractNameButton = css({
  color: `${colors.grey1} !important`,
  backgroundColor: 'transparent',
  ' :hover': {
    color: colors.blue
  }
})

export const NoContractsMessage = css({
  paddingTop: '13px'
})

export const TableTitle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  fontSize: fontSize.large,
  fontWeight: fontWeight.semiBold,
  margin: '0 0 10px 0'
})

export const Contract = css({
  margin: '5px 0 0 0'
})

export const ContractsEndDate = css({
  marginRight: '10px'
})

export const PermissionsTooltip = css({
  width: '245px',
  fontSize: fontSize.small,
  display: 'grid',
  gap: '4px'
})

export const PermissionsTooltipTitle = css({
  fontWeight: fontWeight.semiBold
})

export const PermissionDescription = css({
  color: colors.grey1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
})

export const Button = css({
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal,
  backgroundColor: 'transparent'
})

export const SelectAppsCell = css({
  display: 'flex'
})

export const ActiveContractsCell = css({
  color: colors.grey1,
  height: '100%',
  padding: '10px 0',
  fontSize: fontSize.small,
  overflow: 'hidden'
})

export const ExpensesCell = css({
  display: 'flex',
  ' :hover': {
    cursor: 'pointer'
  }
})

export const ExpensesCellText = css({
  display: 'flex',
  paddingRight: '10px',
  alignItems: 'center'
})

export const NoExpensesText = css({
  color: colors.grey1
})

export const BackButton = css({
  padding: 0,
  marginBottom: '38px'
})

export const IconAndText = css({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '4px'
})
