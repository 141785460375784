import React, { createContext, useCallback, useRef, useState } from 'react'
import { DROP_ZONE_DEFAULT } from '../../uploadExpenses/constants'

export const UploadExpensesPopupContext = createContext()

export const UploadExpensesPopupProvider = ({ children }) => {
  const [state, setState] = useState(DROP_ZONE_DEFAULT)
  const action = useRef(() => {})

  const refreshContext = useCallback(() => {
    setState(DROP_ZONE_DEFAULT)
    action.current = () => {}
  }, [])

  return (
    <UploadExpensesPopupContext.Provider value={{ state, setState, action, refreshContext }}>
      {children}
    </UploadExpensesPopupContext.Provider>
  )
}
