import React from 'react'
import { BarChart, Bar, XAxis, YAxis, LabelList, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import BasicTooltip from '../tooltip'
import CategoriesTooltip from '../categoriesTooltip'
import KeyValueTooltip from '../keyValueTooltip'
import { fontWeight } from '../../../shared/style/sizes'
import Placeholder from '../../placeholder'
import PropTypes from 'prop-types'
import { EMPTY_OBJECT } from '@root/constants'
import { Button, EmptyState, Link } from '@toriihq/design-system'

const CSS = {
  main: css({
    padding: '10px 21px 24px 21px',
    marginLeft: '-30px',
    ' .recharts-surface': {
      overflow: 'visible'
    }
  })
}

export const CONTENT_TOOLTIP_TYPES = {
  CATEGORIES: 'categories',
  KEY_VALUE: 'keyValue',
  BASIC: 'basic'
}

const CustomXAxis = ({ x, y, payload, rotate }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={rotate ? -10 : 0} y={rotate ? 2 : 0} dy={16} textAnchor='middle' fill={colors.grey2} style={{ fontFamily: 'Open Sans' }} transform={`rotate(${rotate ? -35 : 0})`} fontSize={10}>{payload.value}</text>
    </g>
  )
}

const CustomYAxis = ({ x, y, payload, formatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-16} y={0} dy={0} textAnchor='middle' fill={colors.grey2} style={{ fontFamily: 'Open Sans' }} fontSize={10}>{formatter(payload.value)}</text>
    </g>
  )
}

class BarsChart extends React.Component {
  state = { showLabels: this.props.alwaysShowLabels }

  componentDidUpdate (prevProps) {
    if (prevProps.alwaysShowLabels !== this.props.alwaysShowLabels) {
      this.updateShowLabelsState(this.props.alwaysShowLabels)
    }
  }

  formatter = (value) => {
    const { formatter } = this.props
    if (!value) {
      return null
    }

    return formatter(value)
  }

  labelFormatter = (value) => {
    const { labelFormatter } = this.props
    if (!value) {
      return null
    }

    return labelFormatter(value)
  }

  updateShowLabelsState = (show) => {
    this.setState({
      showLabels: show
    })
  }

  getTooltip = () => {
    const { fill, data, formatter, tooltipType, tooltipOverrideStyle, keyValueFormatter } = this.props
    switch (tooltipType) {
      case CONTENT_TOOLTIP_TYPES.CATEGORIES:
        return { ContentTooltip: <CategoriesTooltip hideOnZeroValue formatter={formatter} fill={fill} data={data} />, tooltipSpecialProps: { position: { y: -200 }, ...tooltipOverrideStyle } }
      case CONTENT_TOOLTIP_TYPES.KEY_VALUE:
        return { ContentTooltip: <KeyValueTooltip hideOnZeroValue formatter={formatter} fill={fill} keyValueFormatter={keyValueFormatter} />, tooltipSpecialProps: { position: { y: -100 }, ...tooltipOverrideStyle } }
      default: {
        return { ContentTooltip: <BasicTooltip hideOnZeroValue formatter={formatter} fill={fill} />, tooltipSpecialProps: EMPTY_OBJECT }
      }
    }
  }

  renderChart () {
    const { fill, data, formatter, showLabelsOnHover, barSize, yMaxVal, yNumberOfTicks, chartHeight = 180, overrideStyle, interval, rotateXAxis, labelDataKey, isAnimationActive } = this.props
    const { showLabels } = this.state
    const yExtra = yMaxVal ? { domain: [0, yMaxVal], tickCount: yNumberOfTicks } : {}
    const { ContentTooltip, tooltipSpecialProps } = this.getTooltip()
    return (
      <div {...css(CSS.main, overrideStyle)}>
        <ResponsiveContainer width='100%' height={chartHeight}>
          <BarChart data={data}>
            <CartesianGrid stroke={colors.lightBlue2} vertical={false} />
            <Bar
              isAnimationActive={isAnimationActive}
              onMouseEnter={() => showLabelsOnHover && this.updateShowLabelsState(true)}
              onMouseLeave={() => showLabelsOnHover && this.updateShowLabelsState(false)}
              dataKey='value'
              fill={fill}
              barSize={barSize}
              radius={4}
            >
              {showLabels && <LabelList dataKey={labelDataKey} position='top' fill={colors.text} fontSize={12} style={{ fontWeight: fontWeight.semiBold }} formatter={this.labelFormatter} />}
            </Bar>
            <Tooltip content={ContentTooltip} cursor={false} wrapperStyle={{ zIndex: 1 }} {...tooltipSpecialProps} />
            <XAxis interval={interval} axisLine={false} tickLine={false} dataKey='name' tick={<CustomXAxis rotate={rotateXAxis} />} />
            <YAxis axisLine={false} tickLine={false} tick={<CustomYAxis formatter={formatter} />} {...yExtra} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  renderEmptyState () {
    const { emptyStateInfo } = this.props
    return <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><EmptyState size='small' {...emptyStateInfo} /></div>
  }

  render () {
    const { showEmptyState, loading } = this.props
    return <Placeholder loading={loading} rows={6} style={{ maxWidth: '70%', padding: '20px', height: '100%' }}>
      {showEmptyState ? this.renderEmptyState() : this.renderChart()}
    </Placeholder>
  }
}

const emptyStateInfoType = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  image: PropTypes.instanceOf('img'),
  buttons: PropTypes.arrayOf(PropTypes.instanceOf(Button)),
  link: PropTypes.instanceOf(Link)
})

export const BarsChartProps = {
  loading: PropTypes.bool,
  showEmptyState: PropTypes.bool,
  fill: PropTypes.string.isRequired,
  alwaysShowLabels: PropTypes.bool,
  showLabelsOnHover: PropTypes.bool,
  emptyStateInfo: emptyStateInfoType,
  formatter: PropTypes.func,
  labelFormatter: PropTypes.func,
  barSize: PropTypes.number,
  labelDataKey: PropTypes.string,
  isAnimationActive: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string,
    percentage: PropTypes.number,
    diff: PropTypes.number
  })),
  overrideStyle: PropTypes.object,
  tooltipType: PropTypes.string,
  keyValueFormatter: PropTypes.func
}

BarsChart.propTypes = BarsChartProps

BarsChart.defaultProps = {
  showEmptyState: false,
  alwaysShowLabels: false,
  showLabelsOnHover: false,
  barSize: 25,
  formatter: value => value,
  labelFormatter: value => value,
  interval: 'preserveEnd',
  labelDataKey: 'value',
  isAnimationActive: true,
  tooltipType: CONTENT_TOOLTIP_TYPES.BASIC
}

export default BarsChart
