import { useRef, useEffect } from 'react'

interface Props {
  shouldPoll: boolean
  pollingFunction: () => Promise<void>
  intervalMs?: number
}

export const usePolling = ({
  shouldPoll,
  pollingFunction,
  intervalMs = 5000
}: Props) => {
  const shouldPollRef = useRef(shouldPoll)
  shouldPollRef.current = shouldPoll
  const pollingFunctionRef = useRef(pollingFunction)
  pollingFunctionRef.current = pollingFunction

  useEffect(() => {
    let timeoutId

    const executePolling = async () => {
      try {
        await pollingFunctionRef.current()
      } finally {
        if (shouldPollRef.current) {
          timeoutId = setTimeout(executePolling, intervalMs)
        }
      }
    }

    if (shouldPoll) {
      timeoutId = setTimeout(executePolling, intervalMs)
    }

    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [shouldPoll, intervalMs])
}
