import View from './view'
import { connect } from 'react-redux'
import orderBy from 'lodash/orderBy'
import { getAppDetailsFields, isAppDetailsFieldsLoading } from '@selectors/apps'
import { formFieldTypes, WORKFLOW_ACTION_TYPES, APP_OWNERS_FIELDS } from '@root/constants'
import { getContractsFields } from '@selectors/contracts'

const mapStateToProps = (state, ownProps) => {
  const { action } = ownProps
  const disabledFieldTypes = [formFieldTypes.fileUpload, formFieldTypes.bool]
  const disabledFieldSystemKeys = [APP_OWNERS_FIELDS.appOwners, APP_OWNERS_FIELDS.primary]
  const fields = action.type === WORKFLOW_ACTION_TYPES.SEND_CONTRACT_FORM ? getContractsFields(state) : getAppDetailsFields(state)
  const fieldsOptions = fields
    .filter(field => field.isShown === 1 && !disabledFieldTypes.includes(field.type))
    .filter(field => !field.isReadonly && !field.isReadOnlyGroup)
    .filter(field => !disabledFieldSystemKeys.includes(field.systemKey))
    .map(field => ({ value: field.idField || field.id, label: field.name, formQuestion: field.formQuestion }))
  const sortedFieldsOptions = (fieldsOptions.length > 0 && orderBy(fieldsOptions, 'label')) || []
  return {
    options: sortedFieldsOptions,
    loading: isAppDetailsFieldsLoading(state) && fieldsOptions.length === 0
  }
}

export default connect(mapStateToProps)(View)
