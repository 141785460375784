import React from 'react'
import SelectTimezone from '@components/selectTimezone'
import { TimezoneOption } from '@root/shared/types'
import { ScheduleFormGroup } from './scheduleFormGroup'

interface Props {
    selectedValue?: TimezoneOption | string | null
    onChange?: (value: TimezoneOption) => void
}

export const TimezoneSection = ({
  selectedValue,
  onChange
}: Props): JSX.Element => {
  return (
    <ScheduleFormGroup label='Time zone'>
      <SelectTimezone
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </ScheduleFormGroup>
  )
}
