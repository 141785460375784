import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from '@shared/hooks'

const ScrollToTop = ({ children }) => {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  useEffect(() => {
    if (location.state && location.state.dontScrollToTop) {
      return
    }

    const mainWrapper = document.getElementsByClassName('mainWrapper')
    if (mainWrapper && mainWrapper.length > 0) {
      if (prevLocation && location.pathname === prevLocation.pathname) {
        mainWrapper[0].scrollTo({ behavior: 'smooth', top: 0, left: 0 })
      } else {
        mainWrapper[0].scrollTo(0, 0)
      }
    }
  }, [location, prevLocation])

  return children
}

export default ScrollToTop
