import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { SCOPES } from '@root/constants'
import EnableFor from '../../../enableFor'
import { ButtonSize, ButtonType, Button } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'

class AppOffboardingActionButtons extends React.Component {
  state = { showRemoveConfigurationConfirmation: false, removeConfigurationInfo: {} }

  onConfigureAppClick = (app) => {
    const { actions } = app
    const { toggleConfigureAppForOffboarding, refreshDataFunc, showAlertBox } = this.props

    toggleConfigureAppForOffboarding({
      isOpen: true,
      idApp: app.id,
      idAppAccount: app.idAppAccount,
      appAndAccountName: app.appAndAccountName,
      actions: actions,
      onClose: refreshDataFunc,
      showAlertBox,
      pendingOffboardingAmount: app.pendingOffboardingAmount
    })
  }

  removeConfiguration = async () => {
    const { idOrg, idUsers, deleteWorkflow, getOffboardingApp } = this.props
    const { removeConfigurationInfo } = this.state
    const { idWorkflow, idApp } = removeConfigurationInfo

    await deleteWorkflow({ idOrg, idWorkflow })
    await getOffboardingApp({ idOrg, idUsers, idApp })
    return this.closeConfirmation()
  }

  closeConfirmation = () => {
    this.setState({ showRemoveConfigurationConfirmation: false, removeConfigurationInfo: {} })
  }

  render () {
    const { app, hideDeleteButton, hoverClass } = this.props
    const { showRemoveConfigurationConfirmation, removeConfigurationInfo } = this.state

    const hoverStyle = `.${hoverClass}:hover &`

    CSS.actionsContainer = css({
      display: 'flex',
      alignItems: 'center',
      opacity: 0,
      [hoverStyle]: css({
        opacity: 1
      })
    })

    const removeConfig = () => this.setState({ showRemoveConfigurationConfirmation: true, removeConfigurationInfo: { idWorkflow: app.workflowId, idApp: app.id, appName: app.name } })

    return <div>
      <div {...CSS.actionsContainer} className='actionsContainer'>
        {!hideDeleteButton && <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} allowForToriiAdmin>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={removeConfig} icon='Trash' />
        </EnableFor>}
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} allowForToriiAdmin>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} onClick={() => this.onConfigureAppClick(app)} icon='Edit' />
        </EnableFor>
      </div>

      {showRemoveConfigurationConfirmation && <Confirmation
        isOpen
        header='Delete configuration?'
        text={<div>Are you sure that you want to delete <strong>{removeConfigurationInfo.appName}</strong>'s configuration?</div>}
        confirmText='Delete'
        confirm={this.removeConfiguration}
        declineText='Cancel'
        decline={this.closeConfirmation}
        mainButtonType={ButtonType.destructive}
        close={this.closeConfirmation}
      />}
    </div>
  }
}

AppOffboardingActionButtons.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.number.isRequired,
    idAppAccount: PropTypes.number,
    name: PropTypes.string,
    appAndAccountName: PropTypes.string,
    pendingOffboardingAmount: PropTypes.number,
    workflowId: PropTypes.number,
    actions: PropTypes.array
  }).isRequired,
  hoverClass: PropTypes.string.isRequired,
  hideDeleteButton: PropTypes.bool.isRequired,
  showAlertBox: PropTypes.bool.isRequired,
  idUsers: PropTypes.array
}

AppOffboardingActionButtons.defaultProps = {
  hideDeleteButton: false
}

export default AppOffboardingActionButtons
