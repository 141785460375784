import analytics from '@root/helpers/analytics'

const Analytics = {
  onBackClickAnalytics: (pageName, appName, type) => {
    const props = {
      'Page name': pageName,
      'Application name': appName,
      'Recommendation type': type
    }

    analytics.track('Click on back-to-recommendations', props)
  },
  onViewAllAppRecommendationsClickAnalytics: (fromPage, appName, type) => {
    const props = {
      'Page name': fromPage,
      'Application name': appName,
      'Recommendation type': type
    }
    analytics.track('Click on view-all-app-recommendations', props)
  }
}

export default Analytics
