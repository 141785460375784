import React, { ReactElement } from 'react'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import * as Style from './styles'

interface Props {
  disabled: boolean
  onRemove: (any) => void
  children: ReactElement
}

const VerticalFilterWrapper = ({
  disabled,
  onRemove,
  children: VerticalFilterComponent
}: Props): ReactElement => (
  <Style.Wrapper className={`${Style.VERTICAL_FILTER_CLASS_NAME}`}>
    <Style.FilterControl>
      <Style.FilterTitle>Filter criteria</Style.FilterTitle>
      <Style.DeleteButtonContainer>
        {onRemove && <Button type={ButtonType.compact} size={ButtonSize.small}
          onClick={onRemove}
          icon='Trash'
          disabled={disabled}
          htmlButtonType='button'
        />}
      </Style.DeleteButtonContainer>
    </Style.FilterControl>
    <Style.FilterContainer>
      {VerticalFilterComponent}
    </Style.FilterContainer>
  </Style.Wrapper>
)

export default VerticalFilterWrapper
