import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontSize } from '@shared/style/sizes'
import { isDisabledCss } from '@shared/style/mixins'
import Analytics from '../../../helpers/analytics'
import linkImage from './images/link.svg'
import Spinner from '@media/spinner.svg'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const CSS = {
  main: css({
    width: '100%',
    position: 'relative'
  }),
  loading: css({
    width: '100%',
    textAlign: 'start',
    margin: '10px 0 10px 0'
  }),
  linkContainer: css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    opacity: 1,
    zIndex: 2
  }),
  hide: css({
    zIndex: 1,
    opacity: 0
  }),
  linkInput: css({
    marginTop: '5px',
    fontSize: fontSize.medium,
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%'
  }),
  error: css({
    color: colors.error,
    fontSize: fontSize.small
  }),
  image: css({
    marginRight: '10px',
    transform: 'translateY(-2px)'
  }),
  icon: css({
    color: colors.success
  }),
  linkText: css({
    width: '100%'
  })
}

class ConnectLink extends React.Component {
  state = { showLink: false }

  componentDidUpdate (prevProps) {
    if (prevProps.idApp !== this.props.idApp || prevProps.permission !== this.props.permission) {
      this.setState({ showLink: false })
    }
  }

  showLink = () => {
    const { showLink } = this.state
    return <div {...css(CSS.linkContainer, !showLink && CSS.hide)}>
      <div>Copied to clipboard!</div>
    </div>
  }

  generateLink = () => {
    const { serviceName, error, loading, hasWriteScope, showPermission, permission } = this.props
    const { showLink } = this.state

    const permissionText = !showPermission ? '' : permission === 'read' ? ' (Read)' : ' (Read and take action)'
    const spinner = <div {...CSS.loading}><img alt='Loading...' src={Spinner} width='40px' height='40px' /></div>
    const generateLink = <Fragment>
      <div {...CSS.linkText} >
        <img alt='link' src={linkImage} {...CSS.image} />
        <span>Share a </span>
        <Button type={ButtonType.compact} size={ButtonSize.small} disabled={!hasWriteScope} onClick={hasWriteScope && this.onClick} label='connect-link' />
        <span>{` with ${serviceName} admin${permissionText}.`}</span>
      </div>
      {error && <div {...CSS.error}>Something went wrong, please try again</div>}
    </Fragment>

    return <div {...css(CSS.linkContainer, showLink && CSS.hide)}>
      {loading || showLink ? spinner : generateLink}
    </div>
  }

  onClick = () => {
    const { permission, idApp, idOrg, getConnectIntegrationLink, serviceName, syncType } = this.props
    Analytics.track('Clicked to create shareable link to connect integration', {
      'App id': idApp,
      'App name': serviceName,
      'Permission': permission,
      'Sync type': syncType
    })
    return getConnectIntegrationLink({ idOrg, idApp, permission, syncType })
      .then(({ link }) => {
        const copyElem = document.createElement('textarea')
        document.body.appendChild(copyElem)
        copyElem.value = link
        copyElem.select()
        document.execCommand('copy')
        document.body.removeChild(copyElem)
        this.setState({ link, showLink: true })
        setTimeout(() => this.setState({ showLink: false }), 3 * 1000)
      })
  }

  render () {
    const { overrideStyle, hasWriteScope } = this.props

    return (
      <div {...css(CSS.main, overrideStyle, !hasWriteScope && isDisabledCss)}>
        {this.generateLink()}
        {this.showLink()}
      </div>
    )
  }
}

ConnectLink.propTypes = {
  idApp: PropTypes.number,
  serviceName: PropTypes.string,
  permission: PropTypes.string,
  overrideStyle: PropTypes.object,
  hasWriteScope: PropTypes.bool,
  showPermission: PropTypes.bool,
  syncType: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  idOrg: PropTypes.number,
  getConnectIntegrationLink: PropTypes.func
}

export default ConnectLink
