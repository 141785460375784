import { TransactionModel } from '@components/expensesTable/transactions.types'

export const initialTransaction: TransactionModel = {
  amount: 0,
  concatenatedDescription: '',
  currency: '',
  department: '',
  description: '',
  docId: 0,
  domain: '',
  extraInfo: '',
  fileName: '',
  hash: '',
  id: 0,
  idApp: 0,
  idAppAccount: 0,
  idExternalTransaction: '',
  idOrg: 0,
  idUpload: 0,
  lineKey: 0,
  lineNumber: 0,
  mappingLogic: '',
  mappingReason: '',
  mappingStatus: '',
  modifiedAmount: 0,
  name: '',
  origAmount: 0,
  origCurrency: '',
  origTransactionDate: '',
  origTransactionId: 0,
  reportedBy: 0,
  source: '',
  toriiSource: '',
  transactionDate: ''
}
