import React from 'react'
import ToriiSelect from '@components/select'

export function SelectAppAccount ({
  allAppAccountsLabel, appAccountNames, selectedAppAccountName, onSelected
}: {
  allAppAccountsLabel: string;
  appAccountNames: string[];
  selectedAppAccountName: string;
  onSelected: (name: string) => void;
}) {
  const allSelectableNames = [allAppAccountsLabel, ...appAccountNames]
  const valueKey = 'value'

  const options = allSelectableNames.map(name => ({ [valueKey]: name, label: name }))
  const selectedAppAccountNameOption = options.find(option => option[valueKey] === selectedAppAccountName)

  return <ToriiSelect
    valueKey={valueKey}
    options={options}
    value={selectedAppAccountNameOption}
    hideSelectedOptions
    clearable={false}
    searchable={false}
    onChange={newValue => onSelected((newValue as { [valueKey]: string })[valueKey])}
  />
}
