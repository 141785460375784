import React from 'react'
import { Button, ButtonSize, ButtonType, IconName } from '@toriihq/design-system'
import { FormRenderProps } from 'react-final-form'

type Props = {
  label: string,
  recoveryTime?: number,
  form: FormRenderProps,
  disabled?: boolean,
  size?: typeof ButtonSize[keyof typeof ButtonSize],
  icon?: IconName
  allowPristine?: boolean
}

export const SubmitButton = ({ label, icon, recoveryTime, form: { pristine, valid, handleSubmit }, allowPristine, disabled, size = ButtonSize.small }: Props) : JSX.Element => {
  return <Button
    type={ButtonType.primary}
    disabled={disabled || (pristine && !allowPristine) || !valid}
    size={size}
    onClick={handleSubmit}
    label={label}
    recoveryTime={recoveryTime}
    icon={icon}
  />
}

export default SubmitButton
