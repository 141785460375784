import React, { ReactElement } from 'react'
import { AppIcon, Spacer } from '@toriihq/design-system'
import texts from '@shared/style/texts'
import {
  ContainerButton,
  Description,
  Label,
  OptionDescription
} from './styles'

interface Props {
  label: string
  description?: string
  src?: string
  onChange?: () => void
  disabled?: boolean
}

export const SelectActionButton = ({
  label,
  description,
  disabled,
  src,
  onChange
}: Props): ReactElement => (
  <ContainerButton disabled={disabled} onClick={onChange}>
    {src && (
      <Spacer right={'space-150'}>
        <AppIcon appName={label} appImageUrl={src} />
      </Spacer>
    )}
    <OptionDescription>
      <Label {...texts.heading}>{label}</Label>
      {description && <Description {...texts.subheading}>{description}</Description>}
    </OptionDescription>
  </ContainerButton>
)
