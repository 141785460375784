import React from 'react'
import { Redirect } from 'react-router-dom'

class LogoutPage extends React.Component {
  componentDidMount () {
    const { logout } = this.props
    logout()
  }

  render () {
    const loginURL = {
      search: this.props.location.search,
      pathname: '/login'
    }

    return <Redirect to={loginURL} />
  }
}

export default LogoutPage
