import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import SimilarApp from '../similarApp'
import orderBy from 'lodash/orderBy'
import { Icon } from '@toriihq/design-system'

const CSS = {
  main: css(texts.body, {
    height: '100%'
  }),
  titleWrapper: css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }),
  title: css(texts.subheading, {
    color: colors.darkText
  }),
  apps: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2px'
  })
}

class SimilarApps extends React.Component {
  render () {
    const { loading, similarApps } = this.props
    if (loading || similarApps.length === 0) {
      return null
    }

    if (similarApps.length === 0) {
      return null
    }
    const sortedSimilarApps = orderBy(similarApps, ['desc'])

    return (
      <div {...CSS.main}>
        <div {...CSS.titleWrapper}>
          <Icon name='Applications' color='secondary' />
          <span {...CSS.title}>SIMILAR PRODUCTS</span>
        </div>
        <div {...CSS.apps}>
          {sortedSimilarApps.map((app, i) => (
            <SimilarApp
              key={app.id}
              name={app.name}
              imageUrl={app.imageUrl}
              targetUrl={`/app/${app.id}`}
              last={i === sortedSimilarApps.length - 1}
              disabled={app.disabled}
            />
          ))}
        </div>
      </div>
    )
  }
}

SimilarApps.propTypes = {
  similarApps: PropTypes.array,
  loading: PropTypes.bool
}

SimilarApps.defaultProps = {
  similarApps: []
}

export default SimilarApps
