import styled from 'styled-components'
import RelativeTeamLink from '@root/components/relativeTeamLink'

export const Wrapper = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle02};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  display: flex;
  align-items: center;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.primary}
`

export const Label = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  min-width: 115px;
  text-align: center
`

export const Button = styled(RelativeTeamLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.button.secondaryHover};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  transition: border .2s, opacity .2s;

  &:hover {
    border-color: ${({ theme }) => theme.palette.interaction.hover};
    background-color: ${({ theme }) => theme.palette.interaction.hover};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.palette.interaction.disabled};
    opacity: 0.5;
  }
`
