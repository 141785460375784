import styled from 'styled-components'

export namespace Styles {
  export const LeftSideWrapper = styled.div`
    display: flex;
    flex-flow: column;
  `

  export const HeaderText = styled.span<{ shownOnSubpage?: boolean }>`
    font: ${({ theme, shownOnSubpage }) => shownOnSubpage ? theme.typography.font.header04 : theme.typography.font.header03};
  }
  `

  export const InfoText = styled.div<{ shownOnSubpage?: boolean }>`
    max-width: 428px;
    font: ${({ theme, shownOnSubpage }) => shownOnSubpage ? theme.typography.font.body02 : theme.typography.font.body01};
  `
}
