import React from 'react'
import { Icon, Tooltip, Spacer } from '@toriihq/design-system'

type Props = {
  tooltipText: string,
  maxWidth?: number
}

const InvalidIcon = ({ tooltipText, maxWidth = 250 }: Props) : JSX.Element => (
  <Spacer right={'space-050'}>
    <Tooltip label={tooltipText} maxWidth={maxWidth}>
      <Icon name='Danger' color='error' />
    </Tooltip>
  </Spacer>
)

export default InvalidIcon
