import styled from 'styled-components'

export const EmptyState = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const TasksGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 30px;
`
