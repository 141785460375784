import React, { useEffect, useState } from 'react'
import { parseCron, toCron } from '@shared/cronUtils'
import { Select } from './select'
import { daysOfWeekOptions, hourOptions } from '../consts'
import { DaysAndTimeContainer, SelectTimezoneContainer } from '../styles'
import { TimezoneOption } from '@root/shared/types'
import { TimezoneSection } from './timezoneSection'

interface Props {
  scheduleCron?: string | null,
  timezone?: string | null,
  onScheduleChange: (e: {
    scheduleCron?: string | null,
    scheduleTimezone?: string | null
  }) => void
}

export const CustomScheduleEditor = ({
  scheduleCron,
  timezone,
  onScheduleChange
}: Props): JSX.Element => {
  const [daysOfWeekInput, setDaysOfWeekInput] = useState<string | null>()
  const [hourInput, setHourInput] = useState<string | null>()
  const [timezoneInput, setTimezoneInput] = useState<string | null>()

  useEffect(() => {
    if (scheduleCron) {
      const { dayOfWeek, hour } = parseCron(scheduleCron)
      setDaysOfWeekInput(dayOfWeek)
      setHourInput(hour)
    }
  }, [scheduleCron])

  useEffect(() => {
    if (timezone) {
      setTimezoneInput(timezone)
    }
  }, [timezone])

  const handleDaysOfWeekChange = ({ value }) => {
    const selectedDaysOfWeek = value
    setDaysOfWeekInput(selectedDaysOfWeek)
    updateScheduleFromInputs({ selectedDaysOfWeek })
  }

  const handleHourChange = ({ value }) => {
    const selectedHour = value
    setHourInput(selectedHour)
    updateScheduleFromInputs({ selectedHour })
  }

  const handleTimezoneChange = ({ value }: TimezoneOption) => {
    setTimezoneInput(value)
    updateTimezone({ selectedTimezone: value })
  }

  const updateScheduleFromInputs = ({
    selectedHour = hourInput,
    selectedDaysOfWeek = daysOfWeekInput
  } = {}) => {
    const scheduleCron = selectedHour && selectedDaysOfWeek && toCron({
      minute: '0',
      hour: selectedHour,
      dayOfWeek: selectedDaysOfWeek
    })

    scheduleCron && onScheduleChange({
      scheduleCron
    })
  }

  const updateTimezone = ({
    selectedTimezone = timezoneInput
  } = {}) => {
    selectedTimezone && onScheduleChange({
      scheduleTimezone: selectedTimezone
    })
  }

  return (<>
    <DaysAndTimeContainer>
      <Select
        label='Day(s) of the week'
        options={daysOfWeekOptions}
        value={daysOfWeekInput}
        onChange={handleDaysOfWeekChange}
      />
      <Select
        label='Time of day'
        options={hourOptions}
        value={hourInput}
        onChange={handleHourChange}
      />
    </DaysAndTimeContainer>
    <SelectTimezoneContainer>
      <TimezoneSection
        selectedValue={timezoneInput}
        onChange={handleTimezoneChange}
      />
    </SelectTimezoneContainer>
  </>)
}
