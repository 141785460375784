import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import BackLink from '@components/backLink'
import { APP_PERMISSIONS, AUTH_TYPES, SCOPES, SYNC_TYPE } from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import ConnectLink from '@components/service/connectLink'
import get from 'lodash/get'
import { FORM_ERROR } from 'final-form'
import ConnectSCIM from '@components/service/connectSCIM'
import * as Style from './style'
import Analytics from '@helpers/analytics'
import TestConnectionAnalytics from '@components/testConnection/analytics'
import { CONNECT_STAGES, TEST_PHASES, TEST_RESULTS } from '@components/testConnection/constants'
import TestConnection from '@components/testConnection'
import ReactDOM from 'react-dom'
import { calculateFinalResults } from '@components/testConnection/utils'
import { Button, ButtonSize, Link } from '@toriihq/design-system'

const formError = { [FORM_ERROR]: 'We could not connect to the service and got this error: "The user can not access this resource.". Make sure the details you provided are correct.' }
const DELAY = 2000
const connectionInterface = 'Connect in app'

const ConnectSCIMIntegrationPopup = (props) => {
  const [permission, setPermission] = useState(props.permission)
  const [stage, setStage] = useState(CONNECT_STAGES.CONNECT_FORM)
  const [inputsData, setInputsData] = useState(null)
  const [results, setResults] = useState(null)
  const [internalError, setInternalError] = useState(false)
  const [finalResults, setFinalResults] = useState(null)
  const [idTestConnection, setIdTestConnection] = useState(null)

  const { cancel, connectSCIMService, service, serviceName, capabilityList, disableReadOption } = props
  const { appName, idApp, scimConfig = {}, supportsWritePermission } = service
  const { isReconnect, showReconnectAlertInfo } = connectSCIMService
  const header = `${isReconnect ? 'Reconnect' : 'Connect'} ${appName}`

  useEffect(() => {
    if (props.permission) {
      setPermission(props.permission)
    }
  }, [props.permission])

  const onPermissionChanged = (value) => {
    setPermission(value)
  }

  const onConnect = async () => {
    const { createSourceTokenAfterTestConnection, close, getServicesSyncData, idOrg, getSyncStatus, service, callback } = props
    if (isReconnect) {
      Analytics.track(`Click on reconnect button`, {
        'App name': appName
      })
    }
    createSourceTokenAfterTestConnection({ idTestConnection, idApp: service.idApp, source: service.source, formData: { token: JSON.stringify(inputsData), permission } })
      .then(async (res) => {
        const error = get(res, 'response.error')
        if (error) {
          return formError
        }
        TestConnectionAnalytics.clickOnConnectAfterTestIntegration(finalResults, serviceName, idApp, connectionInterface)
        getSyncStatus && getServicesSyncData({ idOrg })
        resetState({ resetInputData: true })
        close && close()
        callback && callback()
      })
      .catch(e => formError)
  }

  const onSubmitWithoutTestConnection = async (values) => {
    const {
      getSyncStatus,
      callback,
      close,
      source,
      getServicesSyncData,
      idOrg,
      idApp,
      serviceName,
      isReconnect,
      createSourceToken
    } = props

    if (isReconnect) {
      Analytics.track(`Click on reconnect button`, {
        'App name': serviceName
      })
    }

    const onConnectSuccess = () => {
      const SUCCESS_DELAY = 1000
      setTimeout(async () => {
        getSyncStatus && await getServicesSyncData({ idOrg })
        close()
        callback && callback()
      },
      SUCCESS_DELAY)
    }

    return createSourceToken({ idApp, source, formData: { token: JSON.stringify(values), permission } })
      .then(onConnectSuccess).catch(e => {
        window.Sentry && window.Sentry.captureException(e)
        return { [FORM_ERROR]: '* Please check your entries and try again' }
      })
  }

  const onSubmit = async (values) => {
    const { hasTestConnection = true } = props

    if (hasTestConnection) {
      return onSubmitWithTestConnection(values)
    } else {
      return onSubmitWithoutTestConnection(values)
    }
  }

  const onSubmitWithTestConnection = async (values) => {
    const { idOrg, service, testConnection, createTestConnectionEntry } = props
    TestConnectionAnalytics.clickTestIntegration(serviceName, idApp, connectionInterface)

    setStage(CONNECT_STAGES.IN_PROGRESS)
    let idTestConnection

    try {
      const { id } = await createTestConnectionEntry({ data: { phase: TEST_PHASES.IN_PROGRESS, integrationType: INTEGRATION_TYPE.SCIM, idApp }, idOrg })
      idTestConnection = id

      const finalValues = { auth: values.auth, baseUrl: values.baseUrl }

      if (values.auth === AUTH_TYPES.BASIC) {
        finalValues.username = values.username
        finalValues.password = values.password
      } else {
        finalValues.token = values.token
      }

      const results = await testConnection({ idTestConnection: parseInt(idTestConnection), idApp: service.idApp, idOrg, appToken: { token: JSON.stringify(finalValues), permission, source: service.source } })
      const finalResults = calculateFinalResults(results)

      setTimeout(async () => {
        ReactDOM.unstable_batchedUpdates(() => {
          setResults(results)
          setFinalResults(finalResults)
          setStage(CONNECT_STAGES.RESULTS)
          setInputsData(values)
          setIdTestConnection(parseInt(idTestConnection))
        })
      }, DELAY)
    } catch (e) {
      await onInternalError({ e })
    }
  }

  const onInternalError = async ({ e }) => {
    console.error('Internal error occurred during test connection:', e)
    setInternalError(true)
  }

  const onBackClick = () => {
    TestConnectionAnalytics.backClick(finalResults, serviceName, idApp, connectionInterface)
    resetState({ resetInputData: false })
  }

  const connectFormPopup = () => {
    const initialValues = { ...scimConfig, ...inputsData }
    return (
      <>
        <ToriiPopup.Header header={header} subHeader={scimConfig.link ? <Link href={scimConfig.link} target='_blank'>How to set up {appName} integration</Link> : null} />
        <ToriiPopup.Form
          contentAreaStyle={Style.MainArea}
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={(formProps) => {
            return <ConnectSCIM
              formProps={formProps}
              scimConfig={scimConfig}
              showReconnectAlertInfo={showReconnectAlertInfo}
              onPermissionChange={onPermissionChanged}
              permission={permission}
              supportsWritePermission={supportsWritePermission}
              disableReadOption={disableReadOption}
            />
          }}
          renderFooter={(formProps) => {
            formProps.pristine = inputsData ? false : formProps.pristine
            return <ToriiPopup.Footer
              showCancelButton={false}
              mainButtonText={props.hasTestConnection ? 'Continue' : connectSCIMService.isReconnect ? 'Reconnect' : 'Connect'}
              isMainSubmit
              recoveryTime={2000}
              formProps={formProps}
              scopes={[SCOPES.INTEGRATIONS_WRITE]}
              buttonsOverrideStyle={Style.footer}
            >
              <ConnectLink idApp={idApp} serviceName={appName} permission={APP_PERMISSIONS.read} syncType={SYNC_TYPE.API} overrideStyle={Style.connectLink} />
            </ToriiPopup.Footer>
          }}
        />
      </>
    )
  }

  const inProgressPopup = () => {
    return (
      <TestConnection>
        <ToriiPopup.Header header={`Test ${serviceName} Connection`} />
        {!internalError ? <TestConnection.InProgressBanner /> : <TestConnection.InternalErrorBanner />}
        <TestConnection.UnprocessedContent capabilityList={capabilityList} />
        <ToriiPopup.CustomFooter>
          <ToriiPopup.Footer.Buttons>
            <Button disabled size={ButtonSize.medium} label={isReconnect ? 'Reconnect' : 'Connect'} />
          </ToriiPopup.Footer.Buttons>
        </ToriiPopup.CustomFooter>
      </TestConnection>
    )
  }

  const resultsPopup = () => {
    return (
      <TestConnection>
        <ToriiPopup.Header header={`Test ${serviceName} Connection`} />
        <TestConnection.TestResultsBanner finalResults={finalResults} />
        <TestConnection.TestResultsContent finalResults={finalResults} results={results} capabilityList={capabilityList} />
        <ToriiPopup.CustomFooter>
          <BackLink onClick={onBackClick}>Back</BackLink>
          <Button size={ButtonSize.medium} disabled={finalResults.result === TEST_RESULTS.FAILED} onClick={onConnect} label={isReconnect ? 'Reconnect' : 'Connect'} />
        </ToriiPopup.CustomFooter>
      </TestConnection>
    )
  }
  const getPopupContent = () => {
    switch (stage) {
      case CONNECT_STAGES.CONNECT_FORM:
        return connectFormPopup()
      case CONNECT_STAGES.IN_PROGRESS:
        return inProgressPopup()
      case CONNECT_STAGES.RESULTS:
        return resultsPopup()
      default:
        return null
    }
  }

  const resetState = ({ resetInputData = true }) => {
    ReactDOM.unstable_batchedUpdates(() => {
      resetInputData && setInputsData(null)
      setResults(null)
      setStage(CONNECT_STAGES.CONNECT_FORM)
      setInternalError(false)
    })
  }

  const onClose = () => {
    TestConnectionAnalytics.toggleIntegrationConnection(false, serviceName, idApp, 'Test before connect', connectionInterface)
    if (stage === CONNECT_STAGES.RESULTS) {
      TestConnectionAnalytics.closeAfterSeeingTestResults(finalResults, serviceName, idApp, connectionInterface)
    }
    resetState({ resetInputData: true })
    cancel()
  }

  return <ToriiPopup isOpen={connectSCIMService.isOpen} onCloseAction={onClose} styles={{ modal: { width: '645px' } }}>
    {getPopupContent()}
  </ToriiPopup>
}

ConnectSCIMIntegrationPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  permission: PropTypes.string,
  disableReadOption: PropTypes.bool,
  callback: PropTypes.func
}

export default ConnectSCIMIntegrationPopup
