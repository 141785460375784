import config from '../config'
import keyBy from 'lodash/keyBy'
import toArray from 'lodash/toArray'
import get from 'lodash/get'
import escapeStringRegexp from 'escape-string-regexp'
import { findAll as FindTermInText } from 'highlight-words-core'
import moment from 'moment'
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from '@root/constants'

export const isCatalog = path => path && path.startsWith(config.catalogBaseUrl)
export const isExtensionLogin = path => path && path.includes(`${config.appBaseUrl}/team/extensionLogin`)
export const isWebapp = path => path && path.startsWith(config.appBaseUrl) && !isExtensionLogin(path)

export const mergeArraysByKey = (prev = [], next = [], key) => {
  if (prev.length === 0) {
    return next
  }

  if (next.length === 0) {
    return prev
  }

  const prevByKey = keyBy(prev, key)
  next.forEach(item => {
    const prevItem = prevByKey[item[key]]
    prevByKey[item[key]] = prevItem ? { ...prevItem, ...item } : item
  })
  return toArray(prevByKey)
}

export const keepMultipleSpaces = (string) => (string || '').replace(/ /g, '\u00a0')

export const highlightTermInText = (terms, textToSearch) => {
  const escapedTerms = terms.map(term => escapeStringRegexp(term))

  const chunks = FindTermInText({
    caseSensitive: false,
    searchWords: escapedTerms,
    textToHighlight: textToSearch
  })

  const highlitedText = chunks
    .map(chunk => {
      const { end, highlight, start } = chunk
      const text = textToSearch.substring(start, end)
      if (highlight) {
        return `<b>${keepMultipleSpaces(text)}</b>`
      } else {
        return keepMultipleSpaces(text)
      }
    }).join('')
  return highlitedText
}
export const replaceOneItem = (itemsList, newItem, keyToCompare) => {
  const clonedItems = itemsList.slice()
  const itemIndex = itemsList.findIndex(oldItem => oldItem[keyToCompare] === newItem[keyToCompare])
  if (itemIndex === -1) {
    clonedItems.push(newItem)
  } else {
    clonedItems[itemIndex] = newItem
  }
  return clonedItems
}

export const updateOneItem = (itemsList = [], keyToCompare, valueToCompare, data = {}) => {
  return itemsList.map(item => {
    if (item[keyToCompare] !== valueToCompare) {
      return item
    }

    return { ...item, ...data }
  })
}

export const fieldsValuesToOptions = fieldsValues => {
  const mappedFieldsValues = {}
  Object.keys(fieldsValues).forEach(field => {
    mappedFieldsValues[field] = fieldsValues[field].map(item => ({
      value: item.key,
      label: get(item, ['key', 'fullName'], item.key)
    }))
  })
  return mappedFieldsValues
}

export const customJoin = (arr, separator = ', ', lastSeparator = ' and ') => {
  return arr.slice(0, -1).join(separator).concat(arr.length > 1 ? lastSeparator : '', arr.slice(-1))
}

export const sortAlphabeticallyCompareFunction = (a, b) => a.localeCompare(b, 'en')

export const getFormattedDate = ({ date, emptyValueDefaultDisplay = '-', includeTime = false }) => (date && moment.utc(date).format(includeTime ? DATE_FORMAT_WITH_TIME : DATE_FORMAT)) || emptyValueDefaultDisplay

export const removeItemAtIndex = (array, index) => {
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ]
}

export const replaceItemAtIndex = (array, item, index) => {
  return Object.assign([...array], { [index]: item })
}

export const isSupportsUsage = ({ capabilities, supportedFeatures = {} }) => {
  const isUsageEnabled = get(capabilities, ['usage', 'enabled'], false)
  if (!isUsageEnabled) {
    return false
  }

  const serviceUsageFeatureFlag = get(capabilities, ['usage', 'featureFlag'])
  return serviceUsageFeatureFlag ? Boolean(supportedFeatures[serviceUsageFeatureFlag]) : true
}

export const JSONTryParse = (string) => {
  try {
    return JSON.parse(string)
  } catch (e) {
    return string
  }
}
