function escapeIfDangerous<T> (value: T): T | string {
  if (typeof value === 'string' && value.length > 1 && /^[=+\-@]/.test(value)) {
    return `'${value}`
  }
  return value
}

export const toSafeData = (data: object[]): object[] => {
  return data.map(item => {
    return Object
      .entries(item)
      .reduce((acc, [key, value]) => {
        acc[key] = escapeIfDangerous(value)
        return acc
      }, {})
  })
}
