import styled from 'styled-components'
import { Col } from '@toriihq/design-system'

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const ColWithMargin = styled(Col)`
  margin-bottom: ${({ theme }) => theme.spacing('space-400')}
`
