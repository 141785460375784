import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import SelectUsers from '@components/selectUsers'
import Placeholder from '@components/placeholder'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2/popupContext'
import EnableFor from '../enableFor'
import { SCOPES } from '@root/constants'

const FORM_PADDING = '40px'
const CSS = {
  header: css(texts.headers.regular, {
    borderBottom: `1px solid ${colors.border}`,
    flex: '0 0 155px',
    padding: `33px ${FORM_PADDING} 0`
  }),
  form: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),
  assignButton: css({
    float: 'right'
  }),
  footer: css({
    flex: '0 0 80px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: `1px solid ${colors.border}`,
    backgroundColor: colors.white,
    padding: `0 ${FORM_PADDING}`
  }),
  multiUserSelect: css({
    width: '100%',
    ' .Select-control': {
      border: 'none !important'
    }
  })

}

class AssignUser extends React.Component {
  componentDidMount () {
    this.fetchAppData()
    this.context.submitAction.current = this.onSubmit
  }

  componentDidUpdate (prevProps) {
    const { idApp, idOrg } = this.props

    const appChanged = (idApp !== prevProps.idApp)
    const orgChanged = (idOrg !== prevProps.idOrg)
    if (orgChanged || appChanged) {
      this.fetchAppData()
    }
  }

  fetchAppData () {
    const { getSuggestedOwners, idOrg, idApp } = this.props
    if (!idOrg || !idApp) {
      return
    }

    getSuggestedOwners({ idOrg, idApp })
  }

  onSubmit = async ({ selectedUser }) => {
    const { onSelectUser, idApp, idOrg, suggestedOwners, component = 'Button', assignUserCB, onSuccess, isMultipleSelect } = this.props
    const isSuggestedOwner = Boolean(suggestedOwners[selectedUser.id])
    if (isMultipleSelect) {
      await onSelectUser({ idApp, idOrg, isSuggestedOwner, component, users: selectedUser })
    } else {
      await onSelectUser({ idApp, idOrg, isSuggestedOwner, component, ...selectedUser })
    }

    assignUserCB && await assignUserCB({ idApp, idOrg })
    onSuccess()
  }

  renderSearch = (props) => {
    const { idApp, suggestedOwners, loading, isMultipleSelect } = this.props
    return (
      <Placeholder loading={loading} type='rect' style={{ width: '100%', height: '30px', maxWidth: '100%' }}>
        <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_READ, SCOPES.APP_OWNER_READ]}>
          <SelectUsers
            className={css({ width: '100%' })}
            placeholder='Search users'
            specialUsers={suggestedOwners}
            autoFocus
            openOnFocus
            idAppForUsingAppBadge={idApp}
            excludePastUsers
            multi={isMultipleSelect}
            {...props.input}
          />
        </EnableFor>
      </Placeholder>
    )
  }

  render () {
    const { handleSubmit, submitting } = this.props
    this.submitting = submitting
    return (
      <form {...CSS.form} onSubmit={handleSubmit}>
        <Field
          name='selectedUser'
          label='Search user'
          component={this.renderSearch} />
      </form>
    )
  }
}

export const validate = ({ selectedUser }) => {
  const errors = {}
  const users = [].concat(selectedUser)
  users.forEach(user => {
    if (!user || !user.email) {
      errors.selectedUser = 'Required'
      return errors
    }
  })
}

AssignUser.contextType = ToriiPopupContext

AssignUser.propTypes = {
  onSelectUser: PropTypes.func,
  isMultipleSelect: PropTypes.bool
}

export default AssignUser
