import { css } from 'glamor'

export const Popup = {
  modal: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column'
  }
}

export const MainArea = css({
  height: 90,
  overflowY: 'unset',
  width: '100%'
})
