import { useEffect, useRef, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { DebounceCallbackOptions } from './useDebouncedCallback.types'

export const useDebouncedCallback = <T extends (...args: any[]) => any> (
  callback: T,
  delay: number,
  options: DebounceCallbackOptions = { cancelOnDestroy: true }
): DebouncedFunc<T> => {
  const callbackRef = useRef(callback)

  const debouncedCallback = useMemo(() => {
    return debounce((...args: any[]) => callbackRef.current?.(...args) as T, delay)
  }, [delay])

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    return () => {
      if (options.cancelOnDestroy) {
        debouncedCallback.cancel()
      }
    }
  }, [debouncedCallback, options.cancelOnDestroy])

  return debouncedCallback
}
