import React, { useState } from 'react'
import Analytics from '@helpers/analytics'
import { useHistory } from 'react-router-dom'
import { getWorkflowsPathPrefix } from '@lenses/workflows'
import Confirmation from '@components/confirmation'
import { ButtonType } from '@toriihq/design-system'

const CANCEL_BUTTON_LABEL = {
  X: 'x',
  CANCEL: 'CANCEL'
}

const DELETE_EVENTS = {
  OPEN: 'Open workflow-deletion-confirmation-dialog',
  DELETE: 'Delete workflow-deletion-confirmation-dialog',
  CLOSE: 'Close workflow-deletion-confirmation-dialog'
}

const useDeleteLogic = ({
  idOrg,
  deleteWorkflow,
  getUpdatedWorkflowsList,
  referrer,
  getConfirmationHeader = (workflowName) => `Delete '${workflowName}' workflow?`,
  confirmationText = `By clicking "Delete", all data will be lost.`
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [idWorkflow, setIdWorkflow] = useState()
  const [workflowName, setWorkflowName] = useState()
  const [workflowType, setWorkflowType] = useState()
  const [workflowTriggerType, setWorkflowTriggerType] = useState()

  const history = useHistory()

  const toggleIsDeleteOpen = ({ id = idWorkflow, name = workflowName, type = workflowType, triggerType = workflowTriggerType, analyticsEvent, customAnalyticsInfo = {} }) => {
    const newIsDeleteOpen = !isDeleteOpen
    const analyticsInfo = {
      'Workflow ID': id,
      'Workflow name': name,
      ...customAnalyticsInfo
    }

    Analytics.track(analyticsEvent, analyticsInfo)

    setIsDeleteOpen(newIsDeleteOpen)
    setIdWorkflow(id)
    setWorkflowName(name)
    setWorkflowType(type)
    setWorkflowTriggerType(triggerType)
  }

  const onClose = (buttonLabel) => toggleIsDeleteOpen({
    analyticsEvent: DELETE_EVENTS.CLOSE,
    customAnalyticsInfo: {
      'Button label': buttonLabel
    }
  })
  const onOpen = ({ idWorkflow, workflowName, workflowType, workflowTriggerType }) => {
    toggleIsDeleteOpen({
      analyticsEvent: DELETE_EVENTS.OPEN,
      id: idWorkflow,
      name: workflowName,
      type: workflowType,
      triggerType: workflowTriggerType,
      customAnalyticsInfo: {
        'Button location': referrer
      }
    })
  }
  const onDelete = async () => {
    try {
      await deleteWorkflow({ idOrg, idWorkflow, type: workflowType, triggerType: workflowTriggerType })
    } finally {
      await getUpdatedWorkflowsList()
      history.push(`/team/${idOrg}/${getWorkflowsPathPrefix({ workflowType, triggerType: workflowTriggerType })}`)
    }
    toggleIsDeleteOpen({ analyticsEvent: DELETE_EVENTS.DELETE })
  }

  const deleteConfirmation = isDeleteOpen && <Confirmation
    isOpen={isDeleteOpen}
    header={getConfirmationHeader(workflowName)}
    text={confirmationText}
    confirmText='Delete'
    declineText='Cancel'
    decline={() => onClose(CANCEL_BUTTON_LABEL.CANCEL)}
    confirm={onDelete}
    close={() => onClose(CANCEL_BUTTON_LABEL.X)}
    mainButtonType={ButtonType.destructive}
  />

  return {
    onOpen,
    deleteConfirmation
  }
}

export default useDeleteLogic
