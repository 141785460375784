import { fontSize, fontWeight } from '@root/shared/style/sizes'
import colors from '@shared/style/colors'
import { css } from 'glamor'

export const Container = css({
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '50px'
})

export const HeaderContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
})

export const Header = css({
  color: colors.black,
  fontSize: fontSize.mediumLarge,
  fontWeight: fontWeight.semiBold
})

export const SubHeader = css({
  display: 'flex'
})

export const SubHeaderText = css({
  color: colors.text,
  fontSize: fontSize.medium,
  fontWeight: fontWeight.normal,
  marginRight: 0,
  '.a:link, a:visited': {
    color: `${colors.blue}`,
    textDecoration: 'none'
  }
})

export const UsageExplanation = css({
  padding: 0
})
