import { Reducer } from 'redux'
import { CalendarsState, CalendarsAction } from './types'

const initialState: CalendarsState = {
  loading: false,
  calendars: []
}

const calendarsReducer: Reducer<CalendarsState, CalendarsAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CALENDARS_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'GET_CALENDARS_FAILED': {
      return {
        ...state,
        loading: false
      }
    }
    case 'GET_CALENDARS_RESPONSE': {
      const { calendars } = action.payload
      return {
        ...state,
        loading: false,
        calendars
      }
    }
    default: {
      return state
    }
  }
}

export default calendarsReducer
