import React, { ComponentType } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { Styles } from '@pages/pluginMarketplace/components/pluginPage/style'
import FormField from '@components/workflows/formField'
import { get } from 'lodash'
import Markdown from 'markdown-to-jsx'
import { ButtonType } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'

const InstallPopup = ({ plugin, setInstallPopUpOpen, onSubmit }) => {
  const renderInstallDescription = () => {
    const description = get(plugin, ['config', 'consent', 'description'])
    return description
      ? (
        <>
          <Markdown
            children={description || ''}
            options={{
              wrapper: 'article',
              overrides: {
                a: (props) => <Styles.Link {...props} target={'_blank'} rel={'noreferrer noopener'} />
              }
            }}
          />
          <hr />
        </>
      )
      : null
  }

  const validate = (fields) => {
    const errors: { value?: string } = {}

    plugin.config.consent.fields.forEach(field => {
      if (!fields[field.name]) {
        errors[field.name] = 'Required'
      }
    })

    return errors
  }

  if (!plugin.config?.consent) {
    return <Confirmation
      isOpen
      header={`Install ${plugin.name} plugin`}
      text={(
        <div>
          You are about to install the <strong>{plugin.name}</strong> plugin.
        </div>
      )}
      confirmText='Install'
      confirm={() => onSubmit({})}
      declineText='Cancel'
      decline={() => setInstallPopUpOpen(false)}
      mainButtonType={ButtonType.primary}
      close={() => setInstallPopUpOpen(false)}
    />
  }
  return <ToriiPopup isOpen onCloseAction={() => setInstallPopUpOpen(false)}>
    <ToriiPopup.Header header={`Install ${plugin.name}`} />
    <ToriiPopup.Form
      onSubmit={onSubmit}
      validate={validate}
      render={() => {
        return (<div>
          {renderInstallDescription()}
          {(plugin.config.consent.fields ?? []).map((field) => {
            const typeMapping = FormField.mapping[field.type] || {}
            const validate = typeMapping.validators
            return (
              <div className='fs-exclude' key={field.name}>
                <Field
                  addFieldIndex={false}
                  validate={validate}
                  name={field.name}
                  field={field}
                  label={field.label}
                  component={FormField as unknown as ComponentType<FieldRenderProps<any, HTMLElement>>}
                  overrideLabel={field.label}
                  format={typeMapping.format}
                  parse={typeMapping.parse}
                />
              </div>
            )
          })
          }
        </div>
        )
      }}
      renderFooter={(formProps) => (
        <ToriiPopup.Footer
          mainButtonText={'Install'}
          cancelButtonText={'Cancel'}
          isMainSubmit
          recoveryTime={1000}
          formProps={formProps}
        />
      )}
    />
  </ToriiPopup>
}

export default InstallPopup
