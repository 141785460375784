import {
  BranchNodeModel,
  ConditionalBranch,
  WorkflowNodes
} from '@shared/workflows/types'
import { cloneDeep, omit } from 'lodash'
import { removeItemAtIndex } from '@lenses/utils'
import { getNodeDescendants } from '@shared/workflows/actions/utils/getNodeDescendants'
import { getBranchActionBranchesData } from '@shared/workflows/actions/utils/getBranchActionBranchesData'

const replaceBranchesAtNode = (
  branchNode: BranchNodeModel,
  newBranches: ConditionalBranch[]
): BranchNodeModel => {
  const [branchAction] = branchNode.action.fields
  branchAction.value.branches = newBranches
  return branchNode
}

interface RemoveBranchAndDescendantsReturn {
  newNodes: WorkflowNodes | undefined
  removedIdNodes: string[]
}

export const removeBranchAndDescendants = ({
  idNode,
  branchIndexToDelete,
  nodes
}: {
  idNode: string
  branchIndexToDelete: number
  nodes: WorkflowNodes
}): RemoveBranchAndDescendantsReturn => {
  const branchActionNode = nodes[idNode] as BranchNodeModel
  if (!branchActionNode) {
    return { newNodes: undefined, removedIdNodes: [] }
  }
  const { branches } = getBranchActionBranchesData(branchActionNode)
  const branch = branches[branchIndexToDelete]

  if (!branch) {
    return { newNodes: undefined, removedIdNodes: [] }
  }

  const idNodesToRemove = getNodeDescendants(branch.next, nodes)
  const newNodes = omit(nodes, idNodesToRemove)

  const updatedBranches = removeItemAtIndex(branches, branchIndexToDelete)
  const newBranchNodeCopy = cloneDeep(newNodes[idNode])
  newNodes[idNode] = replaceBranchesAtNode(newBranchNodeCopy, updatedBranches)

  return { newNodes, removedIdNodes: idNodesToRemove }
}
