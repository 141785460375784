import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sendRequest } from '@pages/signup/actions'

const mapStateToProps = (state, ownProps) => {
  const { step } = ownProps.match.params
  const { user, org, employeeIdRole, adminIdRole } = state.emailValidation

  return {
    signupError: state.signup.error,
    currentPage: step - 1,
    idRole: user ? user.idRole : (org ? employeeIdRole : adminIdRole),
    adminIdRole,
    org
  }
}

const mapDispatchToProps = {
  sendRequest
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
