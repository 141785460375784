import React, { useMemo } from 'react'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import { useSelector } from 'react-redux'
import * as Style from './style'
import Placeholder from '@components/placeholder'
import RelativeTeamLink from '@components/relativeTeamLink'
import Analytics from '@helpers/analytics'
import { AppIcon } from '@toriihq/design-system'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import OverflowTooltip from '@components/overflowTooltip'

export const AppInfo = (props) => {
  const { idApp, infoLine, loading = false, component, color, overrideStyle, isBig = true, tooltipObj } = props
  const apps = useSelector(deprecatedGetAppsByIds)

  const { name, imageUrl } = useMemo(() => {
    if (!apps) {
      return { name: props.name, imageUrl: props.imageUrl }
    }
    const app = apps[idApp]
    return { name: props.name || app.name, imageUrl: props.imageUrl || app.imageUrl }
  }, [apps, idApp, props.name, props.imageUrl])

  const onNavToApp = (name, component) => {
    Analytics.track('Click on app', {
      Component: component,
      'App name': name
    })
  }

  const overflowTooltipContent = <span {...Style.OverflowTooltipContainer}>{name}{tooltipObj}</span>
  return (
    <div className='appInfoContainer' {...css(overrideStyle)}>
      <Placeholder loading={loading} type='text' rows={2} style={Style.Placeholder(isBig)}>
        <div {...Style.Main}>
          <div {...Style.ColorBar(isBig, color)} />
          <RelativeTeamLink to={`/app/${idApp}`} onClick={() => onNavToApp(name, component)}>
            <AppIcon appName={name} appImageUrl={imageUrl} />
          </RelativeTeamLink>
          <div {...Style.DetailsContainer} className='detailsContainer'>
            <OverflowTooltip label={overflowTooltipContent}>
              {overflowTooltipContent}
            </OverflowTooltip>
            {infoLine && <div className='infoLine' {...Style.InfoLine}>{infoLine}</div>}
          </div>
        </div>
      </Placeholder>
    </div>
  )
}

AppInfo.propTypes = {
  idApp: PropTypes.number.isRequired,
  infoLine: PropTypes.any,
  loading: PropTypes.bool,
  component: PropTypes.string,
  color: PropTypes.string,
  overrideStyle: PropTypes.object,
  isBig: PropTypes.bool,
  enableOverflow: PropTypes.bool
}

export default AppInfo
