import React from 'react'
import AppDetails from '@components/appDetails'
import { WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import { getDisplayName } from '@lenses/users'
import UserDetails from '@components/userDetails'
import { getFormattedDate } from '@lenses/utils'

const AppDetailsCell = (component) => ({ row: { idApp, idUser, idLicense, idAppAccount, appName, imageUrl } }) => (
  <AppDetails
    id={`${idApp}-${idAppAccount}-${idUser}-${idLicense}`}
    name={appName}
    imageUrl={imageUrl}
    component={component}
    blueNameOnHover={false}
  />
)

const UserDetailsCell = ({ row: { firstName, lastName, idUser, email, photoUrl, idAppAccount, idLicense } }) => (
  <UserDetails
    id={`${idAppAccount}-${idUser}-${idLicense}`}
    firstName={firstName}
    lastName={lastName}
    email={email}
    idUser={idUser}
    photoUrl={photoUrl}
    linkToUserPage
    blueNameOnHover={false}
  />
)

const columnsByTriggerType = {
  [WORKFLOW_TRIGGER_TYPES.USER_MEETS_CRITERIA]: [
    {
      Header: '',
      id: 'name',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 220,
      maxWidth: 350,
      Cell: UserDetailsCell,
      textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email }),
      sortable: false
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    }
  ],
  [WORKFLOW_TRIGGER_TYPES.CONTRACT_MEETS_CRITERIA]: [
    {
      Header: '',
      id: 'name',
      accessor: 'name',
      minWidth: 220,
      maxWidth: 350,
      sortable: false
    }
  ],
  [WORKFLOW_TRIGGER_TYPES.APPLICATION_MEETS_CRITERIA]: [
    {
      Header: '',
      id: 'name',
      accessor: 'name',
      Cell: ({ row: { name, imageUrl, category } }) => (
        <AppDetails
          name={name}
          category={category}
          imageUrl={imageUrl}
          component='AMC preview popup'
          blueNameOnHover={false}
        />
      ),
      minWidth: 220,
      maxWidth: 350,
      sortable: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'category',
      show: false
    }
  ],
  [WORKFLOW_TRIGGER_TYPES.USER_STOP_USING_LICENSE]: [
    {
      Header: 'License',
      id: 'licenseName',
      accessor: 'licenseName',
      minWidth: 90,
      maxWidth: 200,
      sortable: false
    },
    {
      Header: 'App name',
      id: 'appName',
      accessor: 'appName',
      Cell: AppDetailsCell('License not in use preview popup'),
      minWidth: 120,
      maxWidth: 350,
      sortable: false
    },
    {
      Header: 'Account',
      accessor: 'appAccountName',
      Cell: ({ row: { appAccountName } }) => appAccountName || '-',
      minWidth: 100,
      maxWidth: 200
    },
    {
      Header: 'Assigned to',
      id: 'user',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 150,
      maxWidth: 350,
      Cell: UserDetailsCell,
      textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email }),
      sortable: false
    },
    {
      Header: 'Last used',
      id: 'lastUsageTime',
      accessor: 'lastUsageTime',
      Cell: ({ row: { lastUsageTime } }) => getFormattedDate({ date: lastUsageTime, emptyValueDefaultDisplay: 'No date' }),
      minWidth: 121,
      sortable: false
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    }
  ]
}

const getColumns = ({ triggerType }) => (columnsByTriggerType[triggerType])

export default getColumns
