import styled from 'styled-components'
import { END_NODE_DIMENSIONS } from '@pages/workflow_v2/workflowEditor/workflowGraph/consts'

const { height, width } = END_NODE_DIMENSIONS

export const Container = styled.div`
  height: ${height}px;
  width: ${width}px;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border: 1px solid ${({ theme }) => theme.palette.border.primaryHover};
  border-radius: ${height / 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
`
