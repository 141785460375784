import React from 'react'
import get from 'lodash/get'
import { getCurrencySymbols } from '@selectors/ui'
import { getCurrency, getCurrencySymbol } from '@selectors/org'
import { useSelector } from 'react-redux'
import { isNil } from 'lodash'
import { OuterDiv, ConvertedAmount, OriginalAmount, NoAmountData } from './style'
import Currency from '../currency'

type Props = {
  value: number | null
  convertedValue: number | null
  currency: string | null
  isForRenewalTooltip?: boolean
}

const OriginalAndConvertedCurrencies = ({
  value,
  convertedValue,
  currency,
  isForRenewalTooltip
}: Props): JSX.Element | null => {
  const currencySymbols = useSelector(getCurrencySymbols)
  const orgDefaultCurrency = useSelector(getCurrency)
  const orgDefaultCurrencySymbol = useSelector(getCurrencySymbol)

  if (isNil(value) || isNil(convertedValue) || isNil(currency)) {
    return isForRenewalTooltip ? <NoAmountData>No amount data</NoAmountData> : null
  }

  const originalValueCurrencySymbol = get(currencySymbols, currency)
  const showOriginalValue = !isNil(value) && orgDefaultCurrency !== currency
  return (
    <OuterDiv>
      <ConvertedAmount isForRenewalTooltip={isForRenewalTooltip}>
        <Currency value={convertedValue} currencySymbol={orgDefaultCurrencySymbol} />
      </ConvertedAmount>
      { showOriginalValue &&
        <OriginalAmount isForRenewalTooltip={isForRenewalTooltip}>
          <Currency value={value} currencySymbol={originalValueCurrencySymbol} />
        </OriginalAmount>
      }
    </OuterDiv>
  )
}

export default OriginalAndConvertedCurrencies
