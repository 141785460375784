import React from 'react'
import texts from '@shared/style/texts'
import RelativeTeamLink from '@components/relativeTeamLink'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import { Link, LinkSize } from '@toriihq/design-system'

const CSS = {
  backButton: css(texts.body, {
    border: 'none',
    outline: 'none',
    backgroundColor: colors.transparent,
    display: 'flex',
    alignItems: 'center'
  }),
  backButtonDisabled: css(texts.body, {
    border: 'none',
    outline: 'none',
    backgroundColor: colors.transparent,
    display: 'flex',
    alignItems: 'center',
    color: `${colors.grey3} !important`,
    fontWeight: 'normal !important',
    cursor: 'pointer'
  }),
  backLinkContent: css(texts.body, {
    display: 'inline-block'
  })
}

type Props = {
  navigateTo?: string,
  linkText?: string,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void,
  overrideStyle?: object,
  disabled?: boolean,
  showBackIcon?: boolean
}

const BackLink = ({ navigateTo, linkText = 'Back', onClick, overrideStyle, disabled = false, showBackIcon = true } : Props) : JSX.Element => {
  const getContent = () => (
    <Link size={LinkSize.Medium} disabled={disabled}>
      {showBackIcon && (<Link.Icon name='ChevronLeft' />)}
      {linkText}
    </Link>
  )

  return navigateTo
    ? (
      <RelativeTeamLink to={navigateTo} onClick={onClick} disabled={disabled} className={css(CSS.backLinkContent, overrideStyle)}>
        {getContent()}
      </RelativeTeamLink>
    )
    : (
      <button {...css(disabled ? CSS.backButtonDisabled : CSS.backButton, overrideStyle)} disabled={disabled} onClick={onClick}>
        {getContent()}
      </button>
    )
}

export default BackLink
