import styled from 'styled-components'
import { fontSize } from '@shared/style/sizes'

export const ManualOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const UploadFileDropzoneWrapper = styled.div`
  width: 100%;
`

export const InstructionText = styled.div`
  font-size: ${fontSize.small};
  color: ${({ theme }) => theme.palette.text.tertiary};
`
