import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize } from '../../shared/style/sizes'
import Breadcrumbs from '../../components/breadcrumbs'
import { Helmet } from 'react-helmet'
import ResizeDetector from 'react-resize-detector'
import { Container, Content } from '@components/page/styles'

const MAX_MAIN_WIDTH = '1420px'
const NAVIGATION_WIDTH = '200px'
const SMALL_SCREEN_HORIZONTAL_PADDING = '20px'
const HORIZONTAL_PADDING = '80px'
const MIN_WIDTH_FOR_NAVIGATION = '1024px'

class Page extends React.Component {
  render () {
    const {
      children,
      isNavigationVisible,
      isSmallScreen,
      breadcrumbs,
      title,
      header,
      setHeaderHeight,
      overrideHeaderStyle,
      fillContainerHeight
    } = this.props
    const headerStyle = css({
      background: isNavigationVisible && isSmallScreen ? colors.blue : undefined
    })

    return (
      <Container data-component='Page' fillContainerHeight={fillContainerHeight}>
        {title && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
        <StickyHeader
          isSmallScreen={isSmallScreen}
          reversed={!isSmallScreen}
          className={[headerStyle.toString(), 'hide-on-print'].join(' ')}
          onResize={(height, top) => setHeaderHeight({ height, top })}
        >
          {header && (
            <Fragment>
              <div {...css(CSS.headerContainer, overrideHeaderStyle)}>
                {!isSmallScreen && header}
                {breadcrumbs && !isSmallScreen && <Breadcrumbs size='small' {...breadcrumbs} />}
              </div>
              {isSmallScreen && !isNavigationVisible && header && <header {...CSS.header}>{header}</header>}
            </Fragment>
          )}
        </StickyHeader>
        <Content fillContainerHeight={fillContainerHeight}>
          {children}
        </Content>
      </Container>
    )
  }
}

Page.propTypes = {
  isNavigationVisible: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  isDemoMode: PropTypes.bool,
  toggleNavigation: PropTypes.func,
  toggleSmallScreen: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.object,
  title: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  overrideHeaderStyle: PropTypes.object
}

Page.defaultProps = {
  children: []
}

const CSS = {
  headerContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: MAX_MAIN_WIDTH,
    margin: '0 auto',
    ' > *': {
      pointerEvents: 'auto'
    },
    marginBottom: '15px',
    paddingTop: '4px',
    [`@media(min-width: ${MIN_WIDTH_FOR_NAVIGATION})`]: {
      padding: '20px 0'
    }
  }),
  header: css({
    width: '100%',
    pointerEvents: 'auto',
    paddingBottom: '20px'
  }),
  mainWrapper: css({
    paddingLeft: SMALL_SCREEN_HORIZONTAL_PADDING,
    paddingRight: SMALL_SCREEN_HORIZONTAL_PADDING,
    [`@media(min-width: ${MIN_WIDTH_FOR_NAVIGATION})`]: {
      marginLeft: NAVIGATION_WIDTH,
      paddingLeft: HORIZONTAL_PADDING,
      paddingRight: HORIZONTAL_PADDING
    }
  }),
  main: css({
    maxWidth: MAX_MAIN_WIDTH,
    margin: '0 auto'
  }),
  navigationButton: css({
    position: 'fixed',
    top: 0,
    left: 0,
    padding: `10px ${SMALL_SCREEN_HORIZONTAL_PADDING}`,
    zIndex: 3,
    fontSize: fontSize.large,
    color: colors.white,
    borderRadius: '100%',
    width: '50px',
    height: '56px',
    [`@media(min-width: ${MIN_WIDTH_FOR_NAVIGATION})`]: {
      display: 'none'
    }
  }),
  searchBar: css({
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 3
  }),
  topMessageBars: css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 3
  })
}

const stickyHeaderCSS = {
  main: css({
    background: 'inherit',
    zIndex: 2,
    width: '100%',
    pointerEvents: 'none',
    position: 'relative',
    [`@media(min-width: ${MIN_WIDTH_FOR_NAVIGATION})`]: {
      marginLeft: NAVIGATION_WIDTH,
      width: `calc(100% - ${NAVIGATION_WIDTH})`,
      paddingRight: HORIZONTAL_PADDING,
      paddingLeft: HORIZONTAL_PADDING,
      position: 'fixed',
      left: 0
    }
  }),
  smallScreenBodyWrapper: css({
    padding: `0 ${SMALL_SCREEN_HORIZONTAL_PADDING}`
  })
}

class StickyHeader extends React.Component {
  componentDidMount () {
    this.setHeight()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
      this.setHeight()
    }
  }

  setHeight () {
    const { onResize, isSmallScreen } = this.props
    const rootHeight = (this.root && (this.root.offsetHeight)) || 0
    const rootTop = (this.root && (this.root.offsetTop)) || 0
    const height = isSmallScreen ? 0 : rootHeight
    const top = isSmallScreen ? 0 : rootTop
    onResize(height, top)
  }

  onResize = () => {
    this.setHeight()
  }

  render () {
    const { children, reversed, isSmallScreen, className } = this.props
    return (
      <header ref={root => { this.root = root }} {...stickyHeaderCSS.main} className={className}>
        <ResizeDetector handleHeight onResize={this.onResize} />
        <div {...css(isSmallScreen && stickyHeaderCSS.smallScreenBodyWrapper, { flexDirection: reversed ? 'row-reverse' : 'row' })}>
          {children}
        </div>
      </header>
    )
  }
}

export default Page
