import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { fontWeight, fontSize } from '../../../shared/style/sizes'

const CSS = {
  main: css({
    textAlign: 'left',
    ' > h1': {
      color: colors.darkText,
      fontSize: '25px',
      fontWeight: fontWeight.semiBold,
      marginTop: '30px',
      marginBottom: '5px'
    },
    ' > h2': {
      color: colors.darkText,
      fontSize: fontSize.large,
      fontWeight: fontWeight.light,
      marginTop: 0,
      marginBottom: '20px',
      lineHeight: '28px'
    }
  })
}

const Headers = ({ headerText, subHeaderText, center }) => {
  const textAlign = center ? { textAlign: 'center' } : {}
  return (
    <div {...css(CSS.main, textAlign)}>
      <h1>{headerText}</h1>
      <h2>{subHeaderText}</h2>
    </div>
  )
}

Headers.propTypes = {
  headerText: PropTypes.node,
  subHeaderText: PropTypes.node
}

export default Headers
