import analytics from '@root/helpers/analytics'
import { ParsedContractDetails } from '../types'

const Analytics = {
  onUploadContract: () => {
    analytics.track('Upload contract-doc')
  },
  onManualButtonClick: () => {
    analytics.track('Click on manually-enter-contract-details-link')
  },
  onContractAIResults: (props: { parsedFields: number, contractDetails: ParsedContractDetails }) => {
    const { parsedFields, contractDetails } = props
    analytics.track('View magic-auto-fill-results', {
      'parsed fields': parsedFields,
      'parsing status': parsedFields > 0,
      'Contract start date': contractDetails?.startDate,
      'Contract end date': contractDetails?.endDate,
      'Contract value': contractDetails?.amount?.value ?? null,
      'Application ID': contractDetails?.idApp
    })
  }
}

export default Analytics
