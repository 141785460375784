import config from '../config'

const segmentScript = (APP_ID) => {
  var analytics = window.analytics = window.analytics || []
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.')
    } else {
      analytics.invoked = !0
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on']
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments)
          e.unshift(t)
          analytics.push(e)
          return analytics
        }
      }
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t]
        analytics[e] = analytics.factory(e)
      }
      analytics.load = function (t, o) {
        var e = document.createElement('script')
        e.type = 'text/javascript'
        e.async = !0
        e.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'torii-static.s3.us-west-2.amazonaws.com/sg/sg.js'
        var n = document.getElementsByTagName('script')[0]
        analytics._loadOptions = o
        n.parentNode.insertBefore(e, n)
      }
      analytics.SNIPPET_VERSION = '4.15.2'
      analytics.load(APP_ID, { obfuscate: true, integrations: { 'Segment.io': { apiHost: 'sg-api.toriihq.com/v1' } } })
    }
  }
}

const { location = {} } = (typeof window !== 'undefined' ? window : {})
const isOnAppUrl = config.appBaseUrl.indexOf(location.host) >= 0
const enabled = config.segment.enabled && isOnAppUrl
let globalProps = {}
let globalOptions = {
  integrations: {
    All: true,
    Intercom: false,
    Zendesk: false
  }
}

const setGlobalProps = (id, props = {}, options = {}) => {
  globalProps = {
    idUser: id,
    name: props.name,
    email: props.email,
    companyName: props.company && props.company.name,
    company: props.company,
    isAdmin: props.isAdmin
  }
  if (props.isAppOwner) {
    globalProps.isAppOwner = props.isAppOwner
  }
  if (props.isAdmin) {
    globalOptions = options
  }
}

const getPropsWithGlobalProps = (props) => ({ ...globalProps, ...props })

const identify = (id, props, options) => {
  setGlobalProps(id, props, options)
  window.analytics.identify(id, { ...globalProps, ...props }, options)
  if (Object.keys(options).length > 0 && Object.keys(globalProps).length > 0) {
    window.analytics.page(globalProps, options)
  }
}

const alias = (id) => {
  window.analytics.alias(id, id, globalOptions)
}

const track = (event, props = {}) => {
  window.analytics.track(event, getPropsWithGlobalProps(props), globalOptions)
}

const pageExtraOptions = {
  Zendesk: false
}
const page = (props = {}) => {
  if (Object.keys(globalOptions).length > 0 && Object.keys(props).length > 0) {
    window.analytics.page(getPropsWithGlobalProps(props), { ...globalOptions, ...pageExtraOptions })
  }
}

const group = (id, props = {}) => {
  window.analytics.group(id, props, globalOptions)
}

let segment = null
if (enabled) {
  segmentScript(config.segment.token)

  segment = {
    identify,
    page,
    track,
    alias,
    group
  }
}

export default segment
