import React, { Fragment } from 'react'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@toriihq/design-system'
import colors from '../../../../shared/style/colors'
import Analytics from '../../../../helpers/analytics'
import InputPopup from '../../../popups/inputPopup'
import OptionsPopup from '../../../popups/optionsPopup'
import { formFieldTypes, formFieldTypeToName } from '../../../../constants'

const CSS = {
  typeBox: css({
    boxSizing: 'border-box',
    height: '123.33px',
    width: '134.64px',
    border: '1px solid #A5A5A5',
    borderRadius: '2px',
    padding: '20px',
    color: colors.black,
    ':hover': {
      color: colors.blue,
      borderColor: colors.blue
    }
  }),
  typeName: css({
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 600,
    marginTop: '10px'
  }),
  button: css({
    background: 'transparent',
    padding: '0px 10px 20px 10px',
    border: 'transparent',
    ':focus': {
      outline: 'none'
    }
  }),
  tooltip: css({
    maxWidth: '250px',
    textAlign: 'center',
    padding: '5px'
  })
}

class FieldType extends React.Component {
  state = { isOpen: false }

  toggleAddFieldState = (isClosedByUser = true) => {
    const { type } = this.props
    const isOpen = !this.state.isOpen

    if (isOpen) {
      Analytics.track('Clicked choose application field type', {
        'Field Type': type
      })
    } else if (isClosedByUser) {
      Analytics.track('Closed choose application field type', {
        'Field Type': type
      })
    }
    this.setState({ isOpen })
  }

  addNewFieldToGroup = async ({ fieldName, idGroup, formQuestion, checkbox, fieldOptions }) => {
    const { type, idOrg, toggleSelectFieldTypePopup, addDetailsField } = this.props
    Analytics.track('Clicked Save application field', {
      'Field Type': type,
      'Field name': fieldName,
      'Field formQuestion:': formQuestion,
      'Group ID': idGroup
    })
    await addDetailsField({ idOrg, type, idGroup, name: fieldName, formQuestion, options: fieldOptions })
    toggleSelectFieldTypePopup(false, idGroup, false)
  }

  render () {
    const { iconName, type, toolTip, idGroup, groupsForSelectGroup, hideFormQuestion } = this.props
    const { isOpen } = this.state

    const isDropdownField = [formFieldTypes.dropdown, formFieldTypes.dropdownMulti].includes(type)
    const typeName = formFieldTypeToName[type]

    const addSimpleFieldPopup = <InputPopup
      isOpen={isOpen}
      header={`Add new ${typeName} field details`}
      input={{ label: 'Field Name', idGroup }}
      cancelButton={{ label: 'Cancel', onClick: this.toggleAddFieldState }}
      submitButton={{ label: 'Save', onClick: this.addNewFieldToGroup }}
      autoHideOnSubmit={false}
      showGroupDropdown
      showCheckBox={!hideFormQuestion}
      groupsForSelectGroup={groupsForSelectGroup}
    />

    const addDropdownFieldPopup = <OptionsPopup
      isOpen={isOpen}
      header={`Add new ${typeName} field details`}
      input={{ label: 'Field Name', idGroup }}
      options={new Array(3).fill({ label: '', isPredefined: 0 })}
      cancelButton={{ label: 'Cancel', onClick: this.toggleAddFieldState }}
      submitButton={{ label: 'Save', onClick: this.addNewFieldToGroup }}
      autoHideOnSubmit={false}
      showGroupDropdown
      showCheckBox={!hideFormQuestion}
      groupsForSelectGroup={groupsForSelectGroup}
    />

    return (
      <Fragment>
        {isOpen && (isDropdownField ? addDropdownFieldPopup : addSimpleFieldPopup)}
        <button onClick={this.toggleAddFieldState} {...CSS.button}>
          <Tooltip distance={10} interactive={false} label={toolTip}>
            <div {...CSS.typeBox}>
              <Icon name={iconName} color='inherit' size='mobile' />
              <div {...CSS.typeName}>{typeName}</div>
            </div>
          </Tooltip>
        </button>
      </Fragment>
    )
  }
}

FieldType.propTypes = {
  iconName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  toolTip: PropTypes.string.isRequired
}

export default FieldType
