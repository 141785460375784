import send from '@shared/redux-fetch'
import {
  TOGGLE_NAVIGATION,
  TOGGLE_SMALL_SCREEN,
  TOGGLE_CONNECT_SOURCE,
  TOGGLE_UPLOAD_EXPENSES,
  GET_UPDATES,
  INVITE_USER,
  UPDATE_USER,
  TOGGLE_INVITE_USER,
  TOGGLE_ASSIGN_USER,
  TOGGLE_ALL_EXPENSE_CATEGORIES,
  UPDATE_ORG,
  UPDATE_ORG_TRIAL_END_TIME,
  UPDATE_ORG_PAID_PLAN_END_TIME,
  REMOVE_ORG_LOGO,
  UPDATE_ORG_LOGO,
  GET_ORG,
  GET_ALL_ORGS,
  MARK_SYNC_AS_RUN,
  DOWNLOAD_EXTENSION,
  EXTENSION_INSTALLED,
  CONTRACT_PARSE,
  GET_CONTRACT_PARSE,
  PARSE_EXPENSE_FILE,
  SET_HEADER_HEIGHT,
  GET_AUTOMATION_RULES,
  UPDATE_AUTOMATION_RULE,
  UPDATE_USER_SETTINGS,
  GET_USER_SETTINGS,
  EXPORT_MONTHLY_EXPENSES_CSV,
  EXPORT_THIRD_PARTY_CSV,
  EXPORT_SSO_AUDIT_CSV,
  GET_APP_DETAILS_VALUES,
  GET_APP_DETAILS_FIELDS,
  TOGGLE_UPLOAD_APP_DETAILS_ATTACHMENTS,
  GET_STATE_DETAILS,
  GET_USAGE_TRENDS,
  GET_ACTIVE_USERS_TRENDS,
  UPDATE_USER_LIFECYCLE,
  UPDATE_USERS_LIFECYCLE,
  UPDATE_APP_USER_INFO,
  GET_USER_AUDIT_LOGS,
  UPDATE_APP_DETAILS_FIELD,
  ADD_APP_DETAILS_FIELD,
  GET_APP_DETAILS_GROUPS,
  ADD_APP_DETAILS_GROUPS,
  EDIT_APP_DETAILS_GROUPS,
  DELETE_APP_DETAILS_GROUPS,
  DELETE_APP_DETAILS_FIELD,
  REORDER_APP_DETAILS_FIELDS,
  UPDATE_IS_SCIM_ENABLED,
  GET_IS_SCIM_ENABLED,
  AUDIT_LOG_TYPES,
  UPDATE_APP_CATALOG_SETTINGS,
  GET_APPS_OF_USER,
  GET_APPS_PERMISSIONS,
  GET_NOTIFY_ON_ERRORS_WORKFLOWS,
  GET_WORKFLOWS_TEMPLATES,
  DELETE_WORKFLOW,
  GET_WORKFLOWS_TRIGGERS_CONFIG,
  GET_WORKFLOWS_ACTIONS_CONFIG,
  GET_WORKFLOWS_TRIGGERS_FIELDS,
  GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES,
  GET_WORKFLOWS_ACTIONS_FIELDS,
  GET_WORKFLOW_FORM_INFO,
  SUBMIT_WORKFLOW_FORM_INFO,
  SUBMIT_WORKFLOW_REQUEST_APPROVAL,
  GET_SUPPORTED_FEATURES,
  GET_UPLOAD_URL,
  CREATE_UPLOAD,
  GET_SSO_AUDIT_REPORT,
  GET_SSO_AUDIT_UNMANAGED_USERS,
  GET_PARSING_STATUS,
  GET_WORKFLOW_EXECUTIONS,
  GET_WORKFLOW_ACTION_EXECUTIONS,
  GET_OFFBOARDING_APPS,
  GET_OFFBOARDING_APPS_OF_USERS,
  GET_OFFBOARDING_STATUS_OF_USER,
  GET_OFFBOARDING_APP,
  TOGGLE_CONFIGURE_APP_FOR_OFFBOARDING,
  GET_INACTIVE_USERS,
  EXPORT_INACTIVE_USERS_CSV,
  UPDATE_LICENSE,
  GET_LICENSES_TYPES,
  GET_LICENSE_TRENDS,
  SET_LICENSE_TREND_FILTER,
  ARCHIVE_EXPENSES_TRANSACTION,
  UNARCHIVE_EXPENSES_TRANSACTION,
  EDIT_EXPENSES_TRANSACTION,
  GET_TRANSACTIONS,
  RESET_TRANSACTIONS,
  GET_SEARCHED_TRANSACTIONS,
  RESET_SEARCH_TRANSACTIONS,
  GET_TRANSACTIONS_BY_SOURCE,
  TRANSACTION_MAPPING_STATUS,
  GET_USER_FIELDS,
  GET_OFFBOARDING_TODO_USERS,
  GET_OFFBOARDING_IN_PROGRESS_USERS,
  GET_OFFBOARDING_DONE_USERS,
  GET_OFFBOARDING_TODO_USERS_COUNT,
  GET_OFFBOARDING_DONE_USERS_COUNT,
  GET_OFFBOARDING_IN_PROGRESS_USERS_COUNT,
  GET_CURRENT_USERS,
  GET_CURRENT_USERS_AMOUNT,
  GET_CURRENT_USERS_FIELD_VALUES,
  GET_PAST_USERS,
  GET_PAST_USERS_AMOUNT,
  GET_PAST_USERS_FIELD_VALUES,
  GET_EXTERNAL_USERS,
  GET_EXTERNAL_USERS_AMOUNT,
  GET_EXTERNAL_USERS_FIELD_VALUES,
  SEARCH_USERS_AND_APPS,
  SEARCH_USERS_RESTRICTED,
  GET_CATALOG_SETTINGS,
  GET_UPLOAD,
  GET_UPLOADS,
  DELETE_UPLOAD,
  GET_MOST_USED_APPS,
  GET_INSIGHTS_STATS,
  GET_USER_DATA_ACCESS_BY_CATEGORY,
  GET_USER_DATA_ACCESS_OF_CATEGORY,
  GET_UNDERUTILIZED_LICENSES_TRENDS,
  GET_USERS_DISTRIBUTION_TRENDS,
  GET_PRODUCT_UPDATES,
  UPDATE_USER_LAST_SEEN_UPDATES_TIME,
  GET_WORKFLOW_TRIGGER_PREVIEW,
  GET_USERS_BY_COUNTRY,
  TOGGLE_CONFIGURE_EXECUTE_ACTION_ON_USERS,
  GET_APP_DETAIL_FIELD_HISTORY,
  TOGGLE_ADD_APPLICATION,
  GET_USER_TASKS,
  UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS,
  TOGGLE_SHOW_OFFBOARDING_MESSAGE,
  GET_CUSTOM_APPS,
  SEARCH_APPS_TAGS,
  GET_ORG_MATCHING_RULES,
  UPDATE_ORG_MATCHING_RULES,
  UPDATE_ORG_MATCHING_RULES_LOCALLY,
  GET_CONTRACTS,
  GET_UNMATCHED_CONTRACTS,
  SET_DONE_CONTRACTS_APP_MATCHING,
  GET_CONTRACTS_FIELDS,
  UPDATE_CONTRACT_DETAILS,
  GET_CONTRACTS_GROUPS,
  REORDER_CONTRACT_DETAILS_GROUPS,
  GET_CONTRACT,
  CREATE_CONTRACT,
  UPDATE_CONTRACTS_DETAILS,
  DELETE_CONTRACTS,
  GET_CONTRACT_DETAIL_FIELD_HISTORY,
  GET_APP_CONTRACTS,
  EDIT_CONTRACT_DETAILS_GROUPS,
  ADD_CONTRACT_DETAILS_GROUPS,
  DELETE_CONTRACT_DETAILS_GROUPS,
  UPDATE_CONTRACT_DETAILS_FIELD,
  DELETE_CONTRACT_DETAILS_FIELD,
  ADD_CONTRACT_DETAILS_FIELD,
  GET_APP_CHARGEBACK,
  GET_CHARGE_BACK_REPORT,
  GET_USER_FIELD_DISPLAY_NAMES,
  GET_APP_CHARGEBACK_CONFIG,
  UPDATE_APP_CHARGEBACK_CONFIG,
  DELETE_APP_CHARGEBACK_CONFIG,
  BULK_UPDATE_LICENSES_TYPES,
  UPDATE_LICENSE_CURRENCY_AND_DATE,
  TOGGLE_SHOW_HIDDEN_WORKFLOWS,
  TOGGLE_UNSAFE_MODE,
  GET_ORG_DOMAINS,
  UPDATE_ORG_DOMAINS,
  LOGIN,
  TOGGLE_CSV_EXPORT_POPUP,
  EXPORT_CSV_REPORT,
  GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS,
  TRIGGER_WORKFLOW,
  GET_ROLES,
  DELETE_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_ROLE,
  GET_SCOPES,
  GET_TEAM_MEMBERS,
  REVOKE_MEMBER,
  TOGGLE_IMPORT_CONTRACTS_FILE,
  GET_CATALOG_APPS,
  GET_USER,
  GET_LIFECYCLE_APPS,
  TOGGLE_USER_LIFECYCLE_SETTINGS,
  GET_LIFECYCLE_PREVIEW,
  GET_BROWSER_COUNT,
  GET_EXTENSION_REPORT_USERS,
  GET_CATALOG_USERS,
  USER_STATUS,
  ADD_USER_REPORTING,
  TOGGLE_ADD_USER,
  GET_LIFECYCLE_SETTINGS,
  UPDATE_LIFECYCLE_SETTINGS,
  TOGGLE_CUSTOM_ACTION_RESPONSE,
  UPDATE_ORG_IS_DISABLED,
  VALIDATE_WORKFLOW,
  APP_COMPARISON_GET_SSO_SOURCES_ACCOUNTS,
  GET_OFFBOARDING_SETTINGS,
  UPDATE_APP_OFFBOARDING_SETTINGS,
  WORKFLOW_TYPES,
  formFieldTypes,
  GET_WORKFLOWS_PERSONALIZATION_CONFIG,
  DEFAULT_API_PAGE_LIMIT,
  WORKFLOW_TRIGGER_TYPES, GET_ALL_USER_FIELDS,
  GET_OFFBOARDING_DEFAULT,
  REORDER_APP_DETAILS_GROUPS,
  TOGGLE_WORKFLOW_AUDIT_LOGS,
  TOGGLE_VIEW_ONLY_MODE,
  UPDATE_ORG_APP_OWNER_SETTINGS,
  SCOPES,
  UPDATE_FEATURE_USABILITY_STATUS,
  GET_SECRETS,
  CREATE_SECRET,
  DELETE_SECRET,
  UPDATE_SECRET,
  GET_CURRENCIES,
  SEARCH_APPS,
  REORDER_CONTRACT_DETAILS_FIELDS,
  USER_TYPE,
  GET_USER_TYPES_AMOUNT,
  GET_TRANSACTIONS_FIELD_VALUES,
  TOGGLE_NAVIGATION_COLLAPSE,
  GET_APP_CATALOG_POLICIES,
  GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES,
  UPDATE_ORG_PLAN,
  GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT,
  GET_TRANSACTIONS_AGGS,
  GET_LAST_12_MONTHS_TRANSACTIONS_AGGS,
  GET_LAST_YEAR_TOTAL_EXPENSES,
  GET_TRANSACTIONS_AGGS_BY_CATEGORY,
  TOGGLE_SUBSCRIBE_POPUP,
  UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION,
  GET_WORKFLOWS_DYNAMIC_PERSONALIZATION_CONFIG,
  UPDATE_IS_TORII_SUPPORT_ALLOWED_FOR_ORG,
  TORII_ADMIN_SCOPES
} from '@root/constants'

import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import get from 'lodash/get'
import pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'
import moment from 'moment'
import exportCSV from '@helpers/exportCSV'
import { getPermissionDescription } from '@shared/thirdPartyPermissions'
import { deprecatedGetAppsByIds, getSourcesWithApps } from '@selectors/apps'
import { getCurrentOrg } from '@selectors/org'
import { getSourceByType } from '@root/sourcesConfig'
import { getValidFilters } from '@shared/filters'
import { ops } from '@lenses/filters'
import config from '@root/config'
import Cookie from 'cookie'
import { isNil, isEmpty, isArray, identity, pickBy } from 'lodash'
import { getScopeByIdOrg, getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { USER_LIFECYCLE_STATUS } from '@shared/types'

export const markSyncAsRun = ({ idAppToken }) =>
  ({
    type: MARK_SYNC_AS_RUN,
    payload: { idAppToken },
    meta: {}
  })

export const toggleAssignUser = (isSelectUserOpen, idApp, onSelectUser, suggestOwner, actionDescription, assignUserCB, componentPage, actionNotes, isMultipleSelect) =>
  ({
    type: TOGGLE_ASSIGN_USER,
    payload: { isSelectUserOpen, idApp, onSelectUser, suggestOwner, actionDescription, assignUserCB, componentPage, actionNotes, isMultipleSelect },
    meta: {}
  })

export const toggleAddUser = (isOpen = false, email, fieldId) =>
  ({
    type: TOGGLE_ADD_USER,
    payload: { isOpen, email, fieldId },
    meta: { isOpen }
  })

export const toggleNavigation = () =>
  ({
    type: TOGGLE_NAVIGATION,
    payload: {},
    meta: {}
  })

export const toggleNavigationForceCollapse = (forceNavigationCollapse = false) =>
  ({
    type: TOGGLE_NAVIGATION_COLLAPSE,
    payload: { forceNavigationCollapse },
    meta: {}
  })

export const toggleSmallScreen = isSmallScreen =>
  ({
    type: TOGGLE_SMALL_SCREEN,
    payload: { isSmallScreen },
    meta: {}
  })

export const toggleConnectSource = params => {
  const {
    isConnectSourceOpen,
    sourceId,
    sourceIdApp,
    onConnect,
    isReconnect = false,
    showReconnectAlertInfo = false,
    idAppToken,
    idAppAccount,
    validTestInProgress,
    hideConnectByLink
  } = params
  return ({
    type: TOGGLE_CONNECT_SOURCE,
    payload: { isConnectSourceOpen, sourceId, sourceIdApp, onConnect, isReconnect, showReconnectAlertInfo, idAppToken, idAppAccount, validTestInProgress, hideConnectByLink },
    meta: {}
  })
}

export const setLicenseTrendFilterAction = ({ selectedUsageIdsFilter, selectedLicenseIdsFilter, selectedTimePeriod, loading }) => ({
  type: SET_LICENSE_TREND_FILTER,
  payload: { selectedUsageIdsFilter, selectedLicenseIdsFilter, selectedTimePeriod },
  meta: { loading }
})

export const toggleAllExpenseCategories = isAllExpenseCategoriesOpen =>
  ({
    type: TOGGLE_ALL_EXPENSE_CATEGORIES,
    payload: { isAllExpenseCategoriesOpen },
    meta: {}
  })

export const toggleInviteUser = (isInviteOpen = true, isByUser = true) =>
  ({
    type: TOGGLE_INVITE_USER,
    payload: { isInviteOpen, isByUser },
    meta: {}
  })

export const toggleUserLifecycleSettings = (isUserLifecycleSettingsOpen = true, isByUser = true) =>
  ({
    type: TOGGLE_USER_LIFECYCLE_SETTINGS,
    payload: { isUserLifecycleSettingsOpen, isByUser },
    meta: {}
  })

export const toggleAddApplication = ({ isAddApplicationOpen = true, isByUser = true, customApp = false, editMode = false, app, onSuccessCB = (idApp) => null, openFrom = '' }) =>
  ({
    type: TOGGLE_ADD_APPLICATION,
    payload: { isAddApplicationOpen, isByUser, editMode, app, customApp, onSuccessCB, openFrom },
    meta: {}
  })

export const toggleUploadExpenses = (isUploadExpensesOpen = true, isByUser = true) =>
  ({
    type: TOGGLE_UPLOAD_EXPENSES,
    payload: { isUploadExpensesOpen, isByUser },
    meta: {}
  })

export const toggleImportContracts = (isImportContractsOpen = true, isByUser = true) =>
  ({
    type: TOGGLE_IMPORT_CONTRACTS_FILE,
    payload: { isImportContractsOpen, isByUser },
    meta: {}
  })

export const toggleUploadAppDetailsAttachments = (isOpen = true, isByUser = true, idApp, idField) =>
  ({
    type: TOGGLE_UPLOAD_APP_DETAILS_ATTACHMENTS,
    payload: { isOpen, isByUser, idApp, idField },
    meta: {}
  })

export const getUpdates = ({ idOrg }) =>
  send(GET_UPDATES, {
    url: `/api/orgs/${idOrg}/updates`
  })

export const getLifecycleSettings = ({ idOrg }) => {
  return send(GET_LIFECYCLE_SETTINGS, {
    url: `/api/orgs/${idOrg}/lifecycleSettings`
  })
}

export const updateLifecycleSettings = ({ idOrg, userLifecycleConfig }) => {
  return send(UPDATE_LIFECYCLE_SETTINGS, {
    url: `/api/orgs/${idOrg}/lifecycleSettings`,
    method: 'PUT',
    body: {
      userLifecycleConfig
    }
  })
}

export const getLifecycleApps = ({ idOrg }) => {
  return send(GET_LIFECYCLE_APPS, {
    url: `/api/orgs/${idOrg}/lifecycleApps`
  })
}

export const getLifecyclePreview = ({ idOrg, lifecycleApps }) => {
  return send(GET_LIFECYCLE_PREVIEW, {
    url: `/api/orgs/${idOrg}/lifecyclePreview`,
    query: {
      lifecycleApps: JSON.stringify(lifecycleApps)
    }
  })
}

export const updateOrgIsDisabled = ({ idOrg, isDisabled }) => {
  return send(UPDATE_ORG_IS_DISABLED, {
    url: `/api/orgs/${idOrg}/isDisabled`,
    method: 'PUT',
    body: {
      isDisabled
    }
  })
}

export const updateOrgPlan = ({ idOrg, idPlan }) => {
  return send(UPDATE_ORG_PLAN, {
    url: `/api/orgs/${idOrg}/plan`,
    method: 'PUT',
    body: {
      idPlan
    },
    meta: {
      idPlan
    }
  })
}

export const updateOrgAllowOnlyYearlySubscription = ({ idOrg, allowOnlyYearlySubscription }) => {
  return send(UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION, {
    url: `/api/orgs/${idOrg}/allowOnlyYearlySubscription`,
    method: 'PUT',
    body: {
      allowOnlyYearlySubscription
    },
    meta: {
      allowOnlyYearlySubscription
    }
  })
}

export const getAppsOfUserFromServer = ({ idOrg, idUser }) =>
  send(GET_APPS_OF_USER, {
    url: `/api/orgs/${idOrg}/users/${idUser}/apps`,
    meta: {
      idUser
    }
  })

export const getAllTransactions = ({ idOrg, idApp, sort, reset, limit, offset, q, filters, mappingStatus }) => {
  return send(GET_TRANSACTIONS, {
    url: `/api/orgs/${idOrg}/transactions`,
    meta: {
      reset,
      timestamp: Date.now()
    },
    query: omitBy({
      idApp,
      sort,
      mappingStatus,
      q,
      filters: JSON.stringify(filters),
      limit,
      offset
    }, isUndefined)
  })
}

const defaultFromDate = moment.utc().subtract(11, 'month').startOf('month').format('YYYY-MM-DD')
const defaultToDate = moment.utc().format('YYYY-MM-DD')

export const getTransactionsAggregationsByView = ({ idOrg, idApp, filters, mappingStatus = TRANSACTION_MAPPING_STATUS.MAPPED, fromDate, toDate, viewName }) => {
  const aggs = { period: 'month' }
  return send(GET_TRANSACTIONS_AGGS, {
    url: `/api/orgs/${idOrg}/transactions`,
    meta: {
      idApp,
      fromDate,
      toDate,
      viewName,
      timestamp: Date.now()
    },
    query: omitBy({
      idApp,
      mappingStatus,
      filters: JSON.stringify(filters),
      limit: 0,
      fromDate,
      toDate,
      aggs: JSON.stringify(aggs)
    }, isUndefined)
  })
}

export const getTransactionsAggsByAppCategory = ({ idOrg, idApp, filters, mappingStatus = TRANSACTION_MAPPING_STATUS.MAPPED, fromDate = defaultFromDate, toDate = defaultToDate }) => {
  const aggs = { period: 'month', field: 'appCategory' }
  return send(GET_TRANSACTIONS_AGGS_BY_CATEGORY, {
    url: `/api/orgs/${idOrg}/transactions`,
    meta: {
      idApp
    },
    query: omitBy({
      idApp,
      mappingStatus,
      filters: JSON.stringify(filters),
      limit: 0,
      fromDate,
      toDate,
      aggs: JSON.stringify(aggs)
    }, isUndefined)
  })
}

export const getLast12MonthsTransactionsAggregations = ({ idOrg, idApp }) => {
  return send(GET_LAST_12_MONTHS_TRANSACTIONS_AGGS, {
    url: `/api/orgs/${idOrg}/transactions`,
    meta: {
      idApp
    },
    query: omitBy({
      idApp,
      mappingStatus: TRANSACTION_MAPPING_STATUS.MAPPED,
      limit: 0,
      fromDate: moment.utc().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
      toDate: moment.utc().format('YYYY-MM-DD'),
      aggs: JSON.stringify({ period: 'month' })
    }, isUndefined),
    scopes: [TORII_ADMIN_SCOPES.ORGS_READ, getScopeByIdOrg(SCOPES.EXPENSE_READ, idOrg)]
  })
}

export const getLastYearTotalExpenses = (idOrg) => {
  return send(GET_LAST_YEAR_TOTAL_EXPENSES, {
    url: `/api/orgs/${idOrg}/transactions`,
    query: omitBy({
      mappingStatus: TRANSACTION_MAPPING_STATUS.MAPPED,
      fromDate: moment.utc().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
      toDate: moment.utc().endOf('month').format('YYYY-MM-DD'),
      limit: 0,
      aggs: JSON.stringify({ period: 'year' })
    }, isUndefined),
    scopes: [TORII_ADMIN_SCOPES.ORGS_READ, getScopeByIdOrg(SCOPES.EXPENSE_READ, idOrg)]
  })
}

export const resetTransactions = () => dispatch => {
  dispatch({
    type: RESET_TRANSACTIONS
  })
}

export const getTransactionsFieldValues = ({ idOrg, idApp, fields, mappingStatus }) =>
  send(GET_TRANSACTIONS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/transactions/fieldValues`,
    query: omitBy({
      idApp,
      fields: JSON.stringify(fields),
      mappingStatus
    }, isUndefined)
  })

export const getTransactionsForSearch = ({ idOrg, q, qOperator, offset = 0, limit = DEFAULT_API_PAGE_LIMIT, sort, idApp, reset }) => {
  const query = {
    q,
    qOperator,
    offset,
    limit,
    sort
  }
  if (idApp) {
    query.idApp = idApp
  }

  return send(GET_SEARCHED_TRANSACTIONS, {
    url: `/api/orgs/${idOrg}/transactions/search`,
    query,
    meta: { reset, timestamp: Date.now() }
  })
}

export const resetSearchTransactions = () => dispatch => {
  dispatch({
    type: RESET_SEARCH_TRANSACTIONS
  })
}

export const getAppTransactionsBySource = ({ idOrg, idApp, source }) => {
  return send(GET_TRANSACTIONS_BY_SOURCE, {
    url: `/api/orgs/${idOrg}/transactions`,
    query: {
      idApp,
      source,
      mappingStatus: TRANSACTION_MAPPING_STATUS.MAPPED
    },
    scopes: [TORII_ADMIN_SCOPES.ORGS_READ, getScopeByIdOrg(SCOPES.EXPENSE_READ, idOrg)]
  })
}

export const getUploadTransactions = ({ idOrg, idUpload, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, reset }) => {
  return send(GET_TRANSACTIONS, {
    url: `/api/orgs/${idOrg}/uploads/${idUpload}/transactions`,
    meta: {
      idUpload,
      reset
    },
    query: omitBy({
      mappingStatus: Object.values(TRANSACTION_MAPPING_STATUS),
      limit,
      offset,
      sort,
      q
    }, isUndefined),
    scopes: [TORII_ADMIN_SCOPES.ORGS_READ, getScopeByIdOrg(SCOPES.EXPENSE_READ, idOrg)]
  })
}

export const activeAppsFilter = { key: 'activeAppsCount', op: ops.gt, value: 0 }
export const activeUsersFilter = { lifecycleStatus: USER_LIFECYCLE_STATUS.ACTIVE }
export const currentUsersBaseFilters = { isExternal: false, isDeletedInIdentitySources: false }
export const isParentUserFilter = { key: 'idParentUser', op: ops.isNotSet }
export const getCurrentUsers = ({ idOrg, fields, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset, filters = [] }) => {
  return send(GET_CURRENT_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      limit,
      offset,
      sort,
      q,
      fields: fields.join(','),
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getUser = ({ idOrg, idUser, fields = [] }) => {
  return send(GET_USER, {
    url: `/api/orgs/${idOrg}/users/${idUser}`,
    query: omitBy({
      limit: 1000,
      offset: 0,
      fields: fields.join(',')
    }, isUndefined)
  })
}

const thirtyDays = 30

const noExtensionUsageReportForPeriod = days => ({
  'key': { 'label': 'Last Report', 'value': 'lastReportedTime', 'type': 'date' },
  'op': { 'label': 'is more than', 'value': 'relativeDateMore', 'type': 'relativeDate' },
  'value': { 'number': days, 'type': { 'label': 'days ago', 'value': 'ago' } }
})

const extensionUsageReportForPeriod = days => ({
  'key': { 'label': 'Last Report', 'value': 'lastReportedTime', 'type': 'date' },
  'op': { 'label': 'is less than', 'value': ops.relativeDateLess, 'type': formFieldTypes.relativeDate },
  'value': { 'number': days, 'type': { 'label': 'days ago', 'value': 'ago' } }
})

const reportDateNotSerFilter = {
  'key': { 'label': 'Last Report', 'value': 'lastReportedTime', 'type': 'date' },
  'op': { 'label': 'is not set', 'value': 'isNotSet' }
}

export const getBrowserExtensionActivationUsers = ({ idOrg, fields = ['firstName', 'lastName', 'photoUrl'] }) => {
  return send(GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...currentUsersBaseFilters,
      ...activeUsersFilter,
      limit: 5,
      fields: fields.join(','),
      filters: JSON.stringify([activeAppsFilter, isParentUserFilter]),
      advancedFilters: JSON.stringify({ or: [noExtensionUsageReportForPeriod(thirtyDays), reportDateNotSerFilter] })
    }, isUndefined)
  })
}

export const pastUsersBaseFilters = { isDeletedInIdentitySources: true }
export const getPastUsers = ({ idOrg, fields, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset, filters = [] }) => {
  return send(GET_PAST_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...pastUsersBaseFilters,
      limit,
      offset,
      sort,
      q,
      fields: fields.join(','),
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const externalUsersBaseFilters = { isExternal: true }
export const getExternalUsers = ({ idOrg, fields, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset, filters = [] }) => {
  return send(GET_EXTERNAL_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...externalUsersBaseFilters,
      limit,
      offset,
      sort,
      q,
      fields: fields.join(','),
      filters: JSON.stringify(filters.concat([activeAppsFilter, isParentUserFilter]))
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getCurrentUsersAmount = ({ idOrg, fields = ['firstName'] }) => {
  return send(GET_CURRENT_USERS_AMOUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: {
      ...currentUsersBaseFilters,
      fields: fields.join(','),
      withoutContent: true,
      filters: JSON.stringify([activeAppsFilter, isParentUserFilter])
    }
  })
}

export const getCurrentUsersWithChildrenAmount = ({ idOrg, fields = ['firstName'] }) => {
  return send(GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: {
      ...currentUsersBaseFilters,
      fields: fields.join(','),
      withoutContent: true,
      filters: JSON.stringify([activeAppsFilter])
    }
  })
}

export const getUserTypeAmount = ({ idOrg, fields = ['firstName'], userType }) => {
  return send(GET_USER_TYPES_AMOUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: {
      isDeletedInIdentitySources: false,
      fields: fields.join(','),
      withoutContent: true,
      filters: JSON.stringify([{ key: 'userType', op: ops.equals, value: USER_TYPE.EMPLOYEE }, isParentUserFilter])
    },
    meta: {
      userType
    }
  })
}

export const getPastUsersAmount = ({ idOrg, fields = ['firstName'] }) => {
  const filters = { isDeletedInIdentitySources: true }

  return send(GET_PAST_USERS_AMOUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: {
      ...filters,
      fields: fields.join(','),
      withoutContent: true
    }
  })
}

export const getExternalUsersAmount = ({ idOrg, fields = ['firstName'] }) => {
  const filters = { isExternal: true }

  return send(GET_EXTERNAL_USERS_AMOUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: {
      ...filters,
      fields: fields.join(','),
      withoutContent: true,
      filters: JSON.stringify([activeAppsFilter, isParentUserFilter])
    }
  })
}

export const getOffboardingTodoUsers = ({ idOrg, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset }) => {
  const filters = { isExternal: false, isDeletedInIdentitySources: true, lifecycleStatus: USER_LIFECYCLE_STATUS.ACTIVE }
  const fields = [
    'firstName',
    'lastName',
    'email',
    'lifecycleStatus',
    'isDeletedInIdentitySources',
    'identitySourcesDeletionTime',
    'totalAppAccountsCount',
    'activeAppsCount',
    'annualCostConverted',
    'needsAttention',
    'photoUrl'
  ]

  return send(GET_OFFBOARDING_TODO_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...filters,
      filters: JSON.stringify([isParentUserFilter]),
      limit,
      offset,
      sort,
      q,
      fields: fields.join(',')
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getOffboardingTodoUsersCount = ({ idOrg }) => {
  const filters = { isExternal: false, isDeletedInIdentitySources: true, lifecycleStatus: USER_LIFECYCLE_STATUS.ACTIVE }

  return send(GET_OFFBOARDING_TODO_USERS_COUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...filters,
      filters: JSON.stringify([isParentUserFilter]),
      withoutContent: true
    }, isUndefined)
  })
}

export const getOffboardingInProgressUsers = ({ idOrg, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset }) => {
  const filters = { lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDING }
  const fields = ['firstName', 'lastName', 'email', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'totalAppAccountsCount', 'ignoredAndRemovedAppAccountsCount', 'offboardingProgress', 'isExternal', 'needsAttention', 'photoUrl', 'offboardingStartedByUser', 'offboardingStartedByWorkflowAction']

  return send(GET_OFFBOARDING_IN_PROGRESS_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...filters,
      filters: JSON.stringify([isParentUserFilter]),
      limit,
      offset,
      sort,
      q,
      fields: fields.join(',')
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getOffboardingInProgressUsersCount = ({ idOrg }) => {
  const filters = { lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDING }

  return send(GET_OFFBOARDING_IN_PROGRESS_USERS_COUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...filters,
      filters: JSON.stringify([isParentUserFilter]),
      withoutContent: true
    }, isUndefined)
  })
}

export const getOffboardingDoneUsers = ({ idOrg, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset }) => {
  const filters = { lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDED }
  const fields = ['firstName', 'lastName', 'email', 'lifecycleStatus', 'identitySourcesDeletionTime', 'offboardingEndTime', 'isExternal', 'photoUrl', 'offboardingStartedByUser', 'offboardingStartedByWorkflowAction']

  return send(GET_OFFBOARDING_DONE_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...filters,
      filters: JSON.stringify([isParentUserFilter]),
      limit,
      offset,
      sort,
      q,
      fields: fields.join(',')
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getOffboardingDoneUsersCount = ({ idOrg }) => {
  const filters = { lifecycleStatus: USER_LIFECYCLE_STATUS.OFFBOARDED }

  return send(GET_OFFBOARDING_DONE_USERS_COUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...filters,
      filters: JSON.stringify([isParentUserFilter]),
      withoutContent: true
    }, isUndefined)
  })
}

export const updateOrg = ({
  idOrg,
  defaultCurrency = undefined,
  companyName = undefined,
  appNotInUsePeriod = undefined,
  emailAlias = undefined,
  extensionMode = undefined,
  inactivityTimeout = undefined,
  userLifecycleConfig = undefined,
  isGeoLocationAllowed = undefined,
  isBenchmarkAllowed = undefined,
  isAppOwnerActive = undefined,
  isBlockThirdPartyAppsEnabled = undefined,
  sessionTimeout = undefined
}) =>
  send(UPDATE_ORG, {
    url: `/api/orgs/${idOrg}`,
    method: 'PUT',
    body: omitBy({
      defaultCurrency,
      companyName,
      appNotInUsePeriod,
      emailAlias,
      extensionMode,
      inactivityTimeout,
      userLifecycleConfig,
      isGeoLocationAllowed,
      isBenchmarkAllowed,
      isAppOwnerActive,
      isBlockThirdPartyAppsEnabled,
      sessionTimeout
    }, isUndefined),
    meta: {
      defaultCurrency,
      companyName,
      appNotInUsePeriod,
      emailAlias,
      extensionMode,
      inactivityTimeout,
      userLifecycleConfig,
      isGeoLocationAllowed,
      isBenchmarkAllowed,
      isAppOwnerActive,
      isBlockThirdPartyAppsEnabled,
      sessionTimeout
    }
  })

export const updateOrgAppOwnerSettings = ({ idOrg, isAppOwnerActive }) =>
  send(UPDATE_ORG_APP_OWNER_SETTINGS, {
    url: `/api/orgs/${idOrg}`,
    method: 'PUT',
    body: {
      isAppOwnerActive
    },
    meta: {
      isAppOwnerActive
    }
  })

export const updateIsToriiSupportAllowedForOrg = ({ idOrg, isToriiSupportAllowedForOrg }) =>
  send(UPDATE_IS_TORII_SUPPORT_ALLOWED_FOR_ORG, {
    url: `/api/orgs/${idOrg}/isToriiSupportAllowedForOrg`,
    method: 'PUT',
    body: {
      isToriiSupportAllowedForOrg
    },
    meta: {
      isToriiSupportAllowedForOrg
    }
  })

export const updateOrgTrialEndTime = ({ idOrg, trialEndTime }) =>
  send(UPDATE_ORG_TRIAL_END_TIME, {
    url: `/api/orgs/${idOrg}/trialEndTime`,
    method: 'PUT',
    body: {
      trialEndTime
    }
  })

export const updateOrgPaidPlanEndTime = ({ idOrg, paidPlanEndTime }) =>
  send(UPDATE_ORG_PAID_PLAN_END_TIME, {
    url: `/api/orgs/${idOrg}/paidPlanEndTime`,
    method: 'PUT',
    body: {
      paidPlanEndTime
    }
  })

export const removeOrgLogo = ({ idOrg, logoType }) =>
  send(REMOVE_ORG_LOGO, {
    url: `/api/orgs/${idOrg}/logos/${logoType}`,
    method: 'DELETE',
    meta: {
      logoType
    }
  })

export const getOrg = ({ idOrg }) =>
  send(GET_ORG, {
    url: `/api/orgs/${idOrg}`
  })

export const getAllOrgs = () =>
  send(GET_ALL_ORGS, {
    url: `/api/orgs`
  })

export const updateUser = ({ idOrg, idUser, idRole, prevIdRole }) =>
  send(UPDATE_USER, {
    url: `/api/orgs/${idOrg}/users/${idUser}`,
    method: 'PUT',
    body: {
      idRole
    },
    meta: {
      idUser,
      idRole,
      prevIdRole
    },
    transform: user => ({ user })
  })

export const revokeMember = ({ idOrg, idUser, idRole, prevIdRole }) =>
  send(REVOKE_MEMBER, {
    url: `/api/orgs/${idOrg}/users/${idUser}`,
    method: 'PUT',
    body: {
      idRole
    },
    meta: {
      idUser,
      idRole,
      prevIdRole
    },
    transform: user => ({ user })
  })

export const updateUserLifecycleStatus = ({ idOrg, idUser, lifecycleStatus }) =>
  send(UPDATE_USER_LIFECYCLE, {
    url: `/api/orgs/${idOrg}/users/${idUser}/lifecycleStatus`,
    method: 'PUT',
    body: {
      lifecycleStatus
    },
    transform: user => ({ user })
  })

export const updateUsersLifecycleStatus = ({ idOrg, idUsers, lifecycleStatus }) =>
  send(UPDATE_USERS_LIFECYCLE, {
    url: `/api/orgs/${idOrg}/users/lifecycleStatus`,
    method: 'PUT',
    body: {
      lifecycleStatus,
      idUsers
    }
  })

export const inviteUser = (idOrg, email, idRole) =>
  send(INVITE_USER, {
    url: `/api/orgs/${idOrg}/inviteMember`,
    method: 'POST',
    meta: {
      email,
      idRole
    },
    body: {
      email,
      idRole
    }
  })

export const extensionInstalled = (isInstalled) => ({
  type: EXTENSION_INSTALLED,
  payload: {
    isInstalled
  },
  meta: {}
})

export const downloadExtension = (onInstallSuccess, onInstallError) => (dispatch) => {
  const onSuccessExtended = () => {
    onInstallSuccess && onInstallSuccess()
    dispatch(extensionInstalled(true))
  }

  if (window.chrome && window.chrome.webstore) {
    window.chrome.webstore.install(null, onSuccessExtended, onInstallError)
  } else {
    const webstoreWindow = window.open('https://chrome.google.com/webstore/detail/khfhkedhhdbejcbapdicgagbljimakai', '_blank')
    webstoreWindow.opener = null
  }

  dispatch({
    type: DOWNLOAD_EXTENSION,
    meta: {}
  })
}

export const parseExpenseFile = ({ idOrg, idUpload }) =>
  send(PARSE_EXPENSE_FILE, {
    url: `/api/orgs/${idOrg}/uploads/${idUpload}/parse/automatically`,
    method: 'PUT',
    meta: {
      isAuto: true
    }
  })

export const parseExpenseFileManually = ({ idOrg, idUpload, parseConfig }) =>
  send(PARSE_EXPENSE_FILE, {
    url: `/api/orgs/${idOrg}/uploads/${idUpload}/parse/manually`,
    method: 'PUT',
    body: { parseConfig },
    meta: {
      isAuto: false
    }
  })

export const getParsingStatus = ({ idOrg, idParsing }) =>
  send(GET_PARSING_STATUS, {
    url: `/api/orgs/${idOrg}/parsings/${idParsing}`
  })

export const setHeaderHeight = ({ height, top }) =>
  ({
    type: SET_HEADER_HEIGHT,
    payload: { height, top },
    meta: {}
  })

export const getAutomationRules = ({ idOrg }) =>
  send(GET_AUTOMATION_RULES, {
    url: `/api/orgs/${idOrg}/automations`
  })

export const updateAutomationRule = ({ idOrg, idRule, isEnabled }) =>
  send(UPDATE_AUTOMATION_RULE, {
    url: `/api/orgs/${idOrg}/automations/${idRule}`,
    method: 'PUT',
    meta: {
      idOrg,
      idRule,
      isEnabled
    },
    body: {
      isEnabled
    }
  })

export const getUserSettings = () =>
  send(GET_USER_SETTINGS, {
    url: `/api/userSettings`
  })

export const updateUserSettings = ({ idField, prevFrequency, frequency }) =>
  send(UPDATE_USER_SETTINGS, {
    url: `/api/userSettings`,
    method: 'PUT',
    meta: {
      idField,
      prevFrequency,
      frequency
    },
    body: {
      frequency,
      idField
    }
  })

export const exportMonthlyExpensesCSV = () => async (dispatch) => {
  dispatch({ type: EXPORT_MONTHLY_EXPENSES_CSV })
}

export const exportThirdPartyCSV = sourcesWithApps => async (dispatch, getState) => {
  dispatch({ type: EXPORT_THIRD_PARTY_CSV })
  if (!sourcesWithApps) {
    const { id: idOrg } = getCurrentOrg(getState())

    await getAppsPermissions({ idOrg })(dispatch)
    sourcesWithApps = getSourcesWithApps(getState())
  }

  let data = []
  const statesByValue = keyBy(get(sourcesWithApps, ['0', 'apps', '0', 'stateInfo', 'options'], []), 'value')
  sourcesWithApps.forEach(source => {
    data = data.concat(source.apps.map(app => ({
      Source: source.name,
      Name: app.name,
      Category: app.category,
      URL: app.url,
      Users: app.activeUsersWithPermissions,
      'Risk Level (1 - 3)': app.riskLevel,
      Permissions: app.permissions
        .map(permission => getPermissionDescription(source.id, permission))
        .join(', '),
      State: statesByValue[app.stateInfo.selectedValue] ? statesByValue[app.stateInfo.selectedValue].label : app.stateInfo.selectedValue
    })))
  })
  const columnNames = ['Source', 'Name', 'Category', 'State', 'URL', 'Users', 'Risk Level (1 - 3)', 'Permissions']
  return exportCSV('3rd_party_apps.csv', data, columnNames)
}

export const exportSSOAuditCSV = () => async (dispatch, getState) => {
  dispatch({ type: EXPORT_SSO_AUDIT_CSV })

  const apps = deprecatedGetAppsByIds(getState())
  const data = getState().reports.ssoAudit.report.map(row => {
    return {
      'Application Name': apps[row.idApp].name,
      'Managed Users': row.managedUsers,
      'Unmanaged Users': row.unmanagedUsers
    }
  })
  const columnNames = ['Application Name', 'Managed Users', 'Unmanaged Users']
  return exportCSV('sso_audit.csv', data, columnNames)
}

export const exportInactiveUsersCSV = () => (dispatch, getState) => {
  dispatch({ type: EXPORT_INACTIVE_USERS_CSV })
  const { id: idOrg } = getCurrentOrg(getState())
  const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/inactive/csv`
  const newWindow = window.open(url, '_blank')
  newWindow.opener = null
}

export const exportCSVReport = ({
  idOrg,
  type,
  filters,
  sort,
  q,
  sendAnalytics = true
}) => async (dispatch) => {
  dispatch({ type: EXPORT_CSV_REPORT, payload: { type, sendAnalytics } })

  const url = `/api/orgs/${idOrg}/reports/csv`
  const query = pickBy({
    type,
    sort,
    filters: JSON.stringify(filters),
    q
  }, identity)

  send(EXPORT_CSV_REPORT, {
    url,
    query
  })(dispatch)
  dispatch({ type: TOGGLE_CSV_EXPORT_POPUP, payload: { isOpen: true } })
}

export const getAppDetailsValues = ({ idOrg, idApps, idFields, systemKeys }) => {
  const query = {}
  if (idApps) {
    if ((isArray(idApps) && !isEmpty(idApps)) || !isArray(idApps)) {
      query.idApps = JSON.stringify([].concat(idApps))
    }
  }
  if (idFields) {
    query.idFields = JSON.stringify([].concat(idFields))
  }
  if (systemKeys) {
    query.systemKeys = JSON.stringify([].concat(systemKeys))
  }

  return send(GET_APP_DETAILS_VALUES, {
    url: `/api/orgs/${idOrg}/details/values`,
    query,
    meta: {
      idApps,
      idFields,
      systemKeys
    }
  })
}

export const getAppDetailsHistoryValues = ({ idOrg, idApp, idField, systemKey }) => {
  const query = omitBy({
    idField,
    systemKey
  }, isUndefined)

  return send(GET_APP_DETAIL_FIELD_HISTORY, {
    url: `/api/orgs/${idOrg}/details/${idApp}/fieldHistory`,
    query,
    meta: {
      idApp,
      idField,
      systemKey
    }
  })
}

export const getContractDetailsHistoryValues = ({ idOrg, idContract, systemKey, idApp }) => {
  return send(GET_CONTRACT_DETAIL_FIELD_HISTORY, {
    url: `/api/orgs/${idOrg}/contracts/${idContract}/fields/${systemKey}/history`,
    meta: {
      idContract,
      systemKey
    },
    query: omitBy({
      idApp
    }, isUndefined)
  })
}

export const getAppDetailsFields = ({ idOrg, idFields, idApp }) => {
  const query = omitBy({
    idFields: idFields ? JSON.stringify(idFields) : idFields,
    idApp
  }, isUndefined)
  return send(GET_APP_DETAILS_FIELDS, {
    url: `/api/orgs/${idOrg}/appFormFields`,
    query
  })
}

export const getUserDetailsFields = ({ idOrg }) => {
  return send(GET_USER_FIELDS, {
    url: `/api/orgs/${idOrg}/users/customFields`
  })
}

export const getUserFields = ({ idOrg }) => {
  return send(GET_ALL_USER_FIELDS, {
    url: `/api/orgs/${idOrg}/users/customFields/all`
  })
}

export const getUserFieldDisplayNames = ({ idOrg, userFieldKey }) => {
  return send(GET_USER_FIELD_DISPLAY_NAMES, {
    url: `/api/orgs/${idOrg}/users/customFields/${userFieldKey}/uniqueValues`,
    meta: {
      userFieldKey
    }
  })
}

export const getAppChargebackConfig = ({ idOrg, idApp }) => {
  return send(GET_APP_CHARGEBACK_CONFIG, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/chargeback/config`,
    method: 'GET',
    meta: {
      idOrg,
      idApp
    }
  })
}

export const updateAppChargebackConfig = ({ idOrg, idApp, allocationStrategy, allocationCost, allocateCostByUserField, config }) => {
  return send(UPDATE_APP_CHARGEBACK_CONFIG, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/chargeback/config`,
    method: 'PUT',
    meta: {
      idOrg,
      idApp,
      allocationStrategy,
      allocationCost,
      allocateCostByUserField,
      config
    },
    body: {
      allocationStrategy,
      allocationCost,
      allocateCostByUserField,
      config
    }
  })
}

export const deleteAppChargebackConfig = ({ idOrg, idApp }) => {
  return send(DELETE_APP_CHARGEBACK_CONFIG, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/chargeback/config`,
    method: 'DELETE',
    meta: {
      idApp
    }
  })
}

export const updateAppDetailsField = ({ idOrg, idGroup, idField, isShown, name, formQuestion, options }) => {
  return send(UPDATE_APP_DETAILS_FIELD, {
    url: `/api/orgs/${idOrg}/appFormFields/${idField}`,
    method: 'PUT',
    meta: {
      idField,
      isShown,
      name,
      idGroup
    },
    body: {
      isShown,
      name,
      formQuestion,
      options,
      idGroup
    }
  })
}

export const addAppDetailsField = ({ idOrg, type, idGroup, name, formQuestion, options }) => {
  return send(ADD_APP_DETAILS_FIELD, {
    url: `/api/orgs/${idOrg}/appFormFields`,
    method: 'POST',
    body: {
      type,
      idGroup,
      name,
      formQuestion,
      options
    }
  })
}

export const getAppDetailsGroups = ({ idOrg }) => {
  return send(GET_APP_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/appFormGroups`
  })
}

export const editAppDetailsGroup = ({ idOrg, idGroup, label, position }) => {
  return send(EDIT_APP_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/appFormGroups/${idGroup}`,
    method: 'PUT',
    body: {
      label
    },
    meta: {
      idGroup,
      label
    }
  })
}

export const addAppDetailsGroup = ({ idOrg, label }) => {
  return send(ADD_APP_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/appFormGroups`,
    method: 'POST',
    body: {
      label
    },
    meta: {
      label
    }
  })
}

export const reorderAppDetailsGroups = ({ idOrg, sourceGroup, destinationGroup, changes, reorderedGroups }) => {
  return send(REORDER_APP_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/appFormGroups/reorder`,
    method: 'PUT',
    body: {
      changes
    },
    meta: {
      sourceGroup,
      destinationGroup,
      reorderedGroups
    }
  })
}

export const deleteAppDetailsGroup = ({ idOrg, idGroup }) => {
  return send(DELETE_APP_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/appFormGroups/${idGroup}`,
    method: 'DELETE',
    meta: {
      idGroup
    }
  })
}

export const deleteAppDetailsField = ({ idOrg, idField }) => {
  return send(DELETE_APP_DETAILS_FIELD, {
    url: `/api/orgs/${idOrg}/appFormFields/${idField}`,
    method: 'DELETE',
    meta: {
      idField
    }
  })
}

export const reorderAppDetailsFields = ({ idOrg, changes, reorderedData, previousData, idGroup }) => {
  return send(REORDER_APP_DETAILS_FIELDS, {
    url: `/api/orgs/${idOrg}/appFormField/reorder`,
    method: 'PUT',
    body: {
      changes
    },
    meta: {
      reorderedData,
      previousData,
      idGroup
    }
  })
}

export const reorderContractDetailsFields = ({ idOrg, changes, reorderedData, previousData }) => {
  return send(REORDER_CONTRACT_DETAILS_FIELDS, {
    url: `/api/orgs/${idOrg}/contracts/fields/reorder`,
    method: 'PUT',
    body: {
      changes
    },
    meta: {
      reorderedData,
      previousData,
      idGroup: changes[0].idGroup
    }
  })
}

export const archiveTransaction = ({ idOrg, idTransaction }) => {
  return send(ARCHIVE_EXPENSES_TRANSACTION, {
    url: `/api/orgs/${idOrg}/transactions/${idTransaction}`,
    method: 'PUT',
    meta: {
      idTransaction
    },
    body: {
      mappingStatus: TRANSACTION_MAPPING_STATUS.ARCHIVED
    }
  })
}

export const unarchiveTransaction = ({ idOrg, idTransaction, mappingStatus }) => {
  return send(UNARCHIVE_EXPENSES_TRANSACTION, {
    url: `/api/orgs/${idOrg}/transactions/${idTransaction}`,
    method: 'PUT',
    meta: {
      idTransaction
    },
    body: {
      mappingStatus
    }
  })
}

export const editTransaction = ({ idOrg, prevIdApp, idApp, idTransaction, mappingStatus, mappingLogic, transactionDate, modifiedAmount, amount }) => {
  return send(EDIT_EXPENSES_TRANSACTION, {
    url: `/api/orgs/${idOrg}/transactions/${idTransaction}`,
    method: 'PUT',
    meta: {
      idTransaction,
      idApp,
      prevIdApp,
      mappingStatus,
      mappingLogic,
      modifiedAmount,
      transactionDate,
      amount
    },
    body: omitBy({
      idApp,
      mappingStatus,
      transactionDate,
      modifiedAmount
    }, isUndefined)
  })
}

export const uploadFile = ({ idOrg, file, type, idApp }) => async (dispatch) => {
  const fileName = file.name
  const fileType = file.type || 'text/csv'
  const { url, filePath } = await send(GET_UPLOAD_URL, {
    method: 'GET',
    url: `/api/orgs/${idOrg}/uploads/url`,
    query: omitBy({
      fileName,
      fileType,
      idApp
    }, isUndefined)
  })(dispatch)

  await window.fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': fileType
    }
  })

  return send(CREATE_UPLOAD, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/uploads`,
    body: {
      filePath,
      type,
      idApp
    }
  })(dispatch)
}

export const uploadOrgLogo = ({ idOrg, logoFile, logoType }) => async (dispatch) => {
  const data = new FormData()
  data.append('logoFile', logoFile)
  const headers = {}
  const cookies = Cookie.parse(document.cookie)
  if (cookies.crumb) {
    headers['x-csrf-token'] = cookies.crumb
  }
  const response = await window.fetch(`${config.proxyApiRequests ? (config.proxyApiRequestsPath || '') : config.apiBaseUrl}/api/orgs/${idOrg}/logos/${logoType}`, {
    method: 'PUT',
    body: data,
    credentials: 'include',
    headers
  })

  const body = await response.json()

  dispatch({
    type: UPDATE_ORG_LOGO,
    meta: {
      logoType,
      url: body[logoType]
    }
  })

  return response.status === 200
}

export const uploadOrgImage = ({ idOrg, file, imageType }) => async () => {
  const data = new FormData()
  data.append('image', file)
  const headers = {}
  const cookies = Cookie.parse(document.cookie)
  if (cookies.crumb) {
    headers['x-csrf-token'] = cookies.crumb
  }
  const response = await window.fetch(`${config.proxyApiRequests ? (config.proxyApiRequestsPath || '') : config.apiBaseUrl}/api/orgs/${idOrg}/images/${imageType}`, {
    method: 'POST',
    body: data,
    credentials: 'include',
    headers
  })

  const body = await response.json()

  return {
    success: response.status === 200,
    url: body.url
  }
}
export const getStateDetails = ({ idOrg }) => {
  const systemKeys = ['state']
  return send(GET_STATE_DETAILS, {
    url: `/api/orgs/${idOrg}/details/values`,
    query: {
      systemKeys: JSON.stringify(systemKeys)
    },
    meta: {
      systemKeys
    }
  })
}

export const getUsageTrends = ({ idOrg, idApp }) => {
  return send(GET_USAGE_TRENDS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/trends/usage`,
    meta: {
      idApp
    }
  })
}

export const getActiveUsersTrends = ({ idOrg, idApp }) => {
  return send(GET_ACTIVE_USERS_TRENDS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/trends/activeUsers`,
    meta: {
      idApp
    }
  })
}

export const updateAppUserRemovalStatus = ({
  idOrg,
  idUser,
  idApp,
  isRemoved,
  triggerIdUser,
  removedTime,
  idActionExe,
  idAppAccount,
  email,
  pageName,
  taskType
}) =>
  send(UPDATE_APP_USER_INFO, {
    url: `/api/orgs/${idOrg}/users/${idUser}/appsRemoval`,
    method: 'PUT',
    body: {
      isRemoved,
      shouldUpdateLifecycleStatus: true
    },
    query: omitBy({
      idApps: JSON.stringify([].concat(idApp)),
      idActionExe,
      idAppAccount
    }, isUndefined),
    meta: {
      idApp,
      idAppAccount,
      idUser,
      isRemoved,
      triggerIdUser,
      removedTime,
      email,
      pageName,
      taskType
    }
  })

export const submitWorkflowFormInfo = ({ idOrg, idActionExe, workflowFormInfo, token, formFieldsValues, rejectForm = false }) => {
  return send(SUBMIT_WORKFLOW_FORM_INFO, {
    url: `/api/orgs/${idOrg}/workflowActionExe/${idActionExe}/form`,
    method: 'PUT',
    body: {
      formFieldsValues,
      rejectForm
    },
    query: {
      token
    },
    meta: {
      idActionExe,
      workflowFormInfo,
      rejectForm
    }
  })
}

export const submitWorkflowApprovalRequest = ({ idOrg, idActionExe, token, approved }) => {
  return send(SUBMIT_WORKFLOW_REQUEST_APPROVAL, {
    url: `/api/orgs/${idOrg}/workflowActionExe/${idActionExe}/requestApproval`,
    method: 'PUT',
    body: {
      approved
    },
    query: {
      token
    },
    meta: {
      idActionExe,
      approved
    }
  })
}

export const getUserOffboardingAuditLogs = ({ idOrg, idUser }) => {
  return send(GET_USER_AUDIT_LOGS, {
    url: `/api/orgs/${idOrg}/users/${idUser}/audit`,
    meta: {
      idUser
    },
    query: {
      types: JSON.stringify([AUDIT_LOG_TYPES.UPDATE_USER_LIFECYCLE_STATUS, AUDIT_LOG_TYPES.APP_REMOVAL, AUDIT_LOG_TYPES.REMOVED_DIRECTLY_BY_TORII])
    }
  })
}

export const getWorkflowFormInfoFromServer = ({ idOrg, idActionExe, token }) => {
  return send(GET_WORKFLOW_FORM_INFO, {
    url: `/api/orgs/${idOrg}/workflowActionExe/${idActionExe}/form`,
    query: {
      token
    },
    meta: {
      idActionExe
    }
  })
}

export const getTriggerFieldsOptions = ({ idOrg, trigger }) => {
  return send(GET_WORKFLOWS_TRIGGERS_FIELDS, {
    url: `/api/orgs/${idOrg}/workflows/triggerFieldsOptions`,
    method: 'POST',
    body: {
      trigger
    },
    meta: {
      trigger
    }
  })
}

export const getTriggerFieldsOptionsValues = ({ idOrg, trigger, filters }) => {
  return send(GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES, {
    url: `/api/orgs/${idOrg}/workflows/triggerFieldsOptionsValues`,
    method: 'POST',
    body: omitBy({
      trigger,
      filters
    }, isUndefined),
    meta: {
      trigger
    }
  })
}

export const getActionFieldsOptions = ({ idOrg, action, triggerType, prevActionTypes }) => {
  const actionForAPI = {
    ...action,
    fields: (action.fields || []).map(field => pick(field, ['id', 'value', 'isValid']))
  }

  return send(GET_WORKFLOWS_ACTIONS_FIELDS, {
    url: `/api/orgs/${idOrg}/workflows/actionFieldsOptions`,
    method: 'POST',
    body: {
      triggerType,
      action: JSON.stringify(actionForAPI),
      prevActionTypes: JSON.stringify(prevActionTypes),
      ...(action && action.idApp ? { idApp: action.idApp } : {})
    },
    meta: {
      action
    }
  })
}

export const getIsScimEnabled = ({ idOrg }) => {
  return send(GET_IS_SCIM_ENABLED, {
    url: `/api/orgs/${idOrg}/isScimEnabled`
  })
}

export const updateIsScimEnabled = ({ idOrg, enable }) => {
  return send(UPDATE_IS_SCIM_ENABLED, {
    url: `/api/orgs/${idOrg}/isScimEnabled`,
    method: 'PUT',
    body: {
      enable
    }
  })
}
export const getOffboardingSettings = ({ idOrg }) => {
  return send(GET_OFFBOARDING_SETTINGS, {
    url: `/api/orgs/${idOrg}/offboardingSettings`
  })
}

export const updateAppOffboardingSettings = ({ idOrg, remindersEnabled, remindersIntervalInDays }) => {
  const dataToUpdate = omitBy({ remindersEnabled, remindersIntervalInDays }, isUndefined)

  return send(UPDATE_APP_OFFBOARDING_SETTINGS, {
    url: `/api/orgs/${idOrg}/offboardingSettings`,
    method: 'PUT',
    body: dataToUpdate,
    meta: dataToUpdate
  })
}

export const getCatalogSettings = ({ idOrg }) => {
  return send(GET_CATALOG_SETTINGS, {
    url: `/api/orgs/${idOrg}/catalogSettings`
  })
}

export const updateAppCatalogSettings = ({ idOrg, isCatalogEnabledForMembers, isRequestLicenseEnabled, requestLicenseConfig, isRequestNewAppEnabled, requestNewAppConfig, appFieldsConfig, appsFiltersConfig }) => {
  const dataToUpdate = omitBy({ isCatalogEnabledForMembers, isRequestLicenseEnabled, requestLicenseConfig, isRequestNewAppEnabled, requestNewAppConfig, appFieldsConfig, appsFiltersConfig }, isUndefined)
  let validDataToUpdate = dataToUpdate

  if (dataToUpdate.appsFiltersConfig) {
    validDataToUpdate = { ...dataToUpdate, appsFiltersConfig: getValidFilters(appsFiltersConfig) }
  }

  return send(UPDATE_APP_CATALOG_SETTINGS, {
    url: `/api/orgs/${idOrg}/catalogSettings`,
    method: 'PUT',
    body: validDataToUpdate,
    meta: dataToUpdate
  })
}

export const getCatalogApps = ({ idOrg, fields = ['id', 'name', 'imageUrl', 'isNew', 'owner', 'activeUsersCount', 'creationTime', 'score', 'state', 'sources', 'category'] }) => {
  return send(GET_CATALOG_APPS, {
    url: `/api/orgs/${idOrg}/catalog/apps`,
    query: {
      fields
    }
  })
}

export const getCurrentUsersFieldValues = ({ idOrg, fields, filters = [] }) => {
  return send(GET_CURRENT_USERS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/users/fieldValues`,
    query: omitBy({
      fields: JSON.stringify(fields),
      filters: JSON.stringify(filters.concat(activeAppsFilter)),
      ...currentUsersBaseFilters
    }, isUndefined)
  })
}

export const getPastUsersFieldValues = ({ idOrg, fields }) => {
  const baseFilters = { isDeletedInIdentitySources: true }

  return send(GET_PAST_USERS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/users/fieldValues`,
    query: omitBy({
      fields: JSON.stringify(fields),
      ...baseFilters
    }, isUndefined)
  })
}

export const getExternalUsersFieldValues = ({ idOrg, fields, filters = [] }) => {
  const baseFilters = { isExternal: true }

  return send(GET_EXTERNAL_USERS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/users/fieldValues`,
    query: omitBy({
      fields: JSON.stringify(fields),
      filters: JSON.stringify(filters.concat([activeAppsFilter, isParentUserFilter])),
      ...baseFilters
    }, isUndefined)
  })
}

export const bulkUpdateLicensesTypes = ({ idOrg, idApp, licenses, LicensesFullDetails, waitForES }) =>
  send(BULK_UPDATE_LICENSES_TYPES, {
    url: `/api/orgs/${idOrg}/licenses/bulk`,
    method: 'PUT',
    body: {
      licenses,
      waitForES
    },
    meta: {
      idApp,
      LicensesFullDetails
    }
  })

export const updateLicenseCurrencyAndDate = ({ idOrg, idApp, currency, conversionDate }) =>
  send(UPDATE_LICENSE_CURRENCY_AND_DATE, {
    url: `/api/orgs/${idOrg}/app/${idApp}/licenses`,
    method: 'PUT',
    body: {
      currency,
      conversionDate
    }
  })

export const getAppsPermissions = ({ idOrg, idApp, sources = ['slack', 'google', 'azure_ad'] }) =>
  send(GET_APPS_PERMISSIONS, {
    url: `/api/orgs/${idOrg}/integrations/permissions`,
    query: omitBy({
      sources: sources.join(','),
      idApp
    }, isUndefined),
    meta: {
      sources
    }
  })

export const getAppCatalogPolicies = ({ idOrg }) => {
  return send(GET_APP_CATALOG_POLICIES, {
    url: `/api/orgs/${idOrg}/workflows`,
    query: {
      type: WORKFLOW_TYPES.appCatalog,
      triggerType: WORKFLOW_TRIGGER_TYPES.REQUEST_ACCESS,
      includeTriggerIdApp: true,
      includeDeleted: false,
      includeActions: true
    }
  })
}

export const getAppCatalogRequestNewAppPolicies = ({ idOrg }) => {
  return send(GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES, {
    url: `/api/orgs/${idOrg}/workflows`,
    query: {
      type: WORKFLOW_TYPES.appCatalog,
      triggerType: WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP,
      includeTriggerIdApp: true,
      includeDeleted: false,
      includeActions: true
    }
  })
}

export const getNotifyOnErrorsWorkflows = ({ idOrg }) => {
  return send(GET_NOTIFY_ON_ERRORS_WORKFLOWS, {
    url: `/api/orgs/${idOrg}/workflows`,
    query: { type: WORKFLOW_TYPES.notifyOnErrors, includeActions: true }
  })
}

export const getWorkflowsTemplates = ({ idOrg }) => {
  return send(GET_WORKFLOWS_TEMPLATES, {
    url: `/api/orgs/${idOrg}/workflows/templates`
  })
}

export const validateWorkflow = ({ idOrg, workflow }) => {
  return send(VALIDATE_WORKFLOW, {
    method: 'GET',
    url: `/api/orgs/${idOrg}/workflows/validate`,
    query: {
      ...pick(workflow, ['idApp', 'triggerType', 'isActive', 'name', 'triggerIdApp', 'triggerIdAppAccount']),
      actions: JSON.stringify(workflow.actions || []),
      triggerConfiguration: JSON.stringify(workflow.triggerConfiguration || [])
    }
  })
}

export const deleteWorkflow = ({ idOrg, idWorkflow, type, triggerType }) => {
  return send(DELETE_WORKFLOW, {
    method: 'DELETE',
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}`,
    meta: {
      idWorkflow,
      type,
      triggerType
    }
  })
}

export const getWorkflowsTriggersConfig = (idOrg, idApp) => {
  return send(GET_WORKFLOWS_TRIGGERS_CONFIG, {
    url: `/api/orgs/workflows/config/triggers`,
    query: omitBy({
      idOrg,
      idApp
    }, isUndefined)
  })
}

export const getWorkflowsPersonalizationsConfig = ({ idOrg, idApp }) => {
  return send(GET_WORKFLOWS_PERSONALIZATION_CONFIG, {
    url: `/api/orgs/${idOrg}/workflows/config/personalization`,
    query: omitBy({
      idApp
    }, isNil)
  })
}

export const getWorkflowsDynamicPersonalizationsConfig = ({ idOrg, idWorkflow }) => {
  return send(GET_WORKFLOWS_DYNAMIC_PERSONALIZATION_CONFIG, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/config/dynamicPersonalization`,
    meta: { idWorkflow }
  })
}

export const getWorkflowsActionsConfig = (idOrg, idApp) => {
  return send(GET_WORKFLOWS_ACTIONS_CONFIG, {
    url: `/api/orgs/${idOrg}/workflows/config/actions`,
    query: omitBy({
      idApp
    }, isUndefined)
  })
}

export const getSupportedFeatures = ({ idOrg }) =>
  send(GET_SUPPORTED_FEATURES, {
    url: `/api/orgs/${idOrg}/supportedFeatures`
  })

export const updateFeatureUsabilityStatus = ({ idOrg, feature, isEnabled }) =>
  send(UPDATE_FEATURE_USABILITY_STATUS, {
    url: `/api/orgs/${idOrg}/labsFeatures`,
    method: 'PUT',
    body: { feature, isEnabled },
    meta: { feature, isEnabled }
  })

export const getSSOAuditReport = ({ idOrg, source }) =>
  send(GET_SSO_AUDIT_REPORT, {
    url: `/api/orgs/${idOrg}/reports/ssoAudit`,
    query: {
      source
    }
  })

export const getSSOAuditUnmanagedUsers = ({ idOrg, idApp, source }) =>
  send(GET_SSO_AUDIT_UNMANAGED_USERS, {
    url: `/api/orgs/${idOrg}/reports/ssoAudit/${idApp}/unmanagedUsers`,
    query: {
      source
    }
  })

export const getWorkflowExecutions = ({ idOrg, idWorkflow, limit = 50, offset = 0, reset }) =>
  send(GET_WORKFLOW_EXECUTIONS, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/executions`,
    query: omitBy({
      limit,
      offset
    }, isUndefined),
    meta: {
      idWorkflow,
      reset
    }
  })

export const getWorkflowActionExecutions = ({ idOrg, idWorkflow, idWorkflowExecution }) =>
  send(GET_WORKFLOW_ACTION_EXECUTIONS, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/executions/${idWorkflowExecution}/actions`,
    meta: {
      idWorkflow,
      idWorkflowExecution
    }
  })

export const getOffboardingApps = ({ idOrg }) => {
  return send(GET_OFFBOARDING_APPS, {
    url: `/api/orgs/${idOrg}/offboarding/apps`
  })
}

export const getOffboardingStatusOfUser = ({ idOrg, idUser }) => {
  return send(GET_OFFBOARDING_STATUS_OF_USER, {
    url: `/api/orgs/${idOrg}/offboarding/users/${idUser}`
  })
}

export const getOffboardingAppsOfUsers = ({ idOrg, idUsers }) => {
  const query = { idUsers, includeAppsMarkedAsRemoved: true }
  return send(GET_OFFBOARDING_APPS_OF_USERS, {
    url: `/api/orgs/${idOrg}/offboarding/apps`,
    query,
    meta: { idUsers }
  })
}

export const getOffboardingApp = ({ idOrg, idApp, idUsers }) => {
  const query = {}
  if (idUsers) {
    query.idUsers = idUsers
  }

  return send(GET_OFFBOARDING_APP, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/offboarding`,
    query
  })
}

export const getDefaultOffboardingWorkflow = ({ idOrg }) => {
  return send(GET_OFFBOARDING_DEFAULT, {
    url: `/api/orgs/${idOrg}/workflows`,
    query: { type: WORKFLOW_TYPES.offboarding, triggerType: WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING, includeDeleted: false, includeDefault: true }
  })
}

export const toggleConfigureAppForOffboarding = ({ isOpen, isByUser = true, idApp, idAppAccount, appAndAccountName, actions, pendingOffboardingAmount, showAlertBox, onConfig = () => { }, onClose = () => { } }) =>
  ({
    type: TOGGLE_CONFIGURE_APP_FOR_OFFBOARDING,
    payload: { isOpen, isByUser, idApp, idAppAccount, appAndAccountName, actions, onConfig, onClose, pendingOffboardingAmount, showAlertBox }
  })

export const toggleSubscribePopup = ({ isOpen }) =>
  ({
    type: TOGGLE_SUBSCRIBE_POPUP,
    payload: { isOpen }
  })

export const toggleCustomActionResponsePopup = ({ isOpen, isByUser = true, onClose = () => { }, action }) =>
  ({
    type: TOGGLE_CUSTOM_ACTION_RESPONSE,
    payload: { isOpen, isByUser, onClose, action }
  })

export const toggleConfigureExecuteActionOnUsers = ({ isOpen, isByUser = true, idApp, users, onConfig = () => { }, actionToRun, extraFieldsDefaultValues = {}, fieldsToValue, executionFlow, flowType, idRecommendation }) =>
  ({
    type: TOGGLE_CONFIGURE_EXECUTE_ACTION_ON_USERS,
    payload: { isOpen, isByUser, idApp, users, onConfig, actionToRun, extraFieldsDefaultValues, fieldsToValue, executionFlow, flowType, idRecommendation }
  })

export const getInactiveUsers = ({ idOrg, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset, filters = [] }) => {
  return send(GET_INACTIVE_USERS, {
    url: `/api/orgs/${idOrg}/users/inactive`,
    query: omitBy({
      limit,
      offset,
      sort,
      q,
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const updateLicense = ({ idOrg, idLicense, pricePerUser, idApp }) => {
  return send(UPDATE_LICENSE, {
    url: `/api/orgs/${idOrg}/licenses/${idLicense}`,
    method: 'PUT',
    body: {
      pricePerUser
    },
    meta: {
      idLicense,
      pricePerUser,
      idApp
    }
  })
}

export const getLicensesTypes = ({ idOrg, idApp }) => {
  const query = {}
  if (idApp) {
    query.idApp = idApp
  }
  return send(GET_LICENSES_TYPES, {
    url: `/api/orgs/${idOrg}/licenses/types`,
    query
  })
}

export const getLicenseTrendsChart = ({ idOrg, period }) => {
  return send(GET_LICENSE_TRENDS, {
    url: `/api/orgs/${idOrg}/licenses/trends`,
    query: {
      period
    }
  })
}

export const searchUsersAndApps = ({
  idOrg,
  idUsers,
  q,
  limit,
  type,
  includeAllApps,
  exactAppName,
  excludePastUsers
}) => {
  return send(SEARCH_USERS_AND_APPS, {
    url: `/api/orgs/${idOrg}/search`,
    query: omitBy({
      idUsers: idUsers ? JSON.stringify([].concat(idUsers)) : undefined,
      q: (q || undefined),
      filters: JSON.stringify([isParentUserFilter]),
      limit,
      type,
      includeAllApps,
      exactAppName,
      excludePastUsers
    }, isUndefined)
  })
}

export const searchAllApps = ({ idOrg, q, limit, exactAppName }) => {
  return send(SEARCH_APPS, {
    url: `/api/apps/orgs/${idOrg}`,
    query: omitBy({
      q: (q || undefined),
      limit,
      exactAppName
    }, isUndefined)
  })
}

export const searchApps = ({ idOrg, q, limit, exactAppName, includeAllApps }) => async (dispatch) => {
  if (includeAllApps) {
    return dispatch(searchAllApps({ idOrg, q, limit, exactAppName }))
  } else {
    const result = await dispatch(searchUsersAndApps({
      idOrg,
      q,
      limit,
      type: 'apps',
      includeAllApps,
      exactAppName
    }))
    return result.results
  }
}

export const searchUserRestricted = ({ idOrg, q, limit, idActionExe, id, secret }) => {
  return send(SEARCH_USERS_RESTRICTED, {
    url: `/api/orgs/${idOrg}/users/restrictedSearch`,
    query: omitBy({
      q,
      limit,
      idActionExe,
      token: `${id}-${secret}`
    }, isUndefined)
  })
}

export const searchAppsTags = ({ idOrg, q, limit }) => {
  return send(SEARCH_APPS_TAGS, {
    url: `/api/orgs/${idOrg}/appsTags/search`,
    query: omitBy({
      q,
      limit
    }, isUndefined)
  })
}

export const getUpload = ({ idOrg, idUpload }) => {
  return send(GET_UPLOAD, {
    url: `/api/orgs/${idOrg}/uploads/${idUpload}`
  })
}

export const getUploads = ({ idOrg }) => {
  return send(GET_UPLOADS, {
    url: `/api/orgs/${idOrg}/uploads`
  })
}

export const deleteUpload = ({ idOrg, idUpload }) => {
  return send(DELETE_UPLOAD, {
    url: `/api/orgs/${idOrg}/uploads/${idUpload}`,
    method: 'DELETE',
    meta: {
      idOrg,
      idUpload
    }
  })
}

export const getMostUsedApps = ({ idOrg }) => {
  return send(GET_MOST_USED_APPS, {
    url: `/api/orgs/${idOrg}/trends/mostUsedApps`
  })
}

export const getInsightsStats = ({ idOrg }) => {
  return send(GET_INSIGHTS_STATS, {
    url: `/api/stats/${idOrg}/insights`
  })
}

export const getUserDataAccessByCategory = ({ idOrg }) => {
  return send(GET_USER_DATA_ACCESS_BY_CATEGORY, {
    url: `/api/orgs/${idOrg}/userDataAccessByCategory`
  })
}

export const getUserDataAccessOfCategory = ({ idOrg, category }) => {
  return send(GET_USER_DATA_ACCESS_OF_CATEGORY, {
    url: `/api/orgs/${idOrg}/userDataAccessByCategory/${category}`
  })
}

export const getUnderutilizedLicenses = ({ idOrg, limit = 10 }) => {
  return send(GET_UNDERUTILIZED_LICENSES_TRENDS, {
    url: `/api/orgs/${idOrg}/licenses/trends/underutilized`,
    query: { limit }
  })
}

export const getUsersDistribution = ({ idOrg }) => {
  return send(GET_USERS_DISTRIBUTION_TRENDS, {
    url: `/api/orgs/${idOrg}/trends/usersDistribution`
  })
}

export const getUsersDistributionV2 = ({ idOrg }) => {
  return send(GET_USERS_DISTRIBUTION_TRENDS, {
    url: `/api/orgs/${idOrg}/trends/usersDistributionV2`
  })
}

export const getProductUpdates = () => {
  return send(GET_PRODUCT_UPDATES, {
    url: `/api/productUpdates`
  })
}

export const updateUserLastSeenProductUpdatesTime = () => {
  return send(UPDATE_USER_LAST_SEEN_UPDATES_TIME, {
    url: `/api/users/my`,
    method: 'PUT',
    body: {
      lastSeenProductUpdatesTime: moment().utc().toDate()
    }
  })
}

export const getTriggerPreview = ({ idOrg, idWorkflow }) => {
  return send(GET_WORKFLOW_TRIGGER_PREVIEW, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/previewTrigger`,
    meta: { idWorkflow }
  })
}

export const getUsersByCountry = ({ idOrg, idApp, country, limit = 100, offset = 0, q, sort }) => {
  return send(GET_USERS_BY_COUNTRY, {
    url: `/api/orgs/${idOrg}/trends/usersByCountry`,
    meta: { offset },
    query: omitBy({
      idApp,
      country,
      q,
      sort,
      limit,
      offset
    }, isUndefined)
  })
}

export const getUserTasks = ({ idOrg, idUser, token }) => {
  return send(GET_USER_TASKS, {
    url: `/api/orgs/${idOrg}/users/${idUser}/tasks`,
    query: {
      token
    },
    meta: {
      idUser
    }
  })
}

export const updateTaskCompletionStatusInstantAccess = ({ idOrg, idUser, idTask, token, isCompleted }) => {
  return send(UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS, {
    url: `/api/orgs/${idOrg}/users/${idUser}/tasks/${idTask}`,
    method: 'PUT',
    body: {
      isCompleted,
      shouldUpdateLifecycleStatus: true
    },
    query: {
      token
    },
    meta: {
      idTask,
      idUser
    }
  })
}

export const toggleShowOffboardingMessage = ({ isOpen = true, message = '' }) =>
  ({
    type: TOGGLE_SHOW_OFFBOARDING_MESSAGE,
    payload: { isOpen, message }
  })

export const getCustomApps = ({ idOrg, fields = ['id', 'name', 'category', 'url', 'description', 'tags', 'imageUrl', 'addedBy', 'creationTime'] }) => {
  return send(GET_CUSTOM_APPS, {
    url: `/api/orgs/${idOrg}/apps_api`,
    query: {
      fields,
      isCustom: true
    }
  })
}

export const triggerWorkflow = ({ idOrg, idWorkflow, data }) => {
  return send(TRIGGER_WORKFLOW, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/trigger`,
    method: 'POST',
    body: { data: [data] }
  })
}

export const getOrgMatchingRules = ({ idOrg }) => {
  return send(GET_ORG_MATCHING_RULES, {
    url: `/api/orgs/${idOrg}/matchingRules`
  })
}

export const updateOrgMatchingRules = ({ idOrg, rules }) => {
  return send(UPDATE_ORG_MATCHING_RULES, {
    url: `/api/orgs/${idOrg}/matchingRules`,
    method: 'PUT',
    body: {
      rules
    }
  })
}

export const updateOrgMatchingRulesLocally = ({ rules }) => (dispatch) => {
  dispatch({
    type: UPDATE_ORG_MATCHING_RULES_LOCALLY,
    meta: {
      rules
    }
  })
}

export const getContracts = ({ idOrg }) => {
  return send(GET_CONTRACTS, {
    url: `/api/orgs/${idOrg}/contracts`
  })
}

export const getUnmatchedContracts = ({ idOrg }) => {
  return send(GET_UNMATCHED_CONTRACTS, {
    url: `/api/orgs/${idOrg}/contracts`,
    query: { contractsWithoutApp: true }
  })
}

export const getAppContracts = ({ idOrg, idApp }) => {
  return send(GET_APP_CONTRACTS, {
    url: `/api/orgs/${idOrg}/contracts`,
    query: { idApp },
    scopes: [SCOPES.CONTRACTS_READ, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_READ, idOrg, idApp)]
  })
}

export const getContract = ({ idOrg, idContract, idApp = undefined }) => {
  return send(GET_CONTRACT, {
    url: `/api/orgs/${idOrg}/contracts/${idContract}`,
    ...(idApp && { query: { idApp } })
  })
}

export const setDoneContractsAppMatching = (isAppMatchingDone) => (dispatch) => {
  dispatch({
    type: SET_DONE_CONTRACTS_APP_MATCHING,
    meta: { isAppMatchingDone }
  })
}

export const getContractsFields = (params) => {
  const { idOrg, idFields, idApp } = params
  const query = idFields ? { idFields: JSON.stringify(idFields) } : {}
  const scopes = [SCOPES.CONTRACTS_READ]
  if (idApp) {
    query.idApp = idApp
    scopes.push(getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_READ, idOrg, idApp))
  }

  return send(GET_CONTRACTS_FIELDS, {
    url: `/api/orgs/${idOrg}/contracts/fields`,
    query,
    scopes
  })
}

export const updateContractDetails = ({ idOrg, idContract, details = {}, belongsToIdApp = null }) => {
  return send(UPDATE_CONTRACT_DETAILS, {
    url: `/api/orgs/${idOrg}/contracts/${idContract}`,
    method: 'PUT',
    body: { ...details, ...(belongsToIdApp && !details.idApp ? { idApp: belongsToIdApp } : []) },
    meta: {
      idContract,
      details
    }
  })
}

export const setContractsDetails = ({ idOrg, idContracts, details = {}, belongsToIdApp = null }) => {
  return send(UPDATE_CONTRACTS_DETAILS, {
    url: `/api/orgs/${idOrg}/contracts`,
    method: 'PUT',
    body: {
      idContracts,
      ...details,
      ...(belongsToIdApp && !details.idApp ? { idApp: belongsToIdApp } : [])
    },
    meta: {
      idContracts,
      details
    }
  })
}

export const deleteContracts = ({ idOrg, idContracts, idApp }) => {
  return send(DELETE_CONTRACTS, {
    url: `/api/orgs/${idOrg}/contracts`,
    method: 'DELETE',
    body: {
      idContracts,
      ...(idApp ? { idApp } : [])
    },
    meta: {
      idContracts
    }
  })
}

export const createContract = ({ idOrg, details = {}, contractToUpdateStore = {}, isDuplicatedContract = false, flow, idAuditLog }) => {
  return send(CREATE_CONTRACT, {
    url: `/api/orgs/${idOrg}/contracts`,
    method: 'POST',
    body: { ...details, idAuditLog },
    meta: {
      contractToUpdateStore,
      isDuplicatedContract,
      flow: flow ? 'AI magic' : 'Manual'
    }
  })
}

export const getContractsGroups = ({ idOrg, idApp }) => {
  const query = {}
  const scopes = [SCOPES.CONTRACTS_READ]
  if (idApp) {
    query.idApp = idApp
    scopes.push(getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_READ, idOrg, idApp))
  }
  return send(GET_CONTRACTS_GROUPS, {
    url: `/api/orgs/${idOrg}/contracts/groups`,
    query,
    scopes
  })
}

export const editContractDetailsGroup = ({ idOrg, idGroup, label, position }) => {
  return send(EDIT_CONTRACT_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/contracts/groups/${idGroup}`,
    method: 'PUT',
    body: {
      label,
      position
    },
    meta: {
      idGroup,
      label
    }
  })
}

export const addContractDetailsGroup = ({ idOrg, label, position }) => {
  return send(ADD_CONTRACT_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/contracts/groups`,
    method: 'POST',
    body: {
      label,
      position
    },
    meta: {
      label
    }
  })
}

export const deleteContractDetailsGroup = ({ idOrg, idGroup }) => {
  return send(DELETE_CONTRACT_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/contracts/groups/${idGroup}`,
    method: 'DELETE',
    meta: {
      idGroup
    }
  })
}

export const reorderContractDetailsGroups = ({ idOrg, sourceGroup, destinationGroup, changes, reorderedGroups }) => {
  return send(REORDER_CONTRACT_DETAILS_GROUPS, {
    url: `/api/orgs/${idOrg}/contracts/groups/reorder`,
    method: 'PUT',
    body: {
      changes
    },
    meta: {
      sourceGroup,
      destinationGroup,
      reorderedGroups
    }
  })
}

export const updateContractDetailsField = ({ idOrg, idGroup, idField, isShown, name, options, formQuestion }) => {
  return send(UPDATE_CONTRACT_DETAILS_FIELD, {
    url: `/api/orgs/${idOrg}/contracts/fields/${idField}`,
    method: 'PUT',
    meta: {
      idField,
      isShown,
      name
    },
    body: {
      isShown,
      name,
      options,
      idGroup,
      formQuestion
    }
  })
}

export const addContractDetailsField = ({ idOrg, type, idGroup, name, options, formQuestion }) => {
  return send(ADD_CONTRACT_DETAILS_FIELD, {
    url: `/api/orgs/${idOrg}/contracts/fields`,
    method: 'POST',
    body: {
      type,
      idGroup,
      name,
      options,
      formQuestion
    }
  })
}

export const deleteContractDetailsField = ({ idOrg, idField }) => {
  return send(DELETE_CONTRACT_DETAILS_FIELD, {
    url: `/api/orgs/${idOrg}/contracts/fields/${idField}`,
    method: 'DELETE',
    meta: {
      idField
    }
  })
}

export const getAppChargeback = ({ idOrg, idApp }) => {
  return send(GET_APP_CHARGEBACK, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/chargeback`,
    method: 'GET',
    meta: {
      idApp
    }
  })
}

export const getChargebackReport = ({ idOrg }) => {
  return send(GET_CHARGE_BACK_REPORT, {
    url: `/api/orgs/${idOrg}/chargeback/app`,
    method: 'GET'
  })
}

export const toggleShowHiddenWorkflows = ({ showHiddenWorkflows }) => (dispatch) => {
  dispatch({
    type: TOGGLE_SHOW_HIDDEN_WORKFLOWS,
    meta: { showHiddenWorkflows }
  })
}

export const toggleUnsafeMode = ({ unsafeMode }) => (dispatch) => {
  dispatch({
    type: TOGGLE_UNSAFE_MODE,
    meta: { unsafeMode }
  })
}

export const toggleViewOnlyMode = ({ viewOnlyMode }) => (dispatch) => {
  dispatch({
    type: TOGGLE_VIEW_ONLY_MODE,
    meta: { viewOnlyMode }
  })
}

export const getOrgDomains = ({ idOrg }) => {
  return send(GET_ORG_DOMAINS, {
    url: `/api/orgs/${idOrg}/domains`,
    method: 'GET'
  })
}

export const updateOrgDomains = ({ idOrg, domains }) => {
  return send(UPDATE_ORG_DOMAINS, {
    url: `/api/orgs/${idOrg}/domains`,
    method: 'PUT',
    body: {
      domains
    }
  })
}

export const instantAccessLogin = ({ token }) => {
  return send(LOGIN, {
    url: `/api/instantAccess/login`,
    method: 'POST',
    query: {
      token
    }
  })
}

export const getRoles = ({ idOrg }) => {
  return send(GET_ROLES, {
    url: `/api/orgs/${idOrg}/roles`
  })
}

export const deleteRole = ({ idOrg, idRole }) => {
  return send(DELETE_ROLE, {
    url: `/api/orgs/${idOrg}/roles/${idRole}`,
    method: 'DELETE',
    meta: {
      idRole
    }
  })
}

export const createRole = ({ idOrg, name, description, scopes }) => {
  return send(CREATE_ROLE, {
    url: `/api/orgs/${idOrg}/roles`,
    method: 'POST',
    body: {
      name,
      description,
      scopes
    }
  })
}

export const updateRole = ({ idOrg, idRole, name, description, scopes }) => {
  return send(UPDATE_ROLE, {
    url: `/api/orgs/${idOrg}/roles/${idRole}`,
    method: 'PUT',
    body: {
      name,
      description,
      scopes
    },
    meta: {
      idRole,
      name,
      description,
      scopes
    }
  })
}

export const getRole = ({ idRole, idOrg }) => {
  return send(GET_ROLE, {
    url: `/api/orgs/${idOrg}/roles/${idRole}`,
    method: 'GET'
  })
}

export const getScopes = () => {
  return send(GET_SCOPES, {
    url: `/api/roles/scopes`,
    method: 'GET'
  })
}

export const getTeamMembers = ({ idOrg, fields, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, reset, filters = [] }) => {
  return send(GET_TEAM_MEMBERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      limit,
      offset,
      sort,
      q,
      fields: fields.join(','),
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const parseContract = ({ idOrg, idUpload, dateFormat, dryRun }) => {
  return send(CONTRACT_PARSE, {
    url: `/api/orgs/${idOrg}/contracts/import`,
    method: 'POST',
    body: {
      idUpload, dateFormat, dryRun
    }
  })
}

export const getParsedContract = ({ idOrg, idContractParsing }) => {
  return send(GET_CONTRACT_PARSE, {
    url: `/api/orgs/${idOrg}/contracts/import/${idContractParsing}`,
    method: 'GET'
  })
}

export const getBrowserUsersCount = ({ idOrg, browser }) => {
  return send(GET_BROWSER_COUNT, {
    url: `/api/orgs/${idOrg}/users`,
    query: {
      ...currentUsersBaseFilters,
      ...activeUsersFilter,
      withoutContent: true,
      filters: JSON.stringify([{ key: 'browser', op: ops.equals, value: browser }, isParentUserFilter].concat(activeAppsFilter)),
      advancedFilters: JSON.stringify({ or: [extensionUsageReportForPeriod(thirtyDays - 1), reportDateNotSerFilter] })
    },
    meta: {
      browser
    }
  })
}

export const getExtensionReportUsers = ({ idOrg, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, q, fields = ['browser', 'lastReportedTime', 'firstName', 'lastName', 'email', 'isExternal', 'photoUrl', 'lifecycleStatus'], reset }) => {
  return send(GET_EXTENSION_REPORT_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      ...currentUsersBaseFilters,
      sort,
      fields,
      filters: JSON.stringify([activeAppsFilter, isParentUserFilter]),
      limit,
      offset,
      q
    }, isUndefined),
    meta: {
      reset
    }
  })
}

const employeeFilter = { key: 'role', op: ops.equals, value: 'employee' }
const activeUserStatusFilter = { key: 'status', op: ops.equals, value: USER_STATUS.ACTIVE }

export const getCatalogUsers = ({ idOrg, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, sort, fields = ['firstName', 'lastName', 'email', 'photoUrl'], reset }) => {
  const filters = [activeUserStatusFilter, employeeFilter]

  return send(GET_CATALOG_USERS, {
    url: `/api/orgs/${idOrg}/users`,
    query: omitBy({
      isDeletedInIdentitySources: false,
      sort,
      fields,
      filters: JSON.stringify(filters),
      limit,
      offset
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const createUserReporting = ({ idOrg, actionType, creator, email, firstName, lastName }) => {
  return send(ADD_USER_REPORTING, {
    url: `/api/orgs/${idOrg}/users`,
    method: 'POST',
    body: {
      email,
      firstName,
      lastName
    },
    meta: {
      actionType,
      creator,
      email,
      firstName,
      lastName
    }
  })
}

export const getComparisonSSOSourcesAccounts = ({ idOrg }) => {
  const idApps = [
    getSourceByType('azure_ad').idApp,
    getSourceByType('okta').idApp,
    getSourceByType('onelogin').idApp,
    getSourceByType('jumpCloud').idApp,
    getSourceByType('google').idApp
  ]

  return send(APP_COMPARISON_GET_SSO_SOURCES_ACCOUNTS, {
    url: `/api/orgs/${idOrg}/appAccounts`,
    query: {
      idApps: JSON.stringify(idApps)
    }
  })
}

export const toggleWorkflowAuditLogs = ({ isOpen, idWorkflow = null, workflowName = '', isAppCatalogPoliciesView = false }) => {
  let meta = {}
  let payload = {}

  if (isOpen) {
    payload = { idWorkflow, workflowName, isAppCatalogPoliciesView }
  } else {
    meta = { idWorkflow, workflowName, isAppCatalogPoliciesView }
  }

  return {
    type: TOGGLE_WORKFLOW_AUDIT_LOGS,
    payload: { isOpen, ...payload },
    meta
  }
}

export const getCurrencies = () => {
  return send(GET_CURRENCIES, {
    url: `/api/currencies`
  })
}

export const getSecrets = ({ idOrg }) => {
  return send(GET_SECRETS, {
    url: `/api/orgs/${idOrg}/secrets`
  })
}

export const createSecret = ({ idOrg, name, value }) => {
  return send(CREATE_SECRET, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/secrets`,
    body: {
      name,
      value
    }
  })
}

export const deleteSecret = ({ idOrg, idOrgSecret }) => {
  return send(DELETE_SECRET, {
    method: 'DELETE',
    url: `/api/orgs/${idOrg}/secrets/${idOrgSecret}`
  })
}

export const updateSecret = ({ idOrg, idOrgSecret, value }) => {
  return send(UPDATE_SECRET, {
    method: 'PUT',
    url: `/api/orgs/${idOrg}/secrets/${idOrgSecret}`,
    body: {
      value
    }
  })
}
