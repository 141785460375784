import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { AppIcon, ButtonType, ExpandableSection, Counter, AlertBox, AlertBoxType } from '@toriihq/design-system'
import Table from '../../table'
import { css } from 'glamor'
import AppDetails from '../../appDetails'
import colors from '../../../shared/style/colors'
import ConfiguredMessage from './configuredMessage'
import AppOffboardingActionButtons from './appOffobardingActionButtons'
import * as Style from '../styles'
import Confirmation from '@components/confirmation'

const MAX_APPS_ICONS = 20
const APP_ICONS_HEIGHT = 32

const CSS = {
  container: css({
    border: `1px solid ${colors.grey3}`,
    borderRadius: '4px',
    padding: '24px 21px'
  }),
  button: css({
    backgroundColor: 'transparent',
    padding: '2px 13px',
    ':disabled': {
      opacity: 0.5,
      color: colors.grey2
    },
    border: '1px solid transparent'
  }),
  actions: css({
    display: 'flex',
    alignItems: 'center',
    float: 'right'
  }),
  configuredMessage: css({
    marginRight: '10px'
  }),
  menu: css({
    marginLeft: '10px'
  }),
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  buttonIconStyle: css({
    fontSize: 20,
    marginRight: 0
  })
}

class Configured extends React.Component {
  state = { showRemoveConfigurationConfirmation: false, removeConfigurationInfo: {}, isOpen: false }
  columns = this.getColumns()

  componentDidMount () {
    const { getWorkflowsActionsConfig, getWorkflowsTriggersConfig, idOrg } = this.props
    getWorkflowsActionsConfig(idOrg)
    getWorkflowsTriggersConfig()
  }

  getColumns () {
    const { idUsers, refreshDataFunc, showAlertBox } = this.props

    return [
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 270,
        className: CSS.nameAndIcon.toString(),
        Cell: ({ row: { idApp, category, imageUrl, appAndAccountName } }) => {
          return <AppDetails
            id={idApp}
            name={appAndAccountName}
            category={category}
            imageUrl={imageUrl}
            component='Offboarding settings'
          />
        }
      },
      {
        id: 'action',
        Header: '',
        sortable: false,
        Cell: ({ row }) => {
          const app = { ...row, id: row.idApp }
          return <div {...CSS.actions}>
            <div {...CSS.configuredMessage}>
              <ConfiguredMessage actions={app.actions} />
            </div>
            <AppOffboardingActionButtons app={app} idUsers={idUsers} hoverClass='rt-tr:hover' refreshDataFunc={refreshDataFunc} showAlertBox={showAlertBox} />
          </div>
        }
      },
      {
        accessor: 'actions',
        show: false
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        id: 'idApp',
        accessor: 'id',
        show: false
      },
      {
        accessor: 'idAppAccount',
        show: false
      },
      {
        accessor: 'workflowId',
        show: false
      },
      {
        accessor: 'appAndAccountName',
        show: false
      }
    ]
  }

  alertBoxContent = () => {
    const { alertBoxContent } = this.props

    if (!alertBoxContent) {
      return null
    }

    return <AlertBox type={AlertBoxType.INFORMATIVE} description={alertBoxContent} />
  }

  bottomSlot = () => {
    const { isOpen } = this.state
    const { apps, forceOpen, maxAppIcons } = this.props

    const hasData = apps.length > 0
    if (!hasData) {
      return null
    }

    if (isOpen || forceOpen) {
      return this.alertBoxContent()
    }

    const renderedApps = apps.slice(0, maxAppIcons).map(app =>
      <Style.AppIconsWrapper>
        <AppIcon key={`${app.id}-${app.idAppAccount}`} appName={app.name} appImageUrl={app.imageUrl} />
      </Style.AppIconsWrapper>
    )

    return <Style.BottomSlotWrapper>
      {this.alertBoxContent()}
      <Style.AppIconsWrapper>{renderedApps} {apps.length > maxAppIcons ? `+${apps.length - maxAppIcons}` : null}</Style.AppIconsWrapper>
    </Style.BottomSlotWrapper>
  }

  render () {
    const { header, apps, tableKey, loading, startAsOpen, forceOpen, tableWrapperId = 'configuredTableWrapper', headerHeight } = this.props
    const { showRemoveConfigurationConfirmation, removeConfigurationInfo, isOpen } = this.state
    const hasData = apps.length > 0
    const headerElement = <div>{header} <Counter value={loading ? 0 : apps.length} /></div>

    return <Fragment>
      <ExpandableSection
        key={tableWrapperId}
        isOpen={!hasData ? false : (isOpen || startAsOpen || forceOpen)}
        bottomSlot={this.bottomSlot()}
        title={headerElement}
        headerHeight={(isOpen || forceOpen) ? headerHeight - APP_ICONS_HEIGHT : headerHeight}
        disabled={!hasData}
        onToggle={isOpen => { this.setState({ isOpen }) }}
        maxHeight={450}
      >
        <Table
          tableKey={tableKey}
          data={apps}
          columns={this.columns}
          scrollObjectId={tableWrapperId}
          style={{ minHeight: '100px' }}
          tableHeaderStyle={{ padding: 0, paddingTop: 8 }}
          forceShowNumberOfResults
        />
      </ExpandableSection>
      {showRemoveConfigurationConfirmation && <Confirmation
        isOpen={showRemoveConfigurationConfirmation}
        header='Delete configuration?'
        text={<div>Are you sure that you want to delete <strong>{removeConfigurationInfo.appName}</strong>'s configuration?</div>}
        confirmText='Delete'
        confirm={() => this.removeConfiguration(removeConfigurationInfo)}
        declineText='Cancel'
        decline={this.closeConfirmation}
        mainButtonType={ButtonType.destructive}
        close={this.closeConfirmation}
      />}
    </Fragment>
  }
}

Configured.propTypes = {
  idOrg: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  apps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    pendingOffboardingAmount: PropTypes.number
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  tableKey: PropTypes.string.isRequired,
  startAsOpen: PropTypes.bool,
  forceOpen: PropTypes.bool,
  showAlertBox: PropTypes.bool,
  maxAppIcons: PropTypes.number
}

Configured.defaultProps = {
  startAsOpen: false,
  forceOpen: false,
  showAlertBox: false,
  maxAppIcons: MAX_APPS_ICONS
}

export default Configured
