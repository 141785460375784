import React from 'react'
import RelativeTeamLink from '@components/relativeTeamLink'
import { AlertBox, AlertBoxType, Link, Spacer } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'
import { ReportNotificationsForSourceProps } from './types'

const sendAnalytics = (isBlockThirdPartyAppsEnabled: boolean) => {
  Analytics.track('Click on reconfigure-in-settings-link', {
    'Configuration state': isBlockThirdPartyAppsEnabled ? 'Revoked (the switch is ON)' : 'Not revoked (the switch is off)'
  })
}

const ReportNotificationsForSource = ({
  isBlockThirdPartyAppsEnabled,
  connectedServices,
  connectedServicesWithBlockAccess,
  shouldDisplayBlockAccessNotification
}: ReportNotificationsForSourceProps) => {
  if (!shouldDisplayBlockAccessNotification) {
    return <></>
  }

  const reconfigureButton = <Spacer left={'space-050'}>
    <RelativeTeamLink to={'/settings'} onClick={() => sendAnalytics(isBlockThirdPartyAppsEnabled)}>
      <Link>Reconfigure in Settings</Link>
    </RelativeTeamLink>
  </Spacer>

  if (!connectedServices.length || connectedServices.length > connectedServicesWithBlockAccess.length || !isBlockThirdPartyAppsEnabled) {
    return <AlertBox
      type={AlertBoxType.NOTICE}
      description={
        <>
          <span>
            Closed apps have access to sensitive organizational data. It's recommended to revoke permissions from these apps.
          </span>
          {reconfigureButton}
        </>
      }
    />
  }

  return <AlertBox
    type={AlertBoxType.INFORMATIVE}
    description={
      <>
        <span>
          Permissions for all apps in the "Closed" state have been revoked.
        </span>
        {reconfigureButton}
      </>
    }
  />
}

export default ReportNotificationsForSource
