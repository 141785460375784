import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Spacer, AppIcon, RadioButton, Tooltip } from '@toriihq/design-system'
import { SCOPES } from '../../../constants'
import EnableFor from '../../enableFor'
import ToriiSelect from '../../select'

const CSS = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  }),
  appsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px -5px 4px -5px'
  }),
  title: css({
    fontWeight: 600,
    marginRight: 10,
    height: 20
  }),
  option: css({
    height: 20
  })
}

const selection = [
  { type: 'all', label: 'All apps' },
  { type: 'exclude', label: 'All apps except for' },
  { type: 'include', label: 'Just for the following apps' }
]

class SelectApps extends React.Component {
  onChange = (selection) => {
    const { onChange, value } = this.props
    onChange({ value: (value.value || []).concat(selection.id), type: value.type })
  }

  onTypeChange = (type) => {
    const { onChange, value } = this.props
    onChange({ value: value.value || [], type })
  }

  onRemove = (id) => {
    const { onChange, value } = this.props
    onChange({ ...value, value: value.value.filter(v => v !== id) })
  }

  render () {
    const { options, value, overrideStyle } = this.props
    const { type, value: apps = [] } = value
    const notSelectedOptions = options.filter(o => !apps.includes(o.id))
    const selectedOptions = options.filter(o => apps.includes(o.id))

    return (
      <div {...css(CSS.container, overrideStyle)}>
        <span {...CSS.title}>Apply for</span>
        {
          selection.map((s, i) => <span {...CSS.option} key={i}>
            <Spacer right={'space-600'}>
              <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                <RadioButton checked={type === s.type} onChange={() => this.onTypeChange(s.type)} label={s.label} labelSize='small' />
              </EnableFor>
            </Spacer>
          </span>)
        }
        {
          type !== 'all' &&
          <>
            <div {...CSS.appsContainer}>
              {
                selectedOptions.map((v) => {
                  return <Tooltip
                    key={v.id}
                    placement='top'
                    label={`Click to remove ${v.name}`}
                  >
                    <Spacer right={'space-050'} bottom={'space-100'} left={'space-050'}>
                      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                        <div onClick={() => this.onRemove(v.id)}>
                          <AppIcon appImageUrl={v.imageUrl} />
                        </div>
                      </EnableFor>
                    </Spacer>
                  </Tooltip>
                })
              }
            </div>
            <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
              <ToriiSelect
                options={notSelectedOptions}
                placeholder='+ Add app'
                onChange={this.onChange}
                clearable
                searchable
                labelKey='name'
                openOnFocus
              />
            </EnableFor>
          </>
        }
      </div>
    )
  }
}

SelectApps.propTypes = {
  overrideStyle: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func
}

export default SelectApps
