import analytics from '@root/helpers/analytics'

const Analytics = {
  toggleIntegrationConnection: (isOpened, appName, idApp, dialogVersion, connectionInterface) => {
    const props = {
      'App name': `${appName} (${idApp})`,
      'Dialog version': dialogVersion,
      'Connection interface': connectionInterface
    }
    analytics.track(`${isOpened ? 'Open' : 'Close'} integration-connection-dialog`, props)
  },
  clickTestIntegration: (appName, idApp, connectionInterface) => {
    const props = {
      'App name': `${appName} (${idApp})`,
      'Connection interface': connectionInterface
    }
    analytics.track('Click on  continue-to-test-button', props)
  },
  clickOnConnectAfterTestIntegration: (testResults, appName, idApp, connectionInterface) => {
    const props = {
      'App name': `${appName} (${idApp})`,
      'Connection interface': connectionInterface,
      'Test Results': testResults
    }
    analytics.track('Click on connect-integration-after-test-button', props)
  },
  closeAfterSeeingTestResults: (testResults, appName, idApp, connectionInterface) => {
    const props = {
      'App name': `${appName} (${idApp})`,
      'Connection interface': connectionInterface,
      'Test Results': testResults
    }
    analytics.track('Close integration-connection-step2-dialog', props)
  },
  backClick: (testResults, appName, idApp, connectionInterface) => {
    const props = {
      'App name': `${appName} (${idApp})`,
      'Connection interface': connectionInterface,
      'Test Results': testResults
    }
    analytics.track('Click on back-button-after-test-step2-dialog', props)
  }
}

export default Analytics
