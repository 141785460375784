import { fontSize, fontWeight } from '@root/shared/style/sizes'
import { link } from '@root/shared/style/textsStyled'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal};
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  };
`

export const CatalogLink = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: flex-start;
`

export const Link = styled.a`
  margin-right: 10px;
  margin-left: 10px;
  ${link}
  :hover {
    text-decoration: underline
  }
`
export const ToggleContainer = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: flex-end
`

export const IconWrapper = styled.div`
  cursor: ${({ showHandCursor }) => (showHandCursor ? 'pointer' : 'auto')};
`
