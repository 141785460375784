import styled from 'styled-components'
import { fieldsContainer } from '../styles'

export const CustomScheduleEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DaysAndTimeContainer = styled.div`
  display: flex;
  gap: 24px;
  
  > * {
    flex: 1;
  }
`

export const SelectTimezoneContainer = styled.div`
  margin-top: 16px;
 `

export const FieldsContainer = styled.div`
  ${fieldsContainer}
  margin-bottom: 8px;
`

export const ToggleContainer = styled.div`
  margin-bottom: 16px;
`
