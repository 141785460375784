import React from 'react'
import { Tooltip } from '@toriihq/design-system'

const withTooltip = (WrappedComponent, tooltipProps = {}) => (props) => {
  const defaultProps = { position: 'top', label: '' }
  const finalProps = { ...defaultProps, ...tooltipProps }
  return (
    <Tooltip {...finalProps}>
      <WrappedComponent {...props} />
    </Tooltip>
  )
}

export default withTooltip
