import { getSourceByType } from '../sourcesConfig'
import { getDisplayName } from '@lenses/users'

const appToolTip = ({ appName, source, addedBy }) => {
  if (source === 'manual') {
    if (addedBy) {
      return `Manually added by ${getDisplayName(addedBy)}`
    }

    return ``
  }

  const sourceConfig = getSourceByType(source)
  if (sourceConfig.appsTooltip) {
    return sourceConfig.appsTooltip({ appName, source })
  }

  return `${sourceConfig.name} was connected to Torii`
}

export default appToolTip
