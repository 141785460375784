import colors from '@shared/style/colors'
import styled from 'styled-components'
import { Tooltip } from '@toriihq/design-system'

export const GroupHeader = styled.div`
  justify-content: center;
  align-content: center;
  height: 60px;
  padding: 21px 20px;
  background-color: ${props => props.setBackground ? colors.lightBlue3 : null};
  border-bottom: 1px solid ${colors.grey3};
  text-align: center;
  color: ${colors.grey1};
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`

export const ListItem = styled.li`
  text-align: left;
`

export const StyledTooltip = styled(Tooltip)`
  padding: 0 20px;
  justify-content: center;
  text-align: center;
`

export const DisappearingButton = styled.div`
  padding: 0;
`
