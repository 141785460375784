import { INTEGRATION_TYPE } from '@shared/types'
import { EMPTY_OBJECT } from '@root/constants'
import { OpenConnectServicePopupParams, IntegrationTypeText } from '@shared/services/openConnectServicePopup/types'
import { analyticsTrackConnectAppAccountClick } from '@shared/services/openConnectServicePopup/analytics'

const openConnectServicePopup = ({
  idApp,
  appName,
  integrationType,
  source,
  supportsMultipleAccounts,
  calledFrom,
  dispatchers: {
    toggleConnectSource,
    toggleConnectService,
    toggleConnectSCIMService
  },
  connectedServiceData,
  isReconnect,
  hideConnectByLink
}: OpenConnectServicePopupParams): void => {
  const { idAppAccount, idAppToken } = connectedServiceData || EMPTY_OBJECT
  const showReconnectAlertInfo = Boolean(isReconnect && idAppAccount && supportsMultipleAccounts)

  const commonParams = {
    idAppAccount,
    idAppToken,
    isReconnect,
    showReconnectAlertInfo,
    serviceName: appName,
    hideConnectByLink
  }

  let integrationTypeText: IntegrationTypeText

  switch (integrationType) {
    case INTEGRATION_TYPE.CONFIGURED_OAUTH:
    case INTEGRATION_TYPE.OAUTH:
    case INTEGRATION_TYPE.TOKENS:
      toggleConnectSource({
        isConnectSourceOpen: true,
        sourceId: source,
        sourceIdApp: idApp,
        ...commonParams
      })
      integrationTypeText = 'Native'
      break
    case INTEGRATION_TYPE.TORII_BOT:
      toggleConnectService({
        isConnectServiceOpen: true,
        idApp,
        appName,
        ...commonParams
      })
      integrationTypeText = 'Toriibot'
      break
    case INTEGRATION_TYPE.SCIM:
      toggleConnectSCIMService({
        isOpen: true,
        idApp,
        ...commonParams
      })
      integrationTypeText = 'Native (SCIM)'
      break
  }

  analyticsTrackConnectAppAccountClick({
    appName,
    calledFrom,
    integrationTypeText
  })
}

export default openConnectServicePopup
