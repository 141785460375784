import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;  
  padding-right: 16px;
`
