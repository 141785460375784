import capitalize from 'lodash/capitalize'

const validate = ({ value, shareStatus, id, name }, { addSharedStatusField, allOptions }) => {
  const errors = {}

  if (!value) {
    errors.value = 'Required'
  }

  if (addSharedStatusField && !shareStatus) {
    errors.shareStatus = 'Required'
  }

  const existingOption = allOptions.find(option => option.label === value && option.id !== id)
  if (existingOption) {
    errors.value = `${capitalize(value)} name already exists`
  }

  return errors
}

export default validate
