import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'

export const getUsageTrends = createSelector(
  state => get(state, ['trends', 'usage'], {}),
  identity
)

export const getActiveUsersTrends = createSelector(
  state => get(state, ['trends', 'activeUsers'], {}),
  identity
)

export const isLoadingUsageTrends = createSelector(
  state => get(state, ['trends', 'loadingSingleAppUsageTrend']),
  identity
)
export const isLoadingActiveUsersTrends = createSelector(
  state => get(state, ['trends', 'loadingSingleAppActiveUsersTrend']),
  identity
)

export const getUnderutilizedLicensesTrends = createSelector(
  state => get(state, ['trends', 'underutilizedLicenses', 'apps']),
  identity
)

export const isLoadingUnderutilizedLicensesTrends = createSelector(
  state => get(state, ['trends', 'underutilizedLicenses', 'loading']),
  identity
)

export const isLoadedUnderutilizedLicensesTrends = createSelector(
  state => get(state, ['trends', 'underutilizedLicenses', 'isLoaded']),
  identity
)

export const getUsersDistributionTrends = createSelector(
  state => get(state, ['trends', 'usersDistribution', 'countries']),
  identity
)

export const isLoadingUsersDistributionTrends = createSelector(
  state => get(state, ['trends', 'usersDistribution', 'loading']),
  identity
)

export const isLoadedUsersDistributionTrends = createSelector(
  state => get(state, ['trends', 'usersDistribution', 'isLoaded']),
  identity
)

export const getUsersByCountrySelector = createSelector(
  state => state.trends.usersDistribution.usersByCountry,
  identity
)
