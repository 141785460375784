import { Parser } from 'json2csv'
import isArray from 'lodash/isArray'
import { toSafeData } from './toSafeData'

export const generateCSV = (data: object[], columnNames: string[]): string => {
  try {
    const parser = new Parser({ fields: columnNames })
    const transformed = transformItemsToObjects(data, columnNames)
    const safeData = toSafeData(transformed)
    return parser.parse(safeData)
  } catch (err) {
    return (err as Error).message
  }
}

const transformItemsToObjects = (data: object[], fields: string[]): object[] => {
  return data.map((item) => {
    if (!isArray(item)) {
      return item
    }

    return item.reduce(
      (res, cur, index) => ({ ...res, [fields[index]]: cur }),
      {}
    )
  })
}

const downloadFile = (content: string, fileName: string, mimeType: string): void => {
  const a = document.createElement('a')
  mimeType = mimeType || 'application/octet-stream'

  if (window.URL && 'download' in a) {
    a.href = window.URL.createObjectURL(new window.Blob([content], {
      type: mimeType
    }))
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content) // only this mime type is supported
  }
}

const forceCommaSeparator = 'sep=,\n'

export const exportCSV = (
  filename: string,
  data: object[],
  columnNames: string[],
  options?: {
    disableCommaSeparator: boolean
  }
): void => {
  const { disableCommaSeparator = false } = options || {}
  const csv = generateCSV(data, columnNames)
  downloadFile(`${disableCommaSeparator ? '' : forceCommaSeparator}${csv}`, filename, 'text/csv;encoding:utf-8')
}

export default exportCSV
