import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { IdWorkflowUiNode } from '@shared/workflows/actions/types'
import { defaultState } from './consts'
import { WorkflowEditorState } from './types'
import { useLocation, useParams } from 'react-router-dom'

interface Props {
  isAppCatalogPoliciesView: boolean
  isRequestNewAppPoliciesView: boolean
  children: ReactNode
  idAudit: number | null
}

export const WorkflowEditorContext = createContext<WorkflowEditorState>(defaultState)

export const WorkflowEditorProvider = ({
  isAppCatalogPoliciesView = false,
  isRequestNewAppPoliciesView = false,
  idAudit = null,
  children
}: Props) => {
  const location = useLocation()
  const { idSelectedNode } = useParams()
  const [idNewNode, setIdNewNode] = useState<IdWorkflowUiNode | null>(null)
  const [isNewNodeSelected, setIsNewNodeSelected] = useState(false)

  useEffect(() => {
    if (location.state && location.state.idNewNode) {
      setIdNewNode(location.state.idNewNode)
    }
  }, [location])

  useEffect(() => {
    const updateNewNodeSelectionState = () => {
      const isNewNodeSelectedNow = idNewNode && idSelectedNode === idNewNode
      const isNewNodeDeselectedAfterBeingSelected =
        !isNewNodeSelectedNow && isNewNodeSelected

      if (isNewNodeSelectedNow) {
        setIsNewNodeSelected(true)
      } else if (isNewNodeDeselectedAfterBeingSelected) {
        setIsNewNodeSelected(false)
        setIdNewNode(null)
      }
    }
    updateNewNodeSelectionState()
  }, [idNewNode, idSelectedNode, isNewNodeSelected])

  const state: WorkflowEditorState = {
    idNewNode,
    setIdNewNode,
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView,
    isPreviewMode: idAudit !== null,
    idAudit
  }

  return (
    <WorkflowEditorContext.Provider value={state}>
      {children}
    </WorkflowEditorContext.Provider>
  )
}

export const useWorkflowEditorContext = () => useContext(WorkflowEditorContext)
