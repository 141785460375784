import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontSize } from '@shared/style/sizes'
import texts from '@shared/style/texts'

export const PermissionContainer = css({
  color: colors.black
})

export const Input = css({
  flex: 1
})

export const Fields = css({
  display: 'flex',
  flexDirection: 'column',
  height: 270
})

export const ErrorText = css({
  color: colors.error,
  fontSize: fontSize.small,
  paddingBottom: 10
})

export const SCIMFieldsLabel = css(texts.headers.small, {
  textTransform: 'uppercase',
  margin: '16px 0'
})
