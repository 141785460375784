import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { Field } from 'react-final-form'
import capitalize from 'lodash/capitalize'
import FormGroup from '../../form/formGroup'
import Input from '../../form/input'
import ToriiSelect from '../../select'
import { SHARE_STATUS_OPTIONS, SCOPES } from '../../../constants'
import EnableFor from '../../enableFor'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2/popupContext'

const CSS = {
  cancelButton: css({
    marginRight: 15,
    width: 137,
    lineHeight: '30px'
  }),
  actions: css({
    borderTop: `1px solid ${colors.border}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `30px 20px 20px 40px`,
    marginTop: '10px'
  }),
  form: css({
    ' input[type="text"]': {
      border: `1px solid ${colors.border}`
    }
  })
}
class AddOrEditForm extends React.Component {
  input = {}

  componentDidMount () {
    this.context.submitAction.current = this.onSubmit
  }

  renderTextField = (props) => {
    const { meta, extraInfo, input, label } = props

    return (
      <FormGroup label={label} error={meta.touched && meta.error}>
        <Input
          {...input}
          ref={(c) => (this.input[input.name] = c)}
          overrideStyle={css({ width: '100%' })}
          autoFocus
        />
        {extraInfo}
      </FormGroup>)
  }

  renderSelectField = (props) => {
    const { meta, extraInfo, input, label, options, disabled } = props

    return (
      <FormGroup label={label} error={meta.touched && meta.error}>
        <ToriiSelect
          {...input}
          ref={(c) => (this.input[input.name] = c)}
          options={options}
          disabled={disabled}
          name='selectSharing'
        />
        {extraInfo}
      </FormGroup>)
  }

  onSubmit = ({ value, shareStatus }) => {
    const { onAddOrEditPopupSubmit } = this.props

    return onAddOrEditPopupSubmit({ label: value, shareStatus: shareStatus.value })
  }

  render () {
    const { name, renderFooter, addSharedStatusField } = this.props
    const { handleSubmit } = this.props

    return <form {...CSS.form} onSubmit={handleSubmit}>
      <div>
        <Field
          name='value'
          label={`${capitalize(name)} Name`}
          component={this.renderTextField}
        />

        { addSharedStatusField && <EnableFor scopes={[SCOPES.PUBLIC_VIEWS_WRITE]}><Field
          name='shareStatus'
          label='Sharing'
          component={this.renderSelectField}
          options={SHARE_STATUS_OPTIONS}
        /></EnableFor> }

        { renderFooter && renderFooter() }
      </div>
    </form>
  }
}

AddOrEditForm.contextType = ToriiPopupContext

export default AddOrEditForm
