import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Tooltip } from '@toriihq/design-system'
import { getSourceByType } from '../../sourcesConfig'
import userIcon from './userIcon.svg'

const SourceIcon = ({ sourceType, tooltipText, imgStyle, disableTooltip, isAppSource }) => {
  const source = getSourceByType(sourceType)
  if (!source) {
    return null
  }

  const icon = isAppSource && sourceType === 'manual' ? userIcon : source.icon.grayscale

  return (
    <Tooltip
      label={tooltipText || source.tooltip}
      hide={disableTooltip}
      maxWidth={200}
    >
      <img alt={source.name} src={icon} {...css(imgStyle)} />
    </Tooltip>
  )
}

SourceIcon.propTypes = {
  sourceType: PropTypes.string,
  tooltipText: PropTypes.string,
  disableTooltip: PropTypes.bool,
  isAppSource: PropTypes.bool
}

SourceIcon.defaultProps = {
  imgStyle: { height: '15', margin: '0 2px' },
  disableTooltip: false,
  isAppSource: false
}

export default SourceIcon
