import Analytics from '@helpers/analytics'

interface AnalyticsParams {
  menuItem: string,
  buttonLabel: string,
  actionLocation: 'Last' | 'In the middle' | 'Add tag dialog',
  triggerType: string
  idWorkflow: number
  entityType: 'trigger' | 'action'
  buttonLocation: 'footer (Close)' | 'Top right corner (X)'
  actionName: string
  stepName: string
  currentNumberOfBranches: number
  numberOfDescendants: number
  workflowName: string
  workflowNewName: string
  continueOnError: boolean
  openedFrom: string
  isOpen: boolean
  sectionLabel: string
}

const analyticsKeys = {
  BUTTON_LABEL: 'Button Label',
  ACTION_LOCATION: 'Action Location',
  TRIGGER_NAME: 'Trigger name',
  WORKFLOW_ID: 'Workflow ID',
  ACTION_NAME: 'Action name',
  OPEN_FROM: 'Open from',
  RESULT: 'Result',
  SECTION_LABEL: 'Section label'
}

const create = {
  click: () => {
    Analytics.track('Click on new-workflow-button')
  },
  selectCreationOption: ({ menuItem }: Pick<AnalyticsParams, 'menuItem'>) => {
    Analytics.track(
      'Select new-workflow-creation-option', {
        'Menu item': menuItem
      })
  },
  selectedTrigger: ({ triggerType }: Pick<AnalyticsParams, 'triggerType'>) => {
    Analytics.track('Click on Trigger-button', {
      [analyticsKeys.TRIGGER_NAME]: triggerType
    })
  },
  created: ({ triggerType }: Pick<AnalyticsParams, 'triggerType'>) => {
    Analytics.track('Click on next-button-after-trigger-selection', {
      [analyticsKeys.TRIGGER_NAME]: triggerType
    })
  }
}

const action = {
  delete: {
    popupOpened: ({ idWorkflow, actionName }: Pick<AnalyticsParams, 'idWorkflow' | 'actionName'>) => {
      Analytics.track('Clicked to open popup: delete workflow action', {
        [analyticsKeys.ACTION_NAME]: actionName,
        [analyticsKeys.WORKFLOW_ID]: idWorkflow
      })
    },
    popupClosed: ({ idWorkflow, actionName }: Pick<AnalyticsParams, 'idWorkflow' | 'actionName'>) => {
      Analytics.track('Closed popup: delete workflow action', {
        [analyticsKeys.ACTION_NAME]: actionName,
        [analyticsKeys.WORKFLOW_ID]: idWorkflow
      })
    },
    deleted: ({ idWorkflow, workflowName, actionName }: Pick<AnalyticsParams, 'idWorkflow' | 'workflowName' | 'actionName'>) => {
      Analytics.track('Deleted workflow action', {
        [analyticsKeys.WORKFLOW_ID]: idWorkflow,
        'Workflow name': workflowName,
        [analyticsKeys.ACTION_NAME]: actionName
      })
    }
  },
  add: ({ actionLocation }: Pick<AnalyticsParams, 'actionLocation'>) => {
    Analytics.track('Add action', {
      [analyticsKeys.ACTION_NAME]: actionLocation
    })
  },
  replace: {
    replaced: () => {
      Analytics.track('Replace action')
    },
    popupClosed: ({ buttonLabel }: Pick<AnalyticsParams, 'buttonLabel'>) => {
      Analytics.track('Close replace-action-dialog', {
        [analyticsKeys.BUTTON_LABEL]: buttonLabel
      })
    }
  },
  select: ({ actionName }: Pick<AnalyticsParams, 'actionName'>) => {
    Analytics.track('Select action', {
      [analyticsKeys.ACTION_NAME]: actionName
    })
  },
  navigateToActionConfiguration: ({ actionName, stepName }: Pick<AnalyticsParams, 'actionName' | 'stepName'>) => {
    Analytics.track('Navigate to action-configuration-step', {
      [analyticsKeys.ACTION_NAME]: actionName,
      'Step name': stepName
    })
  },
  clickOnNext: ({ actionName }: Pick<AnalyticsParams, 'actionName'>) => {
    Analytics.track('Click on Next-button-in-action', {
      [analyticsKeys.ACTION_NAME]: actionName
    })
  },
  closePanel: ({ buttonLocation, triggerType, actionName }: Pick<AnalyticsParams, 'buttonLocation' | 'triggerType' | 'actionName'>) => {
    Analytics.track('Close properties-panel', {
      [analyticsKeys.TRIGGER_NAME]: triggerType,
      [analyticsKeys.ACTION_NAME]: actionName,
      'Entity type': 'action',
      'Button location': buttonLocation
    })
  },
  toggleContinueOnError: ({ idWorkflow, continueOnError }: Pick<AnalyticsParams, 'idWorkflow' | 'continueOnError'>) => {
    Analytics.track('Updated action continue on error', {
      [analyticsKeys.WORKFLOW_ID]: idWorkflow,
      continueOnError
    })
  }
}

const branch = {
  add: ({ triggerType, currentNumberOfBranches }: Pick<AnalyticsParams, 'triggerType' | 'currentNumberOfBranches'>) => {
    Analytics.track('Add branch', {
      [analyticsKeys.TRIGGER_NAME]: triggerType,
      '# branches': currentNumberOfBranches
    })
  },
  delete: {
    popupOpened: ({ triggerType }: Pick<AnalyticsParams, 'triggerType'>) => {
      Analytics.track('Click on delete-branch-button-in-the-panel', {
        [analyticsKeys.TRIGGER_NAME]: triggerType
      })
    },
    deleted: ({ triggerType, numberOfDescendants }: Pick<AnalyticsParams, 'triggerType' | 'numberOfDescendants'>) => {
      Analytics.track('Delete branch', {
        [analyticsKeys.TRIGGER_NAME]: triggerType,
        '# actions': numberOfDescendants
      })
    },
    popupClosed: ({ buttonLabel }: Pick<AnalyticsParams, 'buttonLabel'>) => {
      Analytics.track('Close delete-branch-dialog', {
        [analyticsKeys.BUTTON_LABEL]: buttonLabel
      })
    }
  }
}

const trigger = {
  clickOnSettingsLink: ({ triggerType }: Pick<AnalyticsParams, 'triggerType'>) => {
    Analytics.track('Click on settings-link', {
      [analyticsKeys.TRIGGER_NAME]: triggerType
    })
  },
  closePanel: ({ buttonLocation, triggerType }: Pick<AnalyticsParams, 'buttonLocation' | 'triggerType'>) => {
    Analytics.track('Close properties-panel', {
      [analyticsKeys.TRIGGER_NAME]: triggerType,
      'Entity type': 'trigger',
      'Button location': buttonLocation
    })
  }
}

const updateName = ({ idWorkflow, workflowName, workflowNewName }: Pick<AnalyticsParams, 'idWorkflow' | 'workflowName' | 'workflowNewName'>) => {
  Analytics.track(`Updated workflow name`, {
    'Workflow ID': idWorkflow,
    'Workflow old name': workflowName,
    'Workflow new name': workflowNewName
  })
}

const tags = {
  popupOpened: ({ openedFrom }: Pick<AnalyticsParams, 'openedFrom'>) => {
    Analytics.track('Open edit-tags-dialog', {
      [analyticsKeys.OPEN_FROM]: openedFrom
    })
  },
  popupClosed: ({ buttonLabel }: Pick<AnalyticsParams, 'buttonLabel'>) => {
    Analytics.track('Close edit-tags-dialog', {
      [analyticsKeys.BUTTON_LABEL]: buttonLabel
    })
  },
  save: () => {
    Analytics.track('Save workflow-tags')
  },
  addCustomTag: ({ actionLocation }: Pick<AnalyticsParams, 'actionLocation'>) => {
    Analytics.track('Add custom-tag', {
      [analyticsKeys.ACTION_LOCATION]: actionLocation
    })
  },
  openAddTagDialog: () => {
    Analytics.track('Open add-tag-dialog')
  },
  closeAddTagDialog: ({ buttonLabel }: Pick<AnalyticsParams, 'buttonLabel'>) => {
    Analytics.track('Close add-tag-dialog', {
      [analyticsKeys.BUTTON_LABEL]: buttonLabel
    })
  },
  openEditTagDialog: () => {
    Analytics.track('Open edit-custom-tag-name-dialog')
  },
  closeEditTagDialog: ({ buttonLabel }: Pick<AnalyticsParams, 'buttonLabel'>) => {
    Analytics.track('Close edit-custom-tag-name-dialog', {
      [analyticsKeys.BUTTON_LABEL]: buttonLabel
    })
  },
  saveEditedCustomTag: () => {
    Analytics.track('Save edited-custom-tag')
  },
  openDeleteCustomTagDialog: () => {
    Analytics.track('Open delete-custom-tag-dialog')
  },
  closeDeleteCustomTagDialog: ({ buttonLabel }: Pick<AnalyticsParams, 'buttonLabel'>) => {
    Analytics.track('Close delete-custom-tag-dialog', {
      [analyticsKeys.BUTTON_LABEL]: buttonLabel
    })
  },
  deleteCustomTag: () => {
    Analytics.track('Delete custom-tag')
  }
}

const table = {
  openMoreActionsMenu: ({ isOpen }: Pick<AnalyticsParams, 'isOpen'>) => {
    Analytics.track('Click on more-actions-menu', {
      [analyticsKeys.RESULT]: !isOpen ? 'Open' : 'Closed'
    })
  }
}

const settingsPage = {
  open: () => {
    Analytics.track('Open workflow-settings-page')
  },
  clickOnSection: ({ sectionLabel }: Pick<AnalyticsParams, 'sectionLabel'>) => {
    Analytics.track('Click on settings-section', {
      [analyticsKeys.SECTION_LABEL]: sectionLabel
    })
  }
}

const actionsLogPage = {
  open: ({ openFrom }) => {
    Analytics.track('Navigate to full-audit-log', {
      [analyticsKeys.OPEN_FROM]: openFrom
    })
  }
}

export const analytics = {
  create,
  action,
  branch,
  trigger,
  updateName,
  tags,
  table,
  settingsPage,
  actionsLogPage
}
