import React from 'react'
import * as Style from './style'
import Chart, { BarsChartProps } from './chart'
import PropTypes from 'prop-types'
import SourcesLine from '@components/dataSourcesBox/sourcesLine/index.jsx'
import HoverableHeader from '@components/hoverableHeader'
import { css } from 'glamor'

const className = 'barsChartBox'

const ChartBox = ({ loading, header, description, sources, showEmptyState, appName, isSmallScreen, disabledSource, ...rest }) => {
  const showHeaderSection = header || description
  return (
    <div className={className} {...css(Style.Main, { 'height': showHeaderSection ? '354px' : '300px' })} >
      {showHeaderSection && <HoverableHeader hoverClass={className} overrideStyle={Style.Header} header={header} subHeader={description} />}
      <div style={Style.ChartContainer}>
        <Chart
          {...rest}
          showEmptyState={showEmptyState}
          chartHeight={185}
          overrideStyle={Style.Chart}
        />
      </div>
      <SourcesLine loading={loading} isSmallScreen={isSmallScreen} name='source' appName={appName} sourcesList={sources} disabledSource={disabledSource} emptyListMessage='-' overrideStyle={{ textTransform: 'uppercase' }} />
    </div>
  )
}

ChartBox.propTypes = {
  header: PropTypes.node,
  description: PropTypes.node,
  sources: PropTypes.array,
  disabledSource: PropTypes.object,
  appName: PropTypes.string,
  isSmallScreen: PropTypes.bool,
  ...BarsChartProps
}

export default ChartBox
