import React from 'react'
import { SCOPES, TABLES } from '../../constants'
import TabsPage from '../tabs'
import ExpensesOverview from '../../components/expensesOverview'
import UploadsTable from '../../components/uploadsTable'
import TransactionMatchingRules from '../../components/transactionMatchingRules'
import ExpensesTable from '@components/expensesTable'
import { FEATURES } from '@root/shared/features'
import ExpensesIntegrationsAlert from './expensesIntegrationsAlert'

class ExpensesPage extends React.Component {
  render () {
    const { idOrg, toggleUploadExpenses } = this.props
    const tabsName = ['overview', 'matchingRules', 'uploads', 'allExpenses']
    const tabsHeader = [
      { header: 'Overview' },
      { header: 'Matching rules' },
      { header: 'Expense files' },
      { header: 'All expenses' }
    ]
    const tabsContent = [
      <ExpensesOverview />,
      <TransactionMatchingRules />,
      <UploadsTable />,
      <ExpensesTable idOrg={idOrg} tableKey={TABLES.allExpensesTable.key} />
    ]
    const tabsFeature = [
      FEATURES.EXPENSES.TABS.OVERVIEW,
      FEATURES.EXPENSES.TABS.MATCHING_RULES,
      FEATURES.EXPENSES.TABS.EXPENSE_FILES,
      FEATURES.EXPENSES.TABS.ALL_EXPENSES
    ]

    return (
      <TabsPage
        pageHeader='Expenses'
        pageTitle='Expenses'
        pageName='Expenses'
        path={`/team/${idOrg}/expenses`}
        tabsName={tabsName}
        tabsHeader={tabsHeader}
        contentBelow={<ExpensesIntegrationsAlert />}
        tabsContent={tabsContent}
        tabsFeature={tabsFeature}
        menuButtonText='Upload expenses report'
        menuButtonOnClick={() => toggleUploadExpenses(true)}
        allowedScopes={[SCOPES.EXPENSE_WRITE]}
      />
    )
  }
}

export default ExpensesPage
