import React from 'react'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { fontWeight } from '../../shared/style/sizes'
import { css } from 'glamor'
import sumBy from 'lodash/sumBy'
import BarsChart from '../barsChartBox/chart'
import ChartSummary from '../chartsGenericComponents/summary'
import PropTypes from 'prop-types'
import ResizeDetector from 'react-resize-detector'
import { CONTENT_TOOLTIP_TYPES } from '@components/barsChartBox/chart/view'
import Currency from '../currency'

const CSS = {
  amount: css(texts.headers.xLarge, {
    margin: '14px 12px 5px 0',
    fontSize: '25px',
    fontWeight: fontWeight.semiBold
  })
}

class ExpensesBarChart extends React.Component {
  state = { rotateXAxis: false }

  onResize = (width) => {
    this.setState({ rotateXAxis: width < 560 })
  }

  render () {
    const { expenses, loading, showChartSummary, tooltipType, chartHeight, chartOverrideStyle } = this.props
    const { rotateXAxis } = this.state
    const annualAmount = sumBy(expenses, 'value')
    return (
      <div style={{ width: '100%' }}>
        {showChartSummary && <ChartSummary amount={<span {...CSS.amount}><Currency value={annualAmount} format='$0.0a' /></span>} description='Last 12 months' />}
        <ResizeDetector handleWidth onResize={this.onResize} />
        <BarsChart
          overrideStyle={chartOverrideStyle}
          loading={loading}
          data={expenses}
          fill={colors.purple}
          formatter={value => <Currency value={value} format={'$0,0'} />}
          tooltipType={tooltipType}
          barSize={10}
          chartHeight={chartHeight}
          interval={0}
          rotateXAxis={rotateXAxis}
        />
      </div>
    )
  }
}

ExpensesBarChart.propTypes = {
  tooltipType: PropTypes.string,
  chartHeight: PropTypes.number,
  showChartSummary: PropTypes.bool,
  chartOverrideStyle: PropTypes.object
}

ExpensesBarChart.defaultProps = {
  tooltipType: CONTENT_TOOLTIP_TYPES.BASIC,
  showChartSummary: false
}

export default ExpensesBarChart
