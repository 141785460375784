import React from 'react'
import { css } from 'glamor'
import Table from '../../table'
import isEqual from 'lodash/isEqual'

const CSS = {
  tableHeaderStyle: css({
    padding: 0
  })
}

class UserFieldTable extends React.Component {
  state = { isOpen: false }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return !isEqual(nextProps.userFieldData, this.props.userFieldData) || nextState.isOpen !== this.state.isOpen
  }

  render () {
    const { id, userFieldData, config, getColumns } = this.props
    const { isOpen } = this.state

    const style = isOpen ? { minHeight: 100, height: '100%' } : { minHeight: 66, height: 66 }

    return <div
      key={id}
      id={id}
      style={{
        maxHeight: 500,
        overflowY: isOpen ? 'auto' : 'hidden',
        marginBottom: 10,
        borderRight: '1px solid #E6EBF4',
        borderLeft: '1px solid #E6EBF4'
      }}
    >
      <Table
        tableKey={id}
        data={isOpen ? userFieldData : [userFieldData[0]]}
        tableHeaderStyle={CSS.tableHeaderStyle}
        draggable={false}
        getProps={() => {
          return {
            style
          }
        }}
        getTableProps={() => {
          return {
            style: { overflow: isOpen ? 'auto' : 'hidden' }
          }
        }}
        getTheadTrProps={() => ({
          onClick: (e, handleOriginal) => {
            this.setState({ isOpen: !this.state.isOpen })
            handleOriginal && handleOriginal()
          },
          style: { height: 64 }
        })}
        columns={getColumns({ config, isOpen, userFieldData })}
        clientPaging
        scrollObjectId={id}
      />
    </div>
  }
}

export default UserFieldTable
