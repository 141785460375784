import styled, { css } from 'styled-components'
import { ButtonContainerProps } from './types'

export const ButtonContainer = styled.div.attrs((
  { labelX, labelY }: ButtonContainerProps
) => ({
  style: {
    left: `${labelX}px`,
    top: `${labelY}px`
  }
}))<ButtonContainerProps>`
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const Button = styled.button<{isUpdateInProgress?: boolean}>`
  pointer-events: all;
  height: 32px;
  width: 32px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    color: ${({ theme }) => theme.palette.icon.secondary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border-color: ${({ theme }) => theme.palette.border.secondary};
    > * {
      color: ${({ theme }) => theme.palette.icon.disabled};
    }
  }
  
  &:not(:disabled) {
    &:hover {
      border-color: ${({ theme }) => theme.palette.border.primaryHover};
      > * {
        color: ${({ theme }) => theme.palette.icon.primary};
      }
    }
    
    &:active {
      border-color: ${({ theme }) => theme.palette.border.active};
      > * {
        color: ${({ theme }) => theme.palette.icon.primary};
      }
    }
  }
  
  ${({ isUpdateInProgress }) => isUpdateInProgress && css`
    cursor: not-allowed;
  `}
`

export const IfThenLabelContainer = styled.div`
  height: 24px;
  padding: 4px 8px;
  color: ${({ theme }) => theme.palette.text.primary};
  font: ${({ theme }) => theme.typography.font.caption01};
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  background-color: ${({ theme }) => theme.palette.background.infoSubtle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
`
