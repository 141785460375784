import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import { fontSize } from '@shared/style/sizes'
import Placeholder from '../../components/placeholder'
import BackLink from '@components/backLink'
import colors from '../../shared/style/colors'
import { Grid, Col, RowWithoutMargin, Icon as DesignSystemIcon, ButtonSize, Button, Link, H3, Body1 } from '@toriihq/design-system'
import { getDisplayName } from '@lenses/users'
import ConnectToriiBot from '../../components/service/connectToriiBot'
import { INTEGRATION_TYPE } from '@shared/types'
import isEmpty from 'lodash/isEmpty'
import usersImage from '../../components/usersTabs/images/users.svg'
import { Form } from 'react-final-form'
import ConnectDirectIntegration from '@components/service/connectDirectIntegration'
import validateConnectDirectIntegration from '@components/service/connectDirectIntegration/validation'
import validateConnectToriiBot from '@components/service/connectToriiBot/validation'
import { getTransformedUrl } from '@shared/services'
import { FORM_ERROR } from 'final-form'
import { getSourceByType } from '@root/sourcesConfig'
import get from 'lodash/get'
import Analytics from '@helpers/analytics'
import TestConnectionAnalytics from '@components/testConnection/analytics'
import ConnectSCIM from '@components/service/connectSCIM'
import LogoWithTextWhite from '@media/logo_with_text_white.svg'
import {
  CONNECT_STAGES,
  TEST_PHASES,
  TEST_RESULTS
} from '@components/testConnection/constants'
import TestConnection from '@components/testConnection'
import {
  calculateFinalResults,
  getAuthenticationErrorResults,
  validTestConnectionInProgress
} from '@components/testConnection/utils'
import moment from 'moment'
import { AUTH_TYPES } from '@root/constants'
import SubmitButton from '@components/submitButton'
import NotFoundImg from '@media/404.svg'

const DELAY = 2000
const connectionInterface = 'Connect via link'
const LOGO_FOLDER = 'https://res.cloudinary.com/dy38uvqxu/image/upload/Integrations%20-%20logos/'
const CSS = {
  intro: css(texts.headers.small, {
    backgroundColor: 'rgba(255,255,255,0.15)',
    padding: '20px',
    whiteSpace: 'pre-wrap',
    borderRadius: '4px'
  }),
  left: css({
    color: colors.white,
    background: colors.blue,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '65px 25px',
    minHeight: '100vh',
    height: '100%',
    [`@media(max-width: 1024px)`]: {
      height: 'auto',
      minHeight: 0
    }
  }),
  right: css({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '70px',
    width: '100%',
    [`@media(max-width: 1024px)`]: {
      padding: '25px',
      marginLeft: 0
    },
    justifyContent: 'center',
    alignItems: 'center'
  }),
  fullwidth: css({
    maxWidth: 'unset !important'
  }),
  textAndIconBox: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
  }),
  footer: css(texts.subheading, {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '30px',
    left: '25px',
    color: colors.white,
    '> img': { marginLeft: '5px', height: '13px' }
  }),
  thanksMessage: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }),
  notifyMessage: css({
    fontSize: fontSize.large,
    color: colors.grey2
  }),
  image: css({
    margin: '60px 0'
  }),
  buttonContainer: css({
    marginRight: '48px'
  }),
  connectImagesContainer: css({
    display: 'flex',
    width: '345px',
    justifyContent: 'space-between',
    margin: '0 auto 15px auto'
  }),
  fullLength: css({
    width: '95%'
  }),
  topBorder: css({
    borderTop: `1px solid ${colors.border}`,
    marginBottom: 0
  }),
  bottomHalfContentContainer: css({
    width: '750px',
    margin: '0 auto 15px auto'
  }),
  header: css({
    textAlign: 'center',
    padding: '22px 0'
  }),
  title: css(texts.headers.medium, {
    marginBottom: 17,
    marginTop: 10
  }),
  connectButton: css({
    display: 'flex',
    justifyContent: 'center'
  }),
  testConnectionButtonContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 0
  }),
  testConnectionButton: css({
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    marginRight: 48
  }),
  backLinkButton: css({
    alignSelf: 'flex-start',
    marginLeft: 48,
    marginTop: 8
  }),
  form: css({
    width: '100%',
    padding: '30px',
    borderTop: `1px solid ${colors.border}`,
    borderBottom: `1px solid ${colors.border}`,
    marginBottom: 15
  }),
  scimForm: css({
    height: '420px'
  }),
  connectImages: css({
    width: 130,
    height: 100,
    borderRadius: 4
  })
}

const formError = { [FORM_ERROR]: 'We could not connect the service, please try again' }

class ConnectIntegrationPage extends React.Component {
  state = {
    stage: CONNECT_STAGES.CONNECT_FORM,
    inputsData: null,
    results: null,
    afterRedirect: null,
    idTestConnection: null,
    internalError: false
  }
  componentDidMount () {
    const { serviceName, getConnectIntegrationInfo, idOrg, idApp, token, hasTestConnection } = this.props
    getConnectIntegrationInfo({ idOrg, idApp, token })
    TestConnectionAnalytics.toggleIntegrationConnection(true, serviceName, idApp, hasTestConnection ? 'Test before connect' : 'Connect', connectionInterface)
  }

  async componentDidUpdate (prevProps) {
    const hasTestConnection = this.props.hasTestConnection
    if ((get(prevProps, 'serviceConfig.testConnection') !== hasTestConnection) && hasTestConnection) {
      this.setState(({ hasTestConnection, idTestConnection: this.props.idTestConnection }))
      if (this.props.afterRedirect && !this.props.isAuthError) {
        const testConnectionInfo = await this.props.getTestConnectionInfo({ idTestConnection: this.props.idTestConnection, idOrg: this.props.idOrg, idApp: this.props.idApp })
        const validTestInProgress = validTestConnectionInProgress(this.props.idOrg, this.props.idApp, testConnectionInfo)
        validTestInProgress && await this.handleTestConnectionForOAuth(testConnectionInfo)
      }
    }
  }

  handleTestConnectionForOAuth = async (testConnectionInfo) => {
    const { updateTestConnectionEntry, isConnectionError, idTestConnection, idOrg, serviceConfig } = this.props
    const { capabilities, integrationCategory } = serviceConfig

    this.setState({ afterRedirect: true, stage: CONNECT_STAGES.IN_PROGRESS })
    if (!isConnectionError) {
      await this.onTest()
    } else {
      const results = getAuthenticationErrorResults(capabilities, integrationCategory, testConnectionInfo)
      await updateTestConnectionEntry({ idTestConnection, idOrg, data: { phase: TEST_PHASES.RESULTS, testEndTime: moment.utc().toISOString() } })
      setTimeout(async () => {
        this.setState(({ stage: CONNECT_STAGES.RESULTS, results }))
      }, DELAY)
    }
  }

  renderTextAndIcon (header, description) {
    return <div {...CSS.textAndIconBox}>
      <img alt='Not found' src={NotFoundImg} width='110' />,
      <H3>{header}</H3>
      {description && <Body1>{description}</Body1>}
    </div>
  }

  renderAuthError () {
    return this.renderTextAndIcon('Invalid link', 'You may not have permissions to use this link or the link has expired.')
  }

  renderConnectionError () {
    return this.renderTextAndIcon('Something went wrong, please try again')
  }

  renderThanksMessage (appName, requestedByName) {
    return <div {...CSS.thanksMessage}>
      <div {...texts.headers.regular}>We got it!</div>
      <div {...texts.headers.regular}>Validating credentials and attempting to connect {appName}</div>
      <img {...CSS.image} src={usersImage} alt='Thank you!' />
      <div {...CSS.notifyMessage}>We will notify {requestedByName}</div>
    </div>
  }

  redirectWindow = (inputsData) => {
    const { serviceConfig, permission } = this.props
    const { source, connectUrl } = serviceConfig
    window.location.href = getTransformedUrl({ baseUrl: connectUrl, source, inputsData: { ...inputsData, permission, idTestConnection: this.state.idTestConnection } })
  }

  onSubmit = async (inputsData) => {
    const { serviceConfig, createTestConnectionEntry, idApp, idOrg, hasTestConnection } = this.props
    const { integrationType = {} } = serviceConfig
    if (hasTestConnection) {
      const { id: idTestConnection } = await createTestConnectionEntry({ data: { phase: TEST_PHASES.IN_PROGRESS, integrationType: integrationType, idApp }, idOrg })
      this.setState({ idTestConnection: parseInt(idTestConnection) })
    } else {
      return this.onConnect(inputsData)
    }

    const oauthConnectionWithNoTest = ([INTEGRATION_TYPE.OAUTH, INTEGRATION_TYPE.CONFIGURED_OAUTH].includes(integrationType) && !this.state.afterRedirect)
    if (oauthConnectionWithNoTest) {
      this.redirectWindow(inputsData)
    } else {
      await this.onTest(inputsData)
    }
  }

  onTest = async (inputsData) => {
    const { serviceConfig, serviceName, idApp } = this.props
    const { integrationType = {} } = serviceConfig
    let results

    TestConnectionAnalytics.clickTestIntegration(serviceName, idApp, connectionInterface)
    this.setState({ stage: CONNECT_STAGES.IN_PROGRESS, inputsData })
    try {
      if ([INTEGRATION_TYPE.CONFIGURED_OAUTH, INTEGRATION_TYPE.OAUTH].includes(serviceConfig.integrationType)) {
        results = await this.testOAuth()
      } else if (integrationType === INTEGRATION_TYPE.TOKENS) {
        results = await this.testToken(inputsData)
      } else if (integrationType === INTEGRATION_TYPE.SCIM) {
        results = await this.testSCIM(inputsData)
      }
      setTimeout(async () => {
        this.setState(({ stage: CONNECT_STAGES.RESULTS, results }))
      }, DELAY)
    } catch (e) {
      setTimeout(async () => {
        this.setState({ internalError: true })
      }, DELAY)
    }
  }

  testOAuth = async () => {
    const { idApp, idOrg, testConnection, idTestConnection } = this.props

    this.setState(({ idTestConnection }))
    return testConnection({ idApp, idOrg, idTestConnection })
  }

  testToken = async (inputsData) => {
    const { idApp, idOrg, permission, testConnection, serviceConfig } = this.props
    const { connectFormConfiguration = {} } = serviceConfig

    const token = connectFormConfiguration.sendDataAsToken ? { token: JSON.stringify(inputsData) } : inputsData
    const appToken = { ...token, permission }
    this.setState({ appToken })
    return testConnection({ idApp, idOrg, appToken, idTestConnection: this.state.idTestConnection })
  }

  testSCIM = async (inputsData) => {
    const { idApp, idOrg, permission, testConnection, serviceConfig } = this.props
    const finalValues = {
      auth: inputsData.auth,
      baseUrl: inputsData.baseUrl
    }

    if (inputsData.auth === AUTH_TYPES.BASIC) {
      finalValues.username = inputsData.username
      finalValues.password = inputsData.password
    } else {
      finalValues.token = inputsData.token
    }

    const appToken = { token: JSON.stringify(finalValues), permission, source: serviceConfig.source }
    this.setState({ appToken })
    return testConnection({ idApp, idOrg, appToken, idTestConnection: this.state.idTestConnection })
  }

  onConnect = async (inputsData) => {
    const { createSourceToken, idApp, serviceConfig, permission } = this.props
    const { source, connectUrl, integrationType, connectFormConfiguration = {} } = serviceConfig

    Analytics.track('Click to create integration / Add integration popup / Integrations', {
      'Integration type': 'Native'
    })

    if ([INTEGRATION_TYPE.CONFIGURED_OAUTH, INTEGRATION_TYPE.OAUTH].includes(integrationType)) {
      window.location.href = getTransformedUrl({
        baseUrl: connectUrl,
        source,
        inputsData: { ...inputsData, permission }
      })
    } else {
      const shouldSendDataAsToken = connectFormConfiguration.sendDataAsToken || integrationType === INTEGRATION_TYPE.SCIM
      const formData = shouldSendDataAsToken ? { token: JSON.stringify(inputsData) } : inputsData
      return createSourceToken({ idApp, source, formData: { ...formData, permission } })
        .catch(e => {
          window.Sentry && window.Sentry.captureException(e)
          return { [FORM_ERROR]: '* Please check your entries and try again' }
        })
    }
  }

  onConnectForTestConnection = async () => {
    const { createSourceTokenAfterTestConnection, idApp, serviceConfig, serviceName, permission } = this.props

    const finalResults = calculateFinalResults(this.state.results)
    TestConnectionAnalytics.clickOnConnectAfterTestIntegration(finalResults, serviceName, idApp, connectionInterface)

    await createSourceTokenAfterTestConnection({ idTestConnection: this.state.idTestConnection, idApp, source: serviceConfig.source, formData: { ...this.state.appToken, permission } })
  }

  onBackClick = () => {
    const { serviceName, idApp } = this.props
    const finalResults = calculateFinalResults(this.state.results)
    TestConnectionAnalytics.backClick(finalResults, serviceName, idApp, connectionInterface)
    this.setState({ afterRedirect: false, stage: CONNECT_STAGES.CONNECT_FORM, internalError: false })
  }

  onSubmitToriiBot = (form) => {
    const { createSourceToken, reset } = this.props
    const { user, password, url, idApp } = form
    const token = JSON.stringify({ user, password, url })

    return Promise.resolve(createSourceToken && createSourceToken({ idApp, source: getSourceByType('toriiBot').id, formData: { token, type: idApp } }))
      .then(res => {
        const error = get(res, 'response.error')
        if (error) {
          return formError
        }

        setTimeout(reset, 1000)
      })
      .catch(e => formError)
  }

  renderConnectToriibot = () => {
    const { app, serviceConfig } = this.props
    const { connectFormConfiguration = {} } = serviceConfig
    const { link } = connectFormConfiguration
    const header = app.name ? `Connect ${app.name}` : 'Connect new integration'
    const selection = { value: serviceConfig.idApp, label: serviceConfig.name }

    return <div {...CSS.fullLength}>
      <div {...CSS.header}>
        <header {...CSS.title}>{(header || `Connect ${app.name}`)}</header>
        {link ? <Link href={link.url} target='_blank'>{link.text}</Link> : null}
      </div>
      <div {...CSS.bottomHalfContentContainer}>
        <Form
          onSubmit={this.onSubmitToriiBot}
          validate={(values) => validateConnectToriiBot(values, this.props.serviceConfig)}
          initialValues={{ idApp: serviceConfig.idApp }}
          render={(formProps) =>
            <Fragment>
              <div {...CSS.form}>
                <ConnectToriiBot
                  idApp={app.id}
                  appName={app.name}
                  formProps={formProps}
                  selectServiceConfig={serviceConfig}
                  selection={selection}
                />
              </div>
              <div {...CSS.connectButton}>
                <SubmitButton size={ButtonSize.medium} form={formProps} label='Connect' />
              </div>
            </Fragment>
          }
        />
      </div>
    </div>
  }

  renderConnectDirectIntegration = () => {
    const { permission, idOrg, app, serviceConfig, header, hasTestConnection } = this.props
    const { source, connectUrl, integrationType, connectFormConfiguration = {} } = serviceConfig
    const { link } = connectFormConfiguration

    const fieldsDefinition = connectFormConfiguration.fields || []
    const initialValues = fieldsDefinition.reduce((result, field) => {
      const defaultValue = field.defaultValue ? field.defaultValue : (field.options && field.options.length === 1) ? field.options[0].value : undefined
      if (defaultValue) {
        result[field.name] = defaultValue
      }
      return result
    }, {})

    return <div {...CSS.fullLength} >
      <div {...CSS.header}>
        <header {...CSS.title}>{(header || `Connect ${serviceConfig.name}`)}</header>
        {link ? <Link href={link.url} target='_blank'>{link.text}</Link> : null}
      </div>
      <div {...CSS.bottomHalfContentContainer}>
        <Form
          onSubmit={this.onSubmit}
          validate={(values) => validateConnectDirectIntegration(values, this.props.serviceConfig)}
          initialValues={this.state.inputsData || initialValues}
          render={(formProps) => {
            return (<Fragment>
              <div {...CSS.form}>
                <ConnectDirectIntegration
                  formProps={formProps}
                  serviceName={app.name}
                  source={source}
                  connectUrl={connectUrl}
                  permission={permission}
                  idApp={app.id}
                  idOrg={idOrg}
                  integrationType={integrationType}
                  connectFormConfiguration={connectFormConfiguration}
                  getSyncStatus={false}
                  hideConnectByLink
                />
              </div>
              <div {...CSS.connectButton}>
                <SubmitButton size={ButtonSize.medium} form={{ ...formProps, pristine: false }} label={hasTestConnection ? 'Continue' : 'Connect'} />
              </div>
            </Fragment>)
          }
          }
        />
      </div>
    </div>
  }

  renderConnectSCIM = () => {
    const { serviceConfig, header } = this.props
    const { scimConfig = {} } = serviceConfig
    const initialValues = { ...scimConfig, ...this.state.inputsData }

    return <div {...CSS.fullLength}>
      <div {...CSS.header}>
        <header {...CSS.title}>{(header || `Connect ${serviceConfig.name}`)}</header>
        {scimConfig.link && <Link href={scimConfig.link} target='_blank'>How to set up {serviceConfig.name} integration</Link>}
      </div>
      <div {...CSS.bottomHalfContentContainer}>
        <Form
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          render={(formProps) => {
            return <Fragment>
              <div {...css(CSS.form, CSS.scimForm)}>
                <ConnectSCIM
                  formProps={formProps}
                  scimConfig={scimConfig}
                />
              </div>
              <div {...CSS.connectButton}>
                <SubmitButton size={ButtonSize.medium} form={formProps} label='Continue' />
              </div>
            </Fragment>
          }}
        />
      </div>
    </div>
  }

  renderConnectFormScreen = () => {
    const { serviceConfig } = this.props

    if (isEmpty(serviceConfig)) {
      return null
    }

    switch (serviceConfig.integrationType) {
      case INTEGRATION_TYPE.SCIM:
        return this.renderConnectSCIM()
      default:
        return this.renderConnectDirectIntegration()
    }
  }

  renderInProgressScreen = () => {
    const { serviceConfig, capabilityList } = this.props

    return (
      <TestConnection {...CSS.fullLength}>
        <div {...CSS.header}>
          <header {...CSS.title}>{`Test ${serviceConfig.name} Connection`}</header>
        </div>
        <div {...CSS.topBorder} />
        <div {...CSS.bottomHalfContentContainer}>
          {!this.state.internalError ? <TestConnection.InProgressBanner /> : <TestConnection.InternalErrorBanner />}
          <TestConnection.UnprocessedContent capabilityList={capabilityList} />
          <div {...css(CSS.testConnectionButtonContainer, { justifyContent: 'end' })}>
            <span {...CSS.buttonContainer}>
              <Button disabled size={ButtonSize.medium} label='Connect' />
            </span>
          </div>
        </div>
      </TestConnection>
    )
  }

  renderResultsScreen = () => {
    const { serviceConfig, capabilityList } = this.props
    const finalResults = calculateFinalResults(this.state.results)
    return (
      <TestConnection {...CSS.fullLength} >
        <div {...CSS.header}>
          <header {...CSS.title}>{`Test ${serviceConfig.name} Connection`}</header>
        </div>
        <div {...CSS.topBorder} />
        <div {...CSS.bottomHalfContentContainer}>
          <TestConnection.TestResultsBanner finalResults={finalResults} />
          <TestConnection.TestResultsContent finalResults={finalResults} results={this.state.results} capabilityList={capabilityList} />
          <div {...CSS.testConnectionButtonContainer}>
            <BackLink onClick={this.onBackClick} overrideStyle={CSS.backLinkButton}>Back</BackLink>
            <span {...CSS.buttonContainer}>
              <Button disabled={finalResults.result === TEST_RESULTS.FAILED} size={ButtonSize.medium} onClick={this.onConnectForTestConnection} label='Connect' />
            </span>
          </div>
        </div>
      </TestConnection>
    )
  }

  getConnectIntegrationContent = () => {
    const { serviceConfig } = this.props
    if (serviceConfig.integrationType === INTEGRATION_TYPE.TORII_BOT) {
      return this.renderConnectToriibot()
    }

    switch (this.state.stage) {
      case CONNECT_STAGES.CONNECT_FORM:
        return this.renderConnectFormScreen()
      case CONNECT_STAGES.IN_PROGRESS:
        return this.renderInProgressScreen()
      case CONNECT_STAGES.RESULTS:
        return this.renderResultsScreen()
      default:
        return null
    }
  }

  render () {
    const { isAuthError, isConnectedSuccessfully, loading, requestedBy = {}, app = {}, serviceConfig = {}, isConnectionError, hasTestConnection } = this.props
    const showConnectIntegration = !isAuthError && !isConnectedSuccessfully
    const showThanksMessage = !isAuthError && isConnectedSuccessfully
    const showLogos = !isAuthError && !showThanksMessage
    const showRequestedByMsg = !isAuthError && !isEmpty(requestedBy)
    const requestedByName = getDisplayName(requestedBy)
    const { logo } = serviceConfig
    const { name, imageUrl } = app
    const appImage = logo ? `${LOGO_FOLDER}${logo}` : imageUrl

    return (
      <Grid {...CSS.fullwidth}>
        <RowWithoutMargin nogutter>
          <Col sm={3}>
            <div {...CSS.left}>
              <Placeholder loading={loading} type='text' rows={2} style={{ height: '100px', width: '50px' }}>
                {showRequestedByMsg && <div {...CSS.intro}>
                  {`${requestedByName} would like you to connect ${app.name} to Torii in order to help administrate your account`}
                </div>}
              </Placeholder>
              <footer {...CSS.footer}>
                Powered by <img alt='Torii Logo' src={LogoWithTextWhite} />
              </footer>
            </div>
          </Col>
          <Col sm={9}>
            <div {...CSS.right}>
              <Placeholder loading={loading} type='text' rows={4} style={{ height: '100px', width: '50px' }}>
                {showLogos && <div {...CSS.connectImagesContainer}>
                  <img {...CSS.connectImages} alt={name} src={appImage} title={name} />
                  <div style={{ alignSelf: 'center' }}>
                    <DesignSystemIcon name='Integrations' color='disabled' />
                  </div>
                  <img {...CSS.connectImages} alt='Torii' src={`${LOGO_FOLDER}torii.svg`} title='Torii' />
                </div>}
                {!hasTestConnection && isConnectionError && this.renderConnectionError()}
                {isAuthError && this.renderAuthError()}
                {showConnectIntegration && this.getConnectIntegrationContent()}
                {showThanksMessage && this.renderThanksMessage(app.name, requestedByName)}
              </Placeholder>
            </div>
          </Col>
        </RowWithoutMargin>
      </Grid>
    )
  }
}

ConnectIntegrationPage.propTypes = {
  loading: PropTypes.bool,
  isAuthError: PropTypes.bool
}

export default ConnectIntegrationPage
