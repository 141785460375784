import React from 'react'
import PropTypes from 'prop-types'
import SelectUserButton from '../selectUserButton'
import { SCOPES } from '../../constants'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'

class AssignContractUserButton extends React.Component {
  render () {
    const { onSelectUser, idApp, user, size, label, idOrg } = this.props
    return <SelectUserButton
      onSelectUser={onSelectUser}
      idApp={idApp}
      user={user}
      actionDescription={`Select ${label}`}
      size={size}
      allowedScopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}
    />
  }
}

AssignContractUserButton.propTypes = {
  size: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  idOrg: PropTypes.number,
  idContract: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired,
  systemKey: PropTypes.string.isRequired
}

export default AssignContractUserButton
