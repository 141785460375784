import { DropdownOption } from '../dropdowns/types'

export const USER_DETAIL_CHANGE_ANYTHING_OPTION: DropdownOption = {
  label: 'Anything',
  value: 'anything',
  isCalculated: true
}

export const USER_DETAIL_CHANGE_OTHER_OPTION: DropdownOption = {
  label: 'Other',
  value: 'other',
  isCalculated: true
}

export const EMPTY_USER_DETAIL_VALUES = {
  changedFrom: null,
  changedTo: null
}
