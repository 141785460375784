import AdvancedFiltersRow from '@components/advancedFilters/advancedFiltersRow'
import AdvancedFiltersColumn from '@components/advancedFilters/advancedFiltersColumn'

export const ADVANCED_FILTERS_DISPLAY_TYPE = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
}

export const displayTypeToComponent = {
  [ADVANCED_FILTERS_DISPLAY_TYPE.HORIZONTAL]: AdvancedFiltersRow,
  [ADVANCED_FILTERS_DISPLAY_TYPE.VERTICAL]: AdvancedFiltersColumn
}
