import Analytics from '@helpers/analytics'

export const INTEGRATION_ALERT_THRESHOLD_IN_DAYS = 7

export const shouldShowIntegrationAlert = ({ connectedServices = [] }: { connectedServices: Array<{ nonSyncingPeriodInDays: number }> }) =>
  connectedServices.some(service => service.nonSyncingPeriodInDays >= INTEGRATION_ALERT_THRESHOLD_IN_DAYS)

export const onClickReviewAndFix = ({ sourcePageName }: { sourcePageName: string }): void => {
  Analytics.track('Click on failed integrations CTA', { 'Source page name': sourcePageName })
}
