import React, { ReactElement, Fragment } from 'react'
import { AppIcon, Spacer } from '@toriihq/design-system'
import Widget from '@root/widgets/widget'
import { IWidget } from './widget/types'
import * as Style from './style'

const Widgets = ({ widgets, columnsPerWidget }: { widgets: IWidget[], columnsPerWidget: number }): ReactElement => {
  return <Fragment>{widgets.map(widget => (
    <Style.ColWithMargin sm={columnsPerWidget} key={widget.id}>
      <Widget
        header={<Style.Header>
          <Spacer right={'space-150'}>
            <AppIcon appImageUrl={widget.squareLogoUrl} size='large' />
          </Spacer>
          <div>{widget.name}</div>
        </Style.Header>}
        fields={widget.fields}
        type={widget.type}
        emptyState={widget.emptyState}
      />
    </Style.ColWithMargin>
  ))}
  </Fragment>
}

export default Widgets
