import styled from 'styled-components'
import { theme } from '@toriihq/design-system'

export const ContentAreaStyle = {
  overflowY: 'unset'
}

export const HelperText = styled.div`
  font: ${theme.typography.font.caption01};
  color: ${theme.palette.text.secondary};
`
