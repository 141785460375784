import React from 'react'
import PropTypes from 'prop-types'
import { groupOptions } from '../../selectionBox'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import SingleUser from '../../singleUser'
import EmailSubject from '../emailSubject'
import EmailContent from '../emailContent'
import Personalization from '../../personalization'
import MultipleUsers from '../../multipleUsers'

export const EMAIL_SETUP_FIELD = {
  from: 'from',
  to: 'to',
  cc: 'cc',
  subject: 'subject',
  content: 'content'
}

const typeToComponent = {
  [EMAIL_SETUP_FIELD.from]: props => <SingleUser creatable label='From:' {...props} isExternal={false} disableCreateUser borderless />,
  [EMAIL_SETUP_FIELD.to]: props => <MultipleUsers
    text='To:'
    includeCustomUserFields
    selectClassName={CSS.toMultiUserSelect.toString()}
    placeholder='Search or add user...'
    noResultsText='No results found. Enter an email address to add a new user'
    creatable
    cache={false}
    borderless
    {...props}
  />,
  [EMAIL_SETUP_FIELD.cc]: props => <MultipleUsers
    text='CC:'
    includeCustomUserFields
    selectClassName={CSS.toMultiUserSelect.toString()}
    placeholder='Search or add user...'
    noResultsText='No results found. Enter an email address to add a new user'
    creatable
    cache={false}
    borderless
    {...props}
  />,
  [EMAIL_SETUP_FIELD.subject]: EmailSubject,
  [EMAIL_SETUP_FIELD.content]: EmailContent
}

const CSS = {
  toMultiUserSelect: css({
    width: '100%',
    ' .Select-control': {
      border: 'none !important'
    }
  }),
  container: css({
    borderRadius: '4px'
  }),
  fieldsContainer: css({
    border: `1px solid ${colors.grey2}`,
    borderRadius: '4px',
    '>div:last-child': {
      border: 'none'
    }
  }),
  emailField: css({
    borderBottom: `1px solid ${colors.grey2}`,
    padding: '9px 20px',
    '>div': css(texts.subheading, {
      color: colors.grey1
    })
  }),
  disabled: css({
    opacity: 0.5
  })
}

class EmailSetupBox extends React.Component {
  render () {
    const { onChange, triggerType, action = {}, personalization, fields, overrideStyle, disabled, renderers, allowedScopes } = this.props
    const actionType = action && action.type

    return <div {...css(CSS.container, overrideStyle, disabled && CSS.disabled)}>
      <div {...CSS.fieldsContainer}>
        {fields.map(field => {
          const Component = renderers[field.id] || typeToComponent[field.id]
          return Component && (
            <Component
              key={field.id}
              value={field.value}
              onChange={(selection) => onChange({ fieldId: field.id, selectedValue: selection })}
              overrideStyle={CSS.emailField}
              triggerType={triggerType}
              actionType={actionType}
              mentions={personalization}
              disabled={disabled}
              field={field}
              allowedScopes={allowedScopes}
            />)
        })}
      </div>
      <Personalization />
    </div>
  }
}

EmailSetupBox.propTypes = {
  action: PropTypes.shape({
    idApp: PropTypes.number,
    type: PropTypes.string
  }),
  triggerType: PropTypes.string,
  fields: PropTypes.arrayOf(groupOptions).isRequired,
  personalization: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, display: PropTypes.string })).isRequired,
  onChange: PropTypes.func.isRequired,
  overrideStyle: PropTypes.object,
  renderers: PropTypes.object
}

EmailSetupBox.defaultProps = {
  renderers: {}
}

export default EmailSetupBox
