import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import CreateUser from '@components/createUser'

const SUCCESS_DELAY = 1000

const CreateUserPopup = ({ cancel, isOpen, close, onSuccess, selectedUsers, ...rest }) => {
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel}>
      <ToriiPopup.Header header='Add new user' subHeader='Users will appear on Users page only if they have apps' />
      <ToriiPopup.Form
        render={(formProps) => (
          <CreateUser
            onSuccess={newUser => {
              onSuccess([...selectedUsers, newUser])
              setTimeout(close,
                SUCCESS_DELAY)
            }}
            onCancel={cancel}
            {...rest}
            {...formProps}
          />
        )}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            cancelButtonText='Cancel'
            mainButtonText='Add new user'
            isMainSubmit
            formProps={formProps}
          />
        )}
      />
    </ToriiPopup>
  )
}

CreateUserPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default CreateUserPopup
