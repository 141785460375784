import React, { Fragment } from 'react'
import Placeholder from '@components/placeholder'
import { FormFieldV2 } from '@pages/workflow_v2/formField'
import { Field, Form } from 'react-final-form'
import { fieldTypes, formFieldTypes, SCOPES, WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { get, isEmpty, isEqual, noop } from 'lodash'
import { AlertBox, AlertBoxType, Button, ButtonSize, ButtonType, Icon, Tooltip } from '@toriihq/design-system'
import { AlertBoxWrapper, CSS, IconWrapper, ScheduleHeader, TriggerConfiguration, TriggerHeader } from './styles'
import { WorkflowSchedule } from '@components/workflows/workflowSchedule'
import { WORKFLOW_CHANGE_TYPE } from '@components/workflows/types'
import { SchedulesType, Workflow } from '@shared/workflows/types'
import { formFieldTypeToConfigMap } from '@pages/workflow_v2/formField/mapping/fieldMapping'
import { FetchTriggerValuesParams, FieldChangeParams, TriggerChangeParams } from './types'

interface Props {
  workflow: Workflow
  idOrg: number,
  fields: any[]
  triggerType: string
  onChange: ({
    data,
    locallyOnly,
    clearAllFields,
    fieldsToClearOnChange
  }: TriggerChangeParams) => void
  header: string
  trigger: any,
  triggerConfig: any,
  loading: boolean,
  getTriggerPreview: ({ idOrg, idWorkflow }: {idOrg: number, idWorkflow: number }) => void,
  toggleTriggerPreviewPopup: (isOpen: boolean, idWorkflow: number, workflowName: string, triggerPreviewConfig: any) => void
  getTriggerFieldsOptions: ({ idOrg, trigger }: {idOrg: number, trigger: any }) => void,
  getTriggerFieldsOptionsValues: ({ idOrg, trigger, filters }: {idOrg: number, trigger: any, filters: any }) => void
}

class TriggerStepSetup extends React.Component<Props> {
  fieldsSetDefaultValue = {
    [formFieldTypes.account]: (field, prevField, triggerType) => {
      const { options, value } = field
      const { value: prevValue } = prevField

      if (options && options.length) {
        if (value) {
          const updatedSelectedOption = options.find(option => option.id === value.id) || value
          if (!isEqual(prevValue, updatedSelectedOption)) {
            this.onFieldChange({
              field,
              selectedValue: updatedSelectedOption
            })
          }
        } else {
          if (options.length === 1 && triggerType !== WORKFLOW_TRIGGER_TYPES.APP_WEBHOOK_EVENT_OCCURRED) {
            this.onFieldChange({
              field,
              selectedValue: options[0]
            })
          }
        }
      }
    },
    [formFieldTypes.dropdown]: (field) => {
      if (field.autoSelectFirstOption && field.options && field.options.length > 0 && !field.value) {
        this.onFieldChange({
          field,
          selectedValue: field.options[0]
        })
      }
    }
  }

  componentDidMount () {
    const { idOrg } = this.props
    if (idOrg) {
      this.loadDynamicFieldsOptions()
      this.fetchTriggerValues()
    }
  }

  componentDidUpdate (prevProps) {
    const { fields, idOrg, trigger } = this.props

    this.loadDynamicFieldsOptionsIfNeeded(prevProps)

    if (!isEqual(fields, prevProps.fields)) {
      fields.forEach(field => {
        const prevField = prevProps.fields.find(f => f.type === field.type)
        const triggerType = get(trigger, 'type')
        this.fieldsSetDefaultValue[field.type] && this.fieldsSetDefaultValue[field.type](field, prevField, triggerType)
      })
    }
    if (idOrg && prevProps.idOrg !== idOrg) {
      this.loadDynamicFieldsOptions()
      this.fetchTriggerValues()
    }
  }

  loadDynamicFieldsOptions = () => {
    const { getTriggerFieldsOptions, idOrg, trigger } = this.props
    getTriggerFieldsOptions({ idOrg, trigger })
  }

  loadDynamicFieldsOptionsIfNeeded = (prevProps: any) => {
    const { trigger } = this.props
    const prevTriggerFields = prevProps.trigger?.fields
    const isTriggerFieldsChanged = prevTriggerFields && !isEqual(trigger?.fields, prevTriggerFields)
    if (isTriggerFieldsChanged) {
      const shouldLoad = prevTriggerFields.some(this.shouldLoadDynamicFields)
      if (shouldLoad) {
        this.loadDynamicFieldsOptions()
      }
    }
  }

  fetchTriggerValues = ({ filters, field }: FetchTriggerValuesParams = {}) => {
    const { getTriggerFieldsOptionsValues, idOrg, trigger } = this.props

    const fields = trigger.fields.map(existingField => {
      if (field && existingField.id === field.id) {
        return { ...existingField, value: field.value }
      }
      return existingField
    })

    getTriggerFieldsOptionsValues({
      idOrg,
      trigger: { ...trigger, fields },
      filters
    })
  }

  renderField = (props) => {
    const { input, field } = props
    let showError = false
    if (field.type === fieldTypes.dropdown && field.id === 'license' && !isEmpty(field.options) && input.value) {
      showError = !field.options.find(option => option.value === input.value.value)
    }

    return <div {...CSS.divWrapSelectWithError}>
      <FormFieldV2
        {...props}
        input={{
          ...input,
          disabled: props.disabled,
          onBlur: e => {
            const selectedValue = e?.target ? e.target.value : (e || '')
            input.onBlur(e)
            this.props.onChange({
              data: { fieldId: field.id, selectedValue }
            })
          },
          onChange: (e, locallyOnly = false) => {
            const selectedValue = e && e.target ? e.target.value : (e || '')
            input.onChange(e)
            const clearAllFields = Boolean(field.clearStepFieldsOnClear && !selectedValue)

            this.onFieldChange({
              field,
              selectedValue,
              locallyOnly,
              clearAllFields
            })
          }
        }} />
      {showError && <span {...CSS.spanError}>Integration with account was disconnected</span>}
    </div>
  }

  onFieldChange = ({
    field,
    selectedValue,
    locallyOnly = false,
    clearAllFields = false
  }: FieldChangeParams) => {
    const { fieldsToClearOnChange } = field

    this.props.onChange({
      data: { fieldId: field.id, selectedValue },
      locallyOnly,
      clearAllFields,
      fieldsToClearOnChange
    })
  }

  isDisabled = (field) => {
    const { triggerConfig, fields } = this.props
    const fieldConfig = triggerConfig.inputSchema[field.id]
    const isValid = fieldConfig.disabledUntilFieldsAreValid ? fieldConfig.disabledUntilFieldsAreValid.every(fieldId => fields.find(field => field.id === fieldId).isValid) : true
    return !isValid
  }

  getTriggerPreview = () => {
    const { workflow, toggleTriggerPreviewPopup, triggerConfig } = this.props
    const { id: idWorkflow } = workflow
    toggleTriggerPreviewPopup(true, idWorkflow, workflow.name, triggerConfig.uiConfig.preview)
  }

  shouldLoadDynamicFields = (prevField) => {
    const { trigger } = this.props

    const field = trigger.fields.find(field => field.id === prevField.id)
    const isValueChanged = !isEqual(prevField.value, field?.value)

    if (!isValueChanged) {
      return false
    }

    return this.areThereFieldsDependingOnField(prevField.id)
  }

  areThereFieldsDependingOnField = (idField: string) => {
    const { triggerConfig } = this.props
    const inputSchemaFields = Object.values(triggerConfig.inputSchema)

    return inputSchemaFields.some((fieldConfig: any) =>
      fieldConfig.disabledUntilFieldsAreValid?.includes(idField)
    )
  }

  isHidden = (field) => {
    const { triggerConfig, fields } = this.props
    const fieldConfig = get(triggerConfig, ['inputSchema', field.id]) || {}

    if (fieldConfig.isHidden) {
      return true
    }

    if (!fieldConfig.showOnFieldSpecificValue) {
      return false
    }

    const dependsOnField = fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
    const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField.value
    return dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
  }

  getFieldParams = (field) => {
    const { triggerConfig, fields } = this.props
    const fieldConfig = get(triggerConfig, ['inputSchema', field.id]) || {}

    if (!fieldConfig.fieldParams) {
      return {}
    }

    const values = (fieldConfig.fieldParams || []).reduce((valuesObj, fieldParam) => {
      const fieldForParam = fields.find(field => field.id === fieldParam.fieldId)
      const value = get(fieldForParam, ['value', 'value']) || fieldForParam.value
      valuesObj[fieldParam.paramName] = isNaN(value) ? value : Number(value)
      return valuesObj
    }, {})

    return values
  }

  renderForm = (fields) => {
    const { triggerType, triggerConfig } = this.props
    const initialValues = fields.reduce((result, field) => {
      result[field.id] = field.value
      return result
    }, {})

    const supportPreview = get(triggerConfig, ['supportPreview'], false) && Boolean(get(triggerConfig, ['uiConfig', 'preview']))
    const isFiltersFieldPreview = get(triggerConfig, ['uiConfig', 'preview', 'isFiltersFieldPreview'], false)
    const showTriggerSetupPreview = supportPreview && !isFiltersFieldPreview

    return <div>
      <Form
        onSubmit={noop}
        initialValues={initialValues}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              {fields.filter(Boolean).map((field, index) => {
                const typeMapping = formFieldTypeToConfigMap[field.type] || {}
                const validate = typeMapping.validators
                const disabled = this.isDisabled(field)
                const isHidden = this.isHidden(field)
                const fieldParams = this.getFieldParams(field)

                if (isHidden) {
                  return null
                }

                return (
                  <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} key={field.id}>
                    <Field
                      addFieldIndex={false}
                      index={index}
                      validate={validate}
                      key={field.id}
                      name={field.id}
                      field={field}
                      component={this.renderField}
                      format={typeMapping.format}
                      parse={typeMapping.parse}
                      triggerType={triggerType}
                      disabled={disabled}
                      refreshDynamicFieldsOptions={this.loadDynamicFieldsOptions}
                      fetchTriggerValues={this.fetchTriggerValues}
                      getTriggerPreview={this.getTriggerPreview}
                      fieldParams={fieldParams}
                    />
                  </EnableFor>
                )
              })}
            </div>
            {showTriggerSetupPreview && <div {...CSS.previewButtonContainer}>
              {<Button type={ButtonType.secondary} onClick={this.getTriggerPreview} size={ButtonSize.medium} label='Preview' />}
            </div>}
          </form>
        }}
      />
    </div>
  }

  handleScheduleChange = ({ schedules }: { schedules: SchedulesType | null }) => {
    const { onChange } = this.props
    onChange({
      data: {
        changeType: WORKFLOW_CHANGE_TYPE.SCHEDULES,
        schedules
      }
    })
  }

  render () {
    const { loading, fields, triggerConfig } = this.props
    const { disclaimer, header } = triggerConfig.uiConfig
    const { supportSchedule } = triggerConfig
    const { workflow: { schedules } } = this.props

    return (
      <Placeholder loading={loading} type='text' rows={4} style={{ height: '100px', width: '50px' }}>
        <Fragment>
          <TriggerConfiguration>
            {header && <TriggerHeader>{header}</TriggerHeader>}
            {disclaimer && <AlertBoxWrapper><AlertBox type={AlertBoxType.INFORMATIVE} description={disclaimer} /></AlertBoxWrapper>}
            {this.renderForm(fields)}
          </TriggerConfiguration>
          {supportSchedule &&
            <>
              <ScheduleHeader>
                Schedule (optional)
                <IconWrapper>
                  <Tooltip
                    placement='top'
                    label='The workflow’s trigger criteria will be evaluated prior to running the workflow. If the trigger criteria are met, the workflow will be run.'>
                    <Icon name='Info' />
                  </Tooltip>
                </IconWrapper>
              </ScheduleHeader>
              <WorkflowSchedule
                schedules={schedules}
                onSchedulesChange={this.handleScheduleChange}
              />
            </>
          }
        </Fragment>
      </Placeholder>
    )
  }
}

export default TriggerStepSetup
