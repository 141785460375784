import { WORKFLOW_UI_NODE_TYPE } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { TriggerNode } from '../components/nodes/triggerNode'
import { ActionNode } from '../components/nodes/actionNode'
import { EndNode } from '../components/nodes/endNode'
import { BranchActionNode } from '../components/nodes/branchActionNode'
import { BranchLabel } from '../components/nodes/branchLabel/branchLabel'
import { DefaultBranchLabel } from '../components/nodes/branchLabel/defaultBranchLabel'

export const nodeTypes = {
  [WORKFLOW_UI_NODE_TYPE.ACTION]: ActionNode,
  [WORKFLOW_UI_NODE_TYPE.BRANCH_ACTION]: BranchActionNode,
  [WORKFLOW_UI_NODE_TYPE.BRANCH_LABEL]: BranchLabel,
  [WORKFLOW_UI_NODE_TYPE.DEFAULT_BRANCH_LABEL]: DefaultBranchLabel,
  [WORKFLOW_UI_NODE_TYPE.TRIGGER]: TriggerNode,
  [WORKFLOW_UI_NODE_TYPE.END]: EndNode
}
