import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import { OFFBOARDING_APPS_STATUS, WORKFLOW_ACTION_TYPES } from '@root/constants'
import { getAppAccountName, getAppWithAppAccountName } from '@lenses/appAccounts'
import { shouldOffboardingAppWithAccountBeVisible } from '@lenses/offboarding.t'

export const isOffboardingAppsLoading = createSelector(
  state => state.offboarding.appsConfig.loading,
  identity
)

export const isOffboardingAppsOfUsersLoading = createSelector(
  state => state.offboarding.appsOfUsersConfig.loading,
  identity
)

export const getOffboardingAppsOfUsers = createSelector(
  state => state.offboarding.appsOfUsersConfig.apps,
  apps => apps.map(addAppAccountName)
)

export const getOffboardingAppsOfUsersIdUsers = createSelector(
  state => state.offboarding.appsOfUsersConfig.idUsers,
  identity
)

const addAppAccountName = app => ({ ...app, appAndAccountName: getAppWithAppAccountName(app), accountName: getAppAccountName(app) })

export const getOffboardingApps = createSelector(
  state => state.offboarding.appsConfig.apps,
  apps => apps.filter(shouldOffboardingAppWithAccountBeVisible).map(addAppAccountName)
)

export const getOffboardingAppsResources = createSelector(
  state => state.offboarding.appsConfig.resources,
  identity
)

export const getOffboardingNeedsAttentionApps = createSelector(
  getOffboardingApps,
  apps => apps
    .filter(app => app.status === OFFBOARDING_APPS_STATUS.needsAttention)
)

const isConfiguredAppThatNeedsAttention = app => app.status === OFFBOARDING_APPS_STATUS.needsAttention && app?.actions?.every(action => action.isValid)
const isNonConfiguredAppThatNeedsAttention = app => app.status === OFFBOARDING_APPS_STATUS.needsAttention && (!app?.actions || app?.actions?.some(action => !action.isValid))

export const getOffboardingNeedsAttentionAppsOfUsers = createSelector(
  getOffboardingAppsOfUsers,
  apps => apps
    .filter(isNonConfiguredAppThatNeedsAttention)
)

export const getOffboardingNonConfiguredApps = createSelector(
  getOffboardingApps,
  apps => apps
    .filter(app => app.status === OFFBOARDING_APPS_STATUS.nonConfigured)
)

const offboardingConfiguredAppsMapper = app => {
  const actionType = ((app.actions || []).find(action => [WORKFLOW_ACTION_TYPES.IGNORE, WORKFLOW_ACTION_TYPES.REMOVE_USER, WORKFLOW_ACTION_TYPES.AUTO_REMOVE_USER].includes(action.type)) || {}).type

  return {
    ...app,
    actionType
  }
}

export const getOffboardingConfiguredApps = createSelector(
  getOffboardingApps,
  apps => apps
    .filter(app => app.status === OFFBOARDING_APPS_STATUS.configured)
    .map(offboardingConfiguredAppsMapper)
)

export const getOffboardingConfiguredAppsOfUsers = createSelector(
  getOffboardingAppsOfUsers,
  apps => apps
    .filter(app => app.status === OFFBOARDING_APPS_STATUS.configured || isConfiguredAppThatNeedsAttention(app))
    .map(offboardingConfiguredAppsMapper)
)

export const getOffboardingUserStatus = createSelector(
  state => state.offboarding.user,
  identity
)

export const getOffboardingTodoUsersSelector = createSelector(
  state => state.offboarding.todoUsers,
  identity
)

export const getOffboardingTodoUsersCountSelector = createSelector(
  state => state.offboarding.todoUsersCount,
  identity
)

export const getOffboardingInProgressUsersSelector = createSelector(
  state => state.offboarding.inProgressUsers,
  identity
)

export const getOffboardingInProgressUsersCountSelector = createSelector(
  state => state.offboarding.inProgressUsersCount,
  identity
)

export const getOffboardingDoneUsersSelector = createSelector(
  state => state.offboarding.doneUsers,
  identity
)

export const getOffboardingDoneUsersCountSelector = createSelector(
  state => state.offboarding.doneUsersCount,
  identity
)

export const getOffboardingUserResources = createSelector(
  state => state.offboarding.user.resources,
  identity
)

export const getDefaultOffboardingWorkflow = createSelector(
  state => state.offboarding.defaultOffboarding,
  identity
)

export const getPendingOffboardingTasks = createSelector(
  state => state.offboarding.pendingTasks,
  identity
)

export const getPendingOffboardingTasksSelector = createSelector(
  getPendingOffboardingTasks,
  pendingTasks => pendingTasks.tasks
)

export const getPendingOffboardingTicketsSelector = createSelector(
  getPendingOffboardingTasks,
  pendingTasks => pendingTasks.tickets
)

export const getPendingOffboardingResourcesSelector = createSelector(
  getPendingOffboardingTasks,
  pendingTasks => ({
    appsById: pendingTasks.appsById,
    usersById: pendingTasks.usersById
  })
)
