import { WORKFLOW_TRIGGER_TYPE, WORKFLOW_TYPE } from '@shared/types'
import { getWorkflowsPathPrefix } from '@lenses/workflows'
import { WorkflowNavigationBackData } from './getNavigationBackData.types'

export const getNavigationBackData = (workflowType: WORKFLOW_TYPE = WORKFLOW_TYPE.regular, triggerType?: WORKFLOW_TRIGGER_TYPE | null): WorkflowNavigationBackData => {
  const pathPrefix = getWorkflowsPathPrefix({ workflowType, triggerType: triggerType ?? null })

  const defaultTitle = 'Back to workflows'
  const workflowTypeToTitleMap = {
    [WORKFLOW_TYPE.appCatalog]: 'Back to policies'
  }
  const title = workflowTypeToTitleMap[workflowType] || defaultTitle

  return { path: pathPrefix, title }
}
