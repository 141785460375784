import keyBy from 'lodash/keyBy'

const permissions = [
  {
    Service: 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.install',
    'Permission Description': 'Special scope used to let users approve installation of an app, and scope needs to be requested',
    'Sensitivity level': 3
  },
  {
    Service: 'google',
    'Permission Name': 'https://www.googleapis.com/auth/calendar.events',
    'Permission Description': 'Manage your events',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/adexchange.buyer',
    'Permission Description': 'Manage your Ad Exchange buyer account configuration',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/adexchange.seller',
    'Permission Description': 'View and manage your Ad Exchange data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/adexchange.seller.readonly',
    'Permission Description': 'View your Ad Exchange data',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/xapi.zoo',
    'Permission Description': 'Test scope for access to the Zoo service',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.datatransfer',
    'Permission Description': 'View and manage data transfers between users in your organization',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.datatransfer.readonly',
    'Permission Description': 'View data transfers between users in your organization',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.customer',
    'Permission Description': 'View and manage customer related information',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.customer.readonly',
    'Permission Description': 'View customer related information',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.device.chromeos',
    'Permission Description': 'View and manage your Chrome OS devices\' metadata',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly',
    'Permission Description': 'View your Chrome OS devices\' metadata',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.device.mobile',
    'Permission Description': 'View and manage your mobile devices\' metadata',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.device.mobile.action',
    'Permission Description': 'Manage your mobile devices by performing administrative tasks',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.device.mobile.readonly',
    'Permission Description': 'View your mobile devices\' metadata',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.domain',
    'Permission Description': 'View and manage the provisioning of domains for your customers',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.domain.readonly',
    'Permission Description': 'View domains related to your customers',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.group.member',
    'Permission Description': 'View and manage group subscriptions on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.group.member.readonly',
    'Permission Description': 'View group subscriptions on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.group.readonly',
    'Permission Description': 'View groups on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.notifications',
    'Permission Description': 'View and manage notifications received on your domain',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.orgunit',
    'Permission Description': 'View and manage organization units on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
    'Permission Description': 'View organization units on your domain',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.resource.calendar',
    'Permission Description': 'View and manage the provisioning of calendar resources on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly',
    'Permission Description': 'View calendar resources on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.rolemanagement',
    'Permission Description': 'Manage delegated admin roles for your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly',
    'Permission Description': 'View delegated admin roles for your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.user',
    'Permission Description': 'View and manage the provisioning of users on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.user.alias',
    'Permission Description': 'View and manage user aliases on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.user.alias.readonly',
    'Permission Description': 'View user aliases on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.user.readonly',
    'Permission Description': 'View users on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.user.security',
    'Permission Description': 'Manage data access permissions for users on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.userschema',
    'Permission Description': 'View and manage the provisioning of user schemas on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.userschema.readonly',
    'Permission Description': 'View user schemas on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.reports.audit.readonly',
    'Permission Description': 'View audit reports for your Google Workspace domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.reports.usage.readonly',
    'Permission Description': 'View usage reports for your Google Workspace domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/adsense',
    'Permission Description': 'View and manage your AdSense data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/adsense.readonly',
    'Permission Description': 'View your AdSense data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/adsensehost',
    'Permission Description': 'View and manage your AdSense host data and associated accounts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/analytics',
    'Permission Description': 'View and manage your Google Analytics data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/analytics.edit',
    'Permission Description': 'Edit Google Analytics management entities',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/analytics.manage.users',
    'Permission Description': 'Manage Google Analytics Account users by email address',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/analytics.manage.users.readonly',
    'Permission Description': 'View Google Analytics user permissions',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/analytics.provision',
    'Permission Description': 'Create a new Google Analytics account along with its default property and view',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/analytics.readonly',
    'Permission Description': 'View your Google Analytics data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/androidenterprise',
    'Permission Description': 'Manage corporate Android devices',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/androidmanagement',
    'Permission Description': 'Manage Android devices and apps for your customers',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/androidpublisher',
    'Permission Description': 'View and manage your Google Play Developer account',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/appengine.admin',
    'Permission Description': 'View and manage your applications deployed on Google App Engine',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud-platform',
    'Permission Description': 'View and manage your data across Google Cloud Platform services',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud-platform.read-only',
    'Permission Description': 'View your data across Google Cloud Platform services',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/activity',
    'Permission Description': 'View the activity history of your Google apps',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive',
    'Permission Description': 'View and manage the files in your Google Drive',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.metadata',
    'Permission Description': 'View and manage metadata of files in your Google Drive',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.metadata.readonly',
    'Permission Description': 'View metadata for files in your Google Drive',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.readonly',
    'Permission Description': 'View the files in your Google Drive',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/appstate',
    'Permission Description': 'View and manage your data for this application',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/bigquery',
    'Permission Description': 'View and manage your data in Google BigQuery',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/bigquery.insertdata',
    'Permission Description': 'Insert data into Google BigQuery',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/devstorage.full_control',
    'Permission Description': 'Manage your data and permissions in Google Cloud Storage',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/devstorage.read_only',
    'Permission Description': 'View your data in Google Cloud Storage',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/devstorage.read_write',
    'Permission Description': 'Manage your data in Google Cloud Storage',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/blogger',
    'Permission Description': 'Manage your Blogger account',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/blogger.readonly',
    'Permission Description': 'View your Blogger account',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/books',
    'Permission Description': 'Manage your books',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/calendar',
    'Permission Description': 'Manage your calendars',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/calendar.readonly',
    'Permission Description': 'View your calendars',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.announcements',
    'Permission Description': 'View and manage announcements in Google Classroom',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.announcements.readonly',
    'Permission Description': 'View announcements in Google Classroom',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.courses',
    'Permission Description': 'Manage your Google Classroom classes',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.courses.readonly',
    'Permission Description': 'View your Google Classroom classes',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.coursework.me',
    'Permission Description': 'Manage your course work and view your grades in Google Classroom',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.coursework.me.readonly',
    'Permission Description': 'View your course work and grades in Google Classroom',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.coursework.students',
    'Permission Description': 'Manage course work and grades for students in the Google Classroom classes you teach and view the course work and grades for classes you administer',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.coursework.students.readonly',
    'Permission Description': 'View course work and grades for students in the Google Classroom classes you teach or administer',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.guardianlinks.me.readonly',
    'Permission Description': 'View your Google Classroom guardians',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.guardianlinks.students',
    'Permission Description': 'View and manage guardians for students in your Google Classroom classes',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.guardianlinks.students.readonly',
    'Permission Description': 'View guardians for students in your Google Classroom classes',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.profile.emails',
    'Permission Description': 'View the email addresses of people in your classes',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.profile.photos',
    'Permission Description': 'View the profile photos of people in your classes',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.push-notifications',
    'Permission Description': 'Receive notifications about your Google Classroom data',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.rosters',
    'Permission Description': 'Manage your Google Classroom class rosters',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.rosters.readonly',
    'Permission Description': 'View your Google Classroom class rosters',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.student-submissions.me.readonly',
    'Permission Description': 'View your course work and grades in Google Classroom',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/classroom.student-submissions.students.readonly',
    'Permission Description': 'View course work and grades for students in the Google Classroom classes you teach or administer',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud_debugger',
    'Permission Description': 'Manage cloud debugger',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloudiot',
    'Permission Description': 'Register and manage devices in the Google Cloud IoT service',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/trace.append',
    'Permission Description': 'Write Trace data for a project or application',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud.useraccounts',
    'Permission Description': 'Manage your Google Cloud User Accounts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud.useraccounts.readonly',
    'Permission Description': 'View your Google Cloud User Accounts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/compute',
    'Permission Description': 'View and manage your Google Compute Engine resources',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/compute.readonly',
    'Permission Description': 'View your Google Compute Engine resources',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/content',
    'Permission Description': 'Manage your product listings and accounts for Google Shopping',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/userinfo.email',
    'Permission Description': 'View your email address',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/datastore',
    'Permission Description': 'View and manage your Google Cloud Datastore data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ndev.cloudman',
    'Permission Description': 'View and manage your Google Cloud Platform management resources and deployment status information',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ndev.cloudman.readonly',
    'Permission Description': 'View your Google Cloud Platform management resources and deployment status information',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ddmconversions',
    'Permission Description': 'Manage DoubleClick Digital Marketing conversions',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/dfareporting',
    'Permission Description': 'View and manage DoubleClick for Advertisers reports',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/dfatrafficking',
    'Permission Description': 'View and manage your DoubleClick Campaign Manager\'s (DCM) display ad campaigns',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ndev.clouddns.readonly',
    'Permission Description': 'View your DNS records hosted by Google Cloud DNS',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ndev.clouddns.readwrite',
    'Permission Description': 'View and manage your DNS records hosted by Google Cloud DNS',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/doubleclickbidmanager',
    'Permission Description': 'View and manage your reports in DoubleClick Bid Manager',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/doubleclicksearch',
    'Permission Description': 'View and manage your advertising data in DoubleClick Search',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.appdata',
    'Permission Description': 'View and manage its own configuration data in your Google Drive',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.file',
    'Permission Description': 'View and manage Google Drive files and folders that you have opened or created with this app',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/documents.currentonly',
    'Permission Description': 'View and manage Google Drive files and folders that you have opened or created with this app',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.photos.readonly',
    'Permission Description': 'View the photos, videos and albums in your Google Photos',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/drive.scripts',
    'Permission Description': 'Modify your Google Apps Script scripts\' behavior',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/firebase',
    'Permission Description': 'View and administer all your Firebase data and settings',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/firebase.readonly',
    'Permission Description': 'View all your Firebase data and settings',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.activity.read',
    'Permission Description': 'View your activity information in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.activity.write',
    'Permission Description': 'View and store your activity information in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.blood_glucose.read',
    'Permission Description': 'View blood glucose data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.blood_glucose.write',
    'Permission Description': 'View and store blood glucose data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.blood_pressure.read',
    'Permission Description': 'View blood pressure data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.blood_pressure.write',
    'Permission Description': 'View and store blood pressure data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.body.read',
    'Permission Description': 'View body sensor information in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.body.write',
    'Permission Description': 'View and store body sensor data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.body_temperature.read',
    'Permission Description': 'View body temperature data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.body_temperature.write',
    'Permission Description': 'View and store body temperature data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.location.read',
    'Permission Description': 'View your stored location data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.location.write',
    'Permission Description': 'View and store your location data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.nutrition.read',
    'Permission Description': 'View nutrition information in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.nutrition.write',
    'Permission Description': 'View and store nutrition information in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.oxygen_saturation.read',
    'Permission Description': 'View oxygen saturation data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.oxygen_saturation.write',
    'Permission Description': 'View and store oxygen saturation data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.reproductive_health.read',
    'Permission Description': 'View reproductive health data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fitness.reproductive_health.write',
    'Permission Description': 'View and store reproductive health data in Google Fit',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fusiontables',
    'Permission Description': 'Manage your Fusion Tables',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/fusiontables.readonly',
    'Permission Description': 'View your Fusion Tables',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/games',
    'Permission Description': 'Share your Google+ profile information and view and manage your game activity',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.login',
    'Permission Description': 'Know the list of people in your circles, your age range, and language',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.profile.agerange.read',
    'Permission Description': 'Know your age range',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.moments.write',
    'Permission Description': 'Manage your Google+ activities',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.profile.emails.read',
    'Permission Description': 'View your email address',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.me',
    'Permission Description': 'Know who you are on Google',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/genomics',
    'Permission Description': 'View and manage Genomics data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/genomics.readonly',
    'Permission Description': 'View Genomics data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://mail.google.com',
    'Permission Description': 'Read, send, delete, and manage your email',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.compose',
    'Permission Description': 'Manage drafts and send emails',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.insert',
    'Permission Description': 'Insert mail into your mailbox',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.labels',
    'Permission Description': 'Manage mailbox labels',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.metadata',
    'Permission Description': 'View your email message metadata such as labels and headers, but not the email body',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.modify',
    'Permission Description': 'View and modify but not delete your email',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.readonly',
    'Permission Description': 'View your email messages and settings',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.send',
    'Permission Description': 'Send email on your behalf',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.settings.basic',
    'Permission Description': 'Manage your basic mail settings',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/gmail.settings.sharing',
    'Permission Description': 'Manage your sensitive mail settings, including who can manage your mail',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'profile',
    'Permission Description': 'View your basic profile info',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'email',
    'Permission Description': 'View your email address',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'openid',
    'Permission Description': 'Authenticate using OpenID Connect',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/apps.groups.migration',
    'Permission Description': 'Manage messages in groups on your domain',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/apps.groups.settings',
    'Permission Description': 'View and manage the settings of a Google Workspace group',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud-language',
    'Permission Description': 'Apply machine learning models to reveal the structure and meaning of text',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/apps.licensing',
    'Permission Description': 'View and manage Google Workspace licenses for your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/logging.admin',
    'Permission Description': 'Administrate log data for your projects',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/logging.read',
    'Permission Description': 'View log data for your projects',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/logging.write',
    'Permission Description': 'Submit log data for your projects',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/manufacturercenter',
    'Permission Description': 'Manage your product listings for Google Manufacturer Center',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/glass.location',
    'Permission Description': 'View your location',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/glass.timeline',
    'Permission Description': 'View and manage your Glass timeline',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/monitoring',
    'Permission Description': 'View and write monitoring data for all of your Google and third-party Cloud and API projects',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/monitoring.read',
    'Permission Description': 'View monitoring data for all of your Google Cloud and third-party projects',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/monitoring.write',
    'Permission Description': 'Publish metric data to your Google Cloud projects',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/contacts',
    'Permission Description': 'Manage your contacts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/contacts.readonly',
    'Permission Description': 'View your contacts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/user.addresses.read',
    'Permission Description': 'View your street addresses',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/user.birthday.read',
    'Permission Description': 'View your complete date of birth',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/user.emails.read',
    'Permission Description': 'View your email addresses',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/user.phonenumbers.read',
    'Permission Description': 'View your phone numbers',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.circles.read',
    'Permission Description': 'View your circles and the people and pages in them',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.circles.members.read',
    'Permission Description': 'View your circles and the people and pages in them',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.circles.write',
    'Permission Description': 'Manage your circles and add people and pages. People and pages you add to your circles will be notified. Others may see this information publicly. People you add to circles can use Hangouts with you.',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.media.upload',
    'Permission Description': 'Send your photos and videos to Google+',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.profiles.read',
    'Permission Description': 'View your own Google+ profile and profiles visible to you',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.stream.read',
    'Permission Description': 'View your Google+ posts, comments, and stream',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/plus.stream.write',
    'Permission Description': 'Manage your Google+ posts, comments, and stream',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/userinfo.profile',
    'Permission Description': 'View your basic profile info',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/prediction',
    'Permission Description': 'Manage your data in the Google Prediction API',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/userlocation.beacon.registry',
    'Permission Description': 'View and modify your beacons',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/pubsub',
    'Permission Description': 'View and manage Pub/Sub topics and subscriptions',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/replicapool',
    'Permission Description': 'View and manage replica pools',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/replicapool.readonly',
    'Permission Description': 'View replica pools',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/apps.order',
    'Permission Description': 'Manage users on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/apps.order.readonly',
    'Permission Description': 'Manage users on your domain',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloudruntimeconfig',
    'Permission Description': 'Manage your Google Cloud Platform services\' runtime configuration',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.google.com/calendar/feeds',
    'Permission Description': 'Manage your calendars',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.google.com/m8/feeds',
    'Permission Description': 'Manage your contacts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/admin.directory.group',
    'Permission Description': 'View and manage the provisioning of groups on your domain',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/forms',
    'Permission Description': 'View and manage your forms in Google Drive',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/forms.currentonly',
    'Permission Description': 'View and manage forms that this application has been installed in',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/groups',
    'Permission Description': 'View and manage your Google Groups',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/spreadsheets',
    'Permission Description': 'View and manage your spreadsheets in Google Drive',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'servicecontrol',
    'Permission Description': 'Report usage across Google managed services',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'cloud-platform',
    'Permission Description': 'View and manage your data across Google Cloud Platform services',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'service.management',
    'Permission Description': 'Manage your Google API service configuration',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/servicecontrol',
    'Permission Description': 'Manage your Google Service Control data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/service.management',
    'Permission Description': 'Manage your Google API service configuration',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/service.management.readonly',
    'Permission Description': 'View your Google API service configuration',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/spreadsheets.readonly',
    'Permission Description': 'View your Google Spreadsheets',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/siteverification',
    'Permission Description': 'Manage the list of sites and domains you control',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/siteverification.verify_only',
    'Permission Description': 'Manage your new site verifications with Google',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/presentations',
    'Permission Description': 'View and manage your Google Slides presentations',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/presentations.readonly',
    'Permission Description': 'View your Google Slides presentations',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/source.full_control',
    'Permission Description': 'Manage your source code repositories',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/source.read_only',
    'Permission Description': 'View the contents of your source code repositories',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/source.read_write',
    'Permission Description': 'Manage the contents of your source code repositories',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/spanner.admin',
    'Permission Description': 'Administer your Spanner databases',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/spanner.data',
    'Permission Description': 'View and manage the contents of your Spanner databases',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/sqlservice.admin',
    'Permission Description': 'Manage your Google SQL Service instances',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/streetviewpublish',
    'Permission Description': 'Publish and manage your 360 photos on Google Street View',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.delete.containers',
    'Permission Description': 'Delete your Google Tag Manager containers',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.edit.containers',
    'Permission Description': 'Manage your Google Tag Manager container and its subcomponents, excluding versioning and publishing',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
    'Permission Description': 'Manage your Google Tag Manager container versions',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.manage.accounts',
    'Permission Description': 'View and manage your Google Tag Manager accounts',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.manage.users',
    'Permission Description': 'Manage user permissions of your Google Tag Manager account and container',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.publish',
    'Permission Description': 'Publish your Google Tag Manager container versions',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tagmanager.readonly',
    'Permission Description': 'View your Google Tag Manager container and its subcomponents',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/taskqueue',
    'Permission Description': 'Manage your Tasks and Taskqueues',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/taskqueue.consumer',
    'Permission Description': 'Consume Tasks from your Taskqueues',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tasks',
    'Permission Description': 'Manage your tasks',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/tasks.readonly',
    'Permission Description': 'View your tasks',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud-translation',
    'Permission Description': 'Translate text from one language to another using Google Translate',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/urlshortener',
    'Permission Description': 'Manage your goo.gl short URLs',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ediscovery',
    'Permission Description': 'Manage your eDiscovery data',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/ediscovery.readonly',
    'Permission Description': 'View your eDiscovery data',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/cloud-vision',
    'Permission Description': 'Apply machine learning models to understand and label images',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/webmasters',
    'Permission Description': 'View and manage Search Console data for your verified sites',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/webmasters.readonly',
    'Permission Description': 'View Search Console data for your verified sites',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/youtube',
    'Permission Description': 'Manage your YouTube account',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/youtube.force-ssl',
    'Permission Description': 'Manage your YouTube account',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/youtube.readonly',
    'Permission Description': 'View your YouTube account',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/youtube.upload',
    'Permission Description': 'Manage your YouTube videos',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/youtubepartner',
    'Permission Description': 'View and manage your assets and associated content on YouTube',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/youtubepartner-channel-audit',
    'Permission Description': 'View private information of your YouTube channel relevant during the audit process with a YouTube partner',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
    'Permission Description': 'View monetary and non-monetary YouTube Analytics reports for your YouTube content',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/yt-analytics.readonly',
    'Permission Description': 'View YouTube Analytics reports for your YouTube content',
    'Sensitivity level': 3
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/script.storage',
    'Permission Description': 'Allows the project to read and write Properties',
    'Sensitivity level': 2
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/script.external_request',
    'Permission Description': 'Allows the to make UrlFetch requests',
    'Sensitivity level': 1
  },
  {
    'Service': 'google',
    'Permission Name': 'https://www.googleapis.com/auth/script.scriptapp',
    'Permission Description': 'Modify your Google Apps Script scripts\' behavior',
    'Sensitivity level': 2
  },
  {
    'Service': 'slack',
    'Permission Name': 'admin',
    'Permission Description': 'Administer your workspace',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'bot',
    'Permission Description': 'Add a bot user with the username @your_slack_app',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'channels:history',
    'Permission Description': 'Access content in your public channels',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'channels:read',
    'Permission Description': 'Access information about your public channels',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'channels:write',
    'Permission Description': 'Modify your public channels',
    'Sensitivity level': 2
  },
  {
    'Service': 'slack',
    'Permission Name': 'chat:write:bot',
    'Permission Description': 'Send messages as your slack app',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'chat:write:user',
    'Permission Description': 'Send messages as you',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'client',
    'Permission Description': 'Receive all events from a workspace in realtime',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'commands',
    'Permission Description': 'Add commands to a workspace',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'dnd:read',
    'Permission Description': 'Access your workspace’s Do Not Disturb settings',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'dnd:write',
    'Permission Description': 'Modify your Do Not Disturb settings',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'emoji:read',
    'Permission Description': 'Access your workspace’s emoji',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'files:read',
    'Permission Description': 'Access your workspace’s files, comments, and associated information',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'files:write:user',
    'Permission Description': 'Upload and modify files as you',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'groups:history',
    'Permission Description': 'Access content in your private channels',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'groups:read',
    'Permission Description': 'Access information about your private channels',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'groups:write',
    'Permission Description': 'Modify your private channels',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'identify',
    'Permission Description': 'Confirm your identity',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'identity.avatar',
    'Permission Description': 'View your Slack avatar',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'identity.basic',
    'Permission Description': 'Confirm your identity',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'identity.email',
    'Permission Description': 'View your email address',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'identity.team',
    'Permission Description': 'View your Slack workspace name',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'im:history',
    'Permission Description': 'Access content in your direct messages',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'im:read',
    'Permission Description': 'Access information about your direct messages',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'im:write',
    'Permission Description': 'Modify your direct messages',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'incoming-webhook',
    'Permission Description': 'Post to specific channels in Slack',
    'Sensitivity level': 2
  },
  {
    'Service': 'slack',
    'Permission Name': 'links:read',
    'Permission Description': 'View some URLs in messages',
    'Sensitivity level': 2
  },
  {
    'Service': 'slack',
    'Permission Name': 'links:write',
    'Permission Description': 'Add link previews to messages',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'mpim:history',
    'Permission Description': 'Access your group messages',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'mpim:read',
    'Permission Description': 'Access information about your group messages',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'mpim:write',
    'Permission Description': 'Make changes to your group messages',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'pins:read',
    'Permission Description': 'Access your workspace’s pinned content and associated information',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'pins:write',
    'Permission Description': 'Add and remove pinned messages and files',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'post',
    'Permission Description': 'Post messages to a workspace',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'reactions:read',
    'Permission Description': 'Access your workspace’s content with emoji reactions',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'reactions:write',
    'Permission Description': 'Modify emoji reactions',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'read',
    'Permission Description': 'Access all content in a workspace',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'reminders:read',
    'Permission Description': 'Access reminders created by you or for you',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'reminders:write',
    'Permission Description': 'Add, remove, or complete reminders for you',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'search:read',
    'Permission Description': 'Search your workspace’s content',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'stars:read',
    'Permission Description': 'Access your starred messages and files',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'stars:write',
    'Permission Description': 'Add or remove stars for you',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'team:read',
    'Permission Description': 'Access information about your workspace',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'usergroups:read',
    'Permission Description': 'Access basic information about your User Groups',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'usergroups:write',
    'Permission Description': 'Change your User Groups',
    'Sensitivity level': 3
  },
  {
    'Service': 'slack',
    'Permission Name': 'users.profile:read',
    'Permission Description': 'Access your profile and your workspace’s profile fields',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'users.profile:write',
    'Permission Description': 'Modify your profile',
    'Sensitivity level': 2
  },
  {
    'Service': 'slack',
    'Permission Name': 'users:read',
    'Permission Description': 'Access your workspace’s profile information',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'users:read.email',
    'Permission Description': 'View email addresses of people on your workspace',
    'Sensitivity level': 1
  },
  {
    'Service': 'slack',
    'Permission Name': 'users:write',
    'Permission Description': 'Modify your profile information',
    'Sensitivity level': 1
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Calendars.Read',
    'Permission Description': 'Read user calendars',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read events in user calendars.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Calendars.Read.Shared',
    'Permission Description': 'Read user and shared calendars',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read events in all calendars that the user can access, including delegate and shared calendars.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Calendars.ReadWrite',
    'Permission Description': 'Have full access to user calendars',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete events in user calendars.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Calendars.ReadWrite.Shared',
    'Permission Description': 'Read and write user and shared calendars',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update and delete events in all calendars the user has permissions to access. This includes delegate and shared calendars.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Calendars.Read',
    'Permission Description': 'Read calendars in all mailboxes',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read events of all calendars without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Calendars.ReadWrite',
    'Permission Description': 'Read and write calendars in all mailboxes',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete events of all calendars without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Contacts.Read',
    'Permission Description': 'Read user contacts',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read user contacts.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Contacts.Read.Shared',
    'Permission Description': 'Read user and shared contacts',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read contacts that the user has permissions to access, including the user\'s own and shared contacts.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Contacts.ReadWrite',
    'Permission Description': 'Have full access to user contacts',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete user contacts.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Contacts.ReadWrite.Shared',
    'Permission Description': 'Read and write user and shared contacts',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update and delete contacts that the user has permissions to, including the user\'s own and shared contacts.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Contacts.Read',
    'Permission Description': 'Read contacts in all mailboxes',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read all contacts in all mailboxes without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Contacts.ReadWrite',
    'Permission Description': 'Read and write contacts in all mailboxes',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete all contacts in all mailboxes without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Device.Read',
    'Permission Description': 'Read user devices',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read a user\'s list of devices on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Device.Command',
    'Permission Description': 'Communicate with user devices',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to launch another app or communicate with another app on a user\'s device on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Device.ReadWrite.All',
    'Permission Description': 'Read and write devices',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read and write all device properties without a signed in user. Does not allow device creation, device deletion, or update of device alternative security identifiers.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementApps.Read.All',
    'Permission Description': 'Read Microsoft Intune apps',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read the properties, group assignments and status of apps, app configurations and app protection policies managed by Microsoft Intune.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementApps.ReadWrite.All',
    'Permission Description': 'Read and write Microsoft Intune apps',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write the properties, group assignments and status of apps, app configurations and app protection policies managed by Microsoft Intune.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementConfiguration.Read.All',
    'Permission Description': 'Read Microsoft Intune device configuration and policies',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read properties of Microsoft Intune-managed device configuration and device compliance policies and their assignment to groups.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementConfiguration.ReadWrite.All',
    'Permission Description': 'Read and write Microsoft Intune device configuration and policies',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write properties of Microsoft Intune-managed device configuration and device compliance policies and their assignment to groups.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementManagedDevices.PrivilegedOperations.All',
    'Permission Description': 'Perform user-impacting remote actions on Microsoft Intune devices',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to perform remote high impact actions such as wiping the device or resetting the passcode on devices managed by Microsoft Intune.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementManagedDevices.Read.All',
    'Permission Description': 'Read Microsoft Intune devices',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read the properties of devices managed by Microsoft Intune.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementManagedDevices.ReadWrite.All',
    'Permission Description': 'Read and write Microsoft Intune devices',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write the properties of devices managed by Microsoft Intune. Does not allow high impact operations such as remote wipe and password reset on the device’s owner.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementRBAC.Read.All',
    'Permission Description': 'Read Microsoft Intune RBAC settings',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read the properties relating to the Microsoft Intune Role-Based Access Control (RBAC) settings.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementRBAC.ReadWrite.All',
    'Permission Description': 'Read and write Microsoft Intune RBAC settings',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write the properties relating to the Microsoft Intune Role-Based Access Control (RBAC) settings.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementServiceConfig.Read.All',
    'Permission Description': 'Read Microsoft Intune configuration',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read Intune service properties including device enrollment and third party service connection configuration.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'DeviceManagementServiceConfig.ReadWrite.All',
    'Permission Description': 'Read and write Microsoft Intune configuration',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write Microsoft Intune service properties including device enrollment and third party service connection configuration.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Directory.Read.All',
    'Permission Description': 'Read directory data',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read data in your organization\'s directory, such as users, groups and apps.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Directory.ReadWrite.All',
    'Permission Description': 'Read and write directory data',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write data in your organization\'s directory, such as users, and groups. It does not allow the app to delete users or groups, or reset user passwords.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Directory.AccessAsUser.All',
    'Permission Description': 'Access directory as the signed-in user',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to have the same access to information in the directory as the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAdministration.Read',
    'Permission Description': 'Read education app settings',
    'Sensitivity level': 1,
    'Comment': 'Allows the app to read education app settings on behalf of the user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAdministration.ReadWrite',
    'Permission Description': 'Manage education app settings',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to manage education app settings on behalf of the user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.ReadBasic',
    'Permission Description': 'Read users\' class assignments without grades',
    'Sensitivity level': 1,
    'Comment': 'Allows the app to read assignments without grades on behalf of the user'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.ReadWriteBasic',
    'Permission Description': 'Read and write users\' class assignments without grades',
    'Sensitivity level': 1,
    'Comment': 'Allows the app to read and write assignments without grades on behalf of the user'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.Read',
    'Permission Description': 'Read users\' view of class assignments and their grades',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read assignments and their grades on behalf of the user'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.ReadWrite',
    'Permission Description': 'Read and write users\' view of class assignments and their grades',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write assignments and their grades on behalf of the user'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduRostering.ReadBasic',
    'Permission Description': 'Read a limited subset of users\' view of the roster',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read a limited subset of the data from the structure of schools and classes in an organization\'s roster and education-specific information about users to be read on behalf of the user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.ReadBasic.All',
    'Permission Description': 'Read class assignments without grades',
    'Sensitivity level': 1,
    'Comment': 'Allows the app to read assignments without grades for all users'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.ReadWriteBasic.All',
    'Permission Description': 'Read and write class assignments without grades',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read and write assignments without grades for all users'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.Read.All',
    'Permission Description': 'Read class assignments with grades',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read assignments and their grades for all users'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduAssignments.ReadWrite.All',
    'Permission Description': 'Read and write class assignments with grades',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write assignments and their grades for all users'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduRostering.ReadBasic.All',
    'Permission Description': 'Read a limited subset of the organization\'s roster.',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read a limited subset of both the structure of schools and classes in an organization\'s roster and education-specific information about all users.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduRostering.Read.All',
    'Permission Description': 'Read the organization\'s roster.',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read the structure of schools and classes in the organization\'s roster and education-specific information about all users to be read.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'EduRostering.ReadWrite.All',
    'Permission Description': 'Read and write the organization\'s roster.',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write the structure of schools and classes in the organization\'s roster and education-specific information about all users to be read and written.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.Read',
    'Permission Description': 'Read user files',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read the signed-in user\'s files.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.Read.All',
    'Permission Description': 'Read all files that user can access',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read all files the signed-in user can access.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.ReadWrite',
    'Permission Description': 'Have full access to user files',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read, create, update, and delete the signed-in user\'s files.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.ReadWrite.All',
    'Permission Description': 'Have full access to all files user can access',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read, create, update, and delete all files the signed-in user can access.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.ReadWrite.AppFolder',
    'Permission Description': 'Have full access to the application\'s folder (preview)',
    'Sensitivity level': 3,
    'Comment': '(Preview) Allows the app to read, create, update, and delete files in the application\'s folder.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.Read.Selected',
    'Permission Description': 'Read files that the user selects',
    'Sensitivity level': 3,
    'Comment': '(Preview) Allows the app to read files that the user selects. The app has access for several hours after the user selects a file.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.ReadWrite.Selected',
    'Permission Description': 'Read and write files that the user selects',
    'Sensitivity level': 3,
    'Comment': '(Preview) Allows the app to read and write files that the user selects. The app has access for several hours after the user selects a file.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Files.Read.All',
    'Permission Description': 'Read files in all site collections',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read all files in all site collections without a signed in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Group.Read.All',
    'Permission Description': 'Read all groups',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to list groups, and to read their properties and all group memberships on behalf of the signed-in user. Also allows the app to read calendar, conversations, files, and other group content for all groups the signed-in user can access.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Group.ReadWrite.All',
    'Permission Description': 'Read and write all groups',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create groups and read all group properties and memberships on behalf of the signed-in user. Additionally allows group owners to manage their groups and allows group members to update group content.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'IdentityRiskEvent.Read.All',
    'Permission Description': 'Read identity risk event information',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read identity risk event information for all users in your organization without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'IdentityProvider.Read.All',
    'Permission Description': 'Read identity provider information',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read identity providers configured in your Azure AD or Azure AD B2C tenant on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'IdentityProvider.ReadWrite.All',
    'Permission Description': 'Read and write identity provider information',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read or write identity providers configured in your Azure AD or Azure AD B2C tenant on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Mail.Read',
    'Permission Description': 'Read user mail',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read email in user mailboxes.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Mail.ReadWrite',
    'Permission Description': 'Read and write access to user mail',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete email in user mailboxes. Does not include permission to send mail.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Mail.Read.Shared',
    'Permission Description': 'Read user and shared mail',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read mail that the user can access, including the user\'s own and shared mail.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Mail.ReadWrite.Shared',
    'Permission Description': 'Read and write user and shared mail',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete mail that the user has permission to access, including the user\'s own and shared mail. Does not include permission to send mail.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Mail.Send',
    'Permission Description': 'Send mail as a user',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to send mail as users in the organization.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Mail.Send.Shared',
    'Permission Description': 'Send mail on behalf of others',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to send mail as the signed-in user, including sending on-behalf of others.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'MailboxSettings.Read',
    'Permission Description': 'Read user mailbox settings',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to the read user\'s mailbox settings. Does not include permission to send mail.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'MailboxSettings.ReadWrite',
    'Permission Description': 'Read and write user mailbox settings',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update, and delete user\'s mailbox settings. Does not include permission to send mail.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Member.Read.Hidden',
    'Permission Description': 'Read all hidden memberships',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read the memberships of hidden groups and administrative units without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Notes.Read',
    'Permission Description': 'Read user OneNote notebooks',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read the titles of OneNote notebooks and sections and to create new pages, notebooks, and sections on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Notes.Create',
    'Permission Description': 'Create user OneNote notebooks',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read the titles of OneNote notebooks and sections and to create new pages, notebooks, and sections on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Notes.ReadWrite',
    'Permission Description': 'Read and write user OneNote notebooks',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read, share, and modify OneNote notebooks on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Notes.ReadWrite.CreatedByApp',
    'Permission Description': 'Limited notebook access (deprecated)',
    'Sensitivity level': 3,
    'Comment': 'Do not use. No privileges are granted by this permission.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Notes.Read.All',
    'Permission Description': 'Read all OneNote notebooks',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read all the OneNote notebooks in your organization, without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Notes.ReadWrite.All',
    'Permission Description': 'Read and write all OneNote notebooks',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read, share, and modify all the OneNote notebooks in your organization, without a signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'email',
    'Permission Description': 'View users\' email address',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read your users\' primary email address.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'offline_access',
    'Permission Description': 'Access user\'s data anytime',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and update user data, even when they are not currently using the app.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'openid',
    'Permission Description': 'Sign users in',
    'Sensitivity level': 1,
    'Comment': 'Allows users to sign in to the app with their work or school accounts and allows the app to see basic user profile information.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'profile',
    'Permission Description': 'View users\' basic profile',
    'Sensitivity level': 1,
    'Comment': 'Allows the app to see your users\' basic profile (name, picture, user name).'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'People.Read',
    'Permission Description': 'Read users\' relevant people lists',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read a scored list of people relevant to the signed-in user. The list can include local contacts, contacts from social networking or your organization\'s directory, and people from recent communications (such as email and Skype).'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'People.Read.All',
    'Permission Description': 'Read all users\' relevant people lists',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read a scored list of people relevant to the signed-in user or other users in the signed-in user\'s organization. The list can include local contacts, contacts from social networking or your organization\'s directory, and people from recent communications (such as email and Skype). Also allows the app to search the entire directory of the signed-in user\'s organization.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Reports.Read.All',
    'Permission Description': 'Read all usage reports',
    'Sensitivity level': 2,
    'Comment': 'Allows an app to read all service usage reports without a signed-in user. Services that provide usage reports include Office 365 and Azure Active Directory.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Sites.Read.All',
    'Permission Description': 'Read items in all site collections',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read documents and list items in all site collections on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Sites.ReadWrite.All',
    'Permission Description': 'Read and write items in all site collections',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to edit or delete documents and list items in all site collections on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Sites.Manage.All',
    'Permission Description': 'Create, edit, and delete items and lists in all site collections',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to manage and create lists, documents, and list items in all site collections on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Sites.FullControl.All',
    'Permission Description': 'Have full control of all site collections',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to have full control to SharePoint sites in all site collections on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Tasks.Read',
    'Permission Description': 'Read user tasks',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read user tasks.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Tasks.Read.Shared',
    'Permission Description': 'Read user and shared tasks',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read tasks a user has permissions to access, including their own and shared tasks.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Tasks.ReadWrite',
    'Permission Description': 'Create, read, update and delete user tasks and containers',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to create, read, update and delete tasks and containers (and tasks in them) that are assigned to or shared with the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'Tasks.ReadWrite.Shared',
    'Permission Description': 'Read and write user and shared tasks',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to create, read, update, and delete tasks a user has permissions to, including their own and shared tasks.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'User.Read',
    'Permission Description': 'Sign-in and read user profile',
    'Sensitivity level': 2,
    'Comment': 'Allows users to sign-in to the app, and allows the app to read the profile of signed-in users. It also allows the app to read basic company information of signed-in users.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'User.ReadWrite',
    'Permission Description': 'Read and write access to user profile',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read your profile. It also allows the app to update your profile information on your behalf.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'User.ReadBasic.All',
    'Permission Description': 'Read all users\' basic profiles',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read a basic set of profile properties of other users in your organization on behalf of the signed-in user. This includes display name, first and last name, email address and photo.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'User.Read.All',
    'Permission Description': 'Read all users\' full profiles',
    'Sensitivity level': 2,
    'Comment': 'Allows the app to read the full set of profile properties, reports, and managers of other users in your organization, on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'User.ReadWrite.All',
    'Permission Description': 'Read and write all users\' full profiles',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to read and write the full set of profile properties, reports, and managers of other users in your organization, on behalf of the signed-in user. Also allows the app to create and delete users as well as reset user passwords on behalf of the signed-in user.'
  },
  {
    'Service': 'azure_ad',
    'Permission Name': 'User.Invite.All',
    'Permission Description': 'Invite guest users to the organization',
    'Sensitivity level': 3,
    'Comment': 'Allows the app to invite guest users to your organization, on behalf of the signed-in user.'
  }
]

const permissionsById = keyBy(permissions, permission => `${permission.Service}_${permission['Permission Name']}`)

export const getPermissionDetails = (source, permissionName) => permissionsById[`${source}_${permissionName}`]

export const getPermissionDescription = (source, permissionName) => {
  const permissionDetails = getPermissionDetails(source, permissionName)
  return permissionDetails ? permissionDetails['Permission Description'] : permissionName
}

export const getPermissionSensitivityLevel = (source, permissionName) => {
  const permissionDetails = getPermissionDetails(source, permissionName) || {}
  return permissionDetails['Sensitivity level'] || 1
}

export default permissionsById
