import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  NameContainer,
  Square,
  Tooltip,
  TooltipBreakdownWrapper,
  TooltipHeader,
  TooltipRow
} from '@components/renewalsSummary/styles'
import { Body2, Caption1, Spacer, Subtitle2 } from '@toriihq/design-system'
import Currency from '@components/currency'
import { graphBucketDisplayName, TRANSPARENT } from '@components/renewalsSummary/utils/utils'
import { Rectangle } from 'recharts'
import { ACTUAL_VALUE_SUFFIX, BREAKDOWN_SUFFIX, COUNT_SUFFIX } from '@components/renewalsSummary/utils/calculateGraphData'
import Analytics from '@components/renewalsSummary/analytics'

export const renderToolTip = (baseYear) => ({ payload, label }) => {
  const [lastHoveredColumn, setLastHoveredColumn] = useState(null)

  if (!payload) {
    return null
  }

  useEffect(() => {
    if (label && label !== lastHoveredColumn) {
      setLastHoveredColumn(label)
      Analytics.hoverChart(label)
    }
  }, [label, lastHoveredColumn])

  const showLabel = (label) => label?.includes(baseYear.label)

  const shouldShowRowBreakdown = (item) => !isEmpty(item.payload[`${item.name}${BREAKDOWN_SUFFIX}`])

  const RenderTooltipRow = ({ dataKey, squareColor, name, count, value, textColor, rowMarginBottom }) => (
    <TooltipRow marginBottom={rowMarginBottom} key={dataKey}>
      <NameContainer>
        <Square color={squareColor} />
        <Spacer right='space-050'>
          <Body2 color={textColor}>{name}</Body2>
        </Spacer>
        <Body2 color='tertiary'>({count})</Body2>
      </NameContainer>
      <Body2 color={textColor}>
        <Currency value={value * 100} />
      </Body2>
    </TooltipRow>
  )

  const filteredPayload = payload.filter(item => item.fill !== TRANSPARENT)
  const filteredPayloadRenewalsCount = filteredPayload.reduce((acc, item) => acc + item.payload[`${item.name}${COUNT_SUFFIX}`], 0)

  return (
    <Tooltip>
      {showLabel(label)
        ? <TooltipHeader>
          <Subtitle2 color='primary'>{label.toUpperCase()}</Subtitle2>
          <Caption1 color='tertiary'>{filteredPayloadRenewalsCount} applications</Caption1>
        </TooltipHeader>
        : null}
      {filteredPayload.map(item => (
        <div>
          <RenderTooltipRow dataKey={item.dataKey} squareColor={item.fill} name={item.name} count={item.payload[`${item.name}${COUNT_SUFFIX}`]} value={item.payload[`${item.name}${ACTUAL_VALUE_SUFFIX}`]} textColor={'secondary'} rowMarginBottom={shouldShowRowBreakdown(item) ? 8 : 16} />
          {shouldShowRowBreakdown(item) &&
            <TooltipBreakdownWrapper >
              {item.payload[`${item.name}${BREAKDOWN_SUFFIX}`].map(breakdownItem => (
                <RenderTooltipRow dataKey={breakdownItem} squareColor={TRANSPARENT} name={breakdownItem.name} count={breakdownItem.count} value={breakdownItem.value} textColor={'tertiary'} rowMarginBottom={0} />
              ))}
            </TooltipBreakdownWrapper>
          }
        </div>
      ))}
    </Tooltip>
  )
}

export const CustomBar = (baseYear) => (props) => {
  const { fill, x, y, width, height, name } = props

  let borderRadius: [number, number, number, number] = [4, 4, 4, 4]

  if (name.includes(baseYear.label)) {
    const value = props.value.map(val => val.toFixed(2))
    const total = props.adjustedTotal.toFixed(2)
    if (value[0] === 0 && value[1] === total) {
      borderRadius = [4, 4, 4, 4]
    } else if (value[0] === 0) {
      borderRadius = [0, 0, 4, 4]
    } else if (value[1] === total) {
      borderRadius = [4, 4, 0, 0]
    } else {
      borderRadius = [0, 0, 0, 0]
    }
  }
  const isNotRenewingThisYearBar = name.includes(baseYear.label) && props[graphBucketDisplayName.notRenewingThisYear] > 0 && props.value[0] === 0

  return <Rectangle style={{ cursor: fill === TRANSPARENT || (name.includes(baseYear.label) && !isNotRenewingThisYearBar) ? 'default' : 'pointer' }} fill={fill} x={x} y={y} width={width} height={height} radius={borderRadius} />
}

export const CustomizedLabel = (props) => {
  const {
    x, y, width, value
  } = props

  return (
    <text x={x + width / 2} y={y - 12} fill='#666' textAnchor='middle' dominantBaseline='middle'>
      <Currency value={value * 100} />
    </text>
  )
}
