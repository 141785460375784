import keyBy from 'lodash/keyBy'
import { getNodeDimensions } from '../getNodeDimensions'
import groupBy from 'lodash/groupBy'
import {
  ReactflowGraphData,
  WORKFLOW_UI_NODE_TYPE,
  WorkflowUiNode
} from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { DfsParams } from './getPositionedNodes.types'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'

const VERTICAL_PADDING_BETWEEN_NODES = 80
const HORIZONTAL_PADDING_BETWEEN_NODES = 40

/**
 * #### Positioning Rule:
 * Every node should be horizontally centered over its descendants.
 *
 * #### Detailed Steps:
 * The algorithm performs a Depth-First Search (DFS) starting from the root node and applies
 * the following steps for each node:
 *
 * 1. **Calculate Vertical Position (`accumulatedHeight`)**:
 *    - The vertical distance from the root node is calculated by summing up the heights of all ancestors.
 *
 * 2. **Calculate Horizontal Position**:
 *    - The horizontal position of a node is recursively determined based on the width required to display its children.
 *    - The node is then centered within this calculated width.
 *      When calculating the horizontal position of a node, the following parameters are considered:
 *      - `parentX`: The initial horizontal position of the parent node. Serves as a starting point for
 *        calculating child positions.
 *      - `nodeHorizontalSpace`: The width required for positioning the node. It's either the sum of its children's
 *        widths or the node's own width, whichever is greater.
 *      - `currentWidestAncestorWidth`: The width of the widest ancestor on the same path. Used to center a node with
 *        its ancestors if both it and its children are narrower than its ancestors.
 */

export const getPositionedNodes = (graph: ReactflowGraphData): WorkflowUiNode[] => {
  const positionedNodes: WorkflowUiNode[] = []
  const nodeById = keyBy(graph.nodes, 'id')
  const edgesBySource = groupBy(graph.edges, 'source')

  const calculateChildrenWidth = ({
    idNode,
    parentX,
    accumulatedHeight,
    widestAncestorWidth
  }: DfsParams): number => {
    let childrenWidth = 0
    const childEdges = edgesBySource[idNode] || []
    const doesNodeHaveSingleChild = childEdges.length === 1

    childEdges.forEach((edge, index) => {
      if (index > 0) {
        childrenWidth += HORIZONTAL_PADDING_BETWEEN_NODES
      }

      const childWidth = dfs({
        idNode: edge.target,
        parentX: parentX + childrenWidth,
        accumulatedHeight: accumulatedHeight + VERTICAL_PADDING_BETWEEN_NODES,
        widestAncestorWidth: doesNodeHaveSingleChild ? widestAncestorWidth : 0
      })
      childrenWidth += childWidth
    })

    return childrenWidth
  }

  const dfs = ({
    idNode,
    parentX,
    accumulatedHeight,
    widestAncestorWidth
  }: DfsParams): number => {
    const node = nodeById[idNode]
    if (!node) {
      return 0
    }

    const { height, width } = getNodeDimensions(node.type)
    const totalVerticalHeight = accumulatedHeight + height

    const currentWidestAncestorWidth = Math.max(width, widestAncestorWidth)

    const childrenWidth = calculateChildrenWidth({
      idNode,
      parentX,
      accumulatedHeight: totalVerticalHeight,
      widestAncestorWidth: currentWidestAncestorWidth
    })

    const nodeHorizontalSpace = Math.max(width, childrenWidth)
    const isWiderAncestorExist = currentWidestAncestorWidth > nodeHorizontalSpace
    const neededWidthToAlignWithAncestors = isWiderAncestorExist ? (currentWidestAncestorWidth - width) / 2 : 0
    const centerOffset = (nodeHorizontalSpace - width) / 2
    const positionX = parentX + centerOffset + neededWidthToAlignWithAncestors

    positionedNodes.push({
      ...node,
      position: {
        x: positionX,
        y: accumulatedHeight
      }
    })

    return nodeHorizontalSpace
  }

  dfs({
    idNode: ID_TRIGGER_NODE,
    parentX: 0,
    accumulatedHeight: 0,
    widestAncestorWidth: 0
  })

  const centerTreeHorizontallyToX0 = () => {
    const triggerNode = positionedNodes.find(node => node.id === ID_TRIGGER_NODE)!
    const { width: triggerNodeWidth } = getNodeDimensions(WORKFLOW_UI_NODE_TYPE.TRIGGER)
    const treeOffsetFromX0 = triggerNode.position.x + (triggerNodeWidth / 2)
    for (const node of positionedNodes) {
      node.position.x -= treeOffsetFromX0
    }
  }

  centerTreeHorizontallyToX0()

  return positionedNodes
}
