import styled from 'styled-components'
import { Row as basicRow, ScreenBreakpoints } from '@toriihq/design-system'

export const Row = styled(basicRow)`
  height: ${(props: { $rowHeight: string }) => props.$rowHeight};
  .col > * {
    height: 100%;
  }

  @media (max-width: ${ScreenBreakpoints.sm}) {
    height: auto !important;
    .col {
      margin-bottom: 24px;
      height:  ${(props: { $rowHeight: string }) => props.$rowHeight};
    } 
  }
`
