import colors from '@root/shared/style/colors'
import { fontSize, fontWeight } from '@root/shared/style/sizes'
import { css } from 'glamor'

export const Container = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
})

export const Header = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '32px'
})

export const RecommendationDetails = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px'
})

export const LicenseRecommendationContainer = css({
  flexGrow: 1
})

export const EmptyStateContainer = css({
  width: '100%',
  height: '100%',
  marginTop: '10%'
})

export const EmptyState = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px'
})

export const TextContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
})

export const EmptyStateTitle = css({
  fontSize: `${fontSize.large}`,
  fontWeight: `${fontWeight.semiBold}`,
  color: colors.grey1
})

export const EmptyStateText = css({
  fontSize: `${fontSize.mediumLarge}`,
  color: `${colors.grey2}`,
  width: '280px'
})
