import KeyValueWidget from './keyValueWidget'
import CustomWidget from './customWidget'
import { formFieldTypes } from '@root/constants'

export enum WIDGETS {
  KeyValue = 'KeyValue',
  Custom = 'Custom'
}

export const TYPE_TO_WIDGET = {
  [WIDGETS.KeyValue]: KeyValueWidget,
  [WIDGETS.Custom]: CustomWidget
}

export const WIDGET_FIELD_TYPES = {
  ...formFieldTypes,
  creditCard: 'creditCard',
  deepLink: 'deepLink',
  horizontalRule: 'horizontalRule'
}
