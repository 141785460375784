import {
  ActionNodeModel,
  BranchNodeModel,
  IdWorkflowNode,
  WorkflowActionsModel,
  ActionWithFixedBranchesNodeModel
} from '@shared/workflows/types'
import { ReactflowGraphData } from '../../types'
import { getBranchActionBranchesData } from '@shared/workflows/actions/utils/getBranchActionBranchesData'
import { dfsTraverseActions } from '@shared/workflows/actions/utils/dfsTraverseActions'
import {
  getActionUiNode,
  getBranchActionUiNode,
  getBranchLabelUiNode,
  getDefaultBranchLabelUiNode
} from './nodeFactory'
import { getEdge, getEdgeWithAddButton } from './edgeFactory'
import { getEndNodeData } from './utils'
import { getFixedBranchesData } from '@shared/workflows/actions/utils/getFixedBranchesData'
import { AddBranchesDataToGraphParams } from './types'

export const addActionDataToGraph = (graph: ReactflowGraphData, idNode: IdWorkflowNode, actionNode: ActionNodeModel): ReactflowGraphData => {
  const nodes = [...graph.nodes]
  const edges = [...graph.edges]

  nodes.push(getActionUiNode(idNode))

  if (actionNode.next) {
    edges.push(getEdgeWithAddButton({ source: idNode, target: actionNode.next }))
  } else {
    const { endNode, endNodeEdge } = getEndNodeData({ idLastNode: idNode })
    nodes.push(endNode)
    edges.push(endNodeEdge)
  }

  return { nodes, edges }
}

const addBranchesDataToGraph = ({
  idNode,
  branches,
  defaultBranch,
  nodes,
  edges
}: AddBranchesDataToGraphParams): ReactflowGraphData => {
  branches.forEach((branch, index) => {
    const branchNode = getBranchLabelUiNode({
      idBranchingNode: idNode,
      branchIndex: index
    })
    nodes.push(branchNode)
    edges.push(getEdge({ source: idNode, target: branchNode.id }))

    if (branch.next) {
      edges.push(getEdgeWithAddButton({ source: branchNode.id, target: branch.next }))
    } else {
      const { endNode, endNodeEdge } = getEndNodeData({ idLastNode: branchNode.id })
      nodes.push(endNode)
      edges.push(endNodeEdge)
    }
  })

  const defaultBranchNode = getDefaultBranchLabelUiNode({ idBranchingNode: idNode })
  nodes.push(defaultBranchNode)
  edges.push(getEdge({ source: idNode, target: defaultBranchNode.id }))

  if (defaultBranch.next) {
    edges.push(getEdgeWithAddButton({
      source: defaultBranchNode.id,
      target: defaultBranch.next
    }))
  } else {
    const { endNode, endNodeEdge } = getEndNodeData({ idLastNode: defaultBranchNode.id })
    nodes.push(endNode)
    edges.push(endNodeEdge)
  }

  return { nodes, edges }
}

export const addBranchActionDataToGraph = (graph: ReactflowGraphData, idNode: IdWorkflowNode, branchNode: BranchNodeModel): ReactflowGraphData => {
  const nodes = [...graph.nodes]
  const edges = [...graph.edges]

  nodes.push(getBranchActionUiNode(idNode))
  const { defaultBranch, branches } = getBranchActionBranchesData(branchNode)

  return addBranchesDataToGraph({
    idNode,
    branches,
    defaultBranch,
    nodes,
    edges
  })
}

export const addActionWithFixedBranchesDataToGraph = (graph: ReactflowGraphData, idNode: IdWorkflowNode, actionWithBranches: ActionWithFixedBranchesNodeModel): ReactflowGraphData => {
  const nodes = [...graph.nodes]
  const edges = [...graph.edges]

  nodes.push(getActionUiNode(idNode))
  const { defaultBranch, branches } = getFixedBranchesData(actionWithBranches)

  return addBranchesDataToGraph({
    idNode,
    branches,
    defaultBranch,
    nodes,
    edges
  })
}

export const getActionsGraphWithoutPositioning = ({ idRootNode, nodes }: WorkflowActionsModel): ReactflowGraphData => {
  let graph: ReactflowGraphData = { nodes: [], edges: [] }

  if (!idRootNode) { return graph }

  dfsTraverseActions({
    idCurrentNode: idRootNode,
    nodes,
    handleAction: ({ idNode, actionNode }) => {
      graph = addActionDataToGraph(graph, idNode, actionNode)
    },
    handleBranchAction: ({ idNode, branchNode }) => {
      graph = addBranchActionDataToGraph(graph, idNode, branchNode)
    },
    handleActionWithFixedBranches: ({ idNode, actionNode }) => {
      graph = addActionWithFixedBranchesDataToGraph(graph, idNode, actionNode)
    }
  })

  return graph
}
