import React from 'react'
import { css } from 'glamor'
import { Counter, Spacer, Icon, generateAttributeId } from '@toriihq/design-system'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import colors from '../../shared/style/colors'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Analytics from '@helpers/analytics'
import capitalize from 'lodash/capitalize'
import VisibleFor from '@root/components/visibleFor'
import SuggestToUpgrade from '@components/suggestToUpgrade'
import * as Style from './style'

const generateCSS = (tabContentPadding) => ({
  backIcon: css({
    marginRight: '10px'
  }),
  tabList: css({
    listStyle: 'none',
    display: 'flex',
    marginTop: '20px',
    marginBottom: '0',
    width: '100%',
    paddingLeft: '0',
    overflowX: 'auto'
  }),
  tabListVertical: css({
    flexDirection: 'column',
    marginTop: 0,
    width: 200,
    minWidth: 200
  }),
  tabsSeparator: css({
    borderBottom: `1px solid ${colors.border}`,
    marginBottom: '0'
  }),
  selectedTab: css({
    boxShadow: `0px -2px 0px 0px ${colors.blue} inset`,
    color: colors.blue,
    transition: 'color .2s'
  }),
  selectedTabVertical: css({
    color: colors.blue
  }),
  hideTabs: css({
    display: 'none'
  }),
  tabContent: css({
    paddingTop: tabContentPadding,
    overflow: 'initial',
    flex: 1,
    minWidth: 0
  }),
  tab: css({
    height: 52,
    width: 'auto',
    padding: 0,
    marginRight: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0px -2px 0px 0px ${colors.white} inset`,
    ':hover': {
      color: colors.blue,
      transition: 'color .2s',
      ' [class*="Counter__Wrapper-design-system"]': {
        backgroundColor: '#DFEBFF',
        color: '#0E1C28'
      },
      ' > a > span': {
        color: `${colors.blue} !important`,
        transition: 'color .2s'
      }
    },
    ' > a': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%'
    }
  }),
  tabVertical: css({
    height: 36,
    width: 'auto',
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      color: colors.blue,
      transition: 'color .2s',
      ' > a > span': {
        color: `${colors.blue} !important`,
        transition: 'color .2s'
      }
    },
    ' > a': {
      display: 'flex',
      height: '100%',
      width: '100%',
      padding: '8px 0',
      gap: '8px !important',
      justifyContent: 'flex-start',
      ' > span': {
        paddingRight: 0
      }
    },
    '&:hover': {
      ' .lockIcon': {
        opacity: 1
      }
    }
  }),
  contentWrapper: css({
    display: 'flex',
    width: '100%',
    marginTop: 32
  }),
  divider: css({
    borderRight: `1px solid ${colors.border}`,
    margin: '0 24px 10px 0px'
  }),
  tabLink: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  lockIcon: css({
    marginLeft: '8px',
    opacity: 0,
    transition: 'opacity 200ms',
    height: '20px',
    width: '20px'
  }),
  showLockIcon: css({
    opacity: 1
  })
})

class TabsPage extends React.Component {
  render () {
    const { isSmallScreen, selectedIndex, tabsHeader, tabsContent, pageHeader, pageTitle, nonAccessibleTabIndices, tabsFeature,
      tabsSeparator, menuButtonText, menuButtonOnClick, menuButtonOutline, allowedScopes = [],
      path, tabsName, fixedHeader, headerButtonAllowForToriiAdmin = false, vertical = false,
      contentRight, contentBelow, pageName, hideTabs, isSecondary = false, backLink } = this.props
    const showVertical = vertical && !isSmallScreen
    const contentPadding = showVertical ? 0 : 30
    const CSS = generateCSS(contentPadding)
    const tabsClass = css(CSS.tabList, showVertical && CSS.tabListVertical, !showVertical && tabsSeparator && CSS.tabsSeparator)
    const contentWrapperClass = css(showVertical ? CSS.contentWrapper : '')
    const isNonAccessibleTabSelected = nonAccessibleTabIndices.includes(selectedIndex)

    const onTabClick = (tabName) => {
      Analytics.track(`Click on tab / ${pageName}`, {
        Tab: capitalize(tabName)
      })
    }

    const tabHeaderCounter = (header, counter, isSelected, isLoading, needsAttention) => {
      const shouldShowCounter = !isLoading && Boolean(counter)
      const counterComponent = <Counter value={counter} selected={isSelected} needsAttention={needsAttention} />
      const counterComponentWrap = showVertical
        ? counterComponent
        : <Spacer left={'space-100'}>
          { counterComponent }
        </Spacer>
      return <>
        <Style.TabText isSelected={isSelected}>{header}</Style.TabText>
        {shouldShowCounter && counterComponentWrap}
      </>
    }

    const tabsMenu = (
      <div {...css(showVertical ? CSS.divider : null)}>
        <ul {...tabsClass}>
          {tabsHeader.map((tabHeader, index) => {
            const isSelected = selectedIndex === index
            const { header, counter, isLoading, needsAttention = false, accessScopes } = tabHeader
            const isTabLocked = nonAccessibleTabIndices.includes(index)
            const shouldShowLockIcon = ((isSelected || !showVertical) && isTabLocked)
            const iconCSS = css(CSS.lockIcon, shouldShowLockIcon ? CSS.showLockIcon : {})
            return (
              <VisibleFor key={`visibleFor-header-${header}`} scopes={accessScopes || []}>
                <li key={`header_${index}`} {...css(showVertical ? CSS.tabVertical : CSS.tab, isSelected && (showVertical ? CSS.selectedTabVertical : CSS.selectedTab))} data-intercom-target={generateAttributeId(`intercom-tab-${header}`)}>
                  <Link onClick={() => onTabClick(tabsName[index])} to={`${path}/${tabsName[index]}`} {...css(CSS.tabLink)}>
                    {tabHeaderCounter(header, counter, isSelected, isLoading, needsAttention)}
                  </Link>
                  { isTabLocked && <div className='lockIcon' {...iconCSS}>
                    <Icon name='Lock' color='inherit' />
                  </div> }
                </li>
              </VisibleFor>
            )
          })}
        </ul>
      </div>
    )

    const header = (
      <div style={{ flex: 1 }}>
        {pageHeader && <PageHeader
          allowForToriiAdmin={headerButtonAllowForToriiAdmin}
          title={pageHeader}
          menuButtonText={menuButtonText}
          menuButtonOnClick={menuButtonOnClick}
          menuButtonOutline={menuButtonOutline}
          allowedScopes={allowedScopes}
          contentRight={contentRight}
          contentBelow={contentBelow}
          isSecondary={isSecondary}
          overrideStyle={css({ marginBottom: 0, paddingBottom: 0 }, fixedHeader && { marginTop: 0, paddingTop: 0 })}
        />}
        {!showVertical && !hideTabs && tabsMenu}
      </div>
    )

    const overrideHeaderStyle = { marginBottom: 0, paddingBottom: 0 }
    return (
      <Page title={pageTitle} header={fixedHeader && header} overrideHeaderStyle={overrideHeaderStyle}>
        {!fixedHeader && backLink}
        {!fixedHeader && header}
        <div {...contentWrapperClass}>
          {showVertical && tabsMenu}
          <div {...css(tabsSeparator && CSS.tabContent)}>
            {isNonAccessibleTabSelected ? <SuggestToUpgrade feature={tabsFeature[selectedIndex]} /> : tabsContent[selectedIndex]}
          </div>
        </div>
      </Page>
    )
  }
}

TabsPage.propTypes = {
  pageHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  menuButtonText: PropTypes.string,
  menuButtonOnClick: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  tabsName: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  tabsHeader: PropTypes.array.isRequired,
  tabsContent: PropTypes.array.isRequired,
  tabsSeparator: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  contentRight: PropTypes.node,
  contentBelow: PropTypes.node,
  pageName: PropTypes.string.isRequired,
  hideTabs: PropTypes.bool,
  isSecondary: PropTypes.bool,
  backLink: PropTypes.element
}

TabsPage.defaultProps = {
  tabsSeparator: true,
  menuButtonText: '',
  menuButtonOnClick: () => true,
  fixedHeader: false,
  menuButtonOutline: true,
  hideTabs: false,
  isSecondary: false
}

export default TabsPage
