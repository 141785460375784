import { isEqual, isNil } from 'lodash'
import { formFieldTypes } from '@root/constants'

interface Field {
  id: string
  value?: any
  isValid?: boolean
  type: string
  options?: any[]
}

interface Params {
  currentField: Field
  prevFields: Field[]
  actionConfig: {
    inputSchema: Record<string, {
      disabledUntilFieldsAreValid?: string[]
      showOnFieldSpecificValue?: { id: string, value: any }
    }>
  }
}

export const shouldLoadDynamicFieldsOptions = ({
  currentField,
  prevFields,
  actionConfig
}: Params): boolean => {
  const prevField = prevFields.find(field => field.id === currentField.id)
  const isValueChanged = !isEqual(currentField.value, prevField?.value)
  const isValidationDataUpdated = !isNil(currentField.isValid) && currentField.isValid !== prevField?.isValid

  if (!isValidationDataUpdated && !isValueChanged) {
    return false
  }

  const overridableFields = [
    formFieldTypes.overridableDropdown,
    formFieldTypes.overridableDropdownMulti
  ]
  const isOverridableField = overridableFields.includes(currentField.type)
  const shouldLoadOverridableFieldOptions = isOverridableField && !currentField.options?.length

  const inputSchemaFields = Object.values(actionConfig.inputSchema)
  const doesFieldAffectOtherFields = inputSchemaFields.some(fieldConfig => {
    const isFieldShownBasedOnCurrentFieldValue =
      fieldConfig.showOnFieldSpecificValue?.id === currentField.id &&
      fieldConfig.showOnFieldSpecificValue?.value === (currentField.value?.value || currentField.value)

    return fieldConfig.disabledUntilFieldsAreValid?.includes(currentField.id) ||
      isFieldShownBasedOnCurrentFieldValue
  })

  return shouldLoadOverridableFieldOptions || doesFieldAffectOtherFields
}
