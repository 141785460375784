import { BranchFilter, ConditionalBranch } from '@shared/workflows/types'
import { replaceItemAtIndex } from '@lenses/utils'

interface Props {
  branches: ConditionalBranch[]
  branchIndex: number
  branchFilterIndex: number
  newBranchFilter: BranchFilter
}

export const replaceBranchFilter = ({
  branches,
  branchIndex,
  branchFilterIndex,
  newBranchFilter
}: Props): ConditionalBranch[] => {
  const prevBranch = branches[branchIndex]
  if (!prevBranch) {
    return branches
  }

  const prevBranchFilters = prevBranch.filters
  if (!prevBranchFilters[branchFilterIndex]) {
    return branches
  }

  const updatedBranchFilters = replaceItemAtIndex(prevBranchFilters, newBranchFilter, branchFilterIndex)
  const updatedBranch = { ...prevBranch, filters: updatedBranchFilters }
  return replaceItemAtIndex(branches, updatedBranch, branchIndex)
}
