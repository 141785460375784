import EnableFor from '@components/enableFor'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import React from 'react'
import * as Style from './styles'
import { SCOPES } from '@root/constants.t'

interface Props {
  onAddFilterClick: () => void
  onPreviewClick: ({ idOrg, idWorkflow }: {idOrg: number, idWorkflow: number }) => void
  onClearFiltersClick: () => void
  clearFiltersDisabled: boolean
  editAllowedScopes?: SCOPES[]
}

const FiltersActions = ({
  onAddFilterClick,
  onPreviewClick,
  onClearFiltersClick,
  clearFiltersDisabled,
  editAllowedScopes
}: Props) => {
  return (
    <Style.Container>
      <Style.LeftContainer>
        <EnableFor scopes={editAllowedScopes}>
          <Button type={ButtonType.compact} size={ButtonSize.small}
            onClick={onAddFilterClick}
            icon='Plus'
            label='Add filter'
          />
        </EnableFor>
        <Button
          type={ButtonType.secondary}
          onClick={onPreviewClick}
          label='Preview'
        />
      </Style.LeftContainer>
      <EnableFor scopes={editAllowedScopes}>
        <Button type={ButtonType.compact} size={ButtonSize.small}
          disabled={clearFiltersDisabled}
          onClick={onClearFiltersClick}
          label='Clear filters'
        />
      </EnableFor>
    </Style.Container>
  )
}

export default FiltersActions
