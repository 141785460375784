import styled from 'styled-components'

const hoverActions = `
  opacity: 0;
  transition: opacity .2s;
  &.rt-td {
    textOverflow: 'unset !important';
  }
  .rt-tr:hover & {
    opacity: 1;
  }
`

export const DeleteButton = styled.span`
  margin-left: ${({ theme }) => theme.spacing('space-200')};
  ${hoverActions}
`

export const ActionsWrapper = styled.div`
  display: flex;
`
