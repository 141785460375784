import React, { ReactElement } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ACTIONS_LOG_TYPE } from './types'
import { Button, ButtonType } from '@toriihq/design-system'
import { analytics } from '@shared/services/workflows/analytics'
import { ACTIONS_LOG_CONFIG } from './consts'
import { ButtonContainer } from './styles'
import { SCOPES } from '@root/constants'
import VisibleFor from '@root/components/visibleFor'

interface Props {
  actionsLogType: ACTIONS_LOG_TYPE
}

export const ActionsLogButton = ({
  actionsLogType
}: Props): ReactElement => {
  const history = useHistory()
  const { idOrg } = useParams()
  const {
    location,
    presetFilters
  } = ACTIONS_LOG_CONFIG[actionsLogType]

  const handleClick = () => {
    analytics.actionsLogPage.open({ openFrom: location })
    const qs = presetFilters ? `?filters=${encodeURIComponent(JSON.stringify(presetFilters))}` : ''
    history.push(`/team/${idOrg}/workflows/actions/audit${qs}`)
  }

  return <VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
    <ButtonContainer>
      <Button
        type={ButtonType.secondary}
        onClick={handleClick}
        label='Actions log'
      />
    </ButtonContainer>
  </VisibleFor>
}
