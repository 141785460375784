import colors from '@root/shared/style/colors'
import { fontSize, fontWeight } from '@root/shared/style/sizes'
import { css } from 'glamor'
import { smallDeviceWidth } from '../popups/ToriiPopupV2/style'

export const RecommendationsContainer = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '16px',
  paddingBottom: '50px'
})

export const Header = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
  marginTop: '32px',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'flex-start'
  }
})

export const HeaderText = css({
  fontSize: fontSize.mediumLarge,
  fontWeight: fontWeight.semiBold,
  color: colors.darkText
})

export const FilterContainer = css({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
})

export const SelectFiltersContainer = css({
  display: 'flex',
  gap: '16px'
})

export const EmptyStateContainer = css({
  width: '100%',
  height: '100%',
  marginTop: '10%',
  display: 'flex',
  justifyContent: 'center'
})

export const EmptyState = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px'
})

export const TextContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
})
