import { installedBy } from './components/pluginPage/pluginDetails/pluginInstalledBy/types'
import { IWidget } from '@root/widgets/widget/types'
import { formFieldTypes } from '@root/constants'

export type IdPlugin = number

export enum Publicity {
  Public = 'Public',
  Private = 'Private'
}

interface PluginConfigField {
  name: string
  label: string
  placeholder: string
  type: typeof formFieldTypes[keyof typeof formFieldTypes]
}

interface PluginConfig {
  description?: string
  fields: PluginConfigField[]
}

export interface PluginModel {
  id: IdPlugin
  uuid: string
  name: string
  squareLogoUrl: string
  rectLogoUrl: string
  description: string
  version: string
  vendor: string
  idOrg: number
  isPublic: boolean
  categories: string[]
  installedBy: installedBy
  installationDate: Date
  isDeleted: boolean
  isInstalled: boolean
  widgets: IWidget[]
  config?: PluginConfig
}

export default PluginModel
