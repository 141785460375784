export interface InputValue {
  testStartTime: string | null
  testPayloadReceivedTime: string | null
  payload: any
  testError: string | null
}

export enum TRIGGER_PAYLOAD_TEST_STATE {
  READY_TO_TEST = 'READY_TO_TEST',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}
