import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { css } from 'glamor'
import Analytics from '../../helpers/analytics'
import { TRANSACTION_MAPPING_STATUS } from '../../constants'

const CSS = {
  icon: css({
    fontSize: '16px'
  }),
  main: css({
    display: 'flex',
    marginLeft: '15px'
  })
}

class ExpensesActions extends React.Component {
  onEdit = () => {
    const { id, name, idApp, idUpload, onEdit, transactionDate, description, origTransactionDate, amount, modifiedAmount, origAmount, origCurrency, mappingStatus, mappingLogic } = this.props
    return onEdit({ transaction: { id, idApp, idUpload, name, transactionDate, description, origTransactionDate, amount, modifiedAmount, origAmount, origCurrency, mappingStatus, mappingLogic } })
  }

  onArchive = () => {
    const { id, name, idApp, idUpload, onArchive } = this.props
    return onArchive({ transaction: { id, idApp, idUpload, name } })
  }

  unarchiveTransactionWithAnalytics = async () => {
    const { id, name, unarchiveTransaction } = this.props
    await Analytics.track('Unarchived transaction', {
      'Transaction ID': id,
      'Transaction name': name
    })
    await unarchiveTransaction()
  }

  render () {
    const { disabled, mappingStatus } = this.props
    const isArchived = (mappingStatus === TRANSACTION_MAPPING_STATUS.ARCHIVED)

    return (
      <div {...CSS.main} >
        {!isArchived && <Tooltip
          placement='top'
          hide={disabled}
          label={'Edit'}
        >
          <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled} icon='Edit' onClick={this.onEdit} />
        </Tooltip>}
        <Tooltip
          placement='top'
          label={!isArchived ? 'Archive' : 'Unarchive'}
          hide={!isArchived ? disabled : true}
        >
          {!isArchived
            ? <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled} icon='Archive' onClick={this.onArchive} />
            : <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled} onClick={this.unarchiveTransactionWithAnalytics} label='Unarchive' />}
        </Tooltip>
      </div>
    )
  }
}

ExpensesActions.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  mappingStatus: PropTypes.string.isRequired,
  idApp: PropTypes.number,
  idUpload: PropTypes.number
}

export default ExpensesActions
