import React from 'react'
import NotConfigured from '../../components/offboarding/notConfigured'
import Configured from '../../components/offboarding/configured'
import { TABLES } from '../../constants'
import PageHeader from '../../components/pageHeader'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import TableSearch from '../../components/table/tableSearch'
import BackLink from '@components/backLink'
import AdvancedSettings from './advancedSettings'

const CSS = {
  main: css({
    marginBottom: 25,
    width: '100%'
  }),
  backLink: css({
    color: colors.blue,
    marginTop: '20px'
  })
}

class OffboardingSettingsPage extends React.Component {
  state = { search: '' }

  componentDidMount () {
    const { idOrg, getOffboardingApps, getServicesSyncData, getServicesConfig } = this.props
    getOffboardingApps({ idOrg })
    getServicesSyncData({ idOrg })
    getServicesConfig({ idOrg })
  }

  onSearch = (e) => {
    const { value } = e.currentTarget
    this.setState({
      search: value
    })
  }

  searchFilter = (app) => {
    const { search } = this.state
    const searchTerm = search.toLowerCase()
    return !searchTerm || app.name.toLowerCase().includes(searchTerm)
  }

  render () {
    const { idOrg, configuredApps, needsAttentionApps, nonConfiguredApps, loading, getOffboardingApps } = this.props
    const { search } = this.state
    const isSearching = Boolean(search)
    const needAttentionNoData = needsAttentionApps.length === 0

    let filteredNeedsAttentionApps = needsAttentionApps
    let filteredConfiguredApps = configuredApps
    let filteredNonConfiguredApps = nonConfiguredApps

    if (isSearching) {
      filteredNeedsAttentionApps = needsAttentionApps.filter(this.searchFilter)
      filteredConfiguredApps = configuredApps.filter(this.searchFilter)
      filteredNonConfiguredApps = nonConfiguredApps.filter(this.searchFilter)
    }

    const refreshData = () => getOffboardingApps({ idOrg })

    return <div {...CSS.main}>
      <div {...CSS.backLink}>
        <BackLink navigateTo={`/offboarding/todo`} linkText={'Back to offboarding'} />
      </div>
      <PageHeader title='Offboarding Settings' />
      <TableSearch onSearch={this.onSearch} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
        <NotConfigured
          loading={loading}
          tableWrapperId='needAttentionTableWrapper'
          forceOpen={isSearching}
          apps={filteredNeedsAttentionApps}
          idOrg={idOrg}
          header='Needs attention'
          headerHeight={filteredNeedsAttentionApps.length && !isSearching ? 100 : undefined}
          tableKey={TABLES.needsAttentionAppsTable.key}
          noData={needAttentionNoData}
          showPendingAmount
          showButtonsOnlyOnHover={false}
          refreshDataFunc={refreshData}
          showAlertBox
        />
        <Configured
          loading={loading}
          tableWrapperId='configuredTableWrapper'
          forceOpen={isSearching}
          idOrg={idOrg}
          header='Configured'
          headerHeight={filteredConfiguredApps.length && !isSearching ? 100 : undefined}
          tableKey={TABLES.configuredAppsTable.key}
          apps={filteredConfiguredApps}
          refreshDataFunc={refreshData}
          showAlertBox
        />
        <NotConfigured
          loading={loading}
          tableWrapperId='notConfiguredTableWrapper'
          forceOpen={isSearching}
          apps={filteredNonConfiguredApps}
          idOrg={idOrg}
          header='Not configured'
          headerHeight={filteredNonConfiguredApps.length && !isSearching ? 100 : undefined}
          tableKey={TABLES.notConfiguredAppsTable.key}
          isCollapsible
          showButtonsOnlyOnHover
          refreshDataFunc={refreshData}
        />
      </div>
      <AdvancedSettings />
    </div>
  }
}

export default OffboardingSettingsPage
