import styled from 'styled-components'
import { Spacing } from '@toriihq/design-system'

export const Wrapper = styled.div`
  min-height: 500px;
`

export const PlanTitle = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle01};
  margin-bottom: ${({ theme }) => theme.spacing('space-200')};
  margin-top: ${({ theme }) => theme.spacing('space-300')};
`
export const PlanBox = styled.div<{ gap?: Spacing }>`
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  padding: 22px 24px 24px 24px;
  margin-bottom: ${({ theme }) => theme.spacing('space-200')};
  width: 100%;
  max-width: 437px;
  flex-flow: column;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme, gap }) => gap ? theme.spacing(gap) : 0};
`

export const PlanRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between; 
`

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const PlanName = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle01};
`

export const PlanBillingPeriod = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.tertiary};
  margin-bottom: ${({ theme }) => theme.spacing('space-300')};
`

export const Price = styled.span`
  font: ${({ theme }) => theme.typography.font.header04};
`

export const Period = styled.span`
  font: ${({ theme }) => theme.typography.font.caption01};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const Description = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const AlertBoxContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing('space-300')};
`

export const PriceContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing('space-100')};
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${({ theme }) => theme.spacing('space-100')};
`

export const CancelSubscriptionContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing('space-200')};;
`

export const EmployeeDefinitionMessage = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle02};
  display: flex;
  flex-flow: row;
  width: 100%;
  max-width: 319px;
  gap: ${({ theme }) => theme.spacing('space-100')};
  margin-bottom: ${({ theme }) => theme.spacing('space-200')};
`

export const EmployeeDefinitionMessageText = styled.span``

export const PlanEndTimeContainer = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  margin-bottom: ${({ theme }) => theme.spacing('space-200')};
`

export const PlanContainer = styled.div``
