import send from '@shared/redux-fetch'
import {
  GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN,
  GET_APP_USERS_COUNT,
  GET_WIDGETS
} from '@root/constants'
import { GetAppUsersCountParams } from './app.types'

export const getConnectedAppUsersStatusesBreakdown = ({ idOrg, idApp, idAppAccount }) => {
  return send(GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/statuses`
  })
}

export const getAppUsersCount = ({
  idOrg,
  idApp,
  filters = []
}: GetAppUsersCountParams) => {
  return send(GET_APP_USERS_COUNT, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users`,
    query: {
      withoutContent: true,
      filters: JSON.stringify(filters)
    },
    meta: { idApp }
  })
}

export const getWidgets = ({ idOrg, idApp }) => {
  return send(GET_WIDGETS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/plugins/widgets`
  })
}
