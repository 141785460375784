import { Props } from './types'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import RelativeTeamLink from '@components/relativeTeamLink'
import React from 'react'
import Analytics from '@components/navigationToWorkflowExecutionLog/analytics'
import { Link } from '@toriihq/design-system'

const NavigationToWorkflowExecutionLog = ({ navTo, workflow, isAppCatalogPoliciesView, pageName, title }: Props): JSX.Element => {
  return (
    <EnableFor scopes={[SCOPES.AUTOMATION_READ]} allowForToriiAdmin>
      <RelativeTeamLink
        to={navTo}
        onClick={() => Analytics.onNavToExecutionLog({ workflow, isAppCatalogPoliciesView, pageName })}>
        <Link>{title}</Link>
      </RelativeTeamLink>
    </EnableFor>
  )
}

export default NavigationToWorkflowExecutionLog
